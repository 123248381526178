export const AUTH = '[Auth]'

export const USER_LOGIN = `${AUTH} USER LOGIN`
export const USER_LOGOUT = `${AUTH} USER LOGOUT`
export const USER_PICK_GARAGE = `${AUTH} USER PICK GARAGE`
export const USER_RESET_PASSWORD_EMAIL = `${AUTH} USER RESET PASSWORD EMAIL`
export const USER_RESET_PASSWORD_NEW = `${AUTH} USER RESET PASSWORD NEW`
export const AUTH_REMOVE = `${AUTH} REMOVE`

export default {
    AUTH,
    USER_LOGIN,
    USER_LOGOUT,
    USER_PICK_GARAGE,
    USER_RESET_PASSWORD_EMAIL,
    USER_RESET_PASSWORD_NEW,
    AUTH_REMOVE,
}
