export const SYSTEM_MESSAGES_COUNTER = '[SYSTEM_MESSAGE COUNTER]'

export const COUNT_SYSTEMS_MESSAGES = `${SYSTEM_MESSAGES_COUNTER} COUNT`
export const SET_SYSTEM_MESSAGES_COUNTER = `${SYSTEM_MESSAGES_COUNTER} SET`
export const INCREMENT_SYSTEM_MESSAGES_COUNTER = `${SYSTEM_MESSAGES_COUNTER} INCREMENT`
export const DECREMENT_SYSTEM_MESSAGES_COUNTER = `${SYSTEM_MESSAGES_COUNTER} DECREMENT`

export const countSystemMessages = () => ({
    type: COUNT_SYSTEMS_MESSAGES,
})

export const setSystemMessagesCounter = systemMessagesCounter => ({
    type: SET_SYSTEM_MESSAGES_COUNTER,
    payload: {
        systemMessagesCounter,
    },
})

export const incrementSystemMessagesCounter = () => ({
    type: INCREMENT_SYSTEM_MESSAGES_COUNTER,
})

export const decrementSystemMessagesCounter = () => ({
    type: DECREMENT_SYSTEM_MESSAGES_COUNTER,
})
