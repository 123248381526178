export default {
    logged: false,
    id: null,
    name: null,
    logo: null,
    address: null,
    web: null,
    zip: null,
    emails: [],
    phones: [],
    hourly_price: 0,
    tax: 0,
    discounts: [],
    additional_percent_on_works: 0,
    allow_official_service: false,
    allow_checklist: false,
    allow_reporting: false,
    allow_upload_customer: false,
    allow_checklist_update: true,
    allow_labour: false,
    allow_tires_create: false,
    allow_book_now: false,
    packages: {},
    terms_and_conditions: {
        url: [],
    },
    privacy_policy: [],
    customer_signature: null,
    concept: null,
    is_euromaster: false,
    mastercheck_layout: 1
}
