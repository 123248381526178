import React, {Component} from 'react';
import './Select.scss';
import {compare} from 'utils/common';
import {LOADER_FORM_VEHICLE} from 'store/consts/loader.constants';
import Loader from '../Loader/Loader';
import classNames from 'classnames';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {selectDropdownOpened} from "store/actions/global.actions";
import strNormalize from "utils/strNormalize";
import TextHighlighter from "../Highlighter/TextHighlighter";
import {fieldExists} from "utils/fieldExists";
import Confirm from "../Confirm/Confirm";
import InputRadio from "components/shared/InputRadio";
import Input from "components/shared/Input/Input";
import {captureException} from "../../../utils/captureException";
import {Popup} from "../Popup/Popup";

class   Select extends Component {
    constructor(props) {
        super(props);
        this.optionsRef = React.createRef();
        this.selectRef = React.createRef();

        this.state = {
            list: this.props.list || null,
            isOpened: this.props.isOpened || false,
            selectedOption: fieldExists(this.props, 'selected')
                ? this.props.list.find(item => item.id === this.props.selected)
                : null,
            placeholder: this.props.children || this.props.t('form.placeholder.default.select'),
            filter: {
                value: '',
                isOpened: false
            },
            attributes: this.props.attributes || [],
            attributesSeparator: this.props.attributesSeparator || '',
            index: -1,
            confirmBeforeOpen: null,
            showSearch: false
        };

        document.addEventListener('keyup', this.onKeyUpOrDown);
        document.addEventListener('keyup', this.onKeyEnter);
    }

    componentDidMount() {
        const {list} = this.state;
        const {id, onTouched, onHasOneOption, onChange, onEmptyList} = this.props;

        if (list.length > 0) {
            if (onTouched) {
                onTouched();
            }
        }
        else{
            if(onEmptyList) onEmptyList();
        }

        if (list.length === 1) {
            const selected = list[0];
            onHasOneOption ? onHasOneOption(id, selected) : onChange(id, selected);
            this.selectOne(selected);
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.state.isOpened !== nextState.isOpened) {
            this.props.dispatch(selectDropdownOpened(nextState.isOpened));
        }

        if (
            (this.optionsRef !== null && this.optionsRef.current !== null) &&
            (this.state.filter.value !== nextState.filter.value)
        ) {
            const items = this.optionsRef.querySelector('.dd-list__options').children || false;

            if (items) {
                Array.from(items).forEach(item => {
                    item.className = item.className.replace('highlighted', '');
                })
            }
        }

        if ((this.state.isOpened !== nextState.isOpened) && !fieldExists(this.state, 'indexLast') && this.state.selectedOption) {
            const selectedItemIndex = this.state.list.indexOf(this.state.selectedOption);

            this.setState({
                index: selectedItemIndex
            })
        }

        // if (this.state.filter.value !== nextState.filter.value && nextState.filter.value.length === 0) {
            // this.setState({
            //     selectedOption: null,
            //     indexLast: -1,
            //     index: -1
            // }, () => {
            //     this.props.onChange(this.props.id, {});
            // });
        // }
    }

    componentWillReceiveProps(nextProps) {
        const {list} = this.state;

        if (list && JSON.stringify(list) !== JSON.stringify(nextProps.list)) {
            this.setState({
                list: nextProps.list,
                filter: {value: ''}
            }, () => {
                if (nextProps.list.length === 1) {
                    const selected = nextProps.list[0];
                    this.props.onHasOneOption ? this.props.onHasOneOption(nextProps.id, selected) : this.props.onChange(nextProps.id, selected);
                    this.selectOne(selected);
                }
            });
        }

        if (nextProps.openListOnMultipleOptions && (JSON.stringify(list) !== JSON.stringify(nextProps.list)) && nextProps.list.length > 1) {
            this.setState({
                isOpened: true,
            })
        }

        if (fieldExists(nextProps, 'selected') && ((fieldExists(this.state.selectedOption, 'id') && this.state.selectedOption.id !== nextProps.selected) || !this.state.selectedOption)){
            this.setState({
                selectedOption: nextProps.selected
                    ? nextProps.list.find(item => item.id === nextProps.selected)
                    : null,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list} = this.state;

        if (compare(list, prevState.list)) {
            if (list.length === 1) {
                this.selectOne(list[0]);
            } else {
                this.setState({
                    selectedOption: fieldExists(this.props, 'selected')
                        ? this.props.list.find(item => item.id === this.props.selected)
                        : null,
                });
            }
        }

        /*if ((this.props.list.length !== prevProps.list.length) && this.props.list.length === 1) {
            const selected = list[0];
            this.props.onHasOneOption(this.props.id, selected);
            this.selectOne(selected);
        }*/

        if (
            (this.optionsRef !== null && this.optionsRef.current !== null) &&
            (
                (this.state.filter.value !== prevState.filter.value) ||
                (this.state.isOpened !== prevState.isOpened && this.state.isOpened === true)
            ) &&
            (this.state.selectedOption === null && this.getFilteredList(this.state.list).length > 0)
        ) {
            const items = this.optionsRef.querySelector('.dd-list__options').children || false;

            if (items) {
                this.highlightFirstItem(items[0]);
            }
        }
    }

    highlightFirstItem = (item) => {
        item.className += " highlighted";
        this.setState({
            index: 0
        });
    };

    selectOne = option => {
        const {id, onChange, list, forceOnChange} = this.props;
        const {selectedOption} = this.state;

        if (selectedOption && (option.id === selectedOption.id))
            return this.setState({
                isOpened: false
            });

        const selectedIndex = this.state.list.findIndex(opt => opt.id === option.id);

        this.setState({
                selectedOption: option,
                index: selectedIndex,
                indexLast: selectedIndex,
                filter: {
                    value: option.name.toString().toLowerCase()
                }
            },
            () => {
                this.setState({
                    isOpened: false,
                    filter: {
                        value: '',
                    },
                });

                /* trigger onChange only if there's more then 1 item in list */
                /* or forceOnChange prop is passed */
                if (list.length !== 1 || forceOnChange) {
                    onChange(id, option);
                }
            }
        );
    };

    toggleList = () => {
        this.setState({
            isOpened: !this.state.isOpened
        });
    };

    scrollToPreselectedItem = () => {
        const {selectedOption} = this.state;

        if (!selectedOption)
            return;

        const elementToScrollTo = this.optionsRef.querySelector(`#option-${selectedOption.id}`);
        const LIST_ABOVE_THE_FOLD_HEIGHT = 160;
        const HALF_OF_THE_LIST_HEIGHT = (this.optionsRef.clientHeight / 2.5);

        if (!elementToScrollTo)
            return;

        if (elementToScrollTo.offsetTop > LIST_ABOVE_THE_FOLD_HEIGHT) {
            this.optionsRef.scrollTop = elementToScrollTo.offsetTop - HALF_OF_THE_LIST_HEIGHT;
        }
    };

    highlightSelection = (direction) => {
        const items = this.optionsRef.querySelector('.dd-list__options').children;
        const NUMBER_OF_VISIBLE_ITEMS = 3;

        items.forEach((item, index) => {
            item.classList.remove('highlighted');

            if (index === this.state.index) {
                item.className += ' highlighted';

                if ((index > NUMBER_OF_VISIBLE_ITEMS) && direction === 'DOWN') {
                    this.optionsRef.scrollTop += item.clientHeight;
                }

                if (direction === 'UP') {
                    this.optionsRef.scrollTop -= item.clientHeight;
                }
            }
        })
    };

    onKeyEnter = (e) => {
        e.preventDefault();
        const {isOpened, list} = this.state;

        if (e.keyCode === 13 && isOpened) {
            if (this.optionsRef && this.optionsRef.current !== null) {
                const selected = this.optionsRef.querySelector('.highlighted');

                if (selected) {
                    this.selectOne({
                        id: parseInt(selected.getAttribute('data-item-id')),
                        name: selected.getAttribute('data-item-name'),
                        productionTo: selected.getAttribute('data-item-production-to'),
                        productionFrom: selected.getAttribute('data-item-production-from'),
                    });
                } else {
                    const firstItemInList = this.getFilteredList(list)[0];

                    if (firstItemInList) {
                        this.selectOne({
                            id: firstItemInList.id,
                            name: firstItemInList.name
                        });
                    }

                }
            }
        }
    };

    onKeyUpOrDown = (e) => {
        const KEY_UP = 38;
        const KEY_DOWN = 40;
        const {index, list, isOpened} = this.state;

        if (!isOpened)
            return;

        switch (e.keyCode) {

            case KEY_UP:
                if (index > 0) {
                    this.setState({
                        index: index - 1
                    }, () => {
                        this.highlightSelection('UP');
                    });
                }

                break;

            case KEY_DOWN:
                if (index < (this.getFilteredList(list).length - 1))
                    this.setState({
                        index: index + 1
                    }, () => {
                        this.highlightSelection('DOWN');
                    });
                break;

            default:
                return null;
        }
    };

    open = () => {
        const {onBeforeOpen} = this.props;

        if(onBeforeOpen){
            this.beforeOpen().then(() => {
                onBeforeOpen.then();
                this.setState({confirmBeforeOpen: null})
                this.openList();
            })
            .catch(error => {
                captureException(error);
                onBeforeOpen.catch();
                this.setState({confirmBeforeOpen: null})
            })
        }
        else{
            this.openList();
        }
    };

    openList = () => {
        const hasOnlyOneItem = (this.state.list.length === 1);

        this.setState({
            isOpened: !hasOnlyOneItem
        }, () => {
            if (!hasOnlyOneItem) {
                this.scrollToPreselectedItem()
            }
        });
    };

    async beforeOpen () {
        return await new Promise((resolve, reject) => this.setState({confirmBeforeOpen: {resolve, reject}}))
    }

    renderSelectItem = (option) => {
        const {name, id} = option;
        const {filter} = this.state;

        return (
            <li
                key={id}
                id={`option-${id}`}
                className={classNames('dd-list__options--item', {
                    'selected': (this.state.selectedOption && this.state.selectedOption.id == option.id)
                })}
                onClick={() => this.selectOne(option)}
                data-item-id={id}
                data-item-name={option.name}
                data-item-production-from={option.productionFrom}
                data-item-production-to={option.productionTo}
            >
                <InputRadio
                    id={id}
                    name={"select"}
                    value={option.name}
                    label={null}
                    checked={this.state.selectedOption && this.state.selectedOption.id == option.id}
                    onChange={() => {}}
                />
                <TextHighlighter target={filter.value}>{name}</TextHighlighter>
                {' '}{this.renderAttributes(option)}
            </li>
        );
    };

    shouldRenderIconDropdown = (isLoading) => {
        return (isLoading === false || typeof isLoading === 'undefined') && (this.state.isOpened === false);
    };

    renderAttributes(option) {
        if (!this.state.attributes.length) {
            return null;
        }

        return (
            <small>{
                this.state.attributes.map((item, i) => {
                    return (option[item] ? option[item] : '...') + (this.state.attributes.length - 1 > i ? this.state.attributesSeparator : '');
                })
            }</small>
        )
    }

    handleFilterChange = (e) => {
        const {value} = e.target;

        this.setState({
            filter: {
                value
            }
        });

        if (value === '' && !this.state.indexLast) {
            this.setState({
                index: -1
            })
        } else if (value === '' && this.state.indexLast) {
            this.setState({
                index: this.state.indexLast
            })
        } else if (value !== '') {
            this.optionsRef.scrollTop = 0;
            this.setState({
                index: -1
            })
        }
    };

    handleFilterValueReset = () => {
        this.setState({
            filter: {
                value: ''
            }
        })
    };

    getFilteredList = (list) => {
        const {value} = this.state.filter;
        const selectedOptionName = this.state.selectedOption && this.state.selectedOption.name.toString().toLowerCase();

        if (value === '' || (selectedOptionName === value.toLowerCase()))
            return list;

        if (this.props.directSearch) {
            return list.filter(item => item.name.toString().toLowerCase().startsWith(value.toString().toLowerCase()));
        }

        return list.filter(item => strNormalize(item.name.toString().toLowerCase()).includes(strNormalize(value.toString().toLowerCase())));
    };

    render() {
        const {
            id,
            disabled,
            error,
            label,
            hidden,
            isTransparent,
            iconClass,
            isLoading,
            hideErrorMessage,
            onBeforeOpen,
            t,
            extendClass,
            size
        } = this.props;
        const {isOpened, selectedOption, placeholder, list, filter, confirmBeforeOpen} = this.state;

        const filteredList = this.getFilteredList(list);

        if (hidden) return null;

        return (
            <div className={classNames("dd-container", {
                'dd-sm': size === 'sm',
            })} ref={this.selectRef}>
                <div onClick={() => !disabled ? this.open() : null}>
                    {label ? (
                        <div className={classNames('dd-title', {
                            'dd-title--disabled': disabled,
                            'dd-title--error': error
                        })}>
                            <label>{label}</label>
                        </div>
                    ) : null}

                    <div className="dd-header">
                        {selectedOption ? (
                            <div
                                id={id}
                                className={classNames('dd-select dd-select--selected', {
                                    'dd-select--disabled': disabled,
                                    'dd-select--single-item': filteredList.length === 1,
                                    'dd-select--transparent': isTransparent === true,
                                    'dd-select--error': error,
                                    [extendClass]: extendClass,
                                })}
                            >
                                {selectedOption.name}{' '}{this.renderAttributes(selectedOption)}
                            </div>
                        ) : (
                            <div
                                id={id}
                                className={classNames('dd-select', {
                                    'dd-select--disabled': disabled,
                                    'dd-select--single-item': filteredList.length === 1,
                                    'dd-select--transparent': isTransparent === true,
                                    'dd-select--error': error,
                                    [extendClass]: extendClass,
                                })}
                            >
                                {placeholder}
                            </div>
                        )}

                        {isLoading === true && (
                            <Loader isLoading={isLoading} type={LOADER_FORM_VEHICLE}/>
                        )}

                        {this.shouldRenderIconDropdown(isLoading, filteredList.length) && (
                            <div
                                className={`dd-header__icon ${iconClass ? iconClass : 'icon-select'}`}
                            />
                        )}
                    </div>

                    {error && !hideErrorMessage && (
                        <span className="form-error">
                            <p>{error}</p>
                        </span>
                    )}
                </div>
                {(confirmBeforeOpen && onBeforeOpen) &&
                    <Confirm
                        title={onBeforeOpen.title}
                        subtitle={onBeforeOpen.subtitle}
                        visible={!!confirmBeforeOpen}
                        accept={() => confirmBeforeOpen.resolve(true)}
                        cancel={() => confirmBeforeOpen.reject(false)}
                    />
                }

                {filteredList && isOpened && !disabled && (
                    <Popup
                        title={label ? label : placeholder}
                        visible={filteredList && isOpened && !disabled}
                        onClose={this.toggleList}
                        toggleSearch={(show) => this.setState({showSearch: show})}
                    >
                        <div className="mh-5">
                            <Input
                                type="text"
                                name="filter"
                                value={filter.value}
                                onChange={this.handleFilterChange}
                                onTextClear={this.handleFilterValueReset}
                                preventOnEnter={true}
                                iconLeftClassName={'icon-search'}
                                iconRightClassName={'icon-cancel'}
                            />
                        </div>

                        <div className="dd-list" ref={el => (this.optionsRef = el)}
                             onKeyPress={(e) => e.preventDefault()}>
                            <ul className="dd-list__options">
                                {filteredList && !!filteredList.length && filteredList.map(option => this.renderSelectItem(option))}
                                {filteredList && !!!filteredList.length && (
                                    <div className='dd-no-result'>
                                        <p>{t('form.select.no_result')}</p>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </Popup>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        global: state.global
    };
};

export default connect(mapStateToProps)(translate('translations')(Select));
