import { CAR_STEP_UPDATE, carStepSet } from 'store/actions/carStep.actions'
import { fieldExists } from 'utils/fieldExists'
import {
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE,
} from '../../../app/consts/vehicle'
import { isCarFieldValid } from '../../../utils/isCarFieldValid'
import { packagesPageShow } from 'store/actions/packages.actions'

export const carStepMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case CAR_STEP_UPDATE:
                const car = action.payload.car

                if (fieldExists(car, 'color_list') && car.color_list.length > 0 && car.color === null) {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE, true, action.payload.message))
                } else if (
                    fieldExists(car, 'make_id') &&
                    fieldExists(car, 'range_id') &&
                    fieldExists(car, 'type_id') &&
                    fieldExists(car, 'first_registration') &&
                    isCarFieldValid(car, 'gear_id', 'gears', true) &&
                    isCarFieldValid(car, 'qual_lt', 'qual_lt_list', true) &&
                    isCarFieldValid(car, 'qual_md', 'qual_md_list', true)
                ) {
                    dispatch(packagesPageShow())
                } else if (
                    fieldExists(car, 'make_id') &&
                    fieldExists(car, 'first_registration') &&
                    fieldExists(car, 'range_id') &&
                    fieldExists(car, 'type_id')
                ) {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_2, true, action.payload.message))
                } else if (
                    fieldExists(car, 'make_id') &&
                    fieldExists(car, 'range_id') &&
                    fieldExists(car, 'type_id') &&
                    fieldExists(car, 'first_registration')
                ) {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_2, true, action.payload.message))
                } else {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1, true, action.payload.message))
                }

                break

            default:
                break
        }
    }
}
