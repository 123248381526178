import { URGENCY_URGENT } from '../../consts/package/package.urgency.constants'
import { STATUS_OPEN } from '../../consts/package/package.status.constants'
import { TYPE_REGULAR } from '../../consts/package/package.type.constants'

export default {
    id: null,
    name: '',
    urgency: URGENCY_URGENT,
    status: STATUS_OPEN,
    description: null,
    discount: {
        name: '',
        price: 0,
    },
    type: TYPE_REGULAR,
    files: [],
    labours: [],
    officialService: [],

    tires: {},
}
