export const GARAGE = '[Garage]'

export const GARAGE_SET = `${GARAGE} SET`
export const GARAGE_REMOVE = `${GARAGE} REMOVE`
export const GARAGE_REINITIALIZE = `${GARAGE} REINITIALIZE`
export const GARAGE_UPDATE = `${GARAGE} UPDATE`
export const GARAGE_UPDATE_APPOINTMENT_DISCOUNT = `${GARAGE} UPDATE_APPOINTMENT_DISCOUNT`
export const GARAGE_TERMS_AND_CONDITIONS_ACCEPT = `${GARAGE} TERMS_AND_CONDITIONS_ACCEPT`
export const GARAGE_UPDATE_CUSTOMER_SIGNATURE = `${GARAGE} UPDATE CUSTOMER SIGNATURE`

export const setGarage = garage => ({
    type: GARAGE_SET,
    payload: {
        garage,
    },
})

export const updateGarage = data => ({
    type: GARAGE_UPDATE,
    payload: {
        data,
    },
})

export const garageRemove = () => ({
    type: GARAGE_REMOVE,
})

export const garageReinitialize = () => ({
    type: GARAGE_REINITIALIZE,
})

export const garageUpdateAppointmentDiscount = discount => ({
    type: GARAGE_UPDATE_APPOINTMENT_DISCOUNT,
    payload: {
        discount,
    },
})

export const garageTermsAndConditionsAccept = () => ({
    type: GARAGE_TERMS_AND_CONDITIONS_ACCEPT,
})

export const garageUpdateCustomerSignature = customer_signature => ({
    type: GARAGE_UPDATE_CUSTOMER_SIGNATURE,
    payload: {
        customer_signature,
    },
})
