import {API_ERROR, API_SUCCESS, apiRequest} from 'store/actions/api.actions'
import {LANGUAGE_SETTING_UPDATE} from 'store/actions/language.settings.actions'
import {setLocalStorageItem} from 'utils/storage'
import {STORAGE_LANGUAGE} from 'app/consts/storage.consts'
import {userUpdateLocale} from "../../actions/user.actions";
import ChangeLanguage from "../../../utils/ChangeLanguage";

export const SETTINGS_LANGUAGE_UPDATE_URL = 'garage/settings/update-default-language'

export const languageSettingMiddleware = ({dispatch}) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case LANGUAGE_SETTING_UPDATE:
                dispatch(
                    apiRequest({
                        body: {language_id: action.payload.languageId},
                        method: 'POST',
                        url: SETTINGS_LANGUAGE_UPDATE_URL,
                        signature: LANGUAGE_SETTING_UPDATE,
                    }),
                )
                break

            case `${LANGUAGE_SETTING_UPDATE} ${API_SUCCESS}`:
                setLocalStorageItem(STORAGE_LANGUAGE, action.payload.data.language.locale)
                dispatch(userUpdateLocale(action.payload.data.language.locale))

                ChangeLanguage()

                break

            case `${LANGUAGE_SETTING_UPDATE} ${API_ERROR}`:
                break

            default:
                break
        }
    }
}
