import i18n from 'i18next'
import language from '../../utils/language'
import pages from '../../app/consts/routes'

export const USER_ROLE_MANAGER = 1
export const USER_ROLE_MECHANIC = 2
export const USER_ROLE_MECHANIC_ADVANCED = 3
export const USER_ROLE_SUPERVISOR = 4

export const USER_ACTIVE = 1
export const USER_INACTIVE = 0

export const USER_COMMUNICATION_VONAGE = 'vonage_multiple'
export const USER_COMMUNICATION_MAIL = 'mail'
export const USER_COMMUNICATION_PUSH_NOTIFICATION = 'push_notification'

export const USER_ACTIVITY = new Proxy(
    {
        [USER_ACTIVE]: () => i18n.getResource(language(), 'translations', 'user.activity.active'),
        [USER_INACTIVE]: () => i18n.getResource(language(), 'translations', 'user.activity.inactive'),
    },
    {
        get: (obj, name) => {
            return obj[name]()
        },
    },
)

export const USER_ROLES = new Proxy(
    {
        [USER_ROLE_MANAGER]: () => i18n.getResource(language(), 'translations', 'user.roles.manager'),
        [USER_ROLE_SUPERVISOR]: () => i18n.getResource(language(), 'translations', 'user.roles.supervisor'),
        [USER_ROLE_MECHANIC]: () => i18n.getResource(language(), 'translations', 'user.roles.mechanic'),
        [USER_ROLE_MECHANIC_ADVANCED]: () =>
            i18n.getResource(language(), 'translations', 'user.roles.mechanic_advanced'),
    },
    {
        get: (obj, name) => {
            return obj[name]()
        },
    },
)

export const AVATAR = {
    FILE_SIZE: 36700160,
    SUPPORTED_FORMATS: ['image/jpg', 'image/jpeg', 'image/png'],
    WIDTH: 100,
    HEIGHT: 100,
}

export const FIRST_ON_LOGIN = {
    [USER_ROLE_MANAGER]: pages.requests,
    [USER_ROLE_SUPERVISOR]: pages.requests,
    [USER_ROLE_MECHANIC]: pages.add_cost,
    [USER_ROLE_MECHANIC_ADVANCED]: pages.add_cost,
}

export const USER_COMMUNICATIONS = [
    USER_COMMUNICATION_VONAGE,
    USER_COMMUNICATION_MAIL,
    USER_COMMUNICATION_PUSH_NOTIFICATION,
]

export const USER_CAN_UPDATE_GARAGE = [
    USER_ROLE_MANAGER,
    USER_ROLE_MECHANIC,
    USER_ROLE_MECHANIC_ADVANCED,
    USER_ROLE_SUPERVISOR,
]

export const USER_CAN_EDIT_PACKAGE = [
    USER_ROLE_MANAGER,
    USER_ROLE_SUPERVISOR,
]

export const USER_CAN_APPROVE_REJECT = [
    USER_ROLE_MECHANIC_ADVANCED,
    USER_ROLE_MANAGER,
    USER_ROLE_SUPERVISOR,
]

export const USER_CAN_REMIND_CUSTOMER = [
    USER_ROLE_MECHANIC,
    USER_ROLE_MECHANIC_ADVANCED,
    USER_ROLE_MANAGER,
    USER_ROLE_SUPERVISOR,
]

export const USER_CAN_ARCHIVE = [
    USER_ROLE_MANAGER,
    USER_ROLE_MECHANIC,
    USER_ROLE_MECHANIC_ADVANCED,
    USER_ROLE_SUPERVISOR,
]

export const canSeeAnalyticsEnhanced = (type) => [
    USER_ROLE_SUPERVISOR,
    USER_ROLE_MANAGER,
].indexOf(type) !== -1;

export const canMarkOfferAsTest = (type) => [
    USER_ROLE_SUPERVISOR,
].indexOf(type) !== -1;

export const USER_FILTER_ANALYTICS_BY_GARAGE_CONCEPT = [
    USER_ROLE_MANAGER,
    USER_ROLE_SUPERVISOR,
]

export const USER_CAN_RECEIVE_NOTIFY_FROM_EVERY_GARAGE = [
    USER_ROLE_MANAGER,
    USER_ROLE_SUPERVISOR,
];

export const isManager = (type) => [
    USER_ROLE_MANAGER,
].indexOf(type) !== -1;

export const isSupervisor = (type) => [
    USER_ROLE_SUPERVISOR,
].indexOf(type) !== -1;

export const USER_CAN_UPDATE_PRICES = [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR]
