export const CHANGE_GARAGE_DETAILS_SETTING = '[CHANGE GARAGE DETAILS SETTING]'

export const CHANGE_GARAGE_DETAILS_SETTING_UPDATE = `${CHANGE_GARAGE_DETAILS_SETTING} UPDATE`

export const changeGarageDetailsSettingUpdate = (address, web, zip_id, logo) => ({
    type: CHANGE_GARAGE_DETAILS_SETTING_UPDATE,
    payload: {
        address,
        web,
        zip_id,
        logo,
    },
})
