import React, {Component} from 'react';
import {translate} from "react-i18next";
import './style.scss'
import {connect} from "react-redux";
import axios from "../../app/config/axios";
import {captureException} from "../../utils/captureException";
import {toast} from "react-toastify";
import Toast from "../shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import {
    decrementSystemMessagesCounter,
    incrementSystemMessagesCounter
} from "../../store/actions/systemMessagesCounter.actions";

const API_GET_SYSTEM_MESSAGE_CONTENT = '/garage/get-system-message-content'
const API_MARK_SYSTEM_MESSAGE_AS_READ = '/garage/mark-message-as-read'
const API_MARK_SYSTEM_MESSAGE_AS_UNREAD = '/garage/mark-message-as-unread'

class SystemMessagesList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            modalMessage: {
                system_message_title: null,
                system_message_id: null,
                system_message_content: null,
                show_message_content: false,
                seen: false,
            }
        }
    }

    getSystemMessageContent = () => {
        const {system_message_id} = this.state

        axios.post(API_GET_SYSTEM_MESSAGE_CONTENT, {system_message_id})
            .then((response) => {
                this.setState({
                    system_message_content: response.data.data.description,
                    system_message_title: response.data.data.name,
                    seen: response.data.data.seen,
                    loading: false,
                    show_message_content: true,
                });
            }).catch(error => {
            captureException(error, API_GET_SYSTEM_MESSAGE_CONTENT)
            toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
        })
    }

    renderSystemMessage = (message, index) => {
        const {t} = this.props

        return (
            <div key={index} onClick={() => this.showMessage(message)}
                 className={`system-messages-list__item system-messages-list__marker-${message.type}`}>
                <div className={`${message.seen === true ? 'seen-title' : ''}`}>{message.name}</div>
                <span className="system-messages-list__date">{message.created_at}</span>
                {message.seen === true &&
                <span className="link-style float-right"
                      onClick={() => this.markAsUnread(message.id)}>{t('pages.system_messages.mark_as_unread')}</span>
                }
            </div>
        )
    }

    renderAppendedSystemMessage = message => {
        let nameField = 'name_' + this.props.user.locale

        return (
            <div onClick={() => this.showMessage(message)}
                 className={`system-messages-list__item system-messages-list__marker-${message.type}`}>
                <div>{message[nameField]}</div>
                <span className="system-messages-list__date">{message.created_at}</span>
            </div>
        )
    }

    renderEmptyMessages = () => {
        const {t} = this.props

        return (
            <div className="system-messages-list__empty-messages">
                {t('pages.system_messages.no_messages')}
            </div>
        )
    }

    showMessage = message => {
        this.setState({
            system_message_id: message.id
        }, this.getSystemMessageContent)
    }

    closeMessage = () => {
        this.setState({
            system_message_title: null,
            system_message_id: null,
            system_message_content: null,
            show_message_content: false,
            seen: null,
        })
    }

    markAsUnread = (message_id) => {
        const {dispatch} = this.props

        axios.post(API_MARK_SYSTEM_MESSAGE_AS_UNREAD, {system_message_id: message_id})
            .then((response) => {
                this.setState({
                    system_message_content: null,
                    system_message_title: null,
                    loading: false,
                    show_message_content: false,
                    seen: null,
                }, this.props.fetchSystemMessages)
                dispatch(incrementSystemMessagesCounter())
            })
            .catch(error => {
                captureException(error, API_MARK_SYSTEM_MESSAGE_AS_UNREAD)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
    }

    markAsRead = () => {
        const {system_message_id} = this.state
        const {dispatch} = this.props

        axios.post(API_MARK_SYSTEM_MESSAGE_AS_READ, {system_message_id})
            .then((response) => {
                this.setState({
                    system_message_content: null,
                    system_message_title: null,
                    loading: false,
                    show_message_content: false,
                    seen: null,
                }, this.props.fetchSystemMessages)
                dispatch(decrementSystemMessagesCounter())
            })
            .catch(error => {
                captureException(error, API_MARK_SYSTEM_MESSAGE_AS_READ)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
    }

    renderContent = () => {
        const {system_message_content, system_message_title, seen} = this.state
        const {t} = this.props

        return (
            <div className="system-messages-list__content-container">
                <div className="container">
                    <div className="system-messages-list__content-modal">
                        <i className="icon-cancel" onClick={this.closeMessage}/>
                        <div className="system-messages-list__content-modal-title">
                            {system_message_title}
                        </div>
                        <div className="system-messages-list__content-modal-content"
                             dangerouslySetInnerHTML={{__html: system_message_content}}/>

                        {(seen !== true) &&
                        <button
                            className={`btn btn-lg btn-primary btn-confirm`}
                            onClick={() => this.markAsRead()}
                        >
                            {t(
                                "pages.system_messages.mark_as_read"
                            )}
                        </button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {messages, appendedSystemMessages} = this.props
        const {show_message_content} = this.state

        if (messages.length === 0 && appendedSystemMessages.length === 0) {
            return (
                <React.Fragment>
                    <div className="container">
                        {this.renderEmptyMessages()}
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div className="container">
                    {show_message_content &&
                    this.renderContent()
                    }

                    {appendedSystemMessages && appendedSystemMessages.map(appendedMessage => {
                        return this.renderAppendedSystemMessage(appendedMessage)
                    }).reverse()}

                    {messages.map((message, index) => {
                        return this.renderSystemMessage(message, index)
                    })}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(translate('translations')(SystemMessagesList));
