import strSlug from './strSlug'
import imagesPath from './imagesPath'

export function getServiceImgPath(carName) {
    return imagesPath(`car-makes/${strSlug(carName)}.png`)
}

export default {
    getServiceImgPath,
}
