import labourPrices from 'store/models/labourPrices'
import {
    LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_SET,
    LABOUR_PRICES_DISCOUNT_SET,
    LABOUR_PRICES_BRAKES_DISCOUNT_SET,
    LABOUR_PRICES_HOURLY_PRICES_SET,
    LABOUR_PRICES_LIQUIDS_SET,
    LABOUR_PRICES_OIL_SET,
} from '../actions/labourPrices.actions'

export default function(state = labourPrices, action) {
    switch (action.type) {
        case LABOUR_PRICES_HOURLY_PRICES_SET:
            return {
                ...state,
                hourly: {
                    ...action.payload.hourlyPrices,
                },
            }

        case LABOUR_PRICES_OIL_SET:
            return {
                ...state,
                oil: {
                    ...action.payload.oil,
                },
            }

        case LABOUR_PRICES_LIQUIDS_SET:
            return {
                ...state,
                liquids: {
                    ...action.payload.liquids,
                },
            }

        case LABOUR_PRICES_DISCOUNT_SET:
            return {
                ...state,
                discounts: action.payload.discounts,
            }

        case LABOUR_PRICES_BRAKES_DISCOUNT_SET:
            return {
                ...state,
                discounts: action.payload.discounts,
            }

        case LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_SET:
            return {
                ...state,
                additional_percent_on_works: action.payload.percent,
            }

        default:
            return state
    }
}
