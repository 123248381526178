export const TIRES_AXEL_FRONT = 'front';
export const TIRES_AXEL_REAR = 'rear';

export const TIRES_AXELS = [TIRES_AXEL_FRONT, TIRES_AXEL_REAR];

export const TIRES_TYPE_BRAND_PREMIUM = 'premium';
export const TIRES_TYPE_BRAND_PRICE_QUALITY = 'quality_price';

export const TIRES_TYPES = [TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPE_BRAND_PRICE_QUALITY];

export const TIRES_STEP_AXEL = 1;
export const TIRES_STEP_LIST_PREMIUM = 2;
export const TIRES_STEP_LIST_PRICE_QUALITY = 3;

export const TIRES_STEPS = [TIRES_STEP_AXEL, TIRES_STEP_LIST_PREMIUM, TIRES_STEP_LIST_PRICE_QUALITY];

export const TIRES_STEPS_TO_TYPES = {
    [TIRES_TYPE_BRAND_PREMIUM]: TIRES_STEP_LIST_PREMIUM,
    [TIRES_TYPE_BRAND_PRICE_QUALITY]: TIRES_STEP_LIST_PRICE_QUALITY,
};

export const TIRES_SEASON_SUMMER = 1;
export const TIRES_SEASON_WINTER = 2;
export const TIRES_SEASON_ALL_SEASON = 3;
export const TIRES_SEASON_NORDIC = 4;

export const TIRES_SORT_ASC = 'asc';
export const TIRES_SORT_DESC = 'desc';

export const TIRES_AXEL_RESET_FIELDS_BELLOW = {
    wide: [
        'depth',
        'depth_list',
        'diameter',
        'diameter_list',
    ],
    depth: [
        'diameter',
        'diameter_list',
    ],
};
