import {
    LOADER_API_GET_CAR,
    LOADER_FORM_VEHICLE,
    LOADER_INIT,
    LOADER_LABOUR_PRICES,
    LOADER_LICENCE_PLATE,
    LOADER_LABOURS_PREDEFINED,
    LOADER_DRAFT,
    LOADER_REQUESTS,
    LOADER_NOTIFICATIONS,
} from 'store/consts/loader.constants'

export default {
    [LOADER_INIT]: false,
    [LOADER_FORM_VEHICLE]: {},
    [LOADER_LICENCE_PLATE]: false,
    [LOADER_API_GET_CAR]: false,
    [LOADER_LABOUR_PRICES]: true,
    [LOADER_LABOURS_PREDEFINED]: false,
    [LOADER_DRAFT]: false,
    [LOADER_REQUESTS]: false,
    [LOADER_NOTIFICATIONS]: false,
}
