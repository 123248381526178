export const ALLOWED_IMAGE_TYPES = ['png', 'jpeg', 'jpg']
export const ALLOWED_VIDEO_TYPES = []
export const ALLOWED_AUDIO_TYPES = ['m4a', 'mp3', 'wav', 'aac', 'flac', 'wmv', 'mpeg', 'mpga', 'webm', 'oga', 'mp4']
export const ALLOWED_FILE_TYPES = ['pdf']

export const ALLOWED_TYPES = [
    ...ALLOWED_IMAGE_TYPES,
    ...ALLOWED_FILE_TYPES,
    ...ALLOWED_VIDEO_TYPES,
    ...ALLOWED_AUDIO_TYPES,
]

export const MAXIMUM_FILE_SIZE = 36700160 // 35MB
export const MAXIMUM_FILES_NUMBER = 5
