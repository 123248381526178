import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import {
    CAR_DATA_CHANGE,
    CAR_FETCH_LICENCE_PLATE,
    CAR_FETCH_CAR_NUMBER,
    CAR_MULTIPLE_VEHICLE_CHOOSE,
    carResetFieldsBellow,
    carUpdateField,
    carUpdateObject,
} from 'store/actions/car.actions'
import { STORAGE_OFFER_TOKEN } from 'app/consts/storage.consts'
import { setSessionStorageItem } from 'utils/storage'
import store from 'store/store'
import { carStepSet, carStepSetMessage, carStepUpdate } from 'store/actions/carStep.actions'
import { FORM_VEHICLE_TYPE_MANUAL_1 } from 'app/consts/vehicle'
import { setLoader, setLoaders } from 'store/actions/loader.actions'
import { LOADER_API_GET_CAR, LOADER_FORM_VEHICLE, LOADER_LICENCE_PLATE } from 'store/consts/loader.constants'
// import car from 'store/models/car'
import { updateGarage } from '../../actions/garage.actions'
import { resetToken } from '../../../utils/resetToken'
import { LOADER_API_GET_CAR_NUMBER } from '../../consts/loader.constants'
import {clientSet} from "../../actions/client.actions";

export const CAR_URL = 'garage/add-cost-tool/get-car'
export const CAR_MULTIPLE_VEHICLE_CHOOSE_URL = 'garage/add-cost-tool/multiple-vehicle-choose'
export const CAR_LICENCE_PLATE_URL = 'garage/add-cost-tool/get-licence-plate'

export const carMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case CAR_DATA_CHANGE:
                dispatch(carUpdateField(action.payload.name, action.payload.value))

                if (action.payload.shouldCallAPI === true) {
                    dispatch(setLoader(LOADER_API_GET_CAR, true))

                    if (
                        !(store.getState().car.car_number !== null && store.getState().car.first_registration !== true)
                    ) {
                        dispatch(carResetFieldsBellow(action.payload.name))
                    }

                    dispatch(
                        apiRequest({
                            body: store.getState().car,
                            method: 'POST',
                            url: CAR_URL,
                            signature: CAR_DATA_CHANGE,
                        }),
                    )
                }

                break

            case `${CAR_DATA_CHANGE} ${API_SUCCESS}`:
                setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token)
                dispatch(carUpdateObject(action.payload.data.car))
                dispatch(setLoader(LOADER_API_GET_CAR, false))
                dispatch(setLoaders(LOADER_FORM_VEHICLE, false))

                if (action.payload.data.hasOwnProperty('settings')) {
                    dispatch(updateGarage(action.payload.data.settings))
                }
                break

            case `${CAR_DATA_CHANGE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_API_GET_CAR, false))
                break

            case CAR_FETCH_LICENCE_PLATE:
                resetToken()
                dispatch(setLoader(LOADER_LICENCE_PLATE, true))

                dispatch(
                    apiRequest({
                        body: store.getState().car,
                        method: 'POST',
                        url: CAR_LICENCE_PLATE_URL,
                        signature: CAR_FETCH_LICENCE_PLATE,
                    }),
                )
                break

            case `${CAR_FETCH_LICENCE_PLATE} ${API_SUCCESS}`:
                if (action.payload.data.status === -1) {
                    dispatch(carUpdateField('licence_plate', null))
                    dispatch(carUpdateObject(action.payload.data.car))
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1, true, action.payload.data.message))
                } else {
                    if (action.payload.data.status === 1) {
                        setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token)
                    }
                    dispatch(carUpdateObject(action.payload.data.car))
                    dispatch(carStepUpdate(action.payload.data.car, action.payload.data.message))
                }

                if (action.payload.data.hasOwnProperty('settings')) {
                    dispatch(updateGarage(action.payload.data.settings))
                }

                if (action.payload.data.client) {
                    dispatch(clientSet(action.payload.data.client))
                }

                dispatch(setLoader(LOADER_LICENCE_PLATE, false))
                break

            case `${CAR_FETCH_LICENCE_PLATE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LICENCE_PLATE, false))
                dispatch(carStepSetMessage(action.payload.error.message))

                break

            case CAR_MULTIPLE_VEHICLE_CHOOSE:
                dispatch(setLoader(LOADER_LICENCE_PLATE, true))
                dispatch(
                    apiRequest({
                        body: store.getState().car,
                        method: 'POST',
                        url: CAR_MULTIPLE_VEHICLE_CHOOSE_URL,
                        signature: CAR_MULTIPLE_VEHICLE_CHOOSE,
                    }),
                )
                break

            case `${CAR_MULTIPLE_VEHICLE_CHOOSE} ${API_SUCCESS}`:
                dispatch(setLoader(LOADER_LICENCE_PLATE, false))

                if (action.payload.data.status === -1) {
                    dispatch(carUpdateObject(action.payload.data.car))
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1, true, action.payload.data.message))
                } else {
                    setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token)
                    dispatch(carUpdateObject(action.payload.data.car))
                    dispatch(carStepUpdate(action.payload.data.car, action.payload.data.message))
                }

                if (action.payload.data.hasOwnProperty('settings')) {
                    dispatch(updateGarage(action.payload.data.settings))
                }

                if (action.payload.data.client) {
                    dispatch(clientSet(action.payload.data.client))
                }

                break

            case `${CAR_MULTIPLE_VEHICLE_CHOOSE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LICENCE_PLATE, false))
                dispatch(carStepSetMessage(action.payload.error.message))
                break

            case CAR_FETCH_CAR_NUMBER:
                resetToken()
                dispatch(setLoader(LOADER_API_GET_CAR_NUMBER, true))
                dispatch(
                    apiRequest({
                        body: store.getState().car,
                        method: 'POST',
                        url: CAR_URL,
                        signature: CAR_FETCH_CAR_NUMBER,
                    }),
                )

                break

            case `${CAR_FETCH_CAR_NUMBER} ${API_SUCCESS}`:
                setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token)

                dispatch(carUpdateObject(action.payload.data.car))
                dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1, true, action.payload.data.message))

                dispatch(setLoader(LOADER_API_GET_CAR_NUMBER, false))
                dispatch(setLoaders(LOADER_FORM_VEHICLE, false))
                break

            case `${CAR_FETCH_CAR_NUMBER} ${API_ERROR}`:
                dispatch(setLoader(LOADER_API_GET_CAR_NUMBER, false))
                dispatch(carStepSetMessage(action.payload.error.message))
                break

            default:
                break
        }
    }
}
