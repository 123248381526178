import { NOTIFICATIONS_APPEND, NOTIFICATIONS_SEEN, NOTIFICATIONS_SET } from '../actions/notifications.actions'
import notifications from '../models/notifications'

export default function(state = notifications, action) {
    switch (action.type) {
        case NOTIFICATIONS_SET:
            return {
                ...action.payload.notifications,
                ...{ list: state.list.concat(action.payload.notifications.list) },
            }

        case NOTIFICATIONS_APPEND:
            return {
                ...state,
                data: { ...state.data, ...{ unseen: state.data.unseen + action.payload.notifications.length } },
                ...{ list: [...action.payload.notifications, ...state.list] },
            }

        case NOTIFICATIONS_SEEN:
            let unseen =
                state.data.unseen - action.payload.notifications.length >= 0
                    ? state.data.unseen - action.payload.notifications.length
                    : 0

            return {
                ...state,
                data: { ...state.data, ...{ unseen: unseen } },
                ...{
                    list: state.list.map(notify => {
                        if (action.payload.notifications.indexOf(notify.id) !== -1) {
                            return {
                                ...notify,
                                seen: true,
                            }
                        }

                        return notify
                    }),
                },
            }

        default:
            return state
    }
}
