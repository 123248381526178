import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {URGENCIES} from "store/consts/package/package.urgency.constants";
import InputRadio from "components/shared/InputRadio";
import Button from "components/shared/Button/Button";
import {usePrevious} from "components/hooks/utils";
import {updateObject} from "utils/common";
import {packagesUpdate} from "store/actions/packages.actions";
import {parseDeepPath} from "../../utils/common";
import PackageUrgency from "../../components/shared/PackageUrgency";

export const UrgencyLevel = props => {

    const { t, packages, formSubmitting, packagePath, dispatch, onUpdate, onClose, callPackagesUpdate } = props;

    const [ _package, setPackage ] = useState(props.package);

    const prevPackages = usePrevious(props.packages);

    useEffect(() => {

        if(callPackagesUpdate === false) {
            return;
        }

        if(prevPackages){
            onClose();
        }
    }, [props.packages]);

    let handleUrgencyChange = (e) => {
        const {value} = e.target;

        setPackage({
            ..._package,
            urgency: parseInt(value)
        });
    };

    let submit = (e) => {
        e.preventDefault();

        if(onUpdate){
            onUpdate(_package);
        }

        if(callPackagesUpdate !== false){
            dispatch(packagesUpdate(updateObject(packages, _package, packagePath), parseDeepPath(packagePath)[0].id));
        }
        else {
            onClose();
        }
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>
                <div className="mb-20">
                    {Object.keys(URGENCIES).map((level) => (
                        <div key={level} className="mv-25">
                            <InputRadio
                                key={level}
                                id={level}
                                name={"urgency"}
                                value={level}
                                label={<PackageUrgency urgency={parseInt(level)} inactive={level != _package.urgency}/>}
                                checked={level == _package.urgency}
                                onChange={handleUrgencyChange}
                            />
                        </div>
                    ))}
                </div>

                <Button disabled={formSubmitting.packages && callPackagesUpdate !== false} size="lg" type="primary">{t('pages.price_update.section_form.button_save')}</Button>

            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
        packages: state.packages,
    };
};

export default connect(mapStateToProps)(translate('translations')(UrgencyLevel));
