import Yup from './yup'
import { labourPricesHourlyPricesUpdate } from '../../../store/actions/labourPrices.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            car_make_id: '',
            year: '',
            younger_price: '',
            older_price: '',
            editing: null,
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            car_make_id: Yup.string()
                .required()
                .label(t('validations.label.make')),

            year: Yup.string()
                .required()
                .label(t('validations.label.hourly_price.production_year')),

            younger_price: Yup.number()
                .required()
                .label(t('validations.label.hourly_price.younger_price')),

            older_price: Yup.number().label(t('validations.label.hourly_price.younger_price')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(labourPricesHourlyPricesUpdate(values, values.editing))
    },
}
