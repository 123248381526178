import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import axios from "../../app/config/axios";
import history from "../../routes/history";
import Header from "../../components/layout/Header/Header";
import "./style.scss"
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import CustomerUser from "./CustomerUser/CustomerUser";
import Button from "../../components/shared/Button/Button";
import Popup from "../../components/shared/Popup/Popup";
import {scrollToLastThird} from "../../utils/common";
import Loader, {LOADER_LOADING} from "../../components/shared/Loader/Loader";
import Input from "../../components/shared/Input/Input";
import {debounce} from "lodash";
import {captureException} from "../../utils/captureException";

export const GET_CUSTOMERS = 'garage/customers/list'

const CUSTOMERS_MODEL = {
    data: [],
    meta: {},
};

class Customers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: CUSTOMERS_MODEL,
            user: this.props.user,
            garage: this.props.garage,
            editModal: false,
            customer: null,
            loading: true,
            loadingMore: false,
            search: '',
        }

        this.handleSearchChangeDebounced = debounce((value) => this.handleSearchChange(value), 500)
    }

    componentDidMount() {
        const {t} = this.props

        document.title = t('titles.customers')

        this.fetchCustomers(GET_CUSTOMERS);

        window.addEventListener('scroll', this.scrollEvent)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollEvent)
    }

    scrollEvent = () => {
        if (scrollToLastThird(document.documentElement) && !this.state.loadingMore && this.state.customers.links.next) {
            this.fetchCustomers(this.state.customers.links.next)
        }
    }

    handleSearchChange = (value) => {
        this.setState({
            search: value,
        }, () => {
            if (this.state.search.length >= 2 || this.state.search.length === 0) {
                this.searchCustomer()
            }
        })
    }

    handleSearchClear = () => {
        this.setState({search: ''}, () => this.searchCustomer())
    }

    searchCustomer = () => {
        this.setState({
            loading: true,
            customers: CUSTOMERS_MODEL,
        }, () => this.fetchCustomers(GET_CUSTOMERS))
    }

    fetchCustomers = (link) => {
        const {customers: {data}, search} = this.state

        this.setState({loadingMore: true})

        axios.post(link, {search})
            .then(response => {
                this.setState({
                    customers: {
                        ...response.data,
                        data: [...data, ...response.data.data],
                    },
                    loading: false,
                    loadingMore: false,
                    userDataShow: false
                })
            })
            .catch(error => {
                captureException(error, link);
            })
    }

    handleEditClick = (customer) => {
        this.setState({customer: {...customer}})

        this.setState({editModal: true})
    }

    handleClose = () => this.setState({editModal: false})

    updateCustomer = (customer) => {
        const {customers} = this.state


        this.setState({
            editModal: false,
            customers: {
                ...customers,
                data: customers.data.map(c => c.id === customer.id ? customer : c)
            }
        })
    }

    render() {
        const {loading, loadingMore, garage, customers: {data}, editModal, customer, search} = this.state
        const {t, title} = this.props

        const CustomerDetail = ({customer}) => {

            return (
                <div className="customers__item">
                    {(customer.first_name || customer.last_name) &&
                    <div className="customers__property">
                        <span className="customers__field">{t('pages.customers.name')}: </span>
                        <span
                            className="customers__value">{customer.first_name} {customer.last_name}</span>
                    </div>
                    }
                    {customer.email &&
                    <div className="customers__property">
                        <span className="customers__field">{t('pages.customers.email')}: </span>
                        <span className="customers__value">{customer.email}</span>
                    </div>
                    }
                    {customer.phone &&
                    <div className="customers__property">
                        <span className="customers__field">{t('pages.customers.phone')}: </span>
                        <span className="customers__value">{customer.phone}</span>
                    </div>
                    }
                    {customer.home_phone &&
                    <div className="customers__property">
                        <span className="customers__field">{t('pages.customers.home_phone')}: </span>
                        <span className="customers__value">{customer.home_phone}</span>
                    </div>
                    }
                    {customer.licence_plates.length > 0 &&
                    <div className="customers__property">
                        <span className="customers__field">{t('pages.customers.licence_plates')}: </span>
                        <span className="customers__value">
                                {
                                    customer.licence_plates.map(lp => {
                                        return lp.licence_plate
                                    }).join(', ')
                                }
                            </span>
                    </div>
                    }
                    <div className="customers__edit">
                        <span className="link-style edit-link" onClick={(e) => this.handleEditClick(customer)}>
                            <i className="icon-edit"/> {t('global.edit')}
                        </span>
                    </div>
                </div>
            )
        }

        const CustomerList = ({customers}) => {
            if (customers.length === 0) {
                return (
                    <div>
                        <div className="customers__property">{t('pages.customers.no_results')}</div>
                    </div>
                )
            }

            return (
                <div className="customers__list">
                    {customers.map(customer => (
                        <CustomerDetail key={customer.id} customer={customer}/>
                    ))}

                    {loadingMore &&
                        <div className="mv-30 text-light text-center"><Loader type={LOADER_LOADING} isLoading={true}/> {t('global.loading')}</div>
                    }
                </div>
            )
        }

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container customers">

                    {editModal &&
                    <Popup
                        title={t('pages.customers.edit_title')}
                        visible={editModal}
                        onClose={this.handleClose}
                    >
                        <div className="customers__edit-modal-content">
                            <CustomerUser
                                client={customer}
                                garage={garage}
                                handleClose={() => this.handleClose()}
                                onUpdate={this.updateCustomer}
                            />
                        </div>
                    </Popup>
                    }

                    {garage.allow_upload_customer ?
                        <Button size="md" type="primary" extraClass={"btn-block"}
                                onClick={() => history.push('/customers/upload')}>
                            <div className="font-size-2">{t('pages.customers.customers_upload')}</div>
                        </Button>
                        :
                        ''
                    }

                    <Input
                        type="text"
                        placeholder={t('pages.customers.search')}
                        name="search"
                        value={search}
                        onChange={(e) => this.handleSearchChangeDebounced(e.target.value)}
                        onTextClear={this.handleSearchClear}
                        iconRightClassName={'icon-cancel'}
                        iconLeftClassName={'icon-search'}
                    />

                    {loading ? <ListLoader/> :
                        <CustomerList customers={data}/>
                    }

                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: state.garage,
    }
}

export default connect(mapStateToProps)(translate('translations')(Customers))
