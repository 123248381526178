export const MECHANIC = '[Mechanic]';

//garage/add-cost-tool/mechanics/get/insert/update/delete
export const MECHANIC_API_URL = 'garage/mechanics';

export const MECHANIC_API_URL_INSERT = `${MECHANIC_API_URL}/insert`;
export const MECHANIC_API_URL_GET = `${MECHANIC_API_URL}/get`;
export const MECHANIC_API_URL_DELETE = `${MECHANIC_API_URL}/delete`;
export const MECHANIC_API_URL_UPDATE = `${MECHANIC_API_URL}/update`;

// api
export const MECHANIC_API = '/mechanic';
export const MECHANIC_API_FETCH  = `${MECHANIC} API FETCH`;
export const MECHANIC_API_CREATE = `${MECHANIC} API CREATE`;
export const MECHANIC_API_REMOVE = `${MECHANIC} API REMOVE`;
export const MECHANIC_API_UPDATE = `${MECHANIC} API UPDATE`;

// store
export const MECHANIC_FETCH_ALL = `${MECHANIC} FETCH ALL`;
export const MECHANIC_LIST_SET  = `${MECHANIC} LIST SET`;
export const MECHANIC_REMOVE    = `${MECHANIC} REMOVE`;
export const MECHANIC_UPDATE_ONE    = `${MECHANIC} UPDATE ONE`;
export const MECHANIC_ADD       = `${MECHANIC} ADD`;

export const mechanicApiCreate = person => ({
    type: MECHANIC_API_CREATE,
    payload: {
        person
    }
});

export const mechanicApiFetch = () => ({
    type: MECHANIC_API_FETCH
});

export const mechanicApiRemove = id => ({
    type: MECHANIC_API_REMOVE,
    payload: {
        id
    }
});

export const mechanicApiUpdate = (person) => ({
    type: MECHANIC_API_UPDATE,
    payload: {
        person
    }
})


// store
export const add = (person) => ({
    type: MECHANIC_ADD,
    payload: {
        person
    }
});

export const set = (list) => ({
    type: MECHANIC_LIST_SET,
    payload: {
        list
    }
});

export const remove = id => ({
    type: MECHANIC_REMOVE,
    payload: {
        id
    }
});

export const updateOne = person=> ({
    type: MECHANIC_UPDATE_ONE,
    payload: {
        person
    }
});







