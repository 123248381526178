import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Button from "components/shared/Button/Button";
import {usePrevious} from "components/hooks/utils";
import {updateObject} from "utils/common";
import {packagesUpdate} from "store/actions/packages.actions";
import {deepObject} from "utils/common";
import Input from "../../components/shared/Input/Input";
import {withFormik} from "formik";
import {formDiscount} from "app/config/yup";
import {parseDeepPath} from "../../utils/common";
import BaseCountry from "../../utils/BaseCountry";

export const Discount = props => {

    const { t, packages, formSubmitting, packagePath, dispatch, handleChange, handleBlur, touched, errors, isValid, onClose } = props;

    const [ discount, setDiscount ] = useState(props.discount);

    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if(prevPackages){
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        if(isValid){
            dispatch(packagesUpdate(updateObject(packages, {...deepObject(packages, packagePath), ...{discount: discount}}, packagePath), parseDeepPath(packagePath)[0].id));
        }
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>
                <div className="mb-20">

                    <Input
                        type="tel"
                        placeholder={t('pages.package_details.discount.section_form.placeholder.price')}
                        name="price"
                        label={t('pages.package_details.discount.section_form.label.price', {currency: BaseCountry().currency})}
                        value={discount.price}
                        onBlur={handleBlur}
                        onChange={e => {setDiscount({...discount, price: parseFloat(e.target.value)}); handleChange(e)}}
                        error={touched.price && errors.price}
                        float={true}
                    />

                    <Input
                        type="text"
                        placeholder={t('pages.package_details.discount.section_form.placeholder.name')}
                        name="name"
                        label={t('pages.package_details.discount.section_form.label.name')}
                        value={discount.name}
                        onBlur={handleBlur}
                        onChange={e => {setDiscount({...discount, name: e.target.value}); handleChange(e)}}
                        error={touched.name && errors.name}
                    />

                </div>

                <Button disabled={formSubmitting.packages} size="lg" type="primary">{t('pages.price_update.section_form.button_save')}</Button>

            </form>
        </React.Fragment>
    );
};

const DiscountForm = withFormik(formDiscount)(Discount);

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
        packages: state.packages,
    };
};

export default connect(mapStateToProps)(translate('translations')(DiscountForm));
