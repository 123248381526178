import axios from "../../app/config/axios";

export const TIRES = '[Tires]'

export const TIRES_UPDATE = `${TIRES} UPDATE`;
export const TIRES_SET = `${TIRES} SET`;
export const TIRES_STEP_UPDATE = `${TIRES} STEP UPDATE`;
export const TIRES_AXEL_SAME_DIMENSION_SET = `${TIRES} AXEL SAME DIMENSION SET`;
export const TIRES_AXEL_DIMENSION_UPDATE = `${TIRES} AXEL DIMENSION UPDATE`;
export const TIRES_AXEL_DIMENSION_RESET = `${TIRES} AXEL DIMENSION RESET`;
export const TIRES_TIRE_SELECT = `${TIRES} TIRE SELECT`;
export const TIRES_LOADING_UPDATE = `${TIRES} LOADING UPDATE`;
export const TIRES_EDIT_PREPARE = `${TIRES} EDIT PREPARE`;
export const TIRES_RESET_SELECTED_TIRES = `${TIRES} RESET SELECTED TIRES`;
export const TIRES_ADDITIONAL_SELECT = `${TIRES} ADDITIONAL SELECT`;
export const TIRES_ADDITIONAL_SELECT_LABOUR = `${TIRES} ADDITIONAL SELECT LABOUR`;
export const TIRES_SORT_UPDATE = `${TIRES} SORT UPDATE`;
export const TIRES_RESET = `${TIRES} RESET`;

const TIRES_GET_URL = 'garage/tires/get'

export const tiresUpdate = tires => ({
    type: TIRES_UPDATE,
    payload: {
        tires
    }
});

export const tiresSet = (path, value) => ({
    type: TIRES_SET,
    payload: {
        path, value
    }
});

export const tiresStepUpdate = (step, resetFilter = false) => ({
    type: TIRES_STEP_UPDATE,
    payload: {
        step, resetFilter
    }
});

export const tiresAxelSameDimensionSet = () => ({
    type: TIRES_AXEL_SAME_DIMENSION_SET,
});

export const tiresAxelDimensionUpdate = (axel, name, value) => ({
    type: TIRES_AXEL_DIMENSION_UPDATE,
    payload: {
        axel, name, value
    }
});

export const tiresAxelDimensionReset = (axel) => ({
    type: TIRES_AXEL_DIMENSION_RESET,
    payload: {
        axel
    }
});

export const tiresSelectTire = (tire, brandType, axels) => ({
    type: TIRES_TIRE_SELECT,
    payload: {
        tire, brandType, axels
    }
});

export const tiresLoadingUpdate = loading => ({
    type: TIRES_LOADING_UPDATE,
    payload: {
        loading,
    }
});

export const tiresEditPrepare = tires => ({
    type: TIRES_EDIT_PREPARE,
    payload: {
        tires,
    }
});

export const tiresResetSelectedTires = (brandType) => dispatch => {
    dispatch({
        type: TIRES_RESET_SELECTED_TIRES,
        payload: {
            brandType,
        }
    });

    return Promise.resolve();
};

export const tiresAdditionalSelect = (name, checked) => ({
    type: TIRES_ADDITIONAL_SELECT,
    payload: {
        name, checked
    }
});

export const tiresAdditionalSelectLabour = (name, id) => ({
    type: TIRES_ADDITIONAL_SELECT_LABOUR,
    payload: {
        name, id
    }
});

export const tiresSortUpdate = (column) => ({
    type: TIRES_SORT_UPDATE,
    payload: {
        column
    }
});

export const tiresReset = () => ({
    type: TIRES_RESET,
});

export const tiresGet = () => {
    return function (dispatch, getState) {

        dispatch(tiresLoadingUpdate(true));

        return new Promise((resolve, reject) => {
            axios.post(TIRES_GET_URL, {tires: getState().tires})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
