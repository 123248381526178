import {scroller as scrollerLib} from "react-scroll/modules";

const HEADER_OFFSET = 50;

export const scroller = (element, data) => {

    scrollerLib.scrollTo(element, {...data, offset: -HEADER_OFFSET});

};

export default scroller;