import { LOADER_SET, LOADERS_SET } from 'store/actions/loader.actions';
import loader from 'store/models/loader';

export default function(state = loader, action) {
    switch (action.type) {
        case LOADER_SET:
            return {
                ...state,
                [action.payload.loaderName]: action.payload.loaderState
            };

        case LOADERS_SET:
            if (!action.payload.loaderParentState) {
                return {
                    ...state,
                    [action.payload.loaderParentName]: {}
                };
            }

            return {
                ...state,
                [action.payload.loaderParentName]: action.payload.loaderChildren
            };

        default:
            return state;
    }
}
