import React from 'react'
import './index.scss'
import { translate } from 'react-i18next'
import {
    STATUS_REJECTED,
    STATUS_ACCEPTED,
    STATUS,
} from '../../../store/consts/package/package.status.constants'

const PackageStatus = ({ status, t }) => {
    if (!status) {
        return null
    }

    if (status === STATUS_REJECTED) {
        return (
            <div className="brand-danger font-weight-semi-bold">
                <i className="icon-cancell-fill" /> {STATUS[STATUS_REJECTED]}
            </div>
        )
    }

    if (status === STATUS_ACCEPTED) {
        return (
            <div className="brand-success font-weight-semi-bold">
                <i className="icon-check-fill" /> {STATUS[STATUS_ACCEPTED]}
            </div>
        )
    }

    return null
}

export default translate('translations')(PackageStatus)
