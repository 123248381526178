export const CHANGE_USER_DETAILS_SETTING = '[CHANGE USER DETAILS SETTING]'

export const CHANGE_USER_DETAILS_SETTING_UPDATE = `${CHANGE_USER_DETAILS_SETTING} UPDATE`

export const changeUserDetailsSettingUpdate = (first_name, last_name, phone, email, avatar, username) => ({
    type: CHANGE_USER_DETAILS_SETTING_UPDATE,
    payload: {
        first_name,
        last_name,
        phone,
        email,
        avatar,
        username,
    },
})
