export default {
    itemMpId: null,
    korId: 0,
    name: '',
    works: [],
    custom: false,
    visible: true,
    parentId: null,
    priceFixed: 0,
    allowWorks: true,
}
