import React from 'react';
import {toast} from "react-toastify";
import './Undo.scss'
import {translate} from "react-i18next";
import Toast from "../../shared/Toast/Toast";

const TIME_TO_UNDO = 50000

export const Undo = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                undoable: []
            }
        }

        undoable = (name, undo, doIt) => {
            if(this.state.undoable[name]){
                clearTimeout(this.state.undoable[name].time)
            }

            this.setState({
                undoable: {
                    [name]: {
                        list: [...(this.state.undoable[name] ? this.state.undoable[name].list : []), {
                                undo: undo,
                                doIt: doIt,
                            }
                        ],
                        time: setTimeout(() => {
                            this.doIt(name)
                        }, TIME_TO_UNDO),
                        toastId: this.state.undoable[name] ? this.state.undoable[name].toastId : null
                    }
                }
            }, () => {
                if(this.state.undoable[name].toastId){
                    toast.update(this.state.undoable[name].toastId, {
                        render: this.renderUndo(name),
                        autoClose: TIME_TO_UNDO,
                        closeButton: false,
                        onClose: () => this.doIt(name)
                    })
                }
                else{
                    let toastId = toast.success(<Toast text={this.renderUndo(name)} type="success"/>, {
                        autoClose: TIME_TO_UNDO,
                        closeButton: false,
                        onClose: () => this.doIt(name)
                    })

                    this.setState({
                        undoable: {
                            [name]: {...this.state.undoable[name],
                                toastId: toastId
                            }
                        }
                    })
                }
            })
        }

        renderUndo = (name) => {
            const {t} = this.props

            return (
              <div className="undo-message">
                  <span className="undo-message__text">{t('global.undo_deleted', {num: this.state.undoable[name].list.length})}</span>
                  <span className="undo-message__undo link-style font-weight-semi-bold" onClick={() => this.undo(name)}>
                      {t('global.undo')}</span>
              </div>
            )
        }

        undo = (name) => {
            if(!this.state.undoable[name]){
                return
            }

            clearTimeout(this.state.undoable[name].time)
            this.state.undoable[name].list.reverse().forEach(undoable => {
                undoable.undo()
            })

            this.restart(name)
        }

        doIt = (name) => {
            if(!this.state.undoable[name]){
                return
            }

            clearTimeout(this.state.undoable[name].time)
            this.state.undoable[name].list.forEach(it => {
                it.doIt()
            })

            this.restart(name)
        }

        restart = (name) => {
            this.setState({
                undoable: {
                    [name]: null
                }
            })
        }

        render() {
            return <Component {...this.props} undo={this}/>;
        }
    }
};

export default translate('translations')(Undo);
