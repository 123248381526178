import Yup from './yup'
import { changePasswordSettingUpdate } from '../../../store/actions/change-password.settings.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            old_password: '',
            password: '',
            password_confirmation: '',
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            old_password: Yup.string()
                .required()
                .label(t('pages.user_profile.section_form.label.current_password')),

            password: Yup.string()
                .required()
                .label(t('pages.user_profile.section_form.label.new_password')),

            password_confirmation: Yup.string()
                .required()
                .oneOf([Yup.ref('password'), null], t('validations.label.password_match'))
                .label(t('pages.user_profile.section_form.label.repeat_password')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(changePasswordSettingUpdate(values.old_password, values.password, values.password_confirmation))
    },
}
