export const OFFICIAL_SERVICE_PREV = 0;
export const OFFICIAL_SERVICE_NEXT = 1;

export const OFFICIAL_SERVICE_SELECTED = {
    [OFFICIAL_SERVICE_PREV]: 'prev',
    [OFFICIAL_SERVICE_NEXT]: 'next',
};

export default {
    OFFICIAL_SERVICE_SELECTED,
    OFFICIAL_SERVICE_PREV,
    OFFICIAL_SERVICE_NEXT,
}
