import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import InputRadio from "components/shared/InputRadio";
import {languageSettingUpdate} from "store/actions/language.settings.actions";
import {compare} from "utils/common";

class Language extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: null
        }
    }

    componentDidMount() {
        const userLocale = this.props.user.locale;
        this.props.languages.map(lang => {
            if (lang.locale === userLocale) {
                this.setState({
                    lang: lang.id
                })
            }
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const userLocale = this.props.user.locale;

        if (compare(nextProps.languages, this.props.languages)) {
            nextProps.languages.map(lang => {
                if (lang.locale === userLocale) {
                    this.setState({
                        lang: lang.id
                    })
                }
            })
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        if (e.target instanceof HTMLInputElement) {
            if (e.target.type === 'radio') {
                this.setState({
                    [name]: value
                });

                this.props.dispatch(languageSettingUpdate(value))
            }
        }
    };

    render() {
        const {t, languages} = this.props;

        return (
            <React.Fragment>

                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.user_profile.section_tabs.default_language')}</div>
                    </div>
                    <div className="settings-tab__content">

                        {languages.map((lang, key) => (
                            <div className="mb-20" key={key}>
                                <InputRadio
                                    key={key}
                                    id={lang.locale}
                                    name={"lang"}
                                    value={lang.id}
                                    label={t(lang.name)}
                                    checked={parseInt(this.state.lang) === parseInt(lang.id)}
                                    onChange={this.handleChange}
                                />
                            </div>
                        ))}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        user: state.user
    };
};

export default connect(mapStateToProps)(translate('translations')(Language));
