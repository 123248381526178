import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import ChecklistLabour, {CHECKLIST_OK} from "../../components/ChecklistLabour/ChecklistLabour";
import './ChecklistLabourContainer.scss';
import PackagesStep from "../../routes/middleware/Packages/PackagesStep";
import HasOfferToken from "../../routes/middleware/HasOfferToken";
import {checklistLaboursSet, checklistLaboursUpdate} from "../../store/actions/checklistLabours.actions";
import Button from "../../components/shared/Button/Button";
import Confirm from "../../components/shared/Confirm/Confirm";
import history from "../../routes/history";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {__clone, parseResponse, toBool} from "../../utils/common";
import {packageRemove} from "../../store/actions/packages.actions";
import axios from "../../app/config/axios";
import pages from "../../app/consts/routes";
import ChecklistAllowed from "../../routes/middleware/Checklist/ChecklistAllowed";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import {storeReset} from "../../store/actions/store.actions";
import {removeSessionStorageItem} from "../../utils/storage";
import {STORAGE_OFFER_TOKEN} from "../../app/consts/storage.consts";
import {CHECKLIST_LABOUR_TYPE_SERVICE, checklistTypesToArchive} from "../../store/consts/checklistLabour.constants";
import {captureException} from "../../utils/captureException";
import {selectChecklistLabours} from "../../store/selectors/checklistLabours";

import {
    MASTERCHECK_LAYOUT_CHECKBOXES,
    MASTERCHECK_LAYOUT_TOGGLE
} from "../../components/ChecklistLabour/components/ChecklistLayout"

const API_CHECKLIST_LABOUR_UPDATE_LIST_URL = 'garage/add-cost-tool/checklist-labour/update-list';
const API_ARCHIVE_OFFER_URL = 'garage/add-cost-tool/checklist-labour/archive';

const TYPE_CONTINUE = 1;
const TYPE_ARCHIVE = 2;
const TYPE_ADD_NEW_LABOUR = 3;
const TYPE_INITIAL_STATE = 4;

class ChecklistLabourContainer extends Component {

    state = {
        confirmLeave: false,
        updating: null
    }

    componentDidMount(){
        this.setInitialValue();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setInitialValue();
    }

    setInitialValue = () => {
        if(this.props.garage.mastercheck_layout === MASTERCHECK_LAYOUT_TOGGLE){
            let updated = false
            const checklistLabours = this.props.checklistLabours.map(labour => {
                if(labour.passed === null){
                    labour.passed = true;
                    updated = true;
                }
                return labour
            });
            if(updated){
                this.setState({updating: true})
                this.updateChecklist(checklistLabours, TYPE_INITIAL_STATE)
            }
        }
    }

    submit = (type) => {
        const {dispatch, checklistLabours, packages, officialServices} = this.props;

        if(this.validate()) return;

        this.setState({updating: type})

        let packagesToRemove = [];

        let list = __clone(checklistLabours).map(labour => {
            if(labour.passed === CHECKLIST_OK && labour.package_id !== null){

                if(packages.find(p => p.id === labour.package_id)){
                    packagesToRemove.push(labour.package_id);
                }

                labour.package_id = null;
            }
            else if (labour.package_id !== null && !packages.find(p => p.id === labour.package_id)) {

                if(labour.type === CHECKLIST_LABOUR_TYPE_SERVICE){
                    if(officialServices.exist === true || officialServices.main.length > 0 || officialServices.additional.length > 0){
                        labour.passed = CHECKLIST_OK;
                    }
                }
                else {
                    labour.passed = CHECKLIST_OK;
                }

                labour.package_id = null;
            }

            return labour;
        })

        if(packagesToRemove.length){
            Promise.all(packagesToRemove.map(id => dispatch(packageRemove(id, true))))
            .then(() => {
                this.updateChecklist(list, type)
            })
            .catch(this.onError)
        }
        else{
            this.updateChecklist(list, type)
        }
    }

    archive = () => {
        const {client, dispatch} = this.props;

        this.setState({updating: true})

        axios.post(API_ARCHIVE_OFFER_URL, {user: client})
            .then(response => {

                dispatch(storeReset());
                removeSessionStorageItem(STORAGE_OFFER_TOKEN);

                history.push(`${pages.requests}?tab=3`)
            })
            .catch(this.onError)
    }

    updateChecklist = (checklistLabours, type) => {
        const {dispatch} = this.props;

        axios.post(API_CHECKLIST_LABOUR_UPDATE_LIST_URL, {checklist_labours: checklistLabours})
            .then(response => {
                if(type === TYPE_ARCHIVE){
                    this.archive();
                }
                else if(type === TYPE_ADD_NEW_LABOUR){
                    dispatch(checklistLaboursSet(response.data))
                    history.push(`${pages.packages}${pages.labour_search}`)
                }else if(type === TYPE_INITIAL_STATE){
                    dispatch(checklistLaboursSet(response.data))
                    this.setState({updating: false})
                }
                else {
                    dispatch(checklistLaboursSet(response.data))
                    history.push(pages.packages)
                }
            })
            .catch(this.onError)
    }

    validate = () => toBool(this.props.checklistLabours.find(labour => labour.passed === null || labour.updating === true))

    hasAllPassed = () => !this.props.checklistLabours
        .filter(labour => checklistTypesToArchive(labour.type))
        .filter(labour => {
            return !(labour.type === CHECKLIST_LABOUR_TYPE_SERVICE && labour.package_id !== null && !this.props.packages.find(p => p.id === labour.package_id) &&
                this.props.officialServices.exist === false && this.props.officialServices.main.length === 0 && this.props.officialServices.additional.length === 0);
        })
        .find(labour => (labour.passed !== true))

    canArchive = () => this.hasAllPassed();

    canContinue = () => !this.hasAllPassed() || this.props.garage.allow_labour === true || this.props.garage.allow_official_service === true;

    canAddNewLabour = () => this.props.garage.allow_labour === true;

    onError = (error) => {
        captureException(error);
        toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
        this.setState({updating: null})
    }

    onBack = () => {
        if (this.validate()) {
            this.setState({
                confirmLeave: true
            })
        } else {
            history.goBack()
        }
    }
    checkboxHeader = () => {
        const {t, garage} = this.props;
        if(garage.mastercheck_layout === MASTERCHECK_LAYOUT_CHECKBOXES){
            return <div className="checklist-labour-header">
                <div className="col-xs-push-8 col-xs-1">{t('pages.checklist_labours.ok')}</div>
                <div className="col-xs-push-8 col-xs-1">{t('pages.checklist_labours.not')} {t('pages.checklist_labours.ok')}</div>
            </div>
        }
        return <></>
    }

    render() {
        const {confirmLeave, updating} = this.state;
        const {t, checklistLabours, dispatch} = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} onBack={this.onBack}/>

                {checklistLabours.length === 0 ? <ListLoader/> :
                    <div className="container container--has-submit-container">
                        <div className="checklist-labour">
                            {this.checkboxHeader()}

                            {checklistLabours.map(item => {
                                return (
                                    <ChecklistLabour
                                        key={item.id}
                                        labour={item}
                                        siblings={item.siblings.map(sibling => checklistLabours.find(labour => labour.id === sibling))}
                                        checklistLaboursUpdate={() => dispatch(checklistLaboursUpdate)}
                                    />
                                )
                            })}
                        </div>

                        {this.canAddNewLabour() &&
                            <div className="row">
                                <div className="col-xs-6 col-xs-push-3 ">
                                    <Button size="md" type="primary"
                                            extraClass="mb-15 btn-primary--outline btn-round--sm btn-shadow btn-block font-size-default"
                                            onClick={() => this.submit(TYPE_ADD_NEW_LABOUR)}
                                            disabled={updating || this.validate() || !this.canAddNewLabour()}
                                            loading={updating === TYPE_ADD_NEW_LABOUR}>
                                        {t('pages.checklist_labours.add_new_labour')}
                                    </Button>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-xs-6">
                                <Button size="lg" type="primary" onClick={() => this.submit(TYPE_ARCHIVE)}
                                        disabled={updating || this.validate() || !this.canArchive()}
                                        loading={updating === TYPE_ARCHIVE} icon="icon-file">
                                    {t('pages.checklist_labours.archive')}
                                </Button>
                            </div>
                            <div className="col-xs-6">
                                <Button size="lg" type="primary" onClick={() => this.submit(TYPE_CONTINUE)}
                                        disabled={updating || this.validate() || !this.canContinue()}
                                        loading={updating === TYPE_CONTINUE} icon="icon-chevron-right">
                                    {t('pages.checklist_labours.save')}
                                </Button>
                            </div>
                        </div>

                    </div>
                }

                {confirmLeave &&
                    <Confirm
                        title={t('pages.checklist_labours.on_leave')}
                        visible={!!confirmLeave}
                        accept={() => history.goBack()}
                        cancel={() => this.setState({confirmLeave: false})}
                    />
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        checklistLabours: selectChecklistLabours(state),
        officialServices: state.officialServices,
        packages: state.packages,
        client: state.client,
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(ChecklistAllowed(PackagesStep(HasOfferToken(translate('translations')(ChecklistLabourContainer)))));
