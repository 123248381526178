import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from "formik";
import {formLiquidsPrices} from "app/config/yup";
import Header from "components/layout/Header/Header";
import Button from "../../components/shared/Button/Button";
import {
    labourPricesLiquidsFetch
} from "store/actions/labourPrices.actions";
import Input from "components/shared/Input/Input";
import {arrayColumn} from "utils/common";
import {LOADER_LABOUR_PRICES} from "store/consts/loader.constants";
import ListLoader from "components/shared/ListLoader/ListLoader";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class LiquidsPriceUpdateContainer extends Component {

    componentDidMount() {
        this.props.dispatch(labourPricesLiquidsFetch());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.liquids.prices.length !== nextProps.liquids.prices.length){
            this.props.setValues(arrayColumn(nextProps.liquids.prices, 'price', 'input_name'));
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    render() {
        const { t, liquids: {prices}, handleBlur, handleSubmit, formSubmitting, loader, touched, errors } = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} />

                <div className="container">

                    {loader[LOADER_LABOUR_PRICES] ?
                        <ListLoader /> :

                        <form onSubmit={e => handleSubmit(e)}>
                            <div className="row">
                            {prices.map((liquid, key) => {
                                return (
                                    <div className="col-xs-12 col-sm-6">
                                        <Input
                                            type="tel"
                                            placeholder={t('pages.price_update.section_form.placeholder_younger_price')}
                                            name={liquid.input_name}
                                            label={liquid.name}
                                            onBlur={handleBlur}
                                            onChange={this.handleTextChange}
                                            value={liquid.price}
                                            addOn={liquid.price_type}
                                            error={touched[liquid.input_name] && errors[liquid.input_name]}
                                            step={0.000001}
                                            key={key}
                                            float={true}
                                        />
                                    </div>
                                );

                            })}
                            </div>
                            <SubmitContainer stat={true}>
                                <div className="services-save-footer">
                                    <Button size="lg" type="primary" disabled={formSubmitting.liquidsPrice}>
                                        {t('pages.price_update.section_form.button_save')}
                                    </Button>
                                </div>
                            </SubmitContainer>
                        </form>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const PriceUpdateForm = withFormik(formLiquidsPrices)(LiquidsPriceUpdateContainer);

const mapStateToProps = (state) => {
    return {
        liquids: state.labourPrices.liquids,
        formSubmitting: state.formSubmitting,
        loader: state.loader
    };
};

export default connect(mapStateToProps)(translate('translations')(PriceUpdateForm));
