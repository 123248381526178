import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Input from "components/shared/Input/Input";
import {withFormik} from "formik";
import {formLogin} from "app/config/yup";
import pages from "app/consts/routes";
import Button from "components/shared/Button/Button";
import logo from '../../assets/logo-blue.png';
import {Link} from "react-router-dom";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import Select from "../../components/shared/Select/Select";
import {setLocalStorageItem} from "../../utils/storage";
import {STORAGE_LANGUAGE} from "../../app/consts/storage.consts";
import language from "../../utils/language";
import languages from 'store/models/languages';
import {urlSearchParams} from "../../utils/common";
import ChangeLanguage from "../../utils/ChangeLanguage";

class LoginContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            languages: languages.map(lang => {
                return {...lang, name: lang.locale.toUpperCase()}
            }),
            language: languages.find(lang => lang.locale === language()).id,
        }
    }

    componentDidMount() {
        if(urlSearchParams('lang')){
            this.updateLanguage(languages.find(lang => lang.locale === urlSearchParams('lang')));
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    updateLanguage = (locale) => {
        if(!locale) return;

        setLocalStorageItem(STORAGE_LANGUAGE, locale.locale)

        ChangeLanguage()

        this.setState({
            language: locale.id
        })
    }

    render() {
        const { language, languages } = this.state;
        const { touched, values, t, errors, handleBlur, handleSubmit, formSubmitting } = this.props;

        return (
            <React.Fragment>
                <div className="auth-logo"><img src={logo} alt="Logo"/></div>

                <div className="container container--has-submit-container">

                    <form onSubmit={e => handleSubmit(e)}>

                        <div className="row">
                            <div className="col-sm-push-3 col-sm-6 col-xs-12">
                                <Input
                                    type="text"
                                    placeholder={t('form.placeholder.username')}
                                    name="username"
                                    label={t('form.label.username')}
                                    value={values.username}
                                    onBlur={handleBlur}
                                    onChange={this.handleTextChange}
                                    error={touched.username && errors.username}
                                />

                                <Input
                                    type="password"
                                    placeholder={t('form.placeholder.password')}
                                    name="password"
                                    label={t('form.label.password')}
                                    value={values.password}
                                    onBlur={handleBlur}
                                    onChange={this.handleTextChange}
                                    error={touched.password && errors.password}
                                />

                                <div className="row">
                                    <div className="col-xs-9">
                                        <Link className="btn-block font-size-default mt-20" to={pages.forgetPassword}>{t('pages.login.forget_password')}</Link>
                                    </div>
                                    <div className="col-xs-3">
                                        <Select
                                            id="languages"
                                            list={languages}
                                            selected={language}
                                            extendClass={'dd-select--sm'}
                                            onChange={(id, option) => this.updateLanguage(option)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SubmitContainer columns={"col-sm-push-3 col-sm-6 col-xs-12"}>
                            <Button size="lg" type="primary" disabled={formSubmitting.login}>{t('pages.login.submit')}</Button>
                        </SubmitContainer>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

const LoginForm = withFormik(formLogin)(LoginContainer);

const mapStateToProps = state => {
    return {
        formSubmitting: state.formSubmitting
    };
};

export default connect(mapStateToProps)(translate('translations')(LoginForm));
