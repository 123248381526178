export const TYPE_REGULAR = 1
export const TYPE_OFFICIAL = 2
export const TYPE_TIRES = 3

export const TYPE_TO_APPEND_LABOUR = [TYPE_REGULAR]

export const TYPE_TO_SHOW_MISSING_DATA = [TYPE_REGULAR]

export const TYPE_TO_DELETE_ALL = [TYPE_OFFICIAL, TYPE_TIRES]

export const TYPE_TO_EDIT_WORKS = [TYPE_REGULAR]

export const TYPE_TO_SELECT_WORKS = [TYPE_REGULAR]

export const TYPE_TO_ADD_WORKS = [TYPE_REGULAR]

export const TYPE_TO_UPDATE_PACKAGE = [TYPE_REGULAR]

export const isPackageTypeRegular = type => type === TYPE_REGULAR;
export const isPackageTypeOfficial = type => type === TYPE_OFFICIAL;
export const isPackageTypeTires = type => type === TYPE_TIRES;

export default {
    TYPE_REGULAR,
    TYPE_OFFICIAL,
    TYPE_TO_APPEND_LABOUR,
    TYPE_TO_SHOW_MISSING_DATA,
    TYPE_TO_EDIT_WORKS,
    TYPE_TO_SELECT_WORKS,
    TYPE_TO_ADD_WORKS,
}
