import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import "./TiresImportContainer.scss";
import {connect} from "react-redux";
import {userTiresSettingsUpdate} from "../../store/actions/user.actions";
import Button from "../../components/shared/Button/Button";

const TIRES_IMPORT_URL = 'garage/tires/import'

class TiresImportContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            uploading: false,
        }
    }

    componentDidMount() {
        const {t, user, garage, dispatch} = this.props;

        window.Echo.private(`user-repair-shop.${user.id}`)
            .listen(`.tires.import.started.${garage.concept.id}`, data => {
                dispatch(userTiresSettingsUpdate({import: {in_progress: data.in_progress}}))
                toast.info(<Toast text={t('pages.tires_import.import_started')} type="info"/>)
            })
            .listen(`.tires.import.success.${garage.concept.id}`, data => {
                dispatch(userTiresSettingsUpdate({import: {in_progress: data.in_progress}}))
                toast.success(<Toast text={t('pages.tires_import.import_success')} type="success"/>)
            })
            .listen(`.tires.import.failed.${garage.concept.id}`, data => {
                dispatch(userTiresSettingsUpdate({import: {in_progress: data.in_progress}}))
                toast.error(<Toast text={t('pages.tires_import.import_failed')} type="error"/>)
            })

    }

    onChange = (e) => {
        this.setState({file: e.target.files[0]})

        e.target.value = null;
    }

    fetch = () => {
        const {file} = this.state;

        const formData = new FormData();
        formData.append('file', file)

        this.setState({uploading: true})

        axios
            .post(TIRES_IMPORT_URL, formData, {headers: {'content-type': 'multipart/form-data'}})
            .catch(error => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
            .finally(() => this.setState({uploading: false, file: null}))
    }

    render() {
        const {t, title, user} = this.props;
        const {file, uploading} = this.state;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">

                    <div className="tires-import">

                        <div>
                            <div className={`file-upload ${user.settings.tires.import.in_progress ? 'disabled' : ''}`}>
                                <label htmlFor="import">
                                    {user.settings.tires.import.in_progress ?
                                        <div>{t('pages.tires_import.importing_in_progress')}</div>
                                        :
                                        file ? file.name : t('pages.tires_import.label')
                                    }
                                </label>
                                <input type="file" name="import" id="import" disabled={uploading || user.settings.tires.import.in_progress}
                                       onChange={this.onChange}/>
                            </div>
                            {!user.settings.tires.import.in_progress &&
                            <Button size="lg" type="primary" disabled={uploading || !file} loading={uploading}
                                    onClick={this.fetch}>{t('pages.tires_import.btn')}</Button>
                            }
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(TiresImportContainer));
