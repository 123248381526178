import pages from 'app/consts/routes'
import HomeContainer from 'views/HomeContainer/HomeContainer'
import PackagesContainer from 'views/PackagesContainer/PackagesContainer'
import AddCostContainer from 'views/AddCostContainer/AddCostContainer'
import PriceUpdateContainer from 'views/PriceUpdateContainer/PriceUpdateContainer'
import HourlyPriceUpdateContainer from 'views/PriceUpdateContainer/HourlyPriceUpdateContainer'
import OilPriceUpdateContainer from 'views/PriceUpdateContainer/OilPriceUpdateContainer'
import LiquidsPriceUpdateContainer from 'views/PriceUpdateContainer/LiquidsPriceUpdateContainer'
import SettingsContainer from 'views/SettingsContainer'
import UserProfileContainer from 'views/UserProfileContainer'
import RequestsContainer from 'views/RequestsContainer'
import RequestDetailsContainer from 'views/RequestDetailsContainer'
import PackageDetailsContainer from 'views/PackageDetailsContainer/PackageDetailsContainer'
import LabourSearchContainer from 'views/LabourSearchContainer/LabourSearchContainer'
import MechanicsContainer from 'views/MechanicsContainer'
import RequestPreviewContainer from 'views/RequestPreviewContainer/RequestPreviewContainer'
import ConfirmPageContainer from 'views/ConfirmPageContainer/ConfirmPageContainer'
import {
    USER_ROLE_SUPERVISOR,
    USER_ROLE_MANAGER,
    USER_ROLE_MECHANIC,
    USER_ROLE_MECHANIC_ADVANCED,
} from '../store/consts/user.constants'
import DiscountsUpdateContainer from '../views/PriceUpdateContainer/DiscountsUpdateContainer'
import RequestPackageContainer from '../views/RequestPackageContainer'
import RequestChecklistContainer from '../views/RequestChecklistContainer'
import AdditionalPercentOnWorks from '../views/PriceUpdateContainer/AdditionalPercentOnWorks'
import ActivitiesContainer from '../views/ActivitiesContainer/ActivitiesContainer'
import ActivityContainer from '../views/ActivityContainer/ActivityContainer'
import Customers from '../views/Customers/Customers'
import OfficialServiceEnrichContainer from '../views/OfficialServiceEnrichContainer/OfficialServiceEnrichContainer'
import CustomersUpload from '../views/CustomersUpload/CustomersUpload'
import ChecklistLabourContainer from '../views/ChecklistLabourContainer/ChecklistLabourContainer'
import Checklists from '../views/Checklists/Checklists'
import Reporting from '../views/Reporting/Reporting'
import AnalyticsEnhancedContainer from "../views/AnalyticsEnhancedContainer/AnalyticsEnhancedContainer";
import AnalyticsEnhancedPackagesContainer from "../views/AnalyticsEnhancedContainer/AnalyticsEnhancedPackagesContainer";
import AnalyticsEnhancedChecklistContainer
    from "../views/AnalyticsEnhancedContainer/AnalyticsEnhancedChecklistContainer";
import AnalyticsEnhancedTopCarsContainer from "../views/AnalyticsEnhancedContainer/AnalyticsEnhancedTopCarsContainer";
import AnalyticsEnhancedTopLaboursContainer
    from "../views/AnalyticsEnhancedContainer/AnalyticsEnhancedTopLaboursContainer";
import AnalyticsEnhancedTopGaragesContainer
    from "../views/AnalyticsEnhancedContainer/AnalyticsEnhancedTopGaragesContainer";
import SystemMessagesContainer from "../views/SystemMessagesContainer/SystemMessagesContainer";
import TireWorkPriceUpdateContainer from "../views/PriceUpdateContainer/TireWorkPriceUpdateContainer"
import TiresImportContainer from "../views/TiresImportContainer/TiresImportContainer";
import BookNowConfirmPageContainer from "../views/ConfirmPageContainer/BookNowConfirmPageContainer";

export const homeRoutes = [
    {
        name: 'Home',
        path: `${pages.home}`,
        component: HomeContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.home.title',
        pageTitle: 'titles.home',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const priceUpdateRoutes = [
    {
        name: 'Price update',
        path: `${pages.price_update}`,
        component: PriceUpdateContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.title',
        pageTitle: 'titles.price_update',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Price update - Hourly prices',
        path: `${pages.price_update_hourly_prices}`,
        component: HourlyPriceUpdateContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.hourly_price.title',
        pageTitle: 'titles.price_update_hourly_price',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Price update - Oil prices',
        path: `${pages.price_update_oil_prices}`,
        component: OilPriceUpdateContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.oil_price.title',
        pageTitle: 'titles.price_update_oil_price',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Price update - Liquids prices',
        path: `${pages.price_update_liquids_prices}`,
        component: LiquidsPriceUpdateContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.liquids_price.title',
        pageTitle: 'titles.price_update_liquids_price',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Price update - Discount',
        path: `${pages.price_update_discount}`,
        component: DiscountsUpdateContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.discount.title',
        pageTitle: 'titles.price_update_discount',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Price update - Additional percent on works',
        path: `${pages.price_update_additional_percent_on_works}`,
        component: AdditionalPercentOnWorks,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.price_update_additional_percent_on_works.title',
        pageTitle: 'titles.price_update_price_update_additional_percent_on_works',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Price update - Tire price work',
        path: `${pages.price_update_tire_work_prices}`,
        component: TireWorkPriceUpdateContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.price_update.price_update_additional_percent_on_works.title',
        pageTitle: 'titles.price_update_price_update_additional_percent_on_works',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const settingsRoutes = [
    {
        name: 'Settings',
        path: `${pages.settings}`,
        component: SettingsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.settings.title',
        pageTitle: 'titles.settings',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'User Profile',
        path: `${pages.user_profile}`,
        component: UserProfileContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.user_profile.title',
        pageTitle: 'titles.user_profile',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Mechanics',
        path: `${pages.mechanics}`,
        component: MechanicsContainer,
        exact: true,
        roles: [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR],
        headerTitle: 'pages.mechanics.title',
        pageTitle: 'titles.mechanics',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const requestsRoutes = [
    {
        name: 'Requests',
        path: `${pages.requests}`,
        component: RequestsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.requests.title',
        pageTitle: 'titles.requests',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Request Details',
        path: `${pages.request_details}:request`,
        component: RequestDetailsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.request_details.title',
        pageTitle: 'titles.request_details',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Request Package Details',
        path: `${pages.request_details}:request${pages.request_package_details}:packageId`,
        component: RequestPackageContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.request_details.package.title',
        pageTitle: 'titles.request_package_details',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Request Checklist Details',
        path: `${pages.request_details}:request${pages.request_checklist}`,
        component: RequestChecklistContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.request_details.checklist.title',
        pageTitle: 'titles.request_checklist_details',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

/*export const analyticsRoutes = [
  {
    name: 'Analytics',
    path: `${pages.analytics}`,
    component: AnalyticsContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
    headerTitle: 'pages.analytics.title',
    pageTitle: 'titles.analytics',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
]*/

export const addCostRoutes = [
    {
        name: 'AddCost',
        path: `${pages.add_cost}`,
        component: AddCostContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.add_cost.title',
        pageTitle: 'titles.add_cost',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },

    {
        name: 'Packages',
        path: `${pages.packages}`,
        component: PackagesContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.packages.title',
        pageTitle: 'titles.packages',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Package Details',
        path: `${pages.package_details}:package`,
        component: PackageDetailsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.package_details.title',
        pageTitle: 'titles.package_details',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Labour search',
        path: `${pages.packages}${pages.labour_search}`,
        component: LabourSearchContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.labour_search.title',
        pageTitle: 'titles.labour_search',
        showFooter: false,
        showRightHeaderControls: false,
    },
    {
        name: 'Checklist Labour',
        path: `${pages.checklist_labour}`,
        component: ChecklistLabourContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.checklist_labour.title',
        pageTitle: 'titles.checklist_labour',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Request preview',
        path: `${pages.preview}`,
        component: RequestPreviewContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.request_preview.title',
        pageTitle: 'titles.request_preview',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Confirm Page',
        path: `${pages.confirm}`,
        component: ConfirmPageContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.confirm.title',
        pageTitle: 'titles.confirm',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Confirm Page',
        path: `${pages.book_now_confirm}`,
        component: BookNowConfirmPageContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.confirm.book_now_headline',
        pageTitle: 'pages.confirm.book_now_headline',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Official Service',
        path: `${pages.official_service}`,
        component: OfficialServiceEnrichContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.official_service.title',
        pageTitle: 'titles.official_service',
        showFooter: true,
        showRightHeaderControls: true,
    },
]

export const activityRoutes = [
    {
        name: 'Activities',
        path: `${pages.activities}`,
        component: ActivitiesContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
        headerTitle: 'pages.activities.title',
        pageTitle: 'titles.activities',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
    {
        name: 'Activity',
        path: `${pages.activity}:user_id`,
        component: ActivityContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
        headerTitle: 'pages.activity.title',
        pageTitle: 'titles.activity',
        showFooter: false,
        showRightHeaderControls: true,
    },
]

export const customerRoutes = [
    {
        name: 'Customers',
        path: `${pages.customers}`,
        component: Customers,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.customers.title',
        pageTitle: 'titles.customers',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const customersUploadRoutes = [
    {
        name: 'CustomersUpload',
        path: `${pages.customers_upload}`,
        component: CustomersUpload,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.customers.customers_upload_title',
        pageTitle: 'titles.customers_upload',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const checklistsRoutes = [
    {
        name: 'Checklists',
        path: `${pages.checklists}`,
        component: Checklists,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        gates: [
            (garage) => {
                return garage.allow_checklist_update === true;
            }
        ],
        headerTitle: 'pages.checklist_labour.title',
        pageTitle: 'titles.checklist_labour',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const reportingRoutes = [
    {
        name: 'Reporting',
        path: `${pages.reporting}`,
        component: Reporting,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.generate_report.title',
        pageTitle: 'titles.generate_report',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export const analyticsEnhanced = [
  {
    name: 'Analytics Enhanced',
    path: `${pages.analytics_enhanced}`,
    component: AnalyticsEnhancedContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
    headerTitle: 'pages.analytics_enhanced.title',
    pageTitle: 'titles.analytics_enhanced',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
  {
    name: 'Analytics Enhanced Packages',
    path: `${pages.analytics_enhanced_packages}`,
    component: AnalyticsEnhancedPackagesContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
    headerTitle: 'pages.analytics_enhanced.packages',
    pageTitle: 'titles.analytics_enhanced_packages',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
  {
    name: 'Analytics Enhanced Checklist',
    path: `${pages.analytics_enhanced_checklist}`,
    component: AnalyticsEnhancedChecklistContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
    headerTitle: 'pages.analytics_enhanced.checklist',
    pageTitle: 'titles.analytics_enhanced_checklist',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
  {
    name: 'Analytics Enhanced Top Cars',
    path: `${pages.analytics_enhanced_top_cars}`,
    component: AnalyticsEnhancedTopCarsContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
    headerTitle: 'pages.analytics_enhanced.top_cars',
    pageTitle: 'titles.analytics_enhanced_top_cars',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
  {
    name: 'Analytics Enhanced Top Labours',
    path: `${pages.analytics_enhanced_top_labours}`,
    component: AnalyticsEnhancedTopLaboursContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER],
    headerTitle: 'pages.analytics_enhanced.top_labours',
    pageTitle: 'titles.analytics_enhanced_top_labours',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
  {
    name: 'Analytics Enhanced Top Garages',
    path: `${pages.analytics_enhanced_top_garages}`,
    component: AnalyticsEnhancedTopGaragesContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR],
    headerTitle: 'pages.analytics_enhanced.top_garage',
    pageTitle: 'titles.analytics_enhanced_top_garage',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
]

export const tiresRoutes = [
  {
    name: 'Tires Import',
    path: `${pages.tires_import}`,
    component: TiresImportContainer,
    exact: true,
    roles: [USER_ROLE_SUPERVISOR],
    headerTitle: 'pages.tires_import.title',
    pageTitle: 'titles.tires_import',
    showFooter: true,
    showProfileProgress: true,
    showRightHeaderControls: true,
  },
]

export const systemMessageRoutes = [
    {
        name: 'System messages',
        path: `${pages.system_messages}`,
        component: SystemMessagesContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.system_messages.title',
        pageTitle: 'titles.system_messages',
        showFooter: true,
        showProfileProgress: true,
        showRightHeaderControls: true,
    },
]

export default [
    ...homeRoutes,
    ...priceUpdateRoutes,
    ...settingsRoutes,
    ...requestsRoutes,
    ...addCostRoutes,
    ...activityRoutes,
    ...customerRoutes,
    ...checklistsRoutes,
    ...reportingRoutes,
    ...customersUploadRoutes,
    ...analyticsEnhanced,
    ...systemMessageRoutes,
    ...tiresRoutes,
]
