import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Input from "components/shared/Input/Input";
import {withFormik} from "formik";
import {formPartAdd} from "app/config/yup";
import Button from "components/shared/Button/Button";
import {usePrevious} from "components/hooks/utils";
import {packagesUpdate} from "store/actions/packages.actions";
import {compare, updateObject} from "utils/common";
import {__clone, deepObject} from "utils/common";
import lodash from "lodash";
import partModel from "store/models/package/part";
import randomPackageId from "utils/randomPackageId";
import {parseDeepPath} from "../../../utils/common";
import InputNumber from "../../../components/shared/InputNumber/InputNumber";
import BaseCountry from "../../../utils/BaseCountry";

const PartAdd = props => {

    const { t, handleBlur, touched, errors, handleChange, isValid, packagePath, dispatch, packages, formSubmitting, setTouched, onClose,
        setFieldTouched, setFieldValue} = props;

    const [ part, updatePart ] = useState(lodash.defaultsDeep({
        id: randomPackageId(),
        versions: [
            {
                id: randomPackageId(),
                set: [
                    {
                        id: randomPackageId(),
                    }
                ]
            },
        ],
    }, partModel));

    const [ partUpdated, setPartUpdated ] = useState(false);

    const prevPartSet = usePrevious(part);
    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        setDefaultAmount(1)
    }, [])

    useEffect(() => {
        setPartUpdated((part && prevPartSet && compare(part, prevPartSet)));
    }, [part]);

    useEffect(() => {
        if(prevPackages){
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        setTouched({
            name: true,
            price: true,
            amounts: true,
        });

        if(partUpdated && isValid){

            let _package = deepObject(__clone(packages), packagePath);
            let labour = _package.labours.find(labour => labour.works.find(work => work.selected === true && work.parts.length === 0));

            if(!labour){
                labour = _package.labours.pop();
            }

            let work = labour.works.find(work => work.selected === true);

            if(!work){
                work = labour.works.pop();
            }

            work.parts.push(part);

            dispatch(packagesUpdate(updateObject(packages, labour, `${packagePath}/labours/itemMpId:${labour.itemMpId}`), parseDeepPath(packagePath)[0].id));
        }
    };

    let updateName = (e) => {
        let partClone = __clone(part);

        partClone.name = e.target.value;
        partClone.versions[0].name = e.target.value;
        partClone.versions[0].set[0].name = e.target.value;

        updatePart(partClone);
    };

    let updatePrice = (e) => {
        let partClone = __clone(part);

        partClone.versions[0].set[0].price = e.target.value.parseFloat();

        updatePart(partClone);
    };

    let updateAmounts = (value) => {
        let partClone = __clone(part);

        partClone.versions[0].set[0].amounts = value.parseFloat();

        updatePart(partClone);
    };

    let setDefaultAmount = (value) => {
        updateAmounts(value);
        setFieldTouched("amounts", true)
        setFieldValue("amounts", value)
    }

    return (
        <React.Fragment>

            <form onSubmit={submit}>

                <div className="row">

                    <div className="col-xs-12">
                        <Input
                            type="text"
                            placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.name')}
                            name="name"
                            label={t('pages.package_details.labour.parts.set.section_form.label.name')}
                            value={part.name}
                            onBlur={handleBlur}
                            onChange={e => {updateName(e); handleChange(e)}}
                            error={touched.name && errors.name}
                        />
                    </div>

                    <div className="col-xs-6">
                        <Input
                            type="tel"
                            placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.price')}
                            name="price"
                            label={t('pages.package_details.labour.parts.set.section_form.label.price')}
                            value={part.price}
                            onBlur={handleBlur}
                            onChange={e => {updatePrice(e); handleChange(e)}}
                            error={touched.price && errors.price}
                            addOn={BaseCountry().currency}
                            step={0.0001}
                            float={true}
                        />
                    </div>
                    <div className="col-xs-6">
                        <InputNumber
                            placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.amounts')}
                            name="amounts"
                            label={t('pages.package_details.labour.parts.set.section_form.label.amounts')}
                            value={part.amounts}
                            onBlur={handleBlur}
                            onChange={value => {
                                setDefaultAmount(value)
                            }}
                            error={touched.amounts && errors.amounts}
                            step={0.0001}
                            minimum={0}
                            float={true}
                        />
                    </div>

                </div>

                <Button size="lg" type="primary" disabled={formSubmitting.packages || !partUpdated}>{t('global.save')}</Button>
            </form>

        </React.Fragment>
    );
};

const PartAddForm = withFormik(formPartAdd)(PartAdd);

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(PartAddForm));
