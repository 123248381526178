import React, {Component} from 'react';
import './ChecklistLabour.scss';
import {translate} from "react-i18next";
import {
    CHECKLIST_LABOUR_TYPE_CUSTOM,
    CHECKLIST_LABOUR_TYPE_LIST,
    CHECKLIST_LABOUR_TYPE_NORMAL,
    CHECKLIST_LABOUR_TYPE_TIRES,
    CHECKLIST_LABOUR_TYPE_POSITION,
    CHECKLIST_LABOUR_TYPE_SERVICE, CHECKLIST_LABOUR_TYPE_POSITION_CATEGORIES,
} from "../../store/consts/checklistLabour.constants";
import {__clone, parseResponse} from "../../utils/common";
import NormalType from "./Types/NormalType";
import PositionType from "./Types/PositionType";
import PositionCategoriesType from "./Types/PositionCategoriesType";
import OfficialServiceType from "./Types/OfficialServiceType";
import CustomType from "./Types/CustomType";
import axios from "../../app/config/axios";
import {defaultsDeep} from "lodash";
import labourModel from "../../store/models/package/labour";
import packageModel from "../../store/models/package/package";
import {packageRemove, packagesUpdate, packageTiresCreateOrUpdate} from "../../store/actions/packages.actions";
import {connect} from "react-redux";
import {
    checklistLaboursFieldUpdate,
    checklistLaboursUpdate,
    checklistLaboursUpdatePassed
} from "../../store/actions/checklistLabours.actions";
import {haxMaxPackages} from "../../store/selectors/package";
import ListType from "./Types/ListType";
import TiresType from "./Types/TiresType";
import {tiresEditPrepare} from "../../store/actions/tires.actions";
import {captureException} from "../../utils/captureException";
import {toast} from "react-toastify";
import Toast from "../shared/Toast/Toast";
import NotePopup from "./components/NotePopup";

const API_ADD_COST_LABOUR = 'garage/add-cost-tool/labour'

export const CHECKLIST_OK = true;
export const CHECKLIST_NOT_OK = false;

class ChecklistLabour extends Component {

    state = {
        showNotePopup: false,
    }

    updatePassed = (labour = this.props.labour) => {
        const {dispatch} = this.props;

        if(labour.passed === CHECKLIST_OK && this.props.packages.find(p => p.id === labour.package_id)){
            dispatch(packageRemove(labour.package_id, true))
                .then(() => {
                    dispatch(checklistLaboursUpdatePassed({...labour, package_id: null}))
                        .then(response => dispatch(checklistLaboursUpdate(response)))
                })
                .catch(this.onError)
        }
        else {
            dispatch(checklistLaboursUpdatePassed(labour))
                .then(response => dispatch(checklistLaboursUpdate(response)))
        }
    }

    packageExists = () => this.props.packages.find(p => p.id === this.props.labour.package_id);

    updateChecklistLabourField = (fields, labour = this.props.labour) => this.props.dispatch(checklistLaboursFieldUpdate(labour, fields));

    packageFlow = (labours) => {
        const {dispatch} = this.props;

        this.fetchLabours(labours, this.packageExists())
            .then(response => {
                this.createNewPackage(response.data, this.packageExists())
                    .then((response) => {

                        let siblingPackages = this.props.labour.siblings.filter(sibling => sibling.package_id !== this.props.labour.package_id);

                        Promise.all(siblingPackages.map(sibling => dispatch(packageRemove(sibling.package_id, true))))
                            .then(() => {

                                this.props.labour.siblings.forEach(sibling => {
                                    this.updateChecklistLabourField({package_id: response.package.id}, this.props.labour.siblings.find(s => s.id === sibling.id))
                                        .then(() => this.updatePassed(this.props.labour.siblings.find(s => s.id === sibling.id)))
                                });

                                this.updateChecklistLabourField({package_id: response.package.id})
                                    .then(() => this.updatePassed())

                            })
                            .catch(this.onError)
                    })
                    .catch(this.onError)
            })
            .catch(this.onError)
    }

    onError = (error) => {
        captureException(error);

        toast.error(<Toast text={parseResponse(error.response)} type="error"/>)

        this.props.labour.siblings.forEach(sibling => {
            this.updateChecklistLabourField({updating: false, passed: null}, this.props.labour.siblings.find(s => s.id === sibling.id))
        });

        this.updateChecklistLabourField({updating: false, passed: null});
    }

    fetchLabours = (labours, _package = null) => {
        const {t, haxMaxPackages, garage} = this.props;

        labours = labours.map(labour => defaultsDeep(labour, labourModel))

        return new Promise((resolve, reject) => {

            if (haxMaxPackages && !_package) {
                reject({response: {data: {message: t('pages.packages.max_number', {max: garage.packages.max})}}});
                return;
            }

            axios.post(API_ADD_COST_LABOUR, {params: {package: {labours: labours}}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    captureException(error, API_ADD_COST_LABOUR)

                    reject(error);
                })
        })
    }

    createNewPackage = (labours, _package = null) => {
        const {packages, dispatch} = this.props;

        let packagesClone = __clone(packages), packageAdd;

        if (_package) {
            _package.labours = labours;
            packageAdd = _package;
            packagesClone = packagesClone.map(p => {
                return p.id === _package.id ? packageAdd : p;
            })
        } else {
            packageAdd = defaultsDeep({labours: labours}, packageModel)
            packagesClone.push(packageAdd)
        }

        return dispatch(packagesUpdate(packagesClone, packageAdd.id, true))
    }

    createOrUpdatePackageTires = (tires, _package = null) => {
        const {dispatch} = this.props;

        dispatch(packageTiresCreateOrUpdate(tires, _package)).then((response) => {
            this.updateChecklistLabourField({package_id: response.package.id})
                .then(() => {
                    this.updatePassed()
                })

        })
        .catch(this.onError)
    }

    toggleNotePopup = (show) => this.setState({showNotePopup: show})

    updateNote = (note) => {
        const {labour, dispatch} = this.props;

        dispatch(checklistLaboursUpdatePassed({...labour, note}))
            .then(response => {
                dispatch(checklistLaboursUpdate(response))
            })
            .catch(error => {
                console.log(error);
            })

        this.toggleNotePopup(false);
    }

    renderType = () => {
        const {labour, packages, dispatch, garage} = this.props;

        if (labour.type === CHECKLIST_LABOUR_TYPE_NORMAL) {
            return <NormalType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                packageFlow={this.packageFlow}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        } else if (labour.type === CHECKLIST_LABOUR_TYPE_POSITION) {
            return <PositionType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                packageFlow={this.packageFlow}
                garage={garage}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        } else if (labour.type === CHECKLIST_LABOUR_TYPE_SERVICE) {
            return <OfficialServiceType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                packages={packages}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        } else if (labour.type === CHECKLIST_LABOUR_TYPE_CUSTOM) {
            return <CustomType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        } else if (labour.type === CHECKLIST_LABOUR_TYPE_LIST) {
            return <ListType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                packageFlow={this.packageFlow}
                packages={packages}
                garage={garage}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        } else if(labour.type === CHECKLIST_LABOUR_TYPE_TIRES){
            return <TiresType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                createOrUpdate={this.createOrUpdatePackageTires}
                tiresEditPrepare={(tires) => dispatch(tiresEditPrepare(tires))}
                packages={packages}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        } else if (labour.type === CHECKLIST_LABOUR_TYPE_POSITION_CATEGORIES) {
            return <PositionCategoriesType
                labour={labour}
                onUpdatePassed={this.updatePassed}
                labourFieldUpdate={this.updateChecklistLabourField}
                packageFlow={this.packageFlow}
                garage={garage}
                showNotePopup={() => this.toggleNotePopup(true)}
            />
        }
    }

    render() {
        const {labour} = this.props;
        const {showNotePopup} = this.state;

        return (
            <React.Fragment>
                {this.renderType()}


                {showNotePopup &&
                    <NotePopup
                        labour={labour}
                        visible={showNotePopup}
                        onClose={() => this.toggleNotePopup(false)}
                        onSubmit={(note) => this.updateNote(note)}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    haxMaxPackages: haxMaxPackages(state),
    garage: state.garage,
    packages: state.packages,
});

export default connect(mapStateToProps)(translate('translations')(ChecklistLabour));
