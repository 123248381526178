import store from 'store/store';
import pages from "app/consts/routes";
import history from 'routes/history';
import React from "react";
import fieldExists from "utils/fieldExists";
import {arrayColumn} from "utils/common";

export const PackageDetailsCheck = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            const packages = store.getState().packages;

            this.shouldRender = true;

            if(!fieldExists(this.props.match.params, 'package') || packages.length === 0 || Object.values(arrayColumn(packages, 'id')).indexOf(parseInt(this.props.match.params.package)) === -1){
                this.shouldRender = false;
                history.replace(pages.packages);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }
    }
};

export default PackageDetailsCheck;
