import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import "./index.scss";
import {
    STATUS_ARCHIVED,
    STATUS_PENDING_MANAGER, STATUS_PENDING_USER, STATUS_RESPONDED_USER, STATUS_RESPONDED_GARAGE
} from "../../store/consts/status.constants";
import Requests from "../../components/shared/Requests";
import {fetchRequests} from "../../store/actions/requests.actions";
import Header from "../../components/layout/Header/Header";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import {__clone, compare, urlSearchParams} from "../../utils/common";
import Input from "../../components/shared/Input/Input";
import {LOADER_REQUESTS} from "../../store/consts/loader.constants";
import history from 'routes/history'

const PAGE_LIMIT = 10;

class RequestsContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            statusList: null,
            labels: [{
                name: props.t('pages.requests.tab.pending_manager'),
                value: STATUS_PENDING_MANAGER,
                icon: 'icon-pending-manager',
                class: 'brand-danger',
                page: 1,
            }, {
                name: props.t('pages.requests.tab.pending_user'),
                value: STATUS_PENDING_USER,
                icon: 'icon-pending-customer',
                class: 'brand-warning',
                page: 1,
            }, {
                name: props.t('pages.requests.tab.responded'),
                value: STATUS_RESPONDED_USER,
                icon: 'icon-check-round',
                class: 'brand-primary',
                page: 1,
            }, {
                name: props.t('pages.requests.tab.archived'),
                value: STATUS_ARCHIVED,
                icon: 'icon-file',
                class: 'brand-primary',
                page: 1,
            }],
            search: ''
        };
    }

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch(fetchRequests())

        if(this.props.requests.length > 0){
            this.setState({
                statusList: this.state.labels[this.getFirstFullRequests()].value
            })
        }

        window.addEventListener('scroll', this.scrollEvent)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollEvent)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(compare(prevProps.requests, this.props.requests)){
            this.setState({
                statusList: this.state.labels[this.getFirstFullRequests()].value
            })
        }
    }

    scrollEvent = (e) => {
        let ele = document.documentElement;

        if(ele.scrollTop > (ele.scrollHeight - ele.offsetHeight) - (ele.scrollHeight - ele.offsetHeight) * 0.3) {
            this.setState({
                labels: this.state.labels.map(label => {
                    if(label.value === this.state.statusList){
                        return {
                            ...label,
                            page: label.page + 1
                        }
                    }

                    return label
                })
            })
        }
    }

    handleTextChange = (e) => {
        this.setState({search: e.target.value})
    };

    handleTabChange = (label, index) => {
        history.replace('?tab=' + index)

        this.setState({
            statusList: label.value
        })
    }

    handleTextClear = () => {
        this.setState({
            search: '',
        })
    }

    getFirstFullRequests = () => {
        const {labels} = this.state;
        const {requests} = this.props;
        let tabIndex = 0;

        if (urlSearchParams('tab')) return parseInt(urlSearchParams('tab'))

        labels.find((label, index) => {
            if (requests.filter(request => request.status === label.value).length > 0) {
                tabIndex = index

                return label
            }
        });

        return tabIndex
    }

    filterBySearch = (requests) => {
        const {search} = this.state;

        if (search.length >= 2) {
            return requests.filter(request =>
                request.car.make.toLowerCase().includes(search.toLowerCase()) ||
                request.car.range.toLowerCase().includes(search.toLowerCase()) ||
                request.car.type.toLowerCase().includes(search.toLowerCase()) ||
                (request.car.licence_plate && request.car.licence_plate.toLowerCase().includes(search.toLowerCase())) ||
                (request.car.car_number && request.car.car_number.toLowerCase().includes(search.toLowerCase())) ||
                (request.owner && request.owner.first_name && request.owner.first_name.toLowerCase().includes(search.toLowerCase())) ||
                (request.owner && request.owner.last_name && request.owner.last_name.toLowerCase().includes(search.toLowerCase())))
        }

        return requests
    }

    pages = () => {
        const {requests} = this.props;
        const {statusList, labels} = this.state;

        return requests.filter(request => request.status === statusList || (request.status === STATUS_RESPONDED_GARAGE && statusList === STATUS_RESPONDED_USER)).slice(0, PAGE_LIMIT * labels[this.getFirstFullRequests()].page);
    }

    render() {
        const {t, title, requests, search, loader} = this.props;
        const {statusList, labels} = this.state;

        let list = this.pages();

        if(this.state.search.length >= 2){
            list = this.filterBySearch(requests.filter(request => request.status === statusList));
        }

        return (
            <React.Fragment>
                <Header title={t(title)}/>

                <div className="container">

                    {!loader[LOADER_REQUESTS] ? <ListLoader/> :
                        <div className="row">

                            <div className="col-xs-12 col-sm-4">
                                <div className="request-tabs row">
                                    {__clone(labels).map(label => {
                                        label.number = this.filterBySearch(requests.filter(request => request.status === label.value || (request.status === STATUS_RESPONDED_GARAGE && label.value === STATUS_RESPONDED_USER))).length
                                        return label;
                                    })
                                        .map((label, index) => {
                                            return (
                                                <div className="col-xs-6 col-sm-12" key={index}>
                                                    <div className={`request-tab ${statusList === label.value ? 'active' : ''}`}
                                                         onClick={() => this.handleTabChange(label, index)}>
                                                        <div className="request-tab__number">
                                                            <i className={`${label.icon} ${label.class}`} />
                                                            <span>{label.number}</span>
                                                        </div>
                                                        <div className="request-tab__title">
                                                            {label.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                            <div className="col-sm-1 hide-on-mobile">
                                <div className="vertical-line-delimiter"/>
                            </div>
                            <div className="col-xs-12 col-sm-7">
                                <Input
                                    type="text"
                                    placeholder={t('pages.requests.form.placeholder.search')}
                                    name="search"
                                    value={search}
                                    onChange={this.handleTextChange}
                                    onTextClear={this.handleTextClear}
                                    iconRightClassName={'icon-cancel'}
                                    iconLeftClassName={'icon-search'}
                                />

                                <Requests
                                    requests={list}
                                />
                            </div>
                        </div>
                    }
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        requests: state.requests,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(RequestsContainer));
