export const STORE = '[Store]'

export const STORE_RESET = `${STORE} RESET ALL`

export const storeReset = (persist = true) => ({
    type: STORE_RESET,
    payload: {
        persist,
    },
})
