import recipients from '../models/recipients';
import {RECIPIENTS_SETTINGS_SET} from "../actions/recipients.settings.actions";

export default function(state = recipients, action) {
    switch (action.type) {

        case RECIPIENTS_SETTINGS_SET:
            return action.payload.recipients;

        default:
            return state;
    }
}
