import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions';
import {formSubmittingSettings} from "store/actions/formSubmitting.actions";
import {CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE} from "../../actions/change-customer-signature.settings.actions";
import {garageUpdateCustomerSignature} from "../../actions/garage.actions";

export const SETTINGS_CUSTOMER_SIGNATURE_UPDATE_URL = 'garage/settings/update-customer-signature';

export const changeCustomerSignature = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {

            case CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE:
                dispatch(formSubmittingSettings(true));

                dispatch(
                    apiRequest({
                        body: {customer_signature: action.payload.signature},
                        method: 'POST',
                        url: SETTINGS_CUSTOMER_SIGNATURE_UPDATE_URL,
                        signature: CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE
                    })
                );

                break;

            case `${CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingSettings(false));
                dispatch(garageUpdateCustomerSignature(action.payload.data.customer_signature));
                break;

            case `${CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingSettings(false));
                break;

            default:
                break;
        }
    };
};
