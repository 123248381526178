import React, { Component } from 'react';
import './Button.scss';
import Loader, {LOADER_BUTTON} from "../Loader/Loader";
import {Link} from "react-router-dom";

class Button extends Component {
    render() {
        const { children, size, type, icon, extraClass, onClick, disabled, id, button, loading, to } = this.props;

        if(to){
            return (
                <Link
                    to={to}
                    id={id}
                    className={`btn btn-${size} btn-${type} ${extraClass}`}
                >
                    {icon && (
                        <div className="btn__icon">
                            <span className={icon} />
                        </div>
                    )}
                    {children}
                </Link>
            );
        }

        if(button === false){
            return (
                <div
                    id={id}
                    className={`btn btn-${size} btn-${type} ${extraClass}`}
                    onClick={e => {
                        if (onClick) onClick(e);
                    }}
                >
                    {icon && (
                        <div className="btn__icon">
                            <span className={icon} />
                        </div>
                    )}
                    {children}
                </div>
            );
        }

        return (
            <button
                id={id}
                type="submit"
                className={`btn btn-${size} btn-${type} ${extraClass}`}
                onClick={e => {
                    if (onClick) onClick(e);
                }}
                disabled={disabled}
            >
                {children}

                {icon && (
                    <i className={`${icon} btn__icon`} />
                )}
                <Loader isLoading={loading} type={LOADER_BUTTON}/>
            </button>
        );
    }
}

export default Button;
