import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {garageReinitialize} from "../../store/actions/garage.actions";
import {clearEcho} from "../../utils/echo";
import {pickGarage} from "../../store/actions/auth.actions";
import Loader, {LOADER_LOADING} from "../../components/shared/Loader/Loader";
import Popup from "../../components/shared/Popup/Popup";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import Toast from "../shared/Toast/Toast";
import {parseResponse} from "../../utils/common";

class GarageReinitialize extends Component {

    componentDidMount() {
        const {dispatch, onSuccess, garage} = this.props;

        dispatch(garageReinitialize());
        clearEcho();

        dispatch(pickGarage(garage.id))
            .then(() => onSuccess())
            .catch((response) => {
                toast.error(<Toast text={parseResponse(response)} type="error" />)
            })
    }

    render() {
        const {t} = this.props;

        return (
            <React.Fragment>
                <Popup visible={true} hideCancel={true}>
                    <div className="mv-30 text-light text-center"><Loader type={LOADER_LOADING} isLoading={true}/> {t('global.garage_reinitialize')}</div>
                </Popup>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(translate('translations')(GarageReinitialize));
