export const ACTIVITY_MANAGER_WAITING = 1
export const ACTIVITY_MANAGER_CREATED = 2
export const ACTIVITY_MANAGER_ACCEPTED = 3
export const ACTIVITY_MANAGER_REJECTED = 4
export const ACTIVITY_USER_RESPONDED = 5
export const ACTIVITY_USER_SMS_REPLY = 6
export const ACTIVITY_GARAGE_SMS_REPLY = 7

export default {
    ACTIVITY_MANAGER_WAITING,
    ACTIVITY_MANAGER_ACCEPTED,
    ACTIVITY_MANAGER_REJECTED,
    ACTIVITY_USER_RESPONDED,
    ACTIVITY_USER_SMS_REPLY,
    ACTIVITY_GARAGE_SMS_REPLY,
}
