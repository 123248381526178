import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Input from "components/shared/Input/Input";
import {withFormik} from "formik";
import {formPartSetEdit} from "app/config/yup";
import Button from "components/shared/Button/Button";
import {usePrevious} from "components/hooks/utils";
import {packagesUpdate} from "store/actions/packages.actions";
import {compare, updateObject} from "utils/common";
import {parseDeepPath} from "../../../utils/common";
import InputNumber from "../../../components/shared/InputNumber/InputNumber";

const PartSetEdit = props => {

    const { t, handleBlur, touched, errors, handleChange, packagePath, dispatch, packages, formSubmitting, onClose,
        setFieldTouched, setFieldValue} = props;

    const [ partSet, updatePartSet ] = useState(props.partSet);
    const [ partSetUpdated, setPartSetUpdated ] = useState(props.partSet);

    const prevPartSet = usePrevious(props.partSet);
    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if(!partSet.amounts){
            setDefaultAmount(1)
        }
    }, [])

    useEffect(() => {
        setPartSetUpdated((partSet && prevPartSet && compare(partSet, prevPartSet)));
    }, [partSet]);

    useEffect(() => {
        if(prevPackages){
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        if(!Object.keys(errors).length){
            dispatch(packagesUpdate(updateObject(packages, partSet, packagePath), parseDeepPath(packagePath)[0].id));
        }
    };

    let setDefaultAmount = (value) => {
        updatePartSet({...partSet, amounts: value.parseFloat()});
        setFieldTouched("amounts", true)
        setFieldValue("amounts", value)
    }

    return (
        <React.Fragment>

            <form onSubmit={submit}>

                <div className="row">

                    <div className="col-xs-12">
                        <Input
                            type="text"
                            placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.name')}
                            name="name"
                            label={t('pages.package_details.labour.parts.set.section_form.label.name')}
                            value={partSet.name}
                            onBlur={handleBlur}
                            onChange={e => {updatePartSet({...partSet, name: e.target.value}); handleChange(e)}}
                            error={touched.name && errors.name}
                        />
                    </div>

                    <div className="col-xs-6">
                        <Input
                            type="tel"
                            placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.price')}
                            name="price"
                            label={t('pages.package_details.labour.parts.set.section_form.label.price')}
                            value={partSet.price}
                            onBlur={handleBlur}
                            onChange={e => {updatePartSet({...partSet, price: e.target.value.parseFloat()}); handleChange(e)}}
                            error={touched.price && errors.price}
                            addOn={partSet.currency}
                            step={0.0001}
                            float={true}
                        />
                    </div>
                    <div className="col-xs-6">
                        <InputNumber
                            placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.amounts')}
                            name="amounts"
                            label={t('pages.package_details.labour.parts.set.section_form.label.amounts')}
                            value={partSet.amounts}
                            onBlur={handleBlur}
                            onChange={value => {
                                setDefaultAmount(value)
                            }}
                            error={touched.amounts && errors.amounts}
                            step={0.0001}
                            minimum={0}
                            float={true}
                        />
                    </div>

                </div>

                <Button size="lg" type="primary" disabled={formSubmitting.packages || !partSetUpdated}>{t('global.save')}</Button>
            </form>

        </React.Fragment>
    );
};

const PartSetEditForm = withFormik(formPartSetEdit)(PartSetEdit);

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(PartSetEditForm));
