import Yup from './yup'
import { labourPricesAppointmentDiscountUpdate } from '../../../store/actions/labourPrices.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues(props) {
        return {
            discount: 0,
            show_appointment_discount: !!props.discount,
        }
    },

    validationSchema: ({ t }) => {
        return Yup.object().shape({
            discount: Yup.number()
                .required()
                .label(t('pages.price_update.section_form.label_discount')),
            show_appointment_discount: Yup.bool().label(t('pages.price_update.section_form.label_discount')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props
        dispatch(
            labourPricesAppointmentDiscountUpdate({
                discount: values.discount,
                show_appointment_discount: values.show_appointment_discount,
            }),
        )
    },
}
