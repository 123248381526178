import Yup from './yup'
import { labourPricesAdditionalPercentOnWorksUpdate } from '../../../store/actions/labourPrices.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            additional_percent_on_works: 0,
        }
    },

    validationSchema: ({ t }) => {
        return Yup.object().shape({
            additional_percent_on_works: Yup.number()
                .required()
                .label(t('pages.price_update.section_form.label_additional_percent_on_works')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(labourPricesAdditionalPercentOnWorksUpdate(values.additional_percent_on_works))
    },
}
