import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from "formik";
import Header from "components/layout/Header/Header";
import {labourPricesOilFetch} from "store/actions/labourPrices.actions";
import Input from "components/shared/Input/Input";
import Button from "components/shared/Button/Button";
import {formOilPrices} from "app/config/yup";
import {arrayColumn} from "utils/common";
import {LOADER_LABOUR_PRICES} from "store/consts/loader.constants";
import ListLoader from "components/shared/ListLoader/ListLoader";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class OilPriceUpdateContainer extends Component {
    componentDidMount() {
        this.props.dispatch(labourPricesOilFetch());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.oils.prices.length !== nextProps.oils.prices.length){
            this.props.setValues(arrayColumn(nextProps.oils.prices, 'price', 'input_name'));
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    render() {
        const { t, oils: {prices}, handleBlur, handleSubmit, formSubmitting, loader, touched, errors } = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} />

                <div className="container container--has-submit-container">
                    {loader[LOADER_LABOUR_PRICES] ?
                        <ListLoader/> :
                        <form onSubmit={e => handleSubmit(e)}>
                            <div className="row">
                                {prices.map((oil, key) => {
                                    return (
                                        <div className="col-xs-12 col-sm-6">
                                            <Input
                                                type="tel"
                                                placeholder={t('pages.price_update.section_form.placeholder_younger_price')}
                                                name={oil.input_name}
                                                label={oil.name}
                                                onBlur={handleBlur}
                                                onChange={this.handleTextChange}
                                                value={oil.price}
                                                addOn={oil.price_type}
                                                key={key}
                                                error={touched[oil.input_name] && errors[oil.input_name]}
                                                extraClass={'oil-input'}
                                                step={0.000001}
                                                float={true}
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button size="lg" type="primary" disabled={formSubmitting.oilPrice}>
                                        {t('pages.price_update.section_form.button_save')}
                                    </Button>
                                </div>
                            </SubmitContainer>
                        </form>
                    }
                </div>

            </React.Fragment>
        );
    }
}

const PriceUpdateForm = withFormik(formOilPrices)(OilPriceUpdateContainer);

const mapStateToProps = (state) => {
    return {
        oils: state.labourPrices.oil,
        formSubmitting: state.formSubmitting,
        loader: state.loader
    };
};

export default connect(mapStateToProps)(translate('translations')(PriceUpdateForm));
