import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import Header from "../../components/layout/Header/Header";
import MechanicList from "../../components/shared/MechanicList";
import Popup from "../../components/shared/Popup/Popup";
import Button from "../../components/shared/Button/Button";
import FormMechanic from "../../components/forms/FormMechanic";
import {mechanicApiCreate} from "../../store/actions/mechanic.actions";
import {usePrevious} from "../../components/hooks/utils";
import {LOADER_MECHANICS_PAGE} from "../../store/consts/loader.constants";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

export const MechanicsContainer = props => {

    const {t, formSubmitting, title, loader} = props;

    const [isAddingNewOne, setIsAddingNewOne] = useState(false);
    const [shouldResetForm] = useState(false);
    const prevMechanics = usePrevious(props.mechanics)

    useEffect(() => {
        if (props.mechanics > prevMechanics) {
            setIsAddingNewOne(false);
        }
    }, [props.mechanics, prevMechanics])

    let handlePopupSave = (newMechanic) => {
        props.dispatch(mechanicApiCreate(newMechanic));
    };

    let handlePopupClose = () => {
        setIsAddingNewOne(false);
    };

    let handleCallToActionClick = () => {
        setIsAddingNewOne(true);
    };

    let renderPopup = () => {
        return (<Popup
            title={t('pages.mechanics.change_person_details')}
            visible={isAddingNewOne}
            onClose={handlePopupClose}
            contentScrollable={true}
        >
            <FormMechanic
                shouldResetForm={shouldResetForm}
                isFormSubmitting={formSubmitting.mechanics}
                buttonLabel={t('global.save')}
                onSubmit={handlePopupSave}
            />
        </Popup>)
    };

    let renderCallToAction = () => {
        if(loader[LOADER_MECHANICS_PAGE]){
            return null
        }
        return (
            <SubmitContainer stat={true}>
                <div className="services-save-footer">
                    <Button
                        size="lg"
                        type="primary"
                        onClick={handleCallToActionClick}
                    >
                        {t('pages.mechanics.add_new_person')}
                    </Button>
                </div>
            </SubmitContainer>
        )
    };

    return (
        <React.Fragment>
            <Header title={t(title)}/>
            <div className="container bottom-sticky-space">
                <MechanicList/>
                {renderPopup()}
                {renderCallToAction()}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        mechanics: state.mechanics,
        formSubmitting: state.formSubmitting,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(MechanicsContainer));
