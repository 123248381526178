import React, {Component} from 'react';
import './ZipPicker.scss';
import axios from 'app/config/axios'
import {translate} from "react-i18next";
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import i18n from "i18next";
import language from "../../../utils/language";
import {connect} from "react-redux";

const API_ZIP_FETCH_URL = 'garage/zip'

const NoOptionsMessage = props => <components.NoOptionsMessage {...props}
                                                               children={i18n.getResource(language(), 'translations', 'form.select.no_result')}/>
const LoadingMessage = props => <components.LoadingMessage {...props}
                                                           children={i18n.getResource(language(), 'translations', 'global.loading')}/>
const DropdownIndicator = props => null

class ZipPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    handleTextChange = e => {
        const {setValues, onChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            e.persist();
            this.setState({
                error: ''
            });
            setValues({
                filter: e.target.value,
                selected: null
            });
            if (onChange) {
                onChange(e);
            }
        }
    };

    fetch = (input) => {
        const {country} = this.props;

        if (!input || input.length < country.zip.length) {
            return null
        }

        return axios
            .post(API_ZIP_FETCH_URL, {zip: input})
            .then(response => {
                return response.data.map(item => {
                    return {
                        value: item.id,
                        label: `${item.zip} ${item.name}`,
                    }
                })
            })
            .catch(error => {
                return []
            })
    }

    render() {
        const {name, label, onChange, error} = this.props;
        const {value} = this.state;

        return (
            <div>

                <div className="form-group">
                    <label htmlFor={name}>{label}</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.fetch}
                        name={name}
                        onChange={(e) => {
                            this.setState({
                                value: e
                            })

                            onChange(e)
                        }}
                        value={value}
                        classNamePrefix={"select2"}
                        components={{NoOptionsMessage, LoadingMessage, DropdownIndicator}}
                    />

                    {error && (
                        <span className="form-error">
                        <p>{error}</p>
                    </span>
                    )}
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        country: state.country
    };
};

export default connect(mapStateToProps)(translate('translations')(ZipPicker));
