import {createSelector} from 'reselect';
import {selectPackagesState} from "./package";
import {compare, toBool} from "../../utils/common";
import {CHECKLIST_NOT_OK} from "../../components/ChecklistLabour/ChecklistLabour";

const selectChecklistLaboursState = state => state.checklistLabours;

const BRAKE_DISCS_AND_PADS = [3106, 3107];
const BRAKE_PADS = [602, 838];

export const selectChecklistLabours = createSelector(
    [selectChecklistLaboursState, selectPackagesState],
    (checklistLabours, packages) => {

        let brakeDiscs = checklistLabours.find(checklistLabour => {

            let labours = checklistLabour.labours.filter(labour => labour.car_labour).map(labour => labour.car_labour.itemMpId);

            return !compare(labours, BRAKE_DISCS_AND_PADS);
        })

        let brakePads = checklistLabours.find(checklistLabour => {

            let labours = checklistLabour.labours.filter(labour => labour.car_labour).map(labour => labour.car_labour.itemMpId);

            return !compare(labours, BRAKE_PADS);
        })

        return checklistLabours.map(checklistLabour => {

            return {

                ...checklistLabour,

                labours: checklistLabour.labours.map(labour => {
                    let selectedPackage = packages.find(p => p.id === checklistLabour.package_id);

                    return {
                        ...labour,
                        checked: toBool(selectedPackage && selectedPackage.labours.find(l => (labour.car_labour && l.itemMpId === labour.car_labour.itemMpId)))
                    }
                }),

                disabled: toBool(brakeDiscs && brakePads && checklistLabour.id === brakePads.id && brakeDiscs.passed === CHECKLIST_NOT_OK),

                siblings: (brakeDiscs && checklistLabour.id === brakeDiscs.id) ? [brakePads] : [],
            }
        })
    }
)
