import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import Header from "../../components/layout/Header/Header";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import {
    OFFER_CAN_ARCHIVE,
    STATUS_ARCHIVED,
    STATUS_PENDING_MANAGER,
    STATUS_PENDING_USER, statusOfferUserResponded
} from "../../store/consts/status.constants";
import axios from 'app/config/axios'
import {parseResponse, roundCeil} from "../../utils/common";
import {STATUS_ACCEPTED, STATUS_OPEN} from "../../store/consts/package/package.status.constants";
import EmptyList from "../../components/shared/EmptyList/EmptyList";
import Button from "../../components/shared/Button/Button";
import {
    canMarkOfferAsTest,
    USER_CAN_APPROVE_REJECT, USER_CAN_ARCHIVE,
    USER_CAN_REMIND_CUSTOMER,
    USER_ROLE_MANAGER
} from "../../store/consts/user.constants";
import pages from 'app/consts/routes'
import history from 'routes/history'
import Loader, {LOADER_BUTTON, LOADER_LOADING} from "../../components/shared/Loader/Loader";
import Popup from "../../components/shared/Popup/Popup";
import {withFormik} from "formik";
import {formManagerApprove} from "app/config/yup";
import UserData from "../RequestPreviewContainer/UserData";
import {toast} from "react-toastify";
import {omit} from 'lodash'
import "./index.scss";
import {getDraft} from "../../store/actions/draft.actions";
import {LOADER_DRAFT} from "../../store/consts/loader.constants";
import RemindUser from "./RemindUser/RemindUser";
import RequestStatus from "../../components/shared/Requests/RequestStatus";
import PackageStatus from "../../components/shared/PackageStatus";
import Toast from "../../components/shared/Toast/Toast";
import ShowMoreText from 'react-show-more-text'
import {Link} from "react-router-dom";
import BaseCountry from "../../utils/BaseCountry";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import ArchiveOffer from "./ArchiveOffer";
import GarageReinitialize from "../../components/GarageReinitialize/GarageReinitialize";
import MarkOfferAsTest from "./MarkOfferAsTest";
import Badge from "../../components/shared/Badge/Badge";
import {captureException} from "../../utils/captureException";
import {isPackageTypeTires} from "../../store/consts/package/package.type.constants";
import {TIRES_AXEL_FRONT, TIRES_AXELS, TIRES_TYPES} from "../../store/consts/tires.constants";
import PackagePrice from "../../components/PackageComponents/PackagePrice";

const REQUEST_FETCH_URL = 'garage/requests/request'
const REQUEST_APPROVE_URL = 'garage/add-cost-tool/approve-request'
const REQUEST_REJECT_URL = 'garage/add-cost-tool/reject-request'
const REQUEST_REMIND_URL = 'garage/add-cost-tool/remind-request'
const REQUEST_DUPLICATE_URL = 'garage/add-cost-tool/duplicate-request'
const GENERATE_PDF_URL = 'garage/requests/generate-pdf/request'
const GARAGE_APPOINTMENT = 1

class RequestDetailsContainer extends Component {

    state = {
        pdfRendering: false,
        request: null,
        requestLoader: true,
        approving: false,
        rejecting: false,
        reminding: false,
        duplicating: false,
        userDataShow: false,
        remindDataShow: false,
        segments: {
            client: false,
            vehicle: true,
            log: false,
            packages: true,
            packages_accepted: true,
            packages_rejected: false
        },
        statusPackages: STATUS_OPEN,
        initializeGarage: null,
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.request !== prevProps.match.params.request) {
            this.setState({
                requestLoader: true,
            })
            this.fetch();
        }
    }

    fetch = () => {
        const {garage} = this.props;

        axios
            .post(REQUEST_FETCH_URL, {offer_token: this.props.match.params.request})
            .then(response => {
                this.setState({
                    request: response.data,
                    requestLoader: false,
                    userDataShow: false,
                    initializeGarage: response.data.repairShop.id !== garage.id ? response.data.repairShop : null
                })
            })
            .catch(error => {
                captureException(error, REQUEST_FETCH_URL);
                history.push(pages.requests)
            })
    }

    approve = (e) => {
        const {values, setFieldTouched, isValid} = this.props
        const {request} = this.state

        e.preventDefault();

        Object.keys(values).map((field) => setFieldTouched(field, true))

        if (!isValid) {
            return
        }

        this.setState({
            approving: true
        })

        axios
            .post(REQUEST_APPROVE_URL, {
                offer_token: request.token,
                user: omit(values, ['time_due', 'garage_file', 'comment', 'appointment_discounts']),
                offer: {
                    time_due: values.time_due,
                    contract_number: values.garage_file,
                    comment: values.comment,
                    appointment_discounts: values.appointment_discounts,
                }
            })
            .then((response) => {
                this.setState({
                    request: response.data.request,
                    requestLoader: false,
                    userDataShow: false
                })
            })
            .catch(error => {
                captureException(error, REQUEST_APPROVE_URL);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({
                    approving: false,
                })
            })
    }

    reject = (offerToken) => {
        this.setState({
            rejecting: true
        })

        axios
            .post(REQUEST_REJECT_URL, {offer_token: offerToken})
            .then(() => {
                history.push(pages.requests)
            })
            .catch(error => {
                captureException(error, REQUEST_REJECT_URL)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)

                this.setState({
                    rejecting: false,
                })
            })
    }

    remind = (offerToken) => {
        this.setState({
            reminding: true
        })

        axios
            .post(REQUEST_REMIND_URL, {offer_token: offerToken})
            .then((response) => {
                toast.success(<Toast text={parseResponse(response)} type="success"/>)
                this.setState({
                    reminding: false,
                })
            })
            .catch(error => {
                captureException(error, REQUEST_REMIND_URL)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)

                this.setState({
                    reminding: false,
                })
            })
    }

    duplicate = (offerToken) => {
        const {dispatch} = this.props;

        this.setState({
            duplicating: true
        })

        axios
            .post(REQUEST_DUPLICATE_URL, {offer_token: offerToken})
            .then((response) => {
                dispatch(getDraft(response.data.offer_token))
                this.setState({duplicating: false})
            })
            .catch(error => {
                captureException(error, REQUEST_DUPLICATE_URL)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({duplicating: false})
            })
    }

    showApprove = () => {
        const {values, setFieldValue} = this.props
        const {request} = this.state

        Object.keys(values).map((field) => request.user[field] ? setFieldValue(field, request.user[field]) : null)
        Object.keys(values).map((field) => request.offer[field] ? setFieldValue(field, request.offer[field]) : null)

        this.setState({userDataShow: true})
    }

    showRemind = () => {
        // const {values, setFieldValue} = this.props
        // const {request} = this.state

        // Object.keys(values).map((field) => request.user[field] ? setFieldValue(field, request.user[field]) : null)

        this.setState({remindDataShow: true})
    }

    printPdf = (offerToken) => {
        const {pdfRendering} = this.state;

        if (pdfRendering) return

        this.setState({
            pdfRendering: true
        })

        axios
            .post(GENERATE_PDF_URL, {offer_token: offerToken})
            .then((response) => {
                window.open(response.data.download_pdf_url);
                this.setState({
                    pdfRendering: false
                })
            })
            .catch(error => {
                captureException(error, GENERATE_PDF_URL)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({
                    pdfRendering: false
                })
            })
    }

    renderPackage = () => {
        const {t} = this.props;
        const {request, pdfRendering} = this.state;

        let totalPrice = 0, totalAcceptedPrice = 0,
            selectedAppointment = request.appointment_discounts.find(appointment => appointment.selected && !appointment.is_today);

        return (
            <div className="package-collapsable">
                <div className="package-collapsable-header">
                    <div
                        className={`package-collapsable-header__title request-details`}>{t('pages.request_details.package.packages.title')}</div>

                    <div className="float-right text-right">
                        {request.packages.find(pack => pack.status === STATUS_ACCEPTED) &&
                        <div>
                                <span disabled={pdfRendering}
                                      onClick={() => this.printPdf(request.token)} className="link-style bold lh-10">
                                    {t('pages.request_details.print_pdf')}
                                </span>
                        </div>
                        }

                        {request.checklistLabours.length ?
                            <div>
                                <Link to={`${pages.request_details}${request.token}${pages.request_checklist}`}
                                      className="bold lh-10">
                                    {t('pages.request_details.checklist_link')}
                                </Link>
                            </div>
                            : null}
                    </div>

                    {this.renderOfferComment(request.offer.comment)}
                </div>
                <div className={`package-collapsable-content`}>

                    {request.packages.length === 0 ?
                        <div className="pv-30 relative"><EmptyList label={t('pages.request_details.no_packages')}/>
                        </div>
                        :
                        <div className="mt-10">
                            <div>
                                {request.packages.map((pack, key) => {

                                    totalPrice += pack.price;

                                    if (pack.status === STATUS_ACCEPTED) {
                                        totalAcceptedPrice += pack.price;
                                    }

                                    return (
                                        <div key={pack.id} className="request-package-item">
                                            <div key={key} className="row">
                                                <div className="col-xs-9">
                                                    <span className="font-size-sm">{pack.name}</span>
                                                    <span className="vertical-text-delimiter">|</span>
                                                    <span className="font-size-sm bold"><PackagePrice pack={pack}
                                                                                                      fullPrice={true}/></span>

                                                    {this.renderPackageDetails(pack)}
                                                </div>
                                                <div className="col-xs-3 text-right">
                                                    <div className="link-style font-size-xs bold"
                                                         onClick={() => history.push(`${pages.request_details}${request.token}${pages.request_package_details}${pack.id}`)}>
                                                        <i className="icon-eye"/> Details
                                                    </div>
                                                </div>
                                                {this.renderPackageStatus(pack.status)}
                                                {this.renderPackageComment(pack.comment)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {statusOfferUserResponded(request.status) &&
                            <>
                                <hr className="hr hr--dashed mb-10"/>

                                {selectedAppointment &&
                                <div className="row mt-10">
                                    {(selectedAppointment.type === GARAGE_APPOINTMENT) &&
                                    <div>
                                        <span
                                            className="col-xs-6">{t('pages.request_details.appointment_discount')}</span>
                                        <span
                                            className="col-xs-6 small text-right">({selectedAppointment.datetime}) ({selectedAppointment.discount}%)</span>
                                    </div>
                                    }
                                </div>
                                }

                                <div className="row mt-10">
                                    <span
                                        className="col-xs-6 bold">{t('pages.request_details.total_accepted_with_vat')}</span>
                                    <span className="col-xs-6 bold text-right">
                                        <span
                                            className="total-border">{roundCeil(totalAcceptedPrice, true)} {BaseCountry().currency}</span>
                                    </span>
                                </div>
                                <div className="row mt-10">
                                    <span className="col-xs-6 bold">{t('global.total_with_vat')}</span>
                                    <span className="col-xs-6 bold text-right">
                                        <span
                                            className="total-border">{roundCeil(totalPrice, true)} {BaseCountry().currency}</span>
                                    </span>
                                </div>
                            </>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderPackageDetails = (_package) => {
        const {t} = this.props;

        if (isPackageTypeTires(_package.type)) {

            let selectedTire = _package.tires.list.find(tireType => tireType.selected)

            if (!selectedTire) {
                return null;
            }

            return (
                <div>
                    <div className="mv-10">{selectedTire.full_name}</div>

                    {selectedTire.same_tire_on_both_axel ?
                        (
                            <div className="small mb-10">
                                <div><span
                                    className="text-lighter">{t('tires.axel.both')}:</span> {selectedTire[TIRES_AXEL_FRONT].full_axel}
                                </div>
                                <div><span
                                    className="text-lighter">{t('tires.ean')}:</span> {selectedTire[TIRES_AXEL_FRONT].ean}
                                </div>
                                <div><span
                                    className="text-lighter">{t('tires.sap')}:</span> {selectedTire[TIRES_AXEL_FRONT].code_sap}
                                </div>
                            </div>
                        )
                        :
                        TIRES_AXELS.filter(axel => selectedTire[axel]).map(axel => (
                            <div className="small mb-10" key={axel}>
                                <div><span className="text-lighter">{t(`tires.axel.${axel}`)}:</span> {selectedTire[axel].full_axel}</div>
                                <div><span className="text-lighter">{t('tires.ean')}:</span> {selectedTire[axel].ean}</div>
                                <div><span className="text-lighter">{t('tires.sap')}:</span> {selectedTire[axel].code_sap}</div>
                            </div>
                        ))
                    }

                    <div className="small mb-10">
                        {_package.tires.additional.length > 0 && _package.tires.additional.map(item => (
                            <div key={item.item_mp_id}>{item.name}</div>
                        ))}
                    </div>

                </div>
            )
        }

        return null;
    }

    renderPackageStatus = (status) => {
        return (<div className="col-xs-12">
            <PackageStatus status={status}/>
        </div>)
    };

    renderPackageComment = (comment) => {
        const {t} = this.props;

        if (!comment) return null
        return (
            <div className="col-xs-12 user-comment comment-text-box">
                <hr className="hr hr--dashed mb-10"/>
                <ShowMoreText
                    lines={1}
                    more={t('pages.request_details.read_more')}
                    less={t('pages.request_details.read_less')}
                    anchorClass=''
                    expanded={false}
                    width={420}
                    keepNewLines={true}
                >
                    {comment}
                </ShowMoreText>
            </div>
        )
    };

    renderOfferComment = (comment) => {
        const {t} = this.props;

        if (!comment) return null
        return (
            <div className="col-xs-12 offer-comment comment-text-box">
                <ShowMoreText
                    lines={1}
                    more={t('pages.request_details.read_more')}
                    less={t('pages.request_details.read_less')}
                    anchorClass=''
                    expanded={false}
                    width={420}
                    keepNewLines={true}
                >
                    {comment}
                </ShowMoreText>

            </div>
        )
    };

    renderButtons = () => {
        const {user, t} = this.props;
        const {request, approving, rejecting, reminding} = this.state;

        return (
            <div>
                {(request.status === STATUS_PENDING_MANAGER && USER_CAN_APPROVE_REJECT.indexOf(user.type) !== -1) &&
                <div className="row">
                    <div className="col-xs-6">
                        <Button size="md" type="danger" extraClass="btn-danger--outline btn-block"
                                disabled={approving || rejecting}
                                onClick={() => this.reject(request.token)}>
                            <Loader isLoading={rejecting} type={LOADER_BUTTON}/>
                            {t('pages.request_details.reject_request')}</Button>
                    </div>
                    <div className="col-xs-6">
                        <Button size="md" type="primary" disabled={rejecting} extraClass="btn-block"
                                onClick={this.showApprove}>
                            {t('pages.request_details.approve_request')}</Button>
                    </div>
                </div>
                }
                {(request.status === STATUS_PENDING_USER && USER_CAN_REMIND_CUSTOMER.indexOf(user.type) !== -1) &&
                <SubmitContainer stat={true}>
                    <div className="submit-btn-padd">
                        <Button size="lg" type="primary" disabled={reminding}
                                onClick={() => this.showRemind(request.token)}>
                            <Loader isLoading={reminding} type={LOADER_BUTTON}/>{t('pages.request_details.remind_user')}
                        </Button>
                    </div>
                </SubmitContainer>
                }
            </div>
        )
    }

    toggle = (segment) => {
        const {segments} = this.state;

        this.setState({segments: {...segments, ...{[segment]: !segments[segment]}}})
    }

    renderOfferData = () => {
        const {t, country} = this.props
        const {request, segments} = this.state

        return (
            <div className="mb-50">
                <div className="package-collapsable">
                    <div className="package-collapsable-header" onClick={() => this.toggle('client')}>
                        <div
                            className="package-collapsable-header__title">{t('pages.request_details.package.client.title')}</div>
                        <div className="package-collapsable-header__icon"><span
                            className={`icon-chevron-${segments.client ? 'down' : 'up'}`}/></div>
                    </div>

                    {request.user &&
                    <div className={`package-collapsable-content ${!segments.client ? 'hide' : ''}`}>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.first_name')}</span>
                            <span>{request.user.first_name} {request.user.last_name}</span>
                        </div>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.phone')}</span>
                            <span>{request.user.phone}</span>
                        </div>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.email')}</span>
                            <span>{request.user.email}</span>
                        </div>
                    </div>
                    }
                </div>

                <div className="package-collapsable">
                    <div className="package-collapsable-header" onClick={() => this.toggle('vehicle')}>
                        <div
                            className="package-collapsable-header__title">{t('pages.request_details.package.vehicle.title')}</div>
                        <div className="package-collapsable-header__icon"><span
                            className={`icon-chevron-${segments.vehicle ? 'down' : 'up'}`}/></div>
                    </div>

                    <div className={`package-collapsable-content ${!segments.vehicle ? 'hide' : ''}`}>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.make')}</span>
                            <span>{request.car.make}</span>
                        </div>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.range')}</span>
                            <span>{request.car.range}</span>
                        </div>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.type')}</span>
                            <span>{request.car.type}</span>
                        </div>
                        <div className="package-collapsable-item">
                            <span>{t('form.label.first_registration')}</span>
                            <span>{request.car.year}</span>
                        </div>
                        {request.car.licence_plate &&
                        <div className="package-collapsable-item">
                            <span>{t('form.label.licence_plate')}</span>
                            <span>{request.car.licence_plate}</span>
                        </div>
                        }
                        {(!request.car.licence_plate && request.car.car_number) &&
                        <div className="package-collapsable-item">
                            <span>{t(`pages.request_details.car_number.${country.code}`)}</span>
                            <span>{request.car.car_number}</span>
                        </div>
                        }
                        {request.offer &&
                        <div className="package-collapsable-item">
                            <span>{t('global.car.contract_number')}</span>
                            <span>{request.offer.contract_number}</span>
                        </div>
                        }
                        {request.mileage &&
                        <div className="package-collapsable-item">
                            <span>{t('global.car.mileage')}</span>
                            <span>{request.mileage}</span>
                        </div>
                        }
                    </div>
                </div>

                <div className="package-collapsable">
                    <div className="package-collapsable-header" onClick={() => this.toggle('log')}>
                        <div
                            className="package-collapsable-header__title">{t('pages.request_details.package.log.title')}</div>
                        <div className="package-collapsable-header__icon"><span
                            className={`icon-chevron-${segments.log ? 'down' : 'up'}`}/></div>
                    </div>

                    <div className={`package-collapsable-content ${!segments.log ? 'hide' : ''}`}>
                        {request.log.map((log, key) => (
                            <div className="package-collapsable-item" key={key}>
                                <span>{t('pages.request_details.package.log.status.' + log.status)}</span>
                                <span>{log.created_at}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    renderLinks = () => {
        const {t, loader} = this.props;
        const {request, duplicating} = this.state;

        return (
            <div>
                {request.status !== STATUS_PENDING_MANAGER &&
                <SubmitContainer stat={true}>
                    <div className="mt-20 text-center">
                        <Button disabled={duplicating || loader[LOADER_DRAFT]} size="md" type="primary"
                                extraClass={"btn-primary--outline btn-round--sm btn-shadow btn-block"}
                                onClick={() => this.duplicate(request.token)}>
                            {t('pages.request_details.duplicate_request')}
                            <Loader isLoading={duplicating || loader[LOADER_DRAFT]} type={LOADER_BUTTON}/>
                        </Button>
                    </div>
                </SubmitContainer>
                }
            </div>
        )
    }

    renderArchive = () => {
        const {user} = this.props;
        const {request} = this.state;

        if (!(OFFER_CAN_ARCHIVE.indexOf(request.status) !== -1 && USER_CAN_ARCHIVE.indexOf(user.type) !== -1)) {
            return null
        }

        return (
            <ArchiveOffer
                request={request}
                onSuccess={() => this.setState({request: {...request, status: STATUS_ARCHIVED}})}
            />
        )
    }

    renderMarkAsTest = () => {
        const {user} = this.props;
        const {request} = this.state;

        if (!canMarkOfferAsTest(user.type)) {
            return null;
        }

        return (
            <MarkOfferAsTest
                request={request}
                onSuccess={(marked) => this.setState({request: {...request, is_test: marked}})}
            />
        )
    }

    renderIsTest = () => {
        const {t} = this.props;
        const {request} = this.state;

        if (!request.is_test) return null;

        return (
            <div className="mb-30">
                <Badge type="primary" inverted={true}>
                    {t('pages.request_details.is_test')}
                </Badge>
            </div>
        )
    }

    smsDelivery = () => {
        const {t} = this.props;
        const {request} = this.state;

        if (request.sms) {
            if (request.sms.status === 'delivered') {
                return <div className="sms-delivery-note sms-delivery-note--sent"><i
                    className="icon-double-check"/> {t('pages.request_details.sms.delivered')}</div>
            }

            return <div className="sms-delivery-note sms-delivery-note--in-progress"><i
                className="icon-check"/> {t('pages.request_details.sms.in_progress')}</div>
        }

        return null
    }

    renderStatus = () => {
        const {t, user, dispatch, loader} = this.props;
        const {request} = this.state;

        return (
            <div className="package-collapsable">
                <div className="package-collapsable-header">
                    <div
                        className="package-collapsable-header__title">{t('pages.request_details.package.packages_status.title')}</div>
                </div>

                <div className={`package-collapsable-content`}>
                    <div className="row">
                        <div className="col-sm-12 col-xs-7 text-left">
                            <RequestStatus status={request.status}/>
                        </div>
                        {request.status === STATUS_PENDING_USER &&
                        <div className={`col-sm-12 col-xs-5 text-right-xs bold`}
                             onClick={() => this.showRemind(request.token)}>
                            <span className="link-style"><i
                                className="icon-send font-size-default"/> {t('pages.request_details.remind_user')}</span>
                            {this.smsDelivery()}
                        </div>
                        }

                        {(user.type === USER_ROLE_MANAGER && request.status === STATUS_PENDING_MANAGER) &&
                        <div className={`col-sm-12 col-xs-5 text-right-xs bold`}>
                            {loader[LOADER_DRAFT] ?
                                <span className="link-style"><i
                                    className="icon-bell font-size-default"/><Loader type={LOADER_LOADING}
                                                                                     isLoading={true}/> {t('global.loading')}</span>
                                :
                                <span className="link-style" onClick={() => dispatch(getDraft(request.token))}>
                                            <i className="icon-bell font-size-default"/> {t('pages.request_details.edit_request')}
                                        </span>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderResponseTime = () => {
        const {t} = this.props;
        const {request} = this.state;

        if (!request.response_time) return null;

        return (
            <div className="package-collapsable">
                <div className="package-collapsable-header">
                    <div
                        className="package-collapsable-header__title">{t('pages.request_details.package.response_time.title')}</div>
                </div>

                <div className={`package-collapsable-content`}>
                    <div className="font-size-sm">{request.response_time}</div>
                </div>
            </div>
        )
    }

    render() {
        const {t, title, values, touched, errors, handleBlur, handleChange, setFieldValue, user, garage} = this.props;
        const {requestLoader, request, userDataShow, remindDataShow, approving, initializeGarage} = this.state;

        return (
            <React.Fragment>
                <Header title={t(title)}/>

                <div className="container container--has-submit-container">
                    {requestLoader ? <ListLoader/> :
                        <div className="relative">
                            <div className="row">
                                <div className="col-xs-12 col-sm-4">
                                    {this.renderIsTest()}
                                    {this.renderStatus()}
                                    {this.renderResponseTime()}
                                    {this.renderOfferData()}
                                    {this.renderArchive()}
                                    {this.renderMarkAsTest()}
                                </div>
                                <div className="col-sm-1 hide-on-mobile">
                                    <div className="vertical-line-delimiter"/>
                                </div>
                                <div className="col-xs-12 col-sm-7">
                                    <div className="relative">
                                        {this.renderPackage()}
                                        {this.renderLinks()}
                                        {this.renderButtons()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {!requestLoader &&
                <Popup
                    title={t('pages.request_details.client.approve.title')}
                    subtitle={t('pages.request_details.client.approve.title')}
                    visible={userDataShow}
                    contentScrollable={true}
                    onClose={() => this.setState({userDataShow: false})}
                >
                    <form onSubmit={this.approve}>

                        <UserData
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleFieldChange={(name, value) => {
                                this.props.setFieldTouched(name, true);
                                this.props.setFieldValue(name, value);
                            }}
                            user={user}
                            garage={garage}
                            packages={request.packages}
                            selectedAppointments={request.appointment_discounts}
                            onUserFound={(e) => {
                                Object.keys(e.item).map(key => {
                                    this.props.setFieldTouched(key, true);
                                    this.props.setFieldValue(key, e.item[key]);
                                })
                            }}
                            onUserReset={() => {
                                Object.keys(values).map((field) => request.user.hasOwnProperty(field) ? this.props.setFieldValue(field, request.user[field]) : null)
                            }}
                        />

                        <Button size="md" type="primary" extraClass={"btn-block"} disabled={approving}>
                            <Loader isLoading={approving} type={LOADER_BUTTON}/>
                            <div className="font-size-2">{t('pages.request_preview.send')}</div>
                        </Button>

                    </form>
                </Popup>
                }
                {!requestLoader &&
                <Popup
                    title={t('pages.request_details.client.remind.title')}
                    subtitle={t('pages.request_details.client.remind.subtitle')}
                    visible={remindDataShow}
                    onClose={() => this.setState({remindDataShow: false})}
                >
                    <RemindUser
                        offerToken={request.token}
                        client={request.user}
                    />
                </Popup>
                }

                {initializeGarage &&
                <GarageReinitialize
                    garage={initializeGarage}
                    onSuccess={() => this.setState({initializeGarage: null})}
                />
                }
            </React.Fragment>
        );
    }
}

const ManagerApproveForm = withFormik(formManagerApprove)(RequestDetailsContainer);

const mapStateToProps = (state) => {
    return {
        user: state.user,
        requests: state.requests,
        garage: state.garage,
        loader: state.loader,
        client: state.client,
        country: state.country,
    };
};

export default connect(mapStateToProps)(translate('translations')(ManagerApproveForm));
