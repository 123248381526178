export const CHANGE_CUSTOMER_SIGNATURE_SETTING = '[CHANGE CUSTOMER SIGNATURE SETTING]'

export const CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE = `${CHANGE_CUSTOMER_SIGNATURE_SETTING} UPDATE`

export const changeCustomerSignatureUpdate = (signature) => ({
    type: CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE,
    payload: {
        signature,
    },
})
