import React, {Component} from 'react';
import {translate} from "react-i18next";
import {CHECKLIST_NOT_OK, CHECKLIST_OK} from "../ChecklistLabour";
import ChecklistLayout from "../components/ChecklistLayout";

class NormalType extends Component {

    handleChange = (passed) => {
        const {labour, packageFlow, labourFieldUpdate, onUpdatePassed} = this.props;

        if (passed === CHECKLIST_NOT_OK) {
            labourFieldUpdate({updating: true, passed: passed}).then(() => {
                packageFlow(labour.labours.map(labour => labour.car_labour).filter(l => l !== null))
            })
        }
        else if (passed === CHECKLIST_OK) {
            labourFieldUpdate({updating: true, passed: passed}).then(() => {
                onUpdatePassed();
            })
        }
    };

    render() {
        const {t, labour, showNotePopup} = this.props;

        let noteDisabled = labour.passed === null || labour.updating;

        return (
            <React.Fragment>
                <div className="checklist-labour">
                    <div className="col-xs-8">
                        <div className="checklist-labour__name">
                            {labour.name}
                        </div>
                    </div>
                    <ChecklistLayout name={"checklist-labour"} handleChange={this.handleChange} labour={labour}/>
                    <div className="col-xs-2">
                        <div className="checklist-labour__btn">
                            <span className="link-style" disabled={noteDisabled} onClick={!noteDisabled ? showNotePopup : null}>
                                {labour.note ? t('pages.checklist_labours.note.view') : t('pages.checklist_labours.note.new')}
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default translate('translations')(NormalType);
