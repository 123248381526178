import React from "react";
import {fixedFloat, roundCeil} from "../../utils/common";
import {sumPackagePrice, sumPackagePriceTotal} from "../../store/selectors/packagePrices";
import BaseCountry from "../../utils/BaseCountry";
import {isPackageTypeTires} from "../../store/consts/package/package.type.constants";
import {
    TIRES_TYPE_BRAND_PREMIUM,
    TIRES_TYPE_BRAND_PRICE_QUALITY,
    TIRES_TYPES
} from "../../store/consts/tires.constants";

class PackagePrice extends React.Component {

    render() {

        const {pack, recalculate, fullPrice} = this.props;

        const PackageTiresPrice = () => {

            let selectedTire = pack.tires.list.find(tireType => tireType.selected)

            if(selectedTire){
                if (fullPrice) {
                    return <>
                        {roundCeil(selectedTire.price_with_tax, true)} {BaseCountry().currency}
                    </>
                }

                return <>
                    {fixedFloat(selectedTire.price)} {BaseCountry().currency}
                </>
            }

            if (fullPrice) {
                return <>
                    {pack.tires.list.map(tireType => roundCeil(tireType.price_with_tax, true)).sort((a, b) => a - b).join(' - ')} {BaseCountry().currency}
                </>
            }

            return <>
                {pack.tires.list.map(tireType => fixedFloat(tireType.price)).sort((a, b) => a - b).join(' - ')} {BaseCountry().currency}
            </>
        }

        const PackagePrice = () => {

            if (recalculate) {
                if (fullPrice) {
                    return <>{fixedFloat(sumPackagePriceTotal(pack))} {BaseCountry().currency}</>
                }

                return <>{fixedFloat(sumPackagePrice(pack))} {BaseCountry().currency}</>
            }
            else {
                if (fullPrice) {
                    return <>{roundCeil(pack.price, true)} {BaseCountry().currency}</>
                }

                return <>{fixedFloat(sumPackagePrice(pack))} {BaseCountry().currency}</>
            }
        }

        return (
            <>
                {isPackageTypeTires(pack.type) ?
                    <PackageTiresPrice/>
                    :
                    <PackagePrice/>
                }
            </>
        )
    }
}

export default PackagePrice;
