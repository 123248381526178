import React, { useState } from 'react'
import { translate } from 'react-i18next'
import './index.scss'
import Popup from '../../Popup/Popup'
import FormMechanic from '../../../forms/FormMechanic'
import { connect } from 'react-redux'
import { mechanicApiUpdate } from '../../../../store/actions/mechanic.actions'

export const MechanicDetail = props => {
    const { t, formSubmitting } = props
    const [isEditingOne, setIsEditingOne] = useState(false)

    let togglePopup = () => {
        setIsEditingOne(!isEditingOne)
    }

    let handlePopupSubmit = newMechanicValues => {
        props.dispatch(
            mechanicApiUpdate({
                ...newMechanicValues,
                id: props.person.id,
            }),
        )
    }

    let renderMechanic = () => {
        return (
            <div className="mechanic-detail clearfix">
                <div className="mechanic-detail-row col-xs-6">{t('form.placeholder.first_name')}</div>
                <div className="mechanic-detail-row right col-xs-6">{props.person.first_name}</div>

                <div className="mechanic-detail-row col-xs-6">{t('form.placeholder.last_name')}</div>
                <div className="mechanic-detail-row right col-xs-6">{props.person.last_name}</div>

                <div className="mechanic-detail-row col-xs-6">{t('form.placeholder.email')}</div>
                <div className="mechanic-detail-row right col-xs-6">
                    {props.person.email ? props.person.email : '\u00A0'}
                </div>

                <div className="mechanic-detail-row col-xs-6">{t('form.placeholder.phone')}</div>
                <div className="mechanic-detail-row right col-xs-6">
                    {props.person.phone ? props.person.phone : '\u00A0'}
                </div>
            </div>
        )
    }

    let renderButtons = () => {
        const { t } = props

        return (
            <div className="mechanic-detail-button-container">
                <div className="mechanic-detail-button" onClick={togglePopup}>
                    <span className="link-style edit-link">
                        <i className="icon-edit" /> {t('global.edit')}
                    </span>
                </div>
                {/*<div className="mechanic-detail-button col-md-6" onClick={handleRemoval}>*/}
                {/*  <span className="icon icon-cancel"></span>*/}
                {/*  {t('global.remove')}*/}
                {/*</div>*/}
            </div>
        )
    }

    return (
        <div className="mechanic-detail-container clearfix">
            <Popup
                title={t('pages.mechanics.add_new_person')}
                visible={isEditingOne}
                onClose={togglePopup}
                contentScrollable={true}
            >
                <FormMechanic
                    excludePasswordValidation
                    isFormSubmitting={formSubmitting.mechanics}
                    initialValues={props.person}
                    mechanic={props.person}
                    buttonLabel={t('global.update')}
                    onSubmit={handlePopupSubmit}
                />
            </Popup>

            {renderMechanic()}
            {renderButtons()}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        formSubmitting: state.formSubmitting,
    }
}

export default connect(mapStateToProps)(translate('translations')(MechanicDetail))
