import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import Header from "../../components/layout/Header/Header";
import {fixedFloat, parseResponse, roundCeil} from "../../utils/common";
import axios from 'app/config/axios'
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import "./index.scss";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import {STATUS_PENDING_MANAGER} from "../../store/consts/status.constants";
import {toast} from "react-toastify";
import history from 'routes/history'
import pages from 'app/consts/routes'
import {USER_CAN_EDIT_PACKAGE} from "../../store/consts/user.constants";
import {LOADER_DRAFT} from "../../store/consts/loader.constants";
import {getDraft} from "../../store/actions/draft.actions";
import {packagesFileAdd, packagesFileRemove} from "../../store/actions/packages.actions";
import PackageUrgency from "../../components/shared/PackageUrgency";
import Loader, {LOADER_BUTTON} from "../../components/shared/Loader/Loader";
import Button from "../../components/shared/Button/Button";
import Toast from "../../components/shared/Toast/Toast";
import {DISCOUNT_BRAKES_TYPE, DISCOUNT_PARTS_TYPE} from "../../store/consts/discounts.constants";
import {brakeDiscount, partDiscount} from "../../store/selectors/packagePrices";
import BaseCountry from "../../utils/BaseCountry";
import {TYPE_OFFICIAL, TYPE_TIRES} from "../../store/consts/package/package.type.constants";
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR, TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPES,
} from "../../store/consts/tires.constants";
import PackagePrice from "../../components/PackageComponents/PackagePrice";
import {captureException} from "../../utils/captureException";

const REQUEST_PACKAGE_FETCH_URL = 'garage/requests/package'

class RequestPackageContainer extends Component {

    state = {
        package: null,
        request: null,
        requestLoader: true,
        segments: {labours: true, works: true, parts: true, tires: true, files: false, description: false}
    };

    componentDidMount() {
        this.fetch()
    }

    fetch = () => {
        axios.post(REQUEST_PACKAGE_FETCH_URL, {
            offer_token: this.props.match.params.request,
            package_id: this.props.match.params.packageId
        })
            .then(response => {
                this.setState({
                    package: response.data.package,
                    request: response.data.request,
                    requestLoader: false,
                })
            })
            .catch(error => {
                captureException(error, REQUEST_PACKAGE_FETCH_URL);

                if (error.response.status === 404) {
                    history.push(`${pages.request_details}${this.props.match.params.request}`)
                } else {
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)

                    this.setState({
                        requestLoader: false,
                    })
                }
            })
    }

    toggle = (segment) => {
        const {segments} = this.state;

        this.setState({segments: {...segments, ...{[segment]: !segments[segment]}}})
    }

    renderPackage = () => {
        const {t, dispatch, user, loader} = this.props;
        const {segments, request} = this.state;

        let labours = this.state.package.labours;
        let customLabours = labours.filter(l => l.priceFixed);
        let works = labours.filter(l => !l.priceFixed).map(l => l.works).flat(1).filter(w => w.selected === true)
        let parts = labours.map(l => l.works).flat(1).filter(w => w.selected === true).map(work => work.parts).flat(1).filter(p => p.selected === true)

        const Works = () => {
            return <div className="package-collapsable">
                <div className="package-collapsable-header">
                    <div
                        className="package-collapsable-header__title">{t('pages.request_details.package.works.title')}</div>
                </div>

                <div className={`package-collapsable-content ${!segments.works ? 'hide' : ''}`}>
                    {customLabours.map(customLabour => {
                        return (
                            <div key={customLabour.id}>
                                <div className="package-collapsable-item">
                                    <span className="font-weight-regular">{customLabour.name}</span>
                                    <span>{customLabour.priceFixed} {BaseCountry().currency}</span>
                                </div>
                            </div>
                        )
                    })}
                    {works.map(work => {
                        return (
                            <div key={work.id}>
                                <div className="package-collapsable-item">
                                    <span className="font-weight-regular">{work.name}</span>
                                    <span>{work.duration}h</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        }

        const Parts = () => {
            return <div className="package-collapsable">
                <div className="package-collapsable-header">
                    <div
                        className="package-collapsable-header__title">{t('pages.request_details.package.parts.title')}</div>
                </div>

                <div className={`package-collapsable-content ${!segments.parts ? 'hide' : ''}`}>
                    {parts.map(part => {

                        let version = part.versions.find(v => v.selected === true)

                        return (
                            <div key={part.id}>
                                <div className="package-collapsable-item">
                                    <span className="font-weight-regular">{part.name} <span
                                        className="text-lighter bold">{version.name}</span></span>
                                    <span/>
                                    {version.set.filter(s => s.selected === true).map(set => {
                                        return (
                                            <div key={set.id} className="package-collapsable-item">
                                                <div>
                                                    <span>{set.name}</span>
                                                    <span className="text-color bold"><span
                                                        className="part-set-amounts ">{set.amounts}x</span> {roundCeil(set.price, true)} {BaseCountry().currency}</span>
                                                </div>
                                                {set.partNo ? (
                                                    <div
                                                        className="font-weight-light text-lighter">{t('global.part_number')} {set.partNo}</div>) : ''}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        }

        const Tires = () => {

            let selectedTire = this.state.package.tires.list.find(tireType => tireType.selected)

            let tireData = (tireType, axel, bothAxel) => {
                return (
                    <div className="mt-10">
                        <div className="row">
                            <div className="col-xs-2 col-sm-1">
                                {bothAxel ?
                                    <span className="text-lighter">{t('tires.axel.both')}</span>
                                    :
                                    <span className="text-lighter">{t('tires.axel.' + axel)}</span>
                                }
                            </div>
                            <div className="col-xs-5 col-sm-2">{tireType[axel].full_axel}</div>
                        </div>
                        <div className="row">
                            <div className="col-xs-2 col-sm-1"><span className="text-lighter">{t('tires.ean')}</span>
                            </div>
                            <div className="col-xs-5 col-sm-3">{tireType[axel].ean}</div>
                        </div>
                        <div className="row">
                            <div className="col-xs-2 col-sm-1"><span className="text-lighter">{t('tires.sap')}</span>
                            </div>
                            <div
                                className="col-xs-5 col-sm-3">{tireType[axel].code_sap}</div>
                        </div>
                    </div>
                )
            }

            return <div className="package-collapsable">
                <div className="package-collapsable-header">
                    <div
                        className="package-collapsable-header__title">{t('pages.request_details.package.tires.title')}</div>
                </div>

                <div className={`package-collapsable-content ${!segments.tires ? 'hide' : ''}`}>

                    {this.state.package.tires.list.map(tireType =>
                        <div className="package-collapsable-item mt-20" key={tireType.type}>
                            <div>
                                {(selectedTire && selectedTire.type === tireType.type) &&
                                    <i className="icon-check-fill brand-success mr-10 font-size-default"/>
                                }

                                <i>{tireType.full_name} - {t(`tires.season.${tireType.season}`)} - <span className="bold">{fixedFloat(tireType.price_tires_with_tax)} {BaseCountry().currency}</span></i>
                            </div>

                            {tireType.same_tire_on_both_axel ?
                                <>
                                    {tireType[TIRES_AXEL_FRONT] &&
                                        tireData(tireType, TIRES_AXEL_FRONT, true)
                                    }
                                </>
                                :
                                <>
                                    {tireType[TIRES_AXEL_FRONT] &&
                                        tireData(tireType, TIRES_AXEL_FRONT)
                                    }

                                    {tireType[TIRES_AXEL_REAR] &&
                                        tireData(tireType, TIRES_AXEL_REAR)
                                    }
                                </>
                            }
                        </div>
                    )}

                    <div className="package-collapsable-item mt-20">
                        <i>
                            {t('pages.request_details.package.tires.mounting_total_price')} x{this.state.package.tires.tires_to_change} - <span
                            className="bold">{fixedFloat(this.state.package.tires.price_work_with_tax)} {BaseCountry().currency}</span>
                        </i>
                    </div>

                    <div className="mt-20">
                        {this.state.package.tires.additional.map(additional => (
                            <div className="package-collapsable-item mt-10" key={additional.item_mp_id}>
                                {additional.selected_by_customer ?
                                    <i className="icon-check-fill brand-success mr-10 font-size-default"/>
                                    :
                                    <i className="icon-cancell-fill brand-danger mr-10 font-size-default"/>
                                }
                                <i>
                                    {additional.name} x{this.state.package.tires.tires_to_change} - <span
                                    className="bold">{fixedFloat(additional.price_with_tax)} {BaseCountry().currency}</span>
                                </i>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="package-collapsable">
                    <div className="package-collapsable-header">
                        <div className="package-collapsable-header__title">
                            {`${this.state.package.name}`} {this.state.package.type === TYPE_OFFICIAL ? ` - ${this.state.package.officialService.mileage}km` : ''}
                        </div>
                    </div>

                    <div className={`package-collapsable-content ${!segments.labours ? 'hide' : ''}`}>
                        {labours.map(labour => {
                            return (
                                <div key={labour.itemMpId} className="mb-15">
                                    <div className="mb-5 font-size-sm">{labour.name}</div>
                                    <PackageUrgency urgency={this.state.package.urgency}/>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {this.state.package.type === TYPE_TIRES ? <Tires/> : <><Works/><Parts/></>}

                <div className="package-collapsable">
                    <div className="package-collapsable-header">
                        <div
                            className="package-collapsable-header__title">{t('pages.request_details.package.files.title')}</div>
                    </div>

                    <div className={`package-collapsable-content`}>
                        {this.state.package.files.length > 0 ?
                            <FileUpload
                                files={this.state.package.files}
                                edit={request.status === STATUS_PENDING_MANAGER}
                                formData={{
                                    offer_token: request.token,
                                    packageId: this.state.package.id
                                }}
                                onAddFile={(file) => this.props.dispatch(packagesFileAdd(this.state.package, file))}
                                onRemoveFile={(file) => this.props.dispatch(packagesFileRemove(this.state.package, file))}
                            />
                            :
                            <div
                                className="font-size-sm font-weight-light text-lighter">{t('pages.package_details.no_files')}</div>
                        }
                    </div>
                </div>

                <div className="package-collapsable">
                    <div className="package-collapsable-header">
                        <div
                            className="package-collapsable-header__title">{t('pages.request_details.package.description.title')}</div>
                    </div>

                    <div className={`package-collapsable-content`}>
                        {this.state.package.description ?
                            <p>{this.state.package.description}</p>
                            :
                            <div
                                className="font-size-sm font-weight-light text-lighter">{t('pages.package_details.no_description')}</div>
                        }
                    </div>
                </div>

                <hr className="hr"/>

                <div>
                    {this.state.request.offer.discounts.find(discount => discount.type === DISCOUNT_PARTS_TYPE) && partDiscount(this.state.package) > 0 ?
                        <div className="package-collapsable-item package-collapsable-item--big">
                            <span>{t('pages.package_details.discount_on_parts')}</span>
                            <span>{this.state.request.offer.discounts.find(discount => discount.type === DISCOUNT_PARTS_TYPE).discount}%</span>
                        </div> : null
                    }

                    {this.state.request.offer.discounts.find(discount => discount.type === DISCOUNT_BRAKES_TYPE) && brakeDiscount(this.state.package) > 0 ?
                        <div className="package-collapsable-item package-collapsable-item--big">
                            <span>{t('pages.package_details.discount_on_brakes')}</span>
                            <span>{this.state.request.offer.discounts.find(discount => discount.type === DISCOUNT_BRAKES_TYPE).discount}%</span>
                        </div> : null
                    }

                    {this.state.package.discount.price ?
                        <div className="package-collapsable-item package-collapsable-item--big">
                            <span>{t('global.discount')}{(this.state.package.discount.name !== null && this.state.package.discount.name !== '') &&
                            <span> ({this.state.package.discount.name})</span>}
                            </span>
                            <span>{this.state.package.discount.price} {BaseCountry().currency}</span>
                        </div> : null
                    }

                    {this.state.request.offer.additional_percent_on_works ?
                        <div className="package-collapsable-item package-collapsable-item--big">
                            <span>{t('pages.request_details.package.additional_percent_on_works')}</span>
                            <span>{this.state.request.offer.additional_percent_on_works}%</span>
                        </div> : null
                    }

                    <div className="package-collapsable-item package-collapsable-item--big">
                        <span>{t('global.total_with_vat')}</span>
                        <span>
                            <span className="total-border">
                                <PackagePrice pack={this.state.package} fullPrice={true}/>
                            </span>
                        </span>
                    </div>
                </div>

                {(USER_CAN_EDIT_PACKAGE.indexOf(user.type) !== -1 && request.status === STATUS_PENDING_MANAGER) &&
                <div className="mt-20 text-center">
                    <Button disabled={loader[LOADER_DRAFT]} size="md" type="primary"
                            extraClass={"btn-primary--outline btn-round--sm btn-shadow btn-block"}
                            onClick={() => dispatch(getDraft(request.token))}>
                        {t('pages.request_details.edit_package')}
                        <Loader isLoading={loader[LOADER_DRAFT]} type={LOADER_BUTTON}/>
                    </Button>
                </div>
                }
            </div>
        )
    }

    render() {
        const {t, title} = this.props;
        const {requestLoader} = this.state;

        return (
            <React.Fragment>
                <Header title={this.state.package ? this.state.package.name : t(title)}/>

                <div className="container container--has-submit-container">

                    {requestLoader ? <ListLoader/> : this.renderPackage()}
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        loader: state.loader
    };
};

export default connect(mapStateToProps)(translate('translations')(RequestPackageContainer));
