export const REQUESTS = '[Requests]'

export const REQUESTS_FETCH = `${REQUESTS} FETCH`
export const REQUESTS_SET = `${REQUESTS} SET`

export const fetchRequests = () => ({
  type: REQUESTS_FETCH,
})

export const setRequests = requests => ({
  type: REQUESTS_SET,
  payload: {
    requests,
  },
})
