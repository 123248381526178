export default {
    recommended: {
        next: [],
        prev: [],
        prevKm: 0,
        nextKm: 0,
        prevDate: 0,
        nextDate: 0,
        selected: 0,
    },
    main: [],
    additional: [],
    mileage: null,
    exist: true,
}
