export const CHECKLIST_LABOUR_TYPE_NORMAL = 1
export const CHECKLIST_LABOUR_TYPE_SERVICE = 2
export const CHECKLIST_LABOUR_TYPE_POSITION = 3
export const CHECKLIST_LABOUR_TYPE_CUSTOM = 4
export const CHECKLIST_LABOUR_TYPE_LIST = 5
export const CHECKLIST_LABOUR_TYPE_TIRES = 6
export const CHECKLIST_LABOUR_TYPE_POSITION_CATEGORIES = 7

export const TYPES = {
    CHECKLIST_LABOUR_TYPE_NORMAL: 'Normal',
    CHECKLIST_LABOUR_TYPE_SERVICE: 'Service',
    CHECKLIST_LABOUR_TYPE_POSITION: 'Position',
    CHECKLIST_LABOUR_TYPE_CUSTOM: 'Custom',
    CHECKLIST_LABOUR_TYPE_LIST: 'List',
    CHECKLIST_LABOUR_TYPE_TIRES: 'Tires',
}

export const checklistTypesToArchive = (type) => {
    return [
        CHECKLIST_LABOUR_TYPE_NORMAL,
        CHECKLIST_LABOUR_TYPE_SERVICE,
        CHECKLIST_LABOUR_TYPE_POSITION,
        CHECKLIST_LABOUR_TYPE_LIST,
        CHECKLIST_LABOUR_TYPE_TIRES,
    ].indexOf(type) !== -1
}
