import React, {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import classNames from 'classnames'
import TextHighlighter from 'components/shared/Highlighter/TextHighlighter'
import Input from 'components/shared/Input/Input'
import history from 'routes/history'
import {compare} from 'utils/common'
import Popup from 'components/shared/Popup/Popup'
import {__clone} from 'utils/common'
import {updateObject, urlSearchParams} from 'utils/common'
import {packagesUpdate} from 'store/actions/packages.actions'
import {findPackage} from 'store/selectors/package'
import pages from 'app/consts/routes'
import packageModel from 'store/models/package/package'
import labourModel from 'store/models/package/labour'
import {defaultsDeep, debounce, pick} from 'lodash'
import randomPackageId from 'utils/randomPackageId'
import {PackagesStep} from '../../routes/middleware/Packages/PackagesStep'
import {HasOfferToken} from '../../routes/middleware/HasOfferToken'
import axios from 'app/config/axios'
import axiosDefault from 'axios'
import {parseResponse} from 'utils/common'
import Confirm from '../../components/shared/Confirm/Confirm'
import UrgencyLevel from "../PackageDetailsContainer/UrgencyLevel";
import Button from "../../components/shared/Button/Button";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import {toast} from "react-toastify";
import {URGENCY_URGENT} from "../../store/consts/package/package.urgency.constants";
import Header from "../../components/layout/Header/Header";
import Loader, {LOADER_LOADING} from "../../components/shared/Loader/Loader";
import Toast from "../../components/shared/Toast/Toast";
import InputRadio from "../../components/shared/InputRadio";
import PackagesMaxReached from "../../routes/middleware/Packages/PackagesMaxReached";
import {captureException} from "../../utils/captureException";

const API_START_AT_CHARACTER_NUMBER = 2
const API_ADD_COST_LABOUR = 'garage/add-cost-tool/labour'
export const API_LABOURS_SEARCH = 'garage/add-cost-tool/labours-search'

class LabourSearchContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchText: '',
            selectedOption: null,
            list: [],
            laboursPredefined: props.laboursPredefined,
            _package: props.package,
            labourDataLoad: false,
            labourSearchLoader: false,
            confirm: null,
            packages: props.packages,
            packageNew: null,
            packageTemp: null,
            urgencyLevelShow: false,
            urgencySelected: false,
            filesShow: false,
            filesSelected: false,
            enricherPackageStepsCompleted: false,
        };

        this.timer = null;

        this.enricherPackageProperties = ['urgency', 'files'];

        this.optionsRef = React.createRef()
        this.searchText = React.createRef()

        this.handleTextChangeDebounced = debounce((value) => this.handleTextChange(value), 500)
    }

    componentDidMount() {
        document.querySelector('body').onscroll = () => this.searchText.inputRef.blur()
    }

    componentWillUnmount() {
        document.querySelector('body').onscroll = null
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (compare(nextProps.laboursPredefined, this.state.laboursPredefined)) {
            this.setState({
                laboursPredefined: nextProps.laboursPredefined,
            })
        }

        if (compare(nextProps.packages, this.props.packages)) {
            this.setState({
                packageNew: this.state._package ? this.state._package : nextProps.packages[nextProps.packages.length - 1],
                packages: nextProps.packages,
            }, () => {
                this.setState({
                    packageTemp: (this.state.enricherPackageStepsCompleted === true && this.state.packageNew !== null) ? __clone(this.state.packageNew) : __clone(this.state.packageTemp),
                }, () => {
                    this.joinNewTemp()
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {enricherPackageStepsCompleted, urgencySelected, urgencyLevelShow, filesSelected, filesShow, packageTemp} = this.state

        if (packageTemp && enricherPackageStepsCompleted === false) {
            if (urgencySelected === false) {
                if (urgencyLevelShow === false) {
                    this.setState({
                        urgencyLevelShow: true
                    })
                }
            } else if (filesSelected === false) {
                if (filesShow === false) {
                    this.setState({
                        filesShow: true
                    })
                }
            } else {
                this.setState({
                    enricherPackageStepsCompleted: true
                }, () => {
                    this.joinNewTemp()
                })
            }
        }
    }

    joinNewTemp = () => {
        const {t} = this.props
        const {_package, packages, packageNew, enricherPackageStepsCompleted, packageTemp} = this.state

        if (enricherPackageStepsCompleted === true) {
            if (packageNew) {
                if (compare(pick(packageNew, this.enricherPackageProperties), pick(packageTemp, this.enricherPackageProperties))) {
                    let packageNewClone = {...__clone(packageNew), ...pick(packageTemp, this.enricherPackageProperties)};
                    this.props.dispatch(packagesUpdate(updateObject(__clone(packages), packageNewClone, `id:${packageNewClone.id}`), packageNewClone.id))
                } else {
                    toast.success(<Toast text={t('pages.labour_search.created')} type="success"/>)

                    if (packageNew.labours.filter(l => l.custom === true).length > 0 || (_package !== null && urlSearchParams('return') !== null)) {
                        history.replace(`${pages.package_details}${packageNew.id}`)
                    } else {
                        history.replace(pages.packages)
                    }
                }
            }
        }
    }

    onCancel = () => {
        history.goBack()
    }

    renderSelectItem = option => {
        const {name, itemMpId, visible} = option
        const {searchText, selectedOption} = this.state

        if (!visible) return null

        return (
            <li
                key={itemMpId + option.korId}
                id={`option-${itemMpId}`}
                className={classNames('dd-list__options--item', {
                    selected: selectedOption && parseInt(selectedOption.itemMpId) === parseInt(option.itemMpId),
                })}
                onClick={() => this.selectOne(option)}
                data-item-id={itemMpId}
                data-item-name={option.name}
            >
                <InputRadio
                    id={itemMpId + option.korId}
                    name={"select"}
                    value={option.name}
                    label={null}
                    checked={selectedOption && parseInt(selectedOption.itemMpId) === parseInt(option.itemMpId)}
                    onChange={() => {
                    }}
                />
                <TextHighlighter target={searchText}>{name}</TextHighlighter>
                <div className="font-size-sm text-lighter mt-5">
                    {option.group || option.subGroup ? `${option.group} / ${option.subGroup}` : ''}
                </div>
            </li>
        )
    }

    renderNoResultItem = () => {
        const {searchText} = this.state
        const {t} = this.props

        const option = defaultsDeep(
            {
                itemMpId: randomPackageId(),
                name: searchText,
                custom: true,
            },
            labourModel,
        )

        return (
            <li
                key={option.itemMpId}
                id={`option-${option.itemMpId}`}
                className={classNames('dd-list__options--item', {})}
                onClick={() => this.selectOne(option, true)}
                data-item-id={option.itemMpId}
                data-item-name={option.name}
            >
                <div className="text-color">
                    <TextHighlighter target={option.name}>{option.name}</TextHighlighter>
                </div>
                <Button size="sm" type="secondary">
                    {t('pages.labour_search.add_no_result')}
                </Button>
                <div className="font-size-xs text-lighter">{t('pages.labour_search.tip_on_no_result')}</div>
            </li>
        )
    }

    handleTextChange = value => {
        this.setState(
            {
                searchText: value,
                list: [],
                laboursPredefined: this.laboursPredefinedFilter(this.props.laboursPredefined, value),
                labourSearchLoader: true
            },
            () => {
                if (this.state.searchText.length > API_START_AT_CHARACTER_NUMBER) {
                    this.fetchLabours(this.state.searchText)
                } else {
                    this.setState({
                        labourSearchLoader: false
                    })
                }
            },
        )
    }

    fillLabourData = labour => {
        return defaultsDeep(labour, labourModel)
    }

    fetchLabours = search => {
        axios
            .post(API_LABOURS_SEARCH, {params: {search: search}})
            .then(response => {
                this.setState({
                    list: response.data.filter((labour) => {
                        if (!this.state.laboursPredefined.filter(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId && l.visible === true).length) {
                            return labour
                        }
                    }),
                    labourSearchLoader: false,
                    laboursPredefined: this.laboursPredefinedFilter(this.props.laboursPredefined, this.state.searchText)
                })
            })
            .catch(error => {
                if (!axiosDefault.isCancel(error)) {
                    captureException(error, API_LABOURS_SEARCH)
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                    this.setState({
                        labourSearchLoader: false,
                    })
                }
            })
    }

    laboursPredefinedFilter = (laboursPredefined, searchText) => {
        return laboursPredefined.filter(l =>
            l.name.toLowerCase().includes(searchText.toLowerCase()) ||
            l.group.toLowerCase().includes(searchText.toLowerCase()) ||
            l.subGroup.toLowerCase().includes(searchText.toLowerCase())
        )
    }

    handleTextClear = () => {
        this.setState({
            searchText: '',
            list: [],
            laboursPredefined: this.props.laboursPredefined
        })
    }

    selectOne = (option, isCustom = false) => {
        const {_package} = this.state

        if (_package) {
            this.fetchLabour(option, _package, isCustom)
        } else {
            this.fetchLabour(option, null, isCustom)
        }
    }

    async confirmNew() {
        return await new Promise((resolve, reject) => this.setState({confirm: {resolve, reject}}))
    }

    fetchLabour = (labour, _package, isCustom) => {
        let labours = []

        if (_package) {
            labours = [..._package.labours, labour]
        } else {
            labours = [labour]
        }

        if (_package) {
            this.confirmNew(labours)
                .then(() => {
                    this.fetch(labours, _package, isCustom)
                })
                .catch(error => {
                    captureException(error);
                    this.setState({confirm: null})
                })
        } else {
            this.fetch(labours, _package, isCustom)
        }
    }

    fetch = (labours, _package, isCustom) => {
        this.setState({
            labourDataLoad: true,
            packageTemp: _package ? _package : packageModel,
            confirm: null,
            urgencySelected: !!_package,
            filesSelected: !!_package,
        })


        labours = labours.map(labour => this.fillLabourData(labour))

        if (labours[labours.length - 1].custom === true) {
            this.addLabour(_package, labours, isCustom)
        } else {
            axios
                .post(API_ADD_COST_LABOUR, {params: {package: {labours: labours}}})
                .then(response => {
                    this.addLabour(_package, response.data)
                })
                .catch(error => {
                    captureException(error, API_ADD_COST_LABOUR);
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)

                    this.setState({
                        labourDataLoad: false,
                    })
                })
        }
    }

    addLabour = (_package, labours, isCustom) => {
        this.setState({labourDataLoad: false})

        if (_package) {
            this.addLabourToPackage(_package, labours)
        } else {
            this.createNewPackage(labours, isCustom)
        }
    }

    createNewPackage = (labours, isCustom) => {
        const {packages, packageTemp, enricherPackageStepsCompleted} = this.state

        let packageAdd = {
            name: labours[0].name,
            urgency: URGENCY_URGENT,
            labours: labours,
        }

        if (packageTemp && enricherPackageStepsCompleted === true) {
            packageAdd = defaultsDeep(pick(packageTemp, this.enricherPackageProperties), packageAdd);
        }

        packageAdd = defaultsDeep(packageAdd, packageModel)

        let packagesClone = __clone(packages)
        packagesClone.push(packageAdd)

        this.props.dispatch(packagesUpdate(packagesClone, packageAdd.id, true, isCustom))
    }

    addLabourToPackage = (_package, labours) => {
        const {packages} = this.state

        let updatedPackage = __clone(_package)

        updatedPackage.labours = labours

        this.props.dispatch(packagesUpdate(updateObject(__clone(packages), updatedPackage, `id:${updatedPackage.id}`), updatedPackage.id, true))
    }

    renderLabourLoader = () => {
        const {t, formSubmitting} = this.props
        const {enricherPackageStepsCompleted} = this.state

        if (!formSubmitting.packages || !enricherPackageStepsCompleted) {
            return
        }

        return (
            <Popup
                title={t('pages.labour_search.new_labour_loading_title')}
                visible={formSubmitting.packages}
                hideCancel={true}
            >
                <div className="mv-30 text-light text-center"><Loader type={LOADER_LOADING}
                                                                      isLoading={true}/> {t('pages.labour_search.new_labour_loading')}
                </div>
            </Popup>
        )
    }

    renderLabourDataLoader = () => {
        const {t} = this.props
        const {labourDataLoad} = this.state

        if (!labourDataLoad) {
            return
        }

        return (
            <Popup title={t('pages.labour_search.new_labour_data_title')} visible={labourDataLoad} hideCancel={true}>
                <div className="mv-30 text-light text-center"><Loader type={LOADER_LOADING}
                                                                      isLoading={true}/> {t('pages.labour_search.new_labour_loading')}
                </div>
            </Popup>
        )
    }

    renderUrgencyLevel = () => {
        const {t} = this.props
        const {urgencyLevelShow, packageTemp} = this.state

        if (!urgencyLevelShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.urgency.title')}
                subtitle={t('pages.package_details.urgency.subtitle')}
                visible={urgencyLevelShow}
                hideCancel={true}
            >
                <UrgencyLevel
                    package={packageTemp}
                    packagePath={`id:${packageTemp.id}`}
                    onUpdate={(updatePackage) => {
                        this.setState({
                            packageTemp: {...packageTemp, urgency: updatePackage.urgency}
                        })
                    }}
                    callPackagesUpdate={false}
                    onClose={() => this.setState({urgencyLevelShow: false, urgencySelected: true})}
                />
            </Popup>
        )
    }

    renderFiles = () => {
        const {t} = this.props
        const {filesShow, packageTemp} = this.state

        if (!filesShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.files.title')}
                visible={filesShow}
                hideCancel={true}
                onClose={() => this.setState({filesShow: false, filesSelected: true})}
            >

                <FileUpload
                    files={packageTemp.files}
                    formData={{
                        packageId: packageTemp.id,
                        preLoad: true,
                    }}
                    onAddFile={(file) => {
                        let files = __clone(packageTemp).files;
                        files.push(file);

                        this.setState({
                            packageTemp: {...packageTemp, files: files}
                        })
                    }}
                    onRemoveFile={(file) => {
                        this.setState({
                            packageTemp: {
                                ...packageTemp,
                                files: __clone(packageTemp).files.filter(f => f.id !== file.id)
                            }
                        })
                    }}
                    button={(uploading) => (
                        <div className="mt-30">
                            <Button size="lg" type="primary" disabled={!!!packageTemp.files.length || uploading}
                                    onClick={() => this.setState({filesShow: false, filesSelected: true})}>
                                {t(`pages.price_update.section_form.${uploading ? 'button_uploading' : 'button_save'}`)}</Button>
                            {!!!packageTemp.files.length &&
                            <div className="mt-15 text-center">
                                <Button size="sm" type="secondary"
                                        onClick={() => this.setState({filesShow: false, filesSelected: true})}>
                                    {t('pages.package_details.files.skip')}
                                </Button>
                            </div>
                            }
                        </div>
                    )}
                />
            </Popup>
        )
    }

    render() {
        const {list, searchText, labourSearchLoader, confirm, laboursPredefined} = this.state
        const {t, title} = this.props

        return (
            <React.Fragment>
                <Header title={t(title)} showRightControls={this.props.showRightHeaderControls}/>

                <div className="container">
                    <div>
                        <Input
                            ref={(ref) => this.searchText = ref}
                            type="text"
                            placeholder={t('pages.labour_search.form.placeholder')}
                            name="searchText"
                            value={searchText}
                            onChange={(e) => this.handleTextChangeDebounced(e.target.value)}
                            onTextClear={this.handleTextClear}
                            iconRightClassName={'icon-cancel'}
                            iconLeftClassName={'icon-search'}
                            extraClass={"mt-0"}
                            data-hj-whitelist
                        />
                    </div>

                    <div className="dd-list" ref={el => (this.optionsRef = el)} onKeyPress={e => e.preventDefault()}>
                        {labourSearchLoader === false ? (
                            <ul className="dd-list__options">
                                {laboursPredefined.map(option => this.renderSelectItem(option))}
                                {list && !!list.length && list.map(option => this.renderSelectItem(option))}
                                {list && !!!list.length && searchText && !!!laboursPredefined.length && (
                                    <div className="dd-no-result">
                                        <span className="small">{t('pages.labour_search.no_result_tip')}</span>
                                        {this.renderNoResultItem()}
                                    </div>
                                )}
                            </ul>
                        ) : (
                            <div className="loading mt-40"><Loader type={LOADER_LOADING}
                                                                   isLoading={true}/> {t('global.loading')}</div>
                        )}
                    </div>
                </div>

                {this.renderLabourLoader()}
                {this.renderLabourDataLoader()}
                {this.renderUrgencyLevel()}
                {this.renderFiles()}

                {confirm && (
                    <Confirm
                        title={t('pages.package_details.confirm_edit.title')}
                        subtitle={t('pages.package_details.confirm_edit.subtitle')}
                        visible={!!confirm}
                        accept={() => confirm.resolve(true)}
                        cancel={() => confirm.reject(false)}
                    />
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loader: state.loader,
        formSubmitting: state.formSubmitting,
        packages: state.packages,
        package: findPackage(state, urlSearchParams('package')),
        laboursPredefined: state.laboursPredefined,
    }
}

export default connect(mapStateToProps)(PackagesStep(HasOfferToken(PackagesMaxReached(translate('translations')(LabourSearchContainer)))))
