export const STEP = '[Step]';

export const STEP_CHANGE = `${STEP} CHANGE`;
export const STEP_SET_DATA = `${STEP} SET_DATA`;
export const STEP_SET_DATA_ACTIVE_ONE = `${STEP} SET_DATA_ACTIVE_ONE`;

export const changeStepData = step => ({
    type: STEP_CHANGE,
    payload: {
        step
    }
});

export const setStepData = options => ({
    type: STEP_SET_DATA,
    payload: {
        numberOfSteps: options.numberOfSteps,
        activeStep: options.activeStep
    }
});

export const setStepDataActiveOne = step => ({
    type: STEP_SET_DATA_ACTIVE_ONE,
    payload: {
        activeStep: step
    }
});
