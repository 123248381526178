import React, {Component} from 'react';
import {translate} from 'react-i18next';
import "./Packages.scss";
import {USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR} from "../../../store/consts/user.constants";
import {setActivity, getActivity} from "../../../utils/activity";

class Packages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            collapse: getActivity('analytics.packages.collapsed', false)
        }
    }

    collapse = (users) => {

        if (users.length === 0) {
            return
        }

        this.setState({
            collapse: !this.state.collapse
        }, () => {
            setActivity('analytics.packages.collapsed', this.state.collapse)
        })
    }

    render() {
        const {t, packages, user} = this.props;
        const {collapse} = this.state;
        const users = packages.users;

        return (
            <React.Fragment>
                <div className="analytic-tab analytic-tab-packages">
                    <div className="analytic-tab-header" onClick={() => this.collapse(users)}>
                        <div className="row">
                            <div
                                className="col-xs-4 analytic-tab-header__package-stat analytic-tab-header__package-stat--success">
                                <div>
                                    <span className="icon-check-fill icon"/>
                                    <span className="value">{packages.accepted}</span>
                                </div>
                                <div className="label">{t('pages.analytics.statues.accepted')}</div>
                            </div>
                            <div
                                className="col-xs-4 analytic-tab-header__package-stat analytic-tab-header__package-stat--danger">
                                <div>
                                    <span className="icon-cancell-fill icon"/>
                                    <span className="value">{packages.rejected}</span>
                                </div>
                                <div className="label">{t('pages.analytics.statues.rejected')}</div>
                            </div>
                            <div
                                className="col-xs-4 analytic-tab-header__package-stat analytic-tab-header__package-stat--warning">
                                <div>
                                    <span className="icon-clock-fill icon"/>
                                    <span className="value">{packages.pending}</span>
                                </div>
                                <div className="label">{t('pages.analytics.statues.open')}</div>
                            </div>
                        </div>
                    </div>

                    {(((user.type === USER_ROLE_MANAGER || user.type === USER_ROLE_SUPERVISOR) || users.length > 1) && !collapse) &&
                    <div className="analytic-tab-content analytic-tab-content--expandable">
                        {users.map((requests, key) => {
                            return (
                                <div key={key} className="analytic-tab-content__package-stat">
                                    <div
                                        className="label">{requests.user.first_name} {requests.user.last_name}</div>
                                    <div className="row">
                                        <div
                                            className="col-xs-4 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--success">
                                            <span className="icon-check-fill icon"/>
                                            <span
                                                className="value">{requests.packages.accepted}</span>
                                        </div>
                                        <div
                                            className="col-xs-4 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--danger">
                                            <span className="icon-cancell-fill icon"/>
                                            <span
                                                className="value">{requests.packages.rejected}</span>
                                        </div>
                                        <div
                                            className="col-xs-4 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--warning">
                                            <span className="icon-clock-fill icon"/>
                                            <span
                                                className="value">{requests.packages.pending}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(Packages)
