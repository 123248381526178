import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Input from "../../../components/shared/Input/Input";
import axios from 'app/config/axios'
import {toast} from "react-toastify";
import Toast from "../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../utils/common";
import Button from "../../../components/shared/Button/Button";
import {garageUpdateAppointmentDiscount} from "../../../store/actions/garage.actions";
import {connect} from "react-redux";
import {captureException} from "../../../utils/captureException";

const UPDATE_APPOINTMENT_DISCOUNT_URL = 'garage/add-cost-tool/update-appointment-discount'

class DiscountEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.discount.discount,
            updating: false,
        };
    }

    submit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {onUpdate, dispatch} = this.props;
        const {value} = this.state;

        this.setState({updating: true})

        this.update(value)
            .then(response => {

                dispatch(garageUpdateAppointmentDiscount(value));

                onUpdate(value)

                this.setState({updating: false})
            })
            .catch(error => {
                captureException(error, UPDATE_APPOINTMENT_DISCOUNT_URL);

                toast.error(<Toast text={parseResponse(error.response)} type="error"/>);

                this.setState({updating: false});
            })
    }

    update = (value) => axios.post(UPDATE_APPOINTMENT_DISCOUNT_URL, {discount: parseFloat(value)})

    render() {
        const {updating, value} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <form onSubmit={this.submit}>
                    <div>
                        <Input
                            type="tel"
                            name="appointment_discount"
                            value={value}
                            onChange={e => {
                                this.setState({
                                    value: e.target.value,
                                })
                            }}
                            float={true}
                            addOn={'%'}
                        />
                    </div>

                    <Button size="lg" type="primary" loading={updating}
                            disabled={updating || !value}>{t('pages.request_preview.appointment_discounts.edit.save')}</Button>
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps)(translate('translations')(DiscountEdit));
