import React from 'react'
import history from 'routes/history'
import pages from 'app/consts/routes'
import { PACKAGES_LABOURS_PREDEFINED, PACKAGES_ALL_REMOVE, PACKAGES_SEND_REQUEST, PACKAGES_PAGE_SHOW } from 'store/consts/package/package.constants'
import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { formSubmittingPackagesUpdate } from 'store/actions/formSubmitting.actions'
import { laboursPredefinedSet } from '../../actions/laboursPredefined.actions'
import { LOADER_LABOURS_PREDEFINED } from '../../consts/loader.constants'
import { setLoader } from '../../actions/loader.actions'
import { packagesReset } from '../../actions/packages.actions'
import { formSubmittingPackagesAllRemove } from '../../actions/formSubmitting.actions'
import { toast } from 'react-toastify'
import Toast from '../../../components/shared/Toast/Toast'

const PACKAGES_ALL_REMOVE_URL = 'garage/add-cost-tool/package-all-remove'
export const LABOURS_PREDEFINED_URL = 'garage/add-cost-tool/car-labour'
export const PACKAGES_SEND_REQUEST_URL = 'garage/add-cost-tool/send-request'

export const packagesMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case PACKAGES_PAGE_SHOW:
                history.push(pages.packages)
                break

            case PACKAGES_ALL_REMOVE:
                dispatch(formSubmittingPackagesAllRemove(true))

                dispatch(
                    apiRequest({
                        method: 'POST',
                        url: PACKAGES_ALL_REMOVE_URL,
                        signature: PACKAGES_ALL_REMOVE,
                    }),
                )

                break

            case `${PACKAGES_ALL_REMOVE} ${API_SUCCESS}`:
                dispatch(formSubmittingPackagesAllRemove(false))
                dispatch(packagesReset())

                break

            case `${PACKAGES_ALL_REMOVE} ${API_ERROR}`:
                dispatch(formSubmittingPackagesAllRemove(false))
                break

            case PACKAGES_LABOURS_PREDEFINED:
                dispatch(setLoader(LOADER_LABOURS_PREDEFINED, true))

                dispatch(
                    apiRequest({
                        method: 'POST',
                        url: LABOURS_PREDEFINED_URL,
                        signature: PACKAGES_LABOURS_PREDEFINED,
                    }),
                )

                break

            case `${PACKAGES_LABOURS_PREDEFINED} ${API_SUCCESS}`:
                dispatch(laboursPredefinedSet(action.payload.data.data))
                dispatch(setLoader(LOADER_LABOURS_PREDEFINED, false))
                break

            case `${PACKAGES_LABOURS_PREDEFINED} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LABOURS_PREDEFINED, false))

                break

            case PACKAGES_SEND_REQUEST:
                dispatch(formSubmittingPackagesUpdate(true))

                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: PACKAGES_SEND_REQUEST_URL,
                        signature: PACKAGES_SEND_REQUEST,
                    }),
                )

                break

            case `${PACKAGES_SEND_REQUEST} ${API_SUCCESS}`:
                dispatch(formSubmittingPackagesUpdate(false))
                
                if (action.payload.data.book_now === true) {
                    history.push(pages.book_now_confirm)
                }

                if (action.payload.data.book_now !== true) {
                    history.push(pages.confirm)
                }

                break

            case `${PACKAGES_SEND_REQUEST} ${API_ERROR}`:
                dispatch(formSubmittingPackagesUpdate(false))
                toast.error(<Toast text={action.payload.error.message} type="error" />)
                break

            default:
                break
        }
    }
}
