export const PACKAGES = '[Packages]'

export const PACKAGES_PAGE_SHOW = `${PACKAGES} PAGE SHOW`
export const PACKAGES_ALL_REMOVE = `${PACKAGES} ALL REMOVE`
export const PACKAGES_SET = `${PACKAGES} SET`
export const PACKAGES_LABOURS_PREDEFINED = `${PACKAGES} LABOURS PREDEFINED`
export const PACKAGES_SEND_REQUEST = `${PACKAGES} SEND REQUEST`
export const PACKAGES_RESET = `${PACKAGES} RESET`
export const PACKAGES_FILE_ADD = `${PACKAGES} FILE ADD`
export const PACKAGES_FILE_REMOVE = `${PACKAGES} FILE UPDATE`

export default {
    PACKAGES,
    PACKAGES_PAGE_SHOW,
    PACKAGES_ALL_REMOVE,
    PACKAGES_SET,
    PACKAGES_LABOURS_PREDEFINED,
    PACKAGES_SEND_REQUEST,
    PACKAGES_RESET,
    PACKAGES_FILE_ADD,
    PACKAGES_FILE_REMOVE,
}
