import React, {Component} from 'react';
import {translate} from "react-i18next";
import './SubmitContainer.scss';
import classNames from 'classnames';

class SubmitContainer extends Component {
    render() {
        const {children, stat, columns} = this.props;

        return (
            <div className={`submit-container ${stat ? 'submit-container--static' : ''}`}>
                <div className={'container'}>
                    <div className="row">
                        <div className={classNames("", {
                            "col-sm-push-2 col-sm-8 col-xs-12": !columns,
                            [columns]: columns,
                        })}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(SubmitContainer);
