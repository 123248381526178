import React, {Component} from 'react';
import './ActivitiesItem.scss';
import {translate} from "react-i18next";
import {intersect} from "../../utils/common";
import {uniqBy} from "lodash";
import pages from "../../app/consts/routes";
import Link from "react-router-dom/Link";

class ActivitiesItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activity: props.activity
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop],
                })
            }
        })
    }

    render() {
        const {t} = this.props;
        const {activity} = this.state;

        return (
            <React.Fragment>
                <Link to={`${pages.activity}${activity.user.id}`} className="activities-item">
                    <div className={`activities-item__unseen ${activity.activities.filter(activity => activity.seen === false).length ? 'activities-item__unseen--has-unseen' : ''}`}/>

                    {activity.activities.length === 1 ?
                        <div className="activities-item__car">{activity.last_activity.car.make} {activity.last_activity.car.range} {activity.last_activity.car.type}</div>
                        :
                        <div className="activities-item__car">
                            {uniqBy(activity.activities, (e) => e.car.hash).map((item, key) => {
                                return <div key={key}>{item.car.make} {item.car.range}, </div>
                            })}
                        </div>
                    }
                    <div className="activities-item__user">{activity.user.first_name} {activity.user.last_name}</div>
                    <div className="activities-item__last_activity">
                        <span className="activities-item__last_activity__small">{t('pages.activities.last_activity')}:</span>
                        {activity.last_activity.at.date} {t('pages.activities.at')} {activity.last_activity.at.time}
                    </div>
                </Link>
            </React.Fragment>
        )
    }
}

export default translate('translations')(ActivitiesItem);
