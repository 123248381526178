import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import SystemMessagesList from "../../components/SystemMessagesList/SystemMessagesList";
import axios from "../../app/config/axios";
import {captureException} from "../../utils/captureException";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import Input from "../../components/shared/Input/Input";
import {debounce} from "lodash";
import Select from "../../components/shared/Select/Select";
import {clearAppendedSystemMessages} from "../../store/actions/systemMessage.actions";
import {LOADER_INIT} from "../../store/consts/loader.constants";
import Loader from "../../components/shared/Loader/Loader";
import ListLoader from "../../components/shared/ListLoader/ListLoader";

const API_FETCH_SYSTEM_MESSAGES = '/garage/get-system-messages'
const TYPE_MAINTENANCE = 1;
const TYPE_INCIDENT = 2;
const TYPE_WARNING = 3;
const TYPE_INFO = 4;
const TYPE_UPDATE = 5;

class SystemMessagesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            search: '',
            selected_type: -1,
            loader: true,
            loading: false,
        }

        this.messageTypes = [
            {
                id: -1, name: props.t('pages.system_messages.filters.all_types'),
            },
            {
                id: TYPE_MAINTENANCE, name: props.t('pages.system_messages.filters.type_maintenance'),
            },
            {
                id: TYPE_INCIDENT, name: props.t('pages.system_messages.filters.type_incident'),
            },
            {
                id: TYPE_WARNING, name: props.t('pages.system_messages.filters.type_warning'),
            },
            {
                id: TYPE_INFO, name: props.t('pages.system_messages.filters.type_info'),
            },
            {
                id: TYPE_UPDATE, name: props.t('pages.system_messages.filters.type_update'),
            },
        ];

        this.handleSearchChangeDebounced = debounce((value) => this.handleSearchChange(value), 500)
    }

    componentDidMount() {
        this.fetchSystemMessages()
    }

    handleSearchChange = (value) => {
        this.setState({
            loading: true,
            search: value,
        }, () => {
            if (this.state.search.length >= 2 || this.state.search.length === 0) {
                this.fetchSystemMessages()
            }
        })
    }

    fetchSystemMessages = () => {
        const {search, selected_type, loader} = this.state
        const {dispatch} = this.props

        if (loader === false) {
            this.setState({
                loading: true
            })
        }

        axios.post(API_FETCH_SYSTEM_MESSAGES, {name: search, selected_type})
            .then((response) => {
                this.setState({
                    messages: response.data.data,
                    loader: false,
                    loading: false,
                });
                dispatch(clearAppendedSystemMessages())
            })
            .catch(error => {
                captureException(error, API_FETCH_SYSTEM_MESSAGES)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
    }

    updateSelectedType = (id, option) => {
        this.setState({selected_type: option.id}, this.fetchSystemMessages)
    }

    handleSearchClear = () => {
        this.setState({search: ''}, () => this.fetchSystemMessages())
    }

    render() {
        const {t, title, systemMessages} = this.props
        const {messages, search, selected_type, loader, loading} = this.state

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                {loader ?
                    <Loader isLoading={loader} type={LOADER_INIT}/>
                    :
                    <div>
                        <div className="container">
                            <Input
                                type="text"
                                placeholder={t('pages.customers.search')}
                                name="search"
                                value={search}
                                onChange={(e) => this.handleSearchChangeDebounced(e.target.value)}
                                onTextClear={this.handleSearchClear}
                                iconRightClassName={'icon-cancel'}
                                iconLeftClassName={'icon-search'}
                            />
                            <Select
                                id={"message_type"}
                                list={this.messageTypes}
                                selected={selected_type}
                                onChange={this.updateSelectedType}/>

                        </div>

                        {loading ?
                            <ListLoader/>
                        :
                            <SystemMessagesList messages={messages} appendedSystemMessages={systemMessages} fetchSystemMessages={this.fetchSystemMessages} />
                        }

                    </div>
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        loader: state.loader,
        systemMessages: state.systemMessages
    };
};

export default connect(mapStateToProps)(translate('translations')(SystemMessagesContainer));
