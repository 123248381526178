import Yup from './yup'
import { userResetPassword } from '../../../store/actions/auth.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            username: '',
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            username: Yup.string()
                .required()
                .label(t('validations.label.username')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(userResetPassword(values.username))
    },
}
