import Yup from './yup'
import {changeCustomerSignatureUpdate} from "../../../store/actions/change-customer-signature.settings.actions";

export default {
    enableReinitialize: true,

    mapPropsToValues({garage}) {
        return {
            customer_signature: garage.customer_signature,
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            customer_signature: Yup.string()
                .required()
                .label(t('pages.settings.section_form.label.customer_signature')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(changeCustomerSignatureUpdate(values.customer_signature))
    },
}
