import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Input from "../../../components/shared/Input/Input";
import "./ActivityAction.scss";
import Button from "../../shared/Button/Button";
import axios from "../../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../shared/Toast/Toast";
import {parseResponse} from "../../../utils/common";
import Loader, {LOADER_BUTTON} from "../../shared/Loader/Loader";
import {captureException} from "../../../utils/captureException";

const API_ACTIVITY_SMS_REPLY_URL = 'garage/activities/sms-reply';

class ActivityAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            sending: false
        };
    }

    handleTextChange = (e) => {
        this.setState({message: e.target.value})
    };

    handleTextClear = () => {
        this.setState({
            message: '',
        })
    }

    submit = (e) => {
        e.preventDefault();

        const {user, lastActivity, onReply} = this.props;
        const {message} = this.state;

        if (!message.trim()) {
            return;
        }

        this.setState({sending: true})

        axios
            .post(API_ACTIVITY_SMS_REPLY_URL, {
                message: message,
                offer_token: lastActivity.offer_token,
                user_id: user.id
            })
            .then(response => {
                onReply(response.data);
                this.setState({sending: false, message: ''})
            })
            .catch(error => {
                captureException(error, API_ACTIVITY_SMS_REPLY_URL);

                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({sending: false});
            })
    }

    render() {
        const {t} = this.props;
        const {message, sending} = this.state;

        return (
            <React.Fragment>
                <form onSubmit={this.submit}>
                    <div className="activity-action-fixed">
                        <div className="container">
                            <div className="activity-action">
                                <div className="activity-action__input">
                                    <Input
                                        type="text"
                                        placeholder={t('pages.activity.section_form.placeholder.message')}
                                        name="message"
                                        value={message}
                                        onChange={this.handleTextChange}
                                        onTextClear={this.handleTextClear}
                                    />
                                </div>
                                <div className="activity-action__btn">
                                    <Button size="sm" type="primary" disabled={sending} extraClass="btn-round">
                                        {sending ?
                                            <Loader isLoading={sending} addClass="loader-button-center"
                                                    type={LOADER_BUTTON}/>
                                            :
                                            <i className="icon-send"/>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default translate('translations')(ActivityAction);
