import React, { Component } from 'react';
import {addCostRoutes} from "../../../routes/garage";
import {matchPath, Prompt} from "react-router-dom";
import history from "../../../routes/history";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {compare} from "../../../utils/common";
import {removeSessionStorageItem} from "../../../utils/storage";
import {STORAGE_OFFER_TOKEN} from "../../../app/consts/storage.consts";
import {storeReset} from "../../../store/actions/store.actions";

class PackageResetConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPrompt: false,
      packages: props.packages,
    }

    this.leaving = false
    this.unlisten = null
  };

  componentDidMount() {
      this.unlisten = history.listen((location, action) => {
          if(addCostRoutes.filter(route => matchPath(location.pathname, route)).length === 0 && this.state.packages.length > 0 && this.leaving){
              this.props.dispatch(storeReset())
              removeSessionStorageItem(STORAGE_OFFER_TOKEN);
              this.setState({routeChanged: false})

              this.leaving = false
          }
      })
  }

  componentWillUnmount() {
      this.unlisten()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(compare(nextProps.packages, this.state.packages)){
      this.setState({
        packages: nextProps.packages
      })
    }
  }

  render() {
    const {t} = this.props

    return (
      <div>
          <Prompt
            when={this.state.packages.length > 0}
            message={location => {
                if(addCostRoutes.filter(route => matchPath(location.pathname, route)).length > 0){
                    return true;
                }
                else{
                    this.leaving = true

                    return t('pages.add_cost.tab_change.title')
                }
            }}
          />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    packages: state.packages,
  }
}

export default connect(mapStateToProps)(translate('translations')(PackageResetConfirm))
