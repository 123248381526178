import React, {Component} from 'react';
import './HeaderNotifications.scss';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {
    notificationsFetch,
    updateSeenAllNotifications,
    updateSeenNotifications
} from "../../../store/actions/notifications.actions";
import {LOADER_NOTIFICATIONS} from "../../../store/consts/loader.constants";
import {
    TYPE_MANAGER_ACCEPTED,
    TYPE_MANAGER_REJECTED,
    TYPE_MANAGER_WAITING,
    TYPE_USER_RESPONDED, TYPE_USER_SMS_REPLY
} from "../../../store/consts/notifications.constants";
import {STATUS_ACCEPTED} from "../../../store/consts/package/package.status.constants";
import pages from "../../../app/consts/routes";
import EmptyList from "../EmptyList/EmptyList";
import {scrollToLastThird, thisWeekTimestamp, todayTimestamp, yesterdayTimestamp} from "../../../utils/common";
import {USER_ROLE_MANAGER} from "../../../store/consts/user.constants";
import GarageReinitialize from "../../GarageReinitialize/GarageReinitialize";
import history from 'routes/history'
import HeaderAppVersionInfo from "../../AppVersionUpToDateInfo/HeaderAppVersionInfo";

class HeaderNotifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initializeGarageNotify: null,
        }

        this.navigationContainer = React.createRef();
    }

    componentDidMount() {
        const {notifications: {list}, dispatch} = this.props;

        if (list.filter(notify => notify.seen === false).length > 0) {
            dispatch(updateSeenNotifications(list.filter(notify => notify.seen === false)));
        }

        this.navigationContainer.addEventListener('scroll', this.scrollEvent)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {notifications: {list}, dispatch} = this.props;

        if(list.length !== prevProps.notifications.list.length && list.filter(notify => notify.seen === false).length > 0){
            dispatch(updateSeenNotifications(list.filter(notify => notify.seen === false)));
        }
    }

    componentWillUnmount() {
        this.navigationContainer.removeEventListener('scroll', this.scrollEvent)
    }

    scrollEvent = (e) => {
        const {dispatch} = this.props;

        if(scrollToLastThird(e.target) && this.props.loader[LOADER_NOTIFICATIONS]) {
            dispatch(notificationsFetch());
        }
    }

    notifyLink = (notify) => {
        const {user, garage, onClose} = this.props;

        if (garage.id !== notify.repairShop.id) {
            this.setState({initializeGarageNotify: notify});
            return;
        }

        onClose();

        if (user.type === USER_ROLE_MANAGER) {
            return history.push(`${pages.activity}${notify.user.id}`)
        }

        return history.push(`${pages.request_details}${notify.token}`)
    }

    initializeGarageSuccess = () => {
        const {initializeGarageNotify} = this.state;

        this.notifyLink(initializeGarageNotify);
    }

    markAllAsRead = () => {
        const {dispatch, notifications: {data}} = this.props;

        if(data.unseen > 0){
            dispatch(updateSeenAllNotifications());
        }
    }

    renderManagerWaiting = (notify) => {
        const {t} = this.props;

        if(!notify.created_by) return null;

        return (
            <div className={`notification-item ${notify.seen ? '' : 'notification-item--unseen'}`} key={notify.id}>
                <div className="row">
                    <div className="col-xs-9 notification-item__content">
                        <div className={`notification-item__content__icon info`}>
                            <i className="icon-info-fill"/>
                        </div>
                        <div className="notification-item__content__text">
                            <div>
                                <span className="garage">{notify.repairShop.name} - </span>
                                <span className="created-by">{notify.created_by.first_name} {notify.created_by.last_name}</span> {t('notification.manager_waiting')}
                            </div>
                            <div className="notification-item__content__text__time">{notify.at.time}</div>

                        </div>
                    </div>
                    <div className="col-xs-3 notification-item__link">
                        <span className="link-style" onClick={() => this.notifyLink(notify)}>
                            <i className="icon-eye"/> {t('notification.details')}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderManagerAccepted = (notify) => {
        const {t} = this.props;

        if(!notify.triggered_by) return null;

        return (
            <div className={`notification-item ${notify.seen ? '' : 'notification-item--unseen'}`} key={notify.id}>
                <div className="row">
                    <div className="col-xs-9 notification-item__content">
                        <div className={`notification-item__content__icon success`}>
                            <i className="icon-check-fill"/>
                        </div>
                        <div className="notification-item__content__text">
                            <div>
                                <span className="garage">{notify.repairShop.name} - </span>
                                <span className="created-by">{notify.triggered_by.first_name} {notify.triggered_by.last_name}</span> {t('notification.manager_accepted')}
                            </div>
                            <div className="notification-item__content__text__time">{notify.at.time}</div>
                        </div>
                    </div>
                    <div className="col-xs-3 notification-item__link">
                        <span className="link-style" onClick={() => this.notifyLink(notify)}>
                            <i className="icon-eye"/> {t('notification.details')}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderManagerRejected = (notify) => {
        const {t} = this.props;

        if(!notify.triggered_by) return null;

        return (
            <div className={`notification-item ${notify.seen ? '' : 'notification-item--unseen'}`} key={notify.id}>
                <div className="row">
                    <div className="col-xs-9 notification-item__content">
                        <div className={`notification-item__content__icon warning`}>
                            <i className="icon-warrning-fill"/>
                        </div>
                        <div className="notification-item__content__text">
                            <div>
                                <span className="garage">{notify.repairShop.name} - </span>
                                {notify.triggered_by &&
                                    <span className="created-by">{notify.triggered_by.first_name} {notify.triggered_by.last_name}</span>
                                }
                                {' '}{t('notification.manager_rejected')}
                            </div>
                            <div className="notification-item__content__text__time">{notify.at.time}</div>
                        </div>
                    </div>
                    <div className="col-xs-3 notification-item__link">
                        <span className="link-style" onClick={() => this.notifyLink(notify)}>
                            <i className="icon-eye"/> {t('notification.details')}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderUserResponded = (notify) => {
        const {t} = this.props;
        let hasAccepted = notify.packages.filter(pack => pack.status === STATUS_ACCEPTED).length

        if(!notify.user) return null;

        return (
            <div className={`notification-item ${notify.seen ? '' : 'notification-item--unseen'}`} key={notify.id}>
                <div className="row">
                    <div className="col-xs-8 notification-item__content">
                        <div className={`notification-item__content__icon ${hasAccepted ? 'success' : 'danger'}`}>
                            <i className={hasAccepted ? 'icon-check-fill' : 'icon-cancell-fill'}/>
                        </div>
                        <div className="notification-item__content__text">
                            <div>
                                <span className="garage">{notify.repairShop.name} - </span>
                                <span className="created-by">{notify.user.first_name} {notify.user.last_name}</span> {notify.car.make}
                            </div>
                            {hasAccepted > 0 ?
                                <div className="small">{t('notification.user_responded.accepted', {
                                    accepted: hasAccepted,
                                    packagesLength: notify.packages.length
                                })}</div>
                                :
                                <div className="small">{t('notification.user_responded.rejected')}</div>
                            }
                            <div className="notification-item__content__text__time">{notify.at.time}</div>
                        </div>
                    </div>
                    <div className="col-xs-4 notification-item__link">
                        <span className="link-style" onClick={() => this.notifyLink(notify)}>
                            <i className="icon-eye"/> {t('notification.details')}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderSmsReply = (notify) => {
        const {t} = this.props;

        if(!notify.user) return null;

        return (
            <div className={`notification-item ${notify.seen ? '' : 'notification-item--unseen'}`} key={notify.id}>
                <div className="row">
                    <div className="col-xs-8 notification-item__content">
                        <div className="notification-item__content__icon info">
                            <i className="icon-info-fill"/>
                        </div>
                        <div className="notification-item__content__text">
                            <div>
                                <span className="garage">{notify.repairShop.name} - </span>
                                <span className="created-by">{t('notification.sms_reply', {first_name: notify.user.first_name, last_name: notify.user.last_name})}</span>
                            </div>
                            <div className="notification-item__content__text__time">{notify.at.time}</div>
                        </div>
                    </div>
                    <div className="col-xs-4 notification-item__link">
                        <span className="link-style" onClick={() => this.notifyLink(notify)}>
                            <i className="icon-eye"/> {t('notification.details')}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    segments = (list) => {
        let segments = {};

        for (let item of list) {
            if(item.at.timestamp >= todayTimestamp()){
                if(!segments.hasOwnProperty('today')){
                    segments.today = [];
                }

                segments.today.push(item);
            }
            else if(item.at.timestamp >= yesterdayTimestamp()){
                if(!segments.hasOwnProperty('yesterday')){
                    segments.yesterday = [];
                }

                segments.yesterday.push(item);
            }
            else if(item.at.timestamp >= thisWeekTimestamp()){
                if(!segments.hasOwnProperty('this_week')){
                    segments.this_week = [];
                }

                segments.this_week.push(item);
            }
            else{
                if(!segments.hasOwnProperty(item.at.date)){
                    segments[item.at.date] = [];
                }

                segments[item.at.date].push(item);
            }
        }

        return segments;
    }

    render() {
        const {initializeGarageNotify} = this.state;
        const {t, loader, notifications: {list, data}} = this.props;

        let segments = this.segments(list);

        return (
            <div className="navigation-container navigation-container--notifications" ref={(ref) => this.navigationContainer = ref}>
                <div className="navigation-container__content">
                    <div className="notifications-container">
                        {list.length === 0 ? <EmptyList icon="icon-bell" label={t('notification.empty_list')} relative={true}/> :

                        <>

                            <HeaderAppVersionInfo />

                            <div onClick={this.markAllAsRead} className="mark-all-read">
                                <span className="link-style" disabled={data.unseen === 0}>{t('notification.make_all_as_read')}</span>
                            </div>

                            {Object.keys(segments).map((segment, key) => {
                                return (
                                    <div className="notifications-segment" key={key}>
                                        <div className="notifications-segment__title">
                                            {['today', 'yesterday'].indexOf(segment) !== -1 ?
                                                t(`notification.segment.${segment}`)
                                                :
                                                segment
                                            }
                                        </div>

                                        {segments[segment].map(notify => {
                                            if (notify.type === TYPE_USER_RESPONDED) {
                                                return this.renderUserResponded(notify)
                                            }

                                            if (notify.type === TYPE_MANAGER_WAITING) {
                                                return this.renderManagerWaiting(notify)
                                            }

                                            if (notify.type === TYPE_MANAGER_ACCEPTED) {
                                                return this.renderManagerAccepted(notify)
                                            }

                                            if (notify.type === TYPE_MANAGER_REJECTED) {
                                                return this.renderManagerRejected(notify)
                                            }

                                            if (notify.type === TYPE_USER_SMS_REPLY) {
                                                return this.renderSmsReply(notify)
                                            }

                                            return null
                                        })}
                                    </div>
                                );
                            })}

                        </>}

                        {!loader[LOADER_NOTIFICATIONS] &&
                            <div className="loading">{(t('global.loading'))}</div>
                        }

                        {initializeGarageNotify &&
                            <GarageReinitialize
                                garage={initializeGarageNotify.repairShop}
                                onSuccess={this.initializeGarageSuccess}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications,
        loader: state.loader,
        garage: state.garage,
        user: state.user,
    };
};

export default connect(mapStateToProps)(translate('translations')(HeaderNotifications));
