import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from "formik";
import {formAdditionalPercentOnWorksPrices} from "app/config/yup";
import Header from "components/layout/Header/Header";
import Button from "../../components/shared/Button/Button";
import Input from "components/shared/Input/Input";
import {LOADER_LABOUR_PRICES} from "store/consts/loader.constants";
import ListLoader from "components/shared/ListLoader/ListLoader";
import {
    labourPricesAdditionalPercentOnWorksFetch
} from "../../store/actions/labourPrices.actions";

class AdditionalPercentOnWorks extends Component {

    componentDidMount() {
        this.props.dispatch(labourPricesAdditionalPercentOnWorksFetch());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.additional_percent_on_works !== nextProps.additional_percent_on_works){
            this.props.setValues({additional_percent_on_works: nextProps.additional_percent_on_works});
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    render() {
        const { t, additional_percent_on_works, handleBlur, handleSubmit, formSubmitting, loader, touched, errors } = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} />

                <div className="container">

                    {loader[LOADER_LABOUR_PRICES] ?
                        <ListLoader /> :

                        <form onSubmit={e => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-sm-push-3">
                                    <Input
                                        type="tel"
                                        placeholder={t('pages.price_update.section_form.label_additional_percent_on_works')}
                                        name={'additional_percent_on_works'}
                                        label={t('pages.price_update.section_form.label_additional_percent_on_works')}
                                        onBlur={handleBlur}
                                        onChange={this.handleTextChange}
                                        value={additional_percent_on_works}
                                        error={touched.additional_percent_on_works && errors.additional_percent_on_works}
                                        addOn={'%'}
                                        step={0.000001}
                                        float={true}
                                    />

                                    <div className="services-save-footer">
                                        <Button size="lg" type="primary" disabled={formSubmitting.discountPrice}>
                                            {t('pages.price_update.section_form.button_save')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const AdditionalPercentOnWorksUpdateForm = withFormik(formAdditionalPercentOnWorksPrices)(AdditionalPercentOnWorks);

const mapStateToProps = (state) => {
    return {
        additional_percent_on_works: state.labourPrices.additional_percent_on_works,
        formSubmitting: state.formSubmitting,
        loader: state.loader
    };
};

export default connect(mapStateToProps)(translate('translations')(AdditionalPercentOnWorksUpdateForm));
