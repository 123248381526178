import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Button from "../../../components/shared/Button/Button";
import {withFormik} from "formik";
import {formTimeDue} from "app/config/yup";
import DatePicker from "react-datepicker";
import axios from 'app/config/axios'
import {toast} from "react-toastify";
import {parseResponse} from "../../../utils/common";
import "./TimeDue.scss";
import {updateGarage} from "../../../store/actions/garage.actions";
import InputCheckbox from "../../../components/shared/InputCheckbox";
import Toast from "../../../components/shared/Toast/Toast";
import SubmitContainer from "../../../components/shared/SubmitContainer/SubmitContainer";
import {captureException} from "../../../utils/captureException";

const API_GARAGE_TIME_DUE_UPDATE = 'garage/settings/update-time-due'
export const DEFAULT_TIME_DUE = '11:00'

class TimeDue extends Component {
    constructor(props) {
        super(props);

        let date = new Date()
        if(props.garage.time_due){
          date.setHours(props.garage.time_due.split(':').shift(), props.garage.time_due.split(':').pop())
        }
        else{
          date.setHours(DEFAULT_TIME_DUE.split(':').shift(), DEFAULT_TIME_DUE.split(':').pop())
        }

        this.state = {
            lang: null,
            time: date,
            updating: false,
            time_due_disabled: !!!props.garage.time_due
        }
    }

    submit = (e) => {
        const {isValid, values, setTouched} = this.props;

        e.preventDefault();

        setTouched({time: true});

        if(isValid){
            this.update(values.time_due)
        }
    };

    update = (timeDue) => {
        const {dispatch} = this.props

        this.setState({
            updating: true,
        })

        axios
          .post(API_GARAGE_TIME_DUE_UPDATE, { time_due: timeDue })
          .then(response => {
              toast.success(<Toast text={parseResponse(response)} type="success"/>)
              this.setState({
                  updating: false,
              })
              dispatch(updateGarage({time_due: timeDue}))
          })
          .catch(error => {
              captureException(error, API_GARAGE_TIME_DUE_UPDATE)

              toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
              this.setState({
                  updating: false,
              });
          })
    }

    handleChange = (e) => {
      const {setFieldValue, setFieldTouched} = this.props;
      const {checked} = e.target;

      if(checked === true){
        setFieldValue('time_due', null)
        setFieldTouched('time_due', true)
      }
      else{
        setFieldValue('time_due', `${new Date(this.state.time).getHours()}:${('0'+('0'+new Date(this.state.time).getMinutes()).slice(-2)).slice(-2)}`)
        setFieldTouched('time_due', true)
      }

      this.setState({
        time_due_disabled: checked
      })
    };


  render() {
        const {updating} = this.state;
        const {t, setFieldValue, setFieldTouched} = this.props;

    return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.settings.section_tabs.time_due')}</div>
                    </div>
                    <div className="settings-tab__content">
                        <form onSubmit={this.submit}>

                            <div className={`form-group ${this.state.time_due_disabled ? 'disabled' : ''}`}>
                              <label>{t('pages.settings.section_form.label.time_due')}</label>
                              <DatePicker
                                selected={this.state.time}
                                onChange={date => {
                                  this.setState({time: date})
                                  setFieldValue('time_due', `${new Date(date).getHours()}:${('0'+new Date(date).getMinutes()).slice(-2)}`)
                                  setFieldTouched('time_due', true)
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                className="time-picker-custom"
                                timeClassName={() => "time-picker-time-custom"}
                                disabled={this.state.time_due_disabled}
                                disabledKeyboardNavigation
                              />
                            </div>

                            <div className="mh-20 mb-20">
                              <InputCheckbox
                                id="time_due_disabled"
                                name="time_due_disabled"
                                value={this.state.time_due_disabled}
                                onChange={this.handleChange}
                                checked={this.state.time_due_disabled}
                                label={t('pages.settings.section_form.disable_time_due')}
                              />
                            </div>

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button id="time_due_save" size="lg" type="primary" disabled={updating}>{t('global.save')}</Button>
                                </div>
                            </SubmitContainer>
                        </form>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const TimeDueForm = withFormik(formTimeDue)(TimeDue);

const mapStateToProps = (state) => {
    return {
        garage: state.garage
    };
};

export default connect(mapStateToProps)(translate('translations')(TimeDueForm));
