import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import Input from "../../components/shared/Input/Input";
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse, todayTimestamp, yesterdayTimestamp} from "../../utils/common";
import ActivitiesItem from "../../components/ActivitiesItem/ActivitiesItem";
import "./ActivitiesContainer.scss";
import {captureException} from "../../utils/captureException";

const API_ACTIVITIES_URL = 'garage/activities'

class ActivitiesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            list: [],
            loading: true
        };
    }

    componentDidMount() {
        this.fetch();
    }

    handleTextChange = (e) => {
        this.setState({search: e.target.value})
    };

    handleTextClear = () => {
        this.setState({
            search: '',
        })
    }

    filterBySearch = (list) => {
        const {search} = this.state;

        return list.filter(item =>
            item.activities.filter(activity => activity.car.make.toLowerCase().includes(search.toLowerCase())).length > 0 ||
            item.activities.filter(activity => activity.car.range.toLowerCase().includes(search.toLowerCase())).length > 0 ||
            item.activities.filter(activity => activity.car.type.toLowerCase().includes(search.toLowerCase())).length > 0 ||
            item.user.first_name.toLowerCase().includes(search.toLowerCase()) ||
            item.user.last_name.toLowerCase().includes(search.toLowerCase()) ||
            item.user.email.toLowerCase().includes(search.toLowerCase()) ||
            item.user.phone.toLowerCase().includes(search.toLowerCase()))
    }

    fetch = () => {
        axios.get(API_ACTIVITIES_URL)
            .then(response => {
                this.setState({
                    list: response.data.sort(function (a, b) {
                        return b.last_activity.at.timestamp - a.last_activity.at.timestamp;
                    }),
                    loading: false
                })
            })
            .catch(error => {
                captureException(error, API_ACTIVITIES_URL);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
    }

    segments = (list) => {
        let segments = {
            today: [], yesterday: [], older: []
        };

        for (let item of list) {
            if (item.last_activity.at.timestamp >= todayTimestamp()) {
                segments.today.push(item);
            } else if (item.last_activity.at.timestamp >= yesterdayTimestamp()) {
                segments.yesterday.push(item)
            } else {
                segments.older.push(item)
            }
        }

        return segments;
    }

    render() {
        const {t, title} = this.props;
        const {search, loading, list} = this.state;

        let activities = this.segments(this.filterBySearch(list));

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">

                    {loading ? <ListLoader/> :
                        <div>
                            <Input
                                type="text"
                                placeholder={t('pages.activities.section_form.placeholder.search')}
                                name="search"
                                value={search}
                                onChange={this.handleTextChange}
                                onTextClear={this.handleTextClear}
                                iconRightClassName={'icon-cancel'}
                                iconLeftClassName={'icon-search'}
                            />

                            <div>
                                {Object.keys(activities).map((segment, key) => {

                                    if (!activities[segment].length) {
                                        return null;
                                    }

                                    return (
                                        <div key={key}>
                                            <div
                                                className="activity-date-segment">{t('pages.activities.segment.' + segment)}</div>

                                            {activities[segment].map((item, key) => {
                                                return (
                                                    <ActivitiesItem
                                                        key={key}
                                                        activity={item}
                                                    />
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(ActivitiesContainer);
