export const LABOURS_PREDEFINED = '[Labours Predefined]'

export const LABOURS_PREDEFINED_SET = `${LABOURS_PREDEFINED} SET`

export const laboursPredefinedSet = list => ({
  type: LABOURS_PREDEFINED_SET,
  payload: {
    list,
  },
})
