import React, { Component } from 'react';
import { connect } from "react-redux";
import { translate } from "react-i18next";
import "./style.css"
import Select from "../../components/shared/Select/Select";
import {USER_FILTER_ANALYTICS_BY_GARAGE_CONCEPT} from "../../store/consts/user.constants";

const TYPE_GARAGE = 'garage';
const TYPE_CONCEPT = 'concept';

class GarageFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: `${TYPE_GARAGE}-${props.garage.id}`,
            list: this.enrich(props.user.garages),
            loading: false
        }
    }

    componentDidMount() {
        const {selected, list} = this.state;
        const {filter} = this.props;

        filter({...list.find(i => i.id === selected), id: list.find(i => i.id === selected).item_id})
    }

    enrich = (list) => {
        const {t, user, garage} = this.props;

        list = list.map(i => {
            return {name: i.name, id: `${TYPE_GARAGE}-${i.id}`, item_id: i.id, type: TYPE_GARAGE}
        })

        if(USER_FILTER_ANALYTICS_BY_GARAGE_CONCEPT.indexOf(user.type) !== -1 && garage.concept){
            list.unshift({
                id: `${TYPE_CONCEPT}-${garage.concept.id}`,
                item_id: garage.concept.id,
                name: `${garage.concept.name} ${t('pages.generate_report.concept_total')}`,
                type: TYPE_CONCEPT
            })
        }

        return list;
    }

    handleChange = (id, option) => {
        const {filter} = this.props;

        filter({...option, id: option.item_id})

        this.setState({
            selected: option.id
        });
    }

    render() {
        const { list, selected } = this.state
        const { t } = this.props

        return (
            <React.Fragment>

                <Select
                    id={"garages"}
                    list={list}
                    selected={selected}
                    label={t('pages.analytics.garage_reinitialize.label')}
                    onChange={this.handleChange}
                >
                    {t('pages.analytics.garage_reinitialize.label')}
                </Select>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: state.garage,
        loader: state.loader,
    }
}

export default connect(mapStateToProps)(translate('translations')(GarageFilter))
