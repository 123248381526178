import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Popup from "components/shared/Popup/Popup";
import LabourWorks from "./LabourWorks";
import {compare} from "utils/common";
import {deepObject} from "utils/common";
import WorkAdd from "./WorkAdd";

export const AvailableWorks = props => {

    const {t, packagePath, packages} = props;

    const [works, setWorks] = useState(props.works);
    const [labourWorksShow, setLabourWorksShow] = useState(null);
    const [workAddShow, setWorkAddShow] = useState(false);

    useEffect(() => {
        if (compare(works, props.works)) {
            setWorks(props.works);
        }
    }, [props.works]);

    let renderLabourWorks = () => {

        if (!labourWorksShow) {
            return null;
        }

        let labour = deepObject(packages, labourWorksShow);

        return (
            <Popup title={t('pages.package_details.labour.works.list.title')} subtitle={labour.name} visible={!!labour}
                   contentScrollable={true} onClose={() => setLabourWorksShow(null)}>
                <LabourWorks
                    works={labour.works}
                    packagePath={`${packagePath}/labours/itemMpId:${labour.itemMpId}`}
                    onClose={() => setLabourWorksShow(null)}
                />
            </Popup>
        );
    };

    let renderWorkAddShow = () => {

        if (!workAddShow) {
            return null;
        }

        let _package = deepObject(packages, packagePath);

        return (
            <Popup title={t('pages.package_details.labour.works.add.title')} visible={!!workAddShow}
                   onClose={() => setWorkAddShow(false)}>
                <WorkAdd
                    packagePath={`${packagePath}/labours/itemMpId:${_package.labours[0].id}`}
                    onClose={() => setWorkAddShow(false)}
                />
            </Popup>
        );
    };
    return (
        <React.Fragment>

            <div className="extend-package-list mb-30">
                {Object.keys(works).map(path => (
                    <div key={path} className="extend-package-list__item">
                        <div className="font-size-sm">
                            {works[path].map(work => (
                                <div key={work.id}>{work.name} <span className="vertical-text-delimiter">|</span> <span className="bold">{work.duration}h</span></div>
                            ))}
                        </div>
                        <span className="link-style edit-link"
                              onClick={() => setLabourWorksShow(path)}><i className="icon-edit"/></span>
                    </div>
                ))}
            </div>

            {Object.keys(works).length === 0 &&
                <div className="mt-10">
                    <div className="btn btn-sm btn-secondary"  onClick={() => setWorkAddShow(true)}>
                        {t('pages.package_details.labour.works.add_more')}
                    </div>
                </div>
            }

            {renderLabourWorks()}
            {renderWorkAddShow()}

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        packages: state.packages
    };
};

export default connect(mapStateToProps)(translate('translations')(AvailableWorks));
