export const LOADER_INIT = 'initialization'
export const LOADER_FORM_VEHICLE = 'formVehicle'
export const LOADER_LICENCE_PLATE = 'licencePlate'
export const LOADER_API_GET_CAR = 'apiGetCar'
export const LOADER_API_GET_CAR_NUMBER = 'apiGetCarNumber'
export const LOADER_LABOUR_PRICES = 'labourPrices'
export const LOADER_SETTINGS_PAGE = 'settingsPage'
export const LOADER_MECHANICS_PAGE = 'mechanicsPage'
export const LOADER_LABOURS_SEARCH = 'labourSearch'
export const LOADER_LABOURS_PREDEFINED = 'laboursPredefined'
export const LOADER_ANALYTICS = 'analytics'
export const LOADER_DRAFT = 'draft'
export const LOADER_REQUESTS = 'requests'
export const LOADER_NOTIFICATIONS = 'notifications'
export const LOADER_CUSTOMERS_FETCHING = 'customersFetching'
export const LOADER_CHECKBOX = 'checkbox'
export const LOADER_SWITCH = 'switch'

export default {
    LOADER_INIT,
    LOADER_FORM_VEHICLE,
    LOADER_LICENCE_PLATE,
    LOADER_API_GET_CAR,
    LOADER_API_GET_CAR_NUMBER,
    LOADER_LABOUR_PRICES,
    LOADER_SETTINGS_PAGE,
    LOADER_MECHANICS_PAGE,
    LOADER_LABOURS_SEARCH,
    LOADER_LABOURS_PREDEFINED,
    LOADER_ANALYTICS,
    LOADER_DRAFT,
    LOADER_REQUESTS,
    LOADER_NOTIFICATIONS,
    LOADER_CUSTOMERS_FETCHING,
    LOADER_CHECKBOX,
    LOADER_SWITCH,
}
