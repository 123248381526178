import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {formUserPhone} from "app/config/yup";
import {withFormik} from "formik";
import Button from "components/shared/Button/Button";
import Popup from "components/shared/Popup/Popup";
import Phone from "../../components/shared/Phone/Phone";
import {toast} from "react-toastify";
import {parseResponse} from "../../utils/common";
import axios from 'app/config/axios'
import Loader, {LOADER_LOADING} from "../../components/shared/Loader/Loader";
import EditableEntry from "../../components/shared/EditableEntry/EditableEntry";
import Toast from "../../components/shared/Toast/Toast";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import {captureException} from "../../utils/captureException";
const API_USER_PHONES_URL = 'garage/settings/phone/list';
const API_CREATE_USER_PHONES_URL = 'garage/settings/phone/create';
const API_UPDATE_USER_PHONES_URL = 'garage/settings/phone/update';
const API_REMOVE_USER_PHONE_URL = 'garage/settings/phone/remove';

class Phones extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phones: [],
            phonesLoader: true,
            showAddPhone: false,
            updating: false
        }
    }

    componentDidMount() {
        this.fetch()
    }


    fetch = () => {
        axios.get(API_USER_PHONES_URL).then(response => {
            this.setState({
                phones: response.data.data,
                phonesLoader: false,
            })
        }).catch(error => {
            captureException(error, API_USER_PHONES_URL);

            toast.error(<Toast text={parseResponse(error.response)} type="error"/>);
            this.setState({
                phonesLoader: false,
            });
        })
    };

    updateOrCreate = (e) => {
        e.preventDefault();
        const {values} = this.props;

        if(values.id) this.update(values);
        else this.create(values);
    };

    remove = (item) => {
        this.setState({
            updating: true,
        });

        axios.post(API_REMOVE_USER_PHONE_URL, {phone_id: item.id, phone: item.phone}).then(response => {
            this.setState({
                phones: response.data.data,
                updating: false,
            })
            this.closeAddPhone()
        }).catch(error => {
            captureException(error, API_REMOVE_USER_PHONE_URL);

            toast.error(<Toast text={parseResponse(error.response)} type="error"/>);
            this.setState({
                updating: false,
            });
        })
    };

    update = (item) => {
        this.setState({
            updating: true,
        });

        axios.post(API_UPDATE_USER_PHONES_URL, {phone_id: item.id, phone: item.phone}).then(response => {
            this.setState({
                phones: response.data.data,
                updating: false,
            })
            this.closeAddPhone()
        }).catch(error => {
            captureException(error, API_UPDATE_USER_PHONES_URL)

            toast.error(<Toast text={parseResponse(error.response)} type="error"/>);
            this.setState({
                updating: false,
            });
        })
    };

    create = (item) => {
        this.setState({
            updating: true,
        });

        axios.post(API_CREATE_USER_PHONES_URL, {phone: item.phone}).then(response => {
            this.setState({
                phones: response.data.data,
                updating: false,
            })
            this.closeAddPhone()
        }).catch(error => {
            captureException(error, API_CREATE_USER_PHONES_URL);

            toast.error(<Toast text={parseResponse(error.response)} type="error"/>);
            this.setState({
                updating: false,
            });
        })
    };


    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    handleFieldChange = (name, value) => {
        const {setFieldTouched, setFieldValue} = this.props;

        setFieldTouched(name, true);
        setFieldValue(name, value);
    };

    setPhone = (item) => {
        const {setValues} = this.props;

        setValues(item);

        this.setState({
            showAddPhone: true
        });
    };

    closeAddPhone = () => {
        const {resetForm} = this.props;

        this.setState({showAddPhone: false});
        resetForm();
    };

    phone = () => {
        const {updating} = this.state;
        const {t, handleBlur, values, errors, touched} = this.props;

        return (
            <Popup title={t('pages.settings.section_form.add_new_phone')}
                   visible={this.state.showAddPhone}
                   onClose={this.closeAddPhone}>

                <form onSubmit={e => this.updateOrCreate(e)}>
                    <Phone
                        name={'phone'}
                        onChange={(e) => this.handleFieldChange('phone', e)}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={touched.phone && errors.phone}
                    />
                    <Button id="save_settings_phones" disabled={updating} size="lg" type="primary">{t('global.save')}</Button>
                </form>
            </Popup>
        )
    };


    render() {
        const {phonesLoader, phones} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                {phonesLoader ? <div className="text-center mv-30"><Loader type={LOADER_LOADING} isLoading={true}/> {t('global.loading')}</div> :
                    <div className="settings-tab" id="contact-numbers">
                        <div className="settings-tab__header">
                            <div className="settings-tab__header__title">{t('pages.settings.section_tabs.phones')}</div>
                        </div>
                        <div className="settings-tab__content">

                            {phones.map((item, key) =>
                                <EditableEntry
                                    key={key}
                                    list={[
                                        {name: t('form.label.phone'), value: item.phone}
                                    ]}
                                    buttons={[
                                        {icon: 'icon-bin', label: t('global.remove'), click: () => this.remove(item)},
                                        {icon: 'icon-edit', label: t('global.edit'), click: () => this.setPhone(item)}
                                    ]}
                                />
                            )}

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button size="lg" type="primary" onClick={() => this.setState({showAddPhone: true})}
                                            extraClass="mt-20">
                                        + {t('pages.settings.section_form.add_new_phone')}
                                    </Button>
                                </div>
                            </SubmitContainer>
                        </div>
                    </div>
                }

                {this.phone()}
            </React.Fragment>
        );
    }
}

const PhonesForm = withFormik(formUserPhone)(Phones);

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(translate('translations')(PhonesForm));
