import React from 'react'
import './index.scss'
import Link from 'react-router-dom/es/Link'
import { translate } from 'react-i18next'
import { getServiceImgPath } from '../../../../utils/service'
import pages from 'app/consts/routes'
import history from 'routes/history'
import Badge from "../../Badge/Badge";
import {STATUS_RESPONDED_GARAGE} from "../../../../store/consts/status.constants";

const Request = ({ request, t, createdBy, dot, onClick, time, remove, onRemove, logo }) => {
    if (!request || !request.car) {
        return null
    }

    let appointmentSelected = request.appointment_discounts.find(
        appointment => appointment.selected && !appointment.is_today,
    )

    return (
        <div className={`request clearfix ${remove ? 'request--has-remove' : ''}`}>
            {dot && <span className={`request-dot request-dot--${dot}`} />}

            {remove && (
                <div className="request-remove pointer" onClick={onRemove}>
                    <i className="icon-cancel" />
                </div>
            )}

            {onClick ? (
                <div className="icon" onClick={onClick}>
                    <span className="icon-chevron-right pointer" />
                </div>
            ) : (
                <Link to={`${pages.request_details}${request.token}`} className="icon">
                    <span className="icon-chevron-right pointer" />
                </Link>
            )}

            {logo !== false && (
                <div className="request-logo">
                    <img src={getServiceImgPath(request.car.make.toLowerCase())} alt={request.car.make} />
                </div>
            )}

            <div
                className="request-car-detail"
                onClick={onClick ? onClick : () => history.push(`${pages.request_details}${request.token}`)}
            >
                <div className="request-car-name">
                    <h5>
                        {request.is_test &&
                            <Badge type="primary" inverted={true}>{t('pages.requests.test')}</Badge>
                        }
                        {request.car.make} {request.car.range} {request.car.type}
                    </h5>
                </div>
                <div className="request-car-detail-sub">
                    {request.owner && (
                        <div>
                            {request.owner.first_name} {request.owner.last_name}
                        </div>
                    )}
                    {createdBy && (
                        <div className="request-created-by">
                            <p>
                                {t('pages.requests.created_by')} {request.created_by.first_name}{' '}
                                {request.created_by.last_name}
                            </p>
                        </div>
                    )}
                    {time && (
                        <div className="request-created-by">
                            <p>
                                {t('pages.requests.last_updated')} {request.last_updated}
                            </p>
                        </div>
                    )}
                    {appointmentSelected && (
                        <div>
                            {t('pages.requests.termin')}: {appointmentSelected.datetime}
                        </div>
                    )}
                </div>
            </div>
            {request.status === STATUS_RESPONDED_GARAGE &&
                <Badge type="success" inverted={true}>
                    {t('pages.request_details.package.log.status.' + request.status)}
                </Badge>
            }
        </div>
    )
}

export default translate('translations')(Request)
