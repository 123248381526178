import React, {Component} from 'react';
import './ProfileProgress.scss';
import {translate} from "react-i18next";
import {Link} from "react-router-dom";
import pages from "../../app/consts/routes";
import {connect} from "react-redux";
import {USER_CAN_UPDATE_GARAGE, USER_CAN_UPDATE_PRICES} from "../../store/consts/user.constants";

class ProfileProgress extends Component {

    constructor(props) {
        super(props);

        this.link = {
            garage: {
                logo: `${pages.settings}#logo`,
                address: `${pages.settings}#address`,
                web: `${pages.settings}#web`,
                zip: `${pages.settings}#zip_id`,
                contact_phones: `${pages.settings}#contact-numbers`,
            },
            profile: {
                first_name: `${pages.user_profile}#first_name`,
                last_name: `${pages.user_profile}#last_name`,
                email: `${pages.user_profile}#email`,
                username: `${pages.user_profile}#username`,
                phone: `${pages.user_profile}#phone`,
                avatar: `${pages.user_profile}#avatar`,
            },
            prices: {
                hourly: `${pages.price_update_hourly_prices}`,
                oil: `${pages.price_update_oil_prices}`,
                liquids: `${pages.price_update_liquids_prices}`,
            },
        }
    }

    itemValid = (item) => item ? !!Object.keys(item).length : false;

    item = (list, type) => {
        const {t} = this.props;

        return (
            <div className="profile-progress-item">
                <div className="profile-progress-item-header">
                    <div className="profile-progress-item-header__title">{t('profile_progress.title.' + type)}</div>
                </div>
                <div className="profile-progress-item-list">
                    {Object.keys(list).map((name, key) => {

                        if(this.itemValid(list[name])){
                            return (
                                <div className="profile-progress-item-list__item" key={key}>
                                    <div className="filled">
                                        <i className="icon-check"/> {t(`profile_progress.item.${type}.${name}`)}</div>
                                </div>
                            )
                        }

                        return (
                            <div className="profile-progress-item-list__item" key={key}>
                                <div className="unfilled">
                                    <Link to={this.link[type][name]}>
                                        <i className="icon-warrning-fill"/> {t(`profile_progress.item.${type}.${name}`)}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    render() {
        const {onClose, user, garage, profile, prices, percentFilled} = this.props;

        return (
            <React.Fragment>
                <div className="profile-progress-container-cover" onClick={onClose}/>
                <div className="profile-progress-container">
                    <div className="container">
                        <div className="col-sm-4 col-xs-6">
                            {(garage && USER_CAN_UPDATE_GARAGE.indexOf(user.type) !== -1) ? this.item(garage, 'garage') : null}
                        </div>
                        <div className="col-sm-4 col-xs-6">
                            {profile ? this.item(profile, 'profile') : null}
                        </div>
                        <div className="col-sm-4 col-xs-6">
                            {(prices && USER_CAN_UPDATE_PRICES.indexOf(user.type) !== -1) ? this.item(prices, 'prices') : null}
                        </div>

                        <div className="profile-progress-range">
                            <div className="profile-progress-range__fill" style={{width: percentFilled + '%'}}>
                                <span>{percentFilled}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(translate('translations')(ProfileProgress));
