import store from 'store/store'
import {availableParts} from './package'
import {roundCeil} from '../../utils/common'
import {DISCOUNT_BRAKES_TYPE, DISCOUNT_PARTS_TYPE} from '../consts/discounts.constants'
import {isPackageTypeTires, TYPE_TIRES} from "../consts/package/package.type.constants";
import {TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPE_BRAND_PRICE_QUALITY} from "../consts/tires.constants";

export const sumPackagePriceTotal = (_package, round = true) => {
    let price = sumPackagePrice(_package)

    if (!isPackageTypeTires(_package.type)) {
        price -= partDiscount(_package)
        price -= brakeDiscount(_package)
        price -= packageDiscount(_package)
    }

    price += price * (store.getState().garage.tax / 100)

    if (round) {
        return roundCeil(price)
    }

    return price
}

export const sumPackagesTotalIncludeDiscount = (packages, discount) => {
    let price = 0

    packages.forEach(_package => {
        price += _package.price - _package.price * (discount / 100)
    })

    return roundCeil(price)
}

export const rangedSumPackagesTotalIncludeDiscount = (packages, discount = 0) => {
    let price = 0
    let minPrice = 0
    let maxPrice = 0

    packages.forEach(_package => {
        price += _package.price - _package.price * (discount / 100)
        if(_package.type === TYPE_TIRES){
            _package.tires.list.forEach(_tire => {
                if(_tire.type === TIRES_TYPE_BRAND_PREMIUM){
                    maxPrice += _tire.price_with_tax - _tire.price_with_tax * (discount / 100)
                }else if(_tire.type === TIRES_TYPE_BRAND_PRICE_QUALITY){
                    minPrice += _tire.price_with_tax - _tire.price_with_tax * (discount / 100)
                }
            })
        }else{
            minPrice += _package.price - _package.price * (discount / 100)
            maxPrice += _package.price - _package.price * (discount / 100)
        }
    })

    return {
        price: price,
        minPrice: minPrice,
        maxPrice: maxPrice
    }
}

export const packageDiscount = _package => {
    return _package.discount ? _package.discount.price : 0
}

export const partDiscount = _package => {
    let discountParts = getDiscount(DISCOUNT_PARTS_TYPE),
        price = 0

    if (discountParts) {
        Object.values(availableParts(_package)).forEach(part => {
            if (part.selected === true && discountParts.exclude.indexOf(part.id) === -1) {
                price += sumPartPrice(part)
            }
        })

        return price * (discountParts.discount / 100)
    }

    return price
}

export const brakeDiscount = _package => {

    let discountBrakes = getDiscount(DISCOUNT_BRAKES_TYPE),
        price = 0

    if (discountBrakes) {
        Object.values(availableParts(_package)).forEach(part => {
            if (part.selected === true && discountBrakes.include.indexOf(part.id) !== -1) {
                price += sumPartPrice(part)
            }
        })

        return price * (discountBrakes.discount / 100)
    }

    return price
}

export const getDiscount = discountType => {
    let yearCondition = new Date().getFullYear() - store.getState().car.first_registration,
        carMakeId = store.getState().car.make_id,
        discountBrakes = store.getState().garage.discounts.filter(discount => {
        return discount.type === discountType
            && ((discount.car_make_id === 0 || discount.car_make_id === carMakeId)
                && (discount.rule === ""
                    || discount.rule === "DefaultPriceRule"
                    || (discount.rule === "YoungerThenRule" && yearCondition <= parseInt(discount.condition.year))
                    || (discount.rule === "OlderThenRule" && yearCondition > parseInt(discount.condition.year))))
    })

    if (discountBrakes.length > 1) {
        if(discountBrakes[0].rule === "" || discountBrakes[0].rule === "DefaultPriceRule"){
            discountBrakes = discountBrakes[1]
        }else{
            discountBrakes = discountBrakes[0]
        }
    }else if(discountBrakes){
        discountBrakes = discountBrakes[0]
    }

    return discountBrakes
}

export const sumPackagePrice = _package => {
    let price = 0

    _package.labours.forEach(labour => {
        price += sumLabourPrice(labour)
    })

    return price
}

export const sumLabourPrice = labour => {
    let workPrice = 0;
    let partPrice = 0;
    let priceFixed = labour.priceFixed;

    if (labour.amounts) {
        priceFixed = priceFixed * labour.amounts;
    }

    labour.works.forEach(work => {
        if (work.selected === true) {
            workPrice += sumWorkPrice(work)
            partPrice += sumPartsPrice(work.parts)
        }
    })

    return workPrice + partPrice + priceFixed;
}

export const sumAvailableWorks = availableWorks => {
    let price = 0
    Object.values(availableWorks).forEach(works => {
        works.forEach(work => (price += sumWorkPrice(work)))
    })

    return price
}

export const sumWorkPrice = work => {
    let additionalPercentOnWorks = store.getState().offer.additional_percent_on_works
        ? store.getState().offer.additional_percent_on_works
        : store.getState().garage.additional_percent_on_works

    return work.duration * store.getState().garage.hourly_price * ((additionalPercentOnWorks + 100) / 100)
}

export const sumPartsPrice = parts => {
    let price = 0

    Object.values(parts).forEach(part => {
        if (part.selected === true) price += sumPartPrice(part)
    })

    return price
}

export const sumPartPrice = part => {
    let price = 0
    part.versions.forEach(version => {
        if (version.selected) {
            price += sumVersionPrice(version)
        }
    })

    return price
}

export const sumFixedPrice = labours => {
    let price = 0

    Object.values(labours).forEach(labour => {
        price += labour.priceFixed;
    })

    return price
}

const sumVersionPrice = version => {
    let price = 0
    version.set.forEach(set => {
        price += sumSetPrice(set)
    })

    return price
}

const sumSetPrice = set => {
    return set.price * set.amounts
}
