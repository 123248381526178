import stepReducer from './step.reducer'
import loaderReducer from './loader.reducer'
import formSubmittingReducer from './formSubmitting.reducer'
import carReducer from './car.reducer'
import globalReducer from './global.reducer'
import carStep from './carStep.reducer'
import cantonListReducer from './cantonList.reducer'
import packagesReducer from './packages.reducer'
import userReducer from './user.reducer'
import garageReducer from './garage.reducer'
import labourPricesReducer from './labourPrices.reducer'
import languageReducer from './language.settings.reducer'
import mastercheckLayoutReducer from './mastercheckLayout.settings.reducer'
import recipientsReducer from './recipients.settings.reducer'
import requestsReducer from './requests.reducer'
import mechanicsReducer from './mechanics.reducer'
import laboursPredefinedReducer from './laboursPredefined.reducer'
import analyticsReducer from './analytics.reducer'
import clientReducer from './client.reducer'
import offerReducer from './offer.reducer'
import notificationsReducer from './notifications.reducer'
import countryReducer from './country.reducer'
import checklistLaboursReducer from './checklistLabours.reducer'
import officialServicesReducer from './officialServices.reducer'
import systemMessageReducer from './systemMessage.reducer'
import appVersion from './appVersion.reducer';
import tiresReducer from './tires.reducer'
import systemMessagesCounterReducer from './systemMessagesCounter.reducer'

export default {
    car: carReducer,
    step: stepReducer,
    loader: loaderReducer,
    formSubmitting: formSubmittingReducer,
    global: globalReducer,
    carStep: carStep,
    cantonList: cantonListReducer,
    packages: packagesReducer,
    user: userReducer,
    garage: garageReducer,
    labourPrices: labourPricesReducer,
    languages: languageReducer,
    mastercheckLayout: mastercheckLayoutReducer,
    recipients: recipientsReducer,
    requests: requestsReducer,
    mechanics: mechanicsReducer,
    laboursPredefined: laboursPredefinedReducer,
    analytics: analyticsReducer,
    client: clientReducer,
    offer: offerReducer,
    notifications: notificationsReducer,
    country: countryReducer,
    checklistLabours: checklistLaboursReducer,
    officialServices: officialServicesReducer,
    systemMessages: systemMessageReducer,
    appVersion: appVersion,
    tires: tiresReducer,
    systemMessagesCounter: systemMessagesCounterReducer,
}
