/* eslint-disable */
import React from 'react'
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR,
    TIRES_SEASON_ALL_SEASON,
    TIRES_SEASON_NORDIC,
    TIRES_SEASON_SUMMER,
    TIRES_SEASON_WINTER,
    TIRES_STEP_LIST_PREMIUM,
    TIRES_STEP_LIST_PRICE_QUALITY,
    TIRES_TYPE_BRAND_PREMIUM,
    TIRES_TYPE_BRAND_PRICE_QUALITY
} from "../../../store/consts/tires.constants";

export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage',
        },
        titles: {
            system_messages: 'Systemmeldungen', //TODO: Translate
            generate_report: 'Report',
            customers: 'Kunden',
            mechanics: 'Mechaniker verwalten',
            login: 'Anmelden',
            password_reset: 'Passwort zurücksetzen',
            password_reset_new: 'Neue Passwortaktivierung',
            pick_garage: 'Garage wählen',

            home: 'Garage Booster',
            price_update: 'Preiseinstellungen',
            price_update_hourly_price: 'Stundenansatz bearbeiten',
            price_update_oil_price: 'Ölpreis bearbeiten',
            price_update_liquids_price: 'Flüssigkeitspreis bearbeiten',
            price_update_discount: 'Rabatt auf Teile bearbeiten',
            price_update_price_update_additional_percent_on_works: 'Zusätzlicher Prozentsatz für Arbeiten',

            settings: 'Einstellungen',
            user_profile: 'Profil',

            add_cost: 'Garage Booster – wählen Sie Ihr Fahrzeug aus',
            packages: 'Garage Booster – Paketliste',
            package_details: 'Garage Booster – Paketdetail',
            labour_search: 'Garage Booster – Arbeitssuche',
            preview: 'Garage Booster – Checkout',
            confirm: 'Garage Booster – Anfrage verschickt',

            analytics: 'Statistik',
            requests: 'Aufträge',
            request_details: 'Auftragsdetails',
            request_package_details: 'Paketdetails',
            request_preview: 'Checkout',
            request_checklist_details: 'Mastercheck',

            activities: 'Kundenaktivität',
            activity: 'Aktivität',

            official_service: 'Service gemäss Herstellervorgaben',
            checklist_labour: 'Mastercheck arbeiten',
            customers_upload: 'Kunden hochladen',

            analytics_enhanced: 'Statistik',
            analytics_enhanced_packages: 'Pakete',
            analytics_enhanced_checklist: 'Mastercheck',
            analytics_enhanced_top_cars: 'Fahrzeugstatistik',
            analytics_enhanced_top_labours: 'Arbeitsstatistik',
            analytics_enhanced_top_garage: 'Garagenstatistik',

            tires_import: 'Reifenimport',
        },
        global: {
            app_version_out_of_date: 'Die neue Version von Garage Booster ist verfügbar, bitte klicken Sie hier, um Ihre Anwendung zu aktualisieren',
            app_version_out_of_date_info: 'Die neue Version von Garage Booster ist verfügbar.',
            alt: {
                'request-send-success-icon': 'Erfolgssymbol',
            },
            tabs: {
                pending: 'Anstehend',
                other: 'Andere',
            },
            filter: {
                all: 'Alle',
                title: 'Filter',
                hide: 'Filter ausblenden',
                distance: 'Distanz',
                price: 'Preis',
                else: 'Sonstiges',
                ratings: {
                    label: 'Braucht Bewertungen',
                    description: 'Nur Garagen mit Bewertungen',
                },
                official_garage: {
                    label: 'Offizielle Markengarage',
                    description: 'Nur offizielle {carBrand} Markenvertreter',
                },
                no_filters: 'Keine Filter ausgewählt',
                save: 'Suchen',
                reset: 'Zurücksetzen',
            },
            illustration_card: {
                no_garages_nearby: {
                    title: 'Keine Garage in der Nähe, die ausgewählte Dienstleistungen anbietet',
                    description: 'Ändern Sie Ihren Standort, um Angebote in verschiedenen Regionen anzuzeigen.',
                },
            },
            or: 'oder',
            cancel: 'Abbrechen',
            back: 'Zurück',
            more: 'mehr',
            skip: 'Überspringen',
            currency: 'CHF',
            tax: 'MwSt.',
            total: 'Total',
            total_with_vat: 'Total inkl MwSt',
            discount: 'Rabatt',
            files: 'Bilder',
            service: 'Service',
            part_number: 'Part number:',
            to: 'zu',
            km: 'km',
            loading: 'Wird geladen',
            garage_reinitialize: 'Garage wechseln...',
            save: 'Speichern',
            update: 'Aktualisieren',
            edit: 'Bearbeiten',
            remove: 'Entfernen',
            car: {
                make: 'Marke',
                range: 'Modell',
                type: 'Ausführung',
                go_back: 'Bearbeiten',
                year: 'Baujahr',
                licence_plate: 'Nummernschild',
                contract_number: 'Auftragsnummer',
                update_info: 'Fahrzeuginformationen aktualisieren',
                edit: 'Bearbeiten',
                mileage: 'km-Stand',
            },
            official_service: {
                title: 'EMPFEHLUNG FÜR DEIN AUTO',
                description:
                    'Dank der durch dich zur Verfügung gestellten Daten, sowie den Herstellerangaben von {{carBrand}}, können wir dir einen personalisierten Service für dein Fahrzeug empfehlen.',
                includes: 'BEINHALTET',
            },
            preselected_service: {
                title: 'Ausgewählte Services: ',
            },
            additional_service: {
                open: 'Zusätzliche Services',
                title: 'Reparatur',
                description: 'Wähle weitere Services aus um die Suche zu verfeinern.',
                uncheck_all: 'Alle abwählen',
                save: 'Speichern',
            },
            msg_title: {
                error: 'Fehlermeldung',
                info: 'Info Nachricht',
            },
            undo: 'Rückgängig machen',
            undo_deleted: '{{num}} gelöscht',
            you_sure: 'Sind Sie sicher?',
            months: {
                0: 'Januar',
                1: 'Februar',
                2: 'März',
                3: 'April',
                4: 'Mai',
                5: 'Juni',
                6: 'Juli',
                7: 'August',
                8: 'September',
                9: 'Oktober',
                10: 'November',
                11: 'Dezember',
            },
            agreement: 'Ich akzeptiere die',
            terms_and_conditions: 'AGB',
            privacy_policy: 'Datenschutzerklärung',
            no_data: 'Keine Daten',
            optional: 'optional',
        },
        tab: {
            car: 'FAHRZEUGDATEN',
            services_car: 'Mein Auto',
            services_maintenance: 'Autoservice- und reparatur',
            order_overview: 'Auftragsübersicht',
        },
        form: {
            placeholder: {
                make: 'Marke',
                range: 'Modell',
                type: 'Fahrzeugausführung',
                gear: 'Getriebeart',
                first_registration: '2019',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                username: 'Handynr. / E-Mail eingeben / Benutzername',
                username2: 'Benutzername',
                year: '2019',
                km_stand: 'Kilometerstand',
                first_name: 'Vorname',
                last_name: 'Nachname',
                mechanic_type: 'Type',
                email: 'E-Mail',
                phone: 'Telefonnummer',
                password: 'Passwort',
                password_confirmation: 'Passwort bestätigen',
                city: 'Stadt',
                color: 'Farbe',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Bitte auswählen',
                },
                user_activity: 'Status',
                date: 'Datum auswählen',
                morning: 'Morgen',
                afternoon: 'Nachmittag',
                car_number: {
                    vin_swiss: 'TSN oder VIN',
                    vin_kba: 'KBA-Nummer oder VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'KBA-Nummer',
                    default: 'Auto Nummer', // TODO NOT SURE FOR THIS TRANSLATE
                },
                user_type: 'Berechtigung',
                mileage: 'km-Stand',
            },
            label: {
                licence_plate: 'Nummernschild',
                make: 'Marke',
                range: 'Modell',
                type: 'Ausführung',
                gear: 'Getriebeart',
                first_registration: 'Jahr der Erstzulassung',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                username: 'Handynr. / E-Mail / Benutzername',
                username2: 'Benutzername',
                year: 'Jahr der Erstzulassung',
                km_stand: 'Kilometerstand',
                first_name: 'Vorname',
                last_name: 'Nachname',
                phone: 'Telefonnummer',
                email: 'E-Mail',
                password: 'Passwort',
                password_confirmation: 'Passwort bestätigen',
                date: 'Datum',
                availability: 'Verfügbarkeit',
                bookFor: 'Buchen für',
                book: 'Anfragen',
                city: 'Stadt',
                color: 'Farbe:',
                car_number: {
                    vin_swiss: 'TSN oder VIN',
                    vin_kba: 'KBA-Nummer oder VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'KBA-Nummer',
                    default: 'Auto Nummer', // TODO NOT SURE FOR THIS TRANSLATION
                },
                user_type: 'Konto Typ',
                mileage: 'km-Stand',
            },
            button: {
                next: 'Weiter',
                confirm: 'Bestätigen',
                submit: 'Weiter',
            },
            select: {
                no_result: 'Kein Ergebnis gefunden',
            },
        },
        validations: {
            label: {
                make: 'Marke',
                range: 'Modell',
                type: 'Version',
                first_registration: 'Jahr der Erstzulassung',
                gear: 'Getriebeart',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                km_stand: 'Kilometerstand',
                car_number: 'Autokennzeichen',
                avatar: 'Avatar',
                username: 'Handynr. / E-Mail / Benutzername',
                username2: 'Benutzername',
                firstname: 'Vorname',
                lastname: 'Nachname',
                phone: 'Telefonnummer',
                email: 'E-Mail',
                password: 'Passwort',
                password_match: 'Die Passwörter müssen übereinstimmen',
                user_type: 'Konto Typ',

                zip_not_found: 'Keine Ergebnisse mit PLZ {{name}}',
                zip_not_valid: 'Bitte wähle eine gültige PLZ.',
                zip_required: 'PLZ ist obligatorisch.',
                zip_must_be_number: 'Bitte gib nur Zahlen ein.',

                hourly_price: {
                    production_year: 'Produktionsjahr',
                    younger_price: 'Preis',
                },
            },
            default: {
                mixed: {
                    required: '${label} fehlt',
                },
                string: {
                    email: 'Bitte E-Mail prüfen',
                    min: 'Zu kurzer Wert',
                    max: 'Zu langer Wert',
                    test: '${label} zustimmen',
                },
                boolean: {
                    required: '${label} zustimmen',
                },
            },
            termin_picker: {
                drop_off_title: 'Verfügbarkeit für die Fahrzeugabgabe',
                drop_off_description: 'Wähle das passende Datum und die Uhrzeit für die Abgabe deines Autos.',
                drop_off_description_upto:
                    'Wähle bitte deinen Wunschtermin und die Uhrzeit für die Abgabe deines Autos.',
                maximum: 'Es können maximal 5 Daten gewählt werden.',
                required: 'Bitte wähle ein Datum.',
            },
            licence_plate: {
                canton: {
                    valid: 'Kanton', //Gültiger
                    nonValid: 'Kanton', //Ungültiger
                },
                number: {
                    valid: 'Nummer', //Gültige
                    nonValid: 'Nummer', //Ungültige
                },
                required: 'Gültiges Nummernschild ist erforderlich',
                cantonAsString: 'Kanton darf nur Buchstaben enthalten',
            },
            car_number: {
                invalid_format: 'Ungültiges Format für Typenscheinnummer',
            },
        },
        header: {
            profile_settings: 'Profileinstellungen',
            reporting: 'Report',
            switch_garage: 'Garage wechseln',
            settings: 'Garageneinstellungen',
            price_update: 'Preiseinstellungen',
            mechanic: 'Mechaniker',
            logout: 'Abmelden',
            customers: 'Kunden',
            checklists: 'Mastercheck',
            terms_and_conditions: 'AGB',
            privacy_policy: 'Datenschutzerklärung',
            analytics_enhanced: 'Mehr Statistik',
            tires_upload: 'Reifenimport',
            notifications: 'Information',
        },
        footer: {
            tabs: {
                home: 'Home',
                make_requests: 'Neuer Auftrag',
                requests: 'Aufträge',
                analytics: 'Statistik',
                price_update: 'Preiseinstellungen',
                settings: 'Einstellungen',
                general_settings: 'Allgemeine Einstellungen',
                mechanics: 'Mechaniker',
                news: 'News',
            },
        },
        confirm_popup: {
            ok: 'OK',
            cancel: 'Abbrechen',
        },
        user: {
            activity: {
                active: 'Aktiviert',
                inactive: 'Deaktiviert',
            },
            roles: {
                manager: 'Managerin',
                mechanic: 'Mechaniker',
                mechanic_advanced: 'Mechaniker mit Kundenkontakt',
                supervisor: 'Supervisor',
            },
        },
        pages: {
            system_messages: {
                title: 'Systemmeldungen', //TODO: Translate
                news: 'News', //TODO: Translate
                no_messages: 'Es sind keine Nachrichten vorhanden', //TODO: Translate
                mark_as_read: 'Als gelesen markieren', //TODO: Translate
                mark_as_unread: 'Als ungelesen markieren', //TODO: Translate
                filters: {
                    all_types: 'Alle Typen', //TODO: Translate
                    type_maintenance: 'Instandhaltung', //TODO: Translate
                    type_incident: 'Vorfall', //TODO: Translate
                    type_warning: 'Wartung', //TODO: Translate
                    type_info: 'Info', //TODO: Translate
                    type_update: 'Update', //TODO: Translate
                }
            },
            tire_prices: {
                title: 'Reifenpreise',
                run_flat: 'Run flat',
                tire_disposal_pkw: 'Reifenentsorgung PKW',
                tire_disposal_4x4_llkw: 'Reifenentsorgung 4x4 LLKW',
                rim_size_17_18: 'Felgengrösse 17 und 18',
                rim_size_lt_16: 'Felgengrösse < 17',
                rim_size_gt_19: 'Felgengrösse > 19',
                save_success: 'Preise wurden erfolgreich gespeichert'
            },
            generate_report: {
                pick_date: 'Wähle ein Datum',
                title: 'Excel-Report erstellen',
                start_date: 'Von',
                end_date: 'Bis',
                generate: 'Generieren',
                concept_total: 'TOTAL',
            },
            checklist_labour: {
                title: 'Mastercheck',
                search: 'Suchen...',
                no_results: 'Kein Resultaten.',
                save: 'Speichern',
            },
            customers: {
                title: 'Kunden',
                search: 'Suchen...',
                edit_title: 'Bearbeiten',
                first_name: 'Vorname',
                last_name: 'Name',
                email: 'E-Mail',
                phone: 'Haustelefonnummer',
                home_phone: 'Home phone number',
                licence_plates: 'Nummernschild',
                first_name_required: 'Vorname ist erforderlich',
                last_name_required: 'Name ist erforderlich',
                email_required: 'E-Mail ist erforderlich',
                phone_required: 'Telefonnummer ist erforderlich',
                no_results: 'Kein Resultaten.',
                name: 'Name',
                customers_upload: 'Kunden hochladen',
                file_upload_started: 'Der Upload hat begonnen, bitte warten.',
                file_upload_success: 'Datei erfolgreich hochgeladen.',
                file_upload_error: 'Etwas ist schief gelaufen.',
                file_upload_missing: 'Datei fehlt. Bitte wählen Sie zuerst eine Datei.',
                upload: 'Hochladen',
                customers_upload_title: 'Kunden hochladen',
                loading_upload_message: 'Upload läuft. Warten Sie mal.',
                resolve_duplications: 'Lösen Sie Duplikate',
                skip: 'Überspringen',
                add: 'Hinzufügen',
                overwrite: 'Überschreiben',
                resolve_duplications_in_progress: 'Laufende Duplikate auflösen...',
                choose_file: 'Datei wählen',
            },
            home: {
                title: 'Garage Booster',
                new_requests: 'Neue Aufträge:',
            },
            mechanics: {
                title: 'Mechaniker verwalten',
                list_is_empty: 'Die Liste der Mechaniker ist leer',
                add_new_person: 'Neuen Mechaniker hinzufügen',
                change_person_details: 'Ändern Sie die Personendaten',
            },
            requests: {
                title: 'Aufträge',
                mechanic: 'Mechaniker',
                created_by: 'Erstellt von',
                no_results: 'Keine Aufträge gefunden',
                waiting_for_response: 'Wartet auf Antwort',
                no_requests: 'Keine Aufträge',
                last_updated: 'Letzte Änderung',
                termin: 'Termine',
                test: 'Test',
                tab: {
                    pending_manager: 'Zur Genehmigung',
                    pending_user: 'Auf Kunden warten',
                    responded: 'Beantwortet',
                    archived: 'Archiviert',
                },
                form: {
                    label: {
                        search: 'Suchen',
                    },
                    placeholder: {
                        search: 'Suche nach Kunde oder Auto',
                    },
                },
            },
            request_details: {
                print_pdf: 'PDF drucken',
                title: 'Auftragsdetails',
                no_packages: 'Keine Pakete',
                approve_request: 'Genehmigen',
                reject_request: 'Ablehnen',
                edit_request: 'Auftrag ändern',
                edit_package: 'Paket bearbeiten',
                remind_user: 'Kunden erinnern',
                duplicate_request: 'Erstellen Sie eine weitere Anfrage',
                tab: {
                    accepted: 'Akzeptiert',
                    rejected: 'Abgelehnt',
                },
                package: {
                    title: 'Paketdetails',
                    labours: {
                        title: 'Autoarbeiten',
                    },
                    works: {
                        title: 'Arbeiten',
                    },
                    parts: {
                        title: 'Teile',
                    },
                    tires: {
                        title: 'Reifen',
                        mounting_total_price: 'Reifen montieren (inkl. Wuchten)',
                    },
                    client: {
                        title: 'Kunde',
                    },
                    vehicle: {
                        title: 'Fahrzeug',
                    },
                    files: {
                        title: 'Dateien',
                    },
                    log: {
                        title: 'Logbuch',
                        status: {
                            1: 'Anfrage öffnen',
                            2: 'Wartet auf KDB',
                            3: 'An den Kunden gesendet',
                            4: 'Der Kunde hat geantwortet',
                            41: 'Von Garage bestätigt',
                            5: 'Vom Manager abgelehnt',
                            6: 'Archiviert',
                        },
                    },
                    sms_replies: {
                        title: 'SMS-Antworten',
                    },
                    description: {
                        title: 'Beschreibung',
                    },
                    additional_percent_on_works: 'Kleinteilezuschlag auf Arbeit',
                    packages: {
                        title: 'Auftragsdetails',
                    },
                    packages_accepted: {
                        title: 'Akzeptiert',
                    },
                    packages_rejected: {
                        title: 'Abgelehnt',
                    },
                    packages_opened: {
                        title: 'Geöffnet',
                    },
                    packages_status: {
                        title: 'Auftragsstatus',
                    },
                    response_time: {
                        title: 'Reaktionszeit',
                    },
                },
                client: {
                    approve: {
                        title: 'Kundendaten',
                        subtitle: 'Geben Sie Kundendaten ein, um die Anfrage zu senden',
                    },
                    remind: {
                        title: 'Kundendaten',
                        subtitle: 'Geben Sie Kundendaten ein, um die Anfrage zu senden',
                    },
                },
                sms: {
                    in_progress: 'SMS gesendet',
                    delivered: 'SMS geliefert',
                },
                appointment_discount: 'Rabatt für später Termin',
                user_appointment: 'Termin',
                total_accepted_with_vat: 'Total Akzeptiert inkl MwSt',
                read_more: 'Mehr',
                read_less: 'Weniger',
                car_number: {
                    ch: 'TSN oder VIN',
                    de: 'KBA-Nummer oder VIN',
                },
                checklist_link: 'Mastercheck',
                checklist: {
                    title: 'Mastercheck',
                    ok: 'ok',
                    not: 'nicht',
                    print_pdf: 'PDF drucken',
                },
                archive: {
                    label: 'Archivieren'
                },
                is_test: 'Anfrage als Test markiert',
                mark_as_test: 'Als Test markieren',
                unmark_as_test: 'Als Test abzeichnen',
            },
            analytics: {
                title: 'Statistik',
                mechanics_sub: 'Mechaniker',
                statues: {
                    open: 'Anstehend',
                    accepted: 'Akzeptiert',
                    rejected: 'Abgelehnt',
                    created: 'An den Kunden gesendet',
                    all_passed: 'Völlig in Ordnung',
                },
                start_date: 'Von',
                end_date: 'Bis',
                days_per_column: 'Tage pro Spalte: {{days}}',
                pick_date: 'Wähle ein Datum',
                filter_button: 'Anzeigen',
                total_requested: 'Total',
                requested: 'Gesendet',
                accepted: 'Akzeptiert',
                rejected: 'Abgelehnt',
                sum_prices: 'Akzeptiert gegen Abgelehnt in den letzten {{months_num}} Monaten',
                sum_prices_for_period: 'Genehmigte und abgelehnte Arbeiten für den Zeitraum {{date_from}} bis {{date_to}}',
                sum_packages_by: 'Wert der Arbeiten pro Status in den letzten {{months_num}} Monaten',
                sum_packages_for_period: 'Wert der Arbeiten pro Status für den Zeitraum {{date_from}} bis {{date_to}}',
                top_ranges: 'Top Automodelle',
                top_labours: 'Top Arbeiten',
                titles: {
                    packages: 'Pakete',
                    statistics: 'Statistik',
                    most_requested: 'Am meisten gefragt',
                    checklists: 'Mastercheck',
                },
                filters: {
                    users: 'Statistik',
                    all: 'Garage total',
                    mine: 'Mich',
                    last_months: '{{months}} Monate',
                },
                garage_reinitialize: {
                    label: 'Garage wechseln',
                    concept_total: 'TOTAL',
                },
                more_statistics: 'Mehr Statistik',
            },
            login: {
                title: 'Anmelden',
                submit: 'Anmelden',
                forget_password: 'Passwort vergessen?',
            },
            password_reset: {
                title: 'Passwort zurücksetzen',
                tip: 'Geben Sie Ihre Handynummer oder E-Mail-Adresse ein, um das Passwort zurückzusetzen.',
                submit: 'Passwort zurücksetzen',
                cancel: 'Abbrechen',
            },
            password_reset_new: {
                title: 'Neue Passwortaktivierung',
                tip: 'Geben Sie Ihr neues Passwort in die folgenden Felder ein.',
                submit: 'Passwort bestätigen',
            },
            pick_garage: {
                title: 'Garage wählen',
                tip: 'Bitte wählen Sie eine Werkstatt, als die Sie sich anmelden möchten',
            },
            price_update: {
                title: 'Preiseinstellungen',
                section_tabs: {
                    tab_hourly_rate: 'Stundenansatz',
                    tab_oil: 'Öl',
                    tab_liquids: 'Flüssigkeiten',
                    tab_discount: 'Rabatt ',
                    tab_additional_percent_on_works: 'Kleinteilezuschlag',
                    tab_tires: 'Reifen / Räder',
                    tab_cleaning: 'Reinigung',
                    tab_clima: 'Klimaanlage',
                    tab_other: 'Andere',
                    tab_tip_hourly_price: 'Für alle Ihre Marken',
                    tab_tip_oil: '5w40, 10w30, ...',
                    tab_tip_liquids: 'Brems-/Kühlflüssigkeit',
                    tab_tip_discount: 'Rabatt auf Teile',
                    tab_tip_additional_percent_on_works: 'Kleinteilezuschlag auf Arbeit',
                    tab_tire_change: 'Reifenwechsel ',
                    tab_tip_tire_change: 'Reifenwechsel '
                },
                section_form: {
                    label_hourly_rate: 'Stundenansatz',
                    label_option_all_makes: 'Alle Marken',
                    button_add_price: 'Stundenansatz hinzufügen',
                    button_add_discount: 'Neuen Rabatt hinzufügen',
                    button_save: 'Speichern',
                    button_uploading: 'Laden...',
                    service_not_offered: 'Bieten Sie diesen Service nicht an',
                    hourly_price_remove: 'Entfernen',
                    label_car_make: 'Marke',
                    label_production_year: 'Fahrzeugalter',
                    label_younger_price: 'Jünger als ausgewählt',
                    label_younger_discount: 'Jünger als ausgewählt',
                    placeholder_younger_price: 'Preis',
                    change: 'Bearbeiten',
                    remove: 'Entfernen',
                    add_price: 'Preis hinzufügen',
                    label_discount: 'Rabatt',
                    label_show_appointments_discount: 'Option für zukünftige Terminen zulassen',
                    label_additional_percent_on_works: 'Kleinteilezuschlag',
                },

                hourly_price: {
                    title: 'Stundenansatz',
                    empty_list: 'Preisliste ist leer.',
                },
                oil_price: {
                    title: 'Ölpreis',
                },
                liquids_price: {
                    title: 'Flüssigkeitspreis',
                },
                discount: {
                    title: 'Rabatt auf Teile',
                    appointments_title: 'Rabatt auf zukünftige Termine',
                    parts_title: 'Rabatt auf Teile',
                    brakes_title: 'Rabatt auf Bremsen',
                    empty_list: 'Keine Rabatte gespeichert',
                },
                price_update_additional_percent_on_works: {
                    title: 'Kleinteilezuschlag',
                },

                years: {
                    all_years: 'Alle Produktionsjahre',
                    '2_years_younger': '2 Jahre und jünger',
                    '2_years_older': 'Älter als 2 Jahre',
                    '3_years_younger': '3 Jahre und jünger',
                    '3_years_older': 'Älter als 3 Jahre',
                    '4_years_younger': '4 Jahre und jünger',
                    '4_years_older': 'Älter als 4 Jahre',
                    '5_years_younger': '5 Jahre und jünger',
                    '5_years_older': 'Älter als 5 Jahre',
                    '6_years_younger': '6 Jahre und jünger',
                    '6_years_older': 'Älter als 6 Jahre',
                },
            },
            settings: {
                title: 'Einstellungen',
                section_tabs: {
                    change_details: 'Details ändern',
                    phones: 'Kontaktnummer (Nummer wird dem Kunden angezeigt.)',
                    time_due: 'Antwortezeit bis',
                    communication: 'Benachrichtigungen',
                    customer_signature: 'Unterschrift dem Kunden angezeigt',
                    mastercheck_layout: 'Wählen Sie das Mastercheck-Layout'
                },
                section_form: {
                    label: {
                        address: 'Adresse:',
                        web: 'Webseite:',
                        zip_id: 'PLZ:',
                        logo: 'Logo:',
                        notification_phone: 'Handynummer:',
                        notification_email: 'E-Mail:',
                        time_due: 'Antwortezeit bis:',
                        customer_signature: 'Unterschrift dem Kunden angezeigt',
                    },
                    placeholder: {
                        address: 'Adresse:',
                        web: 'Webseite:',
                        zip_id: 'PLZ:',
                        logo: 'Logo:',
                        email: 'E-Mail eingeben',
                        phone: 'Handynummer eingeben',
                        time_due: 'Antwortezeit bis',
                        customer_signature: 'Unterschrift dem Kunden angezeigt',
                    },
                    logo_change: 'Ändern',
                    logo_upload: 'Logo hochladen',
                    avatar_upload: 'Avatar hochladen',
                    avatar_change: 'Avatar wechseln',
                    submit_user_details: 'Speichern',
                    notification_detail:
                        'Listen Sie alle zugeordneten Empfänger auf, um Benachrichtigungen zu erhalten.',
                    phones_detail: 'Nummer wird dem Kunden angezeigt.',
                    add_new_phone: 'Neue Nummer hinzufügen',

                    file_size: 'Datei zu groß',
                    file_format: 'Nicht unterstütztes Format',

                    disable_time_due: 'Benutze das nicht',

                    communication: {
                        vonage_multiple: 'SMS',
                        mail: 'E-Mail',
                        push_notification: 'Push',
                    },
                    communication_required: 'Es ist mindestens ein Benachrichtigungskanal erforderlich',
                },
                section_popup: {
                    header_remove: 'Are you sure you want to remove recipient?',
                    header_add: 'Add new recipient',
                    header_update: 'Update recipient',
                    remove: 'Remove',
                },
            },
            user_profile: {
                title: 'Benutzerprofil',
                section_tabs: {
                    default_language: 'Wählen Sie die Standardsprache',
                    change_password: 'Passwort ändern',
                    change_details: 'Details ändern',
                },
                section_form: {
                    label: {
                        current_password: 'Altes Passwort',
                        new_password: 'Neues Passwort',
                        repeat_password: 'Neues Passwort bestätigen',
                        notification_phone: 'Handynummer:',
                        notification_email: 'E-Mail:',
                        first_name: 'Vorname',
                        last_name: 'Name',
                        email: 'E-Mail',
                        phone: 'Handynummer',
                        username: 'Benutzername',
                    },
                    placeholder: {
                        current_password: 'Altes Passwort eingeben',
                        new_password: 'Neues Passwort eingeben',
                        repeat_password: 'Neues Passwort bestätigen',
                        notification_email: 'Neue E-Mail eingeben',
                        notification_phone: 'Neues Handynummer eingeben',
                        first_name: 'Vorname eingeben',
                        last_name: 'Name eingeben',
                        email: 'E-Mail eingeben',
                        phone: 'Handynummer eingeben',
                        username: 'Benutzername',
                    },
                    avatar: {
                        change: 'bearbeiten',
                        crop: {
                            select: 'Foto hochladen',
                            zoom: 'Zoomieren',
                            rotate: 'Rotieren',
                            save: 'Speichern',
                            cancel: 'Abbrechen',
                            title: 'Bild zuschneiden',
                        },
                    },
                    submit_new_password: 'Passwort jetzt ändern',
                    submit_user_details: 'Speichern',

                    file_size: 'Datei zu groß',
                    file_format: 'Nicht unterstütztes Format',
                },
            },
            add_cost: {
                title: 'Fahrzeug auswählen:',
                select_method: 'Please, select one of the following methods for finding a car.',
                method_licence_plate: 'Licence plate',
                method_licence_plate_tip: 'You will be asked to enter canton & plate number only',
                method_swiss: 'SWISS',
                method_swiss_tip: 'Find your car by entering SWISS code',
                method_manual: 'Manual',
                method_manual_tip: 'Find your car by entering details manually',

                method_title_lp: 'Find car by licence plate',
                method_title_swiss: 'Find car by SWISS',
                method_title_multiple: 'Choose your cars color',
                method_title_manual: 'Enter details manually',

                licence_plate: {
                    title: 'Enter your car information and get instant offers.',
                    manual: 'Wissen Sie das Nummernschild nicht?',
                    sourcing: 'Beschaffung von Fahrzeuginformationen',
                    sourced_success: 'Wir haben erfolgreich Fahrzeuginformationen beschafft.',
                },
                car_number: {
                    vin_swiss: 'Wissen Sie das Vin oder TSN nicht?',
                    vin_kba: 'Wissen Sie das Vin oder KBA nicht?',
                    vin: 'Wissen Sie das Vin nicht?',
                    swiss: 'Wissen Sie das TSN nicht?',
                    kba: 'Wissen Sie das KBA nicht?',
                    default: 'Wissen Sie das Vin oder TSN nicht?',
                },
                form_sidebar_label_1: '2 years warranty on work and parts',
                form_sidebar_label_2: 'Comparison of more than 450 garages',
                form_sidebar_label_3: 'Free cancellation',
                licence_plate_tooltip:
                    'Die Fahrzeugidentifikation erfolgt mithilfe folgender Quelle: Bundesamt für Strassen ASTRA.',

                swiss: {
                    manual: 'Geben Sie die Details manuell ein',
                },
                packages_reset: {
                    title: 'Pakete neu starten',
                    description: 'Wird geladen',
                },
                draft: {
                    title: 'Entwürfe:',
                    loading: 'Bitte warten',
                    fetching_list: 'Bitte warten',
                    show_less: 'Weniger',
                    show_more: 'Mehr',
                },
                tab_change: {
                    title:
                        'Wenn Sie diese Seite verlassen, wird die Reihenfolge zum Entwurf verschoben. Sind Sie sicher?',
                    accept: 'Verlassen',
                    cancel: 'Bleib auf der Seite',
                },
                search_user: {
                    section_form: {
                        label: {
                            search: 'Bestehender Kunde',
                        },
                        placeholder: {
                            search: 'Nachname oder Vorname des Kunden',
                        },
                    },
                },
            },
            packages: {
                title: 'Paketliste',
                selected_packages: 'Ausgewählte Arbeiten:',
                show_more: 'Mehr',
                show_less: 'Weniger',
                add_item: '+ Fügen Sie verwandte Werke hinzu',
                extend_properties: 'Dringlichkeit/Daten',
                edit: 'Entfernen',
                add_new_labour: '+ Neue Arbeit',
                add_new_official_service: '+ Neue Service',
                add_tires: '+ Reifenwechsel ',
                next: 'Weiter',
                total: 'TOTAL',
                total_incl_vat: 'Total inkl. MwSt',
                remove: 'Entfernen',
                missing_works_parts: 'Einige Teile oder Preise fehlen möglicherweise.',
                empty_list: 'Keine Arbeiten hinzugefügt.',
                confirm_car_update: {
                    title: 'Sind Sie sicher?',
                    subtitle: 'Ein Fahrzeugwechsel setzt die ausgewählten Werke zurück. Sind Sie sicher?',
                },
                confirm_remove: {
                    title: 'Sind Sie sicher?',
                    subtitle: 'Möchten Sie die Arbeit entfernen?',
                },
                package_name: 'Paket',
                service_package_name: 'Service gemäss {{make}}-Herstellervorgaben',
                checklist: 'Mastercheck',
                or: 'oder',
                max_number: 'Maximal {{max}} Pakete pro Anfrage.',
                edit_official: 'Bearbeiten',
                edit_tires: 'Bearbeiten',
                official_package_mileage: ' - km-Stand {{mileage}}km',
            },
            package_details: {
                title: 'Paketdetails',

                level_of_urgency: 'Dringlichkeit',
                add: 'Hinzufügen',
                edit: 'Bearbeiten',
                change: 'Wechseln',
                add_edit: 'Bearbeiten',
                file_uploads: 'Bild-Uploads',
                no_files: 'Keine Dateien',
                no_description: 'Keine Beschreibung',
                attach_new_file: 'Neues Bild anhängen',
                available_works: 'Verfügbare Arbeit',
                available_parts: 'Verfügbare Teile',
                more: 'Mehr',
                details: 'Details',
                remove: 'Entfernen',
                removing: 'entfernen...',
                missing_work: 'Möglicherweise fehlt die Arbeit',
                missing_parts: 'Klicken Sie auf “Details” um Arbeiten oder Teile zu erfassen',
                add_discount: 'Rabatt hinzufügen',

                total: 'Total',
                discount_on_parts: 'Rabatt auf Teile',
                discount_on_brakes: 'Rabatt auf Bremsen',
                discount_package: 'Rabatt',
                total_all: 'Total inkl. MwSt & Rabatt',
                price_fixed: 'Pauschale',
                official_package_mileage: ' - km-Stand {{mileage}}km',

                urgency: {
                    title: 'Dringlichkeit',
                    subtitle: 'Wählen Sie den Dringlichkeitsgrad für diese Zusatzarbeit.',
                    urgency_safety_relevant: 'Sicherheitsrelevant',
                    urgency_urgent: 'Wichtig',
                    urgency_recommended: 'Empfohlen',
                },

                files: {
                    title: 'Daten hochladen',
                    skip: 'Überspringen',
                    see_file: 'Datei öffnen',
                    see_video: 'Video öffnen',
                    file_size: 'Datei zu groß. Die maximale Größe beträgt {{size}} MB',
                    file_format: 'Nicht unterstütztes Format. Unterstützt: {{format}}',
                    files_max: 'Die maximale Anzahl von Dateien beträgt {{max}}',
                    record: 'Aufnehmen',
                    audio: 'Audiodatei',
                    image: 'Bild',
                    record_audio: {
                        title: 'Sprachnotiz aufnehmen',
                        rec: 'Rec',
                        cancel: 'Abbrechen',
                        pause: 'Pause',
                        stop: 'Stop',
                        save: 'Speichern',
                    },
                    remove: {
                        title: 'Datei entfernen?',
                    },
                },

                description: {
                    title: 'Beschreibung hinzufügen',
                    item: 'Beschreibung',
                    not_added: 'Noch nicht hinzugefügt',
                    subtitle: 'Wählen Sie den Wichtigkeitsgrad für neue Zusatzkosten.',
                },

                discount: {
                    title: 'Rabatt hinzufügen',
                    section_form: {
                        label: {
                            name: 'Name',
                            price: 'Rabatt Preis({{currency}})',
                        },
                        placeholder: {
                            name: 'Name',
                            price: 'Preis',
                        },
                    },
                },

                confirm_edit: {
                    title: 'Sind Sie sicher?',
                    subtitle:
                        'Das Hinzufügen neuer Arbeiten kann einige Änderungen überschreiben, die Sie in früheren Arbeiten vorgenommen haben',
                },

                labour: {
                    works: {
                        title: 'Verfügbare Arbeit',
                        add_more: '+ Fügen Sie weitere Arbeit hinzu',
                        list: {
                            title: 'Arbeitsliste',
                        },
                        section_form: {
                            label: {
                                name: 'Name',
                                description: 'Beschreibung',
                                duration: 'Dauer',
                            },
                            placeholder: {
                                name: 'Arbeitsname eingeben',
                                description: 'Beschreibung',
                                duration: 'Arbeitsdauer eingeben',
                            },
                        },
                        edit: {
                            title: 'Arbeit bearbeiten',
                        },
                        add: {
                            title: 'Arbeit hinzufügen',
                        },
                    },
                    parts: {
                        title: 'Teile',
                        add_more: '+ Fügen Sie weitere Teile hinzu',
                        versions: {
                            title: 'Teile',
                        },
                        set: {
                            title: 'Teilesatz bearbeiten',
                            section_form: {
                                label: {
                                    name: 'Name',
                                    price: 'Preis',
                                    amounts: 'Menge',
                                },
                                placeholder: {
                                    name: 'Geben Sie den Teilesatznamen ein',
                                    price: 'Geben Sie den Teilepreis ein',
                                    amounts: 'Geben Sie die Menge des Teilsets ein',
                                },
                            },
                        },
                        add: {
                            title: 'Neues Teil hinzufügen',
                        },
                    },
                },
            },
            labour_search: {
                title: 'Arbeit suchen und auswählen',
                tip_on_no_result:
                    'Sobald Sie es hinzugefügt haben, wird es in Ihrer Liste gespeichert, aus der Sie in Zukunft auswählen können.',

                form: {
                    label: 'Suchen',
                    placeholder: 'Ersetzen, luft, bremsen...',
                    no_result: 'Keine Einträge gefunden.',
                },
                package: {
                    title: 'Paket',
                    subtitle: 'Dieser Artikel wird zu den gleichen zusätzlichen Kosten hinzugefügt.',
                },
                no_result_tip: 'In der folgenden Liste konnten wir kein Ergebnis finden:',
                new_package: '+ neu',
                add_no_result: 'Auswählen und zur Liste hinzufügen',
                new_labour_data_title: 'Abrufen von Arbeitsdaten',
                new_labour_loading_title: 'Neue Arbeit schaffen',
                new_labour_loading: 'Wird geladen',
                created: 'Arbeit erfolgreich erstellt',

                official_service: {
                    section_form: {
                        label: {
                            km_stand: 'km-Stand',
                        },
                        placeholder: {
                            km_stand: 'km-Stand',
                        },

                        submit: 'Weiter',
                    },
                    not_exist: 'Kein Servicevorschlag gefunden',
                    popup_title: 'Service gemäss {{make}}-Herstellervorgaben',
                    fetching: 'Offizielle Services abrufen',
                },
            },
            request_preview: {
                title: 'Checkout',
                send: 'Anfrage senden',
                book_now: 'Jetzt Angebot bestätigen',
                checkout: 'Checkout',
                car_details: 'Fahrzeugdetails',
                customer_details: 'Kundendetails',
                official_package_mileage: ' - km-Stand {{mileage}}km',
                today: 'Termin für heute vereinbaren',

                section_form: {
                    label: {
                        first_name: 'Vorname',
                        last_name: 'Name',
                        email: 'E-Mail',
                        phone: 'Handynummer',
                        time_due: 'Antwortezeit bis',
                        contract_number: 'Vertrags-ID (optional)',
                        comment: 'Kommentar',
                    },
                    placeholder: {
                        first_name: 'Vorname eingeben',
                        last_name: 'Name eingeben',
                        email: 'E-Mail eingeben',
                        phone: 'Handynummer eingeben',
                        contract_number: 'Vertrags-ID (optional)',
                        comment: 'Kommentar',
                    },
                    oneOfRequired: 'Handynummer oder E-Mail erforderlich',

                    skip_time_due: 'Überspringen',
                },

                appointment_discounts: {
                    show_appointments: 'Termine anzeigen',
                    edit: {
                        title: 'Rabatt bearbeiten',
                        link: 'Rabatt bearbeiten',
                        save: 'Speichern',
                        tip:
                            'Aktualisiert Ihren Rabatt für die zukünftigen Terminen. Die Änderung wirkt sich auf alle ausgewählten Termine aus und wird gespeichert.',
                    },
                    header: {
                        termin: 'Termin',
                        discount: 'Rabatt',
                        total: 'Total',
                    },
                    list: {
                        is_today: 'Heute',
                    },
                },
                user_exists: {
                    email: 'Email existiert. Bestehenden Kunden auswählen?',
                    phone: 'Nummer existiert. Bestehenden Kunden auswählen?',
                },
            },
            confirm: {
                title: 'Anfrage wurde gesendet',
                headline: 'Anfrage erfolgreich gesendet',
                description:
                    'Ihre Anfrage wurde erfolgreich an den Kunden gesendet. Erwarten Sie, per E-Mail / SMS benachrichtigt zu werden, sobald er antwortet.',
                description_mechanic: 'Ihre Anfrage wurde erfolgreich an den Kundendiesntberater gesendet.',
                go_home: 'Zurück zum Dashboard',
                new_request: 'Neuer Auftrag',
                book_now_headline: 'Anfrage erfolgreich gebucht',
                book_now_description: 'Ihre Anfrage wurde erfolgreich gebucht. Sie können jetzt mit der Arbeit beginnen.',
            },
            activities: {
                title: 'Kundenaktivität',
                segment: {
                    today: 'Heute',
                    yesterday: 'Gestern',
                    older: 'älter',
                },
                section_form: {
                    placeholder: {
                        search: 'Suchen',
                    },
                },
                last_activity: 'Letzte Aktivität',
                at: 'um',
            },
            activity: {
                title: 'Aktivität',
                segment: {
                    today: 'Heute',
                    yesterday: 'Gestern',
                },
                section_form: {
                    placeholder: {
                        message: 'Schreiben Sie mit dem Kunden',
                    },
                },
                type: {
                    manager_waiting: {
                        name: 'Mechaniker {{first_name}} {{last_name}}',
                        body: 'schickte neue Arbeiten zur Genehmigung',
                    },
                    manager_created: {
                        name: 'Kundendienstberater {{first_name}} {{last_name}}',
                        body: 'hat eine neue Anfrage erstellt.',
                    },
                    manager_accepted: {
                        name: 'Kundendienstberater {{first_name}} {{last_name}}',
                        body: 'akzeptierte Ihre erstellte Arbeit',
                    },
                    manager_rejected: {
                        name: 'Kundendienstberater {{first_name}} {{last_name}}',
                        body: 'lehnte Ihre erstellte Arbeit ab',
                    },
                    sms_garage_reply: {
                        name: 'Kundendienstberater {{first_name}} {{last_name}}',
                    },
                    sms_user_reply: {
                        name: '{{first_name}} {{last_name}}',
                    },
                    user_responded: {
                        name: 'Kunde {{first_name}} {{last_name}}',
                        accepted: 'akzeptierte {{hasAccepted}} von {{length}} Paketen',
                        rejected: 'lehnte das Paket ab',
                    },
                    details: 'Siehe Einzelheiten',
                },
                new_event: 'Neues event',
            },
            checklist_labours: {
                types: {
                    position: {
                        subtitle: 'Welche Position muss ersetzt werden?',
                        save: 'Speichern',
                    },
                    list: {
                        save: 'Speichern',
                    },
                },
                save: 'Weiter',
                archive: 'Archivieren',
                add_new_labour: '+ Neue Arbeit',
                ok: 'ok',
                not: 'nicht',
                on_leave: 'Sind Sie sicher?',
                note: { //TODO: Translate
                    title: 'Neue Notiz hinzufügen',
                    new: 'Neue Notiz',
                    view: 'Notiz ansehen',
                    label: 'Notiz',
                },
            },
            official_service: {
                title: 'Service gemäss Herstellervorgaben',
                total_official: 'Total inkl MwSt',
                submit: 'Weiter',
                or_more: 'oder mehr',
                no_recommended: 'Zum ausgewählten Fahrzeug konnten wir keinen Servicevorschlag finden.',
                info: {
                    ok: 'OK',
                },
                service_interval_title: 'Service at ',
                show_more: 'mehr',
                show_less: 'weniger',
            },
            analytics_enhanced: {
                title: 'Statistik',

                export: 'Nach Excel exportieren',
                packages: 'Pakete',
                checklist: 'Mastercheck',
                top_cars: 'Fahrzeugstatistik',
                top_labours: 'Arbeitsstatistik',
                top_garage: 'Garagenstatistitk',

                packages_info: 'Akzeptiert / abgelehnt',
                checklist_info: 'Masterchecks verarbeitet',
                top_cars_info: 'Top Automodelle',
                top_labours_info: 'Top Arbeiten',
                top_garage_info: 'Top Garagen',

                date_picker: {
                    from: 'Von',
                    to: 'Bis',
                },
                lists: {
                    top_10_by_revenue: 'TOP 10 Einnahmen',
                    worst_10_by_revenue: 'Flop 10 Einnahmen',
                    top_10_by_checklists: 'TOP 10 Masterchecks durchgeführt ',
                },
                column_names: {
                    requests: 'Anfrage',
                    revenue: 'Einnahmen',
                    total: 'Vorgeschlagene Angebote',
                    checklists: 'Mastercheck',
                    accepted: 'Umsatz',
                    percentage: 'Quote',
                },
            },
            tires_import: {
                title: 'Reifenimport',
                importing_in_progress: 'Import läuft...',
                label: 'Wählen Sie eine EXCEL-Datei',
                btn: 'Importieren',
                import_started: 'Reifen werden importiert. Sie werden benachrichtigt, wenn dies erledigt ist.',
                import_success: 'Reifen wurden erfolgreich importiert.',
                import_failed: 'Beim Importieren der Reifen ist ein Fehler aufgetreten.',
            },
        },
        notification: {
            empty_list: 'keine Benachrichtigungen',
            manager_waiting: 'schickte neue Arbeiten zur Genehmigung',
            manager_accepted: 'akzeptierte Ihre erstellte Arbeit',
            manager_rejected: 'lehnte Ihre erstellte Arbeit ab',
            user_responded: {
                accepted: 'akzeptierte {{accepted}} von {{packagesLength}} Paketen',
                rejected: 'lehnte das Paket ab',
            },
            sms_reply: 'Neue Nachricht vom Kunden {{first_name}} {{last_name}}',
            details: 'Einzelheiten',
            segment: {
                today: 'Heute',
                yesterday: 'Gestern',
                this_week: 'diese Woche',
            },
            make_all_as_read: 'Markiere alle als gelesen',
        },
        profile_progress: {
            title: {
                garage: 'Garage',
                profile: 'Profil',
                prices: 'Preise',
            },
            item: {
                garage: {
                    logo: 'Logo',
                    address: 'Addresse',
                    web: 'Webseite',
                    zip: 'PLZ',
                    contact_phones: 'Kontakttelefone',
                },
                profile: {
                    first_name: 'Vorname',
                    last_name: 'Name',
                    email: 'E-Mail',
                    username: 'Benutzername',
                    phone: 'Telefonnummer',
                    avatar: 'Avatar',
                },
                prices: {
                    hourly: 'Stundensatz',
                    oil: 'Öle',
                    liquids: 'Flüssigkeiten',
                },
            },
            info: 'Profileinrichtung {{percent}}%. Klicke hier, um anzusehen.',
            text_loading: 'Profileinrichtung laden...',
        },
        tires: {
            brand_type: {
                [TIRES_TYPE_BRAND_PREMIUM]: 'Premium',
                [TIRES_TYPE_BRAND_PRICE_QUALITY]: 'Preis/Qualität',
            },
            step_title: {
                [TIRES_STEP_LIST_PREMIUM]: 'Premium',
                [TIRES_STEP_LIST_PRICE_QUALITY]: 'Preis/Qualität',
            },
            axel: {
                [TIRES_AXEL_FRONT]: 'Vorne',
                [TIRES_AXEL_REAR]: 'Hinten',
                both: 'V + H',
            },
            season: {
                [TIRES_SEASON_SUMMER]: 'Sommer',
                [TIRES_SEASON_WINTER]: 'Winter',
                [TIRES_SEASON_ALL_SEASON]: 'All Season',
                [TIRES_SEASON_NORDIC]: 'Nordic',
            },

            filter_title_both: 'Reifen vorne + hinten',
            filter_title_front: 'Vorderreifen',
            filter_title_rear: 'Hinterreifen',

            filter_index_all: 'Alle Index',

            package_name: 'Reifenwechsel inkl. Auswuchten ',

            ean: 'EAN',
            sap: 'SAP',

            label: {
                wide: 'Breit',
                depth: 'Tiefe',
                diameter: 'Durchmesser',

                season: 'Saison',
                index: 'Index',
                brand: 'Marke',

                front_axel: 'Vorderachse',
                rear_axel: 'Hinterachse',

                same_dimension: 'Gleiche Abmessungen',
            },

            header: {
                brand: 'Marke',
                index: 'Index',
                price: 'Preis',
            },

            no_tires: 'Keine Reifen für dieses Kriterium',
        },
        toast: {
            success: 'Danke',
            error: 'Oops!',
            info: 'Info',
            warning: 'Warnung',
            app_version: 'Die neue Version von Garage Booster ist verfügbar.',
        },
        toast_notifications: {
            prices_updated: "Der Rabatt wurde gespeichert. Wenn noch nicht fertiggestellte Pakete mit dem Rabatt im Entwurf sind, wird ihr Preis jetzt aktualisiert."
        },
        toast_system_messages: {
            maintenance: 'Maintenance',
            incident: 'Incident',
            warning: 'Warnung',
            info: 'Info',
        },
        language: {
            en: 'Englisch',
            de: 'Deutsch',
            fr: 'Französisch',
        },
        error_recovery: {
            title: 'Unser System hat einen Fehler festgestellt.',
            btn: 'Klicken Sie hier, um die Seite neu zu laden',
        }
    },
}
