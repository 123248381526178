import React from 'react'
import './index.scss'
import Badge from '../Badge/Badge'
import { translate } from 'react-i18next'
import {
    URGENCY_SAFETY_RELEVANT,
    URGENCY_URGENT,
    URGENCY_RECOMMENDED,
    URGENCIES,
} from '../../../store/consts/package/package.urgency.constants'

const PackageUrgency = ({ urgency, t, inactive }) => {
    if (!urgency) {
        return null
    }

    if (urgency === URGENCY_SAFETY_RELEVANT) {
        return (
            <Badge type="danger" extraClass={`${inactive ? 'badge-lighter' : ''}`}>
                {URGENCIES[URGENCY_SAFETY_RELEVANT]}
            </Badge>
        )
    }

    if (urgency === URGENCY_URGENT) {
        return (
            <Badge type="warning" extraClass={`${inactive ? 'badge-lighter' : ''}`}>
                {URGENCIES[URGENCY_URGENT]}
            </Badge>
        )
    }

    if (urgency === URGENCY_RECOMMENDED) {
        return (
            <Badge type="warning-light" extraClass={`${inactive ? 'badge-lighter' : ''}`}>
                {URGENCIES[URGENCY_RECOMMENDED]}
            </Badge>
        )
    }

    return (
        <Badge type="primary" extraClass={`${inactive ? 'badge-lighter' : ''}`}>
            {URGENCIES[URGENCY_SAFETY_RELEVANT]}
        </Badge>
    )
}

export default translate('translations')(PackageUrgency)
