export default [
    {
        id: 1,
        locale: "en",
        name: 'language.en',
    },
    {
        id: 2,
        locale: "de",
        name: 'language.de',
    },
    {
        id: 3,
        locale: "fr",
        name: 'language.fr',
    },
]
