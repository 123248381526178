import {API_ERROR, API_SUCCESS, apiRequest} from 'store/actions/api.actions'
import {MATERCHECK_LAYOUT_SETTING_UPDATE} from 'store/actions/mastercheckLayout.settings.actions'

export const MATERCHECK_LAYOUT_SETTING_UPDATE_URL = 'garage/settings/update-default-mastercheck-layout'

export const mastercheckLayoutSettingMiddleware = ({dispatch}) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case MATERCHECK_LAYOUT_SETTING_UPDATE:
                dispatch(
                    apiRequest({
                        body: {layout: action.payload.layoutId, garage_id: action.payload.garageId},
                        method: 'POST',
                        url: MATERCHECK_LAYOUT_SETTING_UPDATE_URL,
                        signature: MATERCHECK_LAYOUT_SETTING_UPDATE,
                    }),
                )
                break

            case `${MATERCHECK_LAYOUT_SETTING_UPDATE} ${API_SUCCESS}`:
                break

            case `${MATERCHECK_LAYOUT_SETTING_UPDATE} ${API_ERROR}`:
                break

            default:
                break
        }
    }
}
