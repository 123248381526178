import Yup from './yup';

export default {

    enableReinitialize: true,

    mapPropsToValues({labour}) {
        return {
            note: labour.note ?? '',
        };
    },

    validationSchema: props => {
        const {t} = props;

        return Yup.object().shape({
            note: Yup.string()
                .label(t('pages.checklist_labours.note.label')),
        });
    },
};
