import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import {Link} from "react-router-dom";
import pages from "app/consts/routes";
import './PriceUpdateContainer.scss'

class PriceUpdateContainer extends Component {
    render() {
        const { t, garage } = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} />

                <div className="container">
                    <div className="services-tabs price-update">
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <Link id="hourly-prices-link" to={pages.price_update_hourly_prices} className="service-tab">
                                    <div className="service-tab__title">
                                        {t('pages.price_update.section_tabs.tab_hourly_rate')}
                                    </div>
                                    <div className="service-tab__tip">
                                        {t('pages.price_update.section_tabs.tab_tip_hourly_price')}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <Link id="oil-prices-link" to={pages.price_update_oil_prices} className="service-tab">
                                    <div className="service-tab__title">
                                        {t('pages.price_update.section_tabs.tab_oil')}
                                    </div>
                                    <div className="service-tab__tip">
                                        {t('pages.price_update.section_tabs.tab_tip_oil')}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <Link id="liquid-prices-link" to={pages.price_update_liquids_prices} className="service-tab">
                                    <div className="service-tab__title">
                                        {t('pages.price_update.section_tabs.tab_liquids')}
                                    </div>
                                    <div className="service-tab__tip">
                                        {t('pages.price_update.section_tabs.tab_tip_liquids')}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <Link id="discount-prices-link" to={pages.price_update_discount} className="service-tab">
                                    <div className="service-tab__title">
                                        {t('pages.price_update.section_tabs.tab_discount')}
                                    </div>
                                    <div className="service-tab__tip">
                                        {t('pages.price_update.section_tabs.tab_tip_discount')}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <Link id="additional-percent-prices-link" to={pages.price_update_additional_percent_on_works} className="service-tab">
                                    <div className="service-tab__title">
                                        {t('pages.price_update.section_tabs.tab_additional_percent_on_works')}
                                    </div>
                                    <div className="service-tab__tip">
                                        {t('pages.price_update.section_tabs.tab_tip_additional_percent_on_works')}
                                    </div>
                                </Link>
                            </div>

                            {garage.is_euromaster &&
                            <div className="col-sm-6 col-xs-12">
                                <Link id="additional-percent-prices-link" to={pages.price_update_tire_work_prices}
                                      className="service-tab">
                                    <div className="service-tab__title">
                                        {t('pages.price_update.section_tabs.tab_tire_change')}
                                    </div>
                                    <div className="service-tab__tip">
                                        {t('pages.price_update.section_tabs.tab_tip_tire_change')}
                                    </div>
                                </Link>
                            </div>
                            }

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(PriceUpdateContainer));
