import React, {Component} from 'react';
import {translate} from "react-i18next";
import {__clone, addTax, stringToBool, toBool} from "../../../utils/common";
import Popup from "../../shared/Popup/Popup";
import InputCheckbox from "../../shared/InputCheckbox";
import Button from "../../shared/Button/Button";
import {CHECKLIST_OK, CHECKLIST_NOT_OK} from "../ChecklistLabour";
import BaseCountry from "../../../utils/BaseCountry";
import ChecklistLayout from "../components/ChecklistLayout";

class PositionCategoriesType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPositions: false,
        };
    }

    handleChange = (passed) => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        if (passed === CHECKLIST_NOT_OK) {
            this.setState({
                showPositions: true,
            })
        } else if (passed === CHECKLIST_OK) {

            this.props.labour.siblings.forEach(sibling => {
                labourFieldUpdate({
                    updating: true, passed: passed
                }, this.props.labour.siblings.find(s => s.id === sibling.id)).then(() => {
                    onUpdatePassed(this.props.labour.siblings.find(s => s.id === sibling.id));
                })
            })

            labourFieldUpdate({updating: true, passed: passed}).then(() => {
                onUpdatePassed();
            })
        }
    }

    onCloseShowPositions = () => {
        const {labourFieldUpdate} = this.props;

        labourFieldUpdate({updating: false})

        this.setState({
            showPositions: false,
        })
    }

    submit = (labours) => {
        const {labour, packageFlow, labourFieldUpdate} = this.props;

        this.setState({
            showPositions: false,
        });

        labour.siblings.forEach(sibling => labourFieldUpdate({updating: true, passed: CHECKLIST_NOT_OK}, sibling))

        labourFieldUpdate({updating: true, passed: CHECKLIST_NOT_OK}).then(() => {
            packageFlow(labours)
        })
    }

    render() {
        const {t, labour, showNotePopup} = this.props;
        const {showPositions} = this.state;

        let noteDisabled = labour.passed === null || labour.updating;

        return (<React.Fragment>
            <div className="checklist-labour">
                <div className="col-xs-8">
                    <div className="checklist-labour__name">
                        {labour.name}
                    </div>
                </div>
                <ChecklistLayout name={"checklist-labour"} handleChange={this.handleChange} labour={labour}/>
                <div className="col-xs-2">
                    <div className="checklist-labour__btn">
                            <span className="link-style" disabled={noteDisabled}
                                  onClick={!noteDisabled ? showNotePopup : null}>
                                {labour.note ? t('pages.checklist_labours.note.view') : t('pages.checklist_labours.note.new')}
                            </span>
                    </div>
                </div>
            </div>

            <Popup
                title={labour.name}
                subtitle={t('pages.checklist_labours.types.position.subtitle')}
                visible={showPositions}
                onClose={this.onCloseShowPositions}
            >
                <Positions {...this.props}
                           onSubmit={this.submit}
                           labours={labour.labours}
                />
            </Popup>
        </React.Fragment>)
    }
}

class Positions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labours: props.labours,
        };
    }

    checkIsCheckbox = (position) => {
        return (position.is_single_select === false && position.is_parent_single_select && position.parent_id !== null) || (position.is_single_select === false && position.parent_id !== null)
    }

    getStyleForCheckboxChild = (position) => {
        return position.parent_id === null ? {margin: '10px 0 0 30px'} : null;
    }

    renderPosition = (position) => {
        const {labours} = this.state;

        return (<div className="checklist-labour-position" key={position.id}>
            {this.checkIsCheckbox(position) ? this.checkbox(position) : <div>
                {position.parent_id === null && <span>{position.position_name}</span>}

                {position.car_labour !== null && position.is_parent_single_select === false && (
                    <div style={this.getStyleForCheckboxChild(position)}>
                        {this.checkbox(position)}
                    </div>)}
            </div>}

            {labours.filter(l => l.parent_id === position.id).length > 0 && labours.filter(l => l.parent_id === position.id).map(this.renderPosition)}
        </div>)
    }

    checkbox = (position) => {
        const {garage} = this.props;
        const {labours} = this.state;

        return <InputCheckbox
            id={`checklist-labour-position-${position.id}`}
            name={`checklist-labour-position-${position.id}`}
            value={position.id}
            onChange={(e) => {
                this.updateCheckPositions(labours.filter(l => l.id === parseInt(e.target.value)).map(l => l.id), e.target.checked)
            }}
            checked={position.checked}
            label={<>
                <span>{position.position_name}</span>

                {(toBool(position.car_labour) && toBool(position.car_labour.priceFixed)) && <>
                    <span className="vertical-text-delimiter">|</span>
                    <span
                        className="bold">{addTax(position.car_labour.priceFixed, garage.tax, true)} {BaseCountry().currency}</span>
                </>}

            </>}
        />;
    }

    updateCheckPositions = (positionIds, checked) => {
        const {labours} = this.state;

        if (positionIds.length === 0) {
            return;
        }

        let filteredLabours = __clone(labours).map(l => {
            if (positionIds.indexOf(l.id) !== -1) {
                return {...l, checked: checked};
            }

            return {...l, checked: false};
        });

        this.setState({
            labours: filteredLabours
        }, () => {
            this.updateCheckPositions(labours.filter(l => positionIds.indexOf(l.parent_id) !== -1).map(l => l.id), checked)
        })
    }

    getCheckedPositions = () => this.state.labours.filter(l => l.checked === true && l.car_labour !== null).map(l => l.car_labour)

    render() {
        const {labours} = this.state;
        const {t, onSubmit} = this.props;

        return (<div className="checklist-labour-positions">
            {labours.filter(l => l.parent_id === null).map(this.renderPosition)}

            <Button size="lg" type="primary" extraClass={"mt-30"}
                    onClick={() => onSubmit(this.getCheckedPositions())}
                    disabled={!this.getCheckedPositions().length}>{t('pages.checklist_labours.types.position.save')}</Button>
        </div>)
    }
}

export default translate('translations')(PositionCategoriesType);

