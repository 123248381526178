import React from 'react';
import Button from "../shared/Button/Button";
import './ErrorRecovery.scss';
import {translate} from "react-i18next";

const ErrorRecovery = ({t, onResetError, resetError}) => <React.Fragment>
    <div className="container">

        <div className="error-recovery-container">
            <div className="error-recovery-message">{t('error_recovery.title')}</div>

            <Button size="lg" type="primary" onClick={() => onResetError(resetError)}>{t('error_recovery.btn')}</Button>
        </div>

    </div>
</React.Fragment>

export default translate('translations')(ErrorRecovery);
