import React, { Component } from 'react';
import { connect } from "react-redux";
import { translate } from "react-i18next";
import axios from "../../app/config/axios";
import history from "../../routes/history";
import pages from "../../app/consts/routes";
import Header from "../../components/layout/Header/Header";
import "./style.css"
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import DatePicker from "react-datepicker";
import language from "../../utils/language";
import GarageFilter from "./GarageFilter";
import Button from "../../components/shared/Button/Button";
import {captureException} from "../../utils/captureException";

const locale = language();
const GENERATE_REPORT_URL = 'garage/generate-report'

class Reporting extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: this.props.user,
            garage: this.props.garage,
            loader: this.props.loader,
            generating: false,
            startDate: null,
            endDate: null,
            filter: {}
        }
    }

    componentDidMount() {
        const { t, garage } = this.props

        if (!garage.allow_reporting) {
            this.shouldRender = false
            history.replace(pages.home)
        }

        document.title = t('titles.generate_report')
    }

    generateReport = (garage) => {

        this.setState({
            generating: true,
        })

        axios
            .post(GENERATE_REPORT_URL, {
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                filter: this.state.filter,
            })
            .then(response => {
                window.open(response.data.download_report_url);
            })
            .catch(error => {
                captureException(error, GENERATE_REPORT_URL);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => {
                this.setState({generating: false})
            })
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }

    updateFilter = (filter) => {
        this.setState({
            filter: {
                [filter.type]: filter,
            }
        })
    }

    render() {
        const {garage, user, startDate, endDate, generating} = this.state
        const {t, title} = this.props

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container generate-report">
                    <div className="row">
                        <div className="col-xs-12">
                            <GarageFilter
                                user={user}
                                garage={garage}
                                filter={(filter) => {
                                    this.updateFilter(filter)
                                }}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>{t('pages.generate_report.start_date')}</label>
                                <DatePicker
                                    placeholderText={t('pages.generate_report.pick_date')}
                                    selected={startDate}
                                    locale={locale}
                                    className="time-picker-custom"
                                    dateFormat="dd.MM.yyyy"
                                    disabledKeyboardNavigation
                                    onChange={date => this.setStartDate(date)}
                                />
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>{t('pages.generate_report.end_date')}</label>
                                <DatePicker
                                    placeholderText={t('pages.generate_report.pick_date')}
                                    selected={endDate}
                                    locale={locale}
                                    className="time-picker-custom"
                                    dateFormat="dd.MM.yyyy"
                                    disabledKeyboardNavigation
                                    onChange={date => this.setEndDate(date)}
                                />
                            </div>
                        </div>
                    </div>

                    <Button size="lg" type="primary" onClick={() => this.generateReport(garage)} extraClass={"mt-30"}
                            disabled={generating} loading={generating}>{t('pages.generate_report.generate')}</Button>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: state.garage,
        loader: state.loader,
    }
}

export default connect(mapStateToProps)(translate('translations')(Reporting))
