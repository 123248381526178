import Yup from './yup';

export default {

    enableReinitialize: true,

    mapPropsToValues(props) {
        return {
            description: props.description === null ? '' : props.description,
        };
    },

    validationSchema: props => {
        const {t} = props;

        return Yup.object().shape({

            description: Yup.string()
                .required()
                .label(t('pages.package_details.description.item')),
        });
    },
};
