export const API = '[API]';

export const API_REQUEST = `${API} REQUEST`;
export const API_SUCCESS = `${API} SUCCESS`;
export const API_ERROR = `${API} ERROR`;

export const apiRequest = ({ body, method, url, signature }) => ({
    type: `${signature} ${API_REQUEST}`,
    payload: body,
    meta: { method, url, signature }
});

export const apiSuccess = ({ response, signature }) => ({
    type: `${signature} ${API_SUCCESS}`,
    payload: response,
    meta: { signature }
});

export const apiError = ({ error, status, signature }) => ({
    type: `${signature} ${API_ERROR}`,
    payload: {
        error,
        status
    },
    meta: { signature }
});
