import layouts from '../models/mastercheckLayouts';
import {MATERCHECK_LAYOUT_SETTING_SET} from "store/actions/mastercheckLayout.settings.actions";

export default function(state = layouts, action) {
    switch (action.type) {

        case MATERCHECK_LAYOUT_SETTING_SET:
            return action.payload.layouts;

        default:
            return state;
    }
}
