import {
    SELECT_DROPDOWN_OPENED,
} from 'store/actions/global.actions';
import global from 'store/models/global';

export default function(state = global, action) {
    switch (action.type) {

        case SELECT_DROPDOWN_OPENED:
            return {
                ...state,
                selectDropdownOpened: action.payload.isOpened
            };

        default:
            return state;
    }
}
