import React, {Component} from 'react';
import './LicencePlate.scss';
import Yup from "app/config/yup/yup";
import {withFormik} from "formik";
import Input from "components/shared/Input/Input";
import {translate} from 'react-i18next';
import classNames from 'classnames';
import countryFlag from '../../../assets/cantons/SWISS.png';
import emptyCanton from '../../../assets/cantons/palceholder-25.png';

class LicencePlate extends Component {

    constructor(props) {
        super(props);
        this.cantonRef = React.createRef();
        this.numberRef = React.createRef();
        this.BACKSPACE_CHAR_CODE = 8;
        this.REGEX_LETTERS_ONLY = /[^a-z]+/gi;
        this.REGEX_NUMBERS_ONLY = /\D/g;

        this.state = {
            numberBlur: false,
            isPasted: false,
        };

        document.addEventListener('keyup', this.handleKeyPress);
    }

    componentDidMount() {
        const {value} = this.props;

        if (value) {
            this.formatValue(value);
        }

        this.inputFilter(this.cantonRef.inputRef, value => /^[A-Za-z]*$/.test(value));
        this.inputFilter(this.numberRef.inputRef, value => /^\d*$/.test(value));
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyPress);
    }

    inputFilter = (textbox, inputFilter) => {
        ['input', 'keydown', 'keyup'].forEach(function(event) {
            textbox.addEventListener(event, function() {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty('oldValue')) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
            });
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const {onChange, values: {canton, number}, setFieldTouched, onReset} = this.props;

        if ((canton !== nextProps.values.canton) || (number !== nextProps.values.number)) {
            onReset();
        }

        if (number !== nextProps.values.number) {
            this.setState({
                numberBlur: false
            })
        }

        if ((number !== nextProps.values.number) && (nextProps.values.number.toString().length === 0)) {
            this.setState({
                numberBlur: false
            })
        }

        if ((number !== nextProps.values.number) && (nextProps.values.number.toString().length !== 0)) {
            setTimeout(() => {
                this.setState({numberBlur: true}, setFieldTouched('number', true));
            }, 1000)
        }

        if ((canton !== nextProps.values.canton) && (nextProps.values.canton.toString().length === 2)) {
            setTimeout(() => {
                if (this.numberRef && this.numberRef.inputRef) {
                    this.numberRef.inputRef.focus();
                }
            }, 0);
        }

        if (
            ((canton !== nextProps.values.canton) && (nextProps.values.canton.toString().length === 2)) || ((number !== nextProps.values.number) && (nextProps.values.number.toString().length > 0))
        ) {
            onChange(nextProps.values.canton, nextProps.values.number);
        }
    }

    handleKeyPress = (e) => {
        if(e.target === this.cantonRef.inputRef || e.target === this.numberRef.inputRef){
            this.handleBackToNumberFocus(e);
            this.handleBackspaceFocus(e);
        }
    };

    handleBackToNumberFocus = (e) => {
        const NUMBER_CHARS = '0123456789';
        const {setFieldValue, values} = this.props;
        const {isPasted} = this.state;

        if (
            e.keyCode !== this.BACKSPACE_CHAR_CODE &&
            values.canton.length === 2 &&
            values.number.length === 0 &&
            !isPasted
        ) {
            setTimeout(() => {
                if (NUMBER_CHARS.includes(e.key) && this.numberRef && this.numberRef.inputRef) {
                    this.numberRef.inputRef.focus();
                    setFieldValue('number', e.key);
                }
            }, 0);
        }
    };

    handleBackspaceFocus = (e) => {
        const {values: {number}} = this.props;

        if (e.keyCode === this.BACKSPACE_CHAR_CODE && number.length === 0) {
            setTimeout(() => {
                if (this.cantonRef && this.cantonRef.inputRef) {
                    this.cantonRef.inputRef.focus();
                }
            }, 0);
        }
    };

    formatValue = (value) => {
        const {setValues, setTouched} = this.props;

        const canton = this.escapeNonValidCharacters(value, this.REGEX_LETTERS_ONLY).slice(0, 2);
        const number = this.escapeNonValidCharacters(value, this.REGEX_NUMBERS_ONLY).slice(0, 6);

        setValues({canton, number});
        setTouched({canton: true, number: true});
    };

    escapeNonValidCharacters = (value, regex) => {
        return value.replace(regex, '').trim();
    };

    handleChange = (e, regex) => {
        const value = this.escapeNonValidCharacters(e.target.value, regex);
        this.props.setFieldValue([e.target.id], value);
    };

    isCantonValid = () => {
        const {values, errors, list} = this.props;

        return !errors.canton &&
            (values.canton.toString().length === 2) &&
            list.find(cantonName => cantonName.toLowerCase() === values.canton.toLowerCase());
    };

    isInFocus = () => this.cantonRef.inputRef === document.activeElement || this.numberRef.inputRef === document.activeElement

    isValid = () => (this.props.touched.canton && !this.isCantonValid()) || (this.props.touched.number && this.props.errors.number) || this.props.error

    getCantonLogo = (canton) => {
        try{
            return require(`../../../assets/cantons/${canton.toUpperCase()}.png`);
        }
        catch (e) {
            return emptyCanton;
        }
    }

    render() {
        const {t, values, handleBlur, onFocus} = this.props;

        return (
            <div className="licence-plate">
                <div className={`licence-plate-input clearfix ${this.isInFocus() ? 'licence-plate-input--focus' : ''}`}>
                    <div className="form-group mt-0 mb-0">
                        <label htmlFor="canton">{t('form.label.licence_plate')}</label>
                    </div>
                    <Input
                        value={values.canton}
                        ref={ref => this.cantonRef = ref}
                        type="text"
                        id="canton"
                        name="canton"
                        placeholder={t('form.placeholder.canton')}
                        error={this.isValid()}
                        onBlur={(e) => {
                            if (values.canton.length > 0) {
                                handleBlur(e);
                            }
                            this.forceUpdate()
                        }}
                        onFocus={(e) => {
                            onFocus(e)
                        }}
                        onChange={(e) => this.handleChange(e, this.REGEX_LETTERS_ONLY)}
                        onPaste={this.formatValue}
                        maxLength={2}
                        leftChild={
                            <img src={countryFlag} alt="CH"/>
                        }
                        extraClass="licence-plate-group licence-plate-group__canton"
                        hideErrorMessage
                    />
                    <Input
                        value={values.number}
                        ref={ref => this.numberRef = ref}
                        type="text"
                        id="number"
                        name="number"
                        placeholder={t('form.placeholder.plate_number')}
                        error={this.isValid()}
                        onBlur={(e) => {
                            if (values.number.length > 0) {
                                handleBlur(e);
                                this.setState({
                                    numberBlur: true
                                })
                            }
                            this.forceUpdate()
                        }}
                        onFocus={(e) => {
                            onFocus(e)

                            if (!this.isCantonValid()) {
                                this.cantonRef.inputRef.focus()
                            }
                        }}
                        onChange={(e) => this.handleChange(e, this.REGEX_NUMBERS_ONLY)}
                        onPaste={this.formatValue}
                        maxLength={6}
                        rightChild={
                            this.isCantonValid() ?
                                <img src={this.getCantonLogo(values.canton)} alt={`${values.canton.toUpperCase()}`}/> :
                                <img src={emptyCanton} alt="/"/>
                        }
                        extraClass="licence-plate-group licence-plate-group__number"
                        hideErrorMessage
                    />
                </div>
            </div>
        );
    }
}

const licencePlateConfig = {

    mapPropsToValues(props) {
        return {
            canton: props.canton || '',
            number: props.number || ''
        }
    },

    validationSchema: () => {
        return Yup.object().shape({
            canton: Yup.string()
                .nullable()
                .required(),

            number: Yup.number()
                .nullable()
                .required()
        });
    }
};

export default translate('translations')(withFormik(licencePlateConfig)(LicencePlate));
