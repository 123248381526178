import React, {Component} from 'react';
import {translate} from 'react-i18next';
import "./CarStatistic.scss";
import {compare} from 'utils/common';
import {getActivity, setActivity} from "../../../utils/activity";

const MAX_SHOW = 20

class CarStatistic extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cars: null,
            limit: MAX_SHOW,
            collapse: getActivity('analytics.car_statistics.collapsed', false)
        }
    }

    componentDidMount() {
        if (this.props.cars) {
            this.set(this.props.cars)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (compare(nextProps.cars, this.props.cars)) {
            this.set(nextProps.cars)
        }
    }

    set = (cars) => {
        this.setState({
            cars: cars,
        })
    }

    collapse = () => {
        this.setState({
            collapse: !this.state.collapse
        }, () => {
            setActivity('analytics.car_statistics.collapsed', this.state.collapse)
        })
    }

    list = (cars, limit) => (

        cars && cars.slice(0, limit).map((car, key) => {
            const {collapse} = this.state;

            return (
                <div key={key} className="analytic-tab-content__package-stat">
                    <div className="label">
                        {car.make.name} {' '} {car.range.name}
                        {!collapse &&
                        <div className="float-right">
                            {car.counter}
                        </div>
                        }

                        {limit === 1 &&
                        <span className={`chevron ${this.state.collapse ? 'close' : ''}`}><i
                            className="icon-chevron-down"/></span>
                        }
                    </div>
                </div>
            )
        })
    )

    render() {
        const {t} = this.props;
        const {limit, collapse, cars} = this.state;

        return (
            <React.Fragment>
                <div className="analytic-tab analytic-tab-car-statistic">

                    <div className="analytic-tab-header" onClick={this.collapse}>
                        {!collapse ?
                            <div>
                                <span>{t('pages.analytics.top_ranges')}</span>
                                <span className="chevron"><i className="icon-chevron-down"/></span>
                            </div>
                            : this.list(cars, 1)
                        }
                    </div>

                    {!collapse &&
                    <div className="analytic-tab-content analytic-tab-content--expandable">
                        {this.list(cars, limit)}
                    </div>
                    }
                </div>

            </React.Fragment>
        );
    }
}

export default translate('translations')(CarStatistic)
