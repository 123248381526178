export default {
    vehicle: false,
    booking: false,
    login: false,
    hourlyPrice: false,
    oilPrice: false,
    liquidsPrice: false,
    discountPrice: false,
    discountPartsPrice: false,
    discountBrakesPrice: false,
    discountAppointmentsPrice: false,
    settings: false,
    mechanics: false,
    packages: false,
    packagesAllRemove: false,
    packageTire: false,
}
