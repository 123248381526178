import offer from 'store/models/offer'
import {OFFER_RESET, OFFER_SET} from '../actions/offer.actions'

export default function (state = offer, action) {
    switch (action.type) {
        case OFFER_SET:
            return {
                ...action.payload.offer,
            }

        case OFFER_RESET:
            return offer

        default:
            return state
    }
}
