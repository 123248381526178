import Echo from 'laravel-echo'
import { STORAGE_USER_TOKEN } from '../app/consts/storage.consts'
import store from 'store/store'
import { appendNotifications } from '../store/actions/notifications.actions'
import { PushNotifications } from '../components/PushNotifications/PushNotifications'
import {
    USER_CAN_RECEIVE_NOTIFY_FROM_EVERY_GARAGE,
    USER_COMMUNICATION_PUSH_NOTIFICATION
} from '../store/consts/user.constants'
import { appendSystemMessage } from "../store/actions/systemMessage.actions";
import {incrementSystemMessagesCounter} from "../store/actions/systemMessagesCounter.actions";

export const startEcho = (user, garage) => {
    window.io = require('socket.io-client')
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.REACT_APP_LARAVEL_ECHO_URL,
        auth: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(STORAGE_USER_TOKEN),
            },
        },
    })

    let channel = `user-repair-shop.${user.id}`;
    let event =  USER_CAN_RECEIVE_NOTIFY_FROM_EVERY_GARAGE.indexOf(user.type) !== -1 ? '.notification.received' : `.notification.received.${garage.id}`;

    window.Echo.private(channel).listen(event, e => {
        store.dispatch(appendNotifications([e]))

        if (store.getState().user.communication.indexOf(USER_COMMUNICATION_PUSH_NOTIFICATION) !== -1) {
            PushNotifications(e)
        }

        reloadList()
    })

    window.Echo.private(`system-message`)
        .listen(`.system-message`, (e) => {
            if (e) {
                store.dispatch(appendSystemMessage([e]))
                store.dispatch(incrementSystemMessagesCounter())
            }
        });

    const reloadList = () => {
        if (window.location.pathname === '/requests' || window.location.pathname === '/request/*') {
            window.location.reload()
        }
    }
}

export const clearEcho = () => {
    Object.keys(window.Echo.connector.channels).forEach(channel => {
        window.Echo.leave(channel)
    })

    window.Echo = null
}

export default {
    startEcho,
    clearEcho,
}
