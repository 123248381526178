import React, { Component } from 'react';
import classNames from 'classnames';

class TextArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || ''
        };
        this.inputRef = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleChange = (e, callback) => {
        if (e.target instanceof HTMLTextAreaElement) {

            let maxLen = parseInt(e.target.maxLength);
            if(maxLen > 0 && e.target.value.toString().length > maxLen){
                return;
            }

            this.setState({ value: e.target.value });

            callback();
        }
    };

    shouldShowRightIcon = () => {
        return this.props.iconRightClassName && this.state.value;
    };

    handleTextClear = () => {
        this.setState({
            value: ''
        });
        this.props.onTextClear();
    };

    render() {
        const { value } = this.state;
        const {
            name,
            id,
            placeholder,
            label,
            onChange,
            onClick,
            onBlur,
            onPaste,
            error,
            iconLeftClassName,
            className,
            iconRightClassName,
            maxLength,
            extraClass,
            hideErrorMessage,
            autoFocus,
            preventOnEnter,
            hidden,
            onKeyPress,
            readonly,
            rows,
        } = this.props;

        if (hidden) return null;

        return (
            <div className={`form-group ${extraClass}`}>
                {iconLeftClassName && (
                    <div className="input-icon input-icon--left">
                        <span className={iconLeftClassName} />
                    </div>
                )}

                {label && <label htmlFor={name}>{label}</label>}

                <textarea onKeyPress={onKeyPress}
                      ref={ref => this.inputRef = ref}
                      name={name}
                      id={id}
                      placeholder={placeholder}
                      onBlur={onBlur}
                      className={classNames(className, {
                          'form-control': true,
                          'has-left-icon': iconLeftClassName,
                          'has-right-icon': this.shouldShowRightIcon(),
                          'input-error': error
                      })}
                      onChange={e => {
                          this.handleChange(e, () => {
                              if (onChange) {
                                  onChange(e);
                              }
                          });
                      }}
                      onClick={() => {
                          if (onClick) {
                              onClick();
                          }
                      }}
                      onPaste={e => {
                          if (onPaste)
                              onPaste(e.clipboardData.getData('text/plain'));
                          return false;
                      }}
                      autoComplete="off"
                      maxLength={maxLength}
                      readOnly={readonly}
                      autoFocus={autoFocus}
                      defaultValue={value}
                      rows={rows}
                      onKeyDown={e => {
                          if (preventOnEnter && e.keyCode === 13) {
                              e.preventDefault();
                              return false;
                          }
                      }}/>


                {this.shouldShowRightIcon() ? (
                    <div
                        className="input-icon input-icon--right"
                        onClick={this.handleTextClear}
                    >
                        <span className={iconRightClassName} />
                    </div>
                ) : null}

                {error && !hideErrorMessage && (
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                )}
            </div>
        );
    }
}

export default TextArea;
