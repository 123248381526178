export const MATERCHECK_LAYOUT_SETTING = '[MATERCHECK LAYOUT SETTING]';

export const MATERCHECK_LAYOUT_SETTING_SET = `${MATERCHECK_LAYOUT_SETTING} SET`;
export const MATERCHECK_LAYOUT_SETTING_UPDATE = `${MATERCHECK_LAYOUT_SETTING} UPDATE`;

export const mastercheckLayoutSettingUpdate = (garageId, layoutId) => ({
    type: MATERCHECK_LAYOUT_SETTING_UPDATE,
    payload: {
        garageId,
        layoutId
    }
});


