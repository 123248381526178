export const carNumberLabel = garage => {
    if (garage.show_vin && garage.show_swiss) {
        return 'vin_swiss'
    } else if (garage.show_vin && garage.show_kba) {
        return 'vin_kba'
    } else if (garage.show_vin) {
        return 'vin'
    } else if (garage.show_swiss) {
        return 'swiss'
    } else if (garage.show_kba) {
        return 'kba'
    }

    return 'default'
}
