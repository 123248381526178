import Yup from './yup'
import { userLogin } from '../../../store/actions/auth.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            username: '',
            password: '',
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            username: Yup.string()
                .required()
                .label(t('validations.label.username')),

            password: Yup.string().label(t('validations.label.password')),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(userLogin(values))
    },
}
