import React, {Component} from 'react';
import {translate} from "react-i18next";
import InputCheckbox from "../../shared/InputCheckbox";

import {CHECKLIST_NOT_OK, CHECKLIST_OK} from "../ChecklistLabour";
import Switch from "react-switch";
import {stringToBool} from "../../../utils/common";
import {connect} from "react-redux";
import {LOADER_SWITCH} from "../../../store/consts/loader.constants";
import Loader from "../../shared/Loader/Loader";

export const MASTERCHECK_LAYOUT_CHECKBOXES = 1;
export const MASTERCHECK_LAYOUT_TOGGLE = 2;

class ChecklistLayout extends Component {

    handleCheckboxChange = (e) => {
        const {handleChange, labour} = this.props;
        let passed = stringToBool(e.target.value);

        if(this.props.dummyComponent === true){
            handleChange(passed, labour.id);
        }else{
            handleChange(passed);
        }
    }

    handleSwitchChange = (passed) => {
        const {handleChange, labour} = this.props;

        if(this.props.dummyComponent === true){
            handleChange(passed, labour.id);
        }else{
            handleChange(passed);
        }
    }

    renderLayout = () => {
        const layout = this.props.layoutType ?? this.props.garage.mastercheck_layout
        switch(layout) {
            case MASTERCHECK_LAYOUT_CHECKBOXES :
                return  this.renderCheckbox();
                break;

            case MASTERCHECK_LAYOUT_TOGGLE :
                return this.renderToggle();
                break;
        }
    }

    renderCheckbox(){
        const {labour, name, classes} = this.props;

        return <>
            <div className={classes ?? "col-xs-1"}>
                <div className="checklist-labour__btn">
                    <InputCheckbox
                        id={`${name}-${labour.id}`}
                        name={`${name}-${labour.id}`}
                        value={true}
                        checked={labour.passed === CHECKLIST_OK}
                        onChange={this.handleCheckboxChange}
                        disabled={labour.updating || labour.disabled}
                        loading={labour.updating && labour.passed === CHECKLIST_OK}
                    />
                </div>
            </div>
            <div className={classes ?? "col-xs-1"}>
                <div className="checklist-labour__btn">
                    <InputCheckbox
                        id={`${name}-${labour.id}`}
                        name={`${name}-${labour.id}`}
                        value={false}
                        checked={labour.passed === CHECKLIST_NOT_OK}
                        onChange={this.handleCheckboxChange}
                        disabled={labour.updating || labour.disabled}
                        loading={labour.updating && labour.passed === CHECKLIST_NOT_OK}
                    />

                </div>
            </div>
        </>
    }

    renderToggle(){
        const {t, labour, classes, dummyComponent} = this.props;

        return <React.Fragment>
            <div className={classes ?? "col-xs-1 mastercheck-toogle"}>
                <div className="checklist-labour__btn">
                    <Switch onChange={this.handleSwitchChange}
                            disabled={labour.updating || labour.disabled}
                            checked={labour.passed ?? true}
                            uncheckedIcon={ (labour.updating || labour.disabled) && !dummyComponent && <Loader isLoading={true} type={LOADER_SWITCH} addClass={'off-state'}/>}
                            checkedIcon={ (labour.updating || labour.disabled) && !dummyComponent && <Loader isLoading={true} type={LOADER_SWITCH} addClass={'on-state'}/>}
                            onColor={"#1cd091"}
                            offColor={"#ff4d68"}
                    />
                </div>
            </div>
            {dummyComponent && <div className="col-xs-6 mastercheck-options-label">{labour.passed === CHECKLIST_NOT_OK ? t('pages.checklist_labours.not').toUpperCase() : ""} {t('pages.checklist_labours.ok').toUpperCase()}</div>}
        </React.Fragment>
    }

    render() {
        return (
            <>
                {this.renderLayout()}
            </>
        )
    }
}
const mapStateToProps = state => ({
    garage: state.garage,
});

export default connect(mapStateToProps)(translate('translations')(ChecklistLayout));
