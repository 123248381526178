import React from 'react';
import {translate} from "react-i18next";
import Popup from "../../shared/Popup/Popup";
import Button from "../../shared/Button/Button";
import TextArea from "../../shared/TextArea/TextArea";
import {withFormik} from "formik";
import {formChecklistLabourNote} from "../../../app/config/yup";

const NotePopup = props => {

    const {t, visible, onClose, onSubmit, handleBlur, touched, errors, values, handleChange, isValid} = props;

    const submit = (e) => {
        e.preventDefault();

        if (isValid) {
            onSubmit(values.note);
        }
    }

    return (
        <Popup
            title={t('pages.checklist_labours.note.title')}
            visible={visible}
            onClose={onClose}
        >
            <form onSubmit={submit}>
                <TextArea
                    type="text"
                    id="note"
                    name="note"
                    label={t('pages.checklist_labours.note.label')}
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.note && errors.note}
                />

                <Button size="lg" type="primary">{t('global.save')}</Button>
            </form>
        </Popup>
    );
}
const NoteForm = withFormik(formChecklistLabourNote)(NotePopup);

export default translate('translations')(NoteForm);
