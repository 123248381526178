import React, {Component} from "react";
import {translate} from "react-i18next";
import Header from "../../components/layout/Header/Header";
import axios from 'app/config/axios'
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import "./index.scss";
import history from 'routes/history'
import pages from 'app/consts/routes'
import {CHECKLIST_NOT_OK, CHECKLIST_OK} from "../../components/ChecklistLabour/ChecklistLabour";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import {captureException} from "../../utils/captureException";
import Button from "../../components/shared/Button/Button";
import Popup from "../../components/shared/Popup/Popup";

const REQUEST_CHECKLIST_FETCH_URL = 'garage/requests/checklist'
const GENERATE_PDF_URL = 'garage/requests/generate-pdf/checklist'

class RequestChecklistContainer extends Component {

    state = {
        package: null,
        request: null,
        requestLoader: true,
        pdfRendering: false,
        labourNote: null,
    };

    componentDidMount() {
        this.fetch()
    }

    fetch = () => {
        axios
            .post(REQUEST_CHECKLIST_FETCH_URL, {offer_token: this.props.match.params.request})
            .then(response => {
                this.setState({
                    request: response.data.request,
                    requestLoader: false,
                    userDataShow: false
                })
            })
            .catch(error => {
                captureException(error, REQUEST_CHECKLIST_FETCH_URL);
                history.push(`${pages.request_details}${this.props.match.params.request}`)
            })
    }

    printPDF = () => {
        const {pdfRendering} = this.state;

        if (pdfRendering) return

        this.setState({
            pdfRendering: true
        })

        axios.post(GENERATE_PDF_URL, {offer_token: this.props.match.params.request})
            .then((response) => {
                window.open(response.data.download_pdf_url);
                this.setState({
                    pdfRendering: false
                })
            })
            .catch(error => {
                captureException(error, GENERATE_PDF_URL)

                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({
                    pdfRendering: false
                });
            })
    }

    renderChecklist = () => {
        const {t} = this.props;
        const {request, pdfRendering} = this.state;

        return (
            <div>
                <div className="request-checklist-header">
                    <div className="col-xs-push-6 col-xs-2">{t('pages.request_details.checklist.ok')}</div>
                    <div className="col-xs-push-6 col-xs-2"><span
                        className="small">{t('pages.request_details.checklist.not')}</span> {t('pages.request_details.checklist.ok')}
                    </div>
                </div>

                {request.checklistLabours.map(labour => {
                    return (
                        <div className="request-checklist-labour" key={labour.id}>

                            <div className="col-xs-6 request-checklist-labour__name">
                                {labour.name}
                            </div>
                            <div className="col-xs-2 request-checklist-labour__btn">
                                {labour.passed === CHECKLIST_OK &&
                                <i className="icon-check"/>
                                }
                            </div>
                            <div className="col-xs-2 request-checklist-labour__btn">
                                {labour.passed === CHECKLIST_NOT_OK &&
                                <i className="icon-check"/>
                                }
                            </div>
                            <div className="col-xs-2 request-checklist-labour__btn">
                                {labour.note &&
                                    <span className="link-style" onClick={() => this.setState({labourNote: labour})}>
                                        {t('pages.checklist_labours.note.view')}
                                    </span>
                                }
                            </div>
                        </div>
                    );
                })}

                <div className="text-center mt-20">
                    <span className="link-style bold" disabled={pdfRendering}
                          onClick={this.printPDF}>{t('pages.request_details.checklist.print_pdf')}</span>
                </div>
            </div>
        );
    }

    render() {
        const {t, title} = this.props;
        const {requestLoader, labourNote} = this.state;

        return (
            <React.Fragment>
                <Header title={this.state.package ? this.state.package.name : t(title)}/>

                <div className="container container--has-submit-container">

                    {requestLoader ? <ListLoader/> : this.renderChecklist()}

                </div>

                {!!labourNote &&
                    <Popup title={`${labourNote.name} - ${t('pages.checklist_labours.note.label')}`}
                           visible={!!labourNote} onClose={() => this.setState({labourNote: null})}>
                        <div className="text-light mb-20">
                            {labourNote.note}
                        </div>
                    </Popup>
                }

            </React.Fragment>
        );
    }
}

export default translate('translations')(RequestChecklistContainer);
