import React, {Component} from 'react';
import './CarNumber.scss';
import Input from "components/shared/Input/Input";
import {translate} from 'react-i18next';

class CarNumber extends Component {

    constructor(props) {
        super(props);

        this.state = {
            carNumberLabel: props.carNumberLabel
        };

    }

    /*getCarNumberLabel = () => {
        const {show} = this.props;

        if(show.vin && show.swiss){
            return 'vin_swiss';
        }
        else if(show.vin && show.kba){
            return 'vin_kba';
        }
        else if(show.vin){
            return 'vin';
        }
        else if(show.swiss){
            return 'swiss';
        }
        else if(show.kba){
            return 'kba';
        }

        return 'default';
    }*/

    render() {
        const {carNumberLabel} = this.state;
        const {t, onChange, error} = this.props;

        return (
            <div className="car-number">
                <div className="car-number-input">
                    <Input
                        type="text"
                        placeholder={t(`form.placeholder.car_number.${carNumberLabel}`)}
                        name="car_number"
                        label={t(`form.label.car_number.${carNumberLabel}`)}
                        onChange={onChange}
                        error={error}
                        hideErrorMessage
                    />
                </div>
            </div>
        );
    }
}

export default translate('translations')(CarNumber);
