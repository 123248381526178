import i18n from "i18next";
import language from "../../../utils/language";

export const URGENCY_SAFETY_RELEVANT = 1
export const URGENCY_URGENT = 2
export const URGENCY_RECOMMENDED = 3

export const URGENCIES = new Proxy({
        [URGENCY_URGENT]: () => i18n.getResource(language(), 'translations', 'pages.package_details.urgency.urgency_urgent'),
        [URGENCY_SAFETY_RELEVANT]: () => i18n.getResource(language(), 'translations', 'pages.package_details.urgency.urgency_safety_relevant'),
        [URGENCY_RECOMMENDED]: () => i18n.getResource(language(), 'translations', 'pages.package_details.urgency.urgency_recommended'),
    },
    {
        get: (obj, name) => {
            return obj[name]()
        },
    },
)

export default {
    URGENCIES,
    URGENCY_URGENT,
    URGENCY_SAFETY_RELEVANT,
    URGENCY_RECOMMENDED,
}
