import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Link} from "react-router-dom";
import pages from "../../../app/consts/routes";

class ActivityManagerRejected extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notify: props.notify
        };
    }

    render() {
        const {t} = this.props;
        const {notify} = this.state;

        return (
            <div className="activity-item" key={notify.id}>
                <div className="activity-item__sent">
                    <div className="activity-item__time">{notify.at.time}</div>
                    <div className="activity-item__user">{t('pages.activity.type.manager_rejected.name', {first_name: notify.manager.first_name, last_name: notify.manager.last_name})}</div>
                    <div className="activity-item__message">
                        {t('pages.activity.type.manager_rejected.body')}
                    </div>
                    <div className="activity-item__bottom">
                        <Link to={`${pages.request_details}${notify.offer_token}`}>{t('pages.activity.type.details')}</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('translations')(ActivityManagerRejected);
