import {createSelector} from 'reselect';
import {
    TIRES_AXELS, TIRES_SORT_ASC,
    TIRES_STEP_AXEL,
    TIRES_STEP_LIST_PREMIUM, TIRES_STEP_LIST_PRICE_QUALITY,
    TIRES_TYPE_BRAND_PREMIUM,
    TIRES_TYPE_BRAND_PRICE_QUALITY, TIRES_TYPES
} from "../consts/tires.constants";
import {toBool} from "../../utils/common";
import packageTiresModel from "../../store/models/package/tires";
import {cloneDeep, sortBy, uniq} from "lodash";
import i18next from "i18next";

const selectTiresState = state => state.tires;
const selectGarage = state => state.garage;

const selectAreBothAxelSelected = createSelector(
    [selectTiresState],
    (tires) => {
        return tires.axel.front.selected && tires.axel.rear.selected;
    }
);

const selectAreBothAxelSameDimension = createSelector(
    [selectTiresState, selectAreBothAxelSelected],
    (tires, bothAxelSelected) => {
        return bothAxelSelected && tires.axel.front.wide === tires.axel.rear.wide && tires.axel.front.depth === tires.axel.rear.depth && tires.axel.front.diameter === tires.axel.rear.diameter;
    }
);

const selectSelectedTires = createSelector(
    [selectTiresState],
    (tires) => {

        let selected = [];

        TIRES_AXELS.forEach((axel) => {
            if (tires.axel[axel].selected) {
                TIRES_TYPES.forEach((brandType) => {
                    if (tires.axel[axel].brand_type[brandType]) {
                        selected.push(tires.axel[axel].brand_type[brandType].ean)
                    }
                })
            }
        })

        return selected;
    }
);

const selectTiresCurrentList = createSelector(
    [selectTiresState,],
    (tires) => {
        let list = tires.list;

        if (tires.step === TIRES_STEP_LIST_PREMIUM) {
            list = list[TIRES_TYPE_BRAND_PREMIUM];
        } else if (tires.step === TIRES_STEP_LIST_PRICE_QUALITY) {
            list = list[TIRES_TYPE_BRAND_PRICE_QUALITY];
        } else {
            list = [];
        }

        return list;
    }
);

const selectTiresList = createSelector(
    [selectTiresState, selectTiresCurrentList, selectSelectedTires, selectAreBothAxelSelected, selectGarage],
    (tires, tiresCurrentList, selectedTires) => {
        let list = tiresCurrentList;

        list = list.map(tire => ({
            ...tire,
            selected: selectedTires.indexOf(tire.ean) !== -1,
        }));

        list.sort((a, b) => {
            let x = a[tires.sort.column];
            let y = b[tires.sort.column];

            if (tires.sort.column === 'price') {
                return tires.sort.order === TIRES_SORT_ASC ? x - y : y - x;
            }

            if (tires.sort.column === 'brand') {
                x = `${a[tires.sort.column]} ${a.profile}`;
                y = `${b[tires.sort.column]} ${b.profile}`;
            }

            if (tires.sort.order === TIRES_SORT_ASC) {
                return x.toString().localeCompare(y)
            }

            return y.toString().localeCompare(x)
        });

        return tires.index ? list.filter(tire => tire.index.toLowerCase().includes((tires.index).toLowerCase())) : list;
    }
);

export const selectTiresValidateSteps = createSelector(
    [selectTiresState],
    (tires) => {

        let stepAxel = true, stepPremium = true, stepPriceQuality = true;
        TIRES_AXELS.forEach((axel) => {
            if (tires.axel[axel].selected && toBool(tires.axel[axel].wide && tires.axel[axel].depth && tires.axel[axel].diameter) === false) {
                stepAxel = false;
            }

            if (tires.axel[axel].selected && toBool(tires.axel[axel].brand_type[TIRES_TYPE_BRAND_PRICE_QUALITY]) === false) {
                stepPriceQuality = false;
            }
        });

        return {
            [TIRES_STEP_AXEL]: stepAxel,
            [TIRES_STEP_LIST_PREMIUM]: stepPremium,
            [TIRES_STEP_LIST_PRICE_QUALITY]: stepPriceQuality,
        }
    }
)

export const selectTiresFormatSelected = createSelector(
    [selectTiresState],
    (tires) => {

        let model = cloneDeep(packageTiresModel);
        let selectedBrandTypes = [];

        TIRES_AXELS.forEach((axel) => {
            if (tires.axel[axel].selected) {
                TIRES_TYPES.forEach((brandType) => {
                    if (tires.axel[axel].brand_type[brandType]) {
                        model[brandType][axel] = tires.axel[axel].brand_type[brandType];

                        if (selectedBrandTypes.indexOf(brandType) === -1) {
                            selectedBrandTypes.push(brandType)
                        }
                    } else {
                        model.all_types_selected = false
                    }
                })
            }
        })

        model.single_type_selected = selectedBrandTypes.length === 1;

        return model;
    }
)

const selectIndexList = createSelector(
    [selectTiresCurrentList],
    (tiresCurrentList) => {

        let indexList = sortBy(uniq(tiresCurrentList.map(tire => tire.index)).map(index => {
                return {
                    id: index,
                    name: index,
                }
            }),
            [function (o) {
                return parseInt(o.id);
            }]);

        indexList.unshift({id: 0, name: i18next.t('tires.filter_index_all')});

        return indexList;
    }
)


const selectAxel = createSelector(
    [selectTiresState],
    (tires) => {

        let axel = cloneDeep(tires.axel);

        if (axel.front.selected) {
            if (axel.front.wide) {
                axel.rear.wide_list = axel.rear.wide_list.filter(w => w.id >= axel.front.wide)
            }

            if (axel.front.diameter) {
                axel.rear.diameter_list = axel.rear.diameter_list.filter(w => w.id >= axel.front.diameter)
            }
        }

        return {...axel};
    }
)

const selectCanSkip = createSelector(
    [selectTiresState],
    (tires) => {

        let stepAxel = false, stepPremium = true, stepPriceQuality = true;

        TIRES_AXELS.forEach((axel) => {
            if (tires.axel[axel].selected && toBool(tires.axel[axel].brand_type[TIRES_TYPE_BRAND_PREMIUM]) === false) {
                stepPriceQuality = false;
            }
        });

        return {
            [TIRES_STEP_AXEL]: stepAxel,
            [TIRES_STEP_LIST_PREMIUM]: stepPremium,
            [TIRES_STEP_LIST_PRICE_QUALITY]: stepPriceQuality,
        }
    }
)

export const selectTires = createSelector(
    [selectTiresState, selectTiresList, selectAxel, selectAreBothAxelSelected, selectAreBothAxelSameDimension, selectIndexList, selectCanSkip],
    (tires, tiresList, axel, bothAxelSelected, bothAxelSameDimension, indexList, canSkip) => {
        return {
            ...tires,

            axel: axel,

            list: tiresList,

            list_front: (!bothAxelSameDimension && bothAxelSelected) ? tiresList.filter(tire => tire.wide === tires.axel.front.wide && tire.depth === tires.axel.front.depth && tire.diameter === tires.axel.front.diameter) : [],
            list_rear: (!bothAxelSameDimension && bothAxelSelected) ? tiresList.filter(tire => tire.wide === tires.axel.rear.wide && tire.depth === tires.axel.rear.depth && tire.diameter === tires.axel.rear.diameter) : [],

            index_list: indexList,

            canSkip: canSkip,

            bothAxelSelected: bothAxelSelected,
            bothAxelSameDimension: bothAxelSameDimension
        }
    }
)
