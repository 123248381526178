export const DRAFT = '[Offer]'

export const DRAFT_GET = `${DRAFT} GET`
export const DRAFT_REMOVE = `${DRAFT} REMOVE`

export const getDraft = token => ({
  type: DRAFT_GET,
  payload: {
    token,
  },
})

export const removeDraft = token => ({
  type: DRAFT_REMOVE,
  payload: {
    token,
  },
})
