import React, {Component} from "react";
import {translate} from "react-i18next";
import axios from 'app/config/axios'
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import Confirm from "../../components/shared/Confirm/Confirm";
import {captureException} from "../../utils/captureException";

const REQUEST_MARK_AS_TEST_URL = 'garage/add-cost-tool/mark-as-test-request'

class MarkOfferAsTest extends Component {

    state = {
        marking: false,
    };

    mark = () => {
        const {marking} = this.state;
        const {request, onSuccess} = this.props;

        if(marking) return;

        this.setState({
            marking: true, confirm: false
        })

        axios.post(REQUEST_MARK_AS_TEST_URL, {is_test: !request.is_test, offer_token: request.token})
            .then((response) => {
                onSuccess(!request.is_test)

                toast.success(<Toast text={parseResponse(response)} type="success"/>)
            })
            .catch(error => {
                captureException(error, REQUEST_MARK_AS_TEST_URL)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => {
                this.setState({
                    marking: false
                })
            })
    }


    render() {
        const {t, request} = this.props;
        const {marking, confirm} = this.state;

        return (
            <React.Fragment>

                <div className="request-sublinks">
                    <span className="link-style" disabled={marking} onClick={() => this.setState({confirm: true})}>
                        {request.is_test ? t('pages.request_details.unmark_as_test') : t('pages.request_details.mark_as_test')}
                    </span>
                </div>

                {confirm &&
                    <Confirm
                        title={t('global.you_sure')}
                        visible={!!confirm}
                        accept={this.mark}
                        cancel={() => this.setState({confirm: false})}
                    />
                }

            </React.Fragment>
        );
    }
}

export default (translate('translations')(MarkOfferAsTest));
