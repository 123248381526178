import Yup from './yup';

export default {

    enableReinitialize: true,

    mapPropsToValues({work}) {

        return {
            name: work.name,
            duration: work.duration,
        };

    },

    validationSchema: props => {

        const {t} = props;

        return Yup.object().shape({

            name: Yup.string()
                .required()
                .label(t('pages.package_details.labour.works.section_form.label.name')),

            duration: Yup.number()
                .required()
                .label(t('pages.package_details.labour.works.section_form.label.duration')),
        });
    },
};
