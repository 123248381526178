import Yup from './yup'
import { AVATAR } from '../../../store/consts/user.constants'
import { changeUserDetailsSettingUpdate } from '../../../store/actions/change-user-details.settings.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues({ user }) {
        return {
            first_name: user.first_name ? user.first_name : '',
            last_name: user.last_name ? user.last_name : '',
            email: user.email ? user.email : '',
            phone: user.phone ? user.phone : '',
            username: user.username ? user.username : '',
            avatar: null,
        }
    },

    validationSchema: props => {
        const { t } = props
        // const FILE_SIZE = 1048576
        // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

        return Yup.object().shape({
            first_name: Yup.string()
                .nullable()
                .label(t('pages.user_profile.section_form.label.first_name')),

            last_name: Yup.string()
                .nullable()
                .label(t('pages.user_profile.section_form.label.last_name')),

            phone: Yup.string()
                .nullable()
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function() {
                    return this.parent.phone || this.parent.email
                })
                .label(t('pages.user_profile.section_form.label.phone')),

            email: Yup.string()
                .email()
                .nullable()
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function() {
                    return this.parent.phone || this.parent.email
                })
                .label(t('pages.user_profile.section_form.label.email')),

            avatar: Yup.mixed()
                .label(t('pages.user_profile.section_form.label.avatar'))
                .test(
                    'fileSize',
                    t('pages.user_profile.section_form.file_size'),
                    value => value === null || (value && value.size <= AVATAR.FILE_SIZE),
                )
                .test(
                    'fileFormat',
                    t('pages.user_profile.section_form.file_format'),
                    value => value === null || (value && AVATAR.SUPPORTED_FORMATS.includes(value.type)),
                ),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(
            changeUserDetailsSettingUpdate(
                values.first_name,
                values.last_name,
                values.phone,
                values.email,
                values.avatar,
                values.username,
            ),
        )
    },
}
