export const STATUS_OPENED = 1
export const STATUS_PENDING_MANAGER = 2
export const STATUS_PENDING_USER = 3
export const STATUS_RESPONDED_USER = 4
export const STATUS_RESPONDED_GARAGE = 41
export const STATUS_ARCHIVED = 6

export const OFFER_CAN_ARCHIVE = [
    STATUS_PENDING_USER,
    STATUS_RESPONDED_USER,
    STATUS_RESPONDED_GARAGE,
]

export const OFFER_USER_RESPONDED = [
    STATUS_RESPONDED_USER,
    STATUS_ARCHIVED,
]

export const statusOfferUserResponded = (status) => OFFER_USER_RESPONDED.indexOf(status) !== -1

export default {
    STATUS_OPENED,
    STATUS_PENDING_MANAGER,
    STATUS_PENDING_USER,
    STATUS_RESPONDED_USER,
    STATUS_RESPONDED_GARAGE,
    STATUS_ARCHIVED,
    OFFER_CAN_ARCHIVE,
}
