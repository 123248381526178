export default {
    meta: {
        current_page: 0,
        last_page: null,
    },
    list: [],
    data: {
        unseen: 0,
    },
}
