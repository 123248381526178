export const APP_VERSION = '[APP_VERSION]'

export const APP_VERSION_SET = `${APP_VERSION} SET`
export const APP_VERSION_SET_LOCAL = `${APP_VERSION} SET LOCAL`
export const APP_VERSION_SEEN_MESSAGE = `${APP_VERSION} SEEN MESSAGE`

export const setAppVersion = apiVersion => ({
    type: APP_VERSION_SET,
    payload: {
        apiVersion
    }
});

export const setLocalAppVersion = appVersion => ({
    type: APP_VERSION_SET_LOCAL,
    payload: appVersion
})

export const seenOutOfDateMessage = () => ({
    type: APP_VERSION_SEEN_MESSAGE
})

