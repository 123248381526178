import React from 'react'
import axios from 'app/config/axios'
import axiosMain from 'axios'
import {API_REQUEST, apiError, apiSuccess} from 'store/actions/api.actions'
import {parseResponse} from 'utils/common'
import {authRemove} from 'store/actions/auth.actions'
import {toast} from 'react-toastify'
import apiMessages from '../../consts/api.messages.constants.js'
import Toast from '../../../components/shared/Toast/Toast'
import {captureException} from "../../../utils/captureException";

const HTTP_UNAUTHORIZED = 401

const apiMiddleware = ({dispatch}) => next => action => {
    next(action)

    if (action.type.includes(API_REQUEST)) {
        const {method, url, signature} = action.meta
        const body = action.payload

        switch (method) {
            case 'POST':
                axios
                    .post(url, body)
                    .then(response => {
                        if (parseResponse(response) && apiMessages.indexOf(signature) !== -1) {
                            toast.success(<Toast text={parseResponse(response)} type="success"/>)
                        }
                        dispatch(apiSuccess({response, signature}))
                    })
                    .catch(error => {
                        if (!axiosMain.isCancel(error)) {
                            captureException(error, url);

                            const response = error.response

                            if (response && response.status === HTTP_UNAUTHORIZED) {
                                dispatch(authRemove())
                            }

                            if (parseResponse(response) && apiMessages.indexOf(signature) !== -1) {
                                toast.error(<Toast text={parseResponse(response)} type="error"/>)
                            }

                            dispatch(
                                apiError({
                                    error: response ? response.data : '',
                                    status: response ? response.status : 0,
                                    signature,
                                }),
                            )
                        }
                    })
                break
            case 'GET':
                axios
                    .get(url, body)
                    .then(response => {
                        if (parseResponse(response) && apiMessages.indexOf(signature) !== -1) {
                            toast.success(<Toast text={parseResponse(response)} type="success"/>)
                        }
                        dispatch(apiSuccess({response, signature}))
                    })
                    .catch(error => {
                        if (!axiosMain.isCancel(error)) {
                            captureException(error, url);
                            if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
                                dispatch(authRemove())
                            }

                            if (parseResponse(error.response) && apiMessages.indexOf(signature) !== -1) {
                                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                            }
                            dispatch(
                                apiError({
                                    error: error.response ? error.response.data : 'Error',
                                    signature,
                                }),
                            )
                        }
                    })
                break
            case 'PUT':
                axios
                    .put(url, body)
                    .then(response => dispatch(apiSuccess({response, signature})))
                    .catch(error => {
                        if (!axiosMain.isCancel(error)) {
                            captureException(error, url);
                            dispatch(
                                apiError({
                                    error: error.response.data,
                                    signature,
                                }),
                            )
                        }
                    })
                break
            default:
                break
        }
    }
}

export default apiMiddleware
