import React, {Component} from 'react'
import {translate} from 'react-i18next'
import InputRadio from "../../../components/shared/InputRadio";
import {OFFICIAL_SERVICE_SELECTED} from "../../../store/consts/officialServices.constants";
import {fixedFloat} from "../../../utils/common";
import {sumPackagePriceTotal} from "../../../store/selectors/packagePrices";
import {defaultsDeep} from "lodash";
import packageModel from "../../../store/models/package/package";
import Info from "../Info/Info";

class ItemRecommended extends Component {

    state = {
        labourInfo: null
    }

    componentDidMount() {
        let maxHeight = 0;
        document.querySelectorAll('.official-services-single').forEach(e => e.clientHeight > maxHeight ? maxHeight = e.clientHeight : maxHeight);
        document.querySelectorAll('.official-services-single').forEach(e => {
            if(e.clientHeight < maxHeight){
                let margin = (maxHeight - e.clientHeight) / 2;
                e.querySelector('.official-services-single__header').style.marginBottom = `${20 + margin}px`;
                e.querySelector('.official-services-single__total').style.marginTop = `${20 + margin}px`;
            }
        });
    }

    parts = (service) => {
        if (service.works.find(work => /^\-\d+$/.test(work.id))) {
            return service.works.find(work => /^\-\d+$/.test(work.id)).parts.filter(part => part.selected);
        }

        return [];
    }

    showInfo = (e, service) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({labourInfo: service})
    }

    render() {
        const {labourInfo} = this.state;
        const {t, recommended, value, change} = this.props;

        return (
            <>
                <div className="official-services-single">
                    <div className="official-services-single__header">
                        {t('pages.official_service.service_interval_title')}  {recommended[OFFICIAL_SERVICE_SELECTED[value] + 'Km']}km
                    </div>

                    <InputRadio
                        id={`official-services-single-${OFFICIAL_SERVICE_SELECTED[value]}`}
                        name={'official-services-single'}
                        checked={recommended.selected === value}
                        onChange={change}
                        label={
                            <div>
                                {recommended[OFFICIAL_SERVICE_SELECTED[value]].map((service, key) => (
                                    <div className="official-services-single__name" key={key}>
                                        <div className="official-services-single__name__left">
                                            <div className="official-services-single__name__left__name">
                                                {service.name}
                                            </div>
                                        </div>
                                        {this.parts(service).length > 1 &&
                                            <div className="official-services-single__name__right">
                                                <span className="link-style" onClick={(e) => this.showInfo(e, service)}>
                                                    <i className="icon-info-fill"/>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        }
                        value={value}
                    />
                    <div className="official-services-single__total">
                        <div className="col-xs-7">
                            {t('pages.official_service.total_official')}
                        </div>
                        <div className="col-xs-5">
                            <span
                                className="bold">{fixedFloat(sumPackagePriceTotal(defaultsDeep({labours: recommended[OFFICIAL_SERVICE_SELECTED[value]]}, packageModel)))} {t('global.currency')}</span>
                        </div>
                    </div>
                </div>

                {labourInfo &&
                    <Info
                        title={labourInfo.name}
                        visible={!!labourInfo}
                        content={
                            <ul>{this.parts(labourInfo).map(part => <li key={part.id}>{part.name}</li>)}</ul>
                        }
                        onClose={() => this.setState({labourInfo: null})}
                    />
                }
            </>
        )
    }
}

export default translate('translations')(ItemRecommended);
