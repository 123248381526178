import officialServices from '../models/officialServices'
import {OFFICIAL_SERVICES_SET, OFFICIAL_SERVICES_RESET} from "../actions/officialServices.actions";

export default function(state = officialServices, action) {
    switch (action.type) {
        case OFFICIAL_SERVICES_SET:
            return {
                recommended: action.payload.exist ? action.payload.recommended : officialServices.recommended,
                main: action.payload.main,
                additional: action.payload.additional,
                mileage: action.payload.mileage,
                exist: action.payload.exist,
            }

        case OFFICIAL_SERVICES_RESET:
            return officialServices

        default:
            return state
    }
}
