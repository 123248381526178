import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Popup from 'components/shared/Popup/Popup'
import Button from "../../../components/shared/Button/Button";
import Input from "../../../components/shared/Input/Input";
import {withFormik} from "formik";
import {formOfficialServiceKmStand} from "app/config/yup";
import {__clone, intersect, parseResponse} from "../../../utils/common";
import axiosDefault from "axios";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import Loader, {LOADER_LOADING} from "../../../components/shared/Loader/Loader";
import {haxMaxPackages} from "../../../store/selectors/package";
import {officialServicesFetch} from "../../../store/actions/officialServices.actions";
import pages from "../../../app/consts/routes";
import {TYPE_OFFICIAL, TYPE_REGULAR} from "../../../store/consts/package/package.type.constants";
import packageModel from 'store/models/package/package'
import labourModel from 'store/models/package/labour'
import workModel from 'store/models/package/work'
import {defaultsDeep} from "lodash";
import {packagesUpdate} from "../../../store/actions/packages.actions";
import Toast from "../../../components/shared/Toast/Toast";
import history from "../../../routes/history";
import {getValueFromArray} from "../../../utils/car";
import officialServiceModel from "../../../store/models/package/officialService";
import {OFFICIAL_SERVICE_SELECTED} from "../../../store/consts/officialServices.constants";
import randomPackageId from "../../../utils/randomPackageId";
import {WORK_TYPE_CHECKBOX} from "../../../store/consts/package/work";
import axios from "../../../app/config/axios";
import {captureException} from "../../../utils/captureException";

const API_ADD_COST_LABOUR = 'garage/add-cost-tool/labour'

class OfficialService extends Component {
    constructor(props) {
        super(props)

        this.state = {
            car: props.car,
            officialServiceData: {
                car: props.car,
                kmStand: props.car.mileage || null,
            },
            show: props.show,
            officialServiceFetching: false
        }

        this.packageAddNew = React.createRef();
    }

    componentDidMount() {
        const {isValid} = this.props

        if(isValid){
            this.create();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop],
                })
            }
        })
    }

    createDefaultLabour = (data) => {
        return [
            defaultsDeep({
                itemMpId: randomPackageId(),
                name: data.name,
                custom: true,
                works: [
                    defaultsDeep({
                        id: randomPackageId(),
                        name: data.name,
                        input_type: WORK_TYPE_CHECKBOX,
                        selected: true
                    }, workModel)
                ],
            }, labourModel)
        ]
    }

    fetch = () => {
        const {packages, dispatch, t, onSuccess, onError, editOnCreate, createEmpty} = this.props
        const {officialServiceData} = this.state

        let officialServicePackage = packages.find(p => p.type === TYPE_OFFICIAL);

        this.setState({
            officialServiceFetching: true,
        })

        dispatch(officialServicesFetch(officialServiceData.kmStand))
            .then(officialServiceResponse => {

                let isEmpty = officialServiceResponse.data.exist === false && officialServiceResponse.data.main.length === 0 && officialServiceResponse.data.additional.length === 0;
                let isMissingOfficial = officialServiceResponse.data.exist === false;

                if(isEmpty && createEmpty !== true){
                    toast.error(<Toast text={t('pages.labour_search.official_service.not_exist')} type="error"/>)
                    onError(officialServiceResponse.data)
                    return;
                }

                if(isEmpty || isMissingOfficial){
                    toast.warn(<Toast text={t('pages.labour_search.official_service.not_exist')} type="warning"/>)
                }

                if(editOnCreate === true){
                    history.push(`${pages.official_service}`)
                    return;
                }

                let packagesClone = __clone(packages), packageAdd;
                let selectedOfficialService = officialServiceResponse.data.recommended[OFFICIAL_SERVICE_SELECTED[officialServiceResponse.data.recommended.selected]];

                let packageType = TYPE_OFFICIAL;
                if((isEmpty || isMissingOfficial) && createEmpty === true){
                    selectedOfficialService = this.createDefaultLabour(officialServiceResponse.data);

                    if(isEmpty){
                        packageType = TYPE_REGULAR;
                    }
                }

                this.getLabour(selectedOfficialService, packageType)
                .then((response) => {
                    if (officialServicePackage) {
                        officialServicePackage.labours = response.data;
                        packageAdd = officialServicePackage;
                        packagesClone = packagesClone.map(p => {
                            return p.id === officialServicePackage.id ? packageAdd : p;
                        })
                    }
                    else {
                        packageAdd = defaultsDeep({
                            name: officialServiceResponse.data.name,
                            labours: response.data,
                            type: packageType
                        }, packageModel)

                        packagesClone.push(packageAdd)
                    }

                    packageAdd.officialService = defaultsDeep({
                        selected: officialServiceResponse.data.recommended.selected,
                        mileage: officialServiceData.kmStand,
                    }, officialServiceModel);

                    dispatch(packagesUpdate(packagesClone, packageAdd.id, false))
                        .then((response) => {
                            onSuccess(response)
                        })
                        .catch(error => {
                            captureException(error);
                            toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                            onError()
                        })
                })
                .catch((error) => {
                    if(!axiosDefault.isCancel(error)){
                        captureException(error);

                        toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                    }

                    this.setState({
                        officialServiceFetching: false,
                    });

                    onError()
                })
            })
            .catch(error => {
                if(!axiosDefault.isCancel(error)){
                    captureException(error);
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                }

                this.setState({
                    officialServiceFetching: false,
                });

                onError()
            })
    }

    getLabour = (labours, type) => axios.post(API_ADD_COST_LABOUR, {params: {package: {labours: labours, type: type}}})

    submit = (e) => {
        const { isValid } = this.props

        e.preventDefault();

        if(isValid){
            this.create();
        }
    }

    create = () => {
        const {officialServiceData} = this.state
        const {t, garage, values, onSubmit, onError, haxMaxPackages, _package} = this.props

        if(haxMaxPackages && !_package){
            toast.error(<Toast text={t('pages.packages.max_number', {max: garage.packages.max})} type="error"/>)
            onError();
            return;
        }

        this.setState({
            officialServiceData: {...officialServiceData, kmStand: values.km_stand}
        }, () => {
            this.fetch();

            if(onSubmit){
                onSubmit();
            }
        })
    }

    render() {
        const {officialServiceData, show, officialServiceFetching} = this.state
        const {t, onClose, handleChange, handleBlur, touched, errors, loadInBackground} = this.props

        const make = getValueFromArray(officialServiceData.car.makes, 'id', officialServiceData.car.make_id)

        return (
            <React.Fragment>
                {!officialServiceFetching ?
                    <Popup
                        title={t('pages.labour_search.official_service.popup_title', {make: make.name})}
                        visible={show}
                        onClose={onClose}
                    >
                        <form onSubmit={this.submit}>
                            <Input
                                type="tel"
                                placeholder={t('pages.labour_search.official_service.section_form.placeholder.km_stand')}
                                name="km_stand"
                                label={t('pages.labour_search.official_service.section_form.label.km_stand')}
                                value={officialServiceData.kmStand}
                                onChange={e => handleChange(e)}
                                error={touched.km_stand && errors.km_stand}
                                onBlur={handleBlur}
                                addOn="KM"
                                step={0.0001}
                                float={true}
                            />
                            <Button size="lg" type="primary">{t('pages.labour_search.official_service.section_form.submit')}</Button>
                        </form>
                    </Popup>
                    :
                    <Popup
                        title={t('pages.labour_search.official_service.fetching')}
                        visible={show && loadInBackground !== true}
                        hideCancel={true}
                        onClose={onClose}
                    >
                        <div className="mv-30 text-light text-center"><Loader type={LOADER_LOADING} isLoading={true}/> {t('global.loading')}</div>
                    </Popup>
                }
            </React.Fragment>
        )
    }
}

const OfficialServiceForm = withFormik(formOfficialServiceKmStand)(OfficialService);

const mapStateToProps = state => {
    return {
        car: state.car,
        packages: state.packages,
        garage: state.garage,
        haxMaxPackages: haxMaxPackages(state),
    }
}

export default connect(mapStateToProps)(translate('translations')(OfficialServiceForm))
