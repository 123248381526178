/* eslint-disable */
import React from 'react'
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR,
    TIRES_SEASON_ALL_SEASON,
    TIRES_SEASON_NORDIC,
    TIRES_SEASON_SUMMER,
    TIRES_SEASON_WINTER,
    TIRES_STEP_AXEL,
    TIRES_STEP_LIST_PREMIUM,
    TIRES_STEP_LIST_PRICE_QUALITY,
    TIRES_TYPE_BRAND_PREMIUM,
    TIRES_TYPE_BRAND_PRICE_QUALITY
} from "../../../store/consts/tires.constants";

export default {
    translations: {
        links: {
            home: 'Page d\'accueil',
            services: 'Services',
            garage: 'Garage',
        },
        titles: {
            system_messages: 'Messages système',
            generate_report: 'Rapport',
            customers: 'Clients',
            mechanics: 'Gestion mécaniciens',
            login: 'Se connecter',
            password_reset: 'Réinitialiser le mot de passe',
            password_reset_new: 'Activer le nouveau mot de passe',
            pick_garage: 'Choisissez un garage',

            home: 'Garage Booster',

            price_update: 'Paramètres de prix',
            price_update_hourly_price: 'Modifier le taux horaire',
            price_update_oil_price: 'Modifier le prix de l\'huile',
            price_update_liquids_price: 'Modifier le prix du liquide',
            price_update_discount: 'Modifier le rabais sur les pièces',
            price_update_price_update_additional_percent_on_works: 'Pourcentage supplémentaire sur les travaux',

            settings: 'Réglages',
            user_profile: 'Profil',

            add_cost: 'Garage Booster – sélectionnez votre véhicule',
            packages: 'Garage Booster – Liste des paquets',
            package_details: 'Garage Booster – Détails du paquet',
            labour_search: 'Garage Booster – Recherche de réparation',
            request_preview: 'Garage Booster – Check-out',
            confirm: 'Garage Booster – Demande envoyée',

            analytics: 'Statistiques',
            requests: 'Ordres',
            request_details: 'Détails de la commande',
            request_package_details: 'Détails du paquet',
            request_checklist_details: 'Liste de contrôle',

            activities: 'Activité client',
            activity: 'Activité',

            checklist_labour: 'Mastercheck travaux',
            official_service: 'Service selon les spécifications du constructeur',
            customers_upload: 'Téléchargement des clients',

            analytics_enhanced: 'Statistiques',
            analytics_enhanced_packages: 'Packages',
            analytics_enhanced_checklist: 'Mastercheck',
            analytics_enhanced_top_cars: 'Statistiques des véhicules',
            analytics_enhanced_top_labours: 'Statistiques du travail',
            analytics_enhanced_top_garage: 'Statistiques du garage',

            tires_import: 'Importation de pneus',
        },
        global: {
            app_version_out_of_date: 'Une nouvelle version de Garage Booster est disponible, veuillez cliquer ici pour mettre à jour votre application',
            app_version_out_of_date_info: 'Une nouvelle version de Garage Booster est disponible.',
            alt: {
                'request-send-success-icon': 'Succès',
            },
            tabs: {
                pending: 'En attente',
                other: 'Autre',
            },
            filter: {
                all: 'Tout',
                title: 'Filtre',
                hide: 'Masquer le filtre',
                distance: 'Distance',
                price: 'Prix',
                else: 'Autres',
                ratings: {
                    label: 'Examen des besoins',
                    description: 'Seuls les garages avec notes',
                },
                official_garage: {
                    label: 'Concession de marque officielle',
                    description: 'Uniquement concessionnaire de marque  {carBrand} officiel',
                },
                no_filters: 'Aucun filtre choisi',
                save: 'Enregistrer',
                reset: 'Réinitialiser',
            },
            illustration_card: {
                no_garages_nearby: {
                    title: 'Aucun garage trouvé dans la région qui propose les services demandés',
                    description: 'Veuillez changer votre lieu pour rechercher des offres dans d\'autres régions.',
                },
            },
            or: 'ou',
            cancel: 'Annuler ',
            back: 'Retour',
            more: 'plus',
            skip: 'Ignorer',
            currency: 'CHF',
            tax: 'T.V.A.',
            total: 'Total',
            total_with_vat: 'Total TTC',
            discount: 'Rabais',
            files: 'Photos',
            service: 'Service',
            part_number: 'Numéro d\'article:',
            to: 'à',
            km: 'km',
            loading: 'Chargement',
            garage_reinitialize: 'Changement de garage',
            save: 'Enregistrer',
            update: 'Mettre à jour',
            edit: 'Éditer',
            remove: 'Supprimer',
            car: {
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                go_back: 'Retour',
                year: 'Année de construction',
                licence_plate: 'Plaque d\'immatriculation',
                contract_number: 'Numéro de commande',
                update_info: 'Mettre à jour les informations sur la voiture',
                edit: 'Éditer',
                mileage: 'Kilométrage',
            },
            official_service: {
                title: 'RECOMMANDATION POUR VOTRE VOITURE',
                description:
                    'Grâce aux données que vous nous avez fournies, ainsi qu\'aux informations du constructeur de {{carBrand}}, nous pouvons vous recommander un service personnalisé pour votre véhicule.',
                includes: 'INCLUS',
            },
            preselected_service: {
                title: 'Services sélectionnés: ',
            },
            additional_service: {
                open: 'Des services supplémentaires',
                title: 'Réparation',
                description: 'Sélectionnez d\'autres services pour affiner votre recherche.',
                uncheck_all: 'tout déselectionner',
                save: 'Enregistrer',
            },
            msg_title: {
                error: 'Message d\'erreur',
                info: 'Message d\'information',
            },
            undo: 'Annuler',
            undo_deleted: '{{num}} supprimé',
            you_sure: 'Êtes-vous sûr?',
            months: {
                0: 'Janvier',
                1: 'Février',
                2: 'Mars',
                3: 'Avril',
                4: 'Mai',
                5: 'Juin',
                6: 'Juillet',
                7: 'Août',
                8: 'Septembre',
                9: 'Octobre',
                10: 'Novembre',
                11: 'Décembre',
            },
            agreement: 'J\'accepte les',
            terms_and_conditions: 'Termes et conditions',
            privacy_policy: 'Politique de confidentialité',
            no_data: 'Pas de données',
            optional: 'optional',
        },
        tab: {
            car: 'DONNÉES DU VÉHICULE',
            services_car: 'Ma voiture',
            services_maintenance: 'Service et réparation de voitures',
            order_overview: 'Aperçu de la commande',
        },
        form: {
            placeholder: {
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Type de boîte de vitesse',
                first_registration: 'Mise en circulation',
                qual_md_list: 'Propriétés du véhicule',
                qual_lt_list: 'Carrosserie du véhicule',
                username: 'Numéro de portable. / Entrez votre email / nom d\'utilisateur',
                username2: 'Nom d\'utilisateur',
                year: 'Mise en circulation',
                km_stand: 'Kilométrage',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                mechanic_type: 'Type',
                email: 'Email',
                phone: 'Numéro de téléphone',
                password: 'Mot de passe',
                password_confirmation: 'Confirmez le mot de passe',
                city: 'Ville',
                color: 'Couleur',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Veuillez sélectionner',
                },
                user_activity: 'Status',
                date: 'Sélectionner une date',
                morning: 'Demain',
                afternoon: 'Après midi',
                car_number: {
                    vin_swiss: 'TSN ou VIN',
                    vin_kba: 'Numéro KBA ou VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'Numéro KBA',
                    default: 'Identification de la voiture', // TODO NOT SURE FOR THIS TRANSLATE
                },
                user_type: 'Autorisation',
                mileage: 'Kilométrage',
            },
            label: {
                licence_plate: 'Plaque d\'immatriculation',
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Type de boîte de vitesse',
                first_registration: '2019',
                qual_md_list: 'Propriétés du véhicule',
                qual_lt_list: 'Carrosserie du véhicule',
                username: 'Numéro de portable. / Entrez votre email / nom d\'utilisateur',
                username2: 'Nom d\'utilisateur',
                year: 'Année de la 1ère mise en circulation',
                km_stand: 'Kilométrage',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                phone: 'Numéro de téléphone',
                email: 'Email',
                password: 'Mot de passe',
                password_confirmation: 'Confirmez le mot de passe',
                date: 'Date',
                availability: 'Disponibilité',
                bookFor: 'Réservez pour',
                book: 'Demandes',
                city: 'Ville',
                color: 'Couleur:',
                car_number: {
                    vin_swiss: 'TSN ou VIN',
                    vin_kba: 'Numéro KBA ou VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'Numéro KBA',
                    default: 'Identification de la voiture', // TODO NOT SURE FOR THIS TRANSLATION
                },
                user_type: 'Type de compte',
                mileage: 'Kilométrage',
            },
            button: {
                next: 'Continuer',
                confirm: 'Confirmer',
                submit: 'Continuer',
            },
            select: {
                no_result: 'Aucun résultat trouvé',
            },
        },
        validations: {
            label: {
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Type de boîte de vitesse',
                first_registration: 'Mise en circulation',
                qual_md_list: 'Propriétés du véhicule',
                qual_lt_list: 'Carrosserie du véhicule',
                username: 'Numéro de portable. / Entrez votre email / nom d\'utilisateur',
                username2: 'Nom d\'utilisateur',
                year: 'Année de la 1ère mise en circulation',
                km_stand: 'Kilométrage',
                car_number: 'plaque d\'immatriculation',
                avatar: 'Avatar',
                firstname: 'Prénom',
                lastname: 'Nom de famille',
                phone: 'Numéro de téléphone',
                email: 'Email',
                password: 'Mot de passe',
                password_match: 'Les mots de passe doivent correspondre',
                user_type: 'Type de compte',

                zip_not_found: 'Aucun résultat avec NPA {{name}}',
                zip_not_valid: 'Veuillez choisir un code postal valide.',
                zip_required: 'NPA est obligatoire.',
                zip_must_be_number: 'Veuillez saisir que des chiffres.',

                hourly_price: {
                    production_year: 'Année de production',
                    younger_price: 'Prix',
                },
            },
            default: {
                mixed: {
                    required: '${label} manquant',
                },
                string: {
                    email: 'Merci de consulter vos emails',
                    min: 'Valeur trop courte',
                    max: 'Valeur trop longue',
                    test: '${label} accepter',
                },
                boolean: {
                    required: '${label} accepter',
                },
            },
            termin_picker: {
                drop_off_title: 'Disponibilité pour le dépôt des véhicules',
                drop_off_description: 'Choisissez la date et l\'heure appropriées pour déposer votre voiture.',
                drop_off_description_upto:
                    'Veuillez choisir votre date et heure préférées pour déposer votre voiture.',
                maximum: 'Un maximum de 5 dates peut être sélectionné.',
                required: 'Veuillez choisir une date.',
            },
            licence_plate: {
                canton: {
                    valid: 'Canton', //Valide
                    nonValid: 'Canton', //Pas valide
                },
                number: {
                    valid: 'Numéro', //Valide
                    nonValid: 'Numéro', //Pas valide
                },
                required: 'Une plaque d\'immatriculation valide est requise',
                cantonAsString: 'Canton ne peut contenir que des lettres',
            },
            car_number: {
                invalid_format: 'Format non valide pour le numéro de certificat de type',
            },
        },
        header: {
            profile_settings: 'Paramètres de profil',
            reporting: 'Rapport',
            switch_garage: 'Changer de garage',
            settings: 'Paramètres du garage',
            price_update: 'Paramètres de prix',
            mechanic: 'Mécanicien',
            logout: 'Déconnexion',
            customers: 'Clients',
            checklists: 'Mastercheck',
            terms_and_conditions: 'Termes et conditions',
            privacy_policy: 'Politique de confidentialité',
            analytics_enhanced: 'Plus de statistiques',
            tires_upload: 'Importation de pneus',
            notifications: 'Information',
        },
        footer: {
            tabs: {
                home: 'Home',
                make_requests: 'Nouveau devis',
                requests: 'Ordres',
                analytics: 'Statistiques',
                price_update: 'Paramètres de prix',
                settings: 'Réglages',
                general_settings: 'Réglages généraux',
                mechanics: 'Mécanicien',
                news: 'Nouvelles',
            },
        },
        confirm_popup: {
            ok: 'OK',
            cancel: 'Annuler ',
        },
        user: {
            activity: {
                active: 'Activé',
                inactive: 'Désactivée',
            },
            roles: {
                manager: 'Conseiller Service',
                mechanic: 'Mécanicien',
                mechanic_advanced: 'Mécanicien avec contact client',
                supervisor: 'Superviseur',
            },
        },
        pages: {
            system_messages: {
                title: 'Messages système', //TODO: Translate
                news: 'Actualités', //TODO: Translate
                no_messages: 'Il n\'y a pas de messages', //TODO: Translate
                mark_as_read: 'Marquer comme lu', //TODO: Translate
                mark_as_unread: 'Marquer comme non lu', //TODO: Translate
                filters: {
                    all_types: 'Tous les types', //TODO: Translate
                    type_maintenance: 'Maintenance', //TODO: Translate
                    type_incident: 'Incident', //TODO: Translate
                    type_warning: 'Avertissement', //TODO: Translate
                    type_info: 'Info', //TODO: Translate
                    type_update: 'Mettre à jour', //TODO: Translate
                }
            },
            tire_prices: {
                title: 'Prix des pneus',
                run_flat: 'Run flat',
                tire_disposal_pkw: 'Élimination des pneus PKW',
                tire_disposal_4x4_llkw: 'Élimination des pneus 4x4 LLKW',
                rim_size_17_18: 'Taille de jante 17 et 18',
                rim_size_lt_16: 'Taille de jante < 17',
                rim_size_gt_19: 'Taille de jante > 18',
                save_success: 'Les prix sont enregistrés avec succès'
            },
            checklist_labours: {
                types: {
                    position: {
                        subtitle: 'Quelles positions devraient être modifiées?',
                        save: 'Enregistrer',
                    },
                    list: {
                        save: 'Enregistrer',
                    },
                },
                save: 'Enregistrer',
                archive: 'Archivé',
                add_new_labour: '+ Nouveau travail',
                ok: 'ok',
                not: 'pas',
                on_leave: 'Êtes-vous sûr de vouloir quitter?',
                note: { //TODO: Translate
                    title: 'Ajouter une nouvelle note',
                    new: 'Nouvelle remarque',
                    view: 'Voir la note',
                    label: 'Note',
                },
            },
            generate_report: {
                pick_date: 'Choisissez une date',
                title: 'Excel-Report créer',
                start_date: 'de',
                end_date: 'à',
                generate: 'Générer',
                concept_total: 'TOTAL',
            },
            checklist_labour: {
                title: 'Mastercheck',
                search: 'Chercher...',
                no_results: 'Aucun résultat.',
                save: 'Enregistrer',
            },
            customers: {
                title: 'Clients',
                search: 'Chercher...',
                edit_title: 'Modifier le client',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                email: 'E-mail',
                phone: 'Numéro de téléphone',
                home_phone: 'Numéro de téléphone domicile',
                licence_plates: 'Plaque d\'immatriculation',
                first_name_required: 'Le prénom est requis',
                last_name_required: 'Le nom est obligatoire',
                email_required: 'E-mail est requis',
                phone_required: 'Le numéro de téléphone est requis',
                no_results: 'Il n\'y a pas de clients avec des critères de recherche.',
                name: 'Nom',
                customers_upload: 'Téléchargement des clients',
                file_upload_started: 'Le téléchargement a commencé, veuillez patienter.',
                file_upload_success: 'Fichier téléchargé avec succès.',
                file_upload_error: 'Un problème est survenu.',
                file_upload_missing: 'Le fichier est manquant. Veuillez d\'abord choisir un fichier.',
                upload: 'Télécharger',
                customers_upload_title: 'Téléchargement des clients',
                loading_upload_message: 'Téléchargement en cours. S\'il vous plaît, attendez.',
                resolve_duplications: 'Résoudre les duplications',
                skip: 'Ignorer',
                add: 'Ajouter',
                overwrite: 'Modifier',
                resolve_duplications_in_progress: 'Résolution des doublons en cours...',
                choose_file: 'Choisir le fichier',
            },
            home: {
                title: 'Garage Booster',
                new_requests: 'Nouvelles commandes:',
            },
            mechanics: {
                title: 'Gestion mécaniciens',
                list_is_empty: 'La liste des mécaniciens est vide',
                add_new_person: 'Ajouter un nouveau mécanicien',
                change_person_details: 'Changer les données personnelles',
            },
            requests: {
                title: 'Ordres',
                mechanic: 'Mécanicien',
                created_by: 'Créé par',
                no_results: 'Aucune commande trouvée',
                waiting_for_response: 'Attendre une réponse',
                no_requests: 'Aucune commande',
                last_updated: 'Dernier changement',
                termin: 'Date de rendez-vous',
                test: 'Test',
                tab: {
                    pending_manager: 'Pour approbation',
                    pending_user: 'En attente de clients',
                    responded: 'Répondu',
                    archived: 'Archivé',
                },
                form: {
                    label: {
                        search: 'Chercher',
                    },
                    placeholder: {
                        search: 'Recherche par client ou voiture',
                    },
                },
            },
            request_details: {
                print_pdf: 'Imprimer PDF',
                title: 'Détails de la commande',
                no_packages: 'Aucun forfait',
                approve_request: 'Autoriser',
                reject_request: 'Décliner',
                edit_request: 'Changer l\'ordre',
                edit_package: 'Modifier le paquet',
                remind_user: 'Rappeler au client',
                duplicate_request: 'Créer une autre demande',
                tab: {
                    accepted: 'Accepté',
                    rejected: 'Refusé',
                },
                package: {
                    title: 'Détails du paquet',
                    labours: {
                        title: 'Travaux à effectuer sur le véhicule',
                    },
                    works: {
                        title: 'Main-d’œuvre',
                    },
                    parts: {
                        title: 'Pièces détachées',
                    },
                    tires: {
                        title: 'Pneus',
                        mounting_total_price: 'Montage des pneus (y compris l\'équilibrage)',
                    },
                    client: {
                        title: 'Client',
                    },
                    vehicle: {
                        title: 'Véhicule',
                    },
                    files: {
                        title: 'Annexes',
                    },
                    log: {
                        title: 'Registre',
                        status: {
                            1: 'Demande ouverte',
                            2: 'En attente de Conseiller Service',
                            3: 'Envoyé au client',
                            4: 'Le client a répondu',
                            41: 'Confirmé par le garage',
                            5: 'Rejeté par le manager',
                            6: 'Archivé',
                        },
                    },
                    sms_replies: {
                        title: 'Réponses SMS',
                    },
                    description: {
                        title: 'Description',
                    },
                    additional_percent_on_works: 'Supplément petites pièces sur travaux',
                    packages: {
                        title: 'Détails de la commande',
                    },
                    packages_accepted: {
                        title: 'Accepté',
                    },
                    packages_rejected: {
                        title: 'Refusé',
                    },
                    packages_opened: {
                        title: 'Ouvert',
                    },
                    packages_status: {
                        title: 'Statut de la commande',
                    },
                    response_time: {
                        title: 'Temps de réponse',
                    },
                },
                client: {
                    approve: {
                        title: 'Données client',
                        subtitle: 'Entrez les détails du client pour envoyer la demande',
                    },
                    remind: {
                        title: 'Données client',
                        subtitle: 'Entrez les détails du client pour envoyer la demande',
                    },
                },
                sms: {
                    in_progress: 'SMS envoyé',
                    delivered: 'SMS livré',
                },
                appointment_discount: 'Remise pour les rendez-vous ultérieurs',
                user_appointment: 'Date de rendez-vous',
                total_accepted_with_vat: 'Total accepté TVA incl. ',
                read_more: 'Suite',
                read_less: 'Moins',
                car_number: {
                    ch: 'TSN ou VIN',
                    de: 'KBA-Numéro ou VIN',
                },
                checklist_link: 'Mastercheck',
                checklist: {
                    title: 'Mastercheck',
                    ok: 'ok',
                    not: 'pas',
                    print_pdf: 'Imprimer PDF',
                },
                archive: {
                    label: 'Archiver'
                },
                is_test: 'Demande marquée comme test',
                mark_as_test: 'Marquer comme test',
                unmark_as_test: 'Démarquer comme test',
            },
            analytics: {
                title: 'Statistiques',
                mechanics_sub: 'Mécanicien',
                statues: {
                    open: 'En attente',
                    accepted: 'Accepté',
                    rejected: 'Refusé',
                    created: 'Envoyés au client',
                    all_passed: 'Entièrement ok',
                },
                start_date: 'de',
                end_date: 'à',
                days_per_column: 'Jours par colonne: {{days}}',
                pick_date: 'Choisissez une date',
                filter_button: 'Afficher',
                total_requested: 'Total',
                requested: 'Demandé',
                accepted: 'Accepté',
                rejected: 'Refusé',
                sum_prices: 'Accepté ou rejeté au cours des {{months_num}} derniers mois',
                sum_prices_for_period: 'Travaux approuvés vs refusés pour la période {{date_from}} à {{date_to}}',
                sum_packages_by: 'Valeur du travail par statut au cours des {{months_num}} derniers mois',
                sum_packages_for_period: 'Valeur des travaux par statut pour la période {{date_from}} à {{date_to}}',
                top_ranges: 'Modèles de voitures les plus fréquents',
                top_labours: 'Travaux les plus fréquents',
                titles: {
                    packages: 'Paquets',
                    statistics: 'Statistiques',
                    most_requested: 'Le plus demandé',
                    checklists: 'Mastercheck',
                },
                filters: {
                    users: 'Statistiques',
                    all: 'Total garage',
                    mine: 'Moi',
                    last_months: '{{months}} mois',
                },
                garage_reinitialize: {
                    label: 'Changer de garage',
                    concept_total: 'TOTAL',
                },
                more_statistics: 'Plus de statistiques',
            },
            login: {
                title: 'Se connecter',
                submit: 'Se connecter',
                forget_password: 'Mot de passe oublié?',
            },
            password_reset: {
                title: 'Réinitialiser le mot de passe',
                tip: 'Entrez votre numéro de téléphone portable ou votre adresse e-mail pour réinitialiser le mot de passe.',
                submit: 'Réinitialiser le mot de passe',
                cancel: 'Annuler ',
            },
            password_reset_new: {
                title: 'Activation d\'un nouveau mot de passe',
                tip: 'Entrez votre nouveau mot de passe dans les champs ci-dessous.',
                submit: 'Confirmez le mot de passe',
            },
            pick_garage: {
                title: 'Choisissez un garage',
                tip: 'Veuillez sélectionner un atelier auquel vous souhaitez vous inscrire',
            },
            price_update: {
                title: 'Paramètres de prix',
                section_tabs: {
                    tab_hourly_rate: 'Taux horaire',
                    tab_oil: 'Huiles',
                    tab_liquids: 'Liquides',
                    tab_discount: 'Rabais ',
                    tab_additional_percent_on_works: 'Supplément petites pièces',
                    tab_tires: 'Pneus / roues',
                    tab_cleaning: 'Nettoyage',
                    tab_clima: 'Climatisation',
                    tab_other: 'Autre',
                    tab_tip_hourly_price: 'Pour toutes vos marques',
                    tab_tip_oil: '5w40, 10w30, ...',
                    tab_tip_liquids: 'Frein / liquide de refroidissement',
                    tab_tip_discount: 'Rabais sur les pièces',
                    tab_tip_additional_percent_on_works: 'Supplément petites pièces sur travaux',
                    tab_tire_change: 'Changement de pneus',
                    tab_tip_tire_change: 'Changement de pneus'
                },
                section_form: {
                    label_hourly_rate: 'Taux horaire',
                    label_option_all_makes: 'Toutes les marques',
                    button_add_price: 'Ajouter un taux horaire',
                    button_add_discount: 'Ajouter une nouvelle remise',
                    button_save: 'Enregistrer',
                    button_uploading: 'Chargement...',
                    service_not_offered: 'N\'offrez pas ce service',
                    hourly_price_remove: 'Supprimer',
                    label_car_make: 'Marque',
                    label_production_year: 'Âge du véhicule',
                    label_younger_price: 'Plus jeune que sélectionné',
                    label_younger_discount: 'Plus jeune que sélectionné',
                    placeholder_younger_price: 'Prix',
                    change: 'Éditer',
                    remove: 'Supprimer',
                    add_price: 'Ajouter un prix',
                    label_discount: 'Rabais',
                    label_show_appointments_discount: 'Autoriser l\'option pour les futurs rendez-vous',
                    label_additional_percent_on_works: 'Supplément petites pièces',
                },

                hourly_price: {
                    title: 'Taux horaire',
                    empty_list: 'La liste de prix est vide.',
                },
                oil_price: {
                    title: 'Prix du huiles',
                },
                liquids_price: {
                    title: 'Prix liquide',
                },
                discount: {
                    title: 'Rabais sur les pièces',
                    appointments_title: 'Rabais sur les dates futures',
                    parts_title: 'Rabais sur les pièces',
                    brakes_title: 'Rabais sur les freins',
                    empty_list: 'Aucune réduction enregistrée',
                },
                price_update_additional_percent_on_works: {
                    title: 'Supplément petites pièces',
                },

                years: {
                    all_years: 'Toutes les années de production',
                    '2_years_younger': '2 ans et moins',
                    '2_years_older': 'Plus de 2 ans',
                    '3_years_younger': '3 ans et moins',
                    '3_years_older': 'Plus de 3 ans',
                    '4_years_younger': '4 ans et moins',
                    '4_years_older': 'Plus de 4 ans',
                    '5_years_younger': '5 ans et moins',
                    '5_years_older': 'Plus de 5 ans',
                    '6_years_younger': '6 ans et moins',
                    '6_years_older': 'Plus de 6 ans',
                },
            },
            settings: {
                title: 'Réglages',
                section_tabs: {
                    change_details: 'Modifier les détails',
                    phones: 'Numéro de contact (le numéro sera indiqué au client)',
                    time_due: 'Temps de réponse jusqu\'au',
                    communication: 'Notifications',
                    customer_signature: 'Signature affichée au client',
                    mastercheck_layout: 'Choisissez la disposition de mastercheck'
                },
                section_form: {
                    label: {
                        address: 'Adresse:',
                        web: 'Site Internet:',
                        zip_id: 'Code postal:',
                        logo: 'Logo:',
                        notification_phone: 'Numéro de portable:',
                        notification_email: 'E-mail:',
                        time_due: 'Temps de réponse jusqu\'au:',
                        customer_signature: 'Signature affichée au client',
                    },
                    placeholder: {
                        address: 'Adresse:',
                        web: 'Site Internet:',
                        zip_id: 'Code postal:',
                        logo: 'Logo:',
                        email: 'E-mail',
                        phone: 'Numéro de portable',
                        time_due: 'Temps de réponse jusqu\'au',
                        customer_signature: 'Signature affichée au client',
                    },
                    logo_change: 'Changer',
                    logo_upload: 'Télécharger le logo',
                    avatar_upload: 'Télécharger un avatar',
                    avatar_change: 'Changer d\'avatar',
                    submit_user_details: 'Enregistrer',
                    notification_detail:
                        'Répertoriez tous les destinataires affectés pour recevoir des notifications.',
                    phones_detail: 'Le numéro est affiché au client.',
                    add_new_phone: 'Ajouter un nouveau numéro',

                    file_size: 'Fichier trop gros',
                    file_format: 'Format non supporté',

                    disable_time_due: 'Désactiver',

                    communication: {
                        vonage_multiple: 'SMS',
                        mail: 'E-mail',
                        push_notification: 'Notification push',
                    },
                    communication_required: 'Au moins un canal de notification est requis',
                },
                section_popup: {
                    header_remove: 'Voulez-vous vraiment supprimer le destinataire?',
                    header_add: 'Ajouter un nouveau destinataire',
                    header_update: 'Mettre à jour le destinataire',
                    remove: 'Supprimer',
                },
            },
            user_profile: {
                title: 'Profil de l\'utilisateur',
                section_tabs: {
                    default_language: 'Choisissez la langue par défaut',
                    change_password: 'Changer le mot de passe',
                    change_details: 'Modifier les détails',
                },
                section_form: {
                    label: {
                        current_password: 'Ancien mot de passe',
                        new_password: 'Nouveau mot de passe',
                        repeat_password: 'Confirmer le nouveau mot de passe',
                        notification_phone: 'Numéro de portable:',
                        notification_email: 'E-mail:',
                        first_name: 'Prénom',
                        last_name: 'Nom de famille',
                        email: 'E-mail',
                        phone: 'Numéro de portable',
                        username: 'Nom d\'utilisateur',
                    },
                    placeholder: {
                        current_password: 'Entrez l\'ancien mot de passe',
                        new_password: 'Entrer un nouveau mot de passe',
                        repeat_password: 'Confirmer le nouveau mot de passe',
                        notification_email: 'Entrez un nouveau courriel',
                        notification_phone: 'Entrez un nouveau numéro de mobile',
                        first_name: 'Entrez votre prénom',
                        last_name: 'Entrez le nom',
                        email: 'Entrez l\'email',
                        phone: 'Entrez le numéro de mobile',
                        username: 'Nom d\'utilisateur',
                    },
                    avatar: {
                        change: 'éditer',
                        crop: {
                            select: 'Télécharger une photo',
                            zoom: 'Agrandir',
                            rotate: 'Tourner',
                            save: 'Enregistrer',
                            cancel: 'Annuler',
                            title: 'Recadrer l\'image',
                        },
                    },
                    submit_new_password: 'Changer le mot de passe maintenant',
                    submit_user_details: 'Enregistrer',

                    file_size: 'Fichier trop gros',
                    file_format: 'Format non supporté',
                },
            },
            add_cost: {
                title: 'Sélectionnez un véhicule:',
                select_method: 'Veuillez sélectionner l\'une des méthodes suivantes pour trouver une voiture.',
                method_licence_plate: 'Plaque d\'immatriculation',
                method_licence_plate_tip: 'Il vous sera demandé de saisir uniquement le canton et le numéro de plaque',
                method_swiss: 'TSN',
                method_swiss_tip: 'Trouvez votre voiture en entrant TSN',
                method_manual: 'Manuel',
                method_manual_tip: 'Trouvez votre voiture en entrant les détails manuellement',

                method_title_lp: 'Trouver une voiture par plaque d\'immatriculation',
                method_title_swiss: 'Trouvez votre voiture en entrant TSN',
                method_title_multiple: 'Choisissez la couleur de votre voiture',
                method_title_manual: 'Entrez les détails manuellement',

                licence_plate: {
                    title: 'Entrez les informations de votre voiture et obtenez des offres instantanées.',
                    manual: 'Numéro d’immatriculation inconnu ?',
                    sourcing: 'Acquisition d\'informations sur le véhicule',
                    sourced_success: 'Nous avons obtenu avec succès des informations sur le véhicule.',
                },
                car_number: {
                    vin_swiss: 'Vous ne connaissez pas le VIN ou TSN?',
                    vin_kba: 'Vous ne connaissez pas le VIN ou KBA?',
                    vin: 'Vous ne connaissez pas le VIN?',
                    swiss: 'Vous ne connaissez pas le TSN?',
                    kba: 'Vous ne connaissez pas le KBA?',
                    default: 'Vous ne connaissez pas le VIN ou TSN?',
                },
                form_sidebar_label_1: 'Garantie de 2 ans sur les travaux et les pièces',
                form_sidebar_label_2: 'Comparaison de plus de 450 garages',
                form_sidebar_label_3: 'Annulation gratuite',
                licence_plate_tooltip:
                    'Le véhicule est identifié à l\'aide de la source suivante: Office fédéral des routes OFROU.',

                swiss: {
                    manual: 'Entrez les détails manuellement',
                },
                packages_reset: {
                    title: 'Redémarrer les paquet',
                    description: 'Chargement',
                },
                draft: {
                    title: 'Dessins et modèles:',
                    loading: 'Veuilez attendre svp',
                    fetching_list: 'Veuilez attendre svp',
                    show_less: 'moins',
                    show_more: 'plus',
                },
                tab_change: {
                    title:
                        'Si vous quittez cette page, l\'ordre du brouillon sera décalé. Êtes-vous sûr?',
                    accept: 'En quittant',
                    cancel: 'Restez sur le côté',
                },
                search_user: {
                    section_form: {
                        label: {
                            search: 'Client existant',
                        },
                        placeholder: {
                            search: 'Nom ou prénom du client',
                        },
                    },
                },
            },
            packages: {
                title: 'Liste des paquets',
                selected_packages: 'Travaux sélectionnés:',
                show_more: 'Plus',
                show_less: 'Moins',
                add_item: '+ Ajouter des œuvres connexes',
                extend_properties: 'urgences/données',
                edit: 'Supprimer',
                add_new_labour: '+ Nouveau travail',
                add_new_official_service: '+ Nouveau service',
                add_tires: '+ Changement de pneus',
                next: 'Suivant',
                total: 'TOTAL',
                total_incl_vat: 'Total TVA incluse',
                remove: 'Supprimer',
                missing_works_parts: 'Certaines pièces ou certains prix peuvent être manquants',
                empty_list: 'Aucun travail ajouté.',
                confirm_car_update: {
                    title: 'Etes-vous sûr ?',
                    subtitle: 'Un changement de véhicule réinitialise les travaux sélectionnés. Etes-vous sûr ?',
                },
                confirm_remove: {
                    title: 'Etes-vous sûr?',
                    subtitle: 'Voulez-vous supprimer l\'œuvre ?',
                },

                package_name: 'Paquet',
                service_package_name: 'Entretien conforme aux spécifications du constructeur {{make}}',
                checklist: 'Mastercheck',
                or: 'ou',
                max_number: 'Nombre maximal de paquets {{max}} par demande.',
                edit_official: 'Éditer',
                edit_tires: 'Éditer',
                official_package_mileage: ' - kilométrage {{mileage}}km',
            },
            package_details: {
                title: 'Détails du paquet',

                level_of_urgency: 'Urgence',
                add: 'Ajouter',
                edit: 'Éditer',
                change: 'Changement',
                add_edit: 'Éditer',
                file_uploads: 'Téléchargement d\'images',
                no_files: 'Pas de fichiers',
                no_description: 'Pas de description',
                attach_new_file: 'Joindre une nouvelle image',
                available_works: 'Travaux disponibles',
                available_parts: 'Pièces disponibles',
                more: 'Plus',
                details: 'Détails',
                remove: 'Supprimer',
                removing: 'supprimer...',
                missing_work: 'Il est possible que le travail manque',
                missing_parts: 'Cliquez sur "details" pour ajouter des travaux ou des pièces',
                add_discount: 'ajouter une rabais',

                total: 'Total',
                discount_on_parts: 'Rabais sur les pièces',
                discount_on_brakes: 'Rabais sur les freins',
                discount_package: 'Rabais',
                total_all: 'Total, TVA et rabais inclus',
                price_fixed: 'Forfait',
                official_package_mileage: ' - kilométrage {{mileage}}km',

                urgency: {
                    title: 'Urgence',
                    subtitle: 'Choisissez le degré d\'urgence de ce travail supplémentaire.',
                    urgency_safety_relevant: 'Sécurité',
                    urgency_urgent: 'Important',
                    urgency_recommended: 'Recommandé',
                },

                files: {
                    title: 'Chargement des données',
                    skip: 'sauter',
                    see_file: 'Ouvrir le dossier',
                    see_video: 'vidéo ouverte',
                    file_size: 'Fichier trop volumineux. La taille maximale est de {{size}} MB',
                    file_format: 'Format non pris en charge. Soutenu:{{{format}}',
                    files_max: 'Le nombre maximum de fichiers est de {{max}}',
                    record: 'Dossier',
                    audio: 'Fichier audio',
                    image: 'Image',
                    record_audio: {
                        title: 'Enregistrer un mémo vocal',
                        rec: 'Rec',
                        cancel: 'Annuler',
                        pause: 'Pause',
                        stop: 'Stop',
                        save: 'Enregistrer',
                    },
                    remove: {
                        title: 'Supprimer le fichier ?',
                    },
                },

                description: {
                    title: 'Ajouter une description',
                    item: 'Description',
                    not_added: 'Pas encore ajouté',
                    subtitle: 'Sélectionnez le niveau d\'importance des nouveaux coûts supplémentaires.',
                },

                discount: {
                    title: 'Ajouter un rabais',
                    section_form: {
                        label: {
                            name: 'Nom',
                            price: 'Prix réduit({{devise}})',
                        },
                        placeholder: {
                            name: 'Nom',
                            price: 'Prix',
                        },
                    },
                },

                confirm_edit: {
                    title: 'Êtes-vous sûr?',
                    subtitle:
                        'L\'ajout d\'un nouveau travail peut écraser certains changements que vous avez effectués dans votre travail précédent',
                },

                labour: {
                    works: {
                        title: 'Travaux disponibles',
                        add_more: '+ Ajouter du travail',
                        list: {
                            title: 'Liste de travail',
                        },
                        section_form: {
                            label: {
                                name: 'Nom',
                                description: 'Description',
                                duration: 'Durée',
                            },
                            placeholder: {
                                name: 'Entrez le nom de l\'œuvre',
                                description: 'Description',
                                duration: 'Saisir la durée du travail',
                            },
                        },
                        edit: {
                            title: 'Travail d\'édition',
                        },
                        add: {
                            title: 'Ajouter du travail',
                        },
                    },
                    parts: {
                        title: 'Pièces',
                        add_more: '+ Ajouter d\'autres pièces',
                        versions: {
                            title: 'Pièces',
                        },
                        set: {
                            title: 'Modifier l\'ensemble des pièces',
                            section_form: {
                                label: {
                                    name: 'Nom',
                                    price: 'Prix',
                                    amounts: 'Quantité',
                                },
                                placeholder: {
                                    name: 'Entrez le nom de l\'ensemble de pièces',
                                    price: 'Entrez le prix de la pièce',
                                    amounts: 'Indiquez la quantité de pièces fixées',
                                },
                            },
                        },
                        add: {
                            title: 'Ajouter une nouvelle pièce',
                        },
                    },
                },
            },
            labour_search: {
                title: 'Recherche et sélection des travaux',
                tip_on_no_result:
                    'Une fois que vous l\'aurez ajouté, il sera enregistré dans votre liste pour que vous puissiez choisir à l\'avenir.',

                form: {
                    label: 'Recherchez',
                    placeholder: 'Remplacer, Air, Freiner...',
                    no_result: 'Aucune entrée trouvée.',
                },
                package: {
                    title: 'Paquet',
                    subtitle: 'Ce poste sera ajouté au même coût supplémentaire.',
                },
                no_result_tip: 'Nous n\'avons trouvé aucun résultat dans la liste suivante :',
                new_package: '+ nouveau',
                add_no_result: 'Sélectionner et ajouter à la liste',
                new_labour_data_title: 'Récupération des données relatives au travail',
                new_labour_loading_title: 'Créer un nouveau travail',
                new_labour_loading: 'Sera chargé',
                created: 'Travail créé avec succès',

                official_service: {
                    section_form: {
                        label: {
                            km_stand: 'Kilométrage',
                        },
                        placeholder: {
                            km_stand: 'Kilométrage',
                        },

                        submit: 'Suivant',
                    },
                    not_exist: 'Official service does not exist',
                    popup_title: 'Entretien conforme aux spécifications du constructeur {{make}}',
                    fetching: 'Récupération des services officiels',
                },
            },
            request_preview: {
                title: 'Page de commande',
                send: 'Envoyer la demande',
                book_now: 'Confirmer le devis maintenant',
                checkout: 'Page de commande',
                car_details: 'Détails du véhicule',
                customer_details: 'Détails du client',
                official_package_mileage: ' - kilométrage {{mileage}}km',
                today: 'Autoriser un rendez-vous pour aujourd\'hui',

                section_form: {
                    label: {
                        first_name: 'Prénom',
                        last_name: 'Nom',
                        email: 'E-mail',
                        phone: 'Numéro de téléphone portable',
                        time_due: 'Temps de réponse jusqu\'à',
                        contract_number: 'ID de contrat (facultatif)',
                        comment: 'Commentaire',
                    },
                    placeholder: {
                        first_name: 'Entrez le prénom',
                        last_name: 'Entrez le nom',
                        email: 'Entrez l\'adresse e-mail',
                        phone: 'Entrez le numéro de téléphone portable',
                        contract_number: 'ID de contrat (facultatif)',
                        comment: 'Commentaire',
                    },
                    oneOfRequired: 'Numéro de téléphone portable ou e-mail requis',

                    skip_time_due: 'Ignorer',
                },

                appointment_discounts: {
                    show_appointments: 'Afficher les dates',
                    edit: {
                        title: 'Modifier le rabais',
                        link: 'Modifier le rabais',
                        save: 'Enregistrer',
                        tip: 'Actualise votre rabais pour les dates futures. Le changement affectera toutes les dates sélectionnées et sera enregistré.',
                    },
                    header: {
                        termin: 'Date',
                        discount: 'Remise',
                        total: 'Total',
                    },
                    list: {
                        is_today: 'Aujourd\'hui',
                    },
                },
                user_exists: {
                    email: 'Le courrier électronique existe. Sélectionner un client existant?',
                    phone: 'Le numéro existe. Sélectionner un client existant?',
                },
            },
            confirm: {
                title: 'La demande a été envoyée',
                headline: 'Demande envoyée avec succès',
                description:
                    'Votre demande a été envoyée avec succès au client. Attendez-vous à être averti par e-mail / SMS dès qu\'il répond.',
                description_mechanic: 'Votre demande a été envoyée avec succès au représentant du service clientèle.',
                go_home: 'Retour au tableau de bord',
                new_request: 'Nouveau devis',
                book_now_headline: 'Demande enregistrée avec succès',
                book_now_description: 'Votre demande a été enregistrée avec succès. Vous pouvez commencer le travail maintenant.',
            },
            activities: {
                title: 'Activité des clients',
                segment: {
                    today: 'Aujourd\'hui',
                    yesterday: 'Hier',
                    older: 'Plus ancien',
                },
                section_form: {
                    placeholder: {
                        search: 'Recherchez',
                    },
                },
                last_activity: 'Dernière activité',
                at: 'à',
            },
            activity: {
                title: 'Activité',
                segment: {
                    today: 'Aujourd\'hui',
                    yesterday: 'Hier',
                },
                section_form: {
                    placeholder: {
                        message: 'Ecrire avec le client',
                    },
                },
                type: {
                    manager_waiting: {
                        name: 'Mécanicien {{first_name}} {{last_name}}',
                        body: 'a envoyé de nouveaux travaux pour approbation',
                    },
                    manager_created: {
                        name: 'Conseiller service {{first_name}} {{last_name}}',
                        body: 'a créé une nouvelle demande.',
                    },
                    manager_accepted: {
                        name: 'Conseiller service {{first_name}} {{last_name}}',
                        body: 'a accepté l\'œuvre que vous avez créée',
                    },
                    manager_rejected: {
                        name: 'Conseiller service {{first_name}} {{last_name}}',
                        body: 'a rejeté l\'œuvre que vous avez créée',
                    },
                    sms_garage_reply: {
                        name: 'Conseiller service {{first_name}} {{last_name}}',
                    },
                    sms_user_reply: {
                        name: '{{first_name}} {{last_name}}',
                    },
                    user_responded: {
                        name: 'Client {{first_name}} {{last_name}}',
                        accepted: 'accepté  {{hasAccepted}} paquets sur {{length}}',
                        rejected: 'a rejeté le paquet',
                    },
                    details: 'Voir les détails',
                },
                new_event: 'Nouvel événement',
            },
            official_service: {
                title: 'Entretien selon spécifications du constructeur',
                total_official: 'Total TVA incluse',
                submit: 'Suivant',
                no_recommended: 'Nous n’avons pas pu trouver de prestation pour le véhicule sélectionné.',
                or_more: 'ou plus',
                info: {
                    ok: 'OK',
                },
                service_interval_title: 'Entretien à ',
                show_more: 'Plus',
                show_less: 'Moins',
            },
            analytics_enhanced: {
                title: 'Statistiques',

                export: 'Exporter vers Excel',
                packages: 'Packages',
                checklist: 'Mastercheck',
                top_cars: 'Statistiques des véhicules',
                top_labours: 'Statistiques du travail',
                top_garage: 'Statistiques du garage',

                packages_info: 'Accepté / Rejeté',
                checklist_info: 'Contrôles principaux traités',
                top_cars_info: 'Top modèles de voitures',
                top_labours_info: 'Top work',
                top_garage_info: 'Top Garages',

                date_picker: {
                    from: 'De',
                    to: 'à',
                },
                lists: {
                    top_10_by_revenue: 'TOP 10 - le plus de revenus',
                    worst_10_by_revenue: 'TOP 10 - le moins de revenus',
                    top_10_by_checklists: 'TOP 10 des contrôles principaux effectués ',
                },
                column_names: {
                    accepted: 'Ventes',
                    percentage: 'Quota',
                    requests: 'demande',
                    revenue: 'revenu',
                    total: 'Offres suggérées',
                    checklists: 'Mastercheck',
                },
            },
            tires_import: {
                title: 'Importation de pneus',
                importing_in_progress: 'Importation en cours...',
                label: 'Choisissez le fichier EXCEL',
                btn: 'Importer',
                import_started: 'Les pneus sont importés. Vous serez averti lorsque c\'est fait.',
                import_success: 'Les pneus sont importés avec succès.',
                import_failed: 'Une erreur s\'est produite lors de l\'importation des pneus.',
            },
        },
        notification: {
            empty_list: 'aucune notification',
            manager_waiting: 'a envoyé de nouveaux travaux pour approbation',
            manager_accepted: 'a accepté l\'œuvre que vous avez créée',
            manager_rejected: 'a rejeté l\'œuvre que vous avez créée',
            user_responded: {
                accepted: 'accepté  {{accepted}} à partir des paquets {{packagesLength}}',
                rejected: 'a rejeté le paquet',
            },
            sms_reply: 'Nouveau message du client {{first_name}} {{last_name}}',
            details: 'Détails',
            segment: {
                today: 'Aujourd\'hui',
                yesterday: 'Hier',
                this_week: 'Cette semaine',
            },
            make_all_as_read: 'Tout marquer comme lu',
        },
        profile_progress: {
            title: {
                garage: 'Garage',
                profile: 'Profil',
                prices: 'Des prix',
            },
            item: {
                garage: {
                    logo: 'Logo',
                    address: 'Adresse',
                    web: 'site Internet',
                    zip: 'Code postal',
                    contact_phones: 'Téléphone de contact',
                },
                profile: {
                    first_name: 'Prénom',
                    last_name: 'Nom de famille',
                    email: 'E-mail',
                    username: 'Nom d\'utilisateur',
                    phone: 'Téléphone',
                    avatar: 'Avatar',
                },
                prices: {
                    hourly: 'Taux horaire',
                    oil: 'Huiles',
                    liquids: 'Liquides',
                },
            },
            info: 'Configuration du profil {{percent}}%. Cliquez ici pour afficher.',
            text_loading: 'Configuration chargement...',
        },
        tires: {
            brand_type: {
                [TIRES_TYPE_BRAND_PREMIUM]: 'Premium',
                [TIRES_TYPE_BRAND_PRICE_QUALITY]: 'Prix/Qualité',
            },
            step_title: {
                [TIRES_STEP_LIST_PREMIUM]: 'Premium',
                [TIRES_STEP_LIST_PRICE_QUALITY]: 'Prix/Qualité',
            },
            axel: {
                [TIRES_AXEL_FRONT]: 'Avant',
                [TIRES_AXEL_REAR]: 'Arrière',
                both: 'Avant + Arrière',
            },
            season: {
                [TIRES_SEASON_SUMMER]: 'Été',
                [TIRES_SEASON_WINTER]: 'Hiver',
                [TIRES_SEASON_ALL_SEASON]: 'All Season',
                [TIRES_SEASON_NORDIC]: 'Nordic',
            },

            filter_title_both: 'Pneus avant + arrière',
            filter_title_front: 'Pneus avant',
            filter_title_rear: 'Pneus arrière',

            filter_index_all: 'Tous les index',

            package_name: 'Changement de pneus',

            ean: 'EAN',
            sap: 'SAP',

            label: {
                wide: 'Largeur',
                depth: 'Taille',
                diameter: 'Diamètre',

                season: 'Saison',
                index: 'Index',
                brand: 'Marque',

                front_axel: 'Essieu avant',
                rear_axel: 'Essieu arrière',

                same_dimension: 'Mêmes dimensions',
            },

            header: {
                brand: 'Marque',
                index: 'Index',
                price: 'Prix',
            },

            no_tires: 'Pas de pneus pour ce critère',
        },
        toast: {
            success: 'Merci',
            error: 'Oops!',
            info: 'Info',
            warning: 'Avertissement',
            app_version: 'Une nouvelle version de Garage Booster est disponible.',
        },
        toast_notifications: {
            prices_updated: "Prices are updated"
        },
        toast_system_messages: {
            maintenance: 'Maintenance',
            incident: 'Incident',
            warning: 'Attention',
            info: 'Info',
        },
        language: {
            en: 'Anglais',
            de: 'Allemand',
            fr: 'Français',
        },
        error_recovery: {
            title: 'Notre système a rencontré une erreur.',
            btn: 'Cliquez ici pour rafraîchir la page',
        }
    },
}
