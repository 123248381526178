import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Input from "components/shared/Input/Input";
import {withFormik} from "formik";
import {formWorkEdit} from "app/config/yup";
import Button from "components/shared/Button/Button";
import {compare} from "utils/common";
import {usePrevious} from "components/hooks/utils";
import {packagesUpdate} from "store/actions/packages.actions";
import {updateObject} from "utils/common";
import {parseDeepPath} from "../../../utils/common";

const WorkEdit = props => {

    const { t, handleBlur, touched, errors, handleChange, isValid, packagePath, packages, dispatch, formSubmitting, onClose } = props;

    const [ work, updateWork ] = useState(props.work);
    const [ workUpdated, setWorkUpdated ] = useState(false);

    const prevWork = usePrevious(props.work);
    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if(compare(work, props.work)){
            updateWork(props.work);
        }
    }, [props.work]);

    useEffect(() => {
        setWorkUpdated((work && prevWork && compare(work, prevWork)));
    }, [work]);

    useEffect(() => {
        if(prevPackages){
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        if(workUpdated && isValid){
            dispatch(packagesUpdate(updateObject(packages, work, packagePath), parseDeepPath(packagePath)[0].id));
        }
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>
                <Input
                    type="text"
                    placeholder={t('pages.package_details.labour.works.section_form.placeholder.name')}
                    name="name"
                    label={t('pages.package_details.labour.works.section_form.label.name')}
                    value={work.name}
                    onBlur={handleBlur}
                    onChange={e => {updateWork({...work, name: e.target.value}); handleChange(e)}}
                    error={touched.name && errors.name}
                />

                <Input
                    type="tel"
                    placeholder={t('pages.package_details.labour.works.section_form.placeholder.duration')}
                    name="duration"
                    label={t('pages.package_details.labour.works.section_form.label.duration')}
                    value={work.duration}
                    onBlur={handleBlur}
                    onChange={e => {updateWork({...work, duration: e.target.value.parseFloat()}); handleChange(e)}}
                    error={touched.duration && errors.duration}
                    addOn="H"
                    step={0.0001}
                    float={true}
                />

                <Button size="lg" type="primary" disabled={formSubmitting.packages || !workUpdated}>{t('global.save')}</Button>
            </form>

        </React.Fragment>
    );
};

const WorkEditForm = withFormik(formWorkEdit)(WorkEdit);

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(WorkEditForm));
