export const initPushNotifications = () => {
    if ('Notification' in window) {
        Notification.requestPermission(result => {
            if (result !== 'granted') {
                console.warn('No notification permission granted!')
            }
        })
    }
}

export const sendPushNotifications = data => {
    if ('serviceWorker' in navigator && 'Notification' in window) {
        navigator.serviceWorker
            .register('/sw.js')
            .then(reg => {
                try {
                    reg.addEventListener('updatefound', () => {
                        let installingWorker = reg.installing
                        installingWorker.onstatechange = state => {
                            if (state.currentTarget.state === 'activated') {
                                sendPushNotifications(data)
                            }
                        }
                    })

                    if ('active' in reg && reg.active && reg.active.state === 'activated') {
                        reg.showNotification(data.title, {
                            body: data.body,
                            icon: '/ms-icon-310x310.png',
                            tag: JSON.stringify({
                                url: data.url,
                            }),
                        })
                    }
                } catch (err) {
                    console.warn(err)
                }
            })
            .catch(err => {
                console.warn(err)
            })
    }
}
