import React from 'react';
import {translate} from 'react-i18next';
import Popup from "../../../components/shared/Popup/Popup";
import BaseCountry from "../../../utils/BaseCountry";
import Input from "../../../components/shared/Input/Input";
import Button from "../../../components/shared/Button/Button";
import {withFormik} from "formik";
import {formCustomLabourEdit} from "app/config/yup";
import {connect} from "react-redux";
import {packagesUpdate} from "../../../store/actions/packages.actions";
import {updateObject} from "../../../utils/common";

class EditCustomLabour extends React.Component {

    submit = (e) => {
        e.preventDefault();

        const {values, isValid, labour, _package, dispatch, packages, onClose} = this.props;

        if(isValid){
            dispatch(
                packagesUpdate(updateObject(packages, {...labour, priceFixed: parseFloat(values.price)}, `id:${_package.id}/labours/itemMpId:${labour.itemMpId}`), _package.id)
            )
            .then(() => onClose())
        }
    }

    render() {
        const {t, labour, onClose, handleBlur, touched, errors, handleChange, formSubmitting} = this.props;

        return (
            <>
                <Popup title={labour.name} visible={true} onClose={onClose}>
                    <form onSubmit={this.submit}>

                        <div className="row">

                            <div className="col-xs-12">
                                <Input
                                    type="tel"
                                    placeholder={t('pages.package_details.labour.parts.set.section_form.placeholder.price')}
                                    name="price"
                                    label={t('pages.package_details.labour.parts.set.section_form.label.price')}
                                    value={labour.priceFixed}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.price && errors.price}
                                    addOn={BaseCountry().currency}
                                    step={0.0001}
                                    float={true}
                                />
                            </div>

                        </div>

                        <Button size="lg" type="primary" disabled={formSubmitting.packages} loading={formSubmitting.packages}>{t('global.save')}</Button>
                    </form>
                </Popup>
            </>
        );
    }
}

const EditCustomLabourForm = withFormik(formCustomLabourEdit)(EditCustomLabour);

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(EditCustomLabourForm));
