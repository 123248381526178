import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import axios from 'app/config/axios'
import {parseResponse} from "../../../utils/common";
import Button from "../../../components/shared/Button/Button";
import Loader, {LOADER_BUTTON} from "../../../components/shared/Loader/Loader";
import {withFormik} from "formik";
import {formRemindUser} from "app/config/yup";
import {toast} from "react-toastify";
import Input from "../../../components/shared/Input/Input";
import Phone from "../../../components/shared/Phone/Phone";
import Toast from "../../../components/shared/Toast/Toast";
import {captureException} from "../../../utils/captureException";

const REQUEST_REMIND_URL = 'garage/add-cost-tool/remind-request'

class RemindUser extends Component {

    state = {
        reminding: false,
    };

    remind = (e) => {
        e.preventDefault()
        const {values} = this.props;

        this.setState({reminding: true})

        axios
            .post(REQUEST_REMIND_URL, {user: values, offer_token: this.props.offerToken})
            .then((response) => {
                toast.success(<Toast text={parseResponse(response)} type="success"/>)
                this.setState({
                    reminding: false,
                })
            })
            .catch(error => {
                captureException(error, REQUEST_REMIND_URL);

                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)

                this.setState({
                    reminding: false,
                })
            })
    }

    handleFieldChange = (name, value) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, value);
    }

    render() {
        const {t, values, touched, errors, handleBlur, handleChange} = this.props;
        const {reminding} = this.state;

        return (
            <React.Fragment>

                <form onSubmit={this.remind}>

                    <Input
                        type="text"
                        placeholder={t('pages.request_preview.section_form.placeholder.first_name')}
                        name="first_name"
                        label={t('pages.request_preview.section_form.label.first_name')}
                        value={values.first_name}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.first_name && errors.first_name}
                    />

                    <Input
                        type="text"
                        placeholder={t('pages.request_preview.section_form.placeholder.last_name')}
                        name="last_name"
                        label={t('pages.request_preview.section_form.label.last_name')}
                        value={values.last_name}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.last_name && errors.last_name}
                    />

                    <Input
                        type="email"
                        placeholder={t('pages.request_preview.section_form.placeholder.email')}
                        name="email"
                        label={t('pages.request_preview.section_form.label.email')}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.email && errors.email}
                    />

                    <Phone
                        name={'phone'}
                        onChange={(e) => this.handleFieldChange('phone', e)}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={touched.phone && errors.phone}
                    />

                    <Button size="md" type="primary" extraClass={"btn-block"} disabled={reminding}>
                        <Loader isLoading={reminding} type={LOADER_BUTTON}/>
                        <div className="font-size-2">{t('pages.request_details.remind_user')}</div>
                    </Button>

                </form>

            </React.Fragment>
        );
    }
}

const RemindUserForm = withFormik(formRemindUser)(RemindUser);

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(translate('translations')(RemindUserForm));
