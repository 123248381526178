import React, {Component} from 'react'
import {translate} from 'react-i18next'
import axios from 'app/config/axios'
import axiosRoot from 'axios'
import "./FileUpload.scss";
import {random} from 'lodash'
import imgPdf from '../../../assets/pdf.png'
import {
    ALLOWED_TYPES,
    ALLOWED_IMAGE_TYPES,
    ALLOWED_FILE_TYPES,
    MAXIMUM_FILE_SIZE, MAXIMUM_FILES_NUMBER, ALLOWED_AUDIO_TYPES
} from "../../../store/consts/package/package.file.constants";
import Loader, {LOADER_BUTTON} from "../Loader/Loader";
import {b64toBlob, ext, parseResponse} from "../../../utils/common";
import {toast} from 'react-toastify'
import Lightbox from 'react-image-lightbox';
import Popup from 'components/shared/Popup/Popup'
import loadImage from 'blueimp-load-image'
import FileAudio from "./Audio/FileAudio";
import RecordAudio from "./RecordAudio/RecordAudio";
import { compare } from 'utils/common'
import Toast from "../Toast/Toast";
import Confirm from "../Confirm/Confirm";
import {captureException} from "../../../utils/captureException";

const API_FILE_UPLOAD = 'garage/add-cost-tool/file-upload'
const API_FILE_REMOVE = 'garage/add-cost-tool/file-remove'

class FileUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            files: this.parseFiles(props.files),
            photoIndex: null,
            showRecordAudio: false,
            toRemove: null,
        }
        this.inputFile = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (compare(nextProps.files, this.state.files)) {
            this.setState({
                files: this.parseFiles(nextProps.files),
            })
        }
    }

    componentWillUnmount() {
        this.state.files.forEach(file => {
            if (file.uploaded === false)
                file.cancel.cancel()
        })
    }

    parseFiles = (files) => {
        return files && files.length ?
            files.map(file => {
                return {
                    id: file.id,
                    name: file.name,
                    path: file.path,
                    fileObject: null,
                    cancel: null,
                    progress: 0,
                    uploaded: true,
                    removing: false
                }
            })
            : [];
    }

    onChange = (e) => {
        const {t} = this.props
        const files = Object.assign([], this.state.files);
        let toUpload = e.target.files;

        if (files.length >= MAXIMUM_FILES_NUMBER) {
            toast.error(<Toast text={t('pages.package_details.files.files_max', {'max': MAXIMUM_FILES_NUMBER})} type="error"/>)
            return
        }

        if((files.length + toUpload.length) > MAXIMUM_FILES_NUMBER){
            toast.error(<Toast text={t('pages.package_details.files.files_max', {'max': MAXIMUM_FILES_NUMBER})} type="error"/>)

            toUpload = [...toUpload].slice(0, MAXIMUM_FILES_NUMBER - files.length)
        }

        if ([...toUpload].filter(f => ALLOWED_TYPES.indexOf(ext(f.name)) === -1).length > 0) {
            toast.error(<Toast text={t('pages.package_details.files.file_format', {'format': '.' + ALLOWED_TYPES.join(', .')})} type="error"/>)
        }

        if ([...toUpload].filter(f => f.size > MAXIMUM_FILE_SIZE).length > 0) {
            toast.error(<Toast text={t('pages.package_details.files.file_size', {'size': Math.round(MAXIMUM_FILE_SIZE / 1024 / 1024)})} type="error"/>)
        }

        [...toUpload].filter(f => ALLOWED_TYPES.indexOf(ext(f.name)) !== -1)
            .filter(f => f.size < MAXIMUM_FILE_SIZE)
            .forEach(file => {

                if (files.length >= MAXIMUM_FILES_NUMBER) {
                    return
                }

                if (ALLOWED_IMAGE_TYPES.indexOf(ext(file.name)) !== -1) {
                    loadImage(
                        file,
                        (img) => {
                            b64toBlob(img.toDataURL(file.type),
                                (blob) => {
                                    try {
                                        this.setData(new File([blob], file.name, {type: file.type}), files)
                                    } catch (e) {
                                        blob.name = file.name
                                        this.setData(blob, files)
                                    }
                                },
                                (error) => {
                                    console.warn(error)
                                })
                        },
                        {orientation: true, maxHeight: 1024, maxWeight: 1024, canvas: true}
                    )
                } else {
                    this.setData(file, files)
                }

            })

        e.target.value = null;
    }

    setData = (file, files) => {
        let cancel = axiosRoot.CancelToken.source();
        let data = {
            id: random(1000000),
            name: file.name,
            path: file.name,
            fileObject: file,
            cancel: cancel,
            progress: 0,
            uploaded: false,
            removing: false
        }

        data.upload = this.upload(data)

        files.push(data)

        this.setState({
            files: [...files]
        })
    }

    upload = (upload) => {

        const formData = new FormData();

        formData.append('file', upload.fileObject)

        Object.keys(this.props.formData).map(key => formData.append(key, this.props.formData[key]))

        return axios
            .post(API_FILE_UPLOAD, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.setState({
                        files: Object.assign([], this.state.files).map(f => {
                            if (f.id === upload.id) {
                                f.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                            }
                            return f
                        })
                    })
                },
                cancelToken: upload.cancel.token
            })
            .then(response => {
                this.setState({
                    files: Object.assign([], this.state.files).map(f => {
                        if (f.id === upload.id) {
                            f.id = response.data.id
                            f.uploaded = true
                        }
                        return f
                    })
                })

                this.props.onAddFile(response.data)

            })
            .catch(error => {
                if (!axiosRoot.isCancel(error)) {
                    captureException(error, API_FILE_UPLOAD)
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                }

                this.setState({
                    files: this.state.files.filter(f => f.id !== upload.id)
                })
            })
    }

    toRemove (file) {
        (new Promise((resolve, reject) => this.setState({toRemove: {resolve, reject}})))
        .then(() => {
            this.remove(file)
        })
        .catch(() => {})
        .finally(() => {
            this.setState({ toRemove: null })
        })
    }

    remove = (file) => {

        if('preLoad' in this.props.formData && this.props.formData.preLoad === true) {
            this.setState({
                files: this.state.files.filter(f => f.id !== file.id)
            })

            this.props.onRemoveFile(file);

            return;
        }

        if (file.removing) return

        this.setState({
            files: Object.assign([], this.state.files).map(f => {
                if (f.id === file.id) {
                    f.removing = true
                }
                return f
            })
        })

        let data = {fileId: file.id}

        Object.keys(this.props.formData).map(key => data[key] = this.props.formData[key])

        axios
            .post(API_FILE_REMOVE, data)
            .then(() => {
                this.setState({
                    files: this.state.files.filter(f => f.id !== file.id)
                })

                this.props.onRemoveFile(file)
            })
            .catch(error => {
                captureException(error, API_FILE_REMOVE);
            })
    }

    path = (file) => file.fileObject ? window.URL.createObjectURL(file.fileObject) : file.path

    renderFile = (file, index) => {

        let item, path = this.path(file)

        if (ALLOWED_IMAGE_TYPES.indexOf(ext(file.path)) !== -1) {
            item = <img src={path} alt={file.path} onClick={() => this.setState({photoIndex: index})}/>
        } else if (ALLOWED_FILE_TYPES.indexOf(ext(file.path)) !== -1) {
            item = <img src={imgPdf} alt={file.path}/>
        } else if (ALLOWED_AUDIO_TYPES.indexOf(ext(file.path)) !== -1 && typeof file === 'object') {
            item = <div className="file-upload-item__audio"><FileAudio audio={file}/></div>
        }

        return (
            <div key={file.id} className="file-upload-item">
                {!file.uploaded &&
                <div className="file-upload-item__icons">
                    <span>{file.progress}%</span>
                </div>
                }
                <div>
                    {this.props.edit !== false &&
                    <span className="file-upload-item__action file-upload-item__remove"
                          onClick={() => !file.uploaded ? file.cancel.cancel() : this.toRemove(file)}>
                          {file.removing ?
                              <Loader isLoading={true} addClass="loader-button-center" type={LOADER_BUTTON}/> :
                              <i className="icon-cancel"/>}
                    </span>
                    }

                    {(file.uploaded && ALLOWED_FILE_TYPES.indexOf(ext(file.path)) !== -1) &&
                    <a download={file.path} href={path} className="file-upload-item__action file-upload-item__download"><i
                        className="icon-file-upload"/></a>
                    }
                    {item}
                </div>
            </div>
        )
    }

    render() {
        const {t, button} = this.props
        const {photoIndex, toRemove} = this.state

        let images = this.state.files.filter(f => ALLOWED_IMAGE_TYPES.indexOf(ext(f.path)) !== -1).map(f => this.path(f))

        return (
            <React.Fragment>
                <div>
                    {this.props.edit !== false &&
                    <div className="file-upload-controls">
                        <div className="file-upload-icon">
                            <label htmlFor="files-img-disk">

                                <p>
                                    <i className="icon-photo"/> <br/>
                                    {t('pages.package_details.files.image')}</p>
                            </label>
                            <input ref={r => this.inputFile = r} type="file" name="files[]" id="files-img-disk"
                                   className="hide"
                                   multiple accept={'image/' + ALLOWED_IMAGE_TYPES.join(',image/')}
                                   onChange={this.onChange}
                            />
                        </div>
                        <div className="file-upload-icon">
                            <label htmlFor="files-audio-disk">
                                <p><i className="icon-audio"/> <br/> {t('pages.package_details.files.audio')}</p>
                            </label>
                            <input ref={r => this.inputFile = r} type="file" name="files[]" id="files-audio-disk"
                                   className="hide"
                                   multiple accept={'audio/*'}
                                   onChange={this.onChange}
                            />
                        </div>
                        <div className="file-upload-icon">
                            <div onClick={() => this.setState({showRecordAudio: true})}>
                                <p>
                                    <i className="icon-mic"/> <br/>
                                    {t('pages.package_details.files.record')}
                                </p>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="package-files-vertical float-none">
                        {this.state.files.length ? (
                            <div className="files-upload-list">
                                {this.state.files.map((file, index) => this.renderFile(file, index))}
                            </div>
                        ) : (
                            <div className="package-files-vertical__empty">{t('pages.package_details.no_files')}</div>
                        )}

                    </div>
                </div>

                {photoIndex !== null &&
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({photoIndex: null})}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest={() => {
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                    }
                    }
                />
                }

                {button && button(!!this.state.files.filter(f => f.uploaded === false).length)}

                {this.state.showRecordAudio &&
                    <Popup
                        title={t('pages.package_details.files.record_audio.title')}
                        visible={this.state.showRecordAudio}
                        onClose={() => this.setState({ showRecordAudio: false })}
                    >
                        <RecordAudio
                            onSave={blob => {
                                try {
                                    this.setData(new File([blob], 'Recording.mp3', {type: blob.type}), Object.assign([], this.state.files))
                                } catch (e) {
                                    blob.name = 'Recording.mp3'
                                    this.setData(blob, this.state.files)
                                }

                                this.setState({showRecordAudio: false})
                            }}
                            onClose={() => this.setState({showRecordAudio: false})}
                        />
                    </Popup>
                }

                {toRemove && (
                    <Confirm
                        title={t('pages.package_details.files.remove.title')}
                        visible={!!toRemove}
                        accept={() => toRemove.resolve(true)}
                        cancel={() => toRemove.reject(false)}
                    />
                )}

            </React.Fragment>
        )
    }
}

export default translate('translations')(FileUpload)
