export const HOURLY_PRICES = 'hourly_prices'
export const PRICES_OIL = 'oil'
export const PRICES_LIQUIDS = 'liquids'
export const PRICES_PARTS_DISCOUNT = 'discount_parts'
export const PRICES_BRAKES_DISCOUNT = 'discount_brakes'
export const PRICES_APPOINTMENT_DISCOUNT = 'discount_appointment'
export const PRICES_ADDITIONAL_PERCENT_ON_WORKS = 'additional_percent_on_works'

export default {
    HOURLY_PRICES,
    PRICES_OIL,
    PRICES_LIQUIDS,
    PRICES_PARTS_DISCOUNT,
    PRICES_BRAKES_DISCOUNT,
    PRICES_APPOINTMENT_DISCOUNT,
    PRICES_ADDITIONAL_PERCENT_ON_WORKS,
}
