import React, {Component} from 'react';
import {translate} from 'react-i18next';
import './ChecklistAnalytics.scss';

class ChecklistAnalytics extends Component {

    render() {
        const {t, checklists} = this.props;

        const sent = checklists.sum - checklists.accepted > 0 ? checklists.sum - checklists.accepted : 0;
        const allPassed = checklists.accepted;

        return (
            <React.Fragment>
                <div className="analytic-tab analytic-tab-checklist">

                    <div className="analytic-tab-header">
                        <div className="row">
                            <div
                                className="col-xs-6 analytic-tab-header__package-stat analytic-tab-header__package-stat--default">
                                <div>
                                    <span className="icon-list icon"/>
                                    <span className="value">{sent}</span>
                                </div>
                                <div className="label">{t('pages.analytics.statues.created')}</div>
                            </div>
                            <div
                                className="col-xs-6 analytic-tab-header__package-stat analytic-tab-header__package-stat--success">
                                <div>
                                    <span className="icon-check-fill icon"/>
                                    <span className="value">{allPassed}</span>
                                </div>
                                <div className="label">{t('pages.analytics.statues.all_passed')}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(ChecklistAnalytics)
