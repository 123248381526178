import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import InputCheckbox from "components/shared/InputCheckbox";
import {packagesUpdate} from "store/actions/packages.actions";
import {updateObject} from "utils/common";
import Button from "components/shared/Button/Button";
import {__clone} from "utils/common";
import Popup from "components/shared/Popup/Popup";
import PartsVersion from "./PartsVersion";
import {deepObject} from "utils/common";
import {compare} from "utils/common";
import {usePrevious} from "components/hooks/utils";
import {sumPartPrice} from "../../../store/selectors/packagePrices";
import PartAdd from "./PartAdd";
import {fixedFloat, parseDeepPath} from "../../../utils/common";
import {missingInfo} from "../../../store/selectors/missingInfo";
import BaseCountry from "../../../utils/BaseCountry";

export const AvailableParts = props => {

    const { t, packages, dispatch, packagePath, formSubmitting, onClose } = props;

    const [ parts, setParts ] = useState(props.parts);
    const [ partVersionShow, setPartVersionShow ] = useState(null);
    const [ partAddShow, setPartAddShow ] = useState(null);

    const missingData = missingInfo(deepObject(packages, packagePath))

    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if(compare(parts, props.parts) && partVersionShow){
            setParts({
                ...parts, ...{[partVersionShow]: {...parts[partVersionShow], ...{versions: props.parts[partVersionShow].versions}}}
            });
        }

        if(compare(parts, props.parts) && partAddShow){
            setParts(props.parts);
        }

    }, [props.parts]);

    useEffect(() => {

        if(prevPackages && !partVersionShow && !partAddShow){
            onClose();
        }

    }, [props.packages]);

    let handleChange = (e, partPath) => {
        let partItem = __clone(parts[partPath]);
        partItem.selected = e.target.checked;

        setParts({
            ...parts, ...{[partPath]:partItem}
        });
    };

    let submit = (e) => {
        e.preventDefault();

        let updatedPackages = __clone(packages);
        Object.keys(parts).map(path => {
            updatedPackages = updateObject(updatedPackages, parts[path], path)
        });

        dispatch(packagesUpdate(updatedPackages, parseDeepPath(packagePath)[0].id));
    };

    let renderPartVersionShow = () => {

        if(!partVersionShow){
            return null;
        }

        let part = deepObject(packages, `${partVersionShow}`);

        return (
            <Popup title={t('pages.package_details.labour.parts.versions.title')}
                   subtitle={part.name} visible={!!part} contentScrollable={true} onClose={() => setPartVersionShow(null)}>
                <PartsVersion
                    part={part}
                    packagePath={`${partVersionShow}`}
                    onClose={() => setPartVersionShow(null)}
                />
            </Popup>
        );
    };

    let renderPartAddShow = () => {

        if(!partAddShow){
            return null;
        }

        return (
            <Popup title={t('pages.package_details.labour.parts.add.title')} visible={!!partAddShow} onClose={() => setPartAddShow(false)}>
                <PartAdd
                    packagePath={`${packagePath}`}
                    onClose={() => setPartAddShow(false)}
                />
            </Popup>
        );
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>

                <div className="extend-package-list">
                    {Object.keys(parts).map(path => (
                        <div key={parts[path].id} className="extend-package-list__item">

                            <InputCheckbox
                                id={`part-${parts[path].id}`}
                                name={`part-${parts[path].id}`}
                                value={parts[path].id}
                                onChange={(e) => handleChange(e, path)}
                                checked={parts[path].selected}
                                label={
                                    <span>{parts[path].name}
                                        <span className="vertical-text-delimiter">|</span>
                                        <span className={`bold ${missingData.parts.find(data => data.part === parts[path].id) ? 'brand-warning' : ''}`}>
                                            {fixedFloat(sumPartPrice(parts[path]))} {BaseCountry().currency} {missingData.parts.find(data => data.part === parts[path].id) && <i className="icon-warrning-fill"/>}
                                        </span>
                                    </span>
                                }
                            />

                            <span className="link-style edit-link" onClick={() => setPartVersionShow(path)}><i className="icon-edit"/></span>
                        </div>
                    ))}

                </div>

                <div className="mt-10">
                    <div className="btn btn-sm btn-secondary" onClick={() => setPartAddShow(true)}>
                        {t('pages.package_details.labour.parts.add_more')}
                    </div>
                </div>

                {Object.keys(parts).length ?
                    <div className="mt-30">
                        <Button size="lg" type="primary" disabled={formSubmitting.packages}>{t('global.save')}</Button>
                    </div> : null
                }
            </form>

            {renderPartVersionShow()}
            {renderPartAddShow()}

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting
    };
};

export default connect(mapStateToProps)(translate('translations')(AvailableParts));
