import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR,
    TIRES_TYPE_BRAND_PREMIUM,
    TIRES_TYPE_BRAND_PRICE_QUALITY
} from "../../consts/tires.constants";

export default {
    [TIRES_TYPE_BRAND_PREMIUM]: {
        [TIRES_AXEL_FRONT]: null,
        [TIRES_AXEL_REAR]: null,
    },
    [TIRES_TYPE_BRAND_PRICE_QUALITY]: {
        [TIRES_AXEL_FRONT]: null,
        [TIRES_AXEL_REAR]: null,
    },
    all_types_selected: false,
    single_type_selected: false,
}
