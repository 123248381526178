import Yup from './yup'
import { labourPricesLiquidsUpdate } from '../../../store/actions/labourPrices.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues({ liquids }) {
        let list = {}

        liquids.prices.forEach(l => {
            list[l.input_name] = l.price
        })

        return list
    },

    validationSchema: ({ t, liquids }) => {
        let prices = {}
        liquids.prices.forEach(l => {
            prices[l.input_name] = Yup.number()
                .nullable()
                .label(t('pages.price_update.liquids_price.title'))
        })

        return Yup.object().shape(prices)
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(labourPricesLiquidsUpdate(values))
    },
}
