import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import FormVehicle from "components/forms/FormVehicle/FormVehicle";
import Header from "components/layout/Header/Header";
import {carStepPop} from "store/actions/carStep.actions";
import history from "routes/history";
import {changeStepData} from "store/actions/step.actions";

class AddCostContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            car: this.props.car
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(changeStepData(1));
    }

    onBack = () => {
        const { carStep: { prevSteps }, dispatch } = this.props;

        if(prevSteps.length > 0){
            dispatch(carStepPop());
        }
        else{
            history.goBack();
        }
    };

    render() {
        const {t} = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} onBack={this.onBack}/>

                <div className="container container--has-submit-container">

                    <FormVehicle />

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        carStep: state.carStep
    };
};

export default connect(mapStateToProps)(translate('translations')(AddCostContainer));
