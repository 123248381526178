import store from 'store/store';
import pages from "app/consts/routes";
import history from 'routes/history';
import React from "react";

export const RequestPreview = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            const packages = store.getState().packages;

            this.shouldRender = true;

            if(packages.length <= 0){
                this.shouldRender = false;
                history.replace(pages.packages);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }
    }
};

export default RequestPreview;
