import store from 'store/store'
import pages from 'app/consts/routes'
import history from 'routes/history'
import React from 'react'
import { haxMaxPackages } from '../../../store/selectors/package'

export const PackagesMaxReached = Component => {
    return class extends React.Component {
        constructor(props) {
            super(props)

            this.shouldRender = true

            if (haxMaxPackages(store.getState())) {
                this.shouldRender = false
                history.replace(pages.packages)
            }
        }

        render() {
            if (this.shouldRender) {
                return <Component {...this.props} />
            }

            return <div />
        }
    }
}

export default PackagesMaxReached
