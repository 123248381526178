import React, { Component } from 'react'
import { translate } from 'react-i18next'

class FileAudio extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: props.audio,
      audio: new Audio(props.audio.fileObject ? window.URL.createObjectURL(props.audio.fileObject) : props.audio.path),
      play: false
    }
  }

  componentDidMount() {
    this.state.audio.addEventListener('ended', () => this.setState({ play: false }));
  }

  componentWillUnmount() {
    this.state.audio.removeEventListener('ended', () => this.setState({ play: false }));
    this.state.audio.pause()
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.state.audio.play() : this.state.audio.pause();
    });
  }

  render() {
    return (
      <React.Fragment>
          <i className={`${this.state.play ? 'icon-pause' : 'icon-audio'}`} onClick={this.togglePlay}/>
      </React.Fragment>
    )
  }
}

export default translate('translations')(FileAudio)
