import packages from 'store/models/packages'
import { PACKAGES_FILE_ADD, PACKAGES_FILE_REMOVE, PACKAGES_RESET, PACKAGES_SET } from '../consts/package/package.constants'

export default function(state = packages, action) {
    switch (action.type) {
        case PACKAGES_SET:
            return [...action.payload.packages]

        case PACKAGES_RESET:
            return packages

        case PACKAGES_FILE_ADD:
            return state.map(pack => {
                if (pack.id === action.payload.package.id) {
                    pack.files.push(action.payload.file)
                }

                return pack
            })

        case PACKAGES_FILE_REMOVE:
            return state.map(pack => {
                if (pack.id === action.payload.package.id) {
                    pack.files = pack.files.filter(f => f.id !== action.payload.file.id)
                }

                return pack
            })

        default:
            return state
    }
}
