import React, {Component} from 'react';
import {translate} from 'react-i18next';
import "./CarLabours.scss";
import {compare} from 'utils/common';
import {STATUS_ACCEPTED, STATUS_OPEN, STATUS_REJECTED} from "../../../store/consts/package/package.status.constants";
import {getActivity, setActivity} from "../../../utils/activity";

const MAX_SHOW = 20

class CarLabours extends Component {

    constructor(props){
        super(props)

        this.state = {
            labours: this.sort(props.labours),
            limit: MAX_SHOW,
            packages: {},
            collapse: getActivity('analytics.car_labours.collapsed', false)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(compare(nextProps.labours, this.props.labours)){
            this.setState({
                labours: this.sort(nextProps.labours),
            })
        }
    }

    sort = (labours) => Object.keys(labours)
        .map(function(k) { return { itemMpId: k, labours: labours[k] }; })
        .sort(function(a, b) { return b.labours.length - a.labours.length; })

    collapse = () => {
        this.setState({
            collapse: !this.state.collapse
        }, () => {
            setActivity('analytics.car_labours.collapsed', this.state.collapse)
        })
    }

    list = (limit) => (
        Object.keys(this.state.labours).slice(0, limit).map((item, key) => {
            return (
                <div key={key} className="analytic-tab-content__package-stat">
                    <div className="label">{this.state.labours[item].labours[0].name}
                        {limit === 1 &&
                            <span className={`chevron ${this.state.collapse ? 'close' : ''}`}><i className="icon-chevron-down"/></span>
                        }
                    </div>
                    <div className="row">
                        <div className="col-xs-4 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--success">
                            <span className="icon-check-fill icon"/>
                            <span className="value">{this.state.labours[item].labours.filter(i => i.status === STATUS_ACCEPTED).length}</span>
                        </div>
                        <div className="col-xs-4 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--danger">
                            <span className="icon-cancell-fill icon"/>
                            <span className="value">{this.state.labours[item].labours.filter(i => i.status === STATUS_REJECTED).length}</span>
                        </div>
                        <div className="col-xs-4 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--warning">
                            <span className="icon-clock-fill icon"/>
                            <span className="value">{this.state.labours[item].labours.filter(i => i.status === STATUS_OPEN).length}</span>
                        </div>
                    </div>
                </div>
            )
        })
    )

    render() {
        const {t} = this.props;
        const {collapse, limit} = this.state;

        return (
            <React.Fragment>
                <div className="analytic-tab analytic-tab-car-statistic">
                    <div className="analytic-tab-header" onClick={this.collapse}>
                        {!collapse ?
                            <div>
                                <span>{t('pages.analytics.top_labours')}</span>
                                <span className="chevron"><i className="icon-chevron-down"/></span>
                            </div>
                            :
                            this.list(1)
                        }
                    </div>

                    {!collapse &&
                        <div className="analytic-tab-content analytic-tab-content--expandable">
                            {this.list(limit)}
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(CarLabours)
