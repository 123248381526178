import Yup from './yup'

export default {
    enableReinitialize: true,

    mapPropsToValues({ partSet }) {
        return {
            name: partSet.name,
            price: partSet.price,
            amounts: partSet.amounts ? partSet.amounts : 1,
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            name: Yup.string()
                .required()
                .label(t('pages.package_details.labour.parts.set.section_form.label.name')),

            price: Yup.number()
                .required()
                .label(t('pages.package_details.labour.parts.set.section_form.label.price')),

            amounts: Yup.number()
                .required()
                .label(t('pages.package_details.labour.parts.set.section_form.label.amounts')),
        })
    },
}
