import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { LOADER_REQUESTS } from 'store/consts/loader.constants'
import { setLoader } from 'store/actions/loader.actions'
import { REQUESTS_FETCH, setRequests } from '../../actions/requests.actions'

export const REQUESTS_FETCH_URL = 'garage/requests'

export const requestsMiddleware = ({ dispatch }) => next => {
  return action => {
    next(action)

    switch (action.type) {
      case REQUESTS_FETCH:
        dispatch(setLoader(LOADER_REQUESTS, false))

        dispatch(
          apiRequest({
            method: 'GET',
            url: REQUESTS_FETCH_URL,
            signature: REQUESTS_FETCH,
          }),
        )

        break

      case `${REQUESTS_FETCH} ${API_SUCCESS}`:
        dispatch(setRequests(action.payload.data))
        dispatch(setLoader(LOADER_REQUESTS, true))
        break

      case `${REQUESTS_FETCH} ${API_ERROR}`:
        dispatch(setLoader(LOADER_REQUESTS, true))
        break

      default:
        break
    }
  }
}
