import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Input from "../../components/shared/Input/Input";
import {DEFAULT_TIME_DUE} from '../SettingsContainer/TimeDue/TimeDue'
import Phone from "../../components/shared/Phone/Phone";
import SearchUser from "../../components/shared/SearchUser/SearchUser";
import TextArea from "../../components/shared/TextArea/TextArea";
import AppointmentDiscount from "./AppointmentDiscount/AppointmentDiscount";
import {DISCOUNT_APPOINTMENT_TYPE} from "../../store/consts/discounts.constants";
import axios from "../../app/config/axios";
import {captureException} from "../../utils/captureException";

const API_EMAIL_USER_EXISTS_URL = 'garage/add-cost-tool/email-user-exists'
const API_PHONE_USER_EXISTS_URL = 'garage/add-cost-tool/phone-user-exists'

class UserData extends Component {
    constructor(props) {
        super(props);

        let date = new Date()
        if (props.values.time_due) {
            date.setHours(props.values.time_due.split(':').shift(), props.values.time_due.split(':').pop())
        } else {
            date.setHours(DEFAULT_TIME_DUE.split(':').shift(), DEFAULT_TIME_DUE.split(':').pop())
        }

        this.state = {
            time: date,
            time_due: props.values.time_due,
            time_due_disable: !!!props.values.time_due,
            userAlreadyExistsByEmail: null,
            userAlreadyExistsByPhone: null,
            is_today_enabled: true
        }

        this.userEmailRef = React.createRef();
    }

    componentDidMount() {
        this.userEmailRef.inputRef.addEventListener('change', this.emailUserExists);
    }

    formatTimeDue = (time) => {
        return `${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
    };

    handleTimeDueChange = (e) => {
        const {handleFieldChange} = this.props;
        const {checked} = e.target;

        if (checked === true) {
            handleFieldChange('time_due', null)
        } else {
            handleFieldChange('time_due', this.formatTimeDue(this.state.time))
        }

        this.setState({
            time_due_disable: checked
        })
    };

    handleIsTodayChange = (e) => {
        const {handleFieldChange} = this.props;
        const {checked} = e.target;

        handleFieldChange('is_today_enabled', checked)

        this.setState({
            is_today_enabled: checked
        })
    }

    emailUserExists = (e) => {
        let value = e.target.value;

        if (!value) return;

        this.setState({userAlreadyExistsByEmail: null})

        axios.post(API_EMAIL_USER_EXISTS_URL, {email: value})
            .then(response => {
                if ('id' in response.data) {
                    this.setState({
                        userAlreadyExistsByEmail: response.data
                    });
                }
            })
            .catch(error => {
                captureException(error, API_EMAIL_USER_EXISTS_URL);
            })
    }

    phoneUserExists = (e) => {
        let value = e.target.value.replace(/\+|\s/g, '');

        if (!value) return;

        this.setState({userAlreadyExistsByPhone: null})

        axios.post(API_PHONE_USER_EXISTS_URL, {phone: value})
            .then(response => {
                if ('id' in response.data) {
                    this.setState({
                        userAlreadyExistsByPhone: response.data
                    });
                }
            })
            .catch(error => {
                captureException(error, API_PHONE_USER_EXISTS_URL);
            })
    }

    render() {
        const {userAlreadyExistsByEmail, userAlreadyExistsByPhone} = this.state;
        const {
            t, values, touched, errors, handleBlur, handleChange, handleFieldChange, onUserFound, garage,
            selectedAppointments, onUserReset, packages
        } = this.props;

        return (
            <React.Fragment>

                <SearchUser onChange={onUserFound} onUserReset={onUserReset}/>

                <div className="word-hr-separator"/>

                <Input
                    type="text"
                    placeholder={t('pages.request_preview.section_form.placeholder.contract_number')}
                    name="garage_file"
                    label={t('pages.request_preview.section_form.label.contract_number')}
                    value={values.contract_number}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    autocomplete="new-password"
                />

                <Input
                    type="text"
                    placeholder={t('pages.request_preview.section_form.placeholder.first_name')}
                    name="first_name"
                    label={t('pages.request_preview.section_form.label.first_name')}
                    value={values.first_name}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    error={touched.first_name && errors.first_name}
                />

                <Input
                    type="text"
                    placeholder={t('pages.request_preview.section_form.placeholder.last_name')}
                    name="last_name"
                    label={t('pages.request_preview.section_form.label.last_name')}
                    value={values.last_name}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    error={touched.last_name && errors.last_name}
                />

                <Input
                    ref={ref => this.userEmailRef = ref}
                    type="email"
                    placeholder={t('pages.request_preview.section_form.placeholder.email')}
                    name="email"
                    label={t('pages.request_preview.section_form.label.email')}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                    error={touched.email && errors.email}
                />
                {userAlreadyExistsByEmail &&
                <div className="closer-to-form-data">
                        <span className="link-style" onClick={() => {
                            onUserFound({item: userAlreadyExistsByEmail})
                            this.setState({userAlreadyExistsByEmail: null})
                        }}>{t('pages.request_preview.user_exists.email')}</span>
                </div>
                }

                <Phone
                    name={'phone'}
                    onChange={(e) => handleFieldChange('phone', e)}
                    onChangeInput={this.phoneUserExists}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && errors.phone}
                />
                {userAlreadyExistsByPhone &&
                <div className="closer-to-form-data">
                        <span className="link-style" onClick={() => {
                            onUserFound({item: userAlreadyExistsByPhone})
                            this.setState({userAlreadyExistsByPhone: null})
                        }}>{t('pages.request_preview.user_exists.phone')}</span>
                </div>
                }

                <TextArea
                    className="text-area-checkout-comment"
                    placeholder={t('pages.request_preview.section_form.placeholder.comment')}
                    name="comment"
                    label={t('pages.request_preview.section_form.label.comment')}
                    value={values.comment}
                    onBlur={handleBlur}
                    onChange={e => handleChange(e)}
                />

                {/*{user.type !== USER_ROLE_MECHANIC &&*/}
                {/*    <div className="mb-30">*/}
                {/*        <div className={`form-group ${this.state.time_due_disable ? 'disabled' : ''}`}>*/}
                {/*            <label>{t('pages.request_preview.section_form.label.time_due')}</label>*/}
                {/*            <DatePicker*/}
                {/*                selected={this.state.time}*/}
                {/*                onChange={date => {*/}
                {/*                    this.setState({*/}
                {/*                        time: date,*/}
                {/*                        time_due: `${new Date(date).getHours()}:${('0' + new Date(date).getMinutes()).slice(-2)}`*/}
                {/*                    })*/}
                {/*                    handleFieldChange('time_due', `${new Date(date).getHours()}:${('0' + new Date(date).getMinutes()).slice(-2)}`)*/}
                {/*                }}*/}
                {/*                showTimeSelect*/}
                {/*                showTimeSelectOnly*/}
                {/*                timeIntervals={30}*/}
                {/*                timeFormat="HH:mm"*/}
                {/*                dateFormat="HH:mm"*/}
                {/*                className="time-picker-custom"*/}
                {/*                timeClassName={() => "time-picker-time-custom"}*/}
                {/*                disabled={this.state.time_due_disable}*/}
                {/*                disabledKeyboardNavigation*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        <div className="mh-20">*/}
                {/*            <InputCheckbox*/}
                {/*                id="time_due_disable"*/}
                {/*                name="time_due_disable"*/}
                {/*                value={this.state.time_due_disable}*/}
                {/*                onChange={this.handleTimeDueChange}*/}
                {/*                checked={this.state.time_due_disable}*/}
                {/*                label={t('pages.request_preview.section_form.skip_time_due')}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}


                {garage.discounts.find(discount => discount.type === DISCOUNT_APPOINTMENT_TYPE && discount.active) &&
                <AppointmentDiscount
                    packages={packages}
                    selectedAppointments={selectedAppointments}
                    discount={garage.discounts.find(discount => discount.type === DISCOUNT_APPOINTMENT_TYPE)}
                    onChange={(e) => handleFieldChange('appointment_discounts', e)}
                />
                }

            </React.Fragment>
        );
    }
}

export default translate('translations')(UserData);
