import * as React from 'react'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import ScrollToTop from './components/hoc/ScrollToTop'
import {Redirect, Route, Switch, matchPath} from 'react-router-dom'
import garageRoutes from './routes/garage'
import authRoutes from './routes/auth'
import userRoutes from './routes/user'
import {removeSessionStorageItem, setLocalStorageItem, setSessionStorageItem} from 'utils/storage'
import {STORAGE_USER_TOKEN, STORAGE_LANGUAGE, STORAGE_OFFER_TOKEN} from 'app/consts/storage.consts'
import history from 'routes/history'
import Footer from 'components/layout/Footer'
import {userFetch} from 'store/actions/user.actions'
import {LOADER_INIT} from 'store/consts/loader.constants'
import Loader from 'components/shared/Loader/Loader'
import {setLoader} from 'store/actions/loader.actions'
import pages from 'app/consts/routes'
import {authRemove} from 'store/actions/auth.actions'
import {intersect} from 'utils/common'
import {Slide, ToastContainer} from 'react-toastify'
import PackageResetConfirm from './components/shared/PackageResetConfirm/PackageResetConfirm'
import {STORAGE_REMEMBER_URL} from './app/consts/storage.consts'
import {FIRST_ON_LOGIN} from './store/consts/user.constants'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga'
import {clearEcho, startEcho} from './utils/echo'
import {notificationsFetch} from './store/actions/notifications.actions'
import {initPushNotifications} from './utils/pushNotifications'
import language from './utils/language'
import ProfileProgressWidget from './components/ProfileProgress/ProfileProgressWidget'
import CookiePolicy from "./components/CookiePolicy/CookiePolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import ChangeLanguage from "./utils/ChangeLanguage";
import ErrorRecovery from "./components/ErrorRecovery/ErrorRecovery"
import {countSystemMessages} from "./store/actions/systemMessagesCounter.actions";
import {captureException} from "./utils/captureException";
import AppVersionUpToDateInfo from "./components/AppVersionUpToDateInfo/AppVersionUpToDateInfo";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        debug: false,
        titleCase: false,
    })
}

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
    })
}

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            user: this.props.user,
            garage: this.props.garage,
            loader: this.props.loader,
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;

        App.setLanguage()

        this.mounted()

        this.userExist = history.listen((location, action) => {
            if (this.state.user.logged === true && !localStorage.getItem(STORAGE_USER_TOKEN)) {
                dispatch(authRemove())
            }
            ReactGA.pageview(window.location.pathname + window.location.search)
        })

        window.addEventListener('error', (event) => {
            captureException(event);
        })
    }

    mounted = () => {
        this.setUser()

        if (!this.unlisten) {
            this.unlisten = history.listen((location, action) => {
            })
        }

        removeSessionStorageItem(STORAGE_OFFER_TOKEN)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop],
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch} = this.props
        const {user, garage} = this.state

        if (!window.Echo && garage.logged === true && user.logged === true) {
            startEcho(user, garage)
            dispatch(notificationsFetch())
            dispatch(countSystemMessages())
        }

        if (window.Echo && garage.logged === false) {
            clearEcho()
        }

        if (user.logged === true) {
            this.userLoggedIn()
        }

        if (garage.logged === true && user.logged === true) {
            initPushNotifications()
        }

        if (prevProps.loader[LOADER_INIT] === true && this.props.loader[LOADER_INIT] === false) {
            this.mounted()
        }
    }

    userLoggedIn = () => {
        const {user} = this.state

        let reinitialize = user.locale !== language();

        setLocalStorageItem(STORAGE_LANGUAGE, user.locale)

        if (reinitialize) {
            ChangeLanguage();
        }
    }

    componentWillUnmount() {
        this.unlisten()
        this.userExist()
    }

    static setLanguage() {
        setLocalStorageItem(STORAGE_LANGUAGE, language())
    }

    setUser() {
        const {dispatch} = this.props

        if (localStorage.getItem(STORAGE_USER_TOKEN)) {
            dispatch(userFetch())
        } else {
            dispatch(setLoader(LOADER_INIT, true))
        }
    }

    renderAuthRoutes() {
        const {user} = this.state

        return authRoutes.map((item, index) => {
            return (
                <AuthRoute
                    key={index}
                    exact={item.exact}
                    path={item.path}
                    component={item.component}
                    user={user}
                    title={item.headerTitle}
                />
            )
        })
    }

    renderUserRoutes() {
        const {user} = this.state

        return userRoutes.map((item, index) => {
            return (
                <PrivateUserRoute
                    key={index}
                    exact={item.exact}
                    path={item.path}
                    component={item.component}
                    user={user}
                    title={item.headerTitle}
                />
            )
        })
    }

    renderGarageRoutes() {
        const {garage, user} = this.state

        return garageRoutes.map((item, index) => {
            return (
                <PrivateGarageRoute
                    key={index}
                    exact={item.exact}
                    path={item.path}
                    component={item.component}
                    garage={garage}
                    user={user}
                    title={item.headerTitle}
                    roles={item.roles}
                    gates={item.gates}
                    showFooter={item.showFooter}
                    showProfileProgress={item.showFooter}
                    showRightHeaderControls={item.showRightHeaderControls}
                />
            )
        })
    }

    setPageTitle() {
        const {t} = this.props
        const list = authRoutes.concat(garageRoutes).concat(userRoutes)

        for (let route in list) {
            if (matchPath(history.location.pathname, list[route])) {
                document.title = t(list[route].pageTitle)
            }
        }
    }

    render() {
        const {loader} = this.state

        return (
            <React.Fragment>
                <div className="content-wrapper">
                    <Sentry.ErrorBoundary
                        fallback={(props) => <ErrorRecovery {...props} onResetError={(resetError) => resetError()}/>}
                        beforeCapture={(scope) => {
                            scope.setExtra('state', JSON.stringify(this.props.wholeState));
                        }}>
                        <ToastContainer
                            draggable={true}
                            pauseOnHover={false}
                            position="top-center"
                            autoClose={3000}
                            className="booster-toast-container"
                            toastClassName="booster-toast"
                            hideProgressBar={true}
                            transition={Slide}
                        />

                        <AppVersionUpToDateInfo/>

                        <PackageResetConfirm/>

                        <CookiePolicy/>

                        {loader[LOADER_INIT] ? (
                            <ScrollToTop>
                                <Switch>
                                    {this.setPageTitle()}
                                    {this.renderAuthRoutes()}
                                    {this.renderUserRoutes()}
                                    {this.renderGarageRoutes()}
                                </Switch>
                            </ScrollToTop>
                        ) : (
                            <Loader isLoading={true} type={LOADER_INIT}/>
                        )}
                    </Sentry.ErrorBoundary>
                </div>
            </React.Fragment>
        )
    }
}

const AuthRoute = ({component: Component, user, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            user.logged === false ? (
                <Component {...rest} {...props} />
            ) : (
                <RedirectToRemembered to={FIRST_ON_LOGIN[user.type]}/>
            )
        }
    />
)

const PrivateUserRoute = ({component: Component, user, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            user.logged === true && user.garages.length > 1 ? (
                <Component {...rest} {...props} />
            ) : (
                <RememberRoute to={pages.login}/>
            )
        }
    />
)

const PrivateGarageRoute = ({component: Component, garage, user, ...rest}) => (
    <Route
        history={history}
        {...rest}
        render={props =>
            garage.logged === true ? (
                (rest.roles.indexOf(user.type) !== -1 && GateCheck(garage, rest)) ? (
                    <div>
                        <Component {...rest} {...props} />
                        {rest.showFooter && <Footer user={user}/>}
                        {rest.showProfileProgress && <ProfileProgressWidget/>}
                        {(garage.terms_and_conditions && !garage.terms_and_conditions.active) &&
                        <TermsAndConditions acceptable={true}/>}
                    </div>
                ) : (
                    <Redirect to={FIRST_ON_LOGIN[user.type]}/>
                )
            ) : (
                <RememberRoute to={pages.pickGarage}/>
            )
        }
    />
)


const GateCheck = (garage, route) => {
    if (route.gates && route.gates.length > 0) {
        return !route.gates.find(gate => gate(garage) === false);
    }

    return true;
}

const RedirectToRemembered = ({to}) => {
    let route = sessionStorage.getItem(STORAGE_REMEMBER_URL)
    removeSessionStorageItem(STORAGE_REMEMBER_URL)

    if (route && garageRoutes.filter(r => matchPath(route, r)).length > 0) {
        return <Redirect to={route}/>
    }

    return <Redirect to={to}/>
}

const RememberRoute = ({to}) => {
    if (garageRoutes.filter(r => matchPath(history.location.pathname, r)).length > 0) {
        setSessionStorageItem(STORAGE_REMEMBER_URL, `${history.location.pathname}${history.location.search}`)
    }

    return <Redirect to={to}/>
}

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: state.garage,
        loader: state.loader,
        wholeState: state,
    }
}

export default connect(mapStateToProps)(translate('translations')(App))
