import {
    APP_VERSION_SEEN_MESSAGE,
    APP_VERSION_SET,
    APP_VERSION_SET_LOCAL,
} from '../actions/appVersion.actions'
import appVersion from "../models/appVersion";

export default function(state = appVersion, action) {
    switch (action.type) {
        case APP_VERSION_SET:
            if (!Boolean(action.payload.apiVersion)) {
                return state
            }

            if (!Boolean(localStorage.getItem('application-version'))) {
                localStorage.setItem('application-version', '1')
                return {
                    ...state,
                    localVersion: '1',
                    responseVersion: action.payload.apiVersion,
                }
            }

            if (action.payload.apiVersion !== localStorage.getItem('application-version')) {
                return {
                    ...state,
                    localVersion: localStorage.getItem('application-version'),
                    responseVersion: action.payload.apiVersion,
                }
            } else {
                return {
                    ...state,
                    localVersion: localStorage.getItem('application-version'),
                    responseVersion: action.payload.apiVersion,
                }
            }

        case APP_VERSION_SET_LOCAL:
            localStorage.setItem('application-version', action.payload)
            return {
                ...state,
                localVersion: action.payload,
                responseVersion: action.payload
            }

        case APP_VERSION_SEEN_MESSAGE:
            return {
                ...state,
                seen: true
            }

        default:
            return state
    }
}
