import React, {Component} from 'react';
import './ProfileProgress.scss';
import {translate} from "react-i18next";
import ProfileProgress from "./ProfileProgress";
import {toast} from "react-toastify";
import Toast from "../shared/Toast/Toast";
import axios from "../../app/config/axios";
import {parseResponse, timestamp} from "../../utils/common";
import {STORAGE_PROFILE_PROGRESS_TOAST} from "../../app/consts/storage.consts";
import history from "../../routes/history";
import axiosRoot from 'axios'
import {captureException} from "../../utils/captureException";

const API_PROFILE_PROGRESS_URL = 'garage/profile-progress';

class ProfileProgressWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            garage: {
                logo: null,
                address: null,
                zip: null,
                contact_phones: null,
            },
            profile: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                avatar: null,
            },
            prices: {
                hourly: null,
                oil: null,
                liquids: null,
            },
            open: false,
            showToast: !localStorage.getItem(STORAGE_PROFILE_PROGRESS_TOAST),
            showText: this.props.showText,
        }

        this.unlisten = null;
        this.toast = null;
        this.axiosToken = null;
    }

    componentDidMount() {
        const {showToast, showText} = this.state;

        if(showToast || showText){
            this.fetch();
            this.unlisten = history.listen((location, action) => this.close())
        }
    }

    componentWillUnmount() {
        if(this.unlisten) this.unlisten();

        if(this.toast) toast.dismiss(this.toast);
        if(this.axiosToken) this.axiosToken.cancel();
    }

    fetch = () => {
        const {showToast} = this.state;

        this.setState({loading: true});

        this.axiosToken = axiosRoot.CancelToken.source();

        axios.get(API_PROFILE_PROGRESS_URL, {cancelToken: this.axiosToken.token})
            .then(response => {
                this.setState({
                    garage: response.data.garage,
                    profile: response.data.profile,
                    prices: response.data.prices,
                });

                if(showToast) this.showToast()

            })
            .catch(error => {
                if (!axiosRoot.isCancel(error)) {
                    captureException(error, API_PROFILE_PROGRESS_URL);
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                }
                this.setState({
                    loading: false,
                });

                captureException(error, API_PROFILE_PROGRESS_URL);
            })
    }

    showToast = () => {
        const {t} = this.props;

        if(this.percentFinished() === 100){
            return;
        }

        localStorage.setItem(STORAGE_PROFILE_PROGRESS_TOAST, timestamp());

        this.toast = toast.info(<Toast text={t('profile_progress.info', {percent: this.percentFinished()})} type="info"/>, {
            autoClose: false,
            onClick: () => this.open()
        });
    }

    itemValid = (item) => item ? !!Object.keys(item).length : false;

    percentFinished = () => {
        const {garage, profile, prices} = this.state;

        let all = 0, filled = 0;
        let data = {...(garage ? garage : {}), ...(profile ? profile : {}), ...(prices ? prices : {})};

        Object.keys(data).forEach(name => {
            all++;
            if(this.itemValid(data[name])) filled++;
        });

        return Math.round(100 / (all / filled));
    }

    open = () => this.setState({open: true})
    close = () => this.setState({open: false})

    render() {
        const {t} = this.props;
        const {open, garage, profile, prices, showText} = this.state;

        return (
            <React.Fragment>

                {showText &&
                    <div className="profile-progress-text">
                        {this.percentFinished() === 0 ? <div>{t('profile_progress.text_loading')}</div> :
                            this.percentFinished() !== 100 && <span className="link-style" onClick={this.open}>{t('profile_progress.info', {percent: this.percentFinished()})}</span>
                        }
                    </div>
                }

                {open &&
                    <ProfileProgress
                        garage={garage}
                        profile={profile}
                        prices={prices}
                        percentFilled={this.percentFinished()}
                        onClose={this.close}/>
                }
            </React.Fragment>
        )
    }
}

export default translate('translations')(ProfileProgressWidget);
