export const CAR_FORM_FIELDS = {
    licence_plate: [],
    make_id: [
        'first_registration_list',
        'first_registration',
        'ranges',
        'range_id',
        'types',
        'type_id',
        'years',
        'gears',
        'gear_id',
        'qual_md',
        'qual_md_list',
        'qual_lt',
        'qual_lt_list'
    ],
    first_registration: [
        'ranges',
        'range_id',
        'types',
        'type_id',
        'years',
        'gears',
        'gear_id',
        'qual_md',
        'qual_md_list',
        'qual_lt',
        'qual_lt_list'
    ],
    range_id: [
        'types',
        'type_id',
        'years',
        'gears',
        'gear_id',
        'qual_md',
        'qual_md_list',
        'qual_lt',
        'qual_lt_list'
    ],
    type_id: [
        'years',
        'gears',
        'gear_id',
        'qual_md',
        'qual_md_list',
        'qual_lt',
        'qual_lt_list'
    ],
    gear_id: ['qual_md', 'qual_md_list', 'qual_lt', 'qual_lt_list'],
    qual_md: ['qual_lt', 'qual_lt_list'],
    qual_lt: []
};
export default {
    CAR_FORM_FIELDS
};
