import i18n from 'i18next';
import resources from '../lang';
import {language} from "utils/language";

i18n.init({
    resources,
    lng: language(),
    fallbackLng: language(),
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
        formatSeparator: ','
    },
    react: {
        wait: true
    }
});

export default i18n;
