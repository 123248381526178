import React, { Component } from 'react';
import './EditableEntry.scss';

class EditableEntry extends Component {
    render() {
        const {list, buttons} = this.props;

        return (
            <div className="editable-entry">
                <div className="editable-entry__list">
                    {list.map((item, i) => (
                        <div className="row" key={i}>
                            <div className="col-xs-6 left">{item.name}</div>
                            <div className="col-xs-6 right">{item.value}</div>
                        </div>
                    ))}
                </div>
                <div className="editable-entry__footer">
                    <div className="row">
                        {buttons.map((button, i) => (
                            <div className={`col-xs-${12 / buttons.length}`} key={i}>
                                <span className={`pointer link-style edit-link`} onClick={button.click}>
                                    <i className={button.icon}/> {button.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default EditableEntry;
