import React, {Component} from 'react';
import Header from "components/layout/Header/Header";
import {withFormik} from "formik";
import {formHourlyPrices} from "app/config/yup";
import {connect} from 'react-redux';
import {translate} from "react-i18next";
import Select from "components/shared/Select/Select";
import Input from "components/shared/Input/Input";
import Popup from "components/shared/Popup/Popup";
import Button from 'components/shared/Button/Button';
import {labourPricesHourlyPricesRemove, labourPricesHourlyPricesFetch} from "store/actions/labourPrices.actions";
import {LOADER_LABOUR_PRICES} from "store/consts/loader.constants";
import EmptyList from "components/shared/EmptyList/EmptyList";
import ListLoader from "components/shared/ListLoader/ListLoader";
import {compare} from "utils/common";
import BaseCountry from "../../utils/BaseCountry";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class HourlyPriceUpdateContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddPricePopup: false
        };
    }

    componentDidMount() {
        this.props.dispatch(labourPricesHourlyPricesFetch());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(compare(nextProps.hourly, this.props.hourly)){
            this.closeHourlyPrice();
        }
    }

    years = () => {
        const {t} = this.props;

        return [
            {
                'id': 0,
                'name': t('pages.price_update.years.all_years'),
                'name_opposite': ''
            },
            {
                'id': '1',
                'name': t('pages.price_update.years.2_years_younger'),
                'name_opposite': t('pages.price_update.years.2_years_older')
            },
            {
                'id': '2',
                'name': t('pages.price_update.years.3_years_younger'),
                'name_opposite': t('pages.price_update.years.3_years_older')
            },
            {
                'id': '3',
                'name': t('pages.price_update.years.4_years_younger'),
                'name_opposite': t('pages.price_update.years.4_years_older')
            },
            {
                'id': '4',
                'name': t('pages.price_update.years.5_years_younger'),
                'name_opposite': t('pages.price_update.years.5_years_older')
            },
            {
                'id': '5',
                'name': t('pages.price_update.years.6_years_younger'),
                'name_opposite': t('pages.price_update.years.6_years_older')
            }
        ];
    };

    onFormFieldChange = (name, value) => {
        this.updateFormField(name, value);
    };

    handleChangeSelect = (name, {id}) => {
        this.onFormFieldChange(name, id);
    };

    updateFormField = (name, value) => {
        const {values, setValues} = this.props;

        values[name] = value;
        setValues(values);
    };

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    editPrice = (key) => {
        const {hourly: {prices}, setValues} = this.props;

        setValues({
            car_make_id: key,
            year: prices[key][0].year,
            younger_price: prices[key][0].price_rule.price,
            older_price: prices[key].length > 1 ? prices[key][1].price_rule.price : '',
            editing: key
        });

        this.setState({
            showAddPricePopup: true
        });
    };

    removePrice = (key) => {
        const {dispatch} = this.props;

        dispatch(labourPricesHourlyPricesRemove(key));
    };

    closeHourlyPrice = () => {
        const {resetForm} = this.props;

        this.setState({showAddPricePopup: false});
        resetForm();
    };

    addAllMakes = (carMakes) => {
        const {t} = this.props;

        let makes = Object.assign([], carMakes);
        makes.unshift({id:0, name: t('pages.price_update.section_form.label_option_all_makes')});
        return makes;
    };

    addHourlyPrice = () => {
        const {t, handleBlur, values, hourly: {carMakes}, handleSubmit, errors, touched, formSubmitting} = this.props;

        return (
            <Popup title={t('pages.price_update.section_form.add_price')} visible={this.state.showAddPricePopup} onClose={this.closeHourlyPrice}>

                <form onSubmit={e => handleSubmit(e)} className="hourly-price-form">

                    <div className="hourly-price-item">
                        <Select
                            id={"car_make_id"}
                            list={this.addAllMakes(carMakes)}
                            value={parseInt(values.car_make_id)}
                            selected={parseInt(values.car_make_id)}
                            error={touched.car_make_id && errors.car_make_id}
                            label={t('pages.price_update.section_form.label_car_make')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(id, option) => this.handleChangeSelect(id, option)}
                        >
                            {t('pages.price_update.section_form.label_option_all_makes')}
                        </Select>

                        <div className="row">
                            <div className={`${values.year > 0 ? 'col-xs-12' : 'col-xs-6'}`}>
                                <Select
                                    id={"year"}
                                    list={this.years()}
                                    value={values.year}
                                    selected={values.year}
                                    error={touched.year && errors.year}
                                    label={t('pages.price_update.section_form.label_production_year')}
                                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                                    onHasOneOption={(id, option) => this.handleChangeSelect(id, option)}
                                >
                                    {t('pages.price_update.section_form.label_production_year')}
                                </Select>
                            </div>
                            <div className="col-xs-6">
                                <Input
                                    type="tel"
                                    placeholder={t('pages.price_update.section_form.placeholder_younger_price')}
                                    name={"younger_price"}
                                    label={t('pages.price_update.section_form.label_younger_price')}
                                    onBlur={handleBlur}
                                    error={touched.younger_price && errors.younger_price}
                                    onChange={this.handleTextChange}
                                    value={values.younger_price}
                                    addOn={BaseCountry().currency}
                                    step={0.000001}
                                    float={true}
                                />
                            </div>
                            {values.year > 0 &&
                                <div className="col-xs-6">
                                    <Input
                                        type="tel"
                                        placeholder={t('pages.price_update.section_form.placeholder_younger_price')}
                                        name={"older_price"}
                                        label={this.years()[values.year].name_opposite}
                                        onBlur={handleBlur}
                                        onChange={this.handleTextChange}
                                        value={values.older_price}
                                        addOn={BaseCountry().currency}
                                        step={0.000001}
                                        float={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <Button disabled={formSubmitting.hourlyPrice} size="lg" type="primary">
                        {t('pages.price_update.section_form.button_save')}</Button>
                </form>
            </Popup>
        )
    };

    renderPrice = () => {
        const { t, hourly: {prices, carMakes}, formSubmitting } = this.props;

        return (
            <React.Fragment>

                <div className="list-of-prices">
                    <div className="row">
                        {Object.keys(prices).map((key) => {
                            let price = prices[key];

                            return (
                                <div className="col-sm-6 col-xs-12">
                                    <div className="list-of-prices__item" key={key}>
                                        <div className="row mb-10">
                                            <span className="col-xs-6">{t('pages.price_update.section_form.label_car_make')}</span>
                                            <span className="col-xs-6 font-weight-semi-bold text-light font-size-default">
                                            {this.addAllMakes(carMakes).map((carMake) => {
                                                if(price[0].price_rule.car_make_id === carMake.id){
                                                    return carMake.name
                                                }
                                            })}
                                        </span>
                                        </div>
                                        <div className="row mb-10">
                                        <span className="col-xs-6">
                                            {this.years().map((yearList) => {
                                                if(price[0].year === yearList['id']){
                                                    return yearList['name'];
                                                }
                                            })}
                                        </span>
                                            <span className="col-xs-6 font-weight-semi-bold text-light font-size-default">{price[0].price_rule.price} {BaseCountry().currency}</span>
                                        </div>

                                        {price.length > 1 &&
                                        <div className="row mb-10">
                                            <div className="form-group">
                                                <span className="col-xs-6">
                                                    {this.years().map((yearList) => {
                                                        return (price[0].year === yearList['id'] ? yearList['name_opposite'] : '')
                                                    })}
                                                </span>
                                                <span className="col-xs-6 font-weight-semi-bold text-light font-size-default">
                                                    {price[1].price_rule.price} {BaseCountry().currency}
                                                </span>
                                            </div>
                                        </div>
                                        }

                                        <div className="list-of-prices__item__footer">
                                            <div className="col-xs-6 text-center link-style edit-link" onClick={() => this.removePrice(key)} data-index={key}><i className="icon-bin"></i> {t('pages.price_update.section_form.remove')}</div>
                                            <div className="col-xs-6 text-center link-style edit-link" onClick={() => this.editPrice(key)} data-index={key}><i className="icon-edit"></i> {t('pages.price_update.section_form.change')}</div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {Object.keys(prices).length === 0 &&
                    <EmptyList label={t('pages.price_update.hourly_price.empty_list')} />
                }

                <SubmitContainer stat={true}>
                    <div className="services-save-footer">
                        <Button size="lg" type="primary" disabled={formSubmitting.hourlyPrice} onClick={() => this.setState({showAddPricePopup: true})}>
                            {t('pages.price_update.section_form.button_add_price')}
                        </Button>
                    </div>
                </SubmitContainer>

                {this.addHourlyPrice()}
            </React.Fragment>
        )
    };

    render() {
        const { t, loader } = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} />

                <div className="container">

                    {loader[LOADER_LABOUR_PRICES] ?
                        <ListLoader /> : this.renderPrice()
                    }

                </div>
            </React.Fragment>
        );
    }
}

const PriceUpdateForm = withFormik(formHourlyPrices)(HourlyPriceUpdateContainer);

const mapStateToProps = (state) => {
    return {
        hourly: state.labourPrices.hourly,
        formSubmitting: state.formSubmitting,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(PriceUpdateForm));
