import React, {Component} from 'react';
import {translate} from 'react-i18next';
import "./Statistics.scss";
import {compare} from 'utils/common';
import {STATUS_ACCEPTED, STATUS_OPEN, STATUS_REJECTED} from "../../../store/consts/package/package.status.constants";
import {sumBy} from 'lodash'
import {roundCeil} from "../../../utils/common";
import {getActivity, setActivity} from "../../../utils/activity";
import BaseCountry from "../../../utils/BaseCountry";
import DatePicker from "react-datepicker";
import language from "../../../utils/language";
import Button from "../Button/Button";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from "moment";

const locale = language();

class Statistics extends Component {

    constructor(props) {
        super(props)
        let startDate = new Date()
        this.state = {
            analytics: props.analytics,
            collapseLineChart: getActivity('analytics.statistics.collapseLineChart', false),
            collapseDoughnutChart: getActivity('analytics.statistics.collapseDoughnutChart', false),
            startDate: props.dateFrom ? props.dateFrom : startDate.setDate(startDate.getDate() - 90),
            endDate: props.dateTo ? props.dateTo : new Date(),
            startDateLabel: props.dateFrom ? props.dateFrom : startDate,
            endDateLabel: props.dateTo ? props.dateTo : new Date(),
        }

        this.lineChartRef = React.createRef()
        this.doughnutChartRef = React.createRef()
    }

    componentDidMount() {
        const {analytics} = this.state;

        if(analytics.length === 0){
            this.collapseLineChart()
            this.collapseDoughnutChart()
        }

        if (analytics) {
            this.lineChart()
            this.doughnutChart()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {analytics, collapseLineChart, collapseDoughnutChart} = this.state;

        if (collapseLineChart === false && prevState.collapseLineChart === true) {
            this.lineChart()
        }

        if (collapseDoughnutChart === false && prevState.collapseDoughnutChart === true) {
            this.doughnutChart()
        }

        if (compare(prevState.analytics, analytics)) {
            if(this.lineChartRef.current){
                this.updateLineChart(this.lineChartRef.current)
            }

            if(this.doughnutChartRef.current){
                this.updateDoughnutChart(this.doughnutChartRef.current)
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (compare(nextProps.analytics, this.props.analytics)) {
            this.setState({
                analytics: nextProps.analytics,
            })
        }
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }

    prepareLineChartData = () => {
        const {t, groupDays} = this.props;
        const {analytics} = this.state;

        return {
            labels: Object.keys(analytics).map(key => {
                if(groupDays > 1){
                    return moment(key).format("DD.MM") + "-" + moment(key).add(groupDays - 1, 'days').format("DD.MM")
                }
                return moment(key).format("DD.MM")
            }),
            datasets: [{
                type: 'bar',
                label: t('pages.analytics.accepted'),
                data: Object.keys(analytics).map(key => {
                    return analytics[key][STATUS_ACCEPTED]['price']
                }),
                backgroundColor: '#1cd091',
                borderColor: '#1cd091',
            },{
                type: 'bar',
                label: t('pages.analytics.statues.open'),
                data: Object.keys(analytics).map(key => {
                    return analytics[key][STATUS_OPEN]['price']
                }),
                backgroundColor: '#ff8914',
                borderColor: '#ff8914',
            }, {
                type: 'bar',
                label: t('pages.analytics.rejected'),
                data: Object.keys(analytics).map(key => {
                        return analytics[key][STATUS_REJECTED]['price']
                    }),
                backgroundColor: '#ff4d68',
                borderColor: '#ff4d68',
            }]
        }
    }
    lineChart = () => {

        if (this.lineChartRef === null || this.lineChartRef.current === null) {
            return
        }
        new Chart(this.lineChartRef, {
            type: 'scatter',
            data: this.prepareLineChartData(),
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true
                    },
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var value = data.datasets[0].data[tooltipItem.index];
                            var label = data.labels[tooltipItem.index];

                            if (value === 0.1) {
                                value = 0;
                            }

                            return label + ': ' + value + ' %';
                        }
                    }
                },
            }
        });
    }

    updateLineChart = (chart) => {
        chart.data.datasets = this.prepareLineChartData();
        chart.options.scales.x.max = this.state.endDate;

        chart.update();
    }

    prepareDoughnutChartData = () => {
        return [
            this.sumPriceByStatus(STATUS_OPEN),
            this.sumPriceByStatus(STATUS_ACCEPTED),
            this.sumPriceByStatus(STATUS_REJECTED),
        ];
    }

    doughnutChart = () => {
        const {t} = this.props;

        if (this.doughnutChartRef === null || this.doughnutChartRef.current === null) {
            return
        }

        new Chart(this.doughnutChartRef, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: this.prepareDoughnutChartData(),
                    backgroundColor: [
                        '#ff8914', '#1cd091', '#ff4d68'
                    ],
                }],
                labels: [t('pages.analytics.statues.open'), t('pages.analytics.statues.accepted'), t('pages.analytics.statues.rejected')]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                },
                cutout: '70%',
            }
        });
    }

    updateDoughnutChart = (chart) => {
        chart.data.datasets.data = this.prepareDoughnutChartData();
        chart.update();
    }

    collapseLineChart = () => {
        this.setState({
            collapseLineChart: !this.state.collapseLineChart
        }, () => {
            setActivity('analytics.statistics.collapseLineChart', this.state.collapseLineChart)
        })
    }

    collapseDoughnutChart = () => {
        this.setState({
            collapseDoughnutChart: !this.state.collapseDoughnutChart
        }, () => {
            setActivity('analytics.statistics.collapseDoughnutChart', this.state.collapseDoughnutChart)
        })
    }

    sumPriceByStatus = (status) => {
        const {analytics} = this.state;
        let list = Object.keys(analytics).map(date => {
            return Object.keys(analytics[date]).map(statusCode => {
                return analytics[date][statusCode];
            })
        }).flat(2);

        if(status){
            list = Object.keys(analytics).map(key => {
                return analytics[key][status]
            }).flat(1);
        }


        return roundCeil(sumBy(list, 'price'), true)
    }

    updateDateFilter = () => {
        const {updateFilter} = this.props;

        updateFilter({
            type: 'days',
            dateFrom: new Date(this.state.startDate),
            dateTo: new Date(this.state.endDate),
        })
    }

    render() {
        const {t, groupDays} = this.props;
        const {collapseLineChart, collapseDoughnutChart, startDate, endDate, startDateLabel, endDateLabel} = this.state;

        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-xs-8 col-sm-8 col-md-6 pull-r statistic-date-filter">
                        <div className="col-xs-5 col-sm-5 col-md-5 col-stat-4">
                            <div className="form-group">
                                <label>{t('pages.analytics.start_date')}</label>
                                <DatePicker
                                    placeholderText={t('pages.analytics.pick_date')}
                                    selected={startDate}
                                    locale={locale}
                                    className="time-picker-custom"
                                    dateFormat="dd.MM.yyyy"
                                    disabledKeyboardNavigation
                                    onChange={date => this.setStartDate(date)}
                                />
                            </div>
                        </div>
                        <div className="col-xs-5 col-sm-5 col-md-5 col-stat-4">
                            <div className="form-group">
                                <label>{t('pages.analytics.end_date')}</label>
                                <DatePicker
                                    placeholderText={t('pages.analytics.pick_date')}
                                    selected={endDate}
                                    locale={locale}
                                    className="time-picker-custom"
                                    dateFormat="dd.MM.yyyy"
                                    disabledKeyboardNavigation
                                    onChange={date => this.setEndDate(date)}
                                />
                            </div>
                        </div>
                        <div className="col-xs-2 col-sm-2 mt-30 col-md-2 col-stat-4 col-stat-btn">
                            <Button
                                size="sm"
                                type="primary"
                                onClick={this.updateDateFilter}
                                extraClass="filter-statistics-button"
                            >
                                show
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <div className="analytic-tab analytic-tab-statistics">

                            <div className="analytic-tab-header" onClick={this.collapseLineChart}>
                                <div className="sum-prices text-left">
                                    <span className="accepted">{this.sumPriceByStatus(STATUS_ACCEPTED)}</span>
                                    <span className="gray">/</span>
                                    <span
                                        className="rejected">{this.sumPriceByStatus(STATUS_REJECTED)}</span>
                                    <span className="gray">{BaseCountry().currency}</span>
                                    <span className={`statistic-chevron-default mt-22 chevron ${collapseLineChart ? 'close' : ''}`}><i className="icon-chevron-down"/></span>

                                    <div className="label">{t('pages.analytics.sum_prices_for_period',
                                        {
                                            date_from: moment(startDateLabel).format("DD.MM.YYYY"),
                                            date_to: moment(endDateLabel).format("DD.MM.YYYY")
                                        })}
                                    </div>
                                    <div className="label">{t('pages.analytics.days_per_column', {days: groupDays})}</div>
                                </div>
                            </div>

                            {!collapseLineChart &&
                            <div className="analytic-tab-content">
                                <div>
                                    <canvas id="statistics-chart" ref={ref => this.lineChartRef = ref}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="analytic-tab analytic-tab-statistics">
                            <div className="analytic-tab-header" onClick={this.collapseDoughnutChart}>
                                <div className="sum-prices">
                                    <div className="label">
                                        <div className="pull-l">
                                            {t('pages.analytics.sum_packages_for_period',
                                                {
                                                    date_from: moment(startDateLabel).format("DD.MM.YYYY"),
                                                    date_to: moment(endDateLabel).format("DD.MM.YYYY")
                                                })}
                                        </div>
                                        <span className={`statistic-chevron-default chevron ${collapseDoughnutChart ? 'close' : ''}`}><i className="icon-chevron-down"/></span>
                                    </div>

                                </div>
                            </div>

                            <div className="analytic-tab-content">
                                {!collapseDoughnutChart &&
                                <div>
                                    <canvas id="statistics-statuses-chart" ref={ref => this.doughnutChartRef = ref}/>
                                </div>
                                }

                                <div className="package-statuses">
                                    <div className="row">
                                        <div className="col-xs-6"><i className="icon icon--pending icon-clock-fill"/><span
                                            className="label">{t('pages.analytics.statues.open')}</span></div>
                                        <div className="col-xs-6 price">
                                            {this.sumPriceByStatus(STATUS_OPEN)}
                                            <span className="currency">{BaseCountry().currency}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6"><i className="icon icon--accepted icon-check-fill"/><span
                                            className="label">{t('pages.analytics.statues.accepted')}</span></div>
                                        <div className="col-xs-6 price">
                                            {this.sumPriceByStatus(STATUS_ACCEPTED)}
                                            <span className="currency">{BaseCountry().currency}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6"><i className="icon icon--rejected icon-cancell-fill"/><span
                                            className="label">{t('pages.analytics.statues.rejected')}</span></div>
                                        <div className="col-xs-6 price">
                                            {this.sumPriceByStatus(STATUS_REJECTED)}
                                            <span className="currency">{BaseCountry().currency}</span>
                                        </div>
                                    </div>

                                    <div className="package-statuses--total">
                                        <div className="row">
                                            <div className="col-xs-6"><span
                                                className="label">{t('pages.analytics.total_requested')}</span></div>
                                            <div className="col-xs-6 price">
                                        <span className="total-border">
                                            {this.sumPriceByStatus()}
                                            <span className="currency">{BaseCountry().currency}</span>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(Statistics)
