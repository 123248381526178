export function getFieldsToReset(iterable, object, callback) {
    if (iterable.length === 0) return;

    iterable.forEach(item => {
        const value = Array.isArray(object[item]) ? [] : null;
        callback(item, value);
    });
}

export function isArray(item) {
    return Array.isArray(item);
}

export function getValueFromArray(array, keyName, keyValue) {
    if (!array) return {};

    return array.find(item => item[keyName] === keyValue);
}

export default {
    getFieldsToReset,
    isArray
};
