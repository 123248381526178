import { setLocalStorageItem } from './storage'
import { STORAGE_ACTIVITY } from '../app/consts/storage.consts'
import { setWith, get } from 'lodash'

export const setActivity = (path, value) => {
    let activity = JSON.parse(localStorage.getItem(STORAGE_ACTIVITY))

    if (!activity) {
        activity = {}
    }

    setLocalStorageItem(STORAGE_ACTIVITY, JSON.stringify(setWith(activity, path, value, {})))
}

export const getActivity = (path, def) => {
    let activity = JSON.parse(localStorage.getItem(STORAGE_ACTIVITY))

    return get(activity, path, def)
}
