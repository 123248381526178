import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import "./AnalyticsEnhancedContainer.scss";
import {Link} from "react-router-dom";
import pages from "../../app/consts/routes";
import {connect} from "react-redux";
import { isSupervisor } from "../../store/consts/user.constants"

class AnalyticsEnhancedContainer extends Component {

    render() {
        const {t, user} = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)}/>

                <div className="container">

                    <div className="services-tabs price-update">

                        <div className="row">
                            <div className="col-xl-12 col-sm-6">
                                <Link to={pages.analytics_enhanced_packages} className="service-tab">
                                    <div className="service-tab__title">{t('pages.analytics_enhanced.packages')}</div>
                                    <div className="service-tab__tip">{t('pages.analytics_enhanced.packages_info')}</div>
                                </Link>
                            </div>
                            <div className="col-xl-12 col-sm-6">
                                <Link to={pages.analytics_enhanced_checklist} className="service-tab">
                                    <div className="service-tab__title">{t('pages.analytics_enhanced.checklist')}</div>
                                    <div className="service-tab__tip">{t('pages.analytics_enhanced.checklist_info')}</div>
                                </Link>
                            </div>
                            <div className="col-xl-12 col-sm-6">
                                <Link to={pages.analytics_enhanced_top_labours} className="service-tab">
                                    <div className="service-tab__title">{t('pages.analytics_enhanced.top_labours')}</div>
                                    <div className="service-tab__tip">{t('pages.analytics_enhanced.top_labours_info')}</div>
                                </Link>
                            </div>
                            <div className="col-xl-12 col-sm-6">
                                <Link to={pages.analytics_enhanced_top_cars} className="service-tab">
                                    <div className="service-tab__title">{t('pages.analytics_enhanced.top_cars')}</div>
                                    <div className="service-tab__tip">{t('pages.analytics_enhanced.top_cars_info')}</div>
                                </Link>
                            </div>

                            {isSupervisor(user.type) &&
                                <div className="col-xl-12 col-sm-6">
                                    <Link to={pages.analytics_enhanced_top_garages} className="service-tab">
                                        <div className="service-tab__title">{t('pages.analytics_enhanced.top_garage')}</div>
                                        <div
                                            className="service-tab__tip">{t('pages.analytics_enhanced.top_garage_info')}</div>
                                    </Link>
                                </div>
                            }

                        </div>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(translate('translations')(AnalyticsEnhancedContainer));
