import { WORK_TYPE_RADIO } from '../../consts/package/work'

export default {
  id: null,
  name: '',
  duration: 0,
  selected: false,
  parts: [],
  input_type: WORK_TYPE_RADIO,
  autogenerated: false,
}
