export const NOTIFICATIONS = '[NOTIFICATIONS]'

export const NOTIFICATIONS_FETCH = `${NOTIFICATIONS} FETCH`
export const NOTIFICATIONS_SET = `${NOTIFICATIONS} SET`
export const NOTIFICATIONS_APPEND = `${NOTIFICATIONS} APPEND`
export const NOTIFICATIONS_UPDATE_SEEN = `${NOTIFICATIONS} UPDATE SEEN`
export const NOTIFICATIONS_UPDATE_SEEN_ALL = `${NOTIFICATIONS} UPDATE SEEN ALL`
export const NOTIFICATIONS_SEEN = `${NOTIFICATIONS} SEEN`

export const notificationsFetch = () => ({
    type: NOTIFICATIONS_FETCH,
})

export const setNotifications = notifications => ({
    type: NOTIFICATIONS_SET,
    payload: {
        notifications,
    },
})

export const appendNotifications = notifications => ({
    type: NOTIFICATIONS_APPEND,
    payload: {
        notifications,
    },
})

export const updateSeenNotifications = notifications => ({
    type: NOTIFICATIONS_UPDATE_SEEN,
    payload: {
        notifications,
    },
})

export const updateSeenAllNotifications = () => ({
    type: NOTIFICATIONS_UPDATE_SEEN_ALL,
})

export const seenNotifications = notifications => ({
    type: NOTIFICATIONS_SEEN,
    payload: {
        notifications,
    },
})
