/* eslint-disable */
import React from 'react'
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR,
    TIRES_SEASON_ALL_SEASON,
    TIRES_SEASON_NORDIC,
    TIRES_SEASON_SUMMER,
    TIRES_SEASON_WINTER,
    TIRES_STEP_LIST_PREMIUM,
    TIRES_STEP_LIST_PRICE_QUALITY,
    TIRES_TYPE_BRAND_PREMIUM,
    TIRES_TYPE_BRAND_PRICE_QUALITY
} from "../../../store/consts/tires.constants";

export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage',
        },
        titles: {
            system_messages: 'System messages', //TODO: Translate
            generate_report: 'Reporting',
            customers: 'Customers',
            mechanics: 'Manage mechanics',
            login: 'Sign in',
            password_reset: 'Password reset',
            password_reset_new: 'New password activation',
            pick_garage: 'Choose garage',

            home: 'Garage Booster',
            price_update: 'Price settings',
            price_update_hourly_price: 'Edit hourly rate',
            price_update_oil_price: 'Edit oil price',
            price_update_liquids_price: 'Edit price for liquids',
            price_update_discount: 'Edit discount on parts',
            price_update_price_update_additional_percent_on_works: 'Additional percent on works',

            settings: 'Settings',
            user_profile: 'User Profile',

            add_cost: 'Garage Booster – Enter your car information',
            packages: 'Garage Booster – Packages list',
            package_details: 'Garage Booster – Package detail',
            labour_search: 'Garage Booster – Labour search',
            request_preview: 'Garage Booster – Preview your request.',
            confirm: 'Garage Booster – Your request has been sent.',

            analytics: 'Statistics',
            requests: 'Requests',
            request_details: 'Request details',
            request_package_details: 'Package details',
            request_checklist_details: 'Checklist',

            activities: 'Customer activity',
            activity: 'Activity',

            checklist_labour: 'Mastercheck works',
            official_service: 'Recommended services',
            customers_upload: 'Customers upload',

            analytics_enhanced: 'Statistic',
            analytics_enhanced_packages: 'Packages',
            analytics_enhanced_checklist: 'Mastercheck',
            analytics_enhanced_top_cars: 'Vehicle statistic',
            analytics_enhanced_top_labours: 'Work statistic',
            analytics_enhanced_top_garage: 'Garage statistic',

            tires_import: 'Tires import',
        },
        global: {
            app_version_out_of_date: 'New version of Garage Booster is available, please click here to update your application',
            app_version_out_of_date_info: 'New version of Garage Booster is available.',
            alt: {
                'request-send-success-icon': 'Success icon',
            },
            tabs: {
                pending: 'Pending',
                other: 'Other',
            },
            filter: {
                all: 'All',
                title: 'Filters',
                hide: 'Hide filters',
                distance: 'Distance',
                price: 'Price',
                else: 'Else',
                ratings: {
                    description: 'Only garages with ratings',
                    label: 'Need ratings',
                },
                official_garage: {
                    description: 'Only certified {carBrand} partner garages',
                    label: 'Official garage',
                },
                no_filters: 'No filters selected',
                save: 'Apply',
                reset: 'Reset',
            },
            illustration_card: {
                no_garages_nearby: {
                    title: 'No garage nearby offering selected services',
                    description: 'Change your location to see offers in different area.',
                },
            },
            or: 'or',
            cancel: 'Cancel',
            back: 'Back',
            more: 'more',
            skip: 'Skip',
            currency: 'CHF',
            tax: 'Tax',
            total: 'Total',
            total_with_vat: 'Total incl. VAT',
            discount: 'Discount',
            files: 'Photos',
            service: 'Service',
            to: 'to',
            part_number: 'Part number',
            km: 'km',
            loading: 'Loading ...',
            garage_reinitialize: 'Switching garage...',
            car: {
                make: 'Make',
                range: 'Range',
                type: 'Type',
                go_back: 'Edit',
                year: 'Year of production',
                licence_plate: 'Numberplate',
                contract_number: 'ContractNr',
                update_info: 'Update car information',
                edit: 'Edit',
                mileage: 'Mileage',
            },
            official_service: {
                title: 'RECOMMENDATION FOR YOUR CAR',
                description:
                    "Thanks to the data you have provided us together with the manufacturer's specifications for {{carBrand}}, we have set up a personalised service for your car: ",
                includes: 'INCLUDES',
            },
            preselected_service: {
                title: 'Selected services: ',
            },
            additional_service: {
                open: 'Additional service list',
                title: 'Maintenance',
                description: 'Pick additional works to refine the results.',
                uncheck_all: 'Uncheck all',
                save: 'Save',
            },
            msg_title: {
                error: 'Error message',
                info: 'Info message',
            },
            save: 'Save',
            update: 'Update',
            edit: 'Edit',
            remove: 'Remove',
            undo: 'Undo',
            undo_deleted: '{{num}} has been deleted',
            you_sure: 'Are you sure?',
            months: {
                0: 'January',
                1: 'February',
                2: 'March',
                3: 'April',
                4: 'May',
                5: 'June',
                6: 'July',
                7: 'August',
                8: 'September',
                9: 'October',
                10: 'November',
                11: 'December',
            },
            agreement: 'Accept the',
            terms_and_conditions: 'terms and conditions',
            privacy_policy: 'Privacy policy',
            no_data: 'No data',
            optional: 'optional',
        },
        tab: {
            car: 'CAR DATA',
            services_car: 'My car',
            services_maintenance: 'Car service and maintenance',
            order_overview: 'Order overview',
        },
        form: {
            placeholder: {
                make: 'Select make',
                range: 'Select range',
                type: 'Select type',
                gear: 'Select gear',
                first_registration: '2019',
                qual_md_list: 'Select car features',
                qual_lt_list: 'Select vehicle body',
                zip: 'Select zip',
                username: 'Enter username',
                username2: 'Enter username',
                year: '2019',
                first_name: 'First Name',
                last_name: 'Last Name',
                mechanic_type: 'Type',
                email: 'Email',
                phone: 'Phone',
                password: 'Password',
                password_confirmation: 'Password Confirm',
                city: 'City',
                color: 'Color',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Please select',
                },
                user_activity: 'Status',
                date: 'Pick a date',
                morning: 'Morning',
                afternoon: 'Afternoon',
                car_number: {
                    vin_swiss: 'Swiss Type Nr or VIN',
                    vin_kba: 'KBA number or VIN',
                    vin: 'VIN',
                    swiss: 'Swiss Type Nr',
                    kba: 'KBA number',
                    default: 'Car identifier', // za ovo nisam siguran
                },
                user_type: 'Account Type',
                mileage: 'Mileage',
            },
            label: {
                licence_plate: 'Numberplate',
                make: 'Make',
                range: 'Range',
                type: 'Type',
                gear: 'Gear',
                first_registration: 'Year of registration',
                qual_md_list: 'Car features',
                qual_lt_list: 'Vehicle body',
                username: 'Username',
                username2: 'Username',
                year: 'Year of registration',
                first_name: 'First name',
                last_name: 'Last name',
                phone: 'Phone',
                email: 'Email',
                password: 'Password',
                password_confirmation: 'Password Confirm',
                date: 'Date',
                availability: 'Availability',
                bookFor: 'Book for',
                book: 'Request',
                city: 'City',
                color: 'Color',
                car_number: {
                    vin_swiss: 'Swiss Type Nr or VIN',
                    vin_kba: 'KBA number or VIN',
                    vin: 'VIN',
                    swiss: 'Swiss Type Nr',
                    kba: 'KBA number',
                    default: 'Car identifier', // za ovo nisam siguran
                },
                user_type: 'Type',
                mileage: 'Mileage',
            },
            button: {
                next: 'Next',
                confirm: 'Confirm',
                submit: 'Continue',
            },
            select: {
                no_result: 'No result found.',
            },
        },
        validations: {
            label: {
                make: 'make',
                range: 'range',
                type: 'type',
                first_registration: 'year of registration',
                gear: 'gear',
                qual_md_list: 'car features',
                qual_lt_list: 'Vehicle body',
                car_number: 'Car number',
                avatar: 'Avatar',
                username: 'Username',
                username2: 'Username',
                firstname: 'first name',
                lastname: 'last name',
                phone: 'phone',
                email: 'email',
                password: 'password',
                password_match: 'Passwords must match',
                user_type: 'account type',

                zip_not_found: 'No cities with ZIP code {{name}}',
                zip_not_valid: 'Please select valid ZIP code.',
                zip_required: 'ZIP is required.',
                zip_must_be_number: 'Must be a number.',

                hourly_price: {
                    production_year: 'production year',
                    younger_price: 'younger price',
                },
            },
            default: {
                mixed: {
                    required: '${label} required',
                    number: 'You have to agree with our ${label}',
                },
                string: {
                    email: 'Please fill in a valid email',
                    min: 'Value too short',
                    max: 'Value too long',
                    test: 'You have to agree with our ${label}',
                    number: 'You have to agree with our ${label}',
                },
                boolean: {
                    required: 'You have to agree with our ${label}',
                    number: 'You have to agree with our ${label}',
                },
            },
            termin_picker: {
                drop_off_title: 'Drop-off availabilities',
                drop_off_description: 'Select your available date and preferred time to drop-off your car.',
                drop_off_description_upto: 'Please select a desired date and the drop-off time for your car.',
                maximum: 'Maximum number of dates to pick is 5.',
                required: 'Please pick a date.',
            },
            licence_plate: {
                canton: {
                    valid: 'Valid canton',
                    nonValid: 'Invalid canton',
                },
                number: {
                    valid: 'Valid number',
                    nonValid: 'Invalid number',
                },
            },
            car_number: {
                invalid_format: 'Invalid format for SWISS',
            },
        },
        header: {
            profile_settings: 'Profile settings',
            reporting: 'Reporting',
            switch_garage: 'Switch garage',
            settings: 'Garage settings',
            price_update: 'Price Update',
            mechanic: 'Mechanics',
            logout: 'Logout',
            customers: 'Customers',
            checklists: 'Checklists',
            terms_and_conditions: 'Terms and conditions',
            privacy_policy: 'Privacy policy',
            analytics_enhanced: 'More statistic',
            tires_upload: 'Tires import',
            notifications: 'Information',
        },
        footer: {
            tabs: {
                home: 'Home',
                make_requests: 'Make Request',
                requests: 'All Requests',
                analytics: 'Analytics',
                price_update: 'Price update',
                settings: 'Settings',
                general_settings: 'General settings',
                mechanics: 'Mechanics',
                news: 'News',
            },
        },
        confirm_popup: {
            ok: 'OK',
            cancel: 'Cancel',
        },
        user: {
            activity: {
                active: 'Active',
                inactive: 'Inactive',
            },
            roles: {
                manager: 'Manager',
                mechanic: 'Mechanic',
                mechanic_advanced: 'Mechanic Advanced',
                supervisor: 'Supervisor',
            },
        },
        pages: {
            system_messages: {
                title: 'System messages', //TODO: Translate
                news: 'News', //TODO: Translate
                no_messages: 'There are no messages', //TODO: Translate
                mark_as_read: 'Mark as read', //TODO: Translate
                mark_as_unread: 'Mark as unread', //TODO: Translate
                filters: {
                    all_types: 'All types', //TODO: Translate
                    type_maintenance: 'Maintenance', //TODO: Translate
                    type_incident: 'Incident', //TODO: Translate
                    type_warning: 'Warning', //TODO: Translate
                    type_info: 'Info', //TODO: Translate
                    type_update: 'Update', //TODO: Translate
                }
            },
            tire_prices: {
                title: 'Tire prices',
                run_flat: 'Run flat',
                tire_disposal_pkw: 'Tire disposal PKW',
                tire_disposal_4x4_llkw: 'Tire disposal 4x4 LLKW',
                rim_size_17_18: 'Rim size 17 and 18',
                rim_size_lt_16: 'Rim size equal or less than 16',
                rim_size_gt_19: 'Rim size equal or greater than 19',
                save_success: 'Prices are saved successfully'
            },
            generate_report: {
                pick_date: 'Pick a date',
                title: 'Generate excel report',
                start_date: 'Start date',
                end_date: 'End date',
                generate: 'Generate',
                concept_total: 'TOTAL',
            },
            checklist_labour: {
                title: 'Checklist labours',
                search: 'Search...',
                no_results: 'There is no checklist labours with searching criteria.',
                save: 'Save',
            },
            customers: {
                title: 'Customers',
                search: 'Search...',
                edit_title: 'Update a customer',
                first_name: 'First name',
                last_name: 'Last name',
                email: 'E-mail',
                phone: 'Phone number',
                home_phone: 'Home phone number',
                licence_plates: 'Licence plates',
                first_name_required: 'First name is required',
                last_name_required: 'Last name is required',
                email_required: 'E-mail is required',
                phone_required: 'Phone number is required',
                no_results: 'There is no customers with searching criteria.',
                name: 'Name',
                customers_upload: 'Customers upload',
                file_upload_started: 'Upload started, please wait to finish.',
                file_upload_success: 'File successfully uploaded.',
                file_upload_error: 'Something went wrong.',
                file_upload_missing: 'File is missing. Please choose a file first.',
                upload: 'Upload',
                customers_upload_title: 'Customers upload',
                loading_upload_message: 'Upload in progress. Please wait.',
                resolve_duplications: 'Resolve duplications',
                skip: 'Skip',
                add: 'Add',
                overwrite: 'Overwrite',
                resolve_duplications_in_progress: 'Resolving duplicates in progress...',
                choose_file: 'Choose file',
            },
            home: {
                title: 'Home',
                new_requests: 'New requests:',
            },
            mechanics: {
                title: 'Manage mechanics',
                list_is_empty: 'List of mechanics is empty',
                add_new_person: 'Add new person',
                change_person_details: 'Change person details',
            },
            requests: {
                title: 'Requests',
                mechanic: 'Mechanic',
                created_by: 'Created by',
                no_results: 'There are no requests found.',
                waiting_for_response: 'Waiting for a response',
                no_requests: 'No requests',
                last_updated: 'Last updated',
                termin: 'Termin',
                test: 'Test',
                tab: {
                    pending_manager: 'Mechanic',
                    pending_user: 'Pending user',
                    responded: 'Responded',
                    archived: 'Archived',
                },
                form: {
                    label: {
                        search: 'Search',
                    },
                    placeholder: {
                        search: 'Enter car or client name',
                    },
                },
            },
            request_details: {
                print_pdf: 'Print PDF',
                title: 'Request details',
                no_packages: 'No packages here',
                approve_request: 'Approve request',
                reject_request: 'Reject request',
                edit_request: 'Edit request',
                edit_package: 'Edit package',
                remind_user: 'Remind user',
                duplicate_request: '+ Send new request',
                tab: {
                    accepted: 'Accepted',
                    rejected: 'Rejected',
                },
                package: {
                    title: 'Package details',
                    labours: {
                        title: 'Labours',
                    },
                    works: {
                        title: 'Works',
                    },
                    parts: {
                        title: 'Parts',
                    },
                    tires: {
                        title: 'Tires',
                        mounting_total_price: 'Mounting tires (incl balancing)',
                    },
                    client: {
                        title: 'Client',
                    },
                    vehicle: {
                        title: 'Vehicle',
                    },
                    files: {
                        title: 'Files',
                    },
                    log: {
                        title: 'Log',
                        status: {
                            1: 'Open request',
                            2: 'Waiting for Manager approval',
                            3: 'Sent to the customer',
                            4: 'Customer responded',
                            41: 'Confirmed by garage',
                            5: 'Rejected by Manager',
                            6: 'Archived',
                        },
                    },
                    sms_replies: {
                        title: 'SMS Replies',
                    },
                    description: {
                        title: 'Description',
                    },
                    additional_percent_on_works: 'Small parts surcharge on works',
                    packages: {
                        title: 'Work details',
                    },
                    packages_accepted: {
                        title: 'Accepted',
                    },
                    packages_rejected: {
                        title: 'Rejected',
                    },
                    packages_opened: {
                        title: 'Open',
                    },
                    packages_status: {
                        title: 'Request status',
                    },
                    response_time: {
                        title: 'Response time',
                    },
                },
                client: {
                    approve: {
                        title: 'User information',
                        subtitle: 'Enter user information to send request',
                    },
                    remind: {
                        title: 'Remind data',
                        subtitle: 'Enter user data to send notification',
                    },
                },
                sms: {
                    in_progress: 'SMS sent',
                    delivered: 'SMS delivered',
                },
                appointment_discount: 'Discount on later appointment',
                user_appointment: 'Appointment',
                total_accepted_with_vat: 'Total accepted incl VAT',
                read_more: 'Show more',
                read_less: 'Show less',
                car_number: {
                    ch: 'Swiss Type Nr or VIN',
                    de: 'KBA number or VIN',
                },
                checklist_link: 'Mastercheck',
                checklist: {
                    title: 'Mastercheck',
                    ok: 'ok',
                    not: 'not',
                    print_pdf: 'Print PDF',
                },
                archive: {
                    label: 'Archive'
                },
                is_test: 'Request marked as test',
                mark_as_test: 'Mark as test',
                unmark_as_test: 'Unmark as test',
            },
            analytics: {
                title: 'Analytics',
                mechanics_sub: 'Mechanics',
                statues: {
                    open: 'Pending',
                    accepted: 'Accepted',
                    rejected: 'Declined',
                    created: 'Sent to the customer',
                    all_passed: 'Fully ok',
                },
                start_date: 'Start date',
                end_date: 'End date',
                days_per_column: 'Days per column: {{days}}',
                pick_date: 'Pick a date',
                filter_button: 'Show',
                total_requested: 'Total requested',
                requested: 'Requested',
                accepted: 'Accepted',
                rejected: 'Declined',
                sum_prices: 'Approved vs Declined works in last {{months_num}} months in {{currency}}',
                sum_prices_for_period: 'Approved vs Declined jobs for period {{date_from}} to {{date_to}}',
                sum_packages_by: 'Value of works per status in last {{months_num}} months',
                sum_packages_for_period: 'Value of work per status for period {{date_from}} to {{date_to}}',
                top_ranges: 'Top car ranges',
                top_labours: 'Top labours',
                titles: {
                    packages: 'Packages',
                    statistics: 'Statistics',
                    most_requested: 'Most requested',
                    checklists: 'Checklists',
                },
                filters: {
                    users: 'Statistics',
                    all: 'Garage total',
                    mine: 'Me',
                    last_months: '{{months}} months',
                },
                garage_reinitialize: {
                    label: 'Switch garage',
                    concept_total: 'TOTAL',
                },
                more_statistics: 'More statistic',
            },
            login: {
                title: 'Sign in',
                submit: 'Sign in',
                forget_password: 'Forgot password?',
            },
            password_reset: {
                title: 'Password reset',
                tip: 'Share your email with us, and we will send you instructions how to reset the password.',
                submit: 'Reset password',
            },
            password_reset_new: {
                title: 'New password activation',
                tip: 'Enter your new password in the following fields.',
                submit: 'Reset password',
            },
            pick_garage: {
                title: 'Choose garage',
                tip: 'Please choose garage you want to sign in as.',
            },
            price_update: {
                title: 'Price update',
                section_tabs: {
                    tab_hourly_rate: 'Hourly rate',
                    tab_oil: 'Oil',
                    tab_liquids: 'Liquids',
                    tab_discount: 'Discount',
                    tab_additional_percent_on_works: 'Small parts surcharge',
                    tab_tires: 'Tires/Wheels',
                    tab_cleaning: 'Cleaning',
                    tab_clima: 'Air condition',
                    tab_other: 'Other',
                    tab_tip_hourly_price: 'Define prices for vehicles',
                    tab_tip_oil: '5w40, 10w30 and other',
                    tab_tip_liquids: 'Brake & cooling',
                    tab_tip_discount: 'Discount on parts',
                    tab_tip_additional_percent_on_works: 'Small parts surcharge on work',
                    tab_tire_change: 'Tires change',
                    tab_tip_tire_change: 'Tires change'
                },
                section_form: {
                    label_hourly_rate: 'Hourly rate',
                    label_option_all_makes: 'All makes',
                    button_add_price: 'Add new price',
                    button_add_discount: 'Add new discount',
                    button_save: 'Save',
                    button_uploading: 'Uploading...',
                    service_not_offered: 'Don\t offer this service.',
                    hourly_price_remove: 'Remove',
                    label_car_make: 'Make',
                    label_production_year: 'Production year',
                    label_younger_price: 'Younger price',
                    label_younger_discount: 'Younger than selected',
                    placeholder_younger_price: 'Price',
                    change: 'Change',
                    remove: 'Remove',
                    add_price: 'Add price',
                    label_discount: 'Discount',
                    label_show_appointments_discount: 'Show termins with discounted price',
                    label_additional_percent_on_works: 'Additional percent on works',
                },

                hourly_price: {
                    title: 'Hourly price',
                    empty_list: 'Price list is empty.',
                },
                oil_price: {
                    title: 'Oil price',
                },
                liquids_price: {
                    title: 'Liquids price',
                },
                discount: {
                    title: 'Discount price',
                    appointments_title: 'Discount on appointments',
                    parts_title: 'Discount on parts',
                    brakes_title: 'Discount on brakes',
                    empty_list: 'No discounts saved.',
                },
                price_update_additional_percent_on_works: {
                    title: 'Additional percent on works',
                },

                years: {
                    all_years: 'All production years',
                    '2_years_younger': '2 years and younger',
                    '2_years_older': 'Older than 2 years',
                    '3_years_younger': '3 years and younger',
                    '3_years_older': 'Older than 3 years',
                    '4_years_younger': '4 years and younger',
                    '4_years_older': 'Older than 4 years',
                    '5_years_younger': '5 years and younger',
                    '5_years_older': 'Older than 5 years',
                    '6_years_younger': '6 years and younger',
                    '6_years_older': 'Older than 6 years',
                },
            },
            settings: {
                title: 'Settings',
                section_tabs: {
                    change_details: 'Change details',
                    phones: 'Contact number (displayed to customers)',
                    time_due: 'Time due',
                    communication: 'Notifications',
                    customer_signature: 'Signature displayed to the customer',
                    mastercheck_layout: 'Pick mastercheck layout'
                },
                section_form: {
                    label: {
                        address: 'Address:',
                        web: 'Web:',
                        zip_id: 'Zip:',
                        logo: 'Logo:',
                        notification_phone: 'Phone:',
                        notification_email: 'Email:',
                        time_due: 'Time due',
                        customer_signature: 'Signature displayed to the customer',
                    },
                    placeholder: {
                        address: 'Address:',
                        web: 'Web:',
                        zip_id: 'Zip:',
                        logo: 'Logo:',
                        email: 'Enter email',
                        phone: 'Enter phone',
                        time_due: 'Time due',
                        customer_signature: 'Signature displayed to the customer',
                    },
                    logo_change: 'Change',
                    logo_upload: 'Upload logo',
                    avatar_upload: 'Upload avatar',
                    avatar_change: 'Change avatar',
                    submit_user_details: 'Save',
                    notification_detail: 'List all associated recipients in order to get notifications.',
                    phones_detail: 'List all phones to show to the client.',
                    add_new_phone: 'Add new phone',

                    file_size: 'File too large',
                    file_format: 'Unsupported format',

                    disable_time_due: 'Disable time due option',

                    communication: {
                        vonage_multiple: 'SMS',
                        mail: 'E-mail',
                        push_notification: 'Push',
                    },
                    communication_required: 'Minimum one notification channel is required',
                },
                section_popup: {
                    header_remove: 'Are you sure you want to remove recipient?',
                    header_add: 'Add new recipient',
                    header_update: 'Update recipient',
                    remove: 'Remove',
                },
            },
            user_profile: {
                title: 'User Profile',
                section_tabs: {
                    default_language: 'Pick a language',
                    change_password: 'Change password',
                    change_details: 'Edit details',
                },
                section_form: {
                    label: {
                        current_password: 'Old password',
                        new_password: 'New password',
                        repeat_password: 'New password confirmation',
                        notification_phone: 'Mobile phone:',
                        notification_email: 'E-Mail:',
                        first_name: 'Name',
                        last_name: 'Last name',
                        email: 'E-Mail',
                        phone: 'Mobile phone',
                        username: 'Username',
                    },
                    placeholder: {
                        current_password: 'Enter old password',
                        new_password: 'Enter new password',
                        repeat_password: 'Confirm new password',
                        notification_email: 'Enter E-Mail',
                        notification_phone: 'Enter mobile phone',
                        first_name: 'Enter first name',
                        last_name: 'Enter last name',
                        email: 'Enter E-Mail',
                        phone: 'Enter mobile phone',
                        username: 'Username',
                    },
                    avatar: {
                        change: 'Change',
                        crop: {
                            select: 'Select image',
                            zoom: 'Zoom',
                            rotate: 'Rotate',
                            save: 'Save crop image',
                            cancel: 'Cancel',
                            title: 'Crop image',
                        },
                    },
                    submit_new_password: 'Change password',
                    submit_user_details: 'Save',

                    file_size: 'File too large',
                    file_format: 'Format is not allowed',
                },
            },
            add_cost: {
                title: 'Enter your car information',
                select_method: 'Please, select one of the following methods for finding a car.',
                method_licence_plate: 'Licence plate',
                method_licence_plate_tip: 'You will be asked to enter canton & plate number only',
                method_swiss: 'SWISS',
                method_swiss_tip: 'Find your car by entering SWISS code',
                method_manual: 'Manual',
                method_manual_tip: 'Find your car by entering details manually',

                method_title_lp: 'Find car by licence plate',
                method_title_swiss: 'Find car by SWISS',
                method_title_multiple: 'Choose your cars color',
                method_title_manual: 'Enter details manually',

                licence_plate: {
                    title: 'Enter your car information and get instant offers.',
                    manual: "Don't know your plate number?",
                    sourcing: 'Sourcing your car Information',
                    sourced_success: 'We successfully sourced your car information.',
                },
                car_number: {
                    vin_swiss: "Don't know the Swiss Type Nr or VIN?",
                    vin_kba: "Don't know the KBA number or VIN?",
                    vin: "Don't know the VIN?",
                    swiss: "Don't know the Swiss Type Nr?",
                    kba: "Don't know the KBA number?",
                    default: "Don't know the car number?",
                },
                form_sidebar_label_1: '2 years warranty on work and parts',
                form_sidebar_label_2: 'Comparison of more than 450 garages',
                form_sidebar_label_3: 'Free cancellation',
                licence_plate_tooltip:
                    'The vehicle identification is carried out with the help of the following source: Federal Roads Office FEDRO.',
                swiss: {
                    manual: 'Find car by entering details manually',
                },
                packages_reset: {
                    title: 'Restarting packages',
                    description: 'Please wait',
                },
                draft: {
                    title: 'Drafts:',
                    loading: 'Please wait',
                    fetching_list: 'Please wait',
                    show_less: 'Show less',
                    show_more: 'Show more',
                },
                tab_change: {
                    title: 'By leaving this page, your offer will go to draft, are you sure you wont to leave?',
                    accept: 'Leave',
                    cancel: 'Stay on the page',
                },
                search_user: {
                    section_form: {
                        label: {
                            search: 'Existing customer',
                        },
                        placeholder: {
                            search: 'Name or surname of customer',
                        },
                    },
                },
            },
            packages: {
                title: 'Packages list',
                selected_packages: 'Selected Labours:',
                show_more: 'Show more',
                show_less: 'Show less',
                add_item: '+ Add  related labours to this package',
                extend_properties: 'Priority/Files',
                edit: 'Edit',
                add_new_labour: '+ New labour',
                add_new_official_service: '+ New Service',
                add_tires: '+ New Tire Change',
                next: 'Next',
                total: 'TOTAL',
                total_incl_vat: 'Total incl VAT',
                remove: 'Remove',
                missing_works_parts: 'Some data is missing',
                empty_list: 'Your list of labours is empty.',
                confirm_car_update: {
                    title: 'Are you sure?',
                    subtitle: 'Changing car will reset selected works. Are you sure?',
                },
                confirm_remove: {
                    title: 'Are you sure?',
                    subtitle: 'Are you sure you want to remove this labour.',
                },
                package_name: 'Package',
                service_package_name: 'Service according to {{make}} maintenance plan',
                checklist: 'Mastercheck',
                or: 'or',
                max_number: 'Maximum of {{max}} packages per request.',
                edit_official: 'Edit',
                edit_tires: 'Edit',
                official_package_mileage: ' - mileage {{mileage}}km',
            },
            package_details: {
                title: 'Package details',

                level_of_urgency: 'Level of urgency',
                add: 'Add',
                edit: 'Edit',
                change: 'Change',
                add_edit: 'Add / Edit',
                file_uploads: 'File uploads',
                no_files: 'No files',
                no_description: 'No description',
                attach_new_file: 'Attach new file',
                available_works: 'Available works',
                available_parts: 'Available parts',
                more: 'more',
                details: 'Details',
                remove: 'Remove',
                removing: 'removing...',
                missing_work: 'Works are missing',
                missing_parts: 'To add missing parts go to Details!',
                add_discount: 'Add discount',
                total: 'Total',
                discount_on_parts: 'Discount on parts',
                discount_on_brakes: 'Discount on brakes',
                discount_package: 'Discount',
                total_all: 'Total incl VAT & discount',
                price_fixed: 'Fixed price',
                official_package_mileage: ' - mileage {{mileage}}km',

                urgency: {
                    title: 'Urgency',
                    subtitle: 'Select level of importance for new additional cost.',
                    urgency_safety_relevant: 'Security-related',
                    urgency_urgent: 'Important',
                    urgency_recommended: 'Recommended',
                },

                files: {
                    title: 'Upload files',
                    skip: 'Skip',
                    see_file: 'Click here to open file',
                    see_video: 'Click here to open video file',
                    file_size: 'File too large. Maximum size is {{size}}MB',
                    file_format: 'Unsupported format. Supported are: {{format}}',
                    files_max: 'Maximum number of files is {{max}}',
                    record: 'Record',
                    audio: 'Audio',
                    image: 'Image',
                    record_audio: {
                        title: 'Record voice message',
                        rec: 'Rec',
                        cancel: 'Cancel',
                        pause: 'Pause',
                        stop: 'Stop',
                        save: 'Save',
                    },
                    remove: {
                        title: 'Delete file?',
                    },
                },

                description: {
                    title: 'Add Description',
                    item: 'Description',
                    not_added: 'Not yet added',
                    subtitle: 'Select level of importance for new additional cost.',
                },

                discount: {
                    title: 'Add Discount',
                    section_form: {
                        label: {
                            name: 'Name',
                            price: 'Discount price ({{currency}})',
                        },
                        placeholder: {
                            name: 'Name',
                            price: 'Price',
                        },
                    },
                },

                confirm_edit: {
                    title: 'Are you sure?',
                    subtitle: 'Adding new labour can overwrite some changes you have made in previous labours',
                },

                labour: {
                    works: {
                        title: 'Available works',
                        add_more: '+ Add more work',
                        list: {
                            title: 'Works list',
                        },
                        section_form: {
                            label: {
                                name: 'Name',
                                description: 'Description',
                                duration: 'Duration',
                            },
                            placeholder: {
                                name: 'Enter work name',
                                description: 'Description',
                                duration: 'Enter work duration',
                            },
                        },
                        edit: {
                            title: 'Edit work',
                        },
                        add: {
                            title: 'Add work',
                        },
                    },
                    parts: {
                        title: 'Parts',
                        add_more: '+ Add more parts',
                        versions: {
                            title: 'Parts',
                        },
                        set: {
                            title: 'Edit part set',
                            section_form: {
                                label: {
                                    name: 'Name',
                                    price: 'Price',
                                    amounts: 'Amounts',
                                },
                                placeholder: {
                                    name: 'Enter part set name',
                                    price: 'Enter part set price',
                                    amounts: 'Enter amounts of part set',
                                },
                            },
                        },
                        add: {
                            title: 'Add new part',
                        },
                    },
                },
            },
            labour_search: {
                title: 'Search and Select Labour',
                tip_on_no_result: 'Once you add it, it will be saved to your list to choose from in future.',

                form: {
                    label: 'Search',
                    placeholder: 'Replace, air, brakes...',
                    no_result: 'No result found.',
                },
                package: {
                    title: 'Package',
                    subtitle: 'This item will be added to the same additional cost.',
                },
                no_result_tip: 'We couldn’t find any result in the list for the following:',
                new_package: '+ new',
                add_no_result: 'Select & add to list',
                new_labour_data_title: 'Loading labours data',
                new_labour_loading_title: 'Creating new labour',
                new_labour_loading: 'Please wait',
                created: 'Labour successfully created',

                official_service: {
                    section_form: {
                        label: {
                            km_stand: 'Mileage',
                        },
                        placeholder: {
                            km_stand: 'Mileage',
                        },

                        submit: 'Get official services',
                    },
                    not_exist: 'Official service does not exist',
                    popup_title: 'Service according to {{make}} maintenance plan',
                    fetching: 'Fetching official services',
                },
            },
            request_preview: {
                title: 'Request preview',
                send: 'Send request',
                book_now: 'Confirm Quote Now',
                checkout: 'Checkout',
                car_details: 'Car details',
                customer_details: 'Customer details',
                official_package_mileage: ' - mileage {{mileage}}km',
                today: 'Allow appointment for today',

                section_form: {
                    label: {
                        first_name: 'First name',
                        last_name: 'Last name',
                        email: 'Email',
                        phone: 'Phone',
                        time_due: 'Time due',
                        contract_number: 'Contract number',
                        comment: 'Comment',
                    },
                    placeholder: {
                        first_name: 'Enter first name',
                        last_name: 'Enter last name',
                        email: 'Enter email',
                        phone: 'Enter phone',
                        contract_number: 'Contract number',
                        comment: 'Comment',
                    },
                    oneOfRequired: 'Phone or email is required',

                    skip_time_due: 'Skip',
                },

                appointment_discounts: {
                    show_appointments: 'Show appointments',
                    edit: {
                        title: 'Sell discount',
                        link: 'Edit sell discount',
                        save: 'Save',
                        tip:
                            'Updates your discount for future dates. The change affects all selected appointments and is saved.',
                    },
                    header: {
                        termin: 'Termin',
                        discount: 'Discount',
                        total: 'Total',
                    },
                    list: {
                        is_today: 'Today',
                    },
                },
                user_exists: {
                    email: 'Email exists for existing user. Select this user instead?',
                    phone: 'Phone exists for existing user. Select this user instead?',
                },
            },
            confirm: {
                title: 'Request has been sent',
                headline: 'Request successfully sent',
                description:
                    'Your request is successfully sent to the client. Expect to be notified via email/sms once he respond.',
                description_mechanic: 'Request has been sent to the Manager for approval.',
                go_home: 'Go back to home',
                new_request: 'Make new request',
                book_now_headline: 'Request booked successfully',
                book_now_description: 'Your request was successfully booked. You can start with the work now.',
            },
            activities: {
                title: 'Customer activity',
                segment: {
                    today: 'today',
                    yesterday: 'yesterday',
                    older: 'older',
                },
                section_form: {
                    placeholder: {
                        search: 'Search',
                    },
                },
                last_activity: 'last activity',
                at: 'at',
            },
            activity: {
                title: 'Activity',
                segment: {
                    today: 'today',
                    yesterday: 'yesterday',
                },
                section_form: {
                    placeholder: {
                        message: 'Write with the customer',
                    },
                },
                type: {
                    manager_waiting: {
                        name: 'Mechanic {{first_name}} {{last_name}}',
                        body: 'Requested approval of a additional service',
                    },
                    manager_created: {
                        name: 'Manager {{first_name}} {{last_name}}',
                        body: 'Request additional service',
                    },
                    manager_accepted: {
                        name: 'Manager {{first_name}} {{last_name}}',
                        body: 'Accepted request for additional service',
                    },
                    manager_rejected: {
                        name: 'Manager {{first_name}} {{last_name}}',
                        body: 'Rejected requested additional service',
                    },
                    sms_garage_reply: {
                        name: 'Manager {{first_name}} {{last_name}}',
                    },
                    sms_user_reply: {
                        name: '{{first_name}} {{last_name}}',
                    },
                    user_responded: {
                        name: 'Client {{first_name}} {{last_name}}',
                        accepted: 'Accepted {{hasAccepted}} out of {{length}} packages of additional service',
                        rejected: 'Rejected all packages of additional services',
                    },
                    details: 'See details',
                },
                new_event: 'New event',
            },
            checklist_labours: {
                types: {
                    position: {
                        subtitle: 'Which positions should be changed?',
                        save: 'Save',
                    },
                    list: {
                        save: 'Save',
                    },
                },
                save: 'Continue',
                archive: 'Archive',
                add_new_labour: '+ New labour',
                ok: 'ok',
                not: 'not',
                on_leave: 'Are you sure, you want to leave?',
                note: { //TODO: Translate
                    title: 'Add new note',
                    new: 'New note',
                    view: 'View note',
                    label: 'Note',
                },
            },
            official_service: {
                title: 'Recommended services',
                total_official: 'Total incl. VAT',
                submit: 'Save',
                no_recommended: 'No official service recommendation for this vehicle.',
                or_more: 'or more',
                info: {
                    ok: 'OK',
                },
                service_interval_title: 'Service at ',
                show_more: 'more',
                show_less: 'less',
            },
            analytics_enhanced: {
                title: 'Statistic',

                export: 'Export to excel',

                packages: 'Packages',
                checklist: 'Mastercheck',
                top_cars: 'Vehicle statistic',
                top_labours: 'Work statistic',
                top_garage: 'Garage statistic',

                packages_info: 'Accepted/rejected',
                checklist_info: 'Masterchecks processed',
                top_cars_info: 'Top car models',
                top_labours_info: 'Top works',
                top_garage_info: 'Top garages',

                date_picker: {
                    from: 'From',
                    to: 'To',
                },
                lists: {
                    top_10_by_revenue: 'Most revenue',
                    worst_10_by_revenue: 'Least revenue',
                    top_10_by_checklists: 'TOP 10 Masterchecks done',
                },
                column_names: {
                    requests: 'requests',
                    revenue: 'revenue',
                    total: 'suggested offers',
                    checklists: 'mastercheck',
                    accepted: 'revenue',
                    percentage: 'quota',
                },
            },
            tires_import: {
                title: 'Tires import',
                importing_in_progress: 'Import is in progress...',
                label: 'Choose EXCEL file',
                btn: 'Import',
                import_started: 'Tires are being imported. You will be notified when is done.',
                import_success: 'Tires are successfully imported.',
                import_failed: 'There was an error importing tires.',
            }
        },
        notification: {
            empty_list: 'No notifications',
            manager_waiting: 'sent new work for approval',
            manager_accepted: 'accepted your created work',
            manager_rejected: 'rejected your created work',
            user_responded: {
                accepted: 'accepted {{accepted}} of {{packagesLength}} packages',
                rejected: 'rejected the package',
            },
            sms_reply: '{{first_name}} {{last_name}} replied via sms to your work',
            details: 'Details',
            segment: {
                today: 'Today',
                yesterday: 'Yesterday',
                this_week: 'This week',
            },
            make_all_as_read: 'Mark all as read',
        },
        profile_progress: {
            title: {
                garage: 'Garage',
                profile: 'Profile',
                prices: 'Prices',
            },
            item: {
                garage: {
                    logo: 'Logo',
                    address: 'Address',
                    web: 'Web',
                    zip: 'Zip',
                    contact_phones: 'Contact phones',
                },
                profile: {
                    first_name: 'First name',
                    last_name: 'Last name',
                    email: 'Email',
                    username: 'Username',
                    phone: 'Phone',
                    avatar: 'Avatar',
                },
                prices: {
                    hourly: 'Hourly rate',
                    oil: 'Oils',
                    liquids: 'Liquids',
                },
            },
            info: 'Profile setup {{percent}}%. Click here to view.',
            text_loading: 'Profile setup loading...',
        },
        tires: {
            brand_type: {
                [TIRES_TYPE_BRAND_PREMIUM]: 'Premium',
                [TIRES_TYPE_BRAND_PRICE_QUALITY]: 'Price/Quality',
            },
            step_title: {
                [TIRES_STEP_LIST_PREMIUM]: 'Premium',
                [TIRES_STEP_LIST_PRICE_QUALITY]: 'Price/Quality',
            },
            axel: {
                [TIRES_AXEL_FRONT]: 'Front',
                [TIRES_AXEL_REAR]: 'Rear',
                both: 'F + R',
            },
            season: {
                [TIRES_SEASON_SUMMER]: 'Summer',
                [TIRES_SEASON_WINTER]: 'Winter',
                [TIRES_SEASON_ALL_SEASON]: 'All Season',
                [TIRES_SEASON_NORDIC]: 'Nordic',
            },

            filter_title_both: 'Front + rear tires',
            filter_title_front: 'Front tires',
            filter_title_rear: 'Rear tires',

            filter_index_all: 'All index',

            package_name: 'Tires change',

            ean: 'EAN',
            sap: 'SAP',

            label: {
                wide: 'Wide',
                depth: 'Depth',
                diameter: 'Diameter',

                season: 'Season',
                index: 'Index',
                brand: 'Brand',

                front_axel: 'Front axel',
                rear_axel: 'Rear axel',

                same_dimension: 'Same dimension',
            },

            header: {
                brand: 'Name',
                index: 'Index',
                price: 'Price',
            },

            no_tires: 'No tires for this criteria',
        },
        toast: {
            success: 'Thank you',
            error: 'Oops!',
            info: 'Info',
            warning: 'Warning',
            app_version: 'New version of Garage Booster is available.',
        },
        toast_notifications: {
            prices_updated: "The discount has been saved. If there are non finished packages in draft with the discount, their price is now updated."
        },
        toast_system_messages: {
            maintenance: 'Maintenance',
            incident: 'Incident',
            warning: 'Warning',
            info: 'Info',
        },
        language: {
            en: 'English',
            de: 'German',
            fr: 'French',
        },
        error_recovery: {
            title: 'Our system encountered an error.',
            btn: 'Click here to reload the page',
        }
    },
}
