import Yup from './yup'
import { urlSearchParams } from 'utils/common'
import { userResetPasswordNew } from '../../../store/actions/auth.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            username: '',
            password: '',
            password_confirmation: '',
            token: urlSearchParams('token'),
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            username: Yup.string()
                .required()
                .label(t('validations.label.username')),

            password: Yup.string()
                .required()
                .label(t('validations.label.password')),

            password_confirmation: Yup.string()
                .required()
                .oneOf([Yup.ref('password'), null], t('validations.label.password_match'))
                .label(t('validations.label.password')),

            token: Yup.string(),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(userResetPasswordNew(values))
    },
}
