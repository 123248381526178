import React, {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {PackagesStep} from '../../routes/middleware/Packages/PackagesStep'
import {HasOfferToken} from '../../routes/middleware/HasOfferToken'
import Header from "../../components/layout/Header/Header";
import "./OfficialServiceEnrichContainer.scss";
import Button from "../../components/shared/Button/Button";
import {__clone, parseResponse, toBool, compare} from "../../utils/common";
import {TYPE_OFFICIAL} from "../../store/consts/package/package.type.constants";
import packageModel from 'store/models/package/package'
import officialServiceModel from 'store/models/package/officialService'
import {defaultsDeep, uniqBy} from "lodash";
import {packagesUpdate} from "../../store/actions/packages.actions";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import history from "../../routes/history";
import OfficialServices from "../../routes/middleware/OfficialServices/OfficialServices";
import {officialServicesFetch} from "../../store/actions/officialServices.actions";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import axios from "../../app/config/axios";
import Loader, {LOADER_BUTTON} from "../../components/shared/Loader/Loader";
import {
    OFFICIAL_SERVICE_NEXT,
    OFFICIAL_SERVICE_PREV,
    OFFICIAL_SERVICE_SELECTED
} from "../../store/consts/officialServices.constants";
import {officialServiceEnrich} from "../../store/enrich/officialServiceEnrich";
import ItemRecommended from "./Items/ItemRecommended";
import ItemAdditional from "./Items/ItemAdditional";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import ItemMain from "./Items/ItemMain";
import {captureException} from "../../utils/captureException";

const API_ADD_COST_LABOUR = 'garage/add-cost-tool/labour'

class OfficialServiceEnrichContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            recommended: props.officialServices.recommended,
            main: props.officialServices.main,
            additional: props.officialServices.additional,
            exist: props.officialServices.exist,
            mileage: this.officialServicePackage() ? this.officialServicePackage().officialService.mileage : props.officialServices.mileage,
            creating: false,
            loading: false,
        }
    }

    componentDidMount() {
        const {mileage, main, additional} = this.state;
        const {dispatch} = this.props;

        if(this.recommended().length === 0 && main.length === 0 && additional.length === 0){
            this.setState({loading: true})

            dispatch(officialServicesFetch(mileage))
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    captureException(error);
                    this.setState({loading: false})
                })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(compare(nextProps.officialServices, this.props.officialServices)){
            this.setState({
                recommended: nextProps.officialServices.recommended,
                main: nextProps.officialServices.main,
                additional: nextProps.officialServices.additional,
                exist: nextProps.officialServices.exist,
            });
        }
    }

    recommended = () => {
        const {recommended} = this.state;

        return recommended[OFFICIAL_SERVICE_SELECTED[recommended.selected]];
    }

    officialServicePackage = () => this.props.packages.find(p => p.type === TYPE_OFFICIAL)

    labourExists = (labour) => {
        return !!(this.officialServicePackage() && this.officialServicePackage().labours.find(l => l.itemMpId === labour.itemMpId))
    }

    handleChangeAdditional = (e) => {
        const {additional} = this.state;

        this.setState({
            additional: __clone(additional).map(service => {
                if(service.itemMpId === parseInt(e.target.value)){
                    return {...service, checked: e.target.checked}
                }

                return service
            }),
        })
    }

    handleChangeMain = (e) => {
        const {main} = this.state;

        this.setState({
            main: __clone(main).map(service => {
                if(service.itemMpId === parseInt(e.target.value)){
                    return {...service, checked: e.target.checked}
                }

                return service
            }),
        })
    }

    handleChangeRecommended = (e) => {
        const {recommended, main, additional} = this.state;

        let selected = parseInt(e.target.value);

        this.setState({
            recommended: {
                ...recommended,
                next: __clone(recommended.next).map(service => {
                    return {...service, checked: selected === OFFICIAL_SERVICE_NEXT}
                }),
                prev: __clone(recommended.prev).map(service => {
                    return {...service, checked: selected === OFFICIAL_SERVICE_PREV}
                }),
                selected: parseInt(e.target.value),
            },
            main: __clone(main).map(service => {
                return {...service,
                    checked: toBool(recommended[OFFICIAL_SERVICE_SELECTED[selected]].find(l => l.itemMpId === service.itemMpId)),
                    in_official: toBool(recommended[OFFICIAL_SERVICE_SELECTED[selected]].find(l => l.itemMpId === service.itemMpId)),
                }
            }),
            additional: __clone(additional).map(service => {
                return {...service,
                    checked: toBool(recommended[OFFICIAL_SERVICE_SELECTED[selected]].find(l => l.itemMpId === service.itemMpId)),
                    in_official: toBool(recommended[OFFICIAL_SERVICE_SELECTED[selected]].find(l => l.itemMpId === service.itemMpId)),
                }
            }),
        })
    }

    getSelectedServices = () => {
        const {main, additional} = this.state;

        return uniqBy(
            this.recommended().filter(labour => labour.checked === true)
                .concat(main.filter(labour => labour.checked === true))
                .concat(additional.filter(labour => labour.checked === true))
            , (service) => service.itemMpId);
    }

    submit = () => {
        const {recommended, mileage} = this.state;
        const {packages, dispatch} = this.props;

        let packagesClone = __clone(packages), packageAdd;
        let officialServicePackage = this.officialServicePackage();
        let services = this.getSelectedServices();

        this.setState({creating: true})

        this.getLabour(services)
            .then(response => {
                if (officialServicePackage) {
                    officialServicePackage.labours = response.data;
                    packageAdd = officialServicePackage;
                    packagesClone = packagesClone.map(p => {
                        return p.id === officialServicePackage.id ? packageAdd : p;
                    })
                } else {
                    packageAdd = defaultsDeep({
                        labours: response.data,
                        type: TYPE_OFFICIAL,
                    }, packageModel)

                    packagesClone.push(packageAdd)
                }

                packageAdd.officialService = defaultsDeep({
                    selected: recommended.selected,
                    mileage: mileage,
                }, officialServiceModel);

                dispatch(packagesUpdate(packagesClone, packageAdd.id, false))
                    .then((response) => {
                        history.goBack()
                    })
                    .catch(error => {
                        captureException(error);
                        toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                        this.setState({creating: false})
                    })
            })
            .catch(error => {
                captureException(error);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({creating: false})
            })
    }

    getLabour = (labours) => axios.post(API_ADD_COST_LABOUR, {params: {package: {labours: labours, type: TYPE_OFFICIAL}}})

    render() {
        const {t, title} = this.props;
        const {recommended, main, additional, creating, loading, exist} = this.state;

        return (
            <React.Fragment>
                <Header title={t(title)}/>

                <div className="container container--has-submit-container">

                    {loading ? <ListLoader/> :
                        <div className="official-services-container">

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    {toBool(recommended.prev.length) &&
                                        <ItemRecommended
                                            recommended={recommended}
                                            value={OFFICIAL_SERVICE_PREV}
                                            change={this.handleChangeRecommended}
                                        />
                                    }
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    {toBool(recommended.next.length) &&
                                        <ItemRecommended
                                            recommended={recommended}
                                            value={OFFICIAL_SERVICE_NEXT}
                                            change={this.handleChangeRecommended}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="mt-10 mb-30 text-center text-lighter">
                                {exist ? t('pages.official_service.or_more') : t('pages.official_service.no_recommended')}
                            </div>

                            <div className="official-services-main">
                                {main.map((service, key) => <ItemMain change={this.handleChangeMain} service={service} key={key} />)}
                            </div>

                            <hr className="hr hr--dashed mv-50"/>

                            <div className="official-services-additional">
                                {additional.map((service, key) => <ItemAdditional change={this.handleChangeAdditional} service={service} key={key} />)}
                            </div>
                        </div>
                    }

                    {!loading &&
                        <SubmitContainer stat={true}>
                            <Button size="lg" type="primary" disabled={creating || !this.getSelectedServices().length}
                                    onClick={this.submit}>
                                <Loader isLoading={creating} type={LOADER_BUTTON}/>
                                {t('pages.official_service.submit')}</Button>
                        </SubmitContainer>
                    }

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        officialServices: officialServiceEnrich(state),
        packages: state.packages,
    }
}

export default connect(mapStateToProps)(OfficialServices(PackagesStep(HasOfferToken(translate('translations')(OfficialServiceEnrichContainer)))))
