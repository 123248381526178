import client from 'store/models/client'
import { CLIENT_RESET, CLIENT_SET } from '../actions/client.actions'

export default function(state = client, action) {
  switch (action.type) {
    case CLIENT_SET:
      return {
        ...action.payload.client,
      }

    case CLIENT_RESET:
      return client

    default:
      return state
  }
}
