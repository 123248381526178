import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {__clone, parseResponse, todayTimestamp, yesterdayTimestamp, isDate} from "../../utils/common";
import "./ActivityContainer.scss";
import ActivityAction from "../../components/ActivityComponents/ActivityAction/ActivityAction";
import {
    ACTIVITY_GARAGE_SMS_REPLY,
    ACTIVITY_MANAGER_ACCEPTED, ACTIVITY_MANAGER_CREATED,
    ACTIVITY_MANAGER_REJECTED,
    ACTIVITY_MANAGER_WAITING, ACTIVITY_USER_RESPONDED, ACTIVITY_USER_SMS_REPLY
} from "../../store/consts/activities.constants";
import ActivityManagerWaiting from "../../components/ActivityComponents/ActivityManagerWaiting/ActivityManagerWaiting";
import ActivityUserResponded from "../../components/ActivityComponents/ActivityUserResponded/ActivityUserResponded";
import ActivityManagerAccepted
    from "../../components/ActivityComponents/ActivityManagerAccepted/ActivityManagerAccepted";
import ActivityManagerRejected
    from "../../components/ActivityComponents/ActivityManagerRejected/ActivityManagerRejected";
import ActivitySmsUserReply from "../../components/ActivityComponents/ActivitySmsUserReply/ActivitySmsUserReply";
import ActivityManagerCreated
    from "../../components/ActivityComponents/ActivityManagerCreated/ActivityManagerCreated";
import ActivitySmsGarageReply from "../../components/ActivityComponents/ActivitySmsGarageReply/ActivitySmsGarageReply";
import CarDetail from "../../components/CarDetail/CarDetail";
import {uniqBy} from "lodash";
import {connect} from "react-redux";
import history from "../../routes/history";
import pages from "../../app/consts/routes";
import {captureException} from "../../utils/captureException";

const API_ACTIVITY_URL = 'garage/activities/activity'
const API_UPDATE_SEEN_ACTIVITIES_URL = 'garage/activities/update-seen-activities'

class ActivityContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            loading: true
        };

        this.activityListRef = React.createRef()
    }

    componentDidMount() {
        const {garage, user} = this.props;

        this.fetch();

        window.Echo.private(`user-repair-shop.${user.id}`)
            .listen(`.activity.received.${garage.id}`, e => {
                if (this.state.user && this.state.user.id === e.user.id) {
                    this.setState({
                        list: [...this.state.list, e]
                    })
                }
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.list.length !== this.state.list.length) {

            if (this.activityListRef && this.activityListRef.scrollTo) {
                this.activityListRef.scrollTo(0, this.activityListRef.scrollHeight)
            }

            this.updateSeenActivities();
        }

        if (this.props.match.params.user_id !== prevProps.match.params.user_id) {
            this.setState({
                loading: true
            })
            this.fetch();
        }
    }

    fetch = () => {
        axios.post(API_ACTIVITY_URL, {user_id: this.props.match.params.user_id})
            .then(response => {
                this.setState({
                    list: response.data.list,
                    user: response.data.user,
                    loading: false
                })
            })
            .catch(error => {
                captureException(error, API_ACTIVITY_URL);
                history.replace(pages.activities);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
    }

    updateSeenActivities = () => {
        let activities = this.state.list.filter(item => item.seen === false).map(item => item.id);

        if (!activities.length) {
            return;
        }

        axios.post(API_UPDATE_SEEN_ACTIVITIES_URL, {activities: activities})
            .then(response => {
            })
            .catch(error => {
            })
    }

    segments = (list) => {
        let segments = {};

        for (let item of list) {
            if (item.at.timestamp >= todayTimestamp()) {
                if (!segments.hasOwnProperty('today')) {
                    segments.today = [];
                }

                segments.today.push(item);
            } else if (item.at.timestamp >= yesterdayTimestamp()) {
                if (!segments.hasOwnProperty('yesterday')) {
                    segments.yesterday = [];
                }

                segments.yesterday.push(item);
            } else {
                if (!segments.hasOwnProperty(item.at.date)) {
                    segments[item.at.date] = [];
                }

                segments[item.at.date].push(item);
            }
        }

        return segments;
    }

    activityManagerWaitingShowActions = (notify) => {
        const {list} = this.state;

        let items = list.filter(item => item.offer_token === notify.offer_token && item.type === ACTIVITY_MANAGER_WAITING);

        return items[items.length - 1].id === notify.id;
    }

    activityManagerWaitingFinish = (request) => {
        const {list} = this.state;

        if (request && request.user.id !== this.state.user.id) {
            history.replace(`${pages.activity}${request.user.id}`);
            return;
        }

        this.setState({
            list: __clone(list).map(item => {
                if (item.offer_token === request.token) {
                    item.status = request.status;
                }

                return item;
            })
        })
    }

    render() {
        const {t, title} = this.props;
        const {loading, list, user} = this.state;

        let activities = this.segments(list);

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">

                    {loading ? <ListLoader/> :
                        <div className="activity-container">

                            <div className="activity-container-fixed">
                                <div className="container">
                                    <div className="activity-container-user">
                                        <div className="activity-container-user__name">
                                            {user.first_name} {user.last_name}
                                        </div>

                                        <div className="activity-container-user__car">
                                            {uniqBy(list, (e) => e.car.hash).map((item, key) => {
                                                return <CarDetail
                                                    key={key}
                                                    car={item.car}
                                                />
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className={`activity-list ${!user.phone ? 'activity-list--no-action' : ''}`}
                                     ref={(ref) => this.activityListRef = ref}>
                                    {Object.keys(activities).map((segment, key) => {

                                        if (!activities[segment].length) {
                                            return null;
                                        }

                                        let unseen = list.find(item => item.seen === false);

                                        return (
                                            <div key={key}>
                                                <div
                                                    className="activity-list__date-segment">{!isDate(segment) ? t(`pages.activity.segment.${segment}`) : segment}</div>

                                                {activities[segment].map((notify, key) => {
                                                    return (
                                                        <div key={key}>
                                                            {(list.length > 1 && unseen && unseen.id === notify.id) &&
                                                            <div className="new-events-flag">
                                                                <span/>
                                                                <span>{t('pages.activity.new_event')}</span>
                                                            </div>
                                                            }

                                                            {notify.type === ACTIVITY_MANAGER_WAITING &&
                                                            <ActivityManagerWaiting notify={notify} key={notify.id}
                                                                                    showActions={this.activityManagerWaitingShowActions(notify)}
                                                                                    refresh={this.activityManagerWaitingFinish}/>
                                                            }
                                                            {notify.type === ACTIVITY_MANAGER_CREATED &&
                                                            <ActivityManagerCreated notify={notify} key={notify.id}/>
                                                            }

                                                            {notify.type === ACTIVITY_MANAGER_ACCEPTED &&
                                                            <ActivityManagerAccepted notify={notify} key={notify.id}/>
                                                            }

                                                            {notify.type === ACTIVITY_MANAGER_REJECTED &&
                                                            <ActivityManagerRejected notify={notify} key={notify.id}/>
                                                            }

                                                            {notify.type === ACTIVITY_USER_RESPONDED &&
                                                            <ActivityUserResponded notify={notify} key={notify.id}/>
                                                            }

                                                            {notify.type === ACTIVITY_USER_SMS_REPLY &&
                                                            <ActivitySmsUserReply notify={notify} key={notify.id}/>
                                                            }

                                                            {notify.type === ACTIVITY_GARAGE_SMS_REPLY &&
                                                            <ActivitySmsGarageReply notify={notify} key={notify.id}/>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            {user.phone &&
                            <ActivityAction
                                user={user}
                                lastActivity={list[list.length - 1]}
                                onReply={(reply) => {
                                    this.setState({
                                        list: [...list, reply]
                                    })
                                }}
                            />
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
        user: state.user,
    };
};

export default connect(mapStateToProps)(translate('translations')(ActivityContainer));
