import Yup from './yup'
import {
    FORM_VEHICLE_TYPE_LICENCE_PLATE,
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE,
    FORM_VEHICLE_TYPE_CAR_NUMBER,
} from 'app/consts/vehicle'
import { packagesPageShow } from '../../../store/actions/packages.actions'
import { carLicencePlate, carMultipleVehicleChoose, carNumber } from '../../../store/actions/car.actions'
import { carStepSet } from '../../../store/actions/carStep.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues({ car, carStep, type }) {
        const getValueFor = (key, keyOfArray) => {
            if (car[keyOfArray].length === 1) return car[keyOfArray][0].id

            if (car[key] !== null && typeof car[key] !== 'undefined') {
                return car[key]
            }

            return ''
        }

        let toValidate = {}

        if (carStep.step === FORM_VEHICLE_TYPE_LICENCE_PLATE) {
            toValidate.licence_plate = car.licence_plate || null || '' //TODO: get licencePlate from session !window.settings.licencePlate
            toValidate.mileage = car.mileage || null
        }

        if (carStep.step === FORM_VEHICLE_TYPE_MANUAL_1) {
            toValidate.make_id = car.make_id || ''
            toValidate.first_registration = car.first_registration || ''
            toValidate.range_id = car.range_id || ''
            toValidate.type_id = car.type_id || ''
            toValidate.mileage = car.mileage || null
        }

        if (carStep.step === FORM_VEHICLE_TYPE_MANUAL_2) {
            toValidate.gear_id = getValueFor('gear_id', 'gears')
            toValidate.qual_md = getValueFor('qual_md', 'qual_md_list')
            toValidate.qual_lt = getValueFor('qual_lt', 'qual_lt_list')
        }

        if (carStep.step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) {
            toValidate.color_make_id = getValueFor('color_make_id', 'makes')
            toValidate.color = getValueFor('color', 'color_list')
        }

        if (carStep.step === FORM_VEHICLE_TYPE_CAR_NUMBER) {
            toValidate.car_number = car.car_number || ''
            toValidate.car_number_type = car.car_number_type || ''
            toValidate.mileage = car.mileage || null
        }

        return toValidate
    },

    validationSchema: props => {
        const { t } = props

        const commonSchema = {
            agreementPenaltyEnable: Yup.boolean(),
            addMethod1: Yup.addMethod(Yup.mixed, 'validateIfExist', function(message, src) {
                return this.test('ifFieldExistValidate', message, function(fieldValue) {
                    if (src.length === 1) return true

                    if (src.length > 1 && typeof fieldValue === 'undefined') {
                        return this.createError({
                            path: this.path,
                            message: message,
                        })
                    }

                    return true
                })
            }),
            addMethod2: Yup.addMethod(Yup.mixed, 'isDisabled', function(message, src) {
                return this.test('isFieldDisabled', message, function(fieldValue) {
                    if (src.length > 0 && fieldValue === undefined) {
                        return this.createError({
                            path: this.path,
                            message: message,
                        })
                    }

                    return true
                })
            }),
            addMethod3: Yup.addMethod(Yup.mixed, 'isCarNumberValid', function(message, src) {
                return this.test('isCarNumberValid', message, function(fieldValue) {
                    return !!this.parent.car_number_type
                })
            }),
        }

        const licencePlateSchema = {
            licence_plate: Yup.string()
                .required(t('validations.licence_plate.required'))
                .nullable()
                .label(t('validations.label.licence_plate')),
            mileage: Yup.number()
                .nullable()
                .label(t('validations.label.mileage')),
        }

        const manualFirstSchema = {
            make_id: Yup.number()
                .required()
                .nullable()
                .label(t('validations.label.make')),
            range_id: Yup.number()
                .validateIfExist(t('validations.default.mixed.required'), props.car.ranges)
                .nullable()
                .label(t('validations.label.range')),

            type_id: Yup.string()
                .isDisabled(t('validations.default.mixed.required'), props.car.first_registration_list)
                .nullable()
                .label(t('validations.label.type')),

            first_registration: Yup.string()
                .isDisabled(t('validations.default.mixed.required'), props.car.first_registration_list)
                .nullable()
                .label(t('validations.label.first_registration')),

            mileage: Yup.number()
                .nullable()
                .label(t('validations.label.mileage')),
        }

        const manualSecondSchema = {
            gear_id: Yup.string()
                .validateIfExist(t('validations.default.mixed.required'), props.car.gears)
                .nullable()
                .label(t('validations.label.gear')),

            qual_lt: Yup.number()
                .validateIfExist(t('validations.default.mixed.required'), props.car.qual_lt_list)
                .nullable()
                .label(t('validations.label.qual_lt_list')),

            qual_md: Yup.number()
                .validateIfExist(t('validations.default.mixed.required'), props.car.qual_md_list)
                .nullable()
                .label(t('validations.label.qual_md_list')),
        }

        const multipleVehicleChoose = {
            color_make_id: Yup.number()
                .required()
                .nullable()
                .label(t('validations.label.make')),

            color: Yup.number()
                .validateIfExist(t('validations.default.mixed.required'), props.car.color_list)
                .nullable()
                .label(t('validations.label.color_list')),
        }

        const carNumberSchema = {
            car_number: Yup.string()
                .isCarNumberValid(t('validations.car_number.invalid_format'), props.car.car_number)
                .required()
                .nullable()
                .label(t('validations.label.car_number')),

            mileage: Yup.number()
                .nullable()
                .label(t('validations.label.mileage')),
        }

        const getFieldsSchema = carStep => {
            if (carStep.step === FORM_VEHICLE_TYPE_LICENCE_PLATE) {
                return { ...licencePlateSchema }
            } else if (carStep.step === FORM_VEHICLE_TYPE_MANUAL_1) {
                return { ...manualFirstSchema }
            } else if (carStep.step === FORM_VEHICLE_TYPE_MANUAL_2) {
                return { ...manualSecondSchema }
            } else if (carStep.step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) {
                return { ...multipleVehicleChoose }
            } else if (carStep.step === FORM_VEHICLE_TYPE_CAR_NUMBER) {
                return { ...carNumberSchema }
            }

            return {}
        }

        return Yup.object().shape({
            ...commonSchema,
            ...getFieldsSchema(props.carStep),
        })
    },

    handleSubmit(values, formikBag) {
        const {
            car,
            dispatch,
            carStep: { step, incomplete, message },
        } = formikBag.props

        if (step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) {
            dispatch(carMultipleVehicleChoose(car))
            return
        }

        if (step === FORM_VEHICLE_TYPE_LICENCE_PLATE) {
            dispatch(carLicencePlate(car))
            return
        }

        if (step === FORM_VEHICLE_TYPE_MANUAL_1) {
            if (car.qual_lt_list.length > 1 || car.qual_md_list.length > 1 || car.gears.length > 1) {
                dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_2, incomplete, message))
                return
            }
        }

        if (step === FORM_VEHICLE_TYPE_MANUAL_2) {
            dispatch(packagesPageShow())
            return
        }

        if (step === FORM_VEHICLE_TYPE_CAR_NUMBER) {
            dispatch(carNumber(car))
            return
        }

        dispatch(packagesPageShow())
    },
}
