export default [
    'ZH',
    'BE',
    'LU',
    'UR',
    'SZ',
    'OW',
    'NW',
    'GL',
    'ZG',
    'FR',
    'SO',
    'BS',
    'BL',
    'SH',
    'AR',
    'AI',
    'SG',
    'GR',
    'AG',
    'TG',
    'TI',
    'VD',
    'VS',
    'NE',
    'GE',
    'JU',
    'BU',
    'FL',
]
