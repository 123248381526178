import React, {Component} from 'react';
import {translate} from "react-i18next";
import OfficialService from "../../../views/LabourSearchContainer/OfficialService/OfficialService";
import {CHECKLIST_NOT_OK, CHECKLIST_OK} from "../ChecklistLabour";
import pages from "../../../app/consts/routes";
import {Link} from "react-router-dom";
import {TYPE_OFFICIAL} from "../../../store/consts/package/package.type.constants";
import ChecklistLayout from "../components/ChecklistLayout";

class OfficialServiceType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            officialServiceShow: false,
        };
    }

    packageExists = () => this.props.packages.find(p => p.id === this.props.labour.package_id)

    handleChange = (passed) => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        if (passed === CHECKLIST_NOT_OK) {
            this.setState({
                officialServiceShow: true,
            })
        }
        else if (passed === CHECKLIST_OK) {
            labourFieldUpdate({updating: true, passed: passed}).then(() => {
                onUpdatePassed();
            })
        }
    };

    onClose = () => {
        this.setState({
            officialServiceShow: false,
        })
    }

    onSubmit = () => {
        const {labourFieldUpdate} = this.props;

        labourFieldUpdate({updating: true, passed: CHECKLIST_NOT_OK})
    }

    onSuccess = (response) => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        this.setState({officialServiceShow: false})

        labourFieldUpdate({updating: false, package_id: response.package.id}).then(() => {
            onUpdatePassed();
        })
    }

    onError = () => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        labourFieldUpdate({updating: false, passed: null}).then(() => {
            onUpdatePassed();
        })

        this.setState({
            officialServiceShow: false,
        })
    }

    render() {
        const {officialServiceShow} = this.state;
        const {t, labour, showNotePopup} = this.props;

        let noteDisabled = labour.passed === null || labour.updating;

        return (
            <React.Fragment>
                <div className="checklist-labour">
                    <div className="col-xs-8">
                        <div className="checklist-labour__name">
                            {labour.name}

                            {(this.packageExists() && this.packageExists().type === TYPE_OFFICIAL && !labour.updating && labour.passed === CHECKLIST_NOT_OK) &&
                                <Link to={`${pages.official_service}`}>
                                    <i className="icon-edit"/>
                                </Link>
                            }
                        </div>
                    </div>
                    <ChecklistLayout name={"checklist-labour"} handleChange={this.handleChange} labour={labour}/>
                    <div className="col-xs-2">
                        <div className="checklist-labour__btn">
                            <span className="link-style" disabled={noteDisabled} onClick={!noteDisabled ? showNotePopup : null}>
                                {labour.note ? t('pages.checklist_labours.note.view') : t('pages.checklist_labours.note.new')}
                            </span>
                        </div>
                    </div>
                </div>

                {officialServiceShow &&
                    <OfficialService
                        show={officialServiceShow}
                        onClose={this.onClose}
                        onSuccess={this.onSuccess}
                        onError={this.onError}
                        onSubmit={this.onSubmit}
                        loadInBackground={true}
                        createEmpty={true}
                    />
                }

            </React.Fragment>
        )
    }
}

export default translate('translations')(OfficialServiceType)

