export const FORM_SUBMITTING = '[Form Submitting]'

export const FORM_SUBMITTING_VEHICLE = `${FORM_SUBMITTING} VEHICLE`
export const FORM_SUBMITTING_BOOKING = `${FORM_SUBMITTING} BOOKING`
export const FORM_SUBMITTING_LOGIN = `${FORM_SUBMITTING} LOGIN`
export const FORM_SUBMITTING_HOURLY_PRICE = `${FORM_SUBMITTING} HOURLY PRICE`
export const FORM_SUBMITTING_OIL_PRICE = `${FORM_SUBMITTING} OIL PRICE`
export const FORM_SUBMITTING_LIQUIDS_PRICE = `${FORM_SUBMITTING} LIQUIDS PRICE`
export const FORM_SUBMITTING_DISCOUNT_PRICE = `${FORM_SUBMITTING} DISCOUNT PRICE`
export const FORM_SUBMITTING_DISCOUNT_PARTS_PRICE = `${FORM_SUBMITTING} DISCOUNT PARTS PRICE`
export const FORM_SUBMITTING_DISCOUNT_BRAKES_PRICE = `${FORM_SUBMITTING} DISCOUNT BRAKES PRICE`
export const FORM_SUBMITTING_DISCOUNT_APPOINTMENT_PRICE = `${FORM_SUBMITTING} DISCOUNT APPOINTMENT PRICE`
export const FORM_SUBMITTING_SETTINGS = `${FORM_SUBMITTING} SETTINGS`
export const FORM_SUBMITTING_MECHANICS = `${FORM_SUBMITTING} MECHANICS`
export const FORM_SUBMITTING_PACKAGES_UPDATE = `${FORM_SUBMITTING} PACKAGES UPDATE`
export const FORM_SUBMITTING_PACKAGES_ALL_REMOVE = `${FORM_SUBMITTING} PACKAGES ALL REMOVE`
export const FORM_SUBMITTING_PACKAGE_TIRES_UPDATE = `${FORM_SUBMITTING} PACKAGE TIRES UPDATE`

export const formSubmittingVehicle = submitted => ({
    type: FORM_SUBMITTING_VEHICLE,
    payload: {
        submitted,
    },
})

export const formSubmittingBooking = submitted => ({
    type: FORM_SUBMITTING_BOOKING,
    payload: {
        submitted,
    },
})

export const formSubmittingLogin = submitted => ({
    type: FORM_SUBMITTING_LOGIN,
    payload: {
        submitted,
    },
})

export const formSubmittingHourlyPrice = submitted => ({
    type: FORM_SUBMITTING_HOURLY_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingOilPrice = submitted => ({
    type: FORM_SUBMITTING_OIL_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingLiquidsPrice = submitted => ({
    type: FORM_SUBMITTING_LIQUIDS_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingDiscountPrice = submitted => ({
    type: FORM_SUBMITTING_DISCOUNT_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingDiscountPartsPrice = submitted => ({
    type: FORM_SUBMITTING_DISCOUNT_PARTS_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingDiscountBrakesPrice = submitted => ({
    type: FORM_SUBMITTING_DISCOUNT_BRAKES_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingDiscountAppointmentPrice = submitted => ({
    type: FORM_SUBMITTING_DISCOUNT_APPOINTMENT_PRICE,
    payload: {
        submitted,
    },
})

export const formSubmittingSettings = submitted => ({
    type: FORM_SUBMITTING_SETTINGS,
    payload: {
        submitted,
    },
})

export const formSubmittingMechanics = submitted => ({
    type: FORM_SUBMITTING_MECHANICS,
    payload: {
        submitted,
    },
})

export const formSubmittingPackagesUpdate = submitted => ({
    type: FORM_SUBMITTING_PACKAGES_UPDATE,
    payload: {
        submitted,
    },
})

export const formSubmittingPackagesAllRemove = submitted => ({
    type: FORM_SUBMITTING_PACKAGES_ALL_REMOVE,
    payload: {
        submitted,
    },
})

export const formSubmittingPackageTiresUpdate = submitted => ({
    type: FORM_SUBMITTING_PACKAGE_TIRES_UPDATE,
    payload: {
        submitted,
    },
})
