import React from "react";
import {Helmet} from "react-helmet";
import language from '../../utils/language'

const SCRIPT = {
    de: `var _iub = _iub || []; _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"lang":"de","siteId":2165477,"cookiePolicyId":21925595, "banner":{"position":"float-bottom-center","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"#435f71","backgroundColor":"#ffffff","acceptButtonDisplay":true,"acceptButtonColor":"#2dafff","acceptButtonCaptionColor":"white","customizeButtonDisplay":true,"customizeButtonColor":"#212121","customizeButtonCaptionColor":"white"}};`,
    en: `var _iub = _iub || []; _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"lang":"de","siteId":2165477,"cookiePolicyId":21925595, "banner":{"position":"float-bottom-center","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"#435f71","backgroundColor":"#ffffff","acceptButtonDisplay":true,"acceptButtonColor":"#2dafff","acceptButtonCaptionColor":"white","customizeButtonDisplay":true,"customizeButtonColor":"#212121","customizeButtonCaptionColor":"white"}};`,
    fr: `var _iub = _iub || []; _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"lang":"fr","siteId":2165477,"cookiePolicyId":68010839, "banner":{ "position":"float-bottom-center","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","textColor":"#435f71","backgroundColor":"#ffffff","acceptButtonDisplay":true,"acceptButtonColor":"#2dafff","acceptButtonCaptionColor":"white","customizeButtonDisplay":true,"customizeButtonColor":"#212121","customizeButtonCaptionColor":"white" }};`,
};

class CookiePolicy extends React.Component {

    render() {
        return (
            <>
                <Helmet>
                    <script type="text/javascript">
                        {SCRIPT[language()]}
                    </script>
                    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charSet="UTF-8" async/>
                </Helmet>
            </>
        )
    }
}

export default CookiePolicy;
