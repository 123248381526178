export default {
    hourly: {
        prices: [],
    },
    oil: {
        prices: [],
    },
    liquids: {
        prices: [],
    },
    discounts: [],
    additional_percent_on_works: 0,
}
