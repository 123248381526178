import Yup from './yup'

export default {
    enableReinitialize: true,

    mapPropsToValues({garage}) {
        return {
            garage_file: '',
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            comment: '',
            time_due: garage.time_due,
            appointment_discounts: [],
        }
    },

    validationSchema: props => {
        const {t} = props

        return Yup.object().shape({
            garage_file: Yup.string().nullable(),

            id: Yup.number().nullable(),

            first_name: Yup.string()
                .required()
                .label(t('pages.request_preview.section_form.label.first_name')),

            last_name: Yup.string()
                .required()
                .label(t('pages.request_preview.section_form.label.last_name')),

            email: Yup.string()
                .email()
                .label(t('pages.request_preview.section_form.label.email'))
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function () {
                    return this.parent.phone || this.parent.email
                }),

            phone: Yup.number()
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function () {
                    return this.parent.phone || this.parent.email
                })
                .label(t('pages.request_preview.section_form.label.phone')),

            time_due: Yup.string().nullable(),

            comment: Yup.string().nullable(),

            appointment_discounts: Yup.array().nullable(),
        })
    },
}
