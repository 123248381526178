import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from "formik";
import {formDiscountAppointmentPrices} from "app/config/yup";
import Button from "../../../components/shared/Button/Button";
import Input from "components/shared/Input/Input";
import {compare} from "../../../utils/common";
import InputCheckbox from "../../../components/shared/InputCheckbox";

class DiscountAppointmentForm extends Component {

    state = {
        showDiscount: (this.props.discount ? this.props.discount.active : false),
    }

    componentDidMount() {
        const {values} = this.props;

        if(this.props.discount){
            this.props.setValues({...values, discount: this.props.discount.price_rule.discount});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {values} = this.props;

        if(compare(this.props.discount, nextProps.discount)){
            this.props.setValues({...values, discount: nextProps.discount.price_rule.discount});
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    render() {
        const { t, handleBlur, handleSubmit, formSubmitting, touched, errors, discount, values, handleChange } = this.props;

        return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">{t('pages.price_update.discount.appointments_title')}</div>
                    <div className="settings-tab__content">
                        <form onSubmit={e => handleSubmit(e)}>
                            <Input
                                id="show_appointment_discount"
                                type="tel"
                                placeholder={t('pages.price_update.section_form.label_discount')}
                                name={'discount'}
                                label={t('pages.price_update.section_form.label_discount')}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.discount && errors.discount}
                                value={discount ? discount.price_rule.discount : values.discount}
                                addOn={'%'}
                                step={0.000001}
                                disabled={!this.state.showDiscount}
                                float={true}
                            />

                            <InputCheckbox
                                id="show_appointment_discount"
                                name="show_appointment_discount"
                                value={this.state.showDiscount}
                                onChange={(e) => {
                                    this.setState({showDiscount: e.target.checked});
                                    handleChange(e);
                                }}
                                checked={this.state.showDiscount}
                                label={t('pages.price_update.section_form.label_show_appointments_discount')}
                            />

                            <div className="services-save-footer">
                                <Button id="appointment_discount_save" size="lg" type="primary" disabled={formSubmitting.discountAppointmentsPrice || formSubmitting.discountPrice}>
                                    {t('pages.price_update.section_form.button_save')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const DiscountsUpdateForm = withFormik(formDiscountAppointmentPrices)(DiscountAppointmentForm);

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(DiscountsUpdateForm));
