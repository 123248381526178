import React, {Component} from 'react';
import Select from 'components/shared/Select/Select';
import {translate} from 'react-i18next';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import {
    carDataChange, carReset,
    licencePlateChangeReset,
} from 'store/actions/car.actions';
import Button from 'components/shared/Button/Button';
import {setLoader} from 'store/actions/loader.actions';
import {LOADER_API_GET_CAR, LOADER_FORM_VEHICLE, LOADER_LICENCE_PLATE} from 'store/consts/loader.constants';
import scroller from 'utils/scroller';
import {formVehicleConfig} from "app/config/yup";
import {
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE, FORM_VEHICLE_TYPE_CAR_NUMBER, FORM_VEHICLE_TYPE_LICENCE_PLATE
} from "app/consts/vehicle";
import LicencePlate from "components/shared/LicencePlate/LicencePlate";
import "./FormVehicle.scss";
import {carStepSet} from "store/actions/carStep.actions";
import Loader from "components/shared/Loader/Loader";
import {isMobileView} from "utils/common";
import {setNavigationTitle} from "utils/setNavigationTitle";
import CarDetail from "components/CarDetail/CarDetail";
import {packagesAllRemove, packagesReset} from "../../../store/actions/packages.actions";
import Confirm from "../../shared/Confirm/Confirm";
import fieldExists from "../../../utils/fieldExists";
import {resetToken} from "../../../utils/resetToken";
import Draft from "../../shared/Draft/Draft";
import {toast} from "react-toastify";
import history from 'routes/history';
import Toast from "../../shared/Toast/Toast";
import CarNumber from "../../shared/CarNumber/CarNumber";
import {SWISS_LENGTH, KBA_LENGTH, KBA_STRING, SWISS_STRING, VIN_STRING, VIN_REGEX} from "../../../app/consts/carNumber";
import {LOADER_API_GET_CAR_NUMBER} from "../../../store/consts/loader.constants";
import {carStepReplace} from "../../../store/actions/carStep.actions";
import {carNumberLabel} from "../../../store/selectors/carNumberLabel";
import SubmitContainer from "../../shared/SubmitContainer/SubmitContainer";
import {checklistLaboursReset} from "../../../store/actions/checklistLabours.actions";
import {officialServicesReset} from "../../../store/actions/officialServices.actions";
import Input from "../../shared/Input/Input";
import {selectChecklistLabours} from "../../../store/selectors/checklistLabours";

class FormVehicle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            licence_plate: null,
            confirmLicencePlateChange: false
        }
    }

    componentDidMount() {
        const { dispatch, garage } = this.props;

        this.getTitle();

        if(!garage.show_licence_plate){
            if(garage.show_vin || garage.show_swiss || garage.show_kba){
                dispatch(carStepReplace(FORM_VEHICLE_TYPE_CAR_NUMBER));
            }
            else {
                dispatch(carStepReplace(FORM_VEHICLE_TYPE_MANUAL_1));
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { dispatch, car } = this.props;

        if(this.props.carStep.step !== FORM_VEHICLE_TYPE_CAR_NUMBER && nextProps.carStep.step === FORM_VEHICLE_TYPE_CAR_NUMBER){
            this.setState({licence_plate: null});
            dispatch(carReset());
        }

        if(nextProps.car.token && car.token && nextProps.car.token !== car.token){
            dispatch(packagesReset())
            dispatch(checklistLaboursReset())
            dispatch(officialServicesReset())
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        const { carStep: { step, message }, loader } = this.props;

        this.getTitle();

        this.onErrorScrollTo(prevProps.errors, prevProps.isSubmitting);

        if(isMobileView() && (step !== prevProps.carStep.step || loader.licencePlate !== prevProps.loader.licencePlate)){
            scroller('form-container', {
                duration: 500,
                smooth: "ease"
            });
        }

        if(message && prevProps.carStep.message !== message){
            toast.info(<Toast text={message} type="info"/>)
        }
    }

    onErrorScrollTo = (errors, isSubmitting) => {
        const errorFields = Object.keys(errors);
        if (errorFields.length && isSubmitting) {
            scroller(errorFields[0], {
                duration: 500,
                smooth: "ease"
            })
        }
    };

    onFormFieldChange = (name, value, shouldCallAPI = false) => {
        this.updateFormField(name, value);
        this.props.dispatch(carDataChange(name, value, shouldCallAPI)); /* update redux store and eventually call api */
    };

    updateFormField = (name, value) => {
        const {values, setValues} = this.props;

        values[name] = value;
        setValues(values);
    };

    handleHasOneOption = (fieldId, {id}) => {
        /* if there is only one item in the list and the value is not yet set for it */
        if (!this.props.values[fieldId]) {
            this.onFormFieldChange(fieldId, id);
        }
    };

    handleChangeSelectForMakes = (name, {id}) => {
        if (this.props.car.first_registration == null) {
            this.handleChangeSelect(name, {id});
        }
    };

    handleChangeSelect = (name, {id}) => {
        if (name === 'make_id' && fieldExists(this.props.car, 'make_id')) {
            resetToken();
        }

        this.onFormFieldChange(name, id, true);
        this.props.dispatch(setLoader(LOADER_FORM_VEHICLE, {
            [name]: true
        }));
    };

    handleChangeSelectColorSelect = (name, {id}) => {
        this.onFormFieldChange(name, id, false);
        this.props.dispatch(setLoader(LOADER_FORM_VEHICLE, {
            [name]: true
        }));
    };

    handleTextChange = (e) => {
        const {handleChange} = this.props;
        const {name, value} = e.target;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
            this.onFormFieldChange(name, value);
        }
    };

    handleCarNumberChange = (e) => {
        const {handleChange, garage} = this.props;
        const {name, value} = e.target;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
            this.onFormFieldChange(name, value);
        }

        if (garage.show_vin && (new RegExp(VIN_REGEX)).test(value)) {
            this.onFormFieldChange('car_number_type', VIN_STRING);
        }
        else if(garage.show_swiss && value.length === SWISS_LENGTH){
            this.onFormFieldChange('car_number_type', SWISS_STRING);
        }
        else if(garage.show_kba && value.length === KBA_LENGTH){
            this.onFormFieldChange('car_number_type', KBA_STRING);
        }
        else{
            this.onFormFieldChange('car_number_type', null);
        }
    };

    shouldForceChangeMake = () => {
        const { car } = this.props;

        return car.makes.length === 1 && car.first_registration_list.length === 0;
    };

    handleMakesEmpty = () => {
        const { car } = this.props;

        resetToken();

        this.props.dispatch(carDataChange('makes', car.makes, true));
        this.props.dispatch(setLoader(LOADER_FORM_VEHICLE, {
            'makes': true
        }));
    };

    getTitle = () => {
        const {carStep, t} = this.props;

        if(carStep.step === FORM_VEHICLE_TYPE_MANUAL_1){
            setNavigationTitle(t('pages.add_cost.method_title_manual'));
        }
        else if(carStep.step === FORM_VEHICLE_TYPE_MANUAL_2){
            setNavigationTitle(t('pages.add_cost.method_title_manual'));
        }
        else if(carStep.step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE){
            setNavigationTitle(t('pages.add_cost.method_title_multiple'));
        }
        else if(carStep.step === FORM_VEHICLE_TYPE_CAR_NUMBER){
            setNavigationTitle(t('pages.add_cost.method_title_car_number'));
        }
        else{
            setNavigationTitle(t('pages.add_cost.method_title_lp'));
        }
    };

    getFormSubmitLabel = () => {
        const {carStep, t} = this.props;

        if(carStep.step === FORM_VEHICLE_TYPE_MANUAL_1){
            return t('form.button.confirm');
        }
        else if(carStep.step === FORM_VEHICLE_TYPE_MANUAL_2){
            return t('form.button.confirm');
        }
        else if(carStep.step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE){
            return t('form.button.confirm');
        }
        else if(carStep.step === FORM_VEHICLE_TYPE_CAR_NUMBER){
            return t('form.button.confirm');
        }

        return t('form.button.submit');
    };

    renderSubmit = (isDisabled, loader) => {
        const isButtonLoading = this.props.loader[loader];
        return <Button size="lg"
                       type="primary"
                       disabled={isDisabled || isButtonLoading}
                >
                    {this.getFormSubmitLabel(isButtonLoading)}
                    <Loader isLoading={isButtonLoading} type={loader}/>
                </Button>
    };

    handleLicencePlateChange = (canton, number) => {
        const isCantonFound = !this.props.cantonList.find(cantonName => cantonName.toLowerCase() === canton.toLowerCase());
        const isNumberEmpty = (number.toString().length === 0);

        if (isCantonFound || isNumberEmpty)
            return this.onFormFieldChange('licence_plate', null);

        this.setState({
           licence_plate: canton.toUpperCase() + number
        });

        this.onFormFieldChange('licence_plate', `${canton.toUpperCase()}${number}`);
    };

    handleLicencePlateReset = () => {
        this.props.errors['licence_plate'] = null;
        this.onFormFieldChange('licence_plate', null);

        this.props.dispatch(licencePlateChangeReset());
    };

    confirmPackagesReset = () => {
        const { packages, checklistLabours, dispatch } = this.props;

        return (packages.length || checklistLabours.length ? {
            title: this.props.t('pages.packages.confirm_car_update.title'),
            subtitle: this.props.t('pages.packages.confirm_car_update.subtitle'),
            then: () => {
                dispatch(packagesAllRemove())
                dispatch(checklistLaboursReset())
                dispatch(officialServicesReset())
            }, catch: () => {
                history.goBack()
            }
        } : null)
    };

    resetToManual = () => {
        const { dispatch } = this.props;

        this.setState({licence_plate: null});
        dispatch(carReset());
        dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1));
    };

    goToCarNumber = () => {
        const { dispatch, garage } = this.props;

        if (garage.show_vin || garage.show_swiss || garage.show_kba) {
            dispatch(carStepSet(FORM_VEHICLE_TYPE_CAR_NUMBER));
        }
        else{
            this.resetToManual();
        }
    };

    renderFormStep = () => {
        const { carStep: { step } } = this.props;

        if(step === FORM_VEHICLE_TYPE_MANUAL_1){
            return this.renderFormStepManualFirst();
        }
        else if(step === FORM_VEHICLE_TYPE_MANUAL_2){
            return this.renderFormStepManualSecond();
        }
        else if(step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE){
            return this.renderFormStepMultipleVehicle();
        }
        else if(step === FORM_VEHICLE_TYPE_CAR_NUMBER){
            return this.renderFormStepCarNumber();
        }

        return this.renderFormStepLicencePlate();
    };

    renderFormStepLicencePlate = () => {
        const { t, car, touched, errors, loader, cantonList, formSubmitting, packages } = this.props;

        return (
            <div key="licence-plate">

                <LicencePlate
                    onChange={this.handleLicencePlateChange}
                    list={cantonList}
                    error={touched.licence_plate && errors.licence_plate}
                    onReset={this.handleLicencePlateReset}
                    value={car.licence_plate || this.state.licence_plate}
                    onFocus={() => {
                        this.setState({confirmLicencePlateChange: !!packages.length})
                    }}
                    t={t}
                />

                <Input
                    type="tel"
                    placeholder={`${t('form.placeholder.mileage')}`}
                    name="mileage"
                    label={`${t('form.label.mileage')} (${t('global.optional')})`}
                    value={car.mileage}
                    onChange={this.handleTextChange}
                    error={touched.mileage && errors.mileage}
                    extraClass="licence-plate-form-group"
                    integer={true}
                    maxLength={7}
                />

                <div className="word-hr-separator">
                    <span>{t('global.or')}</span>
                </div>

                <div className="text-center">
                    <div className="btn btn-sm btn-secondary" onClick={this.goToCarNumber}>{t('pages.add_cost.licence_plate.manual')}</div>
                </div>

                <div className="row">
                    <div className="col-sm-push-3 col-sm-6">
                        <div className="mv-20 submit-btn-padd">
                            {this.renderSubmit((formSubmitting && formSubmitting.vehicle) || (loader[LOADER_LICENCE_PLATE] && loader[LOADER_LICENCE_PLATE] === true), LOADER_LICENCE_PLATE)}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderFormStepCarNumber = () => {
        const { car, formSubmitting, touched, loader, t, errors, carNumberLabel } = this.props;

        return (
            <div key="car-number">

                <CarNumber
                    onChange={this.handleCarNumberChange}
                    error={touched.car_number && errors.car_number}
                    value={car.car_number}
                    carNumberLabel={carNumberLabel}
                />

                <Input
                    type="tel"
                    placeholder={`${t('form.placeholder.mileage')}`}
                    name="mileage"
                    label={`${t('form.label.mileage')} (${t('global.optional')})`}
                    value={car.mileage}
                    onChange={this.handleTextChange}
                    error={touched.mileage && errors.mileage}
                    extraClass="car-number-form-group"
                    integer={true}
                    maxLength={7}
                />

                <div className="word-hr-separator">
                    <span>{t('global.or')}</span>
                </div>

                <div className="text-center">
                    <div className="btn btn-sm btn-secondary" onClick={this.resetToManual}>{t(`pages.add_cost.car_number.${carNumberLabel}`)}</div>
                </div>

                <div className="row">
                    <div className="col-sm-push-3 col-sm-6">
                        <div className="mv-20 submit-btn-padd">
                            {this.renderSubmit(
                                (formSubmitting && formSubmitting.vehicle) || (loader[LOADER_API_GET_CAR_NUMBER] && loader[LOADER_API_GET_CAR_NUMBER] === true),
                                LOADER_API_GET_CAR_NUMBER
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderFormStepManualFirst = () => {
        const {t, car, values, touched, errors, loader, formSubmitting} = this.props;

        return (
            <div key="manual-first">
                <div className="row">
                    <div className="col-sm-8 col-sm-push-2">
                        <Select
                            id="make_id"
                            list={car.makes}
                            value={values.make_id}
                            label={t('form.label.make')}
                            onHasOneOption={(fieldId, option) => this.handleChangeSelectForMakes(fieldId, option)}
                            error={touched.make_id && errors.make_id}
                            selected={car.make_id}
                            disabled={!car.makes.length}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onEmptyList={this.handleMakesEmpty}
                            isLoading={loader[LOADER_FORM_VEHICLE]['makes']}
                            onBeforeOpen={this.confirmPackagesReset()}
                        >
                            {t('form.placeholder.make')}
                        </Select>
                        <Select
                            id="first_registration"
                            list={car.first_registration_list}
                            value={values.first_registration}
                            label={t('form.label.year')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.first_registration && errors.first_registration}
                            disabled={!car.first_registration_list.length}
                            selected={car.first_registration}
                            hidden={typeof car.first_registration === 'boolean' && car.first_registration === true}
                            isLoading={loader[LOADER_FORM_VEHICLE]['make_id']}
                            onBeforeOpen={this.confirmPackagesReset()}
                        >
                            {t('form.placeholder.year')}
                        </Select>

                        <Select
                            id="range_id"
                            list={car.ranges}
                            value={values.range_id}
                            label={t('form.label.range')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.range_id && errors.range_id}
                            disabled={!car.ranges.length}
                            selected={car.range_id}
                            isLoading={loader[LOADER_FORM_VEHICLE]['first_registration']}
                            onBeforeOpen={this.confirmPackagesReset()}
                            attributes={['productionFrom', 'productionTo']}
                            attributesSeparator={' - '}
                        >
                            {t('form.placeholder.range')}
                        </Select>

                        <Select
                            id="type_id"
                            list={car.types}
                            value={values.type_id}
                            label={t('form.label.type')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.type_id && errors.type_id}
                            disabled={!car.types.length}
                            selected={car.type_id}
                            hidden={typeof car.type_id === 'boolean' && car.type_id === true}
                            onBeforeOpen={this.confirmPackagesReset()}
                            isLoading={loader[LOADER_FORM_VEHICLE]['range_id']}
                            attributes={['productionFrom', 'productionTo']}
                            attributesSeparator={' - '}
                        >
                            {t('form.placeholder.type')}
                        </Select>

                        <Input
                            type="tel"
                            placeholder={`${t('form.placeholder.mileage')}`}
                            name="mileage"
                            label={`${t('form.label.mileage')} (${t('global.optional')})`}
                            value={car.mileage}
                            onChange={this.handleTextChange}
                            error={touched.mileage && errors.mileage}
                            integer={true}
                            maxLength={7}
                        />

                    </div>
                </div>

                <SubmitContainer>
                    {this.renderSubmit(
                        (formSubmitting && formSubmitting.vehicle) || (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true),
                        LOADER_API_GET_CAR
                    )}
                </SubmitContainer>
            </div>
        );
    };

    renderFormStepManualSecond = () => {
        const {t, car, values, touched, errors, loader, formSubmitting} = this.props;

        return (
            <div key="manual-second">
                <div className="row">
                    <div className="col-sm-8 col-sm-push-2">
                        <div className="mb-30">
                            <CarDetail car={car}/>
                        </div>
                        <Select
                            id="gear_id"
                            list={car.gears}
                            value={values.gear_id}
                            label={t('form.label.gear')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.gear_id && errors.gear_id}
                            disabled={!car.gears.length}
                            selected={car.gear_id}
                            hidden={car.gears.length <= 1}
                            onBeforeOpen={this.confirmPackagesReset()}
                            // isLoading={loader[LOADER_FORM_VEHICLE]['first_registration']}
                        >
                            {t('form.placeholder.gear')}
                        </Select>

                        <Select
                            id="qual_md"
                            list={car.qual_md_list}
                            value={values.qual_md}
                            label={t('form.label.qual_md_list')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.qual_md && errors.qual_md}
                            disabled={!car.qual_md_list.length}
                            selected={car.qual_md}
                            hidden={car.qual_md_list.length <= 1}
                            isLoading={loader[LOADER_FORM_VEHICLE]['gear_id']}
                            onBeforeOpen={this.confirmPackagesReset()}
                        >
                            {t('form.placeholder.qual_md_list')}
                        </Select>

                        <Select
                            id="qual_lt"
                            list={car.qual_lt_list}
                            value={values.qual_lt}
                            label={t('form.label.qual_lt_list')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.qual_lt && errors.qual_lt}
                            disabled={!car.qual_lt_list.length}
                            selected={car.qual_lt}
                            hidden={car.qual_lt_list.length <= 1}
                            isLoading={loader[LOADER_FORM_VEHICLE]['qual_md']}
                            onBeforeOpen={this.confirmPackagesReset()}
                        >
                            {t('form.placeholder.qual_lt_list')}
                        </Select>
                    </div>
                </div>

                <SubmitContainer>
                    {this.renderSubmit(
                        (formSubmitting && formSubmitting.vehicle) || (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true),
                        LOADER_API_GET_CAR
                    )}
                </SubmitContainer>
            </div>
        );
    };

    renderFormStepMultipleVehicle = () => {
        const { car, formSubmitting, touched, loader, values, t, errors } = this.props;

        return (
            <div key="multiple-vehicle">
                <Select
                    id="color_make_id"
                    list={car.makes}
                    value={values.color_make_id}
                    label={t('form.label.make')}
                    onHasOneOption={(fieldId, option) => this.handleChangeSelectForMakes(fieldId, option)}
                    error={touched.color_make_id && errors.color_make_id}
                    selected={car.color_make_id}
                    onChange={(id, option) => this.handleChangeSelectColorSelect(id, option)}
                    onBeforeOpen={this.confirmPackagesReset()}
                    directSearch
                >
                    {t('form.placeholder.make')}
                </Select>

                <Select
                    id="color"
                    list={car.color_list}
                    value={values.color}
                    label={t('form.label.color')}
                    onChange={(id, option) => this.handleChangeSelectColorSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.color && errors.color}
                    onBeforeOpen={this.confirmPackagesReset()}
                    selected={car.color}
                >
                    {t('form.placeholder.color')}
                </Select>

                <SubmitContainer>
                    {this.renderSubmit(
                        (formSubmitting && formSubmitting.vehicle) || (loader[LOADER_LICENCE_PLATE] && loader[LOADER_LICENCE_PLATE] === true),
                        LOADER_LICENCE_PLATE
                    )}
                </SubmitContainer>
            </div>
        )
    };

    render() {
        const { t, handleSubmit, carStep: {step} } = this.props;
        const { confirmLicencePlateChange } = this.state;

        return (
            <div className="form-holder">
                <div className="form-container">

                    <form onSubmit={e => handleSubmit(e)}>
                        {this.renderFormStep()}
                    </form>

                    {[FORM_VEHICLE_TYPE_LICENCE_PLATE, FORM_VEHICLE_TYPE_CAR_NUMBER].indexOf(step) !== -1 &&
                        <div className="mt-50">
                            <Draft title={t('pages.add_cost.draft.title')}/>
                        </div>
                    }

                    {(confirmLicencePlateChange && this.confirmPackagesReset()) &&
                        <Confirm
                            title={this.confirmPackagesReset().title}
                            subtitle={this.confirmPackagesReset().subtitle}
                            visible={!!confirmLicencePlateChange}
                            accept={() => {
                                this.confirmPackagesReset().then();
                                this.setState({confirmLicencePlateChange: false})}
                            }
                            cancel={() => {
                                this.confirmPackagesReset().catch();
                                this.setState({confirmLicencePlateChange: false})}
                            }
                        />
                    }
                </div>
            </div>
        );
    }
}

const FormikApp = withFormik(formVehicleConfig)(FormVehicle);

const mapStateToProps = state => {
    return {
        car: state.car,
        error: state.error,
        loader: state.loader,
        global: state.global,
        formSubmitting: state.formSubmitting,
        cantonList: state.cantonList,
        carStep: state.carStep,
        packages: state.packages,
        country: state.country,
        garage: state.garage,
        carNumberLabel: carNumberLabel(state.garage),
        checklistLabours: selectChecklistLabours(state),
    };
};

export default connect(mapStateToProps)(translate('translations')(FormikApp));
