import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { mechanicApiFetch } from '../../../store/actions/mechanic.actions'
import MechanicDetail from './Detail'
import ListLoader from '../ListLoader/ListLoader'
import { LOADER_MECHANICS_PAGE } from '../../../store/consts/loader.constants'
import './index.scss'

export const MechanicList = props => {
    const { mechanics, loader } = props

    useEffect(() => {
        props.dispatch(mechanicApiFetch())
    }, [])

    let renderEmptyList = () => {
        const { t } = props

        return <div className="mechanic-list-empty">{t('pages.mechanics.list_is_empty')}</div>
    }

    if (loader[LOADER_MECHANICS_PAGE]) return <ListLoader />

    if (!mechanics.length) return renderEmptyList()

    return (
        <React.Fragment>
            <div className="row">
                {mechanics.map(person => (
                    <div className="col-xs-12 col-sm-6">
                        <MechanicDetail key={person.id} person={person} />
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        mechanics: state.mechanics,
        loader: state.loader,
    }
}

export default connect(mapStateToProps)(translate('translations')(MechanicList))
