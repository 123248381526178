import React from 'react';
import './index.scss';
import {translate} from "react-i18next";
import {Link} from "react-router-dom";
import pages from "app/consts/routes";
import { connect } from "react-redux";
import { matchPath } from 'react-router-dom'
import history from 'routes/history'
import {addCostRoutes, homeRoutes, requestsRoutes, systemMessageRoutes} from "../../../routes/garage";

export const Footer = props => {
    const { user, t, systemMessagesCounter } = props;

    if (user.logged === false) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="footer-icons">
                <div className="container">
                    <Link to={pages.home} className={`footer-icons__icon ${homeRoutes.filter(r => matchPath(history.location.pathname, r)).length ? 'active' : ''}`}>
                        <i className="icon-pie"/>
                        <span>{t('footer.tabs.analytics')}</span>
                    </Link>
                    <Link to={pages.add_cost} className={`footer-icons__icon ${addCostRoutes.filter(r => matchPath(history.location.pathname, r)).length ? 'active' : ''}`}>
                        <i className="icon-add"/>
                        <span>{t('footer.tabs.make_requests')}</span>
                    </Link>
                    <Link to={pages.requests} className={`footer-icons__icon ${requestsRoutes.filter(r => matchPath(history.location.pathname, r)).length ? 'active' : ''}`}>
                        <i className="icon-list"/>
                        <span>{t('footer.tabs.requests')}</span>
                    </Link>
                    <Link to={pages.system_messages} className={` news-footer footer-icons__icon ${systemMessageRoutes.filter(r => matchPath(history.location.pathname, r)).length ? 'active' : ''}`}>
                        <i className="icon-bell"/>

                        {(systemMessagesCounter && systemMessagesCounter > 0) ?
                            (<div className="message-bubble">{systemMessagesCounter}</div>)
                            :
                            <div className="message-bubble-invisible"></div>
                        }

                        <span>{t('footer.tabs.news')}</span>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        systemMessagesCounter: state.systemMessagesCounter,
    };
};

export default connect(mapStateToProps)(translate('translations')(Footer));
