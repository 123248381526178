import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import "./AnalyticsEnhancedContainer.scss";
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import axiosMain from "axios";
import PickGarage from "../HomeContainer/PickGarage";
import {USER_ROLE_MECHANIC} from "../../store/consts/user.constants";
import UserFilter from "../HomeContainer/UserFilter";
import {connect} from "react-redux"
import CarLabours from "../../components/shared/CarLabours/CarLabours";
import EmptyList from "../../components/shared/EmptyList/EmptyList";
import {captureException} from "../../utils/captureException";

export const ANALYTICS_ENHANCED_FETCH_URL = 'garage/analytics/enhanced/top-labours';

class AnalyticsEnhancedTopLaboursContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: {},
            analytics: {
                checklists: [],
                users: [],
            },
            loading: true,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    updateFilter = (filter) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [filter.type]: filter,
            }
        }, () => {
            this.fetch();
        })
    }

    fetch = () => {
        const {filter} = this.state;

        this.setState({
            loading: true,
        })

        axios
            .post(ANALYTICS_ENHANCED_FETCH_URL, {filter: filter})
            .then(response => {
                this.setState({
                    analytics: response.data,
                })
            })
            .catch(error => {
                if (!axiosMain.isCancel(error)) {
                    captureException(error, ANALYTICS_ENHANCED_FETCH_URL);

                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                }
            })
            .finally(() => this.setState({loading: false}))
    }

    resetFilter = () => {
        this.setState({
            filter: {}
        }, () => this.fetch())
    }

    render() {
        const {t, user, garage} = this.props;
        const {loading, analytics} = this.state;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)}/>

                <div className="container">

                    {user.garages.length > 1 &&
                    <PickGarage
                        onSuccess={() => this.resetFilter()}
                        filter={(filter) => {
                            this.updateFilter(filter)
                        }}
                    />
                    }

                    {user.type !== USER_ROLE_MECHANIC &&
                    <UserFilter
                        users={analytics.users}
                        user={user}
                        garage={garage}
                        filter={(filter) => this.updateFilter(filter)}
                    />
                    }

                    {loading ? <ListLoader/> :
                        <div>
                            {Object.keys(analytics.labours).length > 0 ?
                                <CarLabours labours={analytics.labours}/>
                                :
                                <EmptyList label={t('global.no_data')}/>
                            }
                        </div>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(AnalyticsEnhancedTopLaboursContainer));
