export const FORM_VEHICLE_TYPE_LICENCE_PLATE = 'FORM_VEHICLE_TYPE_LICENCE_PLATE'
export const FORM_VEHICLE_TYPE_MANUAL_1 = 'FORM_VEHICLE_TYPE_MANUAL_1'
export const FORM_VEHICLE_TYPE_MANUAL_2 = 'FORM_VEHICLE_TYPE_MANUAL_2'
export const FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE = 'FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE'
export const FORM_VEHICLE_TYPE_CAR_NUMBER = 'FORM_VEHICLE_TYPE_CAR_NUMBER'

export const ALLOWED_IN_CAR_STEP_POP = [
    FORM_VEHICLE_TYPE_LICENCE_PLATE,
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_CAR_NUMBER,
]
