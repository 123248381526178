import React, { Component } from 'react';

class EmptyList extends Component {
    render() {
        const { icon, label, relative } = this.props;

        return (
            <React.Fragment>
                <div className={`centered-icon ${relative === true ? 'centered-icon--relative' : ''}`}>
                    <i className={icon} />
                    <div>{label}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default EmptyList;
