export const LABOUR_PRICES = '[Labour Prices]'

export const LABOUR_PRICES_HOURLY_PRICES_FETCH = `${LABOUR_PRICES} HOURLY PRICES FETCH`
export const LABOUR_PRICES_HOURLY_PRICES_SET = `${LABOUR_PRICES} HOURLY PRICES SET`
export const LABOUR_PRICES_HOURLY_PRICES_UPDATE = `${LABOUR_PRICES} HOURLY PRICES UPDATE`
export const LABOUR_PRICES_HOURLY_PRICES_REMOVE = `${LABOUR_PRICES} HOURLY PRICES REMOVE`

export const LABOUR_PRICES_OIL_FETCH = `${LABOUR_PRICES} OIL FETCH`
export const LABOUR_PRICES_OIL_SET = `${LABOUR_PRICES} OIL SET`
export const LABOUR_PRICES_OIL_UPDATE = `${LABOUR_PRICES} OIL UPDATE`

export const LABOUR_PRICES_LIQUIDS_FETCH = `${LABOUR_PRICES} LIQUIDS FETCH`
export const LABOUR_PRICES_LIQUIDS_SET = `${LABOUR_PRICES} LIQUIDS SET`
export const LABOUR_PRICES_LIQUIDS_UPDATE = `${LABOUR_PRICES} LIQUIDS UPDATE`

export const LABOUR_PRICES_DISCOUNT_FETCH = `${LABOUR_PRICES} DISCOUNT FETCH`
export const LABOUR_PRICES_DISCOUNT_SET = `${LABOUR_PRICES} DISCOUNT SET`
export const LABOUR_PRICES_DISCOUNT_REMOVE = `${LABOUR_PRICES} DISCOUNT REMOVE`
export const LABOUR_PRICES_PARTS_DISCOUNT_UPDATE = `${LABOUR_PRICES} PARTS DISCOUNT UPDATE`
export const LABOUR_PRICES_BRAKES_DISCOUNT_SET = `${LABOUR_PRICES} BRAKES DISCOUNT SET`
export const LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE = `${LABOUR_PRICES} BRAKES DISCOUNT UPDATE`
export const LABOUR_PRICES_APPOINTMENT_DISCOUNT_SET = `${LABOUR_PRICES} APPOINTMENT DISCOUNT SET`
export const LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE = `${LABOUR_PRICES} APPOINTMENT DISCOUNT UPDATE`

export const LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH = `${LABOUR_PRICES} ADDITIONAL_PERCENT_ON_WORKS FETCH`
export const LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_SET = `${LABOUR_PRICES} ADDITIONAL_PERCENT_ON_WORKS SET`
export const LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE = `${LABOUR_PRICES} ADDITIONAL_PERCENT_ON_WORKS UPDATE`

export const labourPricesHourlyPricesFetch = () => ({
    type: LABOUR_PRICES_HOURLY_PRICES_FETCH,
})

export const labourPricesHourlyPricesSet = hourlyPrices => ({
    type: LABOUR_PRICES_HOURLY_PRICES_SET,
    payload: {
        hourlyPrices,
    },
})

export const labourPricesHourlyPricesUpdate = (hourlyPrice, hourlyPriceId = null) => ({
    type: LABOUR_PRICES_HOURLY_PRICES_UPDATE,
    payload: {
        hourlyPrice,
        hourlyPriceId,
    },
})

export const labourPricesHourlyPricesRemove = hourlyPriceId => ({
    type: LABOUR_PRICES_HOURLY_PRICES_REMOVE,
    payload: {
        hourlyPriceId,
    },
})

export const labourPricesOilFetch = () => ({
    type: LABOUR_PRICES_OIL_FETCH,
})

export const labourPricesOilSet = oil => ({
    type: LABOUR_PRICES_OIL_SET,
    payload: {
        oil,
    },
})

export const labourPricesOilUpdate = prices => ({
    type: LABOUR_PRICES_OIL_UPDATE,
    payload: {
        prices,
    },
})

export const labourPricesLiquidsFetch = () => ({
    type: LABOUR_PRICES_LIQUIDS_FETCH,
})

export const labourPricesLiquidsSet = liquids => ({
    type: LABOUR_PRICES_LIQUIDS_SET,
    payload: {
        liquids,
    },
})

export const labourPricesLiquidsUpdate = prices => ({
    type: LABOUR_PRICES_LIQUIDS_UPDATE,
    payload: {
        prices,
    },
})

export const labourPricesDiscountFetch = () => ({
    type: LABOUR_PRICES_DISCOUNT_FETCH,
})

export const labourPricesDiscountSet = discounts => ({
    type: LABOUR_PRICES_DISCOUNT_SET,
    payload: {
        discounts,
    },
})

export const labourPricesDiscountRemove = (discountId, discountType) => ({
    type: LABOUR_PRICES_DISCOUNT_REMOVE,
    payload: {
        discountId,
        discountType
    },
})

export const labourPricesPartsDiscountUpdate = (discounts, discountId) => ({
    type: LABOUR_PRICES_PARTS_DISCOUNT_UPDATE,
    payload: {
        discounts,
        discountId,
    },
})

export const labourPricesBrakesDiscountSet = discounts => ({
    type: LABOUR_PRICES_BRAKES_DISCOUNT_SET,
    payload: {
        discounts,
    },
})

export const labourPricesBrakesDiscountUpdate = (discounts, discountId) => ({
    type: LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE,
    payload: {
        discounts,
        discountId,
    },
})

export const labourPricesAppointmentDiscountSet = discount => ({
    type: LABOUR_PRICES_APPOINTMENT_DISCOUNT_SET,
    payload: {
        discount,
    },
})

export const labourPricesAppointmentDiscountUpdate = data => ({
    type: LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE,
    payload: {
        data,
    },
})

export const labourPricesAdditionalPercentOnWorksFetch = () => ({
    type: LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH,
})

export const labourPricesAdditionalPercentOnWorksSet = percent => ({
    type: LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_SET,
    payload: {
        percent,
    },
})

export const labourPricesAdditionalPercentOnWorksUpdate = percent => ({
    type: LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE,
    payload: {
        percent,
    },
})
