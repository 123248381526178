import React, { Component } from 'react';
import './input.scss';
import classNames from 'classnames';

export const FLOAT_REGEX = new RegExp('^([0-9]+)?([\\.|\\,])?([0-9])*?$');
export const INTEGER_REGEX = new RegExp('^[0-9]*$');

class Input extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: (props.value !== null && props.value !== undefined) ? props.value : ''
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const {float, integer} = this.props;

        if(float){
            this.inputFilter(this.inputRef, value => FLOAT_REGEX.test(value));
        }
        else if (integer) {
            this.inputFilter(this.inputRef, value => INTEGER_REGEX.test(value));
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleChange = (e, callback) => {
        if (e.target instanceof HTMLInputElement) {

            let maxLen = e.target.maxLength.parseInt();
            if(maxLen > 0 && e.target.value.toString().length > maxLen){
                return;
            }

            this.setState({ value: e.target.value });

            callback();
        }
    };

    shouldShowRightIcon = () => {
        return this.props.iconRightClassName && this.state.value;
    };

    handleTextClear = () => {
        this.setState({
            value: ''
        });
        this.props.onTextClear();
    };

    inputFilter = (textbox, inputFilter) => {
        ['input', 'keydown', 'keyup'].forEach((event) => {
            textbox.addEventListener(event, (e) => {
                let ele = e.target;
                if (inputFilter(ele.value)) {
                    ele.value = this.escapeNonValidCharacters(ele.value);
                    ele.oldValue = this.escapeNonValidCharacters(ele.value);
                    ele.oldSelectionStart = ele.selectionStart;
                    ele.oldSelectionEnd = ele.selectionEnd;

                    if(event === 'input'){
                        this.change(e);
                    }
                } else if (ele.hasOwnProperty('oldValue')) {
                    ele.value = this.escapeNonValidCharacters(ele.oldValue);
                    ele.setSelectionRange(ele.oldSelectionStart, ele.oldSelectionEnd);
                }
            });
        });
    };

    escapeNonValidCharacters = (value) => {
        const {float, integer} = this.props;

        if(float && value.indexOf(',') !== -1) {
            return value.replace(',', '.').trim();
        }
        else if(integer) {
            return value.trim();
        }

        return value;
    };

    change = e => {
        const {onChange} = this.props;

        this.handleChange(e, () => {
            if (onChange) {
                onChange(e);
            }
        });
    }

    render() {
        const { value } = this.state;
        const {
            type,
            name,
            id,
            placeholder,
            label,
            onClick,
            onBlur,
            onFocus,
            onPaste,
            error,
            iconLeftClassName,
            className,
            iconRightClassName,
            maxLength,
            extraClass,
            hideErrorMessage,
            autoFocus,
            preventOnEnter,
            hidden,
            onKeyPress,
            readonly,
            addOn,
            step,
            disabled,
            leftChild,
            rightChild,
            autocomplete,
        } = this.props;

        if (hidden) return null;

        return (
            <div className={`form-group ${extraClass ? extraClass : ''} ${disabled ? 'disabled' : ''}`}>

                {label && <label htmlFor={name}>{label}</label>}

                <div className="relative">
                    {iconLeftClassName && (
                        <div className="input-icon input-icon--left">
                            <span className={iconLeftClassName} />
                        </div>
                    )}

                    {leftChild && (
                        <div className="input-icon input-icon--left">{leftChild}</div>
                    )}

                    {addOn &&
                        <span className="input-group-addon">{addOn}</span>
                    }

                    <input
                        onKeyPress={onKeyPress}
                        ref={ref => this.inputRef = ref}
                        type={type}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        className={classNames(className, {
                            'form-control': true,
                            'has-left-icon': iconLeftClassName || leftChild,
                            'has-right-icon': this.shouldShowRightIcon() || rightChild,
                            'input-error': error
                        })}
                        onChange={this.change}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }}
                        onPaste={e => {
                            if (onPaste)
                                onPaste(e.clipboardData.getData('text/plain'));
                            return false;
                        }}
                        value={value}
                        checked={this.props.checked} //TODO Remove when input checkbox component is added
                        autoComplete={autocomplete ? autocomplete : "off"}
                        maxLength={maxLength}
                        readOnly={readonly}
                        autoFocus={autoFocus}
                        onKeyDown={e => {
                            if (preventOnEnter && e.keyCode === 13) {
                                e.preventDefault();
                                return false;
                            }
                        }}
                        step={step}
                        disabled={disabled}
                    />

                    {this.shouldShowRightIcon() ? (
                        <div
                            className="input-icon input-icon--right"
                            onClick={this.handleTextClear}
                        >
                            <span className={iconRightClassName} />
                        </div>
                    ) : null}

                    {rightChild ? (
                        <div className="input-icon input-icon--right">
                            {rightChild}
                        </div>
                    ) : null}
                </div>

                {error && !hideErrorMessage && (
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                )}
            </div>
        );
    }
}

export default Input;
