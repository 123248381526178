import {
    MECHANIC_API_CREATE,
    set,
    add,
    remove,
    updateOne,
    MECHANIC_API_FETCH,
    MECHANIC_API_REMOVE,
    MECHANIC_API_UPDATE,
    MECHANIC_API_URL_INSERT,
    MECHANIC_API_URL_GET,
    MECHANIC_API_URL_DELETE,
    MECHANIC_API_URL_UPDATE,
} from '../../actions/mechanic.actions'
import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions'
import { formSubmittingMechanics } from '../../actions/formSubmitting.actions'
import { setLoader } from '../../actions/loader.actions'
import { LOADER_MECHANICS_PAGE } from '../../consts/loader.constants'

export const mechanicsMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        let formData = new FormData()

        switch (action.type) {
            case MECHANIC_API_CREATE:
                Object.keys(action.payload.person).forEach(key => {
                    formData.append(key, action.payload.person[key])
                })

                if (!action.payload.person.avatar) {
                    formData.delete('avatar')
                }
                dispatch(formSubmittingMechanics(true))
                dispatch(
                    apiRequest({
                        body: formData,
                        method: 'POST',
                        url: MECHANIC_API_URL_INSERT,
                        signature: MECHANIC_API_CREATE,
                    }),
                )
                break

            case `${MECHANIC_API_CREATE} ${API_SUCCESS}`:
                dispatch(formSubmittingMechanics(false))
                dispatch(add(action.payload.data.mechanic))
                break

            case `${MECHANIC_API_CREATE} ${API_ERROR}`:
                dispatch(formSubmittingMechanics(false))
                break

            case MECHANIC_API_FETCH:
                dispatch(setLoader(LOADER_MECHANICS_PAGE, true))
                dispatch(
                    apiRequest({
                        body: {},
                        method: 'GET',
                        url: MECHANIC_API_URL_GET,
                        signature: MECHANIC_API_FETCH,
                    }),
                )
                break

            case `${MECHANIC_API_FETCH} ${API_SUCCESS}`:
                dispatch(setLoader(LOADER_MECHANICS_PAGE, false))
                dispatch(set(action.payload.data.data))
                break

            case `${MECHANIC_API_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_MECHANICS_PAGE, false))
                break

            case MECHANIC_API_REMOVE:
                dispatch(formSubmittingMechanics(true))
                dispatch(
                    apiRequest({
                        body: {
                            id: action.payload.id,
                        },
                        method: 'POST',
                        url: MECHANIC_API_URL_DELETE,
                        signature: MECHANIC_API_REMOVE,
                    }),
                )
                break

            case `${MECHANIC_API_REMOVE} ${API_SUCCESS}`:
                dispatch(formSubmittingMechanics(false))
                dispatch(remove(action.payload.data.id))
                break

            case `${MECHANIC_API_REMOVE} ${API_ERROR}`:
                dispatch(formSubmittingMechanics(false))
                break

            case MECHANIC_API_UPDATE:
                Object.keys(action.payload.person).forEach(key => {
                    formData.append(key, action.payload.person[key])
                })

                if (!action.payload.person.avatar) {
                    formData.delete('avatar')
                }

                dispatch(formSubmittingMechanics(true))

                dispatch(
                    apiRequest({
                        body: formData,
                        method: 'POST',
                        url: MECHANIC_API_URL_UPDATE,
                        signature: MECHANIC_API_UPDATE,
                    }),
                )
                break

            case `${MECHANIC_API_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingMechanics(false))
                dispatch(updateOne(action.payload.data.mechanic))
                break

            case `${MECHANIC_API_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingMechanics(false))
                break

            default:
                break
        }
    }
}
