import axios from 'axios'
import {STORAGE_OFFER_TOKEN, STORAGE_USER_TOKEN} from 'app/consts/storage.consts'
import persistSingleUrls from 'routes/persistSingleUrls'
import {language} from 'utils/language'
import {addCostRoutes} from '../../routes/garage'
import {matchPath} from 'react-router-dom'
import history from '../../routes/history'
import {setCountryCode} from '../../utils/common'
import {setAppVersion} from "../../store/actions/appVersion.actions";
import store from '../../store/store';

// const ignoreRoutes = [
//     '/garage/notifications',
//     '/garage/analytics',
//     '/garage/logout',
//     '/garage/login',
//     '/garage/login-garage',
// ];

const instance = axios.create({
    baseURL: setCountryCode(process.env.REACT_APP_BASE_URL_API),
    persistSingleUrls: [],
})

instance.interceptors.request.use(config => {
    const OfferToken = sessionStorage.getItem(STORAGE_OFFER_TOKEN)

    if (OfferToken && addCostRoutes.filter(route => matchPath(history.location.pathname, route)).length > 0) {
        config.headers.common.OfferToken = OfferToken
    }
    const UserToken = localStorage.getItem(STORAGE_USER_TOKEN)
    if (UserToken) {
        config.headers.common.Authorization = `Bearer ${UserToken}`
    }

    config.headers.common.Language = language()

    if (persistSingleUrls.indexOf(config.url) !== -1) {
        config.cancelTokenSource = axios.CancelToken.source()
        config.cancelToken = config.cancelTokenSource.token

        config.persistSingleUrls.map((data, i) => {
            if (data.url === config.url) {
                data.cancel()
                config.persistSingleUrls.splice(i, 1)
            }

            return data
        })

        config.persistSingleUrls.push({
            cancel: config.cancelTokenSource.cancel,
            url: config.url,
        })
    }

    return config
})

instance.interceptors.response.use((response) => {
    // let params = new URL(response.config.url);

    // if (!ignoreRoutes.includes(params.pathname)) {
        const {dispatch} = store;
        dispatch(setAppVersion(response.headers['application-version']))
    // }

    return response;
})

export default instance
