import React from 'react';
import {translate} from 'react-i18next';
import Popup from "../../../components/shared/Popup/Popup";
import BaseCountry from "../../../utils/BaseCountry";
import EditCustomLabour from "./EditCustomLabour";

class CustomLabours extends React.Component {

    state = {
        labourEdit: null,
    }

    render() {
        const {t, _package, onClose} = this.props;
        const {labourEdit} = this.state;
        _package.labours.forEach(labour => {
            console.log(labour);
        });
        return (
            <>
                <Popup title={t('pages.package_details.price_fixed')} visible={true} onClose={onClose}>
                    <div className="extend-package-list mb-30">
                        {_package.labours.filter(l => (l.priceFixed !== 0)).map(labour => (
                            <div className="extend-package-list__item" key={labour.itemMpId}>
                                <span className="font-size-sm">
                                    {labour.name}
                                    <span className="vertical-text-delimiter">|</span>
                                    <span className={`bold ${!labour.priceFixed ? 'brand-warning' : ''}`}>
                                        {labour.priceFixed} {BaseCountry().currency}
                                    </span>
                                </span>

                                <span className="link-style edit-link"
                                      onClick={() => this.setState({labourEdit: labour})}><i
                                    className="icon-edit"/></span>
                            </div>
                        ))}
                    </div>
                </Popup>

                {labourEdit &&
                <EditCustomLabour
                    labour={labourEdit}
                    _package={_package}
                    onClose={() => this.setState({labourEdit: null})}
                />
                }
            </>
        );
    }
}

export default translate('translations')(CustomLabours);
