import React from 'react';
import './Toast.scss';
import {translate} from "react-i18next";

const ICONS = {
    'success': 'icon-check-fill',
    'error': 'icon-cancell-fill',
    'info': 'icon-info-fill',
    'warning': 'icon-warrning-fill',
    'app_version': 'icon-warrning-fill',
}


const Toast = ({text, type, t}) => (
    <div>
        <div className="toast-custom">
            <div className={`toast-custom__icon`}><i className={ICONS[type]}/></div>
            <div className="toast-custom-content">
                <div className={`toast-custom-content__title`}>{t('toast.' + type)}</div>
                <div className="toast-custom-content__text">{text}</div>
            </div>
        </div>
    </div>
)

export default (translate('translations')(Toast));
