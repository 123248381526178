import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Select from "../../components/shared/Select/Select";

const ALL = -1;
const TYPE_USER = 'user';

class UserFilter extends Component {

    state = {
        selected: ALL,
    }

    enrich = () => {
        const {t, user} = this.props;

        let users = this.props.users ? this.props.users : [];

        let list = [{id: ALL, item_id: ALL, name: t('pages.analytics.filters.all'), type: TYPE_USER}];

        return [
            ...list,
            {id: `${TYPE_USER}-${user.id}`, item_id: user.id, name: `${user.first_name} ${user.last_name}`, type: TYPE_USER},
            ...users.filter(u => u.id !== user.id).map(u => {
                return {...u, id: `${TYPE_USER}-${u.id}`, item_id: u.id, type: TYPE_USER}
            })
        ];
    }

    handleChange = (id, option) => {
        const {filter} = this.props;

        if(option.hasOwnProperty('id')){
            filter(option.id === ALL ? {...option, id: null} : {...option, id: option.item_id})
            this.setState({selected: option.id})
        }
    }

    render() {
        const {t, users, user} = this.props;
        const {selected} = this.state;

        if (!users || users.filter(u => u.id !== user.id).length === 0) {
            return null
        }

        const isUserSelectedAvailable = this.enrich().find((item)=>item.id == selected)

        let selectedUser = selected

        if (!isUserSelectedAvailable) {
            selectedUser = ALL
        }

        return (
            <React.Fragment>
                <div className="mb-30">
                    <Select
                        id={"users"}
                        list={this.enrich()}
                        selected={selectedUser}
                        label={t('pages.analytics.filters.users')}
                        onChange={this.handleChange}
                        onHasOneOption={this.handleChange}
                        disabled={users ? users.length === 0 : true}
                        persistOnListUpdate={true}
                    >
                        {t('pages.analytics.filters.users')}
                    </Select>
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(UserFilter)
