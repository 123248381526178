import axios from "../../app/config/axios";
import {setLocalStorageItem} from "../../utils/storage";
import {STORAGE_USER_TOKEN} from "../../app/consts/storage.consts";
import {setGarage} from "./garage.actions";
import {
    AUTH_REMOVE,
    USER_LOGIN,
    USER_LOGOUT,
    USER_RESET_PASSWORD_EMAIL,
    USER_RESET_PASSWORD_NEW
} from "../consts/auth.constants";
import {captureException} from "../../utils/captureException";

const USER_PICK_GARAGE_URL = 'garage/login-garage'

export const userLogin = data => ({
    type: USER_LOGIN,
    payload: {
        data,
    },
})

export const pickGarage = garageId => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            axios.post(USER_PICK_GARAGE_URL, {
                garage_id: garageId
            })
                .then(response => {
                    setLocalStorageItem(STORAGE_USER_TOKEN, response.data.access_token_user)
                    dispatch(setGarage(response.data.garage))

                    resolve(response)
                })
                .catch(error => {
                    reject(error);

                    captureException(error, USER_PICK_GARAGE_URL);
                })
        })
    }
}

export const userLogout = () => ({
    type: USER_LOGOUT,
})

export const userResetPassword = username => ({
    type: USER_RESET_PASSWORD_EMAIL,
    payload: {
        username,
    },
})

export const userResetPasswordNew = data => ({
    type: USER_RESET_PASSWORD_NEW,
    payload: {
        data,
    },
})

export const authRemove = () => ({
    type: AUTH_REMOVE,
})
