import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import './ConfirmPageContainer.scss';
import {changeStepData} from "../../store/actions/step.actions";
import {HasOfferToken} from "../../routes/middleware/HasOfferToken";
import {storeReset} from "../../store/actions/store.actions";
import {removeSessionStorageItem} from "../../utils/storage";
import {STORAGE_OFFER_TOKEN} from "../../app/consts/storage.consts";
import {setNavigationTitle} from "../../utils/setNavigationTitle";
import {RequestPreview} from "../../routes/middleware/RequestPreview/RequestPreview";
import {PackagesStep} from "../../routes/middleware/Packages/PackagesStep";
import Header from "../../components/layout/Header/Header";
import imagesPath from "../../utils/imagesPath";
import {Link} from "react-router-dom";
import pages from "app/consts/routes";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class BookNowConfirmPageContainer extends Component {

    componentDidMount() {
        const {t, dispatch} = this.props;

        document.title = t('titles.request_preview');
        setNavigationTitle(t('pages.request_preview.title'));

        dispatch(changeStepData(4));
        dispatch(storeReset());
    }

    componentWillUnmount() {
        removeSessionStorageItem(STORAGE_OFFER_TOKEN);
    }

    render() {
        const {t, title} = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">

                    <div className="confirm-page text-center">
                        <div className="mb-15">
                            <img
                                className="confirm-page__section__img"
                                src={imagesPath('check-small.gif')}
                                alt={t('alt.request-send-success-icon')}
                            />
                        </div>

                        <div className="font-weight-semi-bold font-size-2 mb-15 text-light">{t('pages.confirm.book_now_headline')}</div>
                        <div className="font-weight-light">{t('pages.confirm.book_now_description')}</div>

                    </div>

                </div>

                <SubmitContainer>
                    <Link to={pages.add_cost}
                          className="btn btn-lg btn-primary">{t('pages.confirm.new_request')}</Link>
                </SubmitContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user};
};

export default connect(mapStateToProps)(RequestPreview(PackagesStep(HasOfferToken(translate('translations')(BookNowConfirmPageContainer)))));
