import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {formChangeUserDetails} from "app/config/yup";
import {withFormik} from "formik";
import Button from "components/shared/Button/Button";
import Input from "components/shared/Input/Input";
import "./ChangeUserDetails.scss";
import Phone from "../../../components/shared/Phone/Phone";
import ImageUpload from "../../../components/shared/ImageUpload/ImageUpload";
import SubmitContainer from "../../../components/shared/SubmitContainer/SubmitContainer";

class ChangeUserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changeAvatar: false
        };

        this.avatar = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.user.avatar !== this.props.user.avatar){
            this.setState({changeAvatar: false});
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    handleFieldChange = (name, value) => {
        const {setFieldTouched, setFieldValue} = this.props;

        setFieldTouched(name, true);
        setFieldValue(name, value);
    };

    handleImageCancel = () => {
        const {setFieldTouched, setFieldValue} = this.props;

        setFieldTouched('avatar', false);
        setFieldValue('avatar', null);
        this.setState({changeAvatar: false})
    };

    render() {
        const { t, touched, errors, handleBlur, handleSubmit, formSubmitting, user } = this.props;

        return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.user_profile.section_tabs.change_details')}</div>
                    </div>
                    <div className="settings-tab__content">

                        <form onSubmit={e => handleSubmit(e)}>

                            {(user.avatar && !this.state.changeAvatar) ?
                                <div className="user-avatar">
                                    <img src={user.avatar} ref={ref => this.avatar = ref} alt="Avatar"/>
                                    <span className="link-style" onClick={() => this.setState({changeAvatar: true})}>{t('pages.user_profile.section_form.avatar.change')}</span>
                                </div>
                                :
                                <ImageUpload
                                    onSelect={(value) => this.handleFieldChange('avatar', value)}
                                    onCancel={this.handleImageCancel}
                                    showCancel={user.avatar}
                                    error={errors.avatar}
                                />
                            }

                            <Input
                                type="text"
                                placeholder={t('pages.user_profile.section_form.placeholder.first_name')}
                                name="first_name"
                                id="first_name"
                                label={t('pages.user_profile.section_form.label.first_name')}
                                value={user.first_name}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.first_name && errors.first_name}
                            />

                            <Input
                                type="text"
                                placeholder={t('pages.user_profile.section_form.placeholder.last_name')}
                                name="last_name"
                                id="last_name"
                                label={t('pages.user_profile.section_form.label.last_name')}
                                value={user.last_name}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.last_name && errors.last_name}
                            />

                            <Input
                                type="email"
                                placeholder={t('pages.user_profile.section_form.placeholder.email')}
                                name="email"
                                id="email"
                                label={t('pages.user_profile.section_form.label.email')}
                                value={user.email}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.email && errors.email}
                            />

                            <Input
                                type="text"
                                placeholder={t('pages.user_profile.section_form.placeholder.username')}
                                name="username"
                                id="username"
                                label={t('pages.user_profile.section_form.label.username')}
                                value={user.username}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.username && errors.username}
                            />

                            <Phone
                              name={'phone'}
                              onChange={(e) => this.handleFieldChange('phone', e)}
                              onBlur={handleBlur}
                              value={user.phone}
                              error={touched.phone && errors.phone}
                            />

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button id="user_profile_submit" size="lg" type="primary" disabled={formSubmitting.settings}>{t('pages.user_profile.section_form.submit_user_details')}</Button>
                                </div>
                            </SubmitContainer>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const ChangeUserDetailsForm = withFormik(formChangeUserDetails)(ChangeUserDetails);

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
        user: state.user
    };
};

export default connect(mapStateToProps)(translate('translations')(ChangeUserDetailsForm));
