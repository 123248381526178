import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions';
import {CHANGE_PASSWORD_SETTING_UPDATE} from "store/actions/change-password.settings.actions";
import {formSubmittingSettings} from "store/actions/formSubmitting.actions";

export const SETTINGS_CHANGE_PASSWORD_UPDATE_URL = 'garage/settings/change-password';

export const changePasswordSettingMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {

            case CHANGE_PASSWORD_SETTING_UPDATE:
                dispatch(formSubmittingSettings(true));
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: SETTINGS_CHANGE_PASSWORD_UPDATE_URL,
                        signature: CHANGE_PASSWORD_SETTING_UPDATE
                    })
                );

                break;

            case `${CHANGE_PASSWORD_SETTING_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingSettings(false));
                break;

            case `${CHANGE_PASSWORD_SETTING_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingSettings(false));
                break;

            default:
                break;
        }
    };
};
