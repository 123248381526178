import React, { Component } from 'react'
import { translate } from 'react-i18next'
import Request from './Request'
import { connect } from 'react-redux'
import './index.scss'
import { intersect } from '../../../utils/common'
import EmptyList from '../EmptyList/EmptyList'
import { USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER } from '../../../store/consts/user.constants'
import { STATUS_ACCEPTED, STATUS_OPEN, STATUS_REJECTED } from '../../../store/consts/package/package.status.constants'
import { STATUS_PENDING_MANAGER, STATUS_PENDING_USER } from '../../../store/consts/status.constants'

class Requests extends Component {
    constructor(props) {
        super(props)

        this.state = {
            requests: [],
        }
    }

    componentDidMount() {
        this.setState({
            requests: this.props.requests,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop],
                })
            }
        })
    }

    getDot = request => {
        if (!request.hasOwnProperty('packages')) return null

        if (request.packages.filter(p => p.status === STATUS_OPEN).length > 0) {
            return 'yellow'
        } else if (request.packages.filter(p => p.status === STATUS_ACCEPTED).length > 0) {
            return 'green'
        } else if (request.packages.filter(p => p.status === STATUS_REJECTED).length > 0) {
            return 'red'
        }

        return null
    }

    render() {
        const { t, user } = this.props

        return (
            <div className="requests-wrapper">
                {this.state.requests.length ? (
                    this.state.requests.map(req => {
                        return (
                            <Request
                                key={req.id}
                                request={req}
                                createdBy={user.type === USER_ROLE_MANAGER || user.type === USER_ROLE_SUPERVISOR}
                                time={req.status === STATUS_PENDING_USER || req.status === STATUS_PENDING_MANAGER}
                                dot={this.getDot(req)}
                            />
                        )
                    })
                ) : (
                    <EmptyList label={t('pages.requests.no_requests')} relative={true} />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(translate('translations')(Requests))
