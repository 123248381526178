import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { formSubmittingSettings } from 'store/actions/formSubmitting.actions'
import { updateGarage } from '../../actions/garage.actions'
import { CHANGE_GARAGE_DETAILS_SETTING_UPDATE } from '../../actions/change-garage-details.settings.actions'

export const SETTINGS_CHANGE_GARAGE_DETAILS_URL = 'garage/settings/update-garage-details'

export const changeGarageDetailsSettingMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case CHANGE_GARAGE_DETAILS_SETTING_UPDATE:
                dispatch(formSubmittingSettings(true))

                let formData = new FormData()
                Object.keys(action.payload).forEach(key => {
                    formData.append(key, action.payload[key])
                })

                if (!action.payload.logo) {
                    formData.delete('logo')
                }

                dispatch(
                    apiRequest({
                        body: formData,
                        method: 'POST',
                        url: SETTINGS_CHANGE_GARAGE_DETAILS_URL,
                        signature: CHANGE_GARAGE_DETAILS_SETTING_UPDATE,
                    }),
                )

                break

            case `${CHANGE_GARAGE_DETAILS_SETTING_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingSettings(false))
                dispatch(updateGarage(action.payload.data.garage))
                break

            case `${CHANGE_GARAGE_DETAILS_SETTING_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingSettings(false))
                break

            default:
                break
        }
    }
}
