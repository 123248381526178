import React, { Component } from 'react';
import './index.scss';
import Loader from "../Loader/Loader";
import {LOADER_CHECKBOX} from "../../../store/consts/loader.constants";

class InputCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked || ''
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            checked: nextProps.checked
        });
    }

    handleChange = (e) => {
        this.setState({ checked: e.target.checked });
    };

    render() {
        const { checked } = this.state;
        const {
            name,
            id,
            label,
            onChange,
            value,
            onBlur,
            disabled,
            loading,
        } = this.props;

        return         (
            <div className="custom-checkbox">
                <div className="custom-checkbox-label">
                    <input type="checkbox" id={id}
                           onChange={e => {
                               this.handleChange(e);
                               if (onChange) {
                                   onChange(e);
                               }
                           }}
                           onBlur={onBlur}
                           className="default-language-change"
                           name={name}
                           checked={checked}
                           value={value}
                           autoComplete="off"
                           disabled={disabled}/>

                    <div className="custom-checkbox--checkbox">
                        {loading === true ?
                            <Loader isLoading={loading} type={LOADER_CHECKBOX}/>
                            :
                            <span className="icon-check"/>
                        }
                    </div>
                </div>
                <label className="custom-control-label" htmlFor={id}>{label}</label>
            </div>
        );
    }
}

export default InputCheckbox;
