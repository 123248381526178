import Yup from './yup'
import {toBool} from "../../../utils/common";

export default {
    enableReinitialize: true,
    isInitialValid: ({car}) => toBool(car.mileage),

    mapPropsToValues({car}) {
        return {
            km_stand: car.mileage || '',
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            km_stand: Yup.number()
                .required()
                .label(t('pages.labour_search.official_service.section_form.label.km_stand')),
        })
    },
}
