export const TYPE_MANAGER_WAITING = 1
export const TYPE_MANAGER_ACCEPTED = 2
export const TYPE_MANAGER_REJECTED = 3
export const TYPE_USER_RESPONDED = 4
export const TYPE_USER_SMS_REPLY = 5
export const TYPE_CUSTOM = 6

export default {
    TYPE_MANAGER_WAITING,
    TYPE_MANAGER_ACCEPTED,
    TYPE_MANAGER_REJECTED,
    TYPE_USER_RESPONDED,
    TYPE_USER_SMS_REPLY,
    TYPE_CUSTOM,
}
