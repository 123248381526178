import React from "react";
import {fixedFloat, roundCeil} from "../../utils/common";
import BaseCountry from "../../utils/BaseCountry";

class TotalPrice extends React.Component {

    render() {

        const {sumPriceData} = this.props;

        const getPrice = () => {

            if (sumPriceData.price === sumPriceData.minPrice) {
                return <>{roundCeil(sumPriceData.maxPrice, true)} {BaseCountry().currency}</>
            }
            else if(sumPriceData.price === sumPriceData.maxPrice) {
                return <>{roundCeil(sumPriceData.minPrice, true)} {BaseCountry().currency}</>
            }else{
                return <>{roundCeil(sumPriceData.minPrice, true) + " - " + roundCeil(sumPriceData.maxPrice, true)} {BaseCountry().currency}</>
            }
        }

        return (
            <>
                {getPrice()}
            </>
        )
    }
}

export default TotalPrice;
