export default {
    id: null,
    name: null,
    type: null,
    select_type: null,
    labours: [],
    package_id: null,
    passed: null,
    siblings: [],
    updating: false,
    disabled: false,
    note: null,
}
