import React, { Component } from 'react';

class InputFile extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    render() {
        const {
            name,
            id,
            onChange,
            onClick,
            onBlur,
            autoFocus,
            preventOnEnter,
            hidden,
            onKeyPress,
            readonly,
            accept
        } = this.props;

        if (hidden) return null;

        return (
            <input
                onKeyPress={onKeyPress}
                ref={ref => this.inputRef = ref}
                type={"file"}
                name={name}
                id={id}
                onBlur={onBlur}
                accept={accept}
                onChange={e => {
                    if (onChange) {
                        onChange(e.target.files[0]);
                    }

                    this.inputRef.value = null;
                }}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
                readOnly={readonly}
                autoFocus={autoFocus}
                onKeyDown={e => {
                    if (preventOnEnter && e.keyCode === 13) {
                        e.preventDefault();
                        return false;
                    }
                }}
            />
        );
    }
}

export default InputFile;
