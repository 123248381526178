import {
    LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE, LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE, LABOUR_PRICES_DISCOUNT_REMOVE,
    LABOUR_PRICES_HOURLY_PRICES_FETCH,
} from 'store/actions/labourPrices.actions'
import { apiRequest } from 'store/actions/api.actions'
import { API_ERROR, API_SUCCESS } from 'store/actions/api.actions'
import { LABOUR_PRICES_HOURLY_PRICES_UPDATE, labourPricesHourlyPricesSet } from 'store/actions/labourPrices.actions'
import store from 'store/store'
import {formSubmittingDiscountBrakesPrice, formSubmittingHourlyPrice} from 'store/actions/formSubmitting.actions'
import {
    LABOUR_PRICES_HOURLY_PRICES_REMOVE,
    LABOUR_PRICES_LIQUIDS_FETCH,
    LABOUR_PRICES_LIQUIDS_UPDATE,
    LABOUR_PRICES_OIL_FETCH,
    LABOUR_PRICES_OIL_UPDATE,
    labourPricesLiquidsSet,
    labourPricesOilSet,
} from 'store/actions/labourPrices.actions'
import { formSubmittingLiquidsPrice, formSubmittingOilPrice } from 'store/actions/formSubmitting.actions'
import { LOADER_LABOUR_PRICES } from 'store/consts/loader.constants'
import { setLoader } from 'store/actions/loader.actions'
import {
    HOURLY_PRICES,
    PRICES_LIQUIDS,
    PRICES_OIL,
    PRICES_PARTS_DISCOUNT,
    PRICES_ADDITIONAL_PERCENT_ON_WORKS,
    PRICES_APPOINTMENT_DISCOUNT,
    PRICES_BRAKES_DISCOUNT,
} from 'store/consts/labourPrices.constants'
import {
    LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH,
    LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE,
    LABOUR_PRICES_DISCOUNT_FETCH,
    LABOUR_PRICES_PARTS_DISCOUNT_UPDATE,
    labourPricesAdditionalPercentOnWorksSet,
    labourPricesDiscountSet,
} from '../../actions/labourPrices.actions'
import {
    formSubmittingDiscountAppointmentPrice,
    formSubmittingDiscountPartsPrice,
    formSubmittingDiscountPrice,
} from '../../actions/formSubmitting.actions'
import discount from "../../../views/PackageDetailsContainer/Discount";
import {DISCOUNT_BRAKES_TYPE, DISCOUNT_PARTS_TYPE} from "../../consts/discounts.constants";
import {toast} from "react-toastify";
import Toast from "../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../utils/common";
import React from "react";
import i18n from "i18next";
import language from "../../../utils/language";

export const PRICE_UPDATE_URL = 'garage/price-update'
export const HOURLY_PRICE_URL = 'garage/price-update/hourly-price'
export const OIL_URL = 'garage/price-update/oil'
export const LIQUIDS_URL = 'garage/price-update/liquids'
export const DISCOUNTS_URL = 'garage/price-update/discounts'
export const ADDITIONAL_PERCENT_ON_WORKS_URL = 'garage/price-update/additional-percent-on-works'

export const labourPricesMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case LABOUR_PRICES_HOURLY_PRICES_FETCH:
                dispatch(setLoader(LOADER_LABOUR_PRICES, true))

                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: HOURLY_PRICE_URL,
                        signature: LABOUR_PRICES_HOURLY_PRICES_FETCH,
                    }),
                )

                break

            case `${LABOUR_PRICES_HOURLY_PRICES_FETCH} ${API_SUCCESS}`:
                dispatch(labourPricesHourlyPricesSet(action.payload.data))
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case `${LABOUR_PRICES_HOURLY_PRICES_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case LABOUR_PRICES_HOURLY_PRICES_UPDATE:
                dispatch(formSubmittingHourlyPrice(true))

                let labourPrices = store.getState().labourPrices.hourly.prices
                let carMakeIds = {}
                let year = {}
                let youngerPrice = {}
                let olderPrice = {}

                if (action.payload.hourlyPriceId) {
                    delete labourPrices[action.payload.hourlyPriceId]
                }

                Object.keys(labourPrices).forEach(carMakeId => {
                    carMakeIds[carMakeId] = carMakeId
                    year[carMakeId] = labourPrices[carMakeId][0]['year']
                    youngerPrice[carMakeId] = labourPrices[carMakeId][0]['price_rule']['price']

                    if (labourPrices[carMakeId].length > 1) {
                        olderPrice[carMakeId] = labourPrices[carMakeId][1]['price_rule']['price']
                    }
                })

                carMakeIds[action.payload.hourlyPrice.car_make_id] = action.payload.hourlyPrice.car_make_id
                year[action.payload.hourlyPrice.car_make_id] = action.payload.hourlyPrice.year
                youngerPrice[action.payload.hourlyPrice.car_make_id] = action.payload.hourlyPrice.younger_price

                if (action.payload.hourlyPrice.year > 0) {
                    olderPrice[action.payload.hourlyPrice.car_make_id] = action.payload.hourlyPrice.older_price
                }

                dispatch(
                    apiRequest({
                        body: {
                            car_make_id: carMakeIds,
                            year: year,
                            younger_price: youngerPrice,
                            older_price: olderPrice,
                            form_type: HOURLY_PRICES,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_HOURLY_PRICES_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_HOURLY_PRICES_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingHourlyPrice(false))
                dispatch(labourPricesHourlyPricesSet(action.payload.data))
                break

            case `${LABOUR_PRICES_HOURLY_PRICES_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingHourlyPrice(false))
                break

            case LABOUR_PRICES_HOURLY_PRICES_REMOVE:
                dispatch(formSubmittingHourlyPrice(true))

                let prices = Object.assign({}, store.getState().labourPrices.hourly.prices)
                delete prices[action.payload.hourlyPriceId]

                let carMakes = {}
                let years = {}
                let youngerPrices = {}
                let olderPrices = {}

                Object.keys(prices).forEach(carMakeId => {
                    carMakes[carMakeId] = carMakeId
                    years[carMakeId] = prices[carMakeId][0]['year']
                    youngerPrices[carMakeId] = prices[carMakeId][0]['price_rule']['price']

                    if (prices[carMakeId].length > 1) {
                        olderPrices[carMakeId] = prices[carMakeId][1]['price_rule']['price']
                    }
                })

                dispatch(
                    apiRequest({
                        body: {
                            car_make_id: carMakes,
                            year: years,
                            younger_price: youngerPrices,
                            older_price: olderPrices,
                            form_type: HOURLY_PRICES,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_HOURLY_PRICES_REMOVE,
                    }),
                )

                break

            case `${LABOUR_PRICES_HOURLY_PRICES_REMOVE} ${API_SUCCESS}`:
                dispatch(formSubmittingHourlyPrice(false))
                dispatch(labourPricesHourlyPricesSet(action.payload.data))
                break

            case `${LABOUR_PRICES_HOURLY_PRICES_REMOVE} ${API_ERROR}`:
                dispatch(formSubmittingHourlyPrice(false))
                break

            case LABOUR_PRICES_OIL_FETCH:
                dispatch(setLoader(LOADER_LABOUR_PRICES, true))

                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: OIL_URL,
                        signature: LABOUR_PRICES_OIL_FETCH,
                    }),
                )

                break

            case `${LABOUR_PRICES_OIL_FETCH} ${API_SUCCESS}`:
                dispatch(labourPricesOilSet(action.payload.data))
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case `${LABOUR_PRICES_OIL_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case LABOUR_PRICES_OIL_UPDATE:
                dispatch(formSubmittingOilPrice(true))

                dispatch(
                    apiRequest({
                        body: {
                            ...action.payload.prices,
                            form_type: PRICES_OIL,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_OIL_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_OIL_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingOilPrice(false))
                break

            case `${LABOUR_PRICES_OIL_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingOilPrice(false))
                break

            case LABOUR_PRICES_LIQUIDS_FETCH:
                dispatch(setLoader(LOADER_LABOUR_PRICES, true))

                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: LIQUIDS_URL,
                        signature: LABOUR_PRICES_LIQUIDS_FETCH,
                    }),
                )

                break

            case `${LABOUR_PRICES_LIQUIDS_FETCH} ${API_SUCCESS}`:
                dispatch(labourPricesLiquidsSet(action.payload.data))
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case `${LABOUR_PRICES_LIQUIDS_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case LABOUR_PRICES_LIQUIDS_UPDATE:
                dispatch(formSubmittingLiquidsPrice(true))

                dispatch(
                    apiRequest({
                        body: {
                            ...action.payload.prices,
                            form_type: PRICES_LIQUIDS,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_LIQUIDS_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_LIQUIDS_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingLiquidsPrice(false))
                break

            case `${LABOUR_PRICES_LIQUIDS_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingLiquidsPrice(false))
                break

            case LABOUR_PRICES_DISCOUNT_FETCH:
                dispatch(setLoader(LOADER_LABOUR_PRICES, true))

                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: DISCOUNTS_URL,
                        signature: LABOUR_PRICES_DISCOUNT_FETCH,
                    }),
                )

                break

            case `${LABOUR_PRICES_DISCOUNT_FETCH} ${API_SUCCESS}`:
                dispatch(labourPricesDiscountSet(action.payload.data))
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case `${LABOUR_PRICES_DISCOUNT_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case LABOUR_PRICES_PARTS_DISCOUNT_UPDATE:
                dispatch(formSubmittingDiscountPartsPrice(true))

                let discountPrices = store.getState().labourPrices.discounts.discounts[DISCOUNT_PARTS_TYPE]

                let discountCarMakeIds = {}
                let discountYear = {}
                let youngerDiscount = {}
                let olderDiscount = {}

                if (action.payload.discountId) {
                    delete discountPrices[action.payload.discountId]
                }

                if(discountPrices){
                    Object.keys(discountPrices).forEach(carMakeId => {
                        discountCarMakeIds[carMakeId] = carMakeId
                        discountYear[carMakeId] = discountPrices[carMakeId][0]['year']
                        youngerDiscount[carMakeId] = discountPrices[carMakeId][0]['price_rule']['discount']

                        if (discountPrices[carMakeId].length > 1) {
                            olderDiscount[carMakeId] = discountPrices[carMakeId][1]['price_rule']['discount']
                        }
                    })
                }


                discountCarMakeIds[action.payload.discounts.car_make_id] = action.payload.discounts.car_make_id
                discountYear[action.payload.discounts.car_make_id] = action.payload.discounts.year
                youngerDiscount[action.payload.discounts.car_make_id] = action.payload.discounts.younger_price

                if (action.payload.discounts.year > 0) {
                    olderDiscount[action.payload.discounts.car_make_id] = action.payload.discounts.older_price
                }

                dispatch(
                    apiRequest({
                        body: {
                            car_make_id: discountCarMakeIds,
                            year: discountYear,
                            younger_discount: youngerDiscount,
                            older_discount: olderDiscount,
                            form_type: PRICES_PARTS_DISCOUNT,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_PARTS_DISCOUNT_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_PARTS_DISCOUNT_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingDiscountPartsPrice(false))
                toast.success(<Toast text={i18n.getResource(language(), 'translations', 'toast_notifications.prices_updated')} type="info"/>)
                dispatch(labourPricesDiscountSet(action.payload.data))
                break

            case `${LABOUR_PRICES_PARTS_DISCOUNT_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingDiscountPartsPrice(false))
                dispatch(labourPricesDiscountSet(action.payload.data))
                break

            case LABOUR_PRICES_DISCOUNT_REMOVE:
                dispatch(formSubmittingDiscountPrice(true))

                let discountsRemove = Object.assign({}, store.getState().labourPrices.discounts.discounts)
                discountsRemove =  action.payload.discountType === PRICES_PARTS_DISCOUNT ? discountsRemove[DISCOUNT_PARTS_TYPE] : discountsRemove[DISCOUNT_BRAKES_TYPE]
                delete discountsRemove[action.payload.discountId]

                let carMakesRemove = {}
                let yearsRemove = {}
                let youngerDiscountRemove = {}
                let olderDiscountRemove = {}

                Object.keys(discountsRemove).forEach(carMakeId => {
                    carMakesRemove[carMakeId] = carMakeId
                    yearsRemove[carMakeId] = discountsRemove[carMakeId][0]['year']
                    youngerDiscountRemove[carMakeId] = discountsRemove[carMakeId][0]['price_rule']['discount']

                    if (discountsRemove[carMakeId].length > 1) {
                        olderDiscountRemove[carMakeId] = discountsRemove[carMakeId][1]['price_rule']['discount']
                    }
                })

                dispatch(
                    apiRequest({
                        body: {
                            car_make_id: carMakesRemove,
                            year: yearsRemove,
                            younger_discount: youngerDiscountRemove,
                            older_discount: olderDiscountRemove,
                            form_type: action.payload.discountType,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_DISCOUNT_REMOVE,
                    }),
                )

                break

            case `${LABOUR_PRICES_DISCOUNT_REMOVE} ${API_SUCCESS}`:
                dispatch(formSubmittingDiscountPrice(false))
                toast.success(<Toast text={i18n.getResource(language(), 'translations', 'toast_notifications.prices_updated')} type="info"/>)
                dispatch(labourPricesDiscountSet(action.payload.data))
                break

            case `${LABOUR_PRICES_DISCOUNT_REMOVE} ${API_ERROR}`:
                dispatch(formSubmittingDiscountPrice(false))
                break

            case LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE:
                dispatch(formSubmittingDiscountBrakesPrice(true))

                let discountBreaksPrices = store.getState().labourPrices.discounts.discounts[DISCOUNT_BRAKES_TYPE]

                let breaksCarMakeIds = {}
                let breaksDiscountYear = {}
                let breaksYoungerDiscount = {}
                let breaksOlderDiscount = {}

                if (action.payload.discountId) {
                    delete discountBreaksPrices[action.payload.hourlyPriceId]
                }

                if(discountBreaksPrices){
                    Object.keys(discountBreaksPrices).forEach(carMakeId => {
                        breaksCarMakeIds[carMakeId] = carMakeId
                        breaksDiscountYear[carMakeId] = discountBreaksPrices[carMakeId][0]['year']
                        breaksYoungerDiscount[carMakeId] = discountBreaksPrices[carMakeId][0]['price_rule']['discount']

                        if (discountBreaksPrices[carMakeId].length > 1) {
                            breaksOlderDiscount[carMakeId] = discountBreaksPrices[carMakeId][1]['price_rule']['discount']
                        }
                    })
                }


                breaksCarMakeIds[action.payload.discounts.car_make_id] = action.payload.discounts.car_make_id
                breaksDiscountYear[action.payload.discounts.car_make_id] = action.payload.discounts.year
                breaksYoungerDiscount[action.payload.discounts.car_make_id] = action.payload.discounts.younger_price

                if (action.payload.discounts.year > 0) {
                    breaksOlderDiscount[action.payload.discounts.car_make_id] = action.payload.discounts.older_price
                }

                dispatch(
                    apiRequest({
                        body: {
                            car_make_id: breaksCarMakeIds,
                            year: breaksDiscountYear,
                            younger_discount: breaksYoungerDiscount,
                            older_discount: breaksOlderDiscount,
                            form_type: PRICES_BRAKES_DISCOUNT,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingDiscountBrakesPrice(false))
                toast.success(<Toast text={i18n.getResource(language(), 'translations', 'toast_notifications.prices_updated')} type="info"/>)
                dispatch(labourPricesDiscountSet(action.payload.data))
                break

            case `${LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingDiscountBrakesPrice(false))
                break

            case LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE:
                dispatch(formSubmittingDiscountAppointmentPrice(true))

                dispatch(
                    apiRequest({
                        body: {
                            ...action.payload.data,
                            form_type: PRICES_APPOINTMENT_DISCOUNT,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingDiscountAppointmentPrice(false))
                break

            case `${LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingDiscountAppointmentPrice(false))
                break

            case LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH:
                dispatch(setLoader(LOADER_LABOUR_PRICES, true))

                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: ADDITIONAL_PERCENT_ON_WORKS_URL,
                        signature: LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH,
                    }),
                )

                break

            case `${LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH} ${API_SUCCESS}`:
                dispatch(labourPricesAdditionalPercentOnWorksSet(action.payload.data.percent))
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case `${LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LABOUR_PRICES, false))
                break

            case LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE:
                dispatch(formSubmittingDiscountPrice(true))

                dispatch(
                    apiRequest({
                        body: {
                            additional_percent_on_works: action.payload.percent,
                            form_type: PRICES_ADDITIONAL_PERCENT_ON_WORKS,
                        },
                        method: 'POST',
                        url: PRICE_UPDATE_URL,
                        signature: LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE,
                    }),
                )

                break

            case `${LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingDiscountPrice(false))
                break

            case `${LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingDiscountPrice(false))
                break

            default:
                break
        }
    }
}
