import country from '../models/country'
import { COUNTRY_SET } from '../actions/country.actions'

export default function(state = country, action) {
    switch (action.type) {
        case COUNTRY_SET:
            return action.payload.country

        default:
            return state
    }
}
