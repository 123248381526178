import analytics from '../models/analytics'
import {ANALYTICS_RESET, ANALYTICS_SET} from '../actions/analytics.actions'

export default function(state = analytics, action) {
    switch (action.type) {
        case ANALYTICS_SET:
            return action.payload.analytics

        case ANALYTICS_RESET:
            return analytics

        default:
            return state
    }
}
