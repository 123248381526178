import { fieldExists } from './fieldExists'

export const isCarFieldValid = (object, key, array, nullable) => {
  if (fieldExists(object, key)) {
    return true
  }

  return fieldExists(object, key, nullable) && fieldExists(object, array) && object[array].length === 0
}

export default isCarFieldValid
