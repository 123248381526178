import React, { Component } from 'react';
import './Badge.scss';

class Badge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || ''
        };
    }

    render() {
        const {children, type, extraClass, icon, inverted} = this.props;

        return (
            <div className={`badge badge-${type} ${inverted ? 'badge-' + type + '--inverted' : ''} ${extraClass ? extraClass : ''}`}>
                {children}

                {icon !== undefined &&
                    <span className={`badge-icon badge-${type}--icon`}>{icon}</span>
                }
            </div>
        );
    }
}

export default Badge;
