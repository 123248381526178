export const garageMiddleware = ({ dispatch }) => next => {
  return action => {
    next(action)

    switch (action.type) {
      default:
        break
    }
  }
}
