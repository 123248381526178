import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Select from "../../components/shared/Select/Select";
import {connect} from "react-redux";
import GarageReinitialize from "../../components/GarageReinitialize/GarageReinitialize";
import {USER_FILTER_ANALYTICS_BY_GARAGE_CONCEPT} from "../../store/consts/user.constants";
import {compare} from "../../utils/common";

const TYPE_GARAGE = 'garage';
const TYPE_CONCEPT = 'concept';

class PickGarage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initializeGarage: null,
            selected: `${TYPE_GARAGE}-${props.garage.id}`,
            list: this.enrich(props.user.garages, props.garage.concept),
            garageConcept: props.garage.concept,
            loading: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(compare(this.props.garage, prevProps.garage)){
            this.setState({
                selected: `${TYPE_GARAGE}-${this.props.garage.id}`,
                list: this.enrich(this.props.user.garages, this.props.garage.concept),
            })
        }
    }

    enrich = (list, garageConcept) => {
        const {t, user} = this.props;

        list = list.map(i => {
            return {...i, id: `${TYPE_GARAGE}-${i.id}`, item_id: i.id, type: TYPE_GARAGE}
        })

        if(USER_FILTER_ANALYTICS_BY_GARAGE_CONCEPT.indexOf(user.type) !== -1 && garageConcept){
            list.unshift({
                id: `${TYPE_CONCEPT}-${garageConcept.id}`,
                item_id: garageConcept.id,
                name: `${garageConcept.name} ${t('pages.analytics.garage_reinitialize.concept_total')}`,
                type: TYPE_CONCEPT
            })
        }

        return list;
    }

    handleChange = (id, option) => {
        const {filter} = this.props;

        if(option.type === TYPE_GARAGE){
            this.setState({
                initializeGarage: {...option, id: option.item_id}
            })
        }
        else {
            filter({...option, id: option.item_id})
        }

        this.setState({
            selected: option.id
        });
    }

    render() {
        const {t, onSuccess} = this.props;
        const {initializeGarage, selected, list} = this.state;

        return (
            <React.Fragment>
                <Select
                    id={"garages"}
                    list={list}
                    selected={selected}
                    label={t('pages.analytics.garage_reinitialize.label')}
                    onChange={this.handleChange}
                    persistOnListUpdate={true}
                >
                    {t('pages.analytics.garage_reinitialize.label')}
                </Select>

                {initializeGarage &&
                    <GarageReinitialize
                        garage={initializeGarage}
                        onSuccess={() => {
                            this.setState({initializeGarage: null})
                            onSuccess()
                        }}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(PickGarage));
