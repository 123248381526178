import labourSearch from '../models/labourSearch'
import { LABOURS_PREDEFINED_SET } from '../actions/laboursPredefined.actions'

export default function(state = labourSearch, action) {
  switch (action.type) {
    case LABOURS_PREDEFINED_SET:
      return action.payload.list

    default:
      return state
  }
}
