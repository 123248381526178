import React, {Component} from 'react';
import {translate} from "react-i18next";
import InputCheckbox from "../shared/InputCheckbox";
import Select from "../shared/Select/Select";
import classNames from 'classnames';
import {TIRES_CONTAINER_CALLED_FROM_CHECKLIST} from "./TiresPickContainer";

const TIRE_DISPOSAL_4X4 = 1124000011;

class TiresAdditional extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tires, calledFrom, handleChangeAdditionalSelect} = this.props;

        if(tires.additional.length !== prevProps.tires.additional.length){
            if(calledFrom === TIRES_CONTAINER_CALLED_FROM_CHECKLIST){
                handleChangeAdditionalSelect('storage', true);
            }
        }
    }

    handleChange = (name, checked) => {
        const {handleChangeAdditionalSelect} = this.props;

        handleChangeAdditionalSelect(name, checked)
    }

    handleChangeLabour = (name, option) => {
        const {handleChangeAdditionalSelectLabour, handleChangeAdditionalSelect} = this.props;

        handleChangeAdditionalSelectLabour(name, option)

        if(name === 'disposal'){
            handleChangeAdditionalSelect('lsv-handover', option.id === TIRE_DISPOSAL_4X4);
        }
    }

    render() {
        const {tires} = this.props;

        return (
            <React.Fragment>

                <div className="tires-additional">
                    <div className="row tires-axel-item">
                        {tires.additional.map((additional) => (
                            <TiresAdditionalItem
                                item={additional}
                                key={additional.name}
                                onChange={this.handleChange}
                                onChangeLabour={this.handleChangeLabour}
                            />
                        ))}
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

class TiresAdditionalItem extends Component {

    render() {
        const {item, onChange, onChangeLabour} = this.props;

        return (
            <div >
                <div className={classNames("col-xs-12 col-sm-3", {
                    'col-sm-12': item.list.length === 1,
                })}>
                    <InputCheckbox
                        id={`tire-${item.name}-select`}
                        name={`tire-${item.name}-select`}
                        value={item.name}
                        checked={item.checked}
                        onChange={(e) => onChange(e.target.value, e.target.checked)}
                        label={item.label}
                    />
                </div>
                {item.list.length > 1 &&
                <div className="col-xs-12 col-sm-6">
                    <Select
                        id={`tire-${item.name}-type`}
                        list={item.list}
                        selected={item.selected}
                        onChange={(id, option) => onChangeLabour(item.name, option)}
                        size={'sm'}
                        isLoading={!item.list.length}
                        disabled={!item.checked || !item.list.length}
                    />
                </div>
                }
            </div>
        )
    }
}

export default translate('translations')(TiresAdditional);
