import { USER_LOGIN, USER_LOGOUT, USER_RESET_PASSWORD_EMAIL, USER_RESET_PASSWORD_NEW } from './auth.constants'
import { CHANGE_PASSWORD_SETTING_UPDATE } from '../actions/change-password.settings.actions'
import { CHANGE_USER_DETAILS_SETTING_UPDATE } from '../actions/change-user-details.settings.actions'
import {
    LABOUR_PRICES_PARTS_DISCOUNT_UPDATE,
    LABOUR_PRICES_HOURLY_PRICES_REMOVE,
    LABOUR_PRICES_HOURLY_PRICES_UPDATE,
    LABOUR_PRICES_LIQUIDS_UPDATE,
    LABOUR_PRICES_OIL_UPDATE,
    LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE,
    LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE, LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE,
} from '../actions/labourPrices.actions'
import { MECHANIC_API_CREATE, MECHANIC_API_REMOVE, MECHANIC_API_UPDATE } from '../actions/mechanic.actions'
import { PACKAGES_ALL_REMOVE } from './package/package.constants'
import { CHANGE_GARAGE_DETAILS_SETTING_UPDATE } from '../actions/change-garage-details.settings.actions'
import {CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE} from "../actions/change-customer-signature.settings.actions";

export default [
    USER_LOGIN,
    USER_LOGOUT,
    USER_RESET_PASSWORD_EMAIL,
    USER_RESET_PASSWORD_NEW,
    CHANGE_PASSWORD_SETTING_UPDATE,
    CHANGE_USER_DETAILS_SETTING_UPDATE,
    CHANGE_GARAGE_DETAILS_SETTING_UPDATE,
    CHANGE_CUSTOMER_SIGNATURE_SETTING_UPDATE,
    LABOUR_PRICES_HOURLY_PRICES_UPDATE,
    LABOUR_PRICES_HOURLY_PRICES_REMOVE,
    LABOUR_PRICES_OIL_UPDATE,
    LABOUR_PRICES_LIQUIDS_UPDATE,
    LABOUR_PRICES_PARTS_DISCOUNT_UPDATE,
    LABOUR_PRICES_BRAKES_DISCOUNT_UPDATE,
    LABOUR_PRICES_APPOINTMENT_DISCOUNT_UPDATE,
    MECHANIC_API_CREATE,
    MECHANIC_API_UPDATE,
    MECHANIC_API_REMOVE,
    PACKAGES_ALL_REMOVE,
    LABOUR_PRICES_ADDITIONAL_PERCENT_ON_WORKS_UPDATE,
]
