import pages from "app/consts/routes";
import PickGarageContainer from "views/PickGarageContainer/PickGarageContainer";

const userRoutes = [
    {
        name: 'PickGarage',
        path: `${pages.pickGarage}`,
        component: PickGarageContainer,
        exact: true,
        headerTitle: 'pages.pick_garage.title',
        pageTitle: 'titles.pick_garage',
    },
];

export default userRoutes;
