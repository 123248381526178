import React, {Component} from 'react';
import {translate} from "react-i18next";
import {__clone, addTax, stringToBool, toBool} from "../../../utils/common";
import Popup from "../../shared/Popup/Popup";
import InputCheckbox from "../../shared/InputCheckbox";
import Button from "../../shared/Button/Button";
import {CHECKLIST_OK, CHECKLIST_NOT_OK} from "../ChecklistLabour";
import BaseCountry from "../../../utils/BaseCountry";
import ChecklistLayout from "../components/ChecklistLayout";

const SELECT_TYPE_SINGLE = 1

class ListType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showList: false,
        };
    }

    packageExists = () => this.props.packages.find(p => p.id === this.props.labour.package_id)

    handleChange = (passed) => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        if (passed === CHECKLIST_NOT_OK) {
            this.setState({
                showList: true,
            })
        }
        else if (passed === CHECKLIST_OK) {
            labourFieldUpdate({updating: true, passed: passed}).then(() => {
                onUpdatePassed();
            })
        }
    }

    onCloseShowList = () => {
        const {labourFieldUpdate} = this.props;

        labourFieldUpdate({updating: false})

        this.setState({
            showList: false,
        })
    }

    submit = (labours) => {
        const {packageFlow, labourFieldUpdate} = this.props;

        this.setState({
            showList: false,
        });

        labourFieldUpdate({updating: true, passed: CHECKLIST_NOT_OK}).then(() => {
            packageFlow(labours)
        })
    }

    render() {
        const {t, labour, showNotePopup} = this.props;
        const {showList} = this.state;

        let noteDisabled = labour.passed === null || labour.updating;

        return (
            <React.Fragment>
                <div className="checklist-labour">
                    <div className="col-xs-8">
                        <div className="checklist-labour__name">
                            {labour.name}
                        </div>
                    </div>
                    <ChecklistLayout name={"checklist-labour-list-item"} handleChange={this.handleChange} labour={labour}/>
                    <div className="col-xs-2">
                        <div className="checklist-labour__btn">
                            <span className="link-style" disabled={noteDisabled} onClick={!noteDisabled ? showNotePopup : null}>
                                {labour.note ? t('pages.checklist_labours.note.view') : t('pages.checklist_labours.note.new')}
                            </span>
                        </div>
                    </div>
                </div>

                <Popup
                    title={labour.name}
                    visible={showList}
                    onClose={this.onCloseShowList}
                >
                    <List {...this.props}
                          onSubmit={this.submit}
                          checklistLabour={labour}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            labour: props.checklistLabour,
        };
    }

    updateCheckListItems = (listIds, checked) => {
        const {labour} = this.state;

        if(listIds.length === 0){
            return;
        }

        this.setState({
            labour: {
                ...labour,
                labours: __clone(labour.labours).map(l => {
                    if (listIds.indexOf(l.id) !== -1) {
                        return {...l, checked: checked};
                    }
                    else if (labour.select_type === SELECT_TYPE_SINGLE && checked) {
                        return {...l, checked: !checked};
                    }

                    return l;
                })
            }
        })
    }

    getCheckedList = () => this.state.labour.labours.filter(l => l.checked === true && l.car_labour !== null).map(l => l.car_labour)

    render() {
        const {labour} = this.state;
        const {t, onSubmit, garage} = this.props;

        const Item = ({item}) => (
            <div className="checklist-labour-list-item">
                <InputCheckbox
                    id={`checklist-labour-list-item-${item.id}`}
                    name={`checklist-labour-list-item-${item.id}`}
                    value={item.id}
                    onChange={(e) => {this.updateCheckListItems(labour.labours.filter(l => l.id === parseInt(e.target.value)).map(l => l.id), e.target.checked)}}
                    checked={item.checked}
                    label={
                        <>
                            <span>{item.car_labour.name}</span>

                            {(toBool(item.car_labour.priceFixed)) &&
                            <>
                                <span className="vertical-text-delimiter">|</span>
                                <span className="bold">{addTax(item.car_labour.priceFixed, garage.tax, true)} {BaseCountry().currency}</span>
                            </>
                            }
                        </>
                    }
                />
            </div>
        )

        return (
            <div className="checklist-labour-list">
                {labour.labours.map(item => <Item item={item} key={item.id} />)}

                <Button size="lg" type="primary" extraClass={"mt-30"} onClick={() => onSubmit(this.getCheckedList())}
                        disabled={!this.getCheckedList().length}>{t('pages.checklist_labours.types.list.save')}</Button>
            </div>

        );
    }
}

export default translate('translations')(ListType);
