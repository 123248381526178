import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from "formik";
import {formDiscountPartsPrices} from "app/config/yup";
import Button from "../../../components/shared/Button/Button";
import Input from "components/shared/Input/Input";
import {compare} from "../../../utils/common";
import EmptyList from "../../../components/shared/EmptyList/EmptyList";
import Select from "../../../components/shared/Select/Select";
import {Popup} from "../../../components/shared/Popup/Popup";
import {labourPricesDiscountRemove} from "../../../store/actions/labourPrices.actions";
import {DISCOUNT_PARTS_TYPE} from "../../../store/consts/discounts.constants";
import {PRICES_PARTS_DISCOUNT} from "../../../store/consts/labourPrices.constants";
import Loader, {LOADER_LOADING} from "../../../components/shared/Loader/Loader";

class PartsDiscount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showAddDiscountPopup: false
        };
    }

    componentDidMount() {
        if (this.props.discountsData) {
            this.props.setValues({discountsData: this.props.discountsData.discounts});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(compare(nextProps.discountsData, this.props.discountsData)){
            this.props.setValues({discountsData: nextProps.discountsData.discounts});
            this.closeDiscountPrice();
        }
    }

    addAllMakes = (carMakes) => {
        const {t} = this.props;

        let makes = Object.assign([], carMakes);
        makes.unshift({id: 0, name: t('pages.price_update.section_form.label_option_all_makes')});
        return makes;
    };

    years = () => {
        const {t} = this.props;

        return [
            {
                'id': 0,
                'name': t('pages.price_update.years.all_years'),
                'name_opposite': ''
            },
            {
                'id': '1',
                'name': t('pages.price_update.years.2_years_younger'),
                'name_opposite': t('pages.price_update.years.2_years_older')
            },
            {
                'id': '2',
                'name': t('pages.price_update.years.3_years_younger'),
                'name_opposite': t('pages.price_update.years.3_years_older')
            },
            {
                'id': '3',
                'name': t('pages.price_update.years.4_years_younger'),
                'name_opposite': t('pages.price_update.years.4_years_older')
            },
            {
                'id': '4',
                'name': t('pages.price_update.years.5_years_younger'),
                'name_opposite': t('pages.price_update.years.5_years_older')
            },
            {
                'id': '5',
                'name': t('pages.price_update.years.6_years_younger'),
                'name_opposite': t('pages.price_update.years.6_years_older')
            }
        ];
    };

    onFormFieldChange = (name, value) => {
        this.updateFormField(name, value);
    };

    handleChangeSelect = (name, {id}) => {
        this.onFormFieldChange(name, id);
    };

    updateFormField = (name, value) => {
        const {values, setValues} = this.props;

        values[name] = value;
        setValues(values);
    };

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    onFormFieldChange = (name, value) => {
        this.updateFormField(name, value);
    };

    handleChangeSelect = (name, {id}) => {
        this.onFormFieldChange(name, id);
    };

    updateFormField = (name, value) => {
        const {values, setValues} = this.props;

        values[name] = value;
        setValues(values);
    };

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    editDiscount = (key) => {
        const {discountsData: {discounts}, setValues} = this.props;

        setValues({
            car_make_id: key,
            year: discounts[DISCOUNT_PARTS_TYPE][key][0].year,
            younger_price: discounts[DISCOUNT_PARTS_TYPE][key][0].price_rule.discount,
            older_price: discounts[DISCOUNT_PARTS_TYPE][key].length > 1 ? discounts[DISCOUNT_PARTS_TYPE][key][1].price_rule.discount : '',
            editing: key
        });

        this.setState({
            showAddDiscountPopup: true
        });
    };

    removeDiscount = (key) => {
        const {dispatch} = this.props;

        dispatch(labourPricesDiscountRemove(key, PRICES_PARTS_DISCOUNT));
    };

    addAllMakes = (carMakes) => {
        const {t} = this.props;

        let makes = Object.assign([], carMakes);
        makes.unshift({id: 0, name: t('pages.price_update.section_form.label_option_all_makes')});
        return makes;
    };


    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    closeDiscountPrice = () => {
        const {resetForm} = this.props;

        this.setState({showAddDiscountPopup: false});
        resetForm();
    };

    addDiscount = () => {
        const {t, handleBlur, values, discountsData: {carMakes}, handleSubmit, errors, touched, formSubmitting} = this.props;
        console.log(formSubmitting)
        return (
            <Popup title={t('pages.package_details.add_discount')} visible={this.state.showAddDiscountPopup}
                   onClose={this.closeDiscountPrice} hideCancel={formSubmitting.discountPartsPrice}>

                <form onSubmit={e => handleSubmit(e)} className="discount-price-form">

                    <div className="discount-price-item">
                        <Select
                            id={"car_make_id"}
                            list={this.addAllMakes(carMakes)}
                            value={parseInt(values.car_make_id)}
                            selected={parseInt(values.car_make_id)}
                            error={touched.car_make_id && errors.car_make_id}
                            label={t('pages.price_update.section_form.label_car_make')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(id, option) => this.handleChangeSelect(id, option)}
                        >
                            {t('pages.price_update.section_form.label_option_all_makes')}
                        </Select>

                        <div className="row">
                            <div className={`${values.year > 0 ? 'col-xs-12' : 'col-xs-6'}`}>
                                <Select
                                    id={"year"}
                                    list={this.years()}
                                    value={values.year}
                                    selected={values.year}
                                    error={touched.year && errors.year}
                                    label={t('pages.price_update.section_form.label_production_year')}
                                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                                    onHasOneOption={(id, option) => this.handleChangeSelect(id, option)}
                                >
                                    {t('pages.price_update.section_form.label_production_year')}
                                </Select>
                            </div>
                            <div className="col-xs-6">
                                <Input
                                    type="tel"
                                    placeholder={t('pages.price_update.section_form.label_discount')}
                                    name={"younger_price"}
                                    label={t('pages.price_update.section_form.label_younger_discount')}
                                    onBlur={handleBlur}
                                    error={touched.younger_price && errors.younger_price}
                                    onChange={this.handleTextChange}
                                    value={values.younger_price}
                                    addOn={'%'}
                                    step={0.000001}
                                    float={true}
                                />
                            </div>
                            {values.year > 0 &&
                                <div className="col-xs-6">
                                    <Input
                                        type="tel"
                                        placeholder={t('pages.price_update.section_form.label_discount')}
                                        name={"older_price"}
                                        label={this.years()[values.year].name_opposite}
                                        onBlur={handleBlur}
                                        onChange={this.handleTextChange}
                                        value={values.older_price}
                                        addOn={'%'}
                                        step={0.000001}
                                        float={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <Button disabled={formSubmitting.discountPartsPrice || formSubmitting.discountPrice} size="lg" type="primary">
                        {t('pages.price_update.section_form.button_save')}</Button>
                </form>
            </Popup>
        )
    };

    loader = () => {
        const {formSubmitting} = this.props
        return (
            <Popup
                visible={formSubmitting.discountPartsPrice || formSubmitting.discountPrice}
                hideCancel={true}
            >
                <div className="mv-30 text-light text-center">
                    <Loader type={LOADER_LOADING} isLoading={true}/>
                </div>
            </Popup>
        )
    }

    render() {
        const {discountsData: {discounts, carMakes}, t, title, formSubmitting} = this.props;

        return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">{t(`pages.price_update.discount.${title}`)}</div>
                    <div className="settings-tab__content">
                        <div className="list-of-prices pv-30">

                        {discounts && discounts[DISCOUNT_PARTS_TYPE] && Object.keys(discounts[DISCOUNT_PARTS_TYPE])?.map((key) => {
                            let discount = discounts[DISCOUNT_PARTS_TYPE][key];

                            return (
                                <div className="col-sm-12 col-xs-12" key={key}>
                                    <div className="list-of-prices__item">
                                        <div className="row mb-10">
                                            <span
                                                className="col-xs-6">{t('pages.price_update.section_form.label_car_make')}</span>
                                            <span
                                                className="col-xs-6 font-weight-semi-bold text-light font-size-default">
                                            {this.addAllMakes(carMakes).map((carMake) => {
                                                if (discount[0].price_rule.car_make_id === carMake.id) {
                                                    return carMake.name
                                                }
                                            })}
                                        </span>
                                        </div>
                                        <div className="row mb-10">
                                            <span className="col-xs-6">
                                                {this.years().map((yearList) => {
                                                    if (discount[0].year === yearList['id']) {
                                                        return yearList['name'];
                                                    }
                                                })}
                                            </span>
                                            <span
                                                className="col-xs-6 font-weight-semi-bold text-light font-size-default">{discount[0].price_rule.discount} {'%'}</span>
                                        </div>

                                        {discount.length > 1 &&
                                            <div className="row mb-10">
                                                <div className="form-group">
                                                <span className="col-xs-6">
                                                    {this.years().map((yearList) => {
                                                        return (discount[0].year === yearList['id'] ? yearList['name_opposite'] : '')
                                                    })}
                                                </span>
                                                    <span
                                                        className="col-xs-6 font-weight-semi-bold text-light font-size-default">
                                                    {discount[1].price_rule.discount} {'%'}
                                                </span>
                                                </div>
                                            </div>
                                        }

                                        <div className="list-of-prices__item__footer">
                                            <div className="col-xs-6 text-center link-style edit-link"
                                                 onClick={() => this.removeDiscount(key)} data-index={key}><i
                                                className="icon-bin"></i> {t('pages.price_update.section_form.remove')}
                                            </div>
                                            <div className="col-xs-6 text-center link-style edit-link"
                                                 onClick={() => this.editDiscount(key)} data-index={key}><i
                                                className="icon-edit"></i> {t('pages.price_update.section_form.change')}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                        {(!discounts || !discounts[DISCOUNT_PARTS_TYPE] || Object.keys(discounts[DISCOUNT_PARTS_TYPE]).length === 0) &&
                            <EmptyList label={t('pages.price_update.discount.empty_list')}/>
                        }
                        </div>
                    </div>
                    <div className="services-save-footer">
                        <Button id="discount_brakes_save" size="lg" type="primary" disabled={formSubmitting.discountPartsPrice || formSubmitting.discountPrice}
                                onClick={() => this.setState({showAddDiscountPopup: true})}>
                            {t('pages.price_update.section_form.button_add_discount')}
                        </Button>
                    </div>
                    {this.addDiscount()}
                    {this.loader()}
                </div>
            </React.Fragment>
        );
    }
}

const DiscountsUpdateForm = withFormik(formDiscountPartsPrices)(PartsDiscount);

const mapStateToProps = (state) => {
    return {
        discountsData: state.labourPrices.discounts,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(DiscountsUpdateForm));
