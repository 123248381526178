import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import axios from "../../app/config/axios";
import history from "../../routes/history";
import pages from "../../app/consts/routes";
import Header from "../../components/layout/Header/Header";
import "./style.scss"
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import InputCheckbox from "../../components/shared/InputCheckbox";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import {captureException} from "../../utils/captureException";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {sortBy} from "lodash";

const GET_CHECKLIST_LABOURS = 'garage/list-checklist-labour'
const CHECKLIST_LABOUR_SAVE = 'garage/save-checklist-labour'

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

class Checklists extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checklistLabour: null,
            user: this.props.user,
            garage: this.props.garage,
            loader: this.props.loader,
            search: '',
            requestLoader: true,
        }

        this.checklistLabourSaved = []

        this.onDragEnd = this.onDragEnd.bind(this)
    }

    componentDidMount() {
        const {t} = this.props

        document.title = t('titles.checklist_labour')

        this.fetchChecklistLabour(this.state.garage)
    }

    handleSearch = (e) => {
        this.setState({search: e.target.value})
    }

    handleClearSearch = (e) => {
        this.setState({search: ''})
    }

    sortChecklists = (a, b) => {
        if (a.order === b.order) {
            return 0;
        }
        if (a.order === null) {
            return 1;
        }
        return a.order - b.order
    }

    fetchChecklistLabour = (garage) => {
        this.setState({
            requestLoader: true,
        })

        axios
            .post(GET_CHECKLIST_LABOURS, {
                repair_shop_id: garage.id
            })
            .then(response => {
                this.setState({
                    checklistLabour: sortBy(response.data, 'order'),
                    requestLoader: false,
                    userDataShow: false
                })
            })
            .catch(error => {
                captureException(error, GET_CHECKLIST_LABOURS);
                history.push(pages.requests)
            })
    }

    saveChecklistLabour = (garage) => {
        this.setState({
            requestLoader: true,
        })

        let array = []
        let checkboxes = document.querySelectorAll('input[type=checkbox]:checked.default-language-change')

        for (let i = 0; i < checkboxes.length; i++) {
            array.push(parseInt(checkboxes[i].value))
        }

        axios
            .post(CHECKLIST_LABOUR_SAVE, {
                repair_shop_id: garage.id,
                checklist_labour_ids: array
            })
            .then(response => {
                this.setState({
                    checklistLabour: sortBy(response.data, 'order'),
                    requestLoader: false,
                    userDataShow: false
                })
                this.checklistLabourSaved = []
                toast.success(<Toast text={parseResponse(response)} type="success"/>)
            })
            .catch(error => {
                captureException(error, CHECKLIST_LABOUR_SAVE);
                history.push(pages.requests)
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
    }

    filterBySearch = (list) => {
        const {search} = this.state;

        if (search.length >= 2) {
            return list.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
        }

        return list
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const checklistLabour = reorder(
            this.state.checklistLabour,
            result.source.index,
            result.destination.index
        );

        this.setState({
            checklistLabour
        });
    }

    render() {
        const {requestLoader, garage, checklistLabour} = this.state
        const {t, title, search} = this.props

        const ChecklistLabourDetail = (checklistLabour) => {
            return (
                <div className="checklist-labour__item">
                    {(checklistLabour.checklistLabour.name) &&
                    <div className="checklist-labour__property">

                        <InputCheckbox
                            id={checklistLabour.checklistLabour.id}
                            checked={checklistLabour.checklistLabour.checked}
                            label={checklistLabour.checklistLabour.name}
                            value={checklistLabour.checklistLabour.id}
                        />
                    </div>
                    }
                </div>
            )
        }

        const ChecklistLabourList = (checklistLabours) => {

            if (checklistLabours.length === 0) {
                return (
                    <div>
                        <div className="checklist-labour__property">{t('pages.checklist_labour.no_results')}</div>
                    </div>
                )
            }

            return (
                <div className="checklist-labour__list">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {checklistLabours.checklistLabour.map((checklistLabour, index) => (
                                        <Draggable key={checklistLabour.id} draggableId={String(checklistLabour.id)} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                        <ChecklistLabourDetail key={checklistLabour.id} checklistLabour={checklistLabour}/>
                                                </div>)}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>)}
                        </Droppable>
                    </DragDropContext>
                </div>
            )
        }

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">

                    {requestLoader ? <ListLoader/> :
                        <div>
                            <Input
                                type="text"
                                id="checklist-labour-search-input"
                                placeholder={t('pages.checklist_labour.search')}
                                name="checklist-labour-search-input"
                                value={search}
                                onChange={this.handleSearch}
                                onTextClear={this.handleClearSearch}
                                iconRightClassName={'icon-cancel'}
                                iconLeftClassName={'icon-search'}
                            />

                            <ChecklistLabourList checklistLabour={this.filterBySearch(checklistLabour)}/>

                            <SubmitContainer stat={true}>
                                <Button size="lg" type="primary"
                                        onClick={() => this.saveChecklistLabour(garage)}>{t('pages.checklist_labour.save')}</Button>
                            </SubmitContainer>

                        </div>
                    }
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: state.garage,
        loader: state.loader,
    }
}

export default connect(mapStateToProps)(translate('translations')(Checklists))
