import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import './style.scss'
import store from "../../store/store";
import {setLocalAppVersion} from "../../store/actions/appVersion.actions";

class HeaderAppVersionInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appVersion: props.appVersion
        }
    }

    reloadApplication = appVersion => {
        store.dispatch(setLocalAppVersion(appVersion.responseVersion))
        window.location.reload(true)
    }

    render() {

        const {appVersion, t} = this.props

        return(
            <>
                {appVersion && (appVersion.localVersion !== appVersion.responseVersion) &&
                <div className="app-version-up-to-date-info__header-notification" onClick={() => this.reloadApplication(appVersion)}>
                    <i className="icon-info-fill"/><span>{t('global.app_version_out_of_date')}</span>
                </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
        appVersion: state.appVersion
    };
};

export default connect(mapStateToProps)(translate('translations')(HeaderAppVersionInfo));


