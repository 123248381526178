import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Button from "../../shared/Button/Button";
import "./ActivityManagerWaiting.scss";
import Popup from "../../shared/Popup/Popup";
import UserData from "../../../views/RequestPreviewContainer/UserData";
import Loader, {LOADER_BUTTON} from "../../shared/Loader/Loader";
import {withFormik} from "formik";
import {formManagerApprove} from "../../../app/config/yup";
import {connect} from "react-redux";
import axios from "../../../app/config/axios";
import {omit} from "lodash";
import {toast} from "react-toastify";
import Toast from "../../shared/Toast/Toast";
import {parseResponse} from "../../../utils/common";
import {STATUS_PENDING_MANAGER} from "../../../store/consts/status.constants";
import {captureException} from "../../../utils/captureException";

const REQUEST_APPROVE_URL = 'garage/add-cost-tool/approve-request'
const REQUEST_REJECT_URL = 'garage/add-cost-tool/reject-request'

class ActivityManagerWaitingAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showApprove: false,
            approving: false,
            rejecting: false,
        };
    }

    showApprove = () => {
        const {values, setFieldValue, activity} = this.props

        Object.keys(values).map((field) => activity.user[field] ? setFieldValue(field, activity.user[field]) : null)
        Object.keys(values).map((field) => activity.offer[field] ? setFieldValue(field, activity.offer[field]) : null)

        this.setState({showApprove: true});
    }

    approve = (e) => {
        const {values, setFieldTouched, isValid, approved, activity} = this.props

        e.preventDefault();

        Object.keys(values).map((field) => setFieldTouched(field, true))

        if (!isValid) {
            return
        }

        this.setState({
            approving: true
        })

        axios.post(REQUEST_APPROVE_URL, {
            offer_token: activity.offer_token,
            user: omit(values, ['time_due', 'garage_file', 'comment', 'appointment_discounts']),
            offer: {
                time_due: values.time_due,
                contract_number: values.garage_file,
                comment: values.comment,
                appointment_discounts: values.appointment_discounts,
            }
        })
            .then((response) => {
                this.setState({showApprove: false});
                approved(response.data.request)
            })
            .catch(error => {
                captureException(error, REQUEST_APPROVE_URL)

                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({
                    approving: false,
                });
            })
    }

    reject = (offerToken) => {
        const {rejected} = this.props

        this.setState({
            rejecting: true
        })

        axios.post(REQUEST_REJECT_URL, {offer_token: offerToken})
            .then(() => {
                rejected();
            })
            .catch(error => {
                captureException(error, REQUEST_REJECT_URL)

                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({
                    rejecting: false,
                });
            })
    }

    render() {
        const {t, values, touched, errors, handleBlur, handleChange, setFieldValue, activity, garage} = this.props;
        const {approving, showApprove, rejecting} = this.state;

        return (
            <div>
                {activity.status === STATUS_PENDING_MANAGER &&
                <div className="activity-manager-waiting-action">
                    <Button size="md" type="danger" disabled={approving || rejecting}
                            onClick={() => this.reject(activity.offer_token)}
                            extraClass={"btn-danger--outline btn-link"}>Reject</Button>
                    <Button size="md" type="primary" disabled={rejecting} onClick={this.showApprove}>Approve</Button>
                </div>
                }

                <Popup
                    title={t('pages.request_details.client.approve.title')}
                    subtitle={t('pages.request_details.client.approve.title')}
                    visible={showApprove}
                    contentScrollable={true}
                    onClose={() => this.setState({showApprove: false})}
                >
                    <form onSubmit={this.approve}>

                        <UserData
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleFieldChange={(name, value) => {
                                this.props.setFieldTouched(name, true);
                                this.props.setFieldValue(name, value);
                            }}
                            user={activity.user}
                            garage={garage}
                            packages={activity.packages}
                            selectedAppointments={activity.appointment_discounts}
                            onUserFound={(e) => {
                                Object.keys(e.item).map(key => {
                                    this.props.setFieldTouched(key, true);
                                    this.props.setFieldValue(key, e.item[key]);
                                })
                            }}
                            onUserReset={() => {
                                Object.keys(values).map((field) => activity.user.hasOwnProperty(field) ? this.props.setFieldValue(field, activity.user[field]) : null)
                            }}
                        />

                        <Button size="md" type="primary" extraClass={"btn-block"} disabled={approving}>
                            <Loader isLoading={approving} type={LOADER_BUTTON}/>
                            <div className="font-size-2">{t('pages.request_preview.send')}</div>
                        </Button>

                    </form>
                </Popup>

            </div>
        );
    }
}

const ManagerApproveForm = withFormik(formManagerApprove)(ActivityManagerWaitingAction);

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(ManagerApproveForm));
