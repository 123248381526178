export const CLIENT = '[Client]'

export const CLIENT_SET = `${CLIENT} SET`
export const CLIENT_RESET = `${CLIENT} RESET`

export const clientSet = client => ({
  type: CLIENT_SET,
  payload: {
    client,
  },
})

export const clientReset = () => ({
  type: CLIENT_RESET,
})
