import React, {Component} from 'react';
import {translate} from "react-i18next";
import ChecklistLayout from "../components/ChecklistLayout";

class CustomType extends Component {

    handleChange = (passed) => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        labourFieldUpdate({updating: true, passed: passed}).then(() => {
            onUpdatePassed();
        })
    }

    render() {
        const {t, labour, showNotePopup} = this.props;

        let noteDisabled = labour.passed === null || labour.updating;

        return (
            <React.Fragment>
                <div className="checklist-labour">
                    <div className="col-xs-8">
                        <div className="checklist-labour__name">
                            {labour.name}
                        </div>
                    </div>
                    <ChecklistLayout name={"checklist-labour"} handleChange={this.handleChange} labour={labour}/>
                    <div className="col-xs-2">
                        <div className="checklist-labour__btn">
                            <span className="link-style" disabled={noteDisabled} onClick={!noteDisabled ? showNotePopup : null}>
                                {labour.note ? t('pages.checklist_labours.note.view') : t('pages.checklist_labours.note.new')}
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default translate('translations')(CustomType)

