import React, { Component } from 'react';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import Loader, {LOADER_LOADING} from "../Loader/Loader";

class ListLoader extends Component {
    render() {
        const { t, text } = this.props;

        return (
            <React.Fragment>
                <div className="centered-icon">
                    <div><Loader type={LOADER_LOADING} isLoading={true}/> {text ? text : t('global.loading')}</div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(translate('translations')(ListLoader));
