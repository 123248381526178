export const CAR = '[Car]'

export const CAR_DATA_CHANGE = `${CAR} DATA_CHANGE`
export const CAR_UPDATE_FIELD = `${CAR} UPDATE_FIELD`
export const CAR_UPDATE_OBJECT = `${CAR} UPDATE_OBJECT`
export const CAR_FETCH_DATA = `${CAR} FETCH_DATA`
export const CAR_RESET_FIELDS_BELLOW = `${CAR} RESET_FIELDS_BELLOW`
export const CAR_FETCH_LICENCE_PLATE = `${CAR} FETCH_LICENCE_PLATE`
export const CAR_MULTIPLE_VEHICLE_CHOOSE = `${CAR} MULTIPLE_VEHICLE_CHOOSE`
export const CAR_FETCH_CAR_NUMBER = `${CAR} FETCH_CAR_NUMBER`
export const CAR_NUMBER_CHANGE_RESET = `${CAR} NUMBER_CHANGE_RESET`
export const CAR_LICENCE_PLATE_CHANGE_RESET = `${CAR} LICENCE_PLATE_CHANGE_RESET`
export const CAR_RESET = `${CAR} RESET`

export const carDataChange = (name, value, shouldCallAPI) => ({
    type: CAR_DATA_CHANGE,
    payload: {
        name,
        value,
        shouldCallAPI,
    },
})

export const carUpdateField = (name, value) => ({
    type: CAR_UPDATE_FIELD,
    payload: {
        name,
        value,
    },
})

export const carUpdateObject = car => ({
    type: CAR_UPDATE_OBJECT,
    payload: {
        car,
    },
})

export const fetchCarData = () => ({
    type: CAR_FETCH_DATA,
})

export const carResetFieldsBellow = name => ({
    type: CAR_RESET_FIELDS_BELLOW,
    payload: {
        name,
    },
})

export const carLicencePlate = car => ({
    type: CAR_FETCH_LICENCE_PLATE,
    payload: {
        car,
    },
})

export const carMultipleVehicleChoose = car => ({
    type: CAR_MULTIPLE_VEHICLE_CHOOSE,
    payload: {
        car,
    },
})

export const carNumber = car => ({
    type: CAR_FETCH_CAR_NUMBER,
    payload: {
        car,
    },
})

export const carNumberChangeReset = () => ({
    type: CAR_NUMBER_CHANGE_RESET,
})

export const licencePlateChangeReset = () => ({
    type: CAR_LICENCE_PLATE_CHANGE_RESET,
})

export const carReset = () => ({
    type: CAR_RESET,
})
