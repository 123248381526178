import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Input from "components/shared/Input/Input";
import {withFormik} from "formik";
import {formWorkAdd} from "app/config/yup";
import Button from "components/shared/Button/Button";
import {compare} from "utils/common";
import {usePrevious} from "components/hooks/utils";
import {packagesUpdate} from "store/actions/packages.actions";
import {updateObject} from "utils/common";
import {deepObject} from "utils/common";
import {__clone, parseDeepPath} from "../../../utils/common";
import lodash from "lodash";
import workModel from "store/models/package/work";
import randomPackageId from "utils/randomPackageId";
import {WORK_TYPE_CHECKBOX} from "../../../store/consts/package/work";

const WorkAdd = props => {

    const { t, handleBlur, touched, errors, handleChange, isValid, packagePath, packages, dispatch, formSubmitting, setTouched, onClose,
        setFieldTouched, setFieldValue} = props;

    const [ work, updateWork ] = useState(lodash.defaultsDeep({
        id: randomPackageId(),
        input_type: WORK_TYPE_CHECKBOX,
        selected: true
    }, workModel));

    const [ workUpdated, setWorkUpdated ] = useState(false);

    const prevWork = usePrevious(work);
    const prevPackages = usePrevious(props.packages);

    useEffect(() => {

        let labour = deepObject(__clone(packages), packagePath);

        if(labour.works.find(work => work.autogenerated === false && work.duration === 0)){
            updateWork({...work, name: labour.name})
            setFieldTouched("name", true)
            setFieldValue("name", labour.name)
        }

    }, []);

    useEffect(() => {
        setWorkUpdated((work && prevWork && compare(work, prevWork)));
    }, [work]);

    useEffect(() => {
        if(prevPackages){
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        setTouched({
            name: true,
            duration: true,
        });

        if(workUpdated && isValid){

            let labour = deepObject(__clone(packages), packagePath);

            if(labour.works.length > 0){
                labour.works.push(work);
            }
            else{
                labour.works.push({...work, selected: true});
            }

            dispatch(packagesUpdate(updateObject(packages, labour, packagePath), parseDeepPath(packagePath)[0].id));
        }
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>
                <Input
                    type="text"
                    placeholder={t('pages.package_details.labour.works.section_form.placeholder.name')}
                    name="name"
                    label={t('pages.package_details.labour.works.section_form.label.name')}
                    value={work.name}
                    onBlur={handleBlur}
                    onChange={e => {updateWork({...work, name: e.target.value}); handleChange(e)}}
                    error={touched.name && errors.name}
                />

                <Input
                    type="tel"
                    placeholder={t('pages.package_details.labour.works.section_form.placeholder.duration')}
                    name="duration"
                    label={t('pages.package_details.labour.works.section_form.label.duration')}
                    value={work.duration}
                    onBlur={handleBlur}
                    onChange={e => {updateWork({...work, duration: e.target.value.parseFloat()}); handleChange(e)}}
                    error={touched.duration && errors.duration}
                    addOn="H"
                    step={0.0001}
                    float={true}
                />

                <Button size="lg" type="primary" disabled={formSubmitting.packages || !workUpdated}>{t('global.save')}</Button>
            </form>

        </React.Fragment>
    );
};

const WorkAddForm = withFormik(formWorkAdd)(WorkAdd);

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(WorkAddForm));
