import pages from "app/consts/routes";
import LoginContainer from "views/LoginContainer/LoginContainer";
import ForgetPasswordContainer from "views/ForgetPasswordContainer/ForgetPasswordContainer";
import ForgetPasswordNewContainer from "views/ForgetPasswordNewContainer/ForgetPasswordNewContainer";

const authRoutes = [
    {
        name: 'Login',
        path: `${pages.login}`,
        component: LoginContainer,
        exact: true,
        headerTitle: 'pages.login.title',
        pageTitle: 'titles.login',
    },
    {
        name: 'Forget Password',
        path: `${pages.forgetPassword}`,
        component: ForgetPasswordContainer,
        exact: true,
        headerTitle: 'pages.password_reset.title',
        pageTitle: 'titles.password_reset',
    },
    {
        name: 'Forget Password New',
        path: `${pages.forgetPasswordNew}`,
        component: ForgetPasswordNewContainer,
        exact: true,
        headerTitle: 'pages.password_reset_new.title',
        pageTitle: 'titles.password_reset_new',
    },
];

export default authRoutes;
