import i18n from 'i18next'
import language from '../../../utils/language'

export const STATUS_OPEN = 1
export const STATUS_REJECTED = 2
export const STATUS_ACCEPTED = 3

export const STATUS = new Proxy(
    {
        [STATUS_OPEN]: () =>
            i18n.getResource(language(), 'translations', 'pages.request_details.package.packages_opened.title'),
        [STATUS_ACCEPTED]: () =>
            i18n.getResource(language(), 'translations', 'pages.request_details.package.packages_accepted.title'),
        [STATUS_REJECTED]: () =>
            i18n.getResource(language(), 'translations', 'pages.request_details.package.packages_rejected.title'),
    },
    {
        get: (obj, name) => {
            return obj[name]()
        },
    },
)

export default {
    STATUS_OPEN,
    STATUS_ACCEPTED,
    STATUS_REJECTED,
    STATUS,
}
