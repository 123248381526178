import React, {Component} from "react";
import {translate} from "react-i18next";
import {TIRES_AXELS} from "store/consts/tires.constants";

class PackageSelectedTires extends Component {

    render() {
        const {t, _package, loading} = this.props;

        if(loading){
            return <div>{t('global.loading')}</div>
        }

        return (
            <React.Fragment>

                <div className="package-item-content__parts">

                    {_package.tires.list.map(tireType => (
                        <div className="mb-10" key={tireType.type}>

                            <div>{tireType.full_name}</div>

                            <ul>
                                {TIRES_AXELS.filter(axel => tireType[axel]).map(axel => (
                                    <li className="small text-lighter"><span className="small">{t(`tires.axel.${axel}`)}:</span> {tireType[axel].full_axel}</li>
                                ))}
                            </ul>
                        </div>
                    ))}

                    {_package.tires.additional.map(additional => <div key={additional.item_mp_id}>{additional.name}</div>)}
                </div>

            </React.Fragment>
        );
    }
}

export default (translate('translations')(PackageSelectedTires));
