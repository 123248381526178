import {TIRES_EDIT_PREPARE, tiresUpdate} from "../../actions/tires.actions";
import {
    TIRES_AXELS, TIRES_SEASON_SUMMER, TIRES_STEP_LIST_PREMIUM, TIRES_TYPES
} from "../../consts/tires.constants";
import tiresModel from '../../models/tires'
import {cloneDeep} from "lodash";

export const tiresMiddleware = ({dispatch}) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case TIRES_EDIT_PREPARE:

                let tires = cloneDeep(tiresModel);

                let season = TIRES_SEASON_SUMMER;

                action.payload.tires.list.forEach(tireType => {
                    TIRES_AXELS.forEach(axel => {
                        if (tireType[axel]) {
                            tires.axel[axel] = {
                                selected: true,

                                wide: tireType[axel].wide,
                                wide_list: [],
                                depth: tireType[axel].depth,
                                depth_list: [],
                                diameter: tireType[axel].diameter,
                                diameter_list: [],

                                brand_type: {
                                    ...tires.axel[axel].brand_type,
                                    [tireType.type]: tireType[axel],
                                },
                            };

                            season = tireType[axel].season;
                        }
                        else {
                            tires.axel[axel] = {
                                ...tires.axel[axel],
                                selected: false,
                            };
                        }
                    })
                })

                tires.step = TIRES_STEP_LIST_PREMIUM;
                tires.sameDimensions = false;

                tires.season = season;

                tires.additional_selected = action.payload.tires.additional;
                tires.additional = [];

                dispatch(tiresUpdate(tires));

                break

            default:
                break
        }
    }
}
