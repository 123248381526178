import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Info from "../Info/Info";
import InputCheckbox from "../../../components/shared/InputCheckbox";
import {sumBy} from "lodash";

class ItemAdditional extends Component {

    state = {
        labourInfo: null
    }

    parts = (service) => {
        if (service.works.find(work => work.name === service.name)) {
            return service.works.find(work => work.name === service.name).parts.filter(part => part.selected).map(part => part.name);
        }

        return [];
    }

    showInfo = (e, service) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({labourInfo: service})
    }

    render() {
        const {labourInfo} = this.state;
        const {service, change} = this.props;

        return (
            <>
                <div className="official-services-item">
                    <div className="official-services-item__check">
                        <InputCheckbox
                            id={`official-services-${service.itemMpId}`}
                            name={`official-services-${service.itemMpId}`}
                            value={service.itemMpId}
                            checked={service.checked}
                            onChange={change}
                            disabled={service.in_official}
                            label={
                                <div className="official-services-item__name">
                                    <div className="official-services-item__name__left">
                                        <span className="official-services-item__name__left__name">{service.name}</span>
                                        <span
                                            className="official-services-item__name__left__duration"> - {sumBy(service.works.filter(work => work.selected), work => work.duration)}h</span>
                                    </div>
                                    <div className="float-right">
                                        {service.description &&
                                        <span className="link-style" onClick={(e) => this.showInfo(e, service)}><i
                                            className="icon-info-fill"/></span>
                                        }
                                    </div>
                                    {service.addText &&
                                    <div className="official-services-item__name__add-text">
                                        {service.addText}
                                    </div>
                                    }
                                </div>
                            }
                        />
                    </div>
                </div>

                {labourInfo &&
                <Info
                    title={labourInfo.name}
                    visible={!!labourInfo}
                    content={labourInfo.description}
                    onClose={() => this.setState({labourInfo: null})}
                />
                }
            </>
        )
    }
}

export default translate('translations')(ItemAdditional);
