import {
    MECHANIC_ADD,
    MECHANIC_LIST_SET,
    MECHANIC_REMOVE,
    MECHANIC_UPDATE_ONE
} from "../actions/mechanic.actions";

export default function(state = [], action) {

    switch (action.type) {

        case MECHANIC_LIST_SET:
            return [
                ...action.payload.list
            ];

        case MECHANIC_UPDATE_ONE:
            return [...state.map(person => {
                if (person.id === action.payload.person.id) {
                    return action.payload.person;
                }

                return person;
            })];

        case MECHANIC_ADD:
            return [
                ...state,
                action.payload.person
            ];


        case MECHANIC_REMOVE:
            return [
                ...state.filter(person => person.id !== action.payload.id)
            ];

        default:
            return state;
    }
}
