import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { LOADER_NOTIFICATIONS } from 'store/consts/loader.constants'
import { setLoader } from 'store/actions/loader.actions'
import {
    NOTIFICATIONS_FETCH,
    NOTIFICATIONS_UPDATE_SEEN, NOTIFICATIONS_UPDATE_SEEN_ALL,
    seenNotifications,
    setNotifications,
} from '../../actions/notifications.actions'
import store from 'store/store'

export const NOTIFICATIONS_FETCH_URL = 'garage/notifications'
export const NOTIFICATIONS_UPDATE_SEEN_URL = 'garage/notifications/update-seen'
export const NOTIFICATIONS_UPDATE_SEEN_ALL_URL = 'garage/notifications/update-seen-all'

export const notificationsMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case NOTIFICATIONS_FETCH:
                if (
                    store.getState().notifications.meta.current_page === store.getState().notifications.meta.last_page
                ) {
                    break
                }

                dispatch(setLoader(LOADER_NOTIFICATIONS, false))

                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: { page: store.getState().notifications.meta.current_page + 1 },
                        url: NOTIFICATIONS_FETCH_URL,
                        signature: NOTIFICATIONS_FETCH,
                    }),
                )

                break

            case `${NOTIFICATIONS_FETCH} ${API_SUCCESS}`:
                dispatch(setNotifications(action.payload.data))
                dispatch(setLoader(LOADER_NOTIFICATIONS, true))
                break

            case `${NOTIFICATIONS_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_NOTIFICATIONS, true))
                break

            case NOTIFICATIONS_UPDATE_SEEN:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: { notifications: action.payload.notifications.map(notify => notify.id) },
                        url: NOTIFICATIONS_UPDATE_SEEN_URL,
                        signature: NOTIFICATIONS_UPDATE_SEEN,
                    }),
                )

                break

            case `${NOTIFICATIONS_UPDATE_SEEN} ${API_SUCCESS}`:
                dispatch(seenNotifications(action.payload.data))
                break

            case `${NOTIFICATIONS_UPDATE_SEEN} ${API_ERROR}`:
                break

            case NOTIFICATIONS_UPDATE_SEEN_ALL:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        url: NOTIFICATIONS_UPDATE_SEEN_ALL_URL,
                        signature: NOTIFICATIONS_UPDATE_SEEN_ALL,
                    }),
                )

                break

            case `${NOTIFICATIONS_UPDATE_SEEN_ALL} ${API_SUCCESS}`:
                dispatch(seenNotifications(action.payload.data))
                break

            case `${NOTIFICATIONS_UPDATE_SEEN_ALL} ${API_ERROR}`:
                break

            default:
                break
        }
    }
}
