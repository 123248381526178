import React, { Component } from 'react';
import "./ImageUpload.scss";
import InputFile from "../InputFile/InputFile";
import Popup from "../Popup/Popup";
import ImageCrop from "../ImageCrop/ImageCrop";
import {translate} from "react-i18next";

class ImageUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageUrl: null,
            imageCropped: null,
            cropImagePopupVisible: false
        };
    }

    handleFileChange = (e) => {
        this.setState({
            imageUrl: URL.createObjectURL(e),
            cropImagePopupVisible: true
        })
    }

    onCrop = (e) => {
        const {onSelect} = this.props;

        this.setState({
            imageCropped: e,
            cropImagePopupVisible: false
        })

        onSelect(e)
    }

    cancel = () => {
        const {onCancel} = this.props;

        this.setState({
            imageUrl: null,
            imageCropped: null,
        });

        onCancel();
    }

    render() {
        const {t, showCancel, error} = this.props;
        const {file, imageUrl, imageCropped, cropImagePopupVisible} = this.state;

        return (
            <div>
                <div className="drop-area">
                    <label htmlFor="image__upload"/>
                    <InputFile
                        id="image__upload"
                        accept="image/*"
                        onChange={this.handleFileChange}
                        error={false}
                    />
                    {imageCropped ?
                        <img src={URL.createObjectURL(imageCropped)} alt="..."/>
                        :
                        <span>{t('pages.user_profile.section_form.avatar.crop.select')}</span>
                    }
                </div>
                {(showCancel || imageCropped) &&
                    <div className="text-center">
                        <span className="link-style" onClick={this.cancel}>Cancel</span>
                    </div>
                }

                {error &&
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                }

                <Popup title={t('pages.user_profile.section_form.avatar.crop.title')} visible={cropImagePopupVisible} onClose={() => this.setState({ cropImagePopupVisible: false })}>
                    <ImageCrop
                        file={file}
                        image={imageUrl}
                        onCrop={this.onCrop}
                        onCancel={() => this.setState({ cropImagePopupVisible: false })}
                    />
                </Popup>
            </div>
        );
    }
}

export default translate('translations')(ImageUpload);
