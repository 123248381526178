import { ANALYTICS_FETCH, analyticsSet } from '../../actions/analytics.actions'
import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions'
import { setLoader } from '../../actions/loader.actions'
import { LOADER_ANALYTICS } from '../../consts/loader.constants'

export const ANALYTICS_URL = 'garage/analytics'

export const analyticsMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case ANALYTICS_FETCH:
                dispatch(setLoader(LOADER_ANALYTICS, false))

                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: { filter: action.payload.filter },
                        url: ANALYTICS_URL,
                        signature: ANALYTICS_FETCH,
                    }),
                )

                break

            case `${ANALYTICS_FETCH} ${API_SUCCESS}`:
                dispatch(analyticsSet(action.payload.data))
                dispatch(setLoader(LOADER_ANALYTICS, true))
                break

            case `${ANALYTICS_FETCH} ${API_ERROR}`:
                dispatch(setLoader(LOADER_ANALYTICS, true))
                break

            default:
                break
        }
    }
}
