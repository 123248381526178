import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { formSubmittingSettings } from 'store/actions/formSubmitting.actions'
import { RECIPIENTS_SETTINGS_UPDATE } from 'store/actions/recipients.settings.actions'
import { recipientsSettingSet } from 'store/actions/recipients.settings.actions'
import fieldExists from '../../../utils/fieldExists'

export const SETTINGS_RECIPIENTS_ADD_URL = 'garage/settings/add-recipient'
export const SETTINGS_RECIPIENTS_UPDATE_URL = 'garage/settings/update-recipient'
export const SETTINGS_RECIPIENTS_REMOVE_URL = 'garage/settings/remove-recipient'

export const recipientsSettingMiddleware = ({ dispatch }) => next => {
  return action => {
    next(action)

    switch (action.type) {
      case RECIPIENTS_SETTINGS_UPDATE:
        dispatch(formSubmittingSettings(true))

        let body = {}
        if (fieldExists(action.payload, 'key')) {
          body = {
            phone: { [action.payload.key]: action.payload.phone },
            email: { [action.payload.key]: action.payload.email },
          }
        } else {
          body = { email: action.payload.email, phone: action.payload.phone }
        }

        dispatch(
          apiRequest({
            body: body,
            method: 'POST',
            url: action.payload.type,
            signature: RECIPIENTS_SETTINGS_UPDATE,
          }),
        )

        break

      case `${RECIPIENTS_SETTINGS_UPDATE} ${API_SUCCESS}`:
        dispatch(formSubmittingSettings(false))
        dispatch(recipientsSettingSet(action.payload.data.recipients))
        break

      case `${RECIPIENTS_SETTINGS_UPDATE} ${API_ERROR}`:
        dispatch(formSubmittingSettings(false))
        break

      default:
        break
    }
  }
}
