import {
    MASTERCHECK_LAYOUT_CHECKBOXES,
    MASTERCHECK_LAYOUT_TOGGLE
} from "../../components/ChecklistLabour/components/ChecklistLayout";

export default [
    {
        "id": MASTERCHECK_LAYOUT_CHECKBOXES,
        "name": "Checkboxes",
    },
    {
        "id": MASTERCHECK_LAYOUT_TOGGLE,
        "name": "Toggle",
    },
]
