import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import axios from 'app/config/axios'
import {parseResponse} from "../../../utils/common";
import Button from "../../../components/shared/Button/Button";

import Loader, {LOADER_BUTTON} from "../../../components/shared/Loader/Loader";
import {withFormik} from "formik";
import {formCustomerUser} from "app/config/yup";
import {toast} from "react-toastify";
import Input from "../../../components/shared/Input/Input";
import Phone from "../../../components/shared/Phone/Phone";
import Toast from "../../../components/shared/Toast/Toast";
import {captureException} from "../../../utils/captureException";

const UPDATE_CUSTOMER = 'garage/customers/update'

class CustomerUser extends Component {

    constructor(props) {
        super(props)

        this.state = {
            updating: false,
        }
    }

    submit = (e) => {
        e.preventDefault()
        const {values, onUpdate} = this.props;

        this.setState({updating: true})

        axios
            .post(UPDATE_CUSTOMER, {
                user_id: values.id,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone: values.phone,
                home_phone: values.home_phone,
                licence_plates: values.licence_plates,
            })
            .then((response) => {
                toast.success(<Toast text={parseResponse(response)} type="success"/>)
                onUpdate(response.data.customer)
            })
            .catch(error => {
                captureException(error, UPDATE_CUSTOMER);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({updating: false}))
    }

    handleFieldChange = (name, value) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, value);
    }

    render() {
        const {t, values, touched, errors, handleBlur, handleChange, setFieldValue} = this.props;
        const {updating} = this.state;

        return (
            <React.Fragment>

                <form onSubmit={this.submit}>

                    <input name="user_id " type="hidden" value={values.id}/>

                    <Input
                        type="text"
                        placeholder={t('pages.customers.first_name')}
                        name="first_name"
                        label={t('pages.customers.first_name')}
                        value={values.first_name}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.first_name && errors.first_name}
                    />

                    <Input
                        type="text"
                        placeholder={t('pages.customers.last_name')}
                        name="last_name"
                        label={t('pages.customers.last_name')}
                        value={values.last_name}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.last_name && errors.last_name}
                    />

                    <Input
                        type="email"
                        placeholder={t('pages.customers.email')}
                        name="email"
                        label={t('pages.customers.email')}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.email && errors.email}
                    />

                    <Phone
                        name={'phone'}
                        onChange={(e) => this.handleFieldChange('phone', e)}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={touched.phone && errors.phone}
                    />

                    <Input
                        type="tel"
                        placeholder={t('pages.customers.home_phone')}
                        name="home_phone"
                        label={t('pages.customers.home_phone')}
                        value={values.home_phone}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e)}
                        error={touched.home_phone && errors.home_phone}
                    />

                    {values.licence_plates.map((licence_plate, key) => {
                        return (
                            <Input
                                type="text"
                                placeholder={t('pages.customers.licence_plates')}
                                name="licence_plates"
                                label={t('pages.customers.licence_plates')}
                                value={licence_plate}
                                key={key}
                                onChange={e => {
                                    setFieldValue('licence_plates', values.licence_plates.map((lp, lpKey) => {
                                        return key === lpKey ? e.target.value : lp;
                                    }))
                                }}
                            />
                        )
                    })}

                    <Button size="md" type="primary" extraClass={"btn-block"} disabled={updating}>
                        <Loader isLoading={updating} type={LOADER_BUTTON}/>
                        <div className="font-size-2">{t('global.update')}</div>
                    </Button>

                </form>

            </React.Fragment>
        );
    }
}

const RemindUserForm = withFormik(formCustomerUser)(CustomerUser);

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(translate('translations')(RemindUserForm));
