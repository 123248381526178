import languages from '../models/languages';
import {LANGUAGE_SETTING_SET} from "store/actions/language.settings.actions";

export default function(state = languages, action) {
    switch (action.type) {

        case LANGUAGE_SETTING_SET:
            return action.payload.languages;

        default:
            return state;
    }
}
