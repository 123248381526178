import { REQUESTS_SET } from '../actions/requests.actions'
import requests from '../models/requests'

export default function(state = requests, action) {
    switch (action.type) {
        case REQUESTS_SET:
            return action.payload.requests

        default:
            return state
    }
}
