import React, {Component} from 'react';
import './Header.scss';
import {translate} from "react-i18next";
import history from "routes/history";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import pages from "app/consts/routes";
import {intersect} from "utils/common";
import {userLogout} from "store/actions/auth.actions";
import logo from "../../../assets/logo-blue.png";
import {
    canSeeAnalyticsEnhanced,
    USER_ROLE_MANAGER,
    USER_ROLE_MECHANIC_ADVANCED,
    USER_ROLE_SUPERVISOR
} from "../../../store/consts/user.constants";
import HeaderNotifications from "../../shared/HeaderNotifications/HeaderNotifications";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            garage: props.garage,
            title: props.title,
            showUserDetail: false,
            showNotifications: false,
            showBackArrow: (props.showBackArrow === false ? props.showBackArrow : true),
            showTermsAndConditions: false,
            showPrivacyPolicy: false,
        };

        this.navigationFloat = React.createRef();
        this.navigationNotifyFloat = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop]
                })
            }
        });
    }

    renderTitle = () => {
        const {garage, showBackArrow, title} = this.state;

        if (!garage.logged) {
            return (
                <div className="col-xs-12 text-center navigation-header__title">
                    {title}
                </div>
            );
        }

        if (garage.logged && showBackArrow) {
            return (
                <div className="col-xs-6 text-center navigation-header__title">
                    <span>{title}</span>
                </div>
            );
        }

        return (
            <div className="col-xs-9 pl-15 navigation-header__title">
                <div className="logo"><img src={logo} alt="Logo"/></div>
            </div>
        );
    };

    toggleUserDetails = () => {
        this.setState({
            showUserDetail: !this.state.showUserDetail
        });
    };

    toggleNotifications = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        });
    };

    closeUserDetails = (e) => {
        if (e.target === this.navigationFloat) {
            this.setState({
                showUserDetail: false
            });
        }
    };

    closeNotifications = (e) => {
        if (e.target === this.navigationNotifyFloat) {
            this.setState({
                showNotifications: false
            });
        }
    };

    toggleTermsAndConditions = () => {
        const {showTermsAndConditions} = this.state;

        this.setState({
            showTermsAndConditions: !showTermsAndConditions
        });
    };

    togglePrivacyPolicy = () => {
        const {showPrivacyPolicy} = this.state;

        this.setState({
            showPrivacyPolicy: !showPrivacyPolicy
        });
    };

    onBack = () => {
        const {onBack} = this.props;

        if (onBack) {
            onBack();
        } else {
            if (!history.location.state) {
                history.push(pages.home)
            } else {
                history.goBack();
            }
        }
    };

    getNumberOfNotifications = (unseen, appVersion) => {

        if (!Boolean(unseen) && !Boolean(appVersion) && !Boolean(appVersion.length)) return 0

        let counter = 0

        counter = unseen

        if (Boolean(appVersion)) {
            if (appVersion.localVersion !== appVersion.responseVersion) {
                counter += 1
            }
        }

        return counter
    }

    render() {
        const {user, garage, showUserDetail, showNotifications, showBackArrow, showTermsAndConditions, showPrivacyPolicy} = this.state;
        const {t, dispatch, showRightControls, notifications, appVersion} = this.props;

        return (
            <React.Fragment>
                <header>
                    <div className="navigation">
                        <div className="container">
                            <div className="navigation-header row">

                                {(garage.logged && showBackArrow) &&
                                <span className="col-xs-3 text-left navigation-header__icon" onClick={this.onBack}>
                                        <span className="icon-back pull-l"/>
                                    </span>
                                }

                                {this.renderTitle()}

                                {(garage.logged === true && showRightControls !== false) &&
                                <div
                                    className="col-xs-3 text-right navigation-header__icon navigation-header__icon--settings">
                                    <span id="settings-navigation"
                                          className={`icon-settings ${showUserDetail ? 'active' : ''}`}
                                          onClick={this.toggleUserDetails}/>
                                    <div onClick={this.toggleNotifications} className={`notification-frame ${appVersion && (appVersion.localVersion !== appVersion.responseVersion) ? 'app-update' : ''} ${(notifications.data.unseen || appVersion.localVersion !== appVersion.responseVersion) ? 'unseen animated bounce' : ''} ${showNotifications ? 'active' : ''}`}>
                                        <span className="icon-bell"></span> <span className="notification-frame-text">{t('header.notifications')}</span>

                                        <i className="number"> {this.getNumberOfNotifications(notifications.data.unseen, appVersion)}</i>

                                    </div>

                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </header>

                {showUserDetail &&
                <div className="navigation-float" onClick={this.closeUserDetails}
                     ref={ref => this.navigationFloat = ref}>
                    <div className="container relative">
                        <div className="navigation-container">
                            <div className="navigation-container__content">

                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="navigation-container__content__item pick-garage-name">
                                            {garage.name}
                                        </div>

                                        {user.garages.length > 1 &&
                                        <Link to={pages.pickGarage}
                                              className="navigation-container__content__item pick-garage-link">{t('header.switch_garage')}</Link>
                                        }

                                        <Link id="profile-navigation" className="navigation-container__content__item"
                                              to={pages.user_profile}><i
                                            className="icon-profile"/>{t('header.profile_settings')}</Link>

                                        {(user.type === USER_ROLE_MANAGER || user.type === USER_ROLE_SUPERVISOR) &&
                                        <Link id="price-navigation" className="navigation-container__content__item"
                                              to={pages.price_update}><i
                                            className="icon-dollar"/>{t('header.price_update')}</Link>
                                        }

                                        {(user.type === USER_ROLE_MANAGER || user.type === USER_ROLE_SUPERVISOR) &&
                                        <Link id="mechanic_navigation" className="navigation-container__content__item"
                                              to={pages.mechanics}><i
                                            className="icon-wrench"/>{t('header.mechanic')}</Link>
                                        }


                                        {((user.type === USER_ROLE_MANAGER || user.type === USER_ROLE_MECHANIC_ADVANCED) && garage.allow_checklist && garage.allow_checklist_update) &&
                                        <Link className="navigation-container__content__item" to={pages.checklists}><i
                                            className="icon-list"/>{t('header.checklists')}</Link>
                                        }

                                        {((user.type === USER_ROLE_MANAGER || user.type === USER_ROLE_SUPERVISOR || user.type === USER_ROLE_MECHANIC_ADVANCED) && garage.allow_reporting) &&
                                        <Link className="navigation-container__content__item" to={pages.reporting}><i
                                            className="icon-file"/>{t('header.reporting')}</Link>
                                        }

                                        <Link className="navigation-container__content__item" to={pages.customers}><i
                                            className="icon-profile"/>{t('header.customers')}</Link>

                                        {user.type === USER_ROLE_SUPERVISOR && garage.allow_tires_create &&
                                        <Link className="navigation-container__content__item" to={pages.tires_import}><i
                                            className="icon-export"/>{t('header.tires_upload')}</Link>
                                        }

                                        <Link id="settings_link_navigation"
                                              className="navigation-container__content__item" to={pages.settings}><i
                                            className="icon-settings"/>{t('header.settings')}</Link>

                                        {canSeeAnalyticsEnhanced(user.type) &&
                                            <Link id="settings_link_navigation"
                                                  className="navigation-container__content__item" to={pages.analytics_enhanced}><i
                                                className="icon-pie"/>{t('header.analytics_enhanced')}</Link>
                                        }

                                        {garage.terms_and_conditions.accepted === true &&
                                        <span id="terms_and_conditions"
                                              className="link-style navigation-container__content__item"
                                              onClick={this.toggleTermsAndConditions}><i
                                            className="icon-file-text2"/>{t('header.terms_and_conditions')}</span>
                                        }

                                        <span id="terms_and_conditions"
                                              className="link-style navigation-container__content__item"
                                              onClick={this.togglePrivacyPolicy}><i
                                            className="icon-document-locked"/>{t('header.privacy_policy')}</span>

                                        <div className="navigation-container__content__item link-style">
                                            <i className="icon-logout"/>
                                            <span id="logout-navigation"
                                                  onClick={() => dispatch(userLogout())}>{t('header.logout')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {showNotifications &&
                <div className="navigation-float" onClick={this.closeNotifications}
                     ref={ref => this.navigationNotifyFloat = ref}>
                    <div className="container relative">
                        <HeaderNotifications
                            onClose={() => this.setState({showNotifications: false})}
                        />
                    </div>
                </div>
                }

                {showTermsAndConditions &&
                <TermsAndConditions
                    onClose={this.toggleTermsAndConditions}
                />
                }

                {showPrivacyPolicy &&
                <PrivacyPolicy
                    onClose={this.togglePrivacyPolicy}
                />
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
        notifications: state.notifications,
        appVersion: state.appVersion,
    };
};

export default connect(mapStateToProps)(translate('translations')(Header));
