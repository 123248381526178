import Yup from './yup'
import { labourPricesOilUpdate } from '../../../store/actions/labourPrices.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues({ oils }) {
        let list = {}

        oils.prices.forEach(l => {
            list[l.input_name] = l.price
        })

        return list
    },

    validationSchema: ({ t, oils }) => {
        let prices = {}
        oils.prices.forEach(l => {
            prices[l.input_name] = Yup.number()
                .nullable()
                .label(t('pages.price_update.oil_price.title'))
        })

        return Yup.object().shape(prices)
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(labourPricesOilUpdate(values))
    },
}
