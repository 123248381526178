import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import {
    labourPricesDiscountFetch,
} from "store/actions/labourPrices.actions";
import {LOADER_LABOUR_PRICES} from "store/consts/loader.constants";
import ListLoader from "components/shared/ListLoader/ListLoader";
import DiscountAppointmentForm from "./DiscountForms/DiscountAppointmentForm";
import {
    DISCOUNT_APPOINTMENT_TYPE,
} from "../../store/consts/discounts.constants";
import PartsDiscount from "./Discounts/PartsDiscount";
import BrakesDiscount from "./Discounts/BrakesDiscount";

const CAR_TYPE = 0;
const DISCOUNT_ORDER = 0;

class DiscountsUpdateContainer extends Component {

    componentDidMount() {
        this.props.dispatch(labourPricesDiscountFetch());
    }

    render() {
        const {t, loader, discounts} = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)}/>

                <div className="container">

                    {loader[LOADER_LABOUR_PRICES] ?
                        <ListLoader/> :
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <PartsDiscount title={'parts_title'}/>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <BrakesDiscount title={'brakes_title'}/>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <DiscountAppointmentForm
                                    discount={discounts?.discounts && discounts?.discounts[DISCOUNT_APPOINTMENT_TYPE] ? discounts?.discounts[DISCOUNT_APPOINTMENT_TYPE][CAR_TYPE][DISCOUNT_ORDER] : null}/>
                            </div>
                        </div>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        discounts: state.labourPrices.discounts,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(DiscountsUpdateContainer));
