import formSubmitting from 'store/models/formSubmitting'
import {
    FORM_SUBMITTING_BOOKING,
    FORM_SUBMITTING_VEHICLE,
    FORM_SUBMITTING_LOGIN, FORM_SUBMITTING_PACKAGE_TIRES_UPDATE,
} from 'store/actions/formSubmitting.actions'
import {
    FORM_SUBMITTING_DISCOUNT_APPOINTMENT_PRICE,
    FORM_SUBMITTING_DISCOUNT_PARTS_PRICE,
    FORM_SUBMITTING_DISCOUNT_BRAKES_PRICE,
    FORM_SUBMITTING_DISCOUNT_PRICE,
    FORM_SUBMITTING_HOURLY_PRICE,
    FORM_SUBMITTING_LIQUIDS_PRICE,
    FORM_SUBMITTING_MECHANICS,
    FORM_SUBMITTING_OIL_PRICE,
    FORM_SUBMITTING_PACKAGES_ALL_REMOVE,
    FORM_SUBMITTING_PACKAGES_UPDATE,
    FORM_SUBMITTING_SETTINGS,
} from '../actions/formSubmitting.actions'

export default function(state = formSubmitting, action) {
    switch (action.type) {
        case FORM_SUBMITTING_VEHICLE:
            return {
                ...state,
                vehicle: action.payload.submitted,
            }

        case FORM_SUBMITTING_BOOKING:
            return {
                ...state,
                booking: action.payload.submitted,
            }

        case FORM_SUBMITTING_LOGIN:
            return {
                ...state,
                login: action.payload.submitted,
            }

        case FORM_SUBMITTING_HOURLY_PRICE:
            return {
                ...state,
                hourlyPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_OIL_PRICE:
            return {
                ...state,
                oilPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_LIQUIDS_PRICE:
            return {
                ...state,
                liquidsPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_DISCOUNT_PRICE:
            return {
                ...state,
                discountPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_DISCOUNT_PARTS_PRICE:
            return {
                ...state,
                discountPartsPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_DISCOUNT_BRAKES_PRICE:
            return {
                ...state,
                discountBrakesPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_DISCOUNT_APPOINTMENT_PRICE:
            return {
                ...state,
                discountAppointmentsPrice: action.payload.submitted,
            }

        case FORM_SUBMITTING_SETTINGS:
            return {
                ...state,
                settings: action.payload.submitted,
            }

        case FORM_SUBMITTING_MECHANICS:
            return {
                ...state,
                mechanics: action.payload.submitted,
            }

        case FORM_SUBMITTING_PACKAGES_UPDATE:
            return {
                ...state,
                packages: action.payload.submitted,
            }

        case FORM_SUBMITTING_PACKAGES_ALL_REMOVE:
            return {
                ...state,
                packagesAllRemove: action.payload.submitted,
            }

        case FORM_SUBMITTING_PACKAGE_TIRES_UPDATE:
            return {
                ...state,
                packageTire: action.payload.submitted,
            }

        default:
            return state
    }
}
