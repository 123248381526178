import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import Input from "components/shared/Input/Input";
import Button from "components/shared/Button/Button";
import {parseResponse} from "utils/common";
import ListLoader from "components/shared/ListLoader/ListLoader";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import store from "../../store/store";
import history from "../../routes/history";
import pages from "../../app/consts/routes";

const GET_TIRE_WORK_PRICES_API = 'garage/price-update/get-tire'
const UPDATE_TIRE_WORK_PRICES_API = 'garage/price-update/update-tire'

const PRICE_LABELS = [
    'run_flat',
    'tire_disposal_pkw',
    'tire_disposal_4x4_llkw',
    'rim_size_lt_16',
    'rim_size_17_18',
    'rim_size_gt_19',
]

class TireWorkPriceUpdateContainer extends Component {
    constructor(props) {
        super(props);

        const garage = store.getState().garage

        if (!garage.is_euromaster) {
            this.shouldRender = false
            history.replace(pages.price_update)
        }

        this.state = {
            prices: null,
            loading: true,
        };
    }

    componentDidMount() {
        this.getTireWorkPrices()
    }

    getTireWorkPrices = () => {
        axios
            .get(GET_TIRE_WORK_PRICES_API)
            .then(response => {
                this.setState({prices: response.data.prices, loading: false})
            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({loading: false})
            })
    }

    updateTireWorkPrices = (t) => {
        this.setState({loading: true})
        axios
            .post(UPDATE_TIRE_WORK_PRICES_API, {prices: this.state.prices})
            .then(response => {
                this.setState({prices: response.data.prices, loading: false})
            })
            .then(toast.success(<Toast text={t('pages.tire_prices.save_success')} type="success"/>))
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({loading: false})
            })
    }

    handleInputChange = (e) => {
        const updatedState = { ...this.state.prices, [e.target.name]: e.target.value }
        this.setState({ prices: updatedState })
    }

    render() {

        const { t, handleBlur } = this.props;
        const { prices, loading } = this.state

        return (
            <React.Fragment>

                <Header title={t(`pages.tire_prices.title`)} />

                <div className="container container--has-submit-container">
                    {loading ?
                        <ListLoader/> :
                        <div>
                            <div className="row">

                                {PRICE_LABELS.map((label, key) => {

                                    return (
                                        <div className="col-xs-12 col-sm-6">
                                            <Input
                                                type="tel"
                                                placeholder={t(`pages.tire_prices.${label}`)}
                                                name={label}
                                                label={t(`pages.tire_prices.${label}`)}
                                                onBlur={handleBlur}
                                                onChange={this.handleInputChange}
                                                value={prices[label]}
                                                key={key}
                                                extraClass={'oil-input'}
                                                step={0.000001}
                                                float={true}
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button size="lg" type="primary" disabled={loading} onClick={() => this.updateTireWorkPrices(t)}>
                                        {t('pages.price_update.section_form.button_save')}
                                    </Button>
                                </div>
                            </SubmitContainer>
                        </div>
                    }
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader
    };
};

export default connect(mapStateToProps)(translate('translations')(TireWorkPriceUpdateContainer));
