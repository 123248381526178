export const ANALYTICS = '[ANALYTICS]'

export const ANALYTICS_FETCH = `${ANALYTICS} FETCH`
export const ANALYTICS_SET = `${ANALYTICS} SET`
export const ANALYTICS_RESET = `${ANALYTICS} RESET`

export const analyticsFetch = (filter = null) => ({
    type: ANALYTICS_FETCH,
    payload: {
        filter,
    },
})

export const analyticsSet = analytics => ({
    type: ANALYTICS_SET,
    payload: {
        analytics,
    },
})

export const analyticsReset = () => ({
    type: ANALYTICS_RESET,
})
