import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import InputRadio from "components/shared/InputRadio";
import Popup from "components/shared/Popup/Popup";
import WorkEdit from "./WorkEdit";
import {compare} from "utils/common";
import {__clone} from "utils/common";
import Button from "components/shared/Button/Button";
import {packagesUpdate} from "store/actions/packages.actions";
import {updateObject} from "utils/common";
import {deepObject} from "utils/common";
import {usePrevious} from "components/hooks/utils";
import WorkAdd from "./WorkAdd";
import {WORK_TYPE_CHECKBOX, WORK_TYPE_RADIO} from "../../../store/consts/package/work";
import InputCheckbox from "../../../components/shared/InputCheckbox";
import {parseDeepPath} from "../../../utils/common";
import {
    TYPE_TO_EDIT_WORKS,
    TYPE_TO_SELECT_WORKS,
    TYPE_TO_ADD_WORKS,
    TYPE_TO_UPDATE_PACKAGE
} from "../../../store/consts/package/package.type.constants";

export const LabourWorks = props => {

    const { t, packagePath, formSubmitting, packages, onClose, dispatch } = props;

    const [ works, setWorks ] = useState(props.works);
    const [ workShow, setWorkShow ] = useState(null);
    const [ workAddShow, setWorkAddShow ] = useState(false);
    const [ _package ] = useState(packages.find(pack => pack.id === parseInt(parseDeepPath(packagePath)[0].id)));

    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if(compare(works, props.works) && works[workShow]){
            setWorks(updateObject(works, {...props.works[workShow], selected: works[workShow].selected}, `id:${works[workShow].id}`));
        }

        if(compare(works, props.works) && workAddShow){
            setWorks(props.works);
        }
    }, [props.works]);

    useEffect(() => {
        if(prevPackages && !works[workShow] && !workAddShow){
            onClose();
        }
    }, [props.packages]);

    let renderWorkShow = () => {

        if(!works[workShow]){
            return null;
        }

        let work = deepObject(packages, `${packagePath}/works/id:${works[workShow].id}`);

        return (
            <Popup title={t('pages.package_details.labour.works.edit.title')} visible={!!work}
                   contentScrollable={true} onClose={() => setWorkShow(null)}>
                <WorkEdit
                    work={work}
                    packagePath={`${packagePath}/works/id:${work.id}`}
                    onClose={() => setWorkShow(null)}
                />
            </Popup>
        );
    };

    let renderWorkAddShow = () => {

        if(!workAddShow){
            return null;
        }

        return (
            <Popup title={t('pages.package_details.labour.works.add.title')} visible={!!workAddShow}
                   contentScrollable={true} onClose={() => setWorkAddShow(false)}>
                <WorkAdd
                    packagePath={packagePath}
                    onClose={() => setWorkAddShow(false)}
                />
            </Popup>
        );
    };

    let handleChange = (e) => {
        const {value, checked} = e.target;
        let worksList = __clone(works);

        if(worksList.find(work => work.id == value).input_type === WORK_TYPE_RADIO){
            worksList.map(work => work.input_type === WORK_TYPE_RADIO ? work.selected = false : work);

            worksList.map(work => work.id == value ? work.selected = true : work)
        }
        else{
            worksList.map(work => work.id == value ? work.selected = checked : work)
        }

        setWorks(worksList);
    };

    let submit = (e) => {
        e.preventDefault();

        dispatch(packagesUpdate(updateObject(packages, works, `${packagePath}/works`), parseDeepPath(packagePath)[0].id, true));
    };

    let workTypeRadio = (work) => (
        <InputRadio
            id={work.id}
            name={"work"}
            value={work.id}
            label={
                <span>
                    <span>{work.name}</span>
                    <span className="vertical-text-delimiter">|</span>
                    <span className="bold">{work.duration}h</span>
                </span>
            }
            checked={work.selected}
            onChange={handleChange}
            disabled={TYPE_TO_SELECT_WORKS.indexOf(_package.type) === -1}
        />
    );

    let workTypeCheckbox = (work) => (
        <InputCheckbox
            id={work.id}
            name={`work`}
            value={work.id}
            onChange={handleChange}
            checked={work.selected}
            label={
                <span>
                    <span>{work.name}</span>
                    <span className="vertical-text-delimiter">|</span>
                    <span className="bold">{work.duration}h</span>
                </span>
            }
            disabled={TYPE_TO_SELECT_WORKS.indexOf(_package.type) === -1}
        />
    );

    return (
        <React.Fragment>

            <form onSubmit={submit}>
                <div className="extend-package-list">

                    {works.map((work, key) => (
                      <div key={work.id} className="extend-package-list__item">
                          {work.input_type === WORK_TYPE_RADIO ? workTypeRadio(work) : workTypeCheckbox(work)}

                          {(TYPE_TO_EDIT_WORKS.indexOf(_package.type) !== -1 && (work.input_type === WORK_TYPE_CHECKBOX || work.autogenerated === true)) &&
                            <span className="link-style edit-link" onClick={() => setWorkShow(key)}><i className="icon-edit"/></span>
                          }
                      </div>
                    ))}

                </div>

                {TYPE_TO_ADD_WORKS.indexOf(_package.type) !== -1 &&
                    <div className="mt-10">
                        <div className="btn btn-sm btn-secondary" onClick={() => setWorkAddShow(true)}>
                            {t('pages.package_details.labour.works.add_more')}
                        </div>
                    </div>
                }

                <div className="mt-30">
                    {TYPE_TO_UPDATE_PACKAGE.indexOf(_package.type) !== -1 &&
                        <Button size="lg" type="primary" disabled={formSubmitting.packages}>{t('global.save')}</Button>
                    }
                </div>
            </form>

            {renderWorkShow()}
            {renderWorkAddShow()}

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
        packages: state.packages,
    };
};

export default connect(mapStateToProps)(translate('translations')(LabourWorks));
