import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import InputCheckbox from "components/shared/InputCheckbox";
import {packagesUpdate} from "store/actions/packages.actions";
import Button from "components/shared/Button/Button";
import InputRadio from "components/shared/InputRadio";
import {__clone} from "utils/common";
import {compare, updateObject} from "utils/common";
import {usePrevious} from "components/hooks/utils";
import {deepObject} from "utils/common";
import Popup from "components/shared/Popup/Popup";
import PartSetEdit from "./PartSetEdit";
import {fixedFloat, parseDeepPath} from "../../../utils/common";
import {missingInfo} from "../../../store/selectors/missingInfo";

export const PartsVersion = props => {

    const { t, formSubmitting, packages, packagePath, dispatch, onClose } = props;

    const [ part, setPart ] = useState(props.part);
    const [ partSetShow, setPartSetShow ] = useState(null);

    const missingData = missingInfo(deepObject(packages, `id:${parseDeepPath(packagePath)[0].id}`))

    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if(compare(part, props.part) && partSetShow){

            setPart(updateObject(part, {...deepObject(props.part, partSetShow), selected: deepObject(part, partSetShow).selected}, partSetShow));

        }
    }, [props.part]);

    useEffect(() => {
        if(prevPackages && !partSetShow){
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        dispatch(packagesUpdate(updateObject(packages, part, packagePath), parseDeepPath(packagePath)[0].id));
    };

    let handleVersionChange = (e) => {
        const {value} = e.target;

        let partClone = __clone(part);

        partClone = unselectAllPartVersions(partClone);

        partClone.versions.map(version => {
            if(version.id == value){
                version.selected = true;
                version.set.map(set => set.selected = true);
            }

            return version;
        });

        setPart(partClone);
    };

    let handleSetChange = (e, version) => {
        const {value, checked} = e.target;

        let partClone = __clone(part);

        if(version.selected === false){
            partClone = unselectAllPartVersions(partClone);
        }

        partClone = checkPartSet(partClone, version, value, checked);

        setPart(partClone);
    };

    let checkPartSet = (part, partVersion, partSetId, checked) => {
        part.versions.map(version => {
            if(version.id === partVersion.id){
                version.set.map(set => {
                    if(set.id == partSetId){
                        set.selected = checked;
                    }

                    return set;
                });

                version.selected = true;
            }

            return version;
        });

        return part;
    };

    let unselectAllPartVersions = (part) => {
        part.versions.map(version => {
            version.set.map(set => set.selected = false);
            version.selected = false;

            return version;
        });

        return part;
    };

    let renderPartSet = () => {

        if(!partSetShow){
            return null;
        }

        let partSet = deepObject(packages, `${packagePath}/${partSetShow}`);

        return (
            <Popup title={t('pages.package_details.labour.parts.set.title')} visible={!!partSet} contentScrollable={true} onClose={() => setPartSetShow(null)}>
                <PartSetEdit
                    partSet={partSet}
                    packagePath={`${packagePath}/${partSetShow}`}
                    onClose={() => setPartSetShow(null)}
                />
            </Popup>
        );
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>

                <div className="extend-package-list">

                    {part.versions.map(version => (
                        <div key={version.id} className="extend-package-list__item mb-20">
                            <InputRadio
                                id={version.id}
                                name={"part_version"}
                                value={version.id}
                                label={version.name}
                                checked={version.selected}
                                onChange={handleVersionChange}
                            />
                            <span/>
                            <div className="extend-package-list">
                                {version.set.map(partSet => (
                                    <div key={partSet.id} className="extend-package-list__item">
                                        <InputCheckbox
                                            id={`part-set-${partSet.id}`}
                                            name={`part-set-${partSet.id}`}
                                            value={partSet.id}
                                            label={
                                                <span>
                                                    <span>{partSet.name}</span>
                                                    <span className="vertical-text-delimiter">|</span>
                                                    <span>{partSet.partNo}</span>
                                                    <p className={`bold ${missingData.parts.find(data => data.set === partSet.id) ? 'brand-warning' : ''}`}>
                                                        {partSet.amounts}x{fixedFloat(partSet.price)} {partSet.currency} {missingData.parts.find(data => data.set === partSet.id) && <i className="icon-warrning-fill"/>}
                                                    </p>
                                                </span>
                                            }
                                            checked={partSet.selected}
                                            onChange={(e) => handleSetChange(e, version)}
                                        />
                                        <span className="link-style edit-link" onClick={() => setPartSetShow(`versions/id:${version.id}/set/id:${partSet.id}`)}><i className="icon-edit"/></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <Button size="lg" type="primary" disabled={formSubmitting.packages}>{t('global.save')}</Button>

            </form>

            {renderPartSet()}

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        packages: state.packages,
        formSubmitting: state.formSubmitting
    };
};

export default connect(mapStateToProps)(translate('translations')(PartsVersion));
