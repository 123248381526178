export const RECIPIENTS_SETTINGS = '[RECIPIENTS SETTINGS]';

export const RECIPIENTS_SETTINGS_SET = `${RECIPIENTS_SETTINGS} SET`;
export const RECIPIENTS_SETTINGS_UPDATE = `${RECIPIENTS_SETTINGS} UPDATE`;

export const recipientsSettingSet = (recipients) => ({
    type: RECIPIENTS_SETTINGS_SET,
    payload: {
        recipients
    }
});

export const recipientsSettingsUpdate = ({email, phone, type, key}) => ({
    type: RECIPIENTS_SETTINGS_UPDATE,
    payload: {
        email, phone, type, key
    }
});