import React from 'react';
import {translate} from 'react-i18next';
import {fixedFloat} from "../../utils/common";
import {sumAvailableWorks, sumFixedPrice, sumPartsPrice} from "../../store/selectors/packagePrices";
import {availableParts, availableWorks} from "../../store/selectors/package";
import BaseCountry from "../../utils/BaseCountry";
import {missingInfo} from "../../store/selectors/missingInfo";
import {TYPE_TIRES} from "../../store/consts/package/package.type.constants";
import {
    TIRES_AXEL_FRONT, TIRES_AXELS, TIRES_TYPES
} from "../../store/consts/tires.constants";

class AvailableData extends React.Component {

    render() {
        const {t, _package, openAvailableWorks, openAvailableParts, openCustomLabours} = this.props;
        const missingData = missingInfo(_package)

        const LaboursData = () => {
            return (
                <>
                    {sumFixedPrice(_package.labours) === 0 && (missingData.works.length || missingData.parts.length) ? (
                        <div className="missing-info mb-20">{t('pages.package_details.missing_parts')}</div>
                    ) : null}

                    <div className="extend-package-list">
                        <div className="extend-package-list__item no-border">
                            <div>
                                <span>{t('pages.package_details.available_works')}</span>
                                <span className="vertical-text-delimiter">|</span>
                                <span className={`bold ${missingData.works.length ? 'brand-warning' : ''}`}>
                                    {fixedFloat(sumAvailableWorks(availableWorks(_package)))} {BaseCountry().currency}
                                    {missingData.works.length ? <i className="icon-warning"/> : null}
                                </span>
                            </div>
                            {_package.labours.length > 0 &&
                            <div className="link-style edit-link" onClick={openAvailableWorks}>
                                <i className="icon-eye"/> {t('pages.package_details.details')}
                            </div>
                            }
                        </div>
                        <div className="extend-package-list__item no-border">
                            <div>
                                <span>{t('pages.package_details.available_parts')}</span>
                                <span className="vertical-text-delimiter">|</span>
                                <span className={`bold ${missingData.parts.length && sumFixedPrice(_package.labours) === 0 ? 'brand-warning' : ''}`}>
                                    {fixedFloat(sumPartsPrice(availableParts(_package)))} {BaseCountry().currency}
                                    {(missingData.parts.length > 0 && sumFixedPrice(_package.labours) === 0) && <i className="icon-warrning-fill"/>}
                                </span>
                            </div>
                            {_package.labours.length > 0 &&
                                <div className="link-style edit-link" onClick={openAvailableParts}>
                                    <i className="icon-eye"/> {t('pages.package_details.details')}
                                </div>
                            }
                        </div>
                        {(sumFixedPrice(_package.labours) !== 0) &&
                            <div className="extend-package-list__item no-border">
                                <div className="extend-package-list__item no-border">
                                    <span> {t('pages.package_details.price_fixed')}</span>
                                    <span className="vertical-text-delimiter pull-l">|</span>
                                    <span className={`bold`}>{fixedFloat(sumFixedPrice(_package.labours))} {BaseCountry().currency}</span>
                                </div>
                                {_package.labours.find(l => (l.priceFixed !== 0)) &&
                                    <div className="link-style edit-link" onClick={openCustomLabours}>
                                        <i className="icon-eye"/> {t('pages.package_details.details')}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </>
            )
        }

        const TiresData = () => {
            return (
                <>
                    <div className="extend-package-list">

                        {_package.tires.list.map(tireType => (
                            <div className="extend-package-list__item no-border">
                                <div>
                                    <div>
                                        <span>{tireType.full_name}</span>
                                        <span className="vertical-text-delimiter">|</span>
                                        <span className="bold">{fixedFloat(tireType.price_tires_with_tax)} {BaseCountry().currency}</span>
                                    </div>

                                    {TIRES_AXELS.filter(axel => tireType[axel]).map(axel => (
                                        <div className="small text-lighter">{t('tires.axel.' + axel)}: {tireType[axel].full_axel}</div>
                                    ))}
                                </div>
                            </div>
                        ))}

                        <div className="extend-package-list__item no-border">
                            <div>
                                <div>
                                    <span>{t('pages.request_details.package.tires.mounting_total_price')} x{_package.tires.tires_to_change}</span>
                                    <span className="vertical-text-delimiter">|</span>
                                    <span className="bold">{fixedFloat(_package.tires.price_work_with_tax)} {BaseCountry().currency}</span>
                                </div>
                            </div>
                        </div>

                        {_package.tires.additional.map(additional => (
                            <div className="extend-package-list__item no-border" key={additional.item_mp_id}>
                                <div>
                                    <span>{additional.name} x{_package.tires.tires_to_change}</span>
                                    <span className="vertical-text-delimiter">|</span>
                                    <span className="bold">{fixedFloat(additional.price_with_tax)} {BaseCountry().currency}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )
        }

        return (
            <>
                {_package.type === TYPE_TIRES ? <TiresData/> : <LaboursData/>}
            </>
        );
    }
}

export default translate('translations')(AvailableData);
