import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import ChangePassword from "./ChangePassword";
import ChangeUserDetails from "./ChangeUserDetails/ChangeUserDetails";
import {userUpdateCommunication} from "../../store/actions/user.actions";
import Communication from "./Communication";
import {settingsFetch} from "../../store/actions/settings.actions";
import {USER_ROLE_MECHANIC} from "../../store/consts/user.constants";
import Language from "../SettingsContainer/Language";
import {LOADER_SETTINGS_PAGE} from "../../store/consts/loader.constants";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import ProfileProgressWidget from "../../components/ProfileProgress/ProfileProgressWidget";

class UserProfileContainer extends Component {
    componentDidMount(error, errorInfo) {
        const {dispatch} = this.props;

        dispatch(settingsFetch());
    }

    render() {
        const {t, title, user, dispatch, loader} = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                {!loader[LOADER_SETTINGS_PAGE] ? <ListLoader/> :
                    <div className="container">

                        <ProfileProgressWidget
                            showText={true}
                        />

                        {user.type !== USER_ROLE_MECHANIC &&
                            <Language/>
                        }

                        <ChangeUserDetails/>

                        <Communication
                            user={user}
                            update={(communications) => dispatch(userUpdateCommunication(communications))}
                        />

                        <ChangePassword/>

                    </div>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(UserProfileContainer));
