export const USER = '[User]'

export const USER_FETCH = `${USER} FETCH`
export const USER_SET = `${USER} SET`
export const USER_UPDATE = `${USER} UPDATE`
export const USER_LOGOUT = `${USER} LOGOUT`
export const USER_REMOVE = `${USER} REMOVE`
export const USER_UPDATE_COMMUNICATION = `${USER} UPDATE COMMUNICATION`
export const USER_UPDATE_LOCALE = `${USER} UPDATE LOCALE`
export const USER_SETTINGS_UPDATE = `${USER} UPDATE SETTINGS`
export const USER_CUSTOMER_SETTINGS_UPDATE = `${USER} CUSTOMER SETTINGS UPDATE`
export const USER_TIRES_SETTINGS_UPDATE = `${USER} TIRES SETTINGS UPDATE`

export const userFetch = () => ({
    type: USER_FETCH,
})

export const userSet = data => ({
    type: USER_SET,
    payload: data,
})

export const userUpdateLocale = locale => ({
    type: USER_UPDATE_LOCALE,
    payload: {
        locale
    },
})

export const userUpdate = data => ({
    type: USER_UPDATE,
    payload: {
        data,
    },
})

export const userUpdateCommunication = communications => ({
    type: USER_UPDATE_COMMUNICATION,
    payload: {
        communications,
    },
})

export const userSettingsUpdate = data => ({
    type: USER_SETTINGS_UPDATE,
    payload: {
        data,
    },
})

export const userCustomerSettingsUpdate = data => ({
    type: USER_CUSTOMER_SETTINGS_UPDATE,
    payload: {
        data,
    },
})

export const userTiresSettingsUpdate = data => ({
    type: USER_TIRES_SETTINGS_UPDATE,
    payload: {
        data,
    },
})

export const userRemove = () => ({
    type: USER_REMOVE,
})
