import { createSelector } from 'reselect';
import {
    OFFICIAL_SERVICE_NEXT,
    OFFICIAL_SERVICE_PREV,
    OFFICIAL_SERVICE_SELECTED
} from "../consts/officialServices.constants";
import {toBool} from "../../utils/common";
import {TYPE_OFFICIAL} from "../consts/package/package.type.constants";

const officialServicePackage = state => state.packages.find(p => p.type === TYPE_OFFICIAL);
const officialServices = state => state.officialServices;

export const officialServiceEnrich = createSelector(
    officialServices, officialServicePackage,
    (officialServices, officialServicePackage) => {

        if(officialServicePackage){
            officialServices.recommended.selected = officialServicePackage.officialService.selected;
        }

        officialServices.recommended.next = officialServices.recommended.next.map(labour => {
            return {...labour, checked: officialServices.recommended.selected === OFFICIAL_SERVICE_NEXT}
        });

        officialServices.recommended.prev = officialServices.recommended.prev.map(labour => {
            return {...labour, checked: officialServices.recommended.selected === OFFICIAL_SERVICE_PREV}
        });

        let selectedRecommended = officialServices.recommended[OFFICIAL_SERVICE_SELECTED[officialServices.recommended.selected]]

        if(officialServicePackage){
            officialServices.main = officialServices.main.map(labour => {
                return {...labour,
                    checked: toBool(officialServicePackage.labours.find(l => l.itemMpId === labour.itemMpId)),
                    in_official: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                }
            });

            officialServices.additional = officialServices.additional.map(labour => {
                return {...labour,
                    checked: toBool(officialServicePackage.labours.find(l => l.itemMpId === labour.itemMpId)),
                    in_official: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                }
            });
        }
        else {
            officialServices.main = officialServices.main.map(labour => {
                return {...labour,
                    checked: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                    in_official: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                }
            });

            officialServices.additional = officialServices.additional.map(labour => {
                return {...labour,
                    checked: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                    in_official: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                }
            });
        }

        return officialServices;
    }
);
