import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import {analyticsFetch, analyticsReset} from "../../store/actions/analytics.actions";
import "./HomeContainer.scss";
import {LOADER_ANALYTICS} from "../../store/consts/loader.constants";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import Statistics from "../../components/shared/Statistics/Statistics";
import UserFilter from "./UserFilter";
import {canSeeAnalyticsEnhanced, USER_ROLE_MECHANIC} from "../../store/consts/user.constants";
import PickGarage from "./PickGarage";
import Link from "react-router-dom/es/Link";
import pages from "../../app/consts/routes";

class HomeContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: {}
        }
    }

    updateFilter = (filter) => {
        const {dispatch} = this.props;

        this.setState({
            filter: {
                ...this.state.filter,
                [filter.type]: filter,
            }
        }, () => {
            dispatch(analyticsFetch(this.state.filter));
        })
    }

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch(analyticsFetch());
    }

    resetFilter = () => {
        const { dispatch } = this.props;

        this.setState({
            filter: {}
        }, () => dispatch(analyticsFetch(this.state.filter)))
    }

    render() {
        const {t, analytics, loader, user, garage, dispatch} = this.props;
        const {filter} = this.state;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} showBackArrow={false}/>

                <div className="container">

                    {user.garages.length > 1 &&
                        <PickGarage
                            onSuccess={() => this.resetFilter()}
                            filter={(filter) => {
                                dispatch(analyticsReset())
                                this.updateFilter(filter)
                            }}
                        />
                    }

                    {user.type !== USER_ROLE_MECHANIC &&
                        <UserFilter
                            users={analytics.users}
                            user={user}
                            garage={garage}
                            filter={(filter) => this.updateFilter(filter)}
                        />
                    }

                    {!loader[LOADER_ANALYTICS] ? <ListLoader/> :
                        <div>
                            <div className="analytic-tab-title">{t('pages.analytics.titles.statistics')}</div>
                            <Statistics
                                analytics={analytics.requests}
                                groupDays={analytics.groupDays}
                                filter={filter}
                                updateFilter={(filter) => this.updateFilter(filter)}
                                dateFrom={filter?.days?.dateFrom}
                                dateTo={filter?.days?.dateTo}
                            />

                            {canSeeAnalyticsEnhanced(user.type) &&
                            <div className="text-center font-weight-semi-bold mt-20 mb-50">
                                <Link to={pages.analytics_enhanced}>{t('pages.analytics.more_statistics')}</Link>
                            </div>
                            }
                        </div>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
        loader: state.loader,
        analytics: state.analytics
    };
};

export default connect(mapStateToProps)(translate('translations')(HomeContainer));
