import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {toast} from "react-toastify";
import {__clone, parseResponse} from "../../utils/common";
import axios from 'app/config/axios'
import Toast from "../../components/shared/Toast/Toast";
import InputCheckbox from "../../components/shared/InputCheckbox";
import {USER_COMMUNICATIONS} from '../../store/consts/user.constants'
import Button from "../../components/shared/Button/Button";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import {captureException} from "../../utils/captureException";

const API_USER_COMMUNICATION_UPDATE_URL = 'garage/settings/update-communication';

class Communication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            communication: props.user.communication,
            updating: false,
        }
    }

    componentDidMount() {

    }

    submit = (e) => {
        e.preventDefault();

        const {communication} = this.state;
        const {t, update} = this.props;

        if (communication.length === 0) {
            toast.error(<Toast text={t('pages.settings.section_form.communication_required')} type="error"/>);
            return;
        }

        this.setState({
            updating: true,
        });

        axios.post(API_USER_COMMUNICATION_UPDATE_URL, {communication: communication}).then(response => {
            this.setState({
                updating: false,
            })

            update(response.data.communication);

            toast.success(<Toast text={parseResponse(response)} type="success"/>)
        }).catch(error => {
            captureException(error, API_USER_COMMUNICATION_UPDATE_URL)

            toast.error(<Toast text={parseResponse(error.response)} type="error"/>);
            this.setState({
                updating: false,
            });
        })
    };

    handleChange = (e) => {
        const {value, checked} = e.target;
        const {communication} = this.state;

        let list = __clone(communication);

        if (checked) {
            list.push(value);
        } else {
            list.splice(list.indexOf(value), 1);
        }

        this.setState({
            communication: list
        })
    }

    render() {
        const {communication, updating} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div
                            className="settings-tab__header__title">{t('pages.settings.section_tabs.communication')}</div>
                    </div>
                    <div className="settings-tab__content">
                        <form onSubmit={this.submit}>

                            {USER_COMMUNICATIONS.map(comm => {
                                return (
                                    <InputCheckbox
                                        key={comm}
                                        id={comm}
                                        name={comm}
                                        value={comm}
                                        onChange={this.handleChange}
                                        checked={communication.indexOf(comm) !== -1}
                                        label={t('pages.settings.section_form.communication.' + comm)}
                                    />
                                )
                            })}

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button id="save_settings_communication" size="lg" type="primary"
                                            disabled={updating}>{t('global.save')}</Button>
                                </div>
                            </SubmitContainer>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(Communication);
