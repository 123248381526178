import { DRAFT_GET, DRAFT_REMOVE } from '../../actions/draft.actions'
import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions'
import { LOADER_DRAFT } from '../../consts/loader.constants'
import { setLoader } from '../../actions/loader.actions'
import { carUpdateObject } from '../../actions/car.actions'
import { setStepData } from '../../actions/step.actions'
import { updateGarage } from '../../actions/garage.actions'
import { packagesSet } from '../../actions/packages.actions'
import history from 'routes/history'
import pages from 'app/consts/routes'
import { setSessionStorageItem } from '../../../utils/storage'
import { STORAGE_OFFER_TOKEN } from '../../../app/consts/storage.consts'
import { carStepSet } from '../../actions/carStep.actions'
import { FORM_VEHICLE_TYPE_MANUAL_1 } from '../../../app/consts/vehicle'
import { resetToken } from '../../../utils/resetToken'
import { clientSet } from '../../actions/client.actions'
import { offerSet } from '../../actions/offer.actions'
import { checklistLaboursSet } from '../../actions/checklistLabours.actions'

const API_FETCH_DRAFT_URL = 'garage/add-cost-tool/get-request'
const API_REMOVE_DRAFT_URL = 'garage/add-cost-tool/remove-request'

export const draftMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)
        switch (action.type) {
            case DRAFT_GET:
                dispatch(setLoader(LOADER_DRAFT, true))
                resetToken()

                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: { offer_token: action.payload.token },
                        url: API_FETCH_DRAFT_URL,
                        signature: DRAFT_GET,
                    }),
                )
                break

            case `${DRAFT_GET} ${API_SUCCESS}`:
                dispatch(setLoader(LOADER_DRAFT, false))

                setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token)

                dispatch(carUpdateObject(action.payload.data.car))
                dispatch(setStepData(action.payload.data.steps))
                dispatch(updateGarage(action.payload.data.settings))
                dispatch(packagesSet(action.payload.data.packages))
                dispatch(checklistLaboursSet(action.payload.data.checklistLabours))

                if (action.payload.data.client) {
                    dispatch(clientSet(action.payload.data.client))
                }

                if (action.payload.data.offer) {
                    dispatch(offerSet(action.payload.data.offer))
                }

                if (!action.payload.data.car.licence_plate) {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1))
                }

                history.push(pages.packages)
                break

            case `${DRAFT_GET} ${API_ERROR}`:
                dispatch(setLoader(LOADER_DRAFT, false))
                break

            case DRAFT_REMOVE:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: { offer_token: action.payload.token },
                        url: API_REMOVE_DRAFT_URL,
                        signature: DRAFT_REMOVE,
                    }),
                )
                break

            case `${DRAFT_REMOVE} ${API_SUCCESS}`:
                break

            case `${DRAFT_REMOVE} ${API_ERROR}`:
                break

            default:
                break
        }
    }
}
