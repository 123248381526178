import i18n from "i18next";
import language from "./language";
import * as Yup from "yup";
import lang from "../app/lang";

export const ChangeLanguage = () => {
    i18n.changeLanguage(language())
        .then(() => {
            Yup.setLocale(lang[language()].translations.validations.default);
        })
        .catch(() => {
            window.settings = {preventBeforeUpload: true}
            document.location.reload()
        })
};

export default ChangeLanguage;
