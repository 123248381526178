export default {
    id: null,
    name: '',
    selected: true,
    versions: [
        {
            id: null,
            name: '',
            selected: true,
            set: [
                {
                    id: null,
                    name: '',
                    partNo: 'N/A',
                    price: '',
                    currency: '',
                    amounts: 0,
                    quantity: '',
                    selected: true,
                },
            ],
        },
    ],
}
