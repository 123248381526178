import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from './reducers'
import middleware from './middleware'
import { STORE_RESET } from './actions/store.actions'
import models from './models/models'
import persistReset from './models/persistReset'
import thunk from 'redux-thunk'
import {GARAGE_REINITIALIZE, GARAGE_REMOVE} from "./actions/garage.actions";
import persistGarageRemove from "./models/persistGarageRemove";

const enhancer = composeWithDevTools(applyMiddleware(thunk, ...middleware.feature, ...middleware.core))
const store = combineReducers(reducer)

const rootReducer = (state, action) => {
    if (action.type === STORE_RESET) {
        if (action.payload.persist) {
            state = { ...models, ...persistReset(state) }
        } else {
            state = { ...models }
        }
    }

    if (action.type === GARAGE_REMOVE) {
        state = { ...models, ...persistGarageRemove(state) }
    }

    if (action.type === GARAGE_REINITIALIZE) {
        state = { ...models, ...persistGarageRemove(state) }
    }

    return store(state, action)
}

export default createStore(rootReducer, {}, enhancer)
