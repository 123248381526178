import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {formChangePassword} from "app/config/yup";
import {withFormik} from "formik";
import Button from "components/shared/Button/Button";
import Input from "components/shared/Input/Input";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class ChangePassword extends Component {
    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    render() {
        const { t, touched, errors, values, handleBlur, handleSubmit, formSubmitting } = this.props;

        return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.user_profile.section_tabs.change_password')}</div>
                    </div>
                    <div className="settings-tab__content">

                        <form onSubmit={e => handleSubmit(e)}>

                            <Input
                                type="password"
                                placeholder={t('pages.user_profile.section_form.placeholder.current_password')}
                                name="old_password"
                                label={t('pages.user_profile.section_form.label.current_password')}
                                value={values.old_password}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.old_password && errors.old_password}
                            />

                            <Input
                                type="password"
                                placeholder={t('pages.user_profile.section_form.placeholder.new_password')}
                                name="password"
                                label={t('pages.user_profile.section_form.label.new_password')}
                                value={values.password}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.password && errors.password}
                            />

                            <Input
                                type="password"
                                placeholder={t('pages.user_profile.section_form.placeholder.repeat_password')}
                                name="password_confirmation"
                                label={t('pages.user_profile.section_form.label.repeat_password')}
                                value={values.password_confirmation}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.password_confirmation && errors.password_confirmation}
                            />

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button id="save_new_password" size="lg" type="primary" disabled={formSubmitting.settings}>{t('pages.user_profile.section_form.submit_new_password')}</Button>
                                </div>
                            </SubmitContainer>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const ChangePasswordForm = withFormik(formChangePassword)(ChangePassword);

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting
    };
};

export default connect(mapStateToProps)(translate('translations')(ChangePasswordForm));
