import store from 'store/store';
import pages from "app/consts/routes";
import history from 'routes/history';
import React from "react";
import {TYPE_OFFICIAL} from "../../../store/consts/package/package.type.constants";
import {OFFICIAL_SERVICE_SELECTED} from "../../../store/consts/officialServices.constants";

export const OfficialServices = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            let selectedRecommended = store.getState().officialServices.recommended[OFFICIAL_SERVICE_SELECTED[store.getState().officialServices.recommended.selected]]
            let officialServicePackage = store.getState().packages.find(p => p.type === TYPE_OFFICIAL);

            this.shouldRender = true;

            if (!officialServicePackage && !selectedRecommended) {
                this.shouldRender = false;
                history.replace(pages.packages);
            }
        }

        render() {
            if (this.shouldRender) {
                return <Component {...this.props} />;
            }

            return <div/>;
        }
    }
};

export default OfficialServices;
