import Yup from './yup'
import BaseCountry from '../../../utils/BaseCountry'

export default {
    enableReinitialize: true,

    mapPropsToValues({ discount }) {
        return {
            price: discount.price,
            name: discount.name,
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            name: Yup.string()
                .nullable(true)
                .label(t('pages.package_details.discount.section_form.label.name')),

            price: Yup.number()
                .required()
                .label(
                    t('pages.package_details.discount.section_form.label.price', { currency: BaseCountry().currency }),
                ),
        })
    },
}
