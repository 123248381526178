import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { withFormik } from 'formik'
import formMechanic from '../../../app/config/yup/formMechanic.yup'
import Input from '../../shared/Input/Input'
import Button from '../../shared/Button/Button'
import { usePrevious } from '../../hooks/utils'
import Select from '../../shared/Select/Select'
import {
    USER_ROLE_SUPERVISOR,
    USER_ROLE_MANAGER,
    USER_ROLES,
    USER_ACTIVITY,
} from '../../../store/consts/user.constants'
import Phone from '../../shared/Phone/Phone'
import ImageUpload from '../../shared/ImageUpload/ImageUpload'

export const FormMechanic = props => {
    const {
        t,
        handleChange,
        resetForm,
        handleBlur,
        handleSubmit,
        touched,
        errors,
        values,
        isFormSubmitting,
        buttonLabel,
        setFieldValue,
        setValues,
        setTouched,
        mechanic,
        setFieldTouched,
    } = props
    const prevShouldResetForm = usePrevious(props.shouldResetForm)
    const [changeAvatar, setChangeAvatar] = useState(false)

    let avatar = React.createRef()

    useEffect(() => {
        if (props.shouldResetForm !== prevShouldResetForm && props.shouldResetForm === true) {
            resetForm()
        }
    })

    useEffect(() => {
        setChangeAvatar(false)
    }, [props.mechanic])

    let handleImageCancel = () => {
        setTouched({ ...touched, avatar: false })
        setValues({ ...values, avatar: null })
        setChangeAvatar(false)
    }

    let handleFieldChange = (name, value) => {
        setFieldTouched(name, true)
        setFieldValue(name, value)
    }

    return (
        <form onSubmit={handleSubmit} className="hourly-price-form">
            <div className="hourly-price-item">
                {mechanic && mechanic.avatar && !changeAvatar ? (
                    <div className="user-avatar">
                        <img src={mechanic.avatar} ref={ref => (avatar = ref)} alt="Avatar" />
                        <span className="link-style" onClick={() => setChangeAvatar(true)}>
                            {t('pages.user_profile.section_form.avatar.change')}
                        </span>
                    </div>
                ) : (
                    <ImageUpload
                        onSelect={value => handleFieldChange('avatar', value)}
                        onCancel={handleImageCancel}
                        showCancel={mechanic && mechanic.avatar}
                        error={errors.avatar}
                    />
                )}

                <Select
                    id="type"
                    list={Object.keys(USER_ROLES)
                        .filter(i => i != USER_ROLE_MANAGER && i != USER_ROLE_SUPERVISOR)
                        .map(id => {
                            return { id: parseInt(id), name: USER_ROLES[id] }
                        })}
                    value={parseInt(values.type)}
                    selected={parseInt(values.type)}
                    label={t('form.placeholder.user_type')}
                    error={touched.type && errors.type}
                    onChange={(id, option) => {
                        setFieldValue(id, option.id)
                    }}
                >
                    {t('form.placeholder.user_type')}
                </Select>

                <Input
                    type="text"
                    name="first_name"
                    placeholder={t('form.placeholder.first_name')}
                    label={t('form.label.first_name')}
                    onBlur={handleBlur}
                    error={touched.first_name && errors.first_name}
                    onChange={handleChange}
                    value={values.first_name}
                />

                <Input
                    type="text"
                    name="last_name"
                    placeholder={t('form.placeholder.last_name')}
                    label={t('form.label.last_name')}
                    onBlur={handleBlur}
                    error={touched.last_name && errors.last_name}
                    onChange={handleChange}
                    value={values.last_name}
                />

                <Input
                    type="text"
                    name="email"
                    placeholder={t('form.placeholder.email')}
                    label={t('form.label.email')}
                    onBlur={handleBlur}
                    error={touched.email && errors.email}
                    onChange={handleChange}
                    value={values.email}
                />

                <Input
                    type="text"
                    name="username"
                    placeholder={t('form.placeholder.username2')}
                    label={t('form.label.username2')}
                    onBlur={handleBlur}
                    error={touched.username && errors.username}
                    onChange={handleChange}
                    value={values.username}
                />

                <Phone
                    name={'phone'}
                    onChange={e => handleFieldChange('phone', e)}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={touched.phone && errors.phone}
                />

                <Input
                    type="password"
                    name="password"
                    placeholder={t('form.placeholder.password')}
                    label={t('form.label.password')}
                    onBlur={handleBlur}
                    error={touched.password && errors.password}
                    onChange={handleChange}
                    value={values.password}
                />

                <Select
                    id="active"
                    list={Object.keys(USER_ACTIVITY).map(id => {
                        return { id: parseInt(id), name: USER_ACTIVITY[id] }
                    })}
                    value={parseInt(values.active)}
                    selected={parseInt(values.active)}
                    label={t('form.placeholder.user_activity')}
                    error={touched.active && errors.active}
                    onChange={(id, option) => {
                        setFieldValue(id, option.id)
                    }}
                >
                    {t('form.placeholder.user_activity')}
                </Select>

                <Button size="lg" type="primary" disabled={isFormSubmitting}>
                    {buttonLabel}
                </Button>
            </div>
        </form>
    )
}

export default translate('translations')(withFormik(formMechanic)(FormMechanic))
