import apiMiddleware from './core/api.middleware'
import { stepMiddleware } from './feature/step.middleware'
import { carMiddleware } from './feature/car.middleware'
import { carStepMiddleware } from './feature/carStep.middleware'
import { packagesMiddleware } from 'store/middleware/feature/packages.middleware'
import { garageMiddleware } from 'store/middleware/feature/garage.middleware'
import { labourPricesMiddleware } from 'store/middleware/feature/labourPrices.middleware'
import { authMiddleware } from 'store/middleware/feature/auth.middleware'
import { userMiddleware } from 'store/middleware/feature/user.middleware'
import { languageSettingMiddleware } from 'store/middleware/feature/language.setting.middleware'
import { mastercheckLayoutSettingMiddleware } from 'store/middleware/feature/mastercheckLayout.setting.middleware'
import { changePasswordSettingMiddleware } from 'store/middleware/feature/change-password.setting.middleware'
import { changeUserDetailsSettingMiddleware } from 'store/middleware/feature/change-user-details.setting.middleware'
import { recipientsSettingMiddleware } from 'store/middleware/feature/recipients.setting.middleware'
import { settingMiddleware } from 'store/middleware/feature/setting.middleware'
import { mechanicsMiddleware } from './feature/mechanics.middleware'
import { analyticsMiddleware } from './feature/analytics.middleware'
import { requestsMiddleware } from './feature/requests.middleware'
import { draftMiddleware } from './feature/draft.middleware'
import { changeGarageDetailsSettingMiddleware } from './feature/change-garage-details.setting.middleware'
import { notificationsMiddleware } from './feature/notifications.middleware'
import { checklistLaboursMiddleware } from './feature/checklistLabours.middleware'
import { officialServicesMiddleware } from './feature/officialServices.middleware'
import { changeCustomerSignature } from './feature/change-customer-signature.setting.middleware'
import { tiresMiddleware } from './feature/tires.middleware'

const app = [
    stepMiddleware,
    carMiddleware,
    carStepMiddleware,
    packagesMiddleware,
    garageMiddleware,
    labourPricesMiddleware,
    authMiddleware,
    userMiddleware,
    languageSettingMiddleware,
    mastercheckLayoutSettingMiddleware,
    changePasswordSettingMiddleware,
    changeUserDetailsSettingMiddleware,
    recipientsSettingMiddleware,
    settingMiddleware,
    mechanicsMiddleware,
    analyticsMiddleware,
    requestsMiddleware,
    draftMiddleware,
    changeGarageDetailsSettingMiddleware,
    notificationsMiddleware,
    checklistLaboursMiddleware,
    officialServicesMiddleware,
    changeCustomerSignature,
    tiresMiddleware,
]

const core = [apiMiddleware]

export default {
    core,
    feature: app,
}
