import Yup from './yup'

export default {
    enableReinitialize: true,

    mapPropsToValues({ client }) {
        return {
            first_name: client.first_name || '',
            last_name: client.last_name || '',
            email: client.email || '',
            home_phone: client.home_phone || '',
            phone: client.phone || '',
            licence_plates: client.licence_plates.map(lp => lp.licence_plate) || [],
            id: client.id || '',
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            id: Yup.string().required(),

            first_name: Yup.string()
                .required()
                .label(t('pages.request_preview.section_form.label.first_name')),

            last_name: Yup.string()
                .required()
                .label(t('pages.request_preview.section_form.label.last_name')),

            email: Yup.string()
                .email()
                .label(t('pages.request_preview.section_form.label.email'))
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function() {
                    return this.parent.phone || this.parent.email
                }),

            phone: Yup.number()
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function() {
                    return this.parent.phone || this.parent.email
                })
                .label(t('pages.request_preview.section_form.label.phone')),

            licence_plates: Yup.array()
                .label(t('pages.customers.licence_plates')),

            home_phone: Yup.number()
                .label(t('pages.customers.home_phone')),
        })
    },
}
