import React, {Component} from 'react';
import {translate} from "react-i18next";
import InputRadio from "../shared/InputRadio";
import BaseCountry from "../../utils/BaseCountry";
import Select from "../shared/Select/Select";
import classNames from 'classnames';
import Button from "../shared/Button/Button";
import {
    TIRES_AXEL_FRONT, TIRES_AXEL_REAR, TIRES_AXELS, TIRES_SORT_ASC
} from "../../store/consts/tires.constants";
import ListLoader from "../shared/ListLoader/ListLoader";
import {roundCeil, toBool} from "../../utils/common";

class TiresSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeAxelTab: TIRES_AXEL_FRONT,
        }
    }

    componentDidMount() {
        const {onGet} = this.props;

        onGet()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tires, brandType} = this.props;
        const {activeAxelTab} = this.state;

        if (prevState.activeAxelTab !== this.state.activeAxelTab) {
            if (this.hasSelectedTires() && !tires.axel[activeAxelTab].brand_type[brandType] && tires.brand <= 0) {
                this.filterBrand('brand', {
                    id: tires.axel[activeAxelTab === TIRES_AXEL_FRONT ? TIRES_AXEL_REAR : TIRES_AXEL_FRONT].brand_type[brandType].tire_brand_id,
                })
            }
        }

    }

    filterBrand = (id, option) => {
        const {onUpdate, onGet} = this.props;

        onUpdate(id, option.id)
        onGet()
    };

    filterSeason = (id, option) => {
        const {brandType, resetSelectedTires, onUpdate, onGet} = this.props;

        resetSelectedTires(brandType);
        onUpdate(id, option.id);

        onGet();
    };

    filterIndex = (id, option) => this.props.onUpdate('index', option.id)

    hasSelectedTires = () => {
        const {tires, brandType} = this.props;

        return tires.axel[TIRES_AXEL_FRONT].brand_type[brandType] || tires.axel[TIRES_AXEL_REAR].brand_type[brandType]
    }

    submit = () => {
        const {tires, goToStep, validateSteps, brandType} = this.props;

        if(tires.bothAxelSameDimension === false && tires.bothAxelSelected === true){

            let missingAxel = TIRES_AXELS.find(axel => tires.axel[axel].selected && toBool(tires.axel[axel].brand_type[brandType]) === false)

            if(missingAxel){
                this.setState({activeAxelTab: missingAxel});

                return;
            }
        }

        if (validateSteps[tires.step]) {
            goToStep(tires.step + 1)
        }
    }

    onSkip = () => {
        const {tires, goToStep, resetSelectedTires, brandType} = this.props;

        resetSelectedTires(brandType).then(() => {
            goToStep(tires.step + 1);
        });
    }

    render() {
        const {t, tires, goToStep, selectTire, brandType, sortUpdate} = this.props;
        const {activeAxelTab} = this.state;

        const TireItem = ({item, axels}) => {
            return (
                <div className="tires-item" key={item.ean}>
                    <InputRadio
                        id={item.ean}
                        name={"tire-item"}
                        value={item.ean}
                        label={
                            <div className="tires-item-radio">
                                <div
                                    className="col-xs-6 col-sm-7 tires-item-radio__name">{item.brand} {item.profile}
                                    <br/><small>SAP: {item.code_sap}</small></div>
                                <div className="col-xs-3 col-sm-2 text-center">{item.index}</div>
                                <div className="col-xs-3 col-sm-3 tires-item-radio__price">
                                    {(!tires.bothAxelSameDimension && tires.bothAxelSelected) ?
                                        <>{roundCeil(item.price_per_axel_total, true)} {BaseCountry().currency}</>
                                        :
                                        <>{roundCeil(item.price_total, true)} {BaseCountry().currency}</>
                                    }
                                </div>
                            </div>
                        }
                        checked={item.selected}
                        onChange={() => selectTire(item, brandType, axels)}
                    />
                </div>
            )
        }

        const TiresEmptyList = () => {
            return (
                <div className="tires-list-empty">{t('tires.no_tires')}</div>
            )
        }

        const TiresSort = ({column}) => {
            return (
                <div className={`tires-sort-item ${tires.sort.column === column ? 'active' : ''}`}
                     onClick={() => sortUpdate(column)}>
                    {(tires.sort.order === TIRES_SORT_ASC && tires.sort.column === column) ?
                        <i className="icon-chevron-up"/> :
                        <i className="icon-chevron-down"/>} {t(`tires.header.${column}`)}
                </div>
            )
        }

        const TiresList = (list, axels) => {

            if (list.length === 0) {
                return <TiresEmptyList/>
            }

            return (
                <>
                    <div className="tires-list-header">
                        <div className="col-xs-6 col-sm-7">
                            <TiresSort column="brand"/>
                        </div>
                        <div className="col-xs-3 col-sm-2 text-center">
                            <TiresSort column="index"/>
                        </div>
                        <div className="col-xs-3 col-sm-3 text-right">
                            <TiresSort column="price"/>
                        </div>
                    </div>

                    <div className="tires-list">
                        {list.map((item) => <TireItem key={item.ean} item={item} axels={axels}/>)}
                    </div>
                </>
            )
        }

        const TireAxelTab = () => {
            return (
                <div className="tires-axel-tab">
                    <div className="tires-axel-tabs">
                        <div
                            className={classNames('tires-axel-tab-item', {'active': activeAxelTab === TIRES_AXEL_FRONT})}
                            onClick={() => this.setState({activeAxelTab: TIRES_AXEL_FRONT})}>
                            {t(`tires.axel.${TIRES_AXEL_FRONT}`)} <span
                            className="small">({tires.list_front.length})</span>
                        </div>
                        <div
                            className={classNames('tires-axel-tab-item', {'active': activeAxelTab === TIRES_AXEL_REAR})}
                            onClick={() => this.setState({activeAxelTab: TIRES_AXEL_REAR})}>
                            {t(`tires.axel.${TIRES_AXEL_REAR}`)} <span
                            className="small">({tires.list_rear.length})</span>
                        </div>
                    </div>
                    <div className="tires-select-content">
                        {tires.loading ? <ListLoader/> :
                            <div className="tires-axel-tab-content">
                                {activeAxelTab === TIRES_AXEL_FRONT && TiresList(tires.list_front, [activeAxelTab])}
                                {activeAxelTab === TIRES_AXEL_REAR && TiresList(tires.list_rear, [activeAxelTab])}
                            </div>
                        }
                    </div>
                </div>
            )
        }

        const FilterTitle = () => {
            if (tires.bothAxelSelected) {
                return <span className="tires-filters-axel">{t('tires.filter_title_both')}</span>
            } else if (tires.axel.front.selected) {
                return <span className="tires-filters-axel">{t('tires.filter_title_front')}</span>
            } else if (tires.axel.rear.selected) {
                return <span className="tires-filters-axel">{t('tires.filter_title_rear')}</span>
            }
        }

        const Skip = () => {
            if(!tires.canSkip[tires.step]){
                return null;
            }

            return (
                <span className="link-style next-to-btn-md" onClick={this.onSkip}>{t('global.skip')}</span>
            )
        }

        return (
            <React.Fragment>

                <div className="tires-select">

                    <div className="tires-filters">
                        <div className="row">
                            <div className="col-xs-12 col-sm-3">
                                <FilterTitle/>
                            </div>
                            <div className="col-xs-4 col-sm-3">
                                <Select
                                    id={'brand'}
                                    name={'brand'}
                                    list={tires.brand_list}
                                    selected={tires.brand}
                                    onChange={this.filterBrand}
                                    size={'sm'}
                                >
                                    {t('tires.label.brand')}
                                </Select>
                            </div>
                            <div className="col-xs-4 col-sm-3">
                                <Select
                                    id={'index'}
                                    name={'index'}
                                    list={tires.index_list}
                                    selected={tires.index}
                                    onChange={this.filterIndex}
                                    size={'sm'}
                                >
                                    {t('tires.label.index')}
                                </Select>
                            </div>
                            <div className="col-xs-4 col-sm-3">
                                <Select
                                    id={'season'}
                                    name={'season'}
                                    list={tires.season_list}
                                    selected={tires.season}
                                    onChange={this.filterSeason}
                                    size={'sm'}
                                >
                                    {t('tires.label.season')}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <>
                        {(!tires.bothAxelSameDimension && tires.bothAxelSelected) ?
                            TireAxelTab()
                            :
                            <div className="tires-select-content">
                                {tires.loading ?
                                    <ListLoader/> : TiresList(tires.list, [TIRES_AXEL_FRONT, TIRES_AXEL_REAR])}
                            </div>
                        }
                    </>

                    <div className="tires-step-submit">
                        <div className="row">
                            <div className="col-xs-6">
                                <span className="link-style next-to-btn-md" onClick={() => goToStep(tires.step - 1)}>{t('global.back')}</span>
                            </div>
                            <div className="col-sm-6 col-xs-6 text-right">

                                <Skip />

                                <Button size="md" type="primary" onClick={this.submit}
                                        disabled={!this.hasSelectedTires() || tires.loading}>{t('form.button.next')}</Button>
                            </div>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default translate('translations')(TiresSelect);
