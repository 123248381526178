import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeStepData} from 'store/actions/step.actions';
import {translate} from 'react-i18next';
import {setNavigationTitle} from "utils/setNavigationTitle";
import CarDetail from "components/CarDetail/CarDetail";
import Header from "components/layout/Header/Header";
import Button from "components/shared/Button/Button";
import {intersect} from "utils/common";
import "./RequestPreviewContainer.scss";
import {withFormik} from "formik";
import {formRequestPreview} from "app/config/yup";
import {RequestPreview} from "../../routes/middleware/RequestPreview/RequestPreview";
import {PackagesStep} from "../../routes/middleware/Packages/PackagesStep";
import {HasOfferToken} from "../../routes/middleware/HasOfferToken";
import UserData from "./UserData";
import PackageUrgency from "../../components/shared/PackageUrgency";
import {clientReset, clientSet} from "../../store/actions/client.actions";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import PackagePrice from "../../components/PackageComponents/PackagePrice";
import TotalPrice from "../../components/PackageComponents/TotalPrice";
import {rangedSumPackagesTotalIncludeDiscount} from "../../store/selectors/packagePrices";

class RequestPreviewContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            car: this.props.car,
            packages: this.props.packages,
        };
    }

    componentDidMount() {
        const {t} = this.props;

        document.title = t('titles.request_preview');
        setNavigationTitle(t('pages.request_preview.title'));

        this.props.dispatch(changeStepData(3));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop]
                })
            }
        });
    }

    handleSubmit(event) {
        const {handleSubmit} = this.props
        event.preventDefault();
        handleSubmit(event)
    }

    handleAlternate(event) {
        const {handleSubmit} = this.props
        event.preventDefault();
        handleSubmit(event)
    }

    render() {
        const {car, packages} = this.state;
        const {t, title, values, touched, errors, handleBlur, handleChange, setFieldValue, user, garage, dispatch, offer} = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container container--has-submit-container">

                    <div className="package-collapsable">
                        <div className="package-collapsable-header">
                            <div
                                className="package-collapsable-header__title">{t('pages.request_preview.car_details')}</div>
                        </div>
                        <div className="package-collapsable-content">
                            <CarDetail car={car}/>
                        </div>
                    </div>

                    <div className="package-collapsable">
                        <div className="package-collapsable-header">
                            <div
                                className="package-collapsable-header__title">{t('pages.request_preview.checkout')}</div>
                        </div>
                        <div className="package-collapsable-content">
                            {packages.map(_package => {
                                return (
                                    <div key={_package.id} className="packages-item">
                                        <div className="row">
                                            <div className="col-xs-8">
                                                <div className="mb-5">
                                                    {_package.name}
                                                    {_package.officialService.mileage && t('pages.request_preview.official_package_mileage', {mileage: _package.officialService.mileage})}
                                                </div>
                                                <PackageUrgency urgency={_package.urgency}/>
                                            </div>
                                            <div className="col-xs-4 text-right">
                                                <span className="bold"><PackagePrice pack={_package} fullPrice={true}/></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {/*<div className="packages-total">
                                <span>{t('pages.package_details.total_all')}</span>
                                <span>{fixedFloat(sumPackagesPriceTotal(packages))} {BaseCountry().currency}</span>
                            </div>*/}
                        </div>
                        <hr className='hr'/>
                        <div className="package-collapsable-content">
                            <div className="packages-item">
                                <div className="row">
                                    <div className="col-xs-8">
                                        <div className="mb-5">
                                            {t('global.total')}
                                        </div>
                                    </div>
                                    <div className="col-xs-4 text-right">
                                        <span className="bold"><TotalPrice sumPriceData={rangedSumPackagesTotalIncludeDiscount(packages)}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="package-collapsable">
                        <div className="package-collapsable-header">
                            <div
                                className="package-collapsable-header__title">{t('pages.request_preview.customer_details')}</div>
                        </div>
                        <div className="package-collapsable-content">
                            <form onSubmit={this.handleSubmit.bind(this)}>

                                <UserData
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    handleFieldChange={(name, value) => {
                                        this.props.setFieldTouched(name, true);
                                        this.props.setFieldValue(name, value);
                                    }}
                                    user={user}
                                    garage={garage}
                                    packages={packages}
                                    selectedAppointments={offer.appointment_discounts}
                                    onUserFound={(e) => {
                                        dispatch(clientSet(e.item));
                                    }}
                                    onUserReset={() => {
                                        dispatch(clientReset());
                                    }}
                                />

                                <SubmitContainer stat={true}>
                                    <button className="btn btn-lg btn-primary" type="submit">{t('pages.request_preview.send')}</button>
                                    {garage.allow_book_now &&
                                        <button className="btn btn-lg btn-primary" type="submit" onClick={(e) => {
                                            setFieldValue('book_now', true);
                                            this.handleAlternate.bind(this)
                                        }}>{t('pages.request_preview.book_now')}</button>
                                    }
                                </SubmitContainer>
                            </form>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const RequestPreviewForm = withFormik(formRequestPreview)(RequestPreviewContainer);

const mapStateToProps = state => {
    return {
        car: state.car,
        packages: state.packages,
        formSubmitting: state.formSubmitting,
        garage: state.garage,
        user: state.user,
        client: state.client,
        offer: state.offer,
    };
};

export default connect(mapStateToProps)(RequestPreview(PackagesStep(HasOfferToken(translate('translations')(RequestPreviewForm)))));
