import { CHECKLIST_LABOURS_FETCH, checklistLaboursSet } from '../../actions/checklistLabours.actions'
import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions'

const API_CHECKLIST_LABOURS_FETCH_URL = 'garage/add-cost-tool/checklist-labour'

export const checklistLaboursMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case CHECKLIST_LABOURS_FETCH:
                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: API_CHECKLIST_LABOURS_FETCH_URL,
                        signature: CHECKLIST_LABOURS_FETCH,
                    }),
                )
                break

            case `${CHECKLIST_LABOURS_FETCH} ${API_SUCCESS}`:
                dispatch(checklistLaboursSet(action.payload.data))
                break

            case `${CHECKLIST_LABOURS_FETCH} ${API_ERROR}`:
                break

            default:
                break
        }
    }
}
