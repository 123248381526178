import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { SETTINGS_FETCH } from 'store/actions/settings.actions'
import { languageSettingSet } from 'store/actions/language.settings.actions'
import { LOADER_SETTINGS_PAGE } from 'store/consts/loader.constants'
import { setLoader } from 'store/actions/loader.actions'

export const SETTINGS_PAGE_URL = 'garage/settings'

export const settingMiddleware = ({ dispatch }) => next => {
  return action => {
    next(action)

    switch (action.type) {
      case SETTINGS_FETCH:
        dispatch(setLoader(LOADER_SETTINGS_PAGE, false))

        dispatch(
          apiRequest({
            method: 'GET',
            url: SETTINGS_PAGE_URL,
            signature: SETTINGS_FETCH,
          }),
        )

        break

      case `${SETTINGS_FETCH} ${API_SUCCESS}`:
        dispatch(setLoader(LOADER_SETTINGS_PAGE, true))
        dispatch(languageSettingSet(action.payload.data.languages))
        break

      case `${SETTINGS_FETCH} ${API_ERROR}`:
        dispatch(setLoader(LOADER_SETTINGS_PAGE, true))
        break

      default:
        break
    }
  }
}
