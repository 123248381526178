import history from 'routes/history'
import { matchPath } from 'react-router-dom'
import garageRoutes from 'routes/garage'

const onLeaveConfirmDialog = e => {
    e.preventDefault()
    e.returnValue = ``
}

window.addEventListener('beforeunload', e => {
    let showOn = [
        'AddCost',
        'Packages',
        'Package Details',
        'Labour search',
        'Request preview',
        'Confirm Page',
        'Settings',
        'Price update - Hourly prices',
        'Price update - Oil prices',
        'Price update - Liquids prices',
        'Price update - Discount',
        'Mechanics',
    ]

    for (let route in garageRoutes) {
        if (matchPath(history.location.pathname, garageRoutes[route])) {
            try {
                if (
                    showOn.indexOf(garageRoutes[route].name) !== -1 &&
                    (!window.settings || !window.settings.preventBeforeUpload)
                ) {
                    return onLeaveConfirmDialog(e)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
})
