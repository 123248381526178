import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions'
import { userRemove } from 'store/actions/user.actions'
import { STORAGE_USER_TOKEN } from 'app/consts/storage.consts'
import { removeLocalStorageItem, setLocalStorageItem } from 'utils/storage'
import { formSubmittingLogin } from 'store/actions/formSubmitting.actions'
import { userFetch } from 'store/actions/user.actions'
import { storeReset } from 'store/actions/store.actions'
import history from 'routes/history'
import pages from 'app/consts/routes'
import {
    USER_LOGIN,
    AUTH_REMOVE,
    USER_RESET_PASSWORD_EMAIL,
    USER_RESET_PASSWORD_NEW,
    USER_LOGOUT,
} from 'store/consts/auth.constants'
import {
    authRemove,
} from 'store/actions/auth.actions'
import { garageRemove } from 'store/actions/garage.actions'

const USER_LOGIN_URL = 'garage/login'
const USER_LOGOUT_URL = 'garage/logout'
const USER_RESET_PASSWORD_EMAIL_URL = 'garage/reset-link'
const USER_RESET_PASSWORD_NEW_URL = 'garage/reset'

export const authMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case USER_LOGIN:
                dispatch(formSubmittingLogin(true))
                dispatch(
                    apiRequest({
                        body: action.payload.data,
                        method: 'POST',
                        url: USER_LOGIN_URL,
                        signature: USER_LOGIN,
                    }),
                )
                break

            case `${USER_LOGIN} ${API_SUCCESS}`:
                setLocalStorageItem(STORAGE_USER_TOKEN, action.payload.data.access_token_user)
                dispatch(userFetch())

                break

            case `${USER_LOGIN} ${API_ERROR}`:
                dispatch(formSubmittingLogin(false))
                break

            case USER_LOGOUT:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        url: USER_LOGOUT_URL,
                        signature: USER_LOGOUT,
                    }),
                )

                break

            case `${USER_LOGOUT} ${API_SUCCESS}`:
                removeLocalStorageItem(STORAGE_USER_TOKEN)
                dispatch(authRemove())
                break

            case `${USER_LOGOUT} ${API_ERROR}`:
                break

            case USER_RESET_PASSWORD_EMAIL:
                dispatch(formSubmittingLogin(true))
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: USER_RESET_PASSWORD_EMAIL_URL,
                        signature: USER_RESET_PASSWORD_EMAIL,
                    }),
                )
                break

            case `${USER_RESET_PASSWORD_EMAIL} ${API_SUCCESS}`:
                dispatch(formSubmittingLogin(false))

                break

            case `${USER_RESET_PASSWORD_EMAIL} ${API_ERROR}`:
                dispatch(formSubmittingLogin(false))
                break

            case USER_RESET_PASSWORD_NEW:
                dispatch(formSubmittingLogin(true))
                dispatch(
                    apiRequest({
                        body: action.payload.data,
                        method: 'POST',
                        url: USER_RESET_PASSWORD_NEW_URL,
                        signature: USER_RESET_PASSWORD_NEW,
                    }),
                )
                break

            case `${USER_RESET_PASSWORD_NEW} ${API_SUCCESS}`:
                dispatch(formSubmittingLogin(false))
                history.push(pages.login)

                break

            case `${USER_RESET_PASSWORD_NEW} ${API_ERROR}`:
                dispatch(formSubmittingLogin(false))
                break

            case AUTH_REMOVE:
                dispatch(userRemove())
                dispatch(garageRemove())
                dispatch(storeReset(false))
                break

            default:
                break
        }
    }
}
