import axios from "../../app/config/axios";
import {captureException} from "../../utils/captureException";

export const CHECKLIST_LABOURS = '[Checklist Labours]'

export const CHECKLIST_LABOURS_FETCH = `${CHECKLIST_LABOURS} FETCH`
export const CHECKLIST_LABOURS_UPDATE = `${CHECKLIST_LABOURS} UPDATE`
export const CHECKLIST_LABOURS_FIELD_UPDATE = `${CHECKLIST_LABOURS} FIELD UPDATE`
export const CHECKLIST_LABOURS_SET = `${CHECKLIST_LABOURS} SET`
export const CHECKLIST_LABOURS_RESET = `${CHECKLIST_LABOURS} RESET`

const API_CHECKLIST_LABOUR_UPDATE_URL = 'garage/add-cost-tool/checklist-labour/update';

export const checklistLaboursFetch = () => ({
    type: CHECKLIST_LABOURS_FETCH,
})

export const checklistLaboursUpdate = checklistLabour => ({
    type: CHECKLIST_LABOURS_UPDATE,
    payload: {
        checklistLabour,
    },
})

export const checklistLaboursFieldUpdate = (checklistLabour, field) => dispatch => {
    dispatch({
        type: CHECKLIST_LABOURS_FIELD_UPDATE,
        payload: {
            checklistLabour, field
        }
    });

    return Promise.resolve();
};

export const checklistLaboursSet = checklistLabours => ({
    type: CHECKLIST_LABOURS_SET,
    payload: {
        checklistLabours,
    },
})

export const checklistLaboursUpdatePassed = (labour) => {
    return function (dispatch, getState) {

        dispatch(checklistLaboursFieldUpdate(labour, {updating: true}));

        return new Promise((resolve, reject) => {
            axios
                .post(API_CHECKLIST_LABOUR_UPDATE_URL, {checklist_labour: labour})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    captureException(error, API_CHECKLIST_LABOUR_UPDATE_URL)

                    reject(error);
                })
                .finally(() => {
                    dispatch(checklistLaboursFieldUpdate(labour, {updating: false}))
                })
        })
    }
}

export const checklistLaboursReset = () => ({
    type: CHECKLIST_LABOURS_RESET,
})
