import {
    TYPE_MANAGER_REJECTED,
    TYPE_MANAGER_WAITING,
    TYPE_USER_RESPONDED,
} from '../../store/consts/notifications.constants'
import { STATUS_ACCEPTED } from '../../store/consts/package/package.status.constants'
import { sendPushNotifications } from '../../utils/pushNotifications'
import pages from '../../app/consts/routes'
import i18next from 'i18next'

export const PushNotifications = notify => {
    if (notify.type === TYPE_MANAGER_WAITING) {
        sendPushNotifications({
            title: `${notify.created_by.first_name} ${notify.created_by.last_name} ${i18next.t(
                'notification.manager_waiting',
            )}`,
            body: '',
            url: `${pages.request_details}${notify.token}`,
        })
    } else if (notify.type === TYPE_USER_RESPONDED) {
        let hasAccepted = notify.packages.filter(pack => pack.status === STATUS_ACCEPTED).length

        sendPushNotifications({
            title: `${notify.user.first_name} ${notify.user.last_name} ${notify.car.make}`,
            body: `${
                hasAccepted > 0
                    ? i18next.t('notification.user_responded.accepted', {
                          accepted: hasAccepted,
                          packagesLength: notify.packages.length,
                      })
                    : i18next.t('notification.user_responded.rejected')
            }`,
            url: `${pages.request_details}${notify.token}`,
        })
    } else if (notify.type === TYPE_MANAGER_REJECTED) {
        sendPushNotifications({
            title: `${notify.triggered_by.first_name} ${notify.triggered_by.last_name} ${i18next.t(
                'notification.manager_rejected',
            )}`,
            body: '',
            url: `${pages.request_details}${notify.token}`,
        })
    }
}
