var formatRelativeLocale = {
    lastWeek: "eeee 'la semaine dernière à' p",
    yesterday: "'hier à' p",
    today: "'aujourd’hui à' p",
    tomorrow: "'demain à' p'",
    nextWeek: "eeee 'la semaine prochaine à' p",
    other: 'P',
}

export default function formatRelative(token, _date, _baseDate, _options) {
    return formatRelativeLocale[token]
}
