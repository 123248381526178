import {
    USER_SET,
    USER_REMOVE,
    USER_UPDATE_COMMUNICATION,
    USER_UPDATE_LOCALE,
    USER_SETTINGS_UPDATE, USER_TIRES_SETTINGS_UPDATE, USER_CUSTOMER_SETTINGS_UPDATE
} from 'store/actions/user.actions'
import user from 'store/models/user'

export default function (state = user, action) {
    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                logged: true,
                ...action.payload.user,
            }

        case USER_UPDATE_COMMUNICATION:
            return {
                ...state,
                communication: action.payload.communications,
            }

        case USER_UPDATE_LOCALE:
            return {
                ...state,
                locale: action.payload.locale,
            }

        case USER_SETTINGS_UPDATE:
            return {
                ...state,
                settings: action.payload.data,
            }

        case USER_CUSTOMER_SETTINGS_UPDATE:
            return {
                ...state,
                settings: {
                    ...state.settings, customer: action.payload.data
                },
            }

        case USER_TIRES_SETTINGS_UPDATE:
            return {
                ...state,
                settings: {
                    ...state.settings, tires: action.payload.data
                },
            }

        case USER_REMOVE:
            return user

        default:
            return state
    }
}
