import Yup from './yup'
import {USER_ROLE_MECHANIC} from '../../../store/consts/user.constants'
import {omit} from 'lodash'
import {packagesSendRequest} from '../../../store/actions/packages.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues({offer, garage, client, initialValues = {book_now: false}}) {
        return {
            garage_file: offer.contract_number || '',
            id: client.id || '',
            first_name: client.first_name || '',
            last_name: client.last_name || '',
            email: client.email || '',
            phone: client.phone || '',
            time_due: offer.time_due || garage.time_due,
            comment: offer.comment || '',
            appointment_discounts: [],
            book_now: initialValues.book_now
        }
    },

    validationSchema: props => {
        const {t, user} = props

        if (user.type !== USER_ROLE_MECHANIC) {
            return Yup.object().shape({
                garage_file: Yup.string().nullable(),

                book_now: Yup.boolean().nullable(),

                id: Yup.number().nullable(),

                first_name: Yup.string()
                    .required()
                    .label(t('pages.request_preview.section_form.label.first_name')),

                last_name: Yup.string()
                    .required()
                    .label(t('pages.request_preview.section_form.label.last_name')),

                email: Yup.string()
                    .email()
                    .label(t('pages.request_preview.section_form.label.email'))
                    .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function () {
                        return this.parent.phone || this.parent.email
                    }),

                phone: Yup.number()
                    .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function () {
                        return this.parent.phone || this.parent.email
                    })
                    .label(t('pages.request_preview.section_form.label.phone')),

                time_due: Yup.string().nullable(),
                comment: Yup.string().nullable()
            })
        } else {
            return Yup.object().shape({
                garage_file: Yup.string().nullable(),

                book_now: Yup.boolean().nullable(),

                id: Yup.number().nullable(),

                first_name: Yup.string().label(t('pages.request_preview.section_form.label.first_name')),

                last_name: Yup.string().label(t('pages.request_preview.section_form.label.last_name')),

                email: Yup.string()
                    .email()
                    .label(t('pages.request_preview.section_form.label.email')),

                phone: Yup.number().label(t('pages.request_preview.section_form.label.phone')),

                time_due: Yup.string().nullable(),
                comment: Yup.string().nullable(),
                appointment_discounts: Yup.array().nullable()
            })
        }
    },

    handleSubmit(values, formikBag) {
        const {dispatch} = formikBag.props

        dispatch(
            packagesSendRequest(omit(values, ['time_due', 'garage_file', 'comment', 'appointment_discounts']), {
                time_due: values.time_due,
                contract_number: values.garage_file,
                comment: values.comment,
                appointment_discounts: values.appointment_discounts,
                book_now: values.book_now,
            }),
        )
    },
}
