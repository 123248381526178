import axios from "../../app/config/axios";
import {captureException} from "../../utils/captureException";

export const OFFICIAL_SERVICES = '[OFFICIAL_SERVICES]'

export const OFFICIAL_SERVICES_SET = `${OFFICIAL_SERVICES} SET`
export const OFFICIAL_SERVICES_RESET = `${OFFICIAL_SERVICES} RESET`

const API_OFFICIAL_SERVICE = 'garage/add-cost-tool/official-service'

export const officialServicesSet = (recommended, main, additional, mileage, exist) => ({
    type: OFFICIAL_SERVICES_SET,
    payload: {
        recommended,
        main,
        additional,
        mileage,
        exist,
    },
})

export const officialServicesFetch = (mileage) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            axios.post(API_OFFICIAL_SERVICE, {mileage: mileage})
                .then(response => {
                    // if(response.data.exist === true || response.data.main.length > 0 || response.data.additional.length > 0){

                    dispatch(officialServicesSet(response.data.recommended, response.data.main, response.data.additional, response.data.mileage, response.data.exist));

                    resolve(response)
                    // }
                    // else{
                    //     reject(response)
                    // }
                })
                .catch(error => {
                    captureException(error, API_OFFICIAL_SERVICE);

                    reject(error);
                })
        })
    };
}

export const officialServicesReset = () => ({
    type: OFFICIAL_SERVICES_RESET,
})
