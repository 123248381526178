import React, {useState} from 'react';
import {translate} from 'react-i18next';
import DatePicker from "react-datepicker";
import Button from "../../components/shared/Button/Button";

const DateFilter = ({filter, from, to, t}) => {

    const [startDate, setStartDate] = useState(from);
    const [endDate, setEndDate] = useState(to);

    return (
        <>
            <div className="row">

                <div className="col-xs-4 col-sm-3">
                    <div className="form-group">
                    <label>{t('pages.analytics_enhanced.date_picker.from')}: </label>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd.MM.yyyy"
                            calendarClassName="time-picker-custom"
                            disabledKeyboardNavigation
                        />
                    </div>
                </div>
                <div className="col-xs-4 col-sm-3">
                    <div className="form-group">
                        <label>{t('pages.analytics_enhanced.date_picker.to')}: </label>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="dd.MM.yyyy"
                            calendarClassName="time-picker-custom"
                            disabledKeyboardNavigation
                        />
                    </div>
                </div>
                <div className="col-xs-2 col-sm-1">
                    <div className="form-group">
                        <label>{'\u00A0'}</label>
                        <Button size="sm" type="primary" onClick={() => filter(startDate, endDate)} extraClass={"btn-block"}>
                            <i className="icon-search"/>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default translate('translations')(DateFilter)
