import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Input from "components/shared/Input/Input";
import {withFormik} from "formik";
import {formForgetPassword} from "app/config/yup";
import Header from "components/layout/Header/Header";
import pages from "app/consts/routes";
import Button from "components/shared/Button/Button";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class ForgetPasswordContainer extends Component {
    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    render() {
        const { touched, values, t, errors, handleBlur, handleSubmit, formSubmitting } = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} />

                <div className="container container--has-submit-container">


                    <form onSubmit={e => handleSubmit(e)}>

                        <div className="row">
                            <div className="col-sm-push-3 col-sm-6 col-xs-12">
                                <p className="font-size-sm mb-30 text-light">{t('pages.password_reset.tip')}</p>

                                <Input
                                    type="text"
                                    placeholder={t('form.placeholder.username')}
                                    name="username"
                                    label={t('form.label.username')}
                                    value={values.username}
                                    onBlur={handleBlur}
                                    onChange={this.handleTextChange}
                                    error={touched.username && errors.username}
                                />
                            </div>
                        </div>
                        <SubmitContainer>
                            <div className="col-xs-6">
                                <Button size="md" type="primary" to={pages.login}
                                        extraClass="btn-primary--outline btn-block">
                                    {t('global.cancel')}
                                </Button>
                            </div>
                            <div className="col-xs-6">
                                <Button size="md" type="primary" extraClass="btn-block" disabled={formSubmitting.login}>{t('pages.password_reset.submit')}</Button>
                            </div>
                        </SubmitContainer>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

const ForgetPasswordForm = withFormik(formForgetPassword)(ForgetPasswordContainer);

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting
    };
};

export default connect(mapStateToProps)(translate('translations')(ForgetPasswordForm));
