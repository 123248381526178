export const CAR = '[Car Step]'

export const CAR_STEP_UPDATE = `${CAR} UPDATE`
export const CAR_STEP_SET = `${CAR} SET`
export const CAR_STEP_POP = `${CAR} POP`
export const CAR_STEP_SET_MESSAGE = `${CAR} SET MESSAGE`
export const CAR_STEP_REPLACE = `${CAR} REPLACE`

export const carStepUpdate = (car, message = '') => ({
    type: CAR_STEP_UPDATE,
    payload: {
        car,
        message,
    },
})

export const carStepSet = (step, incomplete = false, message = '') => ({
    type: CAR_STEP_SET,
    payload: {
        step,
        incomplete,
        message,
    },
})

export const carStepReplace = (step, incomplete = false, message = '') => ({
    type: CAR_STEP_REPLACE,
    payload: {
        step,
        incomplete,
        message,
    },
})

export const carStepPop = () => ({
    type: CAR_STEP_POP,
})

export const carStepSetMessage = message => ({
    type: CAR_STEP_SET_MESSAGE,
    payload: {
        message,
    },
})
