export const LOADER = '[Loader]';

export const LOADER_SET = `${LOADER} SET`;
export const LOADERS_SET = `${LOADER} SET_MANY`;

export const setLoader = (loaderName, loaderState) => ({
    type: LOADER_SET,
    payload: {
        loaderName,
        loaderState
    }
});

export const setLoaders = (loaderParentName, loaderParentState, loaderChildren = {}) => ({
    type: LOADERS_SET,
    payload: {
        loaderParentName,
        loaderParentState,
        loaderChildren
    }
});
