import React, { Component } from 'react'
import { translate } from 'react-i18next'
import './RecordAudio.scss'
import Button from "../../Button/Button";
import RecordRTC, {StereoAudioRecorder} from 'recordrtc'

const isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

class RecordAudio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentTime: 0,
            isRecording: false,
            file: null
        }

        this.mediaRecorder = null
        this.chunks = [];
        this.timer = null;

        this.microphone = null;
    }

    componentWillUnmount() {
        this.stopRecording()
    }

    startRecording = () => {

        if(this.mediaRecorder){
            if(this.mediaRecorder.state === 'recording'){
                this.mediaRecorder.pauseRecording()
                this.setState(this.state)
                return;
            }

            if(this.mediaRecorder.state === 'paused'){
                this.mediaRecorder.resumeRecording()
                return;
            }
        }

        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = (constraints) => {

                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                return new Promise((resolve, reject) => {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                /*this.mediaRecorder = new MediaRecorder(stream);

                this.mediaRecorder.addEventListener('dataavailable', data => {
                    this.chunks.push(data.data)
                })

                this.mediaRecorder.addEventListener('start', () => {
                    this.timer = setInterval(this.setTime, 1000)
                })

                this.mediaRecorder.addEventListener('pause', () => {
                    clearInterval(this.timer)
                })

                this.mediaRecorder.addEventListener('resume', () => {
                    this.timer = setInterval(this.setTime, 1000)
                })

                this.mediaRecorder.addEventListener('stop', () => {
                    this.setState({
                        file: new Blob(this.chunks, { type: 'audio/mp3' }),
                        isRecording: false,
                    })

                    clearInterval(this.timer)
                })

                this.mediaRecorder.start();*/

                this.stream = stream

                let options = {
                    type: 'audio',
                    numberOfAudioChannels: isEdge ? 1 : 2,
                    checkForInactiveTracks: true,
                    bufferSize: 16384
                };

                if(isSafari || isEdge) {
                    options.recorderType = StereoAudioRecorder;
                }

                if(navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
                    options.sampleRate = 48000; // or 44100 or remove this line for default
                }

                if(isSafari) {
                    options.sampleRate = 44100;
                    options.bufferSize = 4096;
                    options.numberOfAudioChannels = 2;
                }

                if(this.mediaRecorder) {
                    this.mediaRecorder.destroy();
                    this.mediaRecorder = null;
                }

                this.mediaRecorder = RecordRTC(stream, options);

                this.mediaRecorder.startRecording();

                this.timer = setInterval(this.setTime, 1000)

            })
            .catch((err) => {

                this.setState({
                    isRecording: false,
                    currentTime: 0,
                    file: null
                })
            });

        this.setState({
            isRecording: true
        })
    };

    setTime = () => {
        if(this.mediaRecorder.state === 'recording'){
            this.setState({
                currentTime: this.state.currentTime + 1
            })
        }
    }

    saveRecording = () => {

        this.mediaRecorder.stopRecording(() => {


            this.setState({
                file: this.mediaRecorder.getBlob(),
                isRecording: false,
            })

            clearInterval(this.timer)

            this.stream.stop();
            this.stream = null;
        })
    };

    stopRecording = () => {
        if(this.stream){
            this.stream.stop();
            this.stream = null;
        }

        if(this.mediaRecorder){
            this.mediaRecorder.destroy()
            this.mediaRecorder = null
            clearInterval(this.timer)
        }

        this.setState({
            isRecording: false,
            currentTime: 0,
            file: null
        })
    };

    fancyTimeFormat = (time) => {
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
    }

    render() {
        const {isRecording, file, currentTime} = this.state
        const {t, onSave} = this.props

        return (
            <div className="audio-recording-controls">

                <div className="row mb-20">
                    <div className="col-xs-12">
                        <div className={`audio-recording-controls__time ${file !== null ? 'audio-recording-controls__time--has-file':''}`}>
                            {this.fancyTimeFormat(currentTime)}
                        </div>
                    </div>

                    {(isRecording || file) &&
                    <div className="col-xs-4">
                        <div className="audio-record-control">
                            <button onClick={this.stopRecording} className="audio-record-control-icon audio-record-control-icon__cancel"><i className="icon-cancel"/></button>
                            <span>{t('pages.package_details.files.record_audio.cancel')}</span>
                        </div>
                    </div>}

                    <div className={(isRecording || file) ? 'col-xs-4' : 'col-xs-12'}>
                        <div className="audio-record-control">
                            <button disabled={file !== null} onClick={this.startRecording}
                                    className={`audio-record-control-icon audio-record-control-icon__rec ${file !== null ? 'audio-record-control-icon__rec--has-file' : ''}`}>
                                {(this.mediaRecorder && this.mediaRecorder.state === 'recording') ?
                                    <i className="icon-pause"/>
                                    :
                                    <i className="icon-rec"/>
                                }
                            </button>
                            {(file === null) &&
                            ((this.mediaRecorder && this.mediaRecorder.state === 'recording') ?
                                <span>{t('pages.package_details.files.record_audio.pause')}</span>
                                :
                                <span>{t('pages.package_details.files.record_audio.rec')}</span>)
                            }
                        </div>
                    </div>

                    {(isRecording || file) &&
                    <div className="col-xs-4">
                        <div className="audio-record-control">
                            <button disabled={file !== null} onClick={this.saveRecording}
                                    className={`audio-record-control-icon audio-record-control-icon__stop ${file !== null ? 'audio-record-control-icon__stop--has-file' : ''}`}>
                                <i className="icon-stop"/></button>
                            <span>{t('pages.package_details.files.record_audio.stop')}</span>
                        </div>
                    </div>}
                </div>

                <Button size="lg" type="primary" disabled={file === null}
                        onClick={() => onSave(file)}>{t('pages.package_details.files.record_audio.save')}</Button>
            </div>
        )
    }
}

export default translate('translations')(RecordAudio)
