import Yup from './yup'

export default {
  enableReinitialize: true,

  mapPropsToValues() {
    return {
      time_due: '',
    }
  },

  validationSchema: props => {
    const { t } = props

    return Yup.object().shape({
      time_due: Yup.string()
        .nullable()
        .label(t('pages.settings.section_form.label.time_due')),
    })
  },
}
