import React, {Component} from 'react';
import './SearchUser.scss';
import axios from 'app/config/axios'
import {translate} from "react-i18next";
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import i18n from "i18next";
import language from "../../../utils/language";
import {trim} from "lodash";
import {captureException} from "../../../utils/captureException";

const MIN_CHAR_INPUT = 3
const API_SEARCH_USER_URL = 'garage/add-cost-tool/search-user'

const NoOptionsMessage = props => <components.NoOptionsMessage {...props}
                                                               children={i18n.getResource(language(), 'translations', 'form.select.no_result')}/>
const LoadingMessage = props => <components.LoadingMessage {...props}
                                                           children={i18n.getResource(language(), 'translations', 'global.loading')}/>
const DropdownIndicator = props => null

const CustomOption = (props) => {

    const {innerProps, isDisabled, data} = props

    if (isDisabled) {
        return null;
    }

    return <div {...innerProps} className="search-user-option">
        <div className="search-user-option__name">{`${data.item.first_name} ${data.item.last_name}`}</div>
        <span className="search-user-option__sub">{trim(`${data.item.email ? data.item.email : ''} | ${data.item.phone ? data.item.phone : ''}`, ' | ')}</span>
    </div>
}

class SearchUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    fetch = (input) => {

        if (!input || input.length < MIN_CHAR_INPUT) {
            return null
        }

        return axios
            .post(API_SEARCH_USER_URL, {search: input})
            .then(response => {
                return response.data.map(item => {
                    return {
                        value: item.id,
                        label: `${item.first_name} ${item.last_name}`,
                        item: item
                    }
                })
            })
            .catch(error => {
                captureException(error, API_SEARCH_USER_URL)
                return []
            })
    }

    render() {
        const {t, onChange, onUserReset, error} = this.props;
        const {value} = this.state;

        return (
            <div>

                <div className="form-group">
                    <label htmlFor="search-user">{t('pages.add_cost.search_user.section_form.label.search')}</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.fetch}
                        id="search-user"
                        placeholder={t('pages.add_cost.search_user.section_form.placeholder.search')}
                        onChange={(e) => {
                            this.setState({
                                value: e
                            })

                            e === null ? onUserReset() : onChange(e);
                        }}
                        isClearable={true}
                        value={value}
                        classNamePrefix={"select2"}
                        components={{NoOptionsMessage, LoadingMessage, DropdownIndicator, Option: CustomOption}}
                    />

                    {error && (
                        <span className="form-error">
                            <p>{error}</p>
                        </span>
                    )}
                </div>

            </div>
        );
    }
}

export default translate('translations')(SearchUser)
