export const SYSTEM_MESSAGE = '[SYSTEM_MESSAGE]'

export const SYSTEM_MESSAGES_FETCH = `${SYSTEM_MESSAGE} FETCH`
export const SYSTEM_MESSAGE_SET = `${SYSTEM_MESSAGE} SET`
export const SYSTEM_MESSAGE_SEEN = `${SYSTEM_MESSAGE} SEEN`
export const SYSTEM_MESSAGE_APPEND = `${SYSTEM_MESSAGE} APPEND`
export const SYSTEM_MESSAGE_APPENDED_CLEAR = `${SYSTEM_MESSAGE} APPENDED CLEAR`

export const fetchSystemMessage = () => ({
    type: SYSTEM_MESSAGES_FETCH,
})

export const setSystemMessage = systemMessage => ({
    type: SYSTEM_MESSAGE_SET,
    payload: {
        systemMessage,
    },
})

export const appendSystemMessage = systemMessage => ({
    type: SYSTEM_MESSAGE_APPEND,
    payload: {
        systemMessage,
    },
})

export const messageSeen = messageId => ({
    type: SYSTEM_MESSAGE_SEEN,
    payload: {
        messageId,
    },
})

export const clearAppendedSystemMessages = () => ({
    type: SYSTEM_MESSAGE_APPENDED_CLEAR,
})
