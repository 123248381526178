import './app/config/polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import './assets/styles/app.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import historyRoute from 'routes/history'
import store from 'store/store'
import i18n from 'app/config/i18n'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

require('./utils/beforeUnload')
require('./utils/date-object-enrich')

const render = Component => {
    return (
        <Router history={historyRoute}>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <Component />
                </I18nextProvider>
            </Provider>
        </Router>
    )
}

ReactDOM.render(render(App), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
