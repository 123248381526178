import {STORAGE_LANGUAGE} from "app/consts/storage.consts";
import * as languages from 'app/lang';

export const language = () => {

    if(localStorage.getItem(STORAGE_LANGUAGE) && Object.keys(languages.default).indexOf(localStorage.getItem(STORAGE_LANGUAGE)) !== -1){
        return localStorage.getItem(STORAGE_LANGUAGE);
    }

    return process.env.REACT_APP_FALLBACK_LANG;
};

export default language;