import { WORK_TYPE_RADIO } from '../consts/package/work'
import { TYPE_TO_SHOW_MISSING_DATA } from '../consts/package/package.type.constants'

const PACKAGE_MISSING_WORKS = 'missing-works'
const PACKAGE_MISSING_PARTS = 'missing-parts'
const PACKAGE_MISSING_PART_PRICE = 'missing-part-price'
const PACKAGE_MISSING_PART_AMOUNTS = 'missing-part-amounts'

export const missingInfo = _package => {
    let missing = { works: [], parts: [] }

    if (TYPE_TO_SHOW_MISSING_DATA.indexOf(_package.type) === -1) {
        return missing
    }

    for (let labour of _package.labours.filter(labour => labour.visible === true)) {
        if (labour.works.length === 0) {
            missing.works.push({ PACKAGE_MISSING_WORKS })
        }

        for (let work of labour.works.filter(
            w => w.selected === true && w.autogenerated === false && w.input_type === WORK_TYPE_RADIO,
        )) {
            if (work.parts.length === 0) {
                missing.parts.push({ PACKAGE_MISSING_PARTS })
            }

            for (let part of work.parts) {
                if (part.selected === true) {
                    for (let version of part.versions) {
                        if (version.selected === true) {
                            for (let set of version.set) {
                                if (isNaN(parseFloat(set.price)) || parseFloat(set.price) === 0) {
                                    missing.parts.push({
                                        part: part.id,
                                        version: version.id,
                                        set: set.id,
                                        type: PACKAGE_MISSING_PART_PRICE,
                                    })
                                }

                                if (isNaN(parseFloat(set.amounts)) || parseFloat(set.amounts) === 0) {
                                    missing.parts.push({
                                        part: part.id,
                                        version: version.id,
                                        set: set.id,
                                        type: PACKAGE_MISSING_PART_AMOUNTS,
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return missing
}
