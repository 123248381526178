import React, {Component} from 'react';
import {connect} from "react-redux";
import {translate} from "react-i18next";
import axios from "../../app/config/axios";
import Header from "../../components/layout/Header/Header";
import "./style.scss"
import Button from "../../components/shared/Button/Button";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import Loader from "../../components/shared/Loader/Loader";
import history from "../../routes/history";
import pages from "../../app/consts/routes";
import {userCustomerSettingsUpdate} from "../../store/actions/user.actions";
import {captureException} from "../../utils/captureException";

const CUSTOMERS_UPLOAD_URL = 'garage/customers/customers-upload'

class CustomersUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: null,
            user: this.props.user,
            garage: this.props.garage,
            loader: this.props.loader,
            editModal: false,
            customer: null,
            requestLoader: true,
            selectedFile: null,
            uploadingLoader: false,
            resolvingLoader: false,
        }
    }

    componentDidMount() {
        const {t, user, garage, dispatch} = this.props

        if (!garage.allow_upload_customer) {
            this.shouldRender = false
            history.replace(pages.customers)
        }

        document.title = t('titles.customers_upload')

        window.Echo.private(`user-repair-shop.${user.id}`)
            .listen(`.customers.upload.${garage.id}`, data => {
                dispatch(userCustomerSettingsUpdate(data))

                this.notifyUser(data);
            })
    }

    notifyUser = (data) => {
        const {t} = this.props;
        if (data.uploadProcessing === false && data.successfully) {
            toast.success(<Toast text={t('pages.customers.file_upload_success')} type="success"/>)
        } else if (data.uploadProcessing === false && data.successfully === false) {
            toast.error(<Toast text={t('pages.customers.file_upload_error')} type="error"/>)
        }
    }

    onChangeHandler = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })

        document.getElementById('file_name').innerHTML = ''
        document.getElementById('file_name').innerHTML = event.target.files[0].name
    }


    isLoading = () => {
        const {user} = this.props;
        const {uploadingLoader} = this.state;

        return uploadingLoader || user.settings.customer.uploadProcessing;
    }

    onClickHandler = () => {
        const {t, dispatch} = this.props;
        const data = new FormData()

        if (document.getElementById('customers_upload_file').value == null || document.getElementById('customers_upload_file').value === '') {
            toast.error(<Toast text={t('pages.customers.file_upload_missing')} type="error"/>)
            return
        }

        data.append('file', this.state.selectedFile)
        data.append('repair_shop_id', this.state.garage.id)

        this.setState({
            uploadingLoader: true
        })

        dispatch(userCustomerSettingsUpdate({
            uploadProcessing: true,
            successfully: true,
        }))


        axios.post(CUSTOMERS_UPLOAD_URL, data, {}).then(res => {
            document.getElementById('customers_upload_file').value = null
            this.setState({
                selectedFile: null,
                uploadingLoader: false
            })
            toast.info(<Toast text={t('pages.customers.file_upload_started')} type="info"/>)
            document.getElementById('file_name').innerHTML = ''
            document.getElementById('file_name').innerHTML = t('pages.customers.choose_file')
        }).catch(error => {
            captureException(error, CUSTOMERS_UPLOAD_URL);
            toast.error(<Toast text={t('pages.customers.file_upload_error')} type="error"/>)
            document.getElementById('file_name').innerHTML = ''
            document.getElementById('file_name').innerHTML = t('pages.customers.choose_file')
            window.location.reload();
        })
    }


    renderUploadForm = (uploadingLoader, t) => {
        return (
            <div>
                <Loader isLoading={this.isLoading()} type="loading"/>

                <span
                    className={"customers_upload__loading-message" + (!this.isLoading() ? ' hide' : '')}>{t('pages.customers.loading_upload_message')}</span>

                <div className="customers_upload__file_container">
                    <input
                        accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
                        id="customers_upload_file"
                        className={"customers_upload__file_upload" + (this.isLoading() ? ' hide' : '')}
                        type="file"
                        name="file"
                        onChange={this.onChangeHandler}
                    />
                    <span id="file_name">{t('pages.customers.choose_file')}</span>
                </div>

                <Button onClick={this.onClickHandler} size="md" type="primary" extraClass={"btn-block"}
                        disabled={this.isLoading()}>
                    <div className="font-size-2">{t('pages.customers.upload')}</div>
                </Button>
            </div>
        )
    }

    render() {
        const {t, title} = this.props
        const {uploadingLoader} = this.state

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container customers_upload">
                    {this.renderUploadForm(uploadingLoader, t)}
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: state.garage,
        loader: state.loader,
    }
}

export default connect(mapStateToProps)(translate('translations')(CustomersUpload))
