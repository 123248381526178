import React from 'react'
import axios from '../../app/config/axios'
import {formSubmittingPackagesUpdate, formSubmittingPackageTiresUpdate} from './formSubmitting.actions'
import {__clone} from '../../utils/common'
import i18n from 'i18next'
import language from '../../utils/language'
import store from '../store'
import {toast} from 'react-toastify'
import Toast from '../../components/shared/Toast/Toast'
import i18next from 'i18next'
import {getValueFromArray} from '../../utils/car'
import {TYPE_OFFICIAL, TYPE_TIRES} from '../consts/package/package.type.constants'
import {
    PACKAGES_PAGE_SHOW,
    PACKAGES_ALL_REMOVE,
    PACKAGES_SET,
    PACKAGES_LABOURS_PREDEFINED,
    PACKAGES_SEND_REQUEST,
    PACKAGES_RESET,
    PACKAGES_FILE_ADD,
    PACKAGES_FILE_REMOVE,
} from "../consts/package/package.constants";
import {defaultsDeep} from "lodash";
import packageModel from "../models/package/package";
import {captureException} from "../../utils/captureException";
import {sumPackagePriceTotal} from "../selectors/packagePrices";

const PACKAGES_UPDATE_URL = 'garage/add-cost-tool/package-update'
const PACKAGES_REMOVE_URL = 'garage/add-cost-tool/package-remove'

export const packagesPageShow = () => ({
    type: PACKAGES_PAGE_SHOW,
})

export const packagesSet = packages => ({
    type: PACKAGES_SET,
    payload: {
        packages,
    },
})

export const packagesLaboursPredefined = () => ({
    type: PACKAGES_LABOURS_PREDEFINED,
})

export const packagesSendRequest = (user, offer) => ({
    type: PACKAGES_SEND_REQUEST,
    payload: {
        user,
        offer,
    },
})

export const packagesFileAdd = (pack, file) => ({
    type: PACKAGES_FILE_ADD,
    payload: {
        package: pack,
        file,
    },
})

export const packagesFileRemove = (pack, file) => ({
    type: PACKAGES_FILE_REMOVE,
    payload: {
        package: pack,
        file,
    },
})

export const packagesReset = () => ({
    type: PACKAGES_RESET,
})

export const packagesAllRemove = () => ({
    type: PACKAGES_ALL_REMOVE,
})

export const packagesUpdate = (packages, packageId, labourUpdate = false, labourCustom = false) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(formSubmittingPackagesUpdate(true))

            let _package = packages.find(_package => _package.id == packageId)
            _package.price = sumPackagePriceTotal(_package)

            if (_package.type == TYPE_OFFICIAL) {
                const make = getValueFromArray(getState().car.makes, 'id', getState().car.make_id)
                _package.name = i18next.t('pages.packages.service_package_name', {make: make.name})
            }
            else {
                if (_package.labours.filter(i => i.visible === true).length > 1) {
                    _package.name = i18n.getResource(language(), 'translations', 'pages.packages.package_name')
                } else {
                    _package.name = _package.labours.length ? _package.labours[0].name : _package.name
                }
            }

            axios
                .post(PACKAGES_UPDATE_URL, {
                    package: _package,
                    labour_update: labourUpdate,
                    labour_creating_custom: labourCustom
                })
                .then(response => {
                    dispatch(formSubmittingPackagesUpdate(false))

                    let packages = __clone(getState().packages)

                    if (!packages.find(_package => _package.id === response.data.package.id)) {
                        packages.push(response.data.package)

                        if (getState().garage.packages.max <= packages.length) {
                            toast.info(
                                <Toast
                                    text={i18next.t('pages.packages.max_number', {max: getState().packages.max})}
                                    type="info"
                                />,
                            )
                        }
                    } else {
                        packages = packages.map(_package => {
                            if (_package.id === response.data.package.id) {
                                return response.data.package
                            }
                            return _package
                        })
                    }

                    dispatch(packagesSet(packages))

                    resolve(response.data)
                })
                .catch(error => {
                    captureException(error, PACKAGES_UPDATE_URL)
                    dispatch(formSubmittingPackagesUpdate(false))
                    reject(error)
                })
        })
    }
}

export const packageTiresCreateOrUpdate = (tires, _package = null) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(formSubmittingPackageTiresUpdate(true))

            let packagesClone = __clone(getState().packages), packageAdd;

            if (_package) {
                _package.tires = tires;
                packageAdd = _package;
                packagesClone = packagesClone.map(p => {
                    return p.id === _package.id ? packageAdd : p;
                })
            }
            else {
                packageAdd = defaultsDeep({
                    tires: tires,
                    type: TYPE_TIRES,
                    name: i18n.getResource(language(), 'translations', 'tires.package_name'),
                }, packageModel)

                packagesClone.push(packageAdd)
            }

            return dispatch(packagesUpdate(packagesClone, packageAdd.id, false))
                .then((response) => resolve(response))
                .catch((error) => reject(error))
                .finally(() => {
                    dispatch(formSubmittingPackageTiresUpdate(false))
                })
        })
    }
}

export const packageRemove = (packageId, relations = false) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch(formSubmittingPackagesUpdate(true))

            axios
                .post(PACKAGES_REMOVE_URL, {
                    package: store.getState().packages.find(p => p.id === packageId),
                    relations: relations,
                })
                .then(response => {
                    dispatch(formSubmittingPackagesUpdate(false))
                    dispatch(packagesSet(store.getState().packages.filter(_p => _p.id !== response.data.package.id)))

                    resolve(response.data)
                })
                .catch(error => {
                    captureException(error, PACKAGES_REMOVE_URL);
                    dispatch(formSubmittingPackagesUpdate(false))
                    reject(error)
                })
        })
    }
}
