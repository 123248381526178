import Yup from './yup'
import { LOGO } from '../../../store/consts/garage.constants'
import { changeGarageDetailsSettingUpdate } from '../../../store/actions/change-garage-details.settings.actions'

export default {
    enableReinitialize: true,

    mapPropsToValues({ garage }) {
        return {
            address: garage.address ? garage.address : '',
            web: garage.web ? garage.web : '',
            zip_id: garage.zip.id ? garage.zip.id : '',
            logo: null,
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            address: Yup.string()
                .nullable()
                .label(t('pages.settings.section_form.label.address')),

            web: Yup.string()
                .nullable()
                .label(t('pages.settings.section_form.label.web')),

            zip_id: Yup.string()
                .nullable()
                .label(t('pages.settings.section_form.label.zip_id')),

            logo: Yup.mixed()
                .label(t('pages.settings.section_form.label.logo'))
                .test(
                    'fileSize',
                    t('pages.settings.section_form.file_size'),
                    value => value === null || (value && value.size <= LOGO.FILE_SIZE),
                )
                .test(
                    'fileFormat',
                    t('pages.settings.section_form.file_format'),
                    value => value === null || (value && LOGO.SUPPORTED_FORMATS.includes(value.type)),
                ),
        })
    },

    handleSubmit(values, formikBag) {
        const { dispatch } = formikBag.props

        dispatch(changeGarageDetailsSettingUpdate(values.address, values.web, values.zip_id, values.logo))
    },
}
