import React, {Component} from 'react';
import {translate} from "react-i18next";
import './TiresPickContainer.scss';
import Popup from "../shared/Popup/Popup";
import {TIRES_AXELS, TIRES_STEPS_TO_TYPES} from "../../store/consts/tires.constants";
import {
    tiresAdditionalSelect, tiresAdditionalSelectLabour,
    tiresGet,
    tiresLoadingUpdate,
    tiresReset,
    tiresResetSelectedTires,
    tiresUpdate
} from "../../store/actions/tires.actions";
import {selectTires, selectTiresFormatSelected} from "../../store/selectors/tires";
import {connect} from "react-redux";
import TiresAdditional from "./TiresAdditional";
import ListLoader from "../shared/ListLoader/ListLoader";
import Button from "../shared/Button/Button";

class TiresEdit extends Component {

    componentDidMount() {
        this.getTires();
    }

    getTires = () => {
        const {dispatch} = this.props;

        return new Promise((resolve) => {
            dispatch(tiresGet())
                .then((response) => {
                    dispatch(tiresUpdate(response.data))
                    resolve()
                })
                .finally(() => dispatch(tiresLoadingUpdate(false)))
        })

    }

    resetSelectedTires = (brandType) => this.props.dispatch(tiresResetSelectedTires(brandType))

    tiresAdditionalSelect = (name, checked) => this.props.dispatch(tiresAdditionalSelect(name, checked))
    tiresAdditionalSelectLabour = (name, option) => this.props.dispatch(tiresAdditionalSelectLabour(name, option.id))

    onClose = () => {
        const {onClose, dispatch} = this.props;

        dispatch(tiresReset())
        onClose()
    }

    submit = () => {
        const {onCreate, dispatch} = this.props;

        this.getTires().then(() => {
            onCreate(this.props.tiresFormatSelected)

            dispatch(tiresReset())
        })
    }

    render() {
        const {t, onClose, _package, tires, onTiresEdit} = this.props;

        return (
            <Popup title={'Tires edit'} visible={true} onClose={this.onClose} responsive={true} contentScrollable={true}>

                <div className="tires-pick-container">

                    <>
                        {_package.tires.list.map(tireType => (
                            <div className="tires-selected-edit" key={tireType.type}>
                                <div><span className="link-style edit" onClick={() => onTiresEdit(_package, TIRES_STEPS_TO_TYPES[tireType.type])}><i className="icon-edit"/></span> {tireType.full_name}</div>
                                <ul>
                                    {TIRES_AXELS.filter(axel => tireType[axel]).map(axel => (
                                        <li key={axel}>{t(`tires.axel.${axel}`)}: {tireType[axel].full_axel}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}

                        <TiresAdditional
                            tires={tires}
                            handleChangeAdditionalSelect={this.tiresAdditionalSelect}
                            handleChangeAdditionalSelectLabour={this.tiresAdditionalSelectLabour}
                        />

                        <div className="tires-step-submit">
                            <div className="row">
                                <div className="col-xs-12 text-right">
                                    <span className="link-style next-to-btn-md" onClick={onClose}>{t('global.cancel')}</span>

                                    <Button size="md" type="primary" disabled={tires.loading} loading={tires.loading} onClick={this.submit}>{t('global.save')}</Button>
                                </div>
                            </div>
                        </div>
                    </>

                </div>

            </Popup>
        )
    }
}

const mapStateToProps = state => ({
    tires: selectTires(state),
    tiresFormatSelected: selectTiresFormatSelected(state),
});

export default connect(mapStateToProps)(translate('translations')(TiresEdit));
