import React from 'react'
import { translate } from 'react-i18next'

const PackageItemLabours = ({ list }) => {

  return (
    <div>
      {list.map((labour, key) => (
        <div key={key}>{labour.name}</div>
      ))}
    </div>
  )

}

export default translate('translations')(PackageItemLabours)
