import React, {Component} from 'react';
import {translate} from "react-i18next";
import axios from 'app/config/axios'
import {__clone, parseResponse} from "../../../utils/common";
import Request from "../Requests/Request";
import ListLoader from "../ListLoader/ListLoader";
import {connect} from "react-redux";
import {getDraft, removeDraft} from "../../../store/actions/draft.actions";
import Popup from "../Popup/Popup";
import {LOADER_DRAFT} from "../../../store/consts/loader.constants";
import lodash from "lodash";
import {toast} from "react-toastify";
import {Undo} from "../../hoc/Undo/Undo";
import Loader, {LOADER_LOADING} from "../Loader/Loader";
import Toast from "../Toast/Toast";
import {captureException} from "../../../utils/captureException";

const API_DRAFTS_URL = 'garage/requests/drafts'

const SHOW_LIMIT = 3

class Draft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            limit: SHOW_LIMIT,
            draftsLoader: true,
            usersDrafts: []
        }
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list, usersDrafts} = this.state;

        if(list.length !== prevState.list.length){
            this.setState({
                usersDrafts: usersDrafts.filter(user => list.filter(l => l.created_by.id === user).length > 0)
            })
        }
    }

    fetch = () => {
        axios
            .get(API_DRAFTS_URL)
            .then(response => {
                this.setState({
                    list: response.data,
                    draftsLoader: false,
                })
            })
            .catch(error => {
                captureException(error, API_DRAFTS_URL);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({
                    draftsLoader: false,
                })
            })
    }

    renderLoader = () => {
        const {t, loader} = this.props

        return (
            <Popup title={t('pages.add_cost.draft.title')} visible={loader[LOADER_DRAFT]} hideCancel={true}>
                <div className="mv-30 text-light text-center"><Loader type={LOADER_LOADING} isLoading={true}/> {t('pages.add_cost.draft.loading')}</div>
            </Popup>
        )
    }

    renderUsers = () => {
        const {list, usersDrafts} = this.state;

        let users = lodash.values(lodash.groupBy(list.map(l => l.created_by).flat(1), analytic => analytic.id)).map(i => i[0])

        if(users.length <= 1){
            return null
        }

        return (
            <div className="horizontal-elements">
                {users.map(user => (
                    <div key={user.id} className={`horizontal-elements__item ${usersDrafts.indexOf(user.id) !== -1 ? 'horizontal-elements__item--active' : ''}`}
                         onClick={() => {
                             if(usersDrafts.indexOf(user.id) === -1){
                                 this.setState({usersDrafts: [...usersDrafts, user.id]})
                             }
                             else{
                                 let ids = __clone(usersDrafts)
                                 ids.splice(usersDrafts.indexOf(user.id), 1)
                                 this.setState({usersDrafts: ids})
                             }
                         }}>
                        {user.first_name} {user.last_name}
                    </div>
                ))}
            </div>
        )
    }

    render() {
        const {t, dispatch, title} = this.props;
        const {list, draftsLoader, limit, usersDrafts} = this.state;

        return (
            <div className="relative">
                {draftsLoader ? <ListLoader text={t('pages.add_cost.draft.fetching_list')}/> :
                    <div>
                        {list.length > 0 &&
                            (<div className="package-collapsable">
                                <div className="package-collapsable-header">
                                    <div className="package-collapsable-header__title">{title}</div>
                                </div>
                                <div className={`package-collapsable-content`}>
                                    {this.renderUsers()}

                                    {list.filter(l => usersDrafts.length ? usersDrafts.indexOf(l.created_by.id) !== -1 : l).slice(0, limit).map(draft =>
                                        <Request key={draft.id} request={draft} createdBy={true} logo={false} time={true} onClick={() => dispatch(getDraft(draft.token))}
                                                 onRemove={() => {
                                                     let listFiltered = list.filter(l => l.id !== draft.id);
                                                     this.setState({
                                                         list: listFiltered
                                                     })
                                                     this.props.undo.undoable('draft-remove', () => this.setState({list: [...listFiltered, draft]}), () => dispatch(removeDraft(draft.token)))
                                                 }}
                                                 remove={true}/>)}

                                    {list.filter(l => usersDrafts.length ? usersDrafts.indexOf(l.created_by.id) !== -1 : l).length > SHOW_LIMIT &&
                                    <div className="text-center mt-10">
                                        <span className="link-style font-size-sm" onClick={() => this.setState({limit: list.length === limit ? SHOW_LIMIT : list.length})}>{list.length === limit ? t('pages.add_cost.draft.show_less') : t('pages.add_cost.draft.show_more')}</span>
                                    </div>
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                }

                {this.renderLoader()}
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(Undo(Draft)));
