import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import Phones from "./Phones";
import GarageDetails from "./GarageDetails";
import CustomerSignature from "./CustomerSignature";
import ProfileProgressWidget from "../../components/ProfileProgress/ProfileProgressWidget";
import MastercheckLayout from "./MastercheckLayout/MastercheckLayout";

class SettingsContainer extends Component {
    render() {
        const {t, title} = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">
                    <ProfileProgressWidget
                        showText={true}
                    />

                    <GarageDetails/>

                    <Phones/>

                    {/*{user.type !== USER_ROLE_MECHANIC &&*/}
                    {/*    <TimeDue/>*/}
                    {/*}*/}

                    <CustomerSignature/>

                    <MastercheckLayout/>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader,
        user: state.user,
        garage: state.garage
    };
};

export default connect(mapStateToProps)(translate('translations')(SettingsContainer));
