import {INCREMENT_SYSTEM_MESSAGES_COUNTER, SET_SYSTEM_MESSAGES_COUNTER, DECREMENT_SYSTEM_MESSAGES_COUNTER} from '../actions/systemMessagesCounter.actions'
import systemMessagesCounter from "../models/systemMessagesCounter";

export default function(state = systemMessagesCounter, action) {
    switch (action.type) {
        case SET_SYSTEM_MESSAGES_COUNTER:
            return action.payload.systemMessagesCounter

        case INCREMENT_SYSTEM_MESSAGES_COUNTER:
            return ++state

        case DECREMENT_SYSTEM_MESSAGES_COUNTER:
            return --state

        default:
            return state
    }
}
