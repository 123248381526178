import React from 'react'
import './index.scss'
import Badge from '../../Badge/Badge'
import { translate } from 'react-i18next'
import {
    STATUS_ARCHIVED,
    STATUS_PENDING_MANAGER,
    STATUS_PENDING_USER,
    STATUS_RESPONDED_USER,
    STATUS_RESPONDED_GARAGE,
} from '../../../../store/consts/status.constants'

const RequestStatus = ({ status, t }) => {
    if (!status) {
        return null
    }

    if (status === STATUS_PENDING_MANAGER) {
        return (
            <Badge type="warning" inverted={true}>
                {t('pages.request_details.package.log.status.' + status)}
            </Badge>
        )
    }

    if (status === STATUS_PENDING_USER) {
        return (
            <Badge type="warning-light" inverted={true}>
                {t('pages.request_details.package.log.status.' + status)}
            </Badge>
        )
    }

    if (status === STATUS_RESPONDED_USER) {
        return (
            <Badge type="success" inverted={true}>
                {t('pages.request_details.package.log.status.' + status)}
            </Badge>
        )
    }

    if (status === STATUS_RESPONDED_GARAGE) {
        return (
            <Badge type="success" inverted={true}>
                {t('pages.request_details.package.log.status.' + status)}
            </Badge>
        )
    }

    if (status === STATUS_ARCHIVED) {
        return (
            <Badge type="primary" inverted={true}>
                {t('pages.request_details.package.log.status.' + status)}
            </Badge>
        )
    }

    return (
        <Badge type="primary" inverted={true}>
            {t('pages.request_details.package.log.status.' + status)}
        </Badge>
    )
}

export default translate('translations')(RequestStatus)
