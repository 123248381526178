import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Link} from "react-router-dom";
import pages from "../../../app/consts/routes";
import "./ActivityManagerWaiting.scss";
import ActivityManagerWaitingAction from "./ActivityManagerWaitingAction";

class ActivityManagerWaiting extends Component {

    render() {
        const {t, refresh, notify, showActions} = this.props;

        return (
            <div className="activity-item" key={notify.id}>
                <div className="activity-item__sent">
                    <div className="activity-item__time">{notify.at.time}</div>
                    <div className="activity-item__user">{t('pages.activity.type.manager_waiting.name', {first_name: notify.created_by.first_name, last_name: notify.created_by.last_name})}</div>
                    <div className="activity-item__message">
                        {t('pages.activity.type.manager_waiting.body')}
                    </div>
                    <div className="activity-item__bottom">
                        <Link to={`${pages.request_details}${notify.offer_token}`}>{t('pages.activity.type.details')}</Link>
                    </div>

                    {showActions &&
                        <ActivityManagerWaitingAction
                            activity={notify}
                            approved={(request) => refresh(request)}
                            rejected={() => refresh()}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default translate('translations')(ActivityManagerWaiting);
