import React, {Component} from 'react';
import {connect} from "react-redux";
import {toast} from "react-toastify";
import Toast from "../shared/Toast/Toast";
import {translate} from "react-i18next";
import store from "../../store/store";
import {seenOutOfDateMessage} from "../../store/actions/appVersion.actions";

const INITIAL_VERSION = '1.0'

class AppVersionUpToDateInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appVersion: props.appVersion
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {appVersion} = this.props

        if (prevState.appVersion !== appVersion && appVersion.responseVersion !== INITIAL_VERSION) {
            if (Boolean(appVersion.seen) !== true && (appVersion.localVersion !== appVersion.responseVersion)) {
                this.showOutOfDateToast()
                store.dispatch(seenOutOfDateMessage())
            }
            this.setState({
                appVersion: appVersion
            })
        }
    }

    showOutOfDateToast = () => {
        const {t} = this.props;

        return (
            toast.info(<Toast text={t('global.app_version_out_of_date_info')} type="app_version"/>)
        )
    }

    render() {
        return (
            <></>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
        appVersion: state.appVersion
    };
};

export default connect(mapStateToProps)(translate('translations')(AppVersionUpToDateInfo));


