import React, {Component} from 'react';
import {translate} from 'react-i18next';
import BaseCountry from "../../utils/BaseCountry";
import {getActivity, setActivity} from "../../utils/activity";
import EmptyList from "../../components/shared/EmptyList/EmptyList";
import axios from "../../app/config/axios";
import axiosMain from "axios";
import {captureException} from "../../utils/captureException";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse} from "../../utils/common";
import {ANALYTICS_ENHANCED_EXPORT} from "./AnalyticsEnhancedTopGaragesContainer";
import Loader from "../../components/shared/Loader/Loader";
import {LOADER_INIT} from "../../store/consts/loader.constants";

class TopByAccepted extends Component {

    constructor(props) {
        super(props);

        this.state = {
            garages: props.list,
            collapse: getActivity('analytics.top-by-revenue.collapsed', false),
            loadingExport: false,
        }
    }

    collapse = () => {
        this.setState({
            collapse: !this.state.collapse
        }, () => {
            setActivity('analytics.top-by-revenue.collapsed', this.state.collapse)
        })
    }

    download = () => {
        this.setState({
            loadingExport: !this.state.loadingExport
        });

        axios.post(ANALYTICS_ENHANCED_EXPORT, {
            from: (new Date(this.props.from)).getTime() / 1000,
            to: (new Date(this.props.to)).getTime() / 1000
        }).then((response) => {
            window.open(response.data.url);
        }).catch((error) => {
            if (!axiosMain.isCancel(error)) {
                captureException(error, ANALYTICS_ENHANCED_EXPORT);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({loading: false})
            }
        }).finally(() => {
            this.setState({
                loadingExport: !this.state.loadingExport
            });
        })
    }

    render() {
        const {garages, collapse, loadingExport} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <div className="analytics-export" onClick={this.download}>
                    <Loader isLoading={loadingExport} type={LOADER_INIT}/>
                    {t('pages.analytics_enhanced.export')}
                </div>

                <div className="analytic-tab analytic-tab-top-by-revenue">

                    <div className="analytic-tab-header" onClick={this.collapse}>
                        <div>
                            <span>{t('pages.analytics_enhanced.lists.top_10_by_revenue')}</span>
                            <span className="chevron"><i className="icon-chevron-down"/></span>
                        </div>
                    </div>

                    {!collapse && (garages.length > 0 ?
                        <div className="analytic-tab-content analytic-tab-content--expandable">

                            <div className="analytic-tab-content__package-stat">
                                <div className="row">
                                    <div className="col-xs-3"/>

                                    <div className="col-xs-2 analytic-tab-content__package-stat__item">
                                        <span className="value header">
                                            {t('pages.analytics_enhanced.column_names.checklists')}
                                        </span>
                                    </div>

                                    <div className="col-xs-2 analytic-tab-content__package-stat__item">
                                        <span className="value header">
                                            {t('pages.analytics_enhanced.column_names.accepted')}
                                        </span>
                                    </div>

                                    <div className="col-xs-3 analytic-tab-content__package-stat__item">
                                        <span className="value header">
                                            {t('pages.analytics_enhanced.column_names.total')}
                                        </span>
                                    </div>

                                    <div className="col-xs-2 analytic-tab-content__package-stat__item">
                                        <span className="value header">
                                            {t('pages.analytics_enhanced.column_names.percentage')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {garages.map((garage, key) => {
                                return (
                                    <div key={key} className="analytic-tab-content__package-stat">
                                        <div className="row">
                                            <div className="col-xs-3">
                                                <div className="label">
                                                    #{(key + 1)} {garage.name}
                                                </div>
                                            </div>

                                            <div
                                                className="col-xs-2 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--success">
                                                <span className="value">{garage.checklists}</span>
                                            </div>

                                            <div
                                                className="col-xs-2 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--success">
                                                <span className="value">
                                                    {garage.accepted} {BaseCountry().currency}
                                                </span>
                                            </div>
                                            <div
                                                className="col-xs-3 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--danger">
                                                <span className="value">{garage.sum} {BaseCountry().currency}</span>
                                            </div>
                                            <div
                                                className="col-xs-2 analytic-tab-content__package-stat__item analytic-tab-content__package-stat__item--warning">
                                                <span className="value">{garage.percentage}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <EmptyList relative={true} label={t('global.no_data')}/>)
                    }
                </div>


            </React.Fragment>
        );
    }
}

export default translate('translations')(TopByAccepted)
