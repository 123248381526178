export const COUNTRY = '[COUNTRY]'

export const COUNTRY_SET = `${COUNTRY} SET`

export const countrySet = country => ({
    type: COUNTRY_SET,
    payload: {
        country,
    },
})
