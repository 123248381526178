import React, {Component} from 'react';
import {translate} from 'react-i18next';
import "./ActivitySmsUserReply.scss";

class ActivitySmsUserReply extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notify: props.notify
        };
    }

    render() {
        const {t} = this.props;
        const {notify} = this.state;

        return (
            <div className="activity-item" key={notify.id}>
                <div className="activity-item__received">
                    <div className="activity-item__time">{notify.at.time}</div>
                    <div className="activity-item__user">{t('pages.activity.type.sms_user_reply.name', {first_name: notify.user.first_name, last_name: notify.user.last_name})}</div>
                    <div className="activity-item__message activity-item__sms-user">
                        {notify.sms.user.body}
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('translations')(ActivitySmsUserReply);
