import React, {Component} from 'react'
import {connect} from 'react-redux'
import {changeStepData} from 'store/actions/step.actions'
import {translate} from 'react-i18next'
import {setNavigationTitle} from 'utils/setNavigationTitle'
import CarDetail from 'components/CarDetail/CarDetail'
import Header from 'components/layout/Header/Header'
import './PackagesContainer.scss'
import {Link} from 'react-router-dom'
import Popup from 'components/shared/Popup/Popup'
import {intersect, parseResponse} from 'utils/common'
import pages from 'app/consts/routes'
import {removeDeep} from 'utils/common'
import {packageRemove, packageTiresCreateOrUpdate} from 'store/actions/packages.actions'
import {packagesUpdate} from 'store/actions/packages.actions'
import {sumFixedPrice} from '../../store/selectors/packagePrices'
import {PackagesStep} from '../../routes/middleware/Packages/PackagesStep'
import {HasOfferToken} from '../../routes/middleware/HasOfferToken'
import {missingInfo} from '../../store/selectors/missingInfo'
import {packagesFileAdd, packagesFileRemove, packagesLaboursPredefined} from '../../store/actions/packages.actions'
import Confirm from '../../components/shared/Confirm/Confirm'
import PackageItemLabours from '../../components/PackageItemLabours/'
import {__clone, deepObject} from "../../utils/common";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import Button from "../../components/shared/Button/Button";
import OfficialService from "../LabourSearchContainer/OfficialService/OfficialService";
import {
    TYPE_OFFICIAL, TYPE_TIRES,
    TYPE_TO_DELETE_ALL
} from "../../store/consts/package/package.type.constants";
import UrgencyLevel from "../PackageDetailsContainer/UrgencyLevel";
import {checklistLaboursFetch} from "../../store/actions/checklistLabours.actions";
import {haxMaxPackages} from "../../store/selectors/package";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import history from "../../routes/history";
import {tiresEditPrepare, tiresSet} from "../../store/actions/tires.actions";
import TiresPickContainer from "../../components/Tires/TiresPickContainer";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import PackagePrice from "../../components/PackageComponents/PackagePrice";
import PackageSelectedTires from "./components/PackageSelectedTires";
import {captureException} from "../../utils/captureException";
import {selectChecklistLabours} from "../../store/selectors/checklistLabours";
import TiresEdit from "../../components/Tires/TiresEdit";

class PackagesContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            car: this.props.car,
            packages: this.props.packages,
            extendPackageShow: null,
            extendPackageProperties: [
                {
                    name: 'urgency',
                    selected: false,
                },
                {
                    name: 'files',
                    selected: false,
                },
            ],
            extendPackageProperty: null,
            extendPackageListShow: null,
            tiresOverviewEditShow: null,
            tiresEditShow: null,
            confirm: null,
            officialServiceShow: false,
            tiresCreateShow: false,

        }

        this.packageAddNew = React.createRef();
    }

    componentDidMount() {
        const {t, dispatch, checklistLabours, garage} = this.props

        document.title = t('titles.packages')
        setNavigationTitle(t('pages.packages.title'))

        dispatch(changeStepData(2))

        dispatch(packagesLaboursPredefined())

        if (garage.allow_checklist && !checklistLabours.length) {
            dispatch(checklistLaboursFetch());
        }

        this.scrollAddNewPackageIntoView()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {extendPackageShow, extendPackageProperties, extendPackageProperty} = this.state

        if (extendPackageShow !== null) {
            let property = extendPackageProperties.find(item => item.selected === false)
            if (property) {
                if (!extendPackageProperty || extendPackageProperty.name !== property.name) {
                    this.setState({
                        extendPackageProperty: property
                    })
                }
            } else {
                this.setState({
                    extendPackageShow: null,
                    extendPackageProperty: null,
                    extendPackageProperties: __clone(extendPackageProperties).map(item => {
                        return {...item, selected: false};
                    })
                });
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop],
                })
            }
        })
    }

    scrollAddNewPackageIntoView() {
        setTimeout(() => this.packageAddNew.current ? this.packageAddNew.current.scrollIntoView() : null, 100)
    }

    handleEditPackage(e, key) {
        e.preventDefault()
        this.setState({extendPackageListShow: key})
    }

    handleExtendPackage(e, key) {
        e.preventDefault()

        this.setState({extendPackageShow: key})
    }

    handleExtendClose = (key) => {
        const {extendPackageProperties} = this.state

        this.setState({
            extendPackageProperties: __clone(extendPackageProperties).map(item => {
                if (item.name === key) {
                    return {...item, selected: true};
                }
                return item;
            })
        })
    }

    renderExtendPackageFiles = () => {
        const {t, dispatch} = this.props
        const {extendPackageShow, packages, extendPackageProperty} = this.state

        if (extendPackageProperty && extendPackageProperty.name !== 'files') return null

        if (!packages[extendPackageShow]) return null

        let pack = packages[extendPackageShow];

        return (
            <Popup
                title={t('pages.package_details.files.title')}
                subtitle={pack.name}
                visible={extendPackageShow !== null}
                onClose={() => this.handleExtendClose(extendPackageProperty.name)}
            >
                <FileUpload
                    files={pack.files}
                    formData={{
                        packageId: pack.id
                    }}
                    onAddFile={(file) => dispatch(packagesFileAdd(pack, file))}
                    onRemoveFile={(file) => dispatch(packagesFileRemove(pack, file))}
                    button={(uploading) => (
                        <div className="mt-30">
                            <Button size="lg" type="primary" disabled={!!!pack.files.length || uploading}
                                    onClick={() => this.handleExtendClose(extendPackageProperty.name)}>
                                {t(`pages.price_update.section_form.${uploading ? 'button_uploading' : 'button_save'}`)}
                            </Button>
                        </div>
                    )}
                />

            </Popup>
        )
    }

    renderExtendUrgencyLevel = () => {
        const {t} = this.props
        const {extendPackageProperty, extendPackageShow, packages} = this.state

        if (extendPackageProperty && extendPackageProperty.name !== 'urgency') return null

        if (!packages[extendPackageShow]) return null

        let pack = packages[extendPackageShow];

        return (
            <Popup
                title={t('pages.package_details.urgency.title')}
                subtitle={t('pages.package_details.urgency.subtitle')}
                visible={extendPackageShow !== null}
                onClose={() => this.handleExtendClose(extendPackageProperty.name)}
            >
                <UrgencyLevel
                    package={pack}
                    packagePath={`id:${pack.id}`}
                    onClose={() => this.handleExtendClose(extendPackageProperty.name)}
                />
            </Popup>
        )
    }

    tiresCreateShow = () => {
        this.setState({tiresCreateShow: true})
    }

    tiresOverviewEditShow = (pack) => {
        const {dispatch} = this.props;

        dispatch(tiresEditPrepare(pack.tires))
        this.setState({tiresOverviewEditShow: pack})
    }

    tiresEditShow = (pack, step) => {
        const {dispatch} = this.props;

        dispatch(tiresSet('brand_list', []))
        dispatch(tiresSet('step', step))

        this.setState({
            tiresEditShow: pack,
            tiresOverviewEditShow: null,
        })
    }

    renderList = () => {
        const {packages} = this.state;
        const {t, formSubmitting} = this.props;

        if (packages.length === 0) {
            return <div
                className="text-lighter mt-20 mb-50 font-weight-light text-center">{t('pages.packages.empty_list')}</div>
        }

        return packages.map((pack, key) => {
            const missingData = missingInfo(pack)

            return (
                <div key={key} className="package-item">
                    <div className="package-item-content">
                        <Link className="package-item-content__title" to={`${pages.package_details}${pack.id}`}>
                            <span>
                                {pack.name}
                                {pack.officialService.mileage && t('pages.packages.official_package_mileage', {mileage: pack.officialService.mileage})}
                            </span>
                            <span>
                                <i className="icon-chevron-right"/>
                              </span>
                        </Link>
                        {(pack.labours.filter(i => i.visible === true).length > 1 || pack.type === TYPE_OFFICIAL) &&
                            <div className="package-item-content__parts">
                                <PackageItemLabours packId={pack.id} list={pack.labours.filter(i => i.visible === true)}/>
                            </div>
                        }

                        {pack.type === TYPE_TIRES &&
                            <PackageSelectedTires _package={pack} loading={formSubmitting.packageTire}/>
                        }

                        {/*{TYPE_TO_APPEND_LABOUR.indexOf(pack.type) !== -1 &&*/}
                        {/*<div className="mt-10">*/}
                        {/*    <Button size="sm" type="secondary">*/}
                        {/*        <Link to={`${pages.packages}${pages.labour_search}?package=${pack.id}`}>*/}
                        {/*            {t('pages.packages.add_item')}*/}
                        {/*        </Link>*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        {/*}*/}

                        {pack.type === TYPE_OFFICIAL &&
                        <div className="mt-10">
                            <Button size="sm" type="secondary">
                                <Link to={pages.official_service}>
                                    {t('pages.packages.edit_official')}
                                </Link>
                            </Button>
                        </div>
                        }

                        {pack.type === TYPE_TIRES &&
                        <div className="mt-10">
                            <Button size="sm" type="secondary" onClick={() => this.tiresOverviewEditShow(pack)}>
                                {t('pages.packages.edit_tires')}
                            </Button>
                        </div>
                        }

                        {sumFixedPrice(pack.labours) === 0 && (missingData.works.length || missingData.parts.length) ? (
                            <div className="missing-info">
                                <i className="icon-warrning-fill"/> {t('pages.packages.missing_works_parts')}
                            </div>
                        ) : null}

                        <div className="package-item-content__total">
                            <span>{t('pages.packages.total_incl_vat')}:</span>
                            <span><PackagePrice pack={pack} fullPrice={true}/></span>
                        </div>
                    </div>
                    <div className="package-item-footer">
                        <button onClick={e => this.handleExtendPackage(e, key)} className="col-xs-6">
                            <i className="icon-file"/> {t('pages.packages.extend_properties')}
                        </button>
                        {TYPE_TO_DELETE_ALL.indexOf(pack.type) === -1 ?
                            <button onClick={e => this.handleEditPackage(e, key)} className="col-xs-6">
                                <i className="icon-edit"/> {t('pages.packages.edit')}
                            </button>
                            :
                            <button onClick={() => this.removePackage(pack.id)} className="col-xs-6">
                                <i className="icon-bin"/> {t('pages.packages.remove')}
                            </button>
                        }

                    </div>
                </div>
            )
        })
    }

    renderExtendPackageList = () => {
        const {extendPackageListShow, packages} = this.state
        const {t, formSubmitting} = this.props

        if (!packages[extendPackageListShow]) return null

        return (
            <Popup
                title={t('pages.price_update.section_form.add_price')}
                subtitle={packages[extendPackageListShow].name}
                visible={extendPackageListShow !== null}
                onClose={() => this.setState({extendPackageListShow: null})}
            >
                <div className="extend-package-list mb-30">
                    {packages[extendPackageListShow].labours.filter(i => i.visible === true).map((labour, key) => (
                        <div key={key} className="extend-package-list__item">
                            <span>{labour.name}</span>
                            {!formSubmitting.packages ? (
                                <span
                                    className="link-style edit-link"
                                    onClick={() => this.removeLabour(packages[extendPackageListShow].id, labour.itemMpId)}>
                                    <i className="icon-bin"/> {t('pages.packages.remove')}
                                </span>
                            ) : (
                                <span className="text-lighter">{t('pages.package_details.removing')}</span>
                            )}
                        </div>
                    ))}
                </div>
            </Popup>
        )
    }

    async confirmRemove() {
        return await new Promise((resolve, reject) => this.setState({confirm: {resolve, reject}}))
    }

    removeLabour = (packageId, labourId) => {
        const {dispatch} = this.props;
        const {extendPackageListShow} = this.state;

        this.confirmRemove()
            .then(() => {
                this.setState({confirm: null})

                let packages = this.state.packages;
                let labours = deepObject(packages, `id:${packageId}/labours`)

                labours.filter(l => l.parentId === labourId).map((labour) => {
                    packages = removeDeep(packages, `id:${packageId}/labours/parentId:${labourId}`)
                })

                dispatch(packagesUpdate(
                    removeDeep(packages, `id:${packageId}/labours/itemMpId:${labourId}`),
                    packageId,
                    true,
                ))
                    .then(() => {
                        if (extendPackageListShow !== null) {
                            this.setState({extendPackageListShow: null})
                        }

                        if (this.state.packages.find(p => p.id === packageId).labours.length === 0) {
                            dispatch(packageRemove(this.state.packages.find(p => p.id === packageId).id))
                        }
                    })
            })
            .catch(error => {
                captureException(error);
                this.setState({confirm: null})
            })
    }

    removePackage = (packageId) => {
        this.confirmRemove()
            .then(() => {
                this.setState({confirm: null})

                this.props.dispatch(packageRemove(packageId, true));
            })
            .catch(error => {
                captureException(error);
                this.setState({confirm: null})
            })
    }

    render() {
        const {car, packages, confirm, officialServiceShow, tiresEditShow, tiresOverviewEditShow, tiresCreateShow} = this.state
        const {t, title, garage, haxMaxPackages, dispatch} = this.props

        return (
            <React.Fragment>
                <Header title={t(title)}/>

                <div className="container container--has-submit-container">
                    <div className="mb-30">
                        <CarDetail car={car} editable={true}/>
                    </div>

                    <div className="package-collapsable">
                        <div className="package-collapsable-header mb-10">
                            <div
                                className="package-collapsable-header__title">{t('pages.packages.selected_packages')}</div>
                        </div>

                        <div className="package-collapsable-content">
                            {this.renderList()}
                        </div>
                    </div>

                    <div className="row">
                        {garage.allow_checklist ?
                            <>
                                <div className="col-xs-12">
                                    <Link to={pages.checklist_labour}
                                          className={"btn btn-lg btn-primary btn-primary--outline btn-round--sm btn-shadow font-size-default"}>
                                        <span><i className="icon-check"/> {t('pages.packages.checklist')}</span>
                                    </Link>

                                </div>
                                {(!haxMaxPackages && (garage.allow_official_service || garage.allow_labour)) &&
                                <div className="col-xs-12 text-center mv-10">
                                    <span>{t('pages.packages.or')}</span>
                                </div>
                                }
                            </>
                            : null}

                        {!haxMaxPackages &&
                        <>
                            {(garage.allow_labour) &&
                            <div
                                className={`${(garage.allow_official_service && !packages.find(pack => pack.type === TYPE_OFFICIAL)) ? 'col-xs-6' : 'col-xs-12'}`}>
                                <Link to={`${pages.packages}${pages.labour_search}`}
                                      className="package-add-new btn btn-md btn-primary mb-15 btn-primary--outline btn-round--sm btn-shadow btn-block font-size-default">
                                    <span ref={this.packageAddNew}>{t('pages.packages.add_new_labour')}</span>
                                </Link>
                            </div>
                            }
                            <div className={`${(garage.allow_labour) ? 'col-xs-6' : 'col-xs-12'}`}>
                                {(garage.allow_official_service && !packages.find(pack => pack.type === TYPE_OFFICIAL)) &&
                                <Button size="md" type="primary"
                                        extraClass={"mb-15 btn-primary--outline btn-round--sm btn-shadow btn-block font-size-default"}
                                        onClick={() => this.setState({officialServiceShow: true})}>
                                    {t('pages.packages.add_new_official_service')}
                                </Button>
                                }
                            </div>
                        </>
                        }

                        {(garage.allow_tires_create && !packages.find(pack => pack.type === TYPE_TIRES)) &&
                            <div className="col-xs-12 col-sm-6">
                                <Button size="md" type="primary"
                                        extraClass={"mb-15 btn-primary--outline btn-round--sm btn-shadow btn-block font-size-default"}
                                        onClick={this.tiresCreateShow}>
                                    {t('pages.packages.add_tires')}
                                </Button>
                            </div>
                        }
                    </div>

                </div>

                {this.renderExtendPackageList()}
                {this.renderExtendUrgencyLevel()}
                {this.renderExtendPackageFiles()}

                <SubmitContainer>
                    <Button size="lg" type="primary" extraClass={"btn-block"} disabled={!!!packages.length}
                            onClick={() => history.push(pages.preview)}>
                        <div className="font-size-2">{t('pages.packages.next')}</div>
                        {/*<div className="font-size-xs font-weight-regular">
                            {t('pages.packages.total')} {fixedFloat(sumPackagesPriceTotal(packages))} {BaseCountry().currency}
                        </div>*/}
                    </Button>
                </SubmitContainer>

                {confirm && (
                    <Confirm
                        title={t('pages.packages.confirm_remove.title')}
                        subtitle={t('pages.packages.confirm_remove.subtitle')}
                        visible={!!confirm}
                        accept={() => confirm.resolve(true)}
                        cancel={() => confirm.reject(false)}
                    />
                )}

                {officialServiceShow &&
                    <OfficialService
                        editOnCreate={true}
                        show={officialServiceShow}
                        onClose={() => this.setState({officialServiceShow: false})}
                        onSuccess={() => this.setState({officialServiceShow: false})}
                        onError={() => this.setState({officialServiceShow: false})}
                    />
                }

                {tiresOverviewEditShow &&
                    <TiresEdit
                        _package={tiresOverviewEditShow}
                        onCreate={tires => {
                            dispatch(packageTiresCreateOrUpdate(tires, {...tiresOverviewEditShow}))
                                .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
                            this.setState({tiresOverviewEditShow: false})
                        }}
                        onTiresEdit={(_package, step) => this.tiresEditShow(_package, step)}
                        onClose={() => this.setState({tiresOverviewEditShow: null})}
                    />
                }

                {tiresEditShow &&
                    <TiresPickContainer
                        title={tiresEditShow.name}
                        onCreate={tires => {
                            dispatch(packageTiresCreateOrUpdate(tires, {...tiresEditShow}))
                                .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
                            this.setState({tiresEditShow: null})
                        }}
                        onClose={() => this.setState({tiresEditShow: null})}
                    />
                }

                {tiresCreateShow &&
                    <TiresPickContainer
                        title={t('tires.package_name')}
                        onCreate={tires => {
                            dispatch(packageTiresCreateOrUpdate(tires))
                                .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
                            this.setState({tiresCreateShow: false})
                        }}
                        onClose={() => this.setState({tiresCreateShow: false})}
                    />
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        packages: state.packages,
        formSubmitting: state.formSubmitting,
        laboursPredefined: state.laboursPredefined,
        garage: state.garage,
        checklistLabours: selectChecklistLabours(state),
        haxMaxPackages: haxMaxPackages(state),
        offer: state.offer,
    }
}

export default connect(mapStateToProps)(PackagesStep(HasOfferToken(translate('translations')(PackagesContainer))))
