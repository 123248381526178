import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import InputRadio from "components/shared/InputRadio";
import {compare} from "utils/common";
import {mastercheckLayoutSettingUpdate} from "../../../store/actions/mastercheckLayout.settings.actions";
import {setGarage} from "../../../store/actions/garage.actions";
import ChecklistLayout, {
    MASTERCHECK_LAYOUT_CHECKBOXES,
    MASTERCHECK_LAYOUT_TOGGLE
} from "../../../components/ChecklistLabour/components/ChecklistLayout";
import "./MastercheckLayout.scss";

class MastercheckLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            layout: null,
            changed: 0,
            demoButtons: [
                {
                    id: MASTERCHECK_LAYOUT_CHECKBOXES,
                    passed: true,
                    updating: false,
                    disabled: props.garage.mastercheck_layout !== MASTERCHECK_LAYOUT_CHECKBOXES,
                },
                {
                    id: MASTERCHECK_LAYOUT_TOGGLE,
                    passed: true,
                    updating: false,
                    disabled: props.garage.mastercheck_layout !== MASTERCHECK_LAYOUT_TOGGLE,
                }
            ]
        }
    }

    componentDidMount() {
        const garageLayout = this.props.garage.mastercheck_layout;
        this.props.layouts.map((layout) => {
            if (parseInt(layout.id) === garageLayout) {
                this.setState({
                    layout: layout.id
                })
            }
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const garageLayout = this.props.garage.mastercheck_layout;

        if (compare(nextProps.layouts, this.props.layouts)) {
            nextProps.layouts.map(layout => {
                if (parseInt(layout.id) === garageLayout) {
                    this.setState({
                        layout: layout.id
                    })
                }
            })
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        const {garage} = this.props;

        if (e.target instanceof HTMLInputElement) {
            if (e.target.type === 'radio') {
                this.setState({
                    [name]: value
                });
                const newDemoButtons = this.state.demoButtons.map((demoButton) => {
                    demoButton.disabled = true;
                    if(demoButton.id === parseInt(value)){
                        demoButton.disabled = false;
                    }
                    return demoButton
                })
                this.setState({demoButtons: newDemoButtons})
                this.props.dispatch(mastercheckLayoutSettingUpdate(garage.id, value))

                garage.mastercheck_layout = parseInt(value);
                this.props.dispatch(setGarage(garage))
            }
        }
    };

    handleDemoChange = (passed, id) => {
        const newDemoButtons = this.state.demoButtons.map((demoButton) => {
            if(demoButton.id === id){
                demoButton.passed = passed;
            }
            return demoButton
        })
        this.setState({demoButtons: newDemoButtons})
        this.setState({ changed: this.state.changed + 1})
    }

    checkboxHeader = (layout) => {
        const {t} = this.props;
        if(layout === MASTERCHECK_LAYOUT_CHECKBOXES){
            return <div className="checklist-labour-header">
                <div className="col-xs-3 mastercheck-options">{t('pages.checklist_labours.ok')}</div>
                <div className="col-xs-3 mastercheck-options">{t('pages.checklist_labours.not')} {t('pages.checklist_labours.ok')}</div>
            </div>
        }
        return <></>
    }

    render() {
        const {t, layouts} = this.props;

        return (
            <React.Fragment>

                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.settings.section_tabs.mastercheck_layout')}</div>
                    </div>
                    <div className="settings-tab__content">

                        {layouts?.map((layout, key) => {
                            return (
                                <div key={key} className="col-xs-12 mastercheck-options-wrapper">
                                    <div className="mt-20 col-xs-4" key={key}>
                                        <InputRadio
                                            key={key}
                                            id={`${layout.name}-${key}`}
                                            name={"layout"}
                                            value={layout.id}
                                            label={t(layout.name)}
                                            checked={parseInt(this.state.layout) === parseInt(layout.id)}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className={"col-xs-8 mastercheck-layout-" + layout.name.toLowerCase()}>
                                        {this.checkboxHeader(layout.id)}
                                        <ChecklistLayout
                                            classes={"col-xs-3 mastercheck-options"}
                                            passed={this.state.changed}
                                            dummyComponent={true}
                                            layoutType={layout.id}
                                            name={"checklist-labour"}
                                            handleChange={this.handleDemoChange}
                                            labour={this.state.demoButtons[key]}
                                        />
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layouts: state.mastercheckLayout,
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(MastercheckLayout));
