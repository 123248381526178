const pages = {
    home: '/',
    price_update: '/price-update',
    price_update_hourly_prices: '/price-update/hourly-prices',
    price_update_oil_prices: '/price-update/oil-prices',
    price_update_liquids_prices: '/price-update/liquids-prices',
    price_update_discount: '/price-update/discount',
    price_update_additional_percent_on_works: '/price-update/additional-percent-on-works',
    price_update_tire_work_prices: '/price-update/tire-work-prices',
    settings: '/settings',
    user_profile: '/user-profile',

    add_cost: '/add-cost',
    packages: '/packages',
    package_details: '/package/',
    labour_search: '/labour-search',
    preview: '/preview',
    confirm: '/confirm',
    book_now_confirm: '/book-now-confirm',

    login: '/login',
    pickGarage: '/pick-garage',
    forgetPassword: '/reset',
    forgetPasswordNew: '/reset-new',

    requests: '/requests',
    request_details: '/request/',
    request_package_details: '/package/',
    request_checklist: '/checklist',
    mechanics: '/mechanics',
    checklists: '/checklists',
    reporting: '/reporting',
    analytics: '/analytics',

    activities: '/activities',
    activity: '/activity/',

    customers: '/customers',
    customers_upload: '/customers/upload',

    checklist_labour: '/checklist-labours',

    official_service: '/official-service',

    analytics_enhanced: '/analytics-enhanced',
    analytics_enhanced_packages: '/analytics-enhanced/packages',
    analytics_enhanced_checklist: '/analytics-enhanced/checklist',
    analytics_enhanced_top_labours: '/analytics-enhanced/top-labours',
    analytics_enhanced_top_cars: '/analytics-enhanced/top-cars',
    analytics_enhanced_top_garages: '/analytics-enhanced/top-garages',

    system_messages: '/system-messages',

    tires_import: '/tires/import',
}

export default pages
