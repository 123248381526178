import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import "./AnalyticsEnhancedContainer.scss";
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {__clone, firstDayOfMonth, lastDayOfMonth, parseResponse} from "../../utils/common";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import axiosMain from "axios";
import {captureException} from "../../utils/captureException";
import DateFilter from "./DateFilter";
import TopByAccepted from "./TopByAccepted";

export const ANALYTICS_ENHANCED_FETCH_URL = 'garage/analytics/enhanced/top-garages';
export const ANALYTICS_ENHANCED_EXPORT = 'garage/analytics/enhanced/top-garages/export';

class AnalyticsEnhancedTopGaragesContainer extends Component {

    state = {
        garages: [],
        initialLoaded: false,
        loading: false,
        date: {
            from: new Date(firstDayOfMonth()),
            to: new Date(lastDayOfMonth()),
        }
    }

    fetch = () => {
        const {date} = this.state;

        this.setState({
            loading: true,
            initialLoaded: true
        })

        axios.post(ANALYTICS_ENHANCED_FETCH_URL, {
            from: (new Date(date.from)).getTime() / 1000,
            to: (new Date(date.to)).getTime() / 1000
        }).then((response) => {
            this.groupByGarages(response.data)
        }).catch((error) => {
            if (!axiosMain.isCancel(error)) {
                captureException(error, ANALYTICS_ENHANCED_FETCH_URL);
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                this.setState({loading: false})
            }
        })
    }

    groupByGarages = (list) => {
        this.setState({
            loading: false,
            initialLoaded: true,
            garages: list,
        })
    }

    render() {
        const {t} = this.props;
        const {garages, loading, initialLoaded, date} = this.state;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)}/>

                <div className="container">

                    <div className="analytic-enhanced-date-picker">
                        <DateFilter from={date.from}
                                    to={date.to}
                                    filter={(from, to) => this.setState({date: {from, to}}, () => this.fetch())}/>
                        {/*<MonthFilter filter={(from, to) => this.prepareAndFetch(from, to)}/>*/}
                    </div>

                    {initialLoaded && (loading ? <ListLoader/> :
                        <TopByAccepted from={date.from} to={date.to} list={__clone(garages)}/>)}

                </div>
            </React.Fragment>
        );
    }
}

export default (translate('translations')(AnalyticsEnhancedTopGaragesContainer));
