import React, {Component} from 'react';
import {translate} from 'react-i18next';
import InputCheckbox from "../../../components/shared/InputCheckbox";
import DatePicker from "react-datepicker";
import "./AppointmentDiscount.scss";
import {__clone, compare, fixedFloat} from "../../../utils/common";
import {
    rangedSumPackagesTotalIncludeDiscount,
    sumPackagesTotalIncludeDiscount
} from "../../../store/selectors/packagePrices";
import { registerLocale } from  "react-datepicker";
import language from "../../../utils/language";
import DatePickerLocales from '../../../app/plugins/DatePicker/locale';
import BaseCountry from "../../../utils/BaseCountry";
import Popup from "../../../components/shared/Popup/Popup";
import DiscountEdit from "./DiscountEdit";
import TotalPrice from "../../../components/PackageComponents/TotalPrice";

const locale = language();
registerLocale(locale, DatePickerLocales[locale])

class AppointmentDiscount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAppointments: false,
            showEdit: false,
            appointments: [
                {
                    checked: false,
                    timestamp: this.addDays(1),
                    discount: 0,
                    is_today: true,
                },
                {
                    checked: false,
                    timestamp: this.addDays(3),
                    discount: props.discount.discount,
                    is_today: false,
                },
                {
                    checked: false,
                    timestamp: this.addDays(5),
                    discount: props.discount.discount,
                    is_today: false,
                },
                {
                    checked: false,
                    timestamp: this.addDays(7),
                    discount: props.discount.discount,
                    is_today: false,
                },
            ]
        };
    }

    componentDidMount() {
        const {selectedAppointments} = this.props;
        const {appointments} = this.state;

        if(selectedAppointments && selectedAppointments.length){
            let appointmentsClone = __clone(appointments);

            selectedAppointments.forEach((item, key) => {
                appointmentsClone[key].checked = true;
                appointmentsClone[key].timestamp = selectedAppointments[key].timestamp * 1000;
            })

            this.setState({
                showAppointments: true,
                appointments: appointmentsClone,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {onChange} = this.props;
        const {showAppointments, appointments} = this.state;

        if(compare(prevState.appointments, appointments)) {
            onChange(
                __clone(appointments).filter(appointment => appointment.checked === true)
                    .map(appointment => {
                        appointment.timestamp = Math.round(appointment.timestamp / 1000);
                        return appointment;
                    })
            );
        }

        if(prevState.showAppointments === true && showAppointments === false){
            this.setState({appointments: __clone(appointments).map(appointment => {
                    appointment.checked = false;

                    return appointment;
                })
            });
        }
    }

    addDays = (days) => {
        let date = new Date();
        date.setMinutes(0);
        date.setSeconds(0);

        let timestamp = date.getTime() + (86400 * 1000 * days);

        if((new Date(timestamp)).getDay() === 0){
            timestamp = date.getTime() + (86400 * 1000 * (days + 1));
        }

        return timestamp;
    }

    updateDiscount = (value) => {
        const {appointments} = this.state;

        this.setState({
            appointments: __clone(appointments).map(app => {
                if(!app.is_today){
                    app.discount = value
                }

                return app;
            }),
            showEdit: false,
        })
    }

    render() {
        const {t, packages, discount} = this.props;
        const {showAppointments, appointments, showEdit} = this.state;

        return (
            <React.Fragment>
                <div className="appointments-container">
                    <InputCheckbox
                        id="show_appointments"
                        name="show_appointments"
                        value={showAppointments}
                        onChange={(e) => this.setState({showAppointments: e.target.checked})}
                        checked={showAppointments}
                        label={t('pages.request_preview.appointment_discounts.show_appointments')}
                    />

                    {showAppointments &&
                        <div className="appointments-list">
                            <hr/>
                            <div className="row mb-15 appointments-header">
                                <div className="col-xs-2">{'\u00A0'}</div>
                                <div className="col-xs-4">{t('pages.request_preview.appointment_discounts.header.termin')}</div>
                                <div className="col-xs-3 link-style line-height-inherit" onClick={() => this.setState({showEdit: true})}>
                                    <i className="icon-edit"/> {t('pages.request_preview.appointment_discounts.header.discount')}
                                </div>
                                <div className="col-xs-3">{t('pages.request_preview.appointment_discounts.header.total')}</div>
                            </div>

                            {appointments.map((appointment, key) => {
                                return (
                                    <div className={`row appointments-item ${appointment.checked ?? 'appointments-item--disabled'}`} key={key}>
                                        <div className="col-xs-2 text-left">
                                            <InputCheckbox
                                                id="show_appointments"
                                                name="show_appointments"
                                                value={appointment.checked}
                                                onChange={(e) => {
                                                    let appointmentsClone = __clone(appointments);
                                                    appointmentsClone[key].checked = e.target.checked;
                                                    this.setState({appointments: appointmentsClone});
                                                }}
                                                checked={appointment.checked}
                                                label=""
                                            />
                                        </div>
                                        <div className="col-xs-4">
                                            {appointment.is_today ?
                                                <div>{t('pages.request_preview.appointment_discounts.list.is_today')}</div>
                                                :
                                                <DatePicker
                                                    locale={locale}
                                                    selected={new Date(appointment.timestamp)}
                                                    onChange={datetime => {
                                                        let appointmentsClone = __clone(appointments);
                                                        appointmentsClone[key].timestamp = datetime.getTime();
                                                        this.setState({appointments: appointmentsClone});
                                                    }}
                                                    showTimeSelect
                                                    timeIntervals={30}
                                                    className="time-picker-custom"
                                                    timeClassName={() => "time-picker-time-custom"}
                                                    disabled={!appointment.checked}
                                                    timeFormat="HH:mm"
                                                    dateFormat="d.M.yyyy HH:mm"
                                                    minDate={new Date()}
                                                    filterTime={filterPassedTime}
                                                    filterDate={filterSunday}
                                                    disabledKeyboardNavigation
                                                />
                                            }
                                        </div>
                                        <div className="col-xs-3">{appointment.is_today ? '-' : appointment.discount + '%'}</div>
                                        <div className="col-xs-3"><TotalPrice sumPriceData={rangedSumPackagesTotalIncludeDiscount(packages, appointment.discount)}/></div>
                                    </div>
                                )
                            })}
                        </div>
                    }

                </div>

                <Popup title={t('pages.request_preview.appointment_discounts.edit.title')}
                       subtitle={t('pages.request_preview.appointment_discounts.edit.tip')}
                       visible={showEdit} onClose={() => this.setState({showEdit: false})}>
                    <DiscountEdit
                        discount={discount}
                        onUpdate={this.updateDiscount}
                    />
                </Popup>
            </React.Fragment>
        );
    }
}

const filterPassedTime = time => {
    const selectedDate = new Date(time);

    return selectedDate.getHours() > 6 && selectedDate.getHours() < 21;
}

const filterSunday = (date) => {
    return new Date(date).getDay() !== 0;
}


export default translate('translations')(AppointmentDiscount);
