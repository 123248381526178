import Yup from './yup'

export default {
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            phone: '',
            id: null,
        }
    },

    validationSchema: props => {
        const { t } = props

        return Yup.object().shape({
            phone: Yup.string()
                .required()
                .label(t('pages.settings.section_form.label.notification_phone')),
        })
    },
}
