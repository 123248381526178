import {
    SYSTEM_MESSAGE_APPEND,
    SYSTEM_MESSAGE_SET,
    SYSTEM_MESSAGE_SEEN,
    SYSTEM_MESSAGE_APPENDED_CLEAR
} from '../actions/systemMessage.actions'
import systemMessage from "../models/systemMessage";

export default function(state = systemMessage, action) {
    switch (action.type) {
        case SYSTEM_MESSAGE_SET:
            return action.payload.systemMessage

        case SYSTEM_MESSAGE_APPEND:
            return [
                ...state,
                ...action.payload.systemMessage
            ]

        case SYSTEM_MESSAGE_SEEN:
            return state.map(item=>{
                if(item.id===action.payload.messageId){
                    return {
                        ...item,
                        seen: true
                    }
                }

                return item
            })

        case SYSTEM_MESSAGE_APPENDED_CLEAR:
            return []

        default:
            return state
    }
}
