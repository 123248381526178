import {setStepDataActiveOne, STEP, STEP_CHANGE} from 'store/actions/step.actions';
import {apiRequest} from 'store/actions/api.actions';

export const STEP_URL = 'garage/add-cost-tool/steps';

export const stepMiddleware = ({dispatch}) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case STEP_CHANGE:
                dispatch(setStepDataActiveOne(action.payload));
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: STEP_URL,
                        signature: STEP
                    })
                );
                break;

            default:
                break;
        }
    };
};
