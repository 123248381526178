export const OFFER = '[Offer]'

export const OFFER_SET = `${OFFER} SET`
export const OFFER_RESET = `${OFFER} RESET`

export const offerSet = offer => ({
  type: OFFER_SET,
  payload: {
    offer,
  },
})

export const offerReset = () => ({
  type: OFFER_RESET,
})
