export const LANGUAGE_SETTING = '[LANGUAGE SETTING]';

export const LANGUAGE_SETTING_SET = `${LANGUAGE_SETTING} SET`;
export const LANGUAGE_SETTING_UPDATE = `${LANGUAGE_SETTING} UPDATE`;

export const languageSettingSet = (languages) => ({
    type: LANGUAGE_SETTING_SET,
    payload: {
        languages
    }
});

export const languageSettingUpdate = (languageId) => ({
    type: LANGUAGE_SETTING_UPDATE,
    payload: {
        languageId
    }
});


