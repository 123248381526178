import * as Sentry from "@sentry/react";
import store from "../store/store";

const ignoreExceptionUrls = ['garage/add-cost-tool/steps'];

export const captureException = (error, url = '') => {
    try {


        if (process.env.REACT_APP_SENTRY_DSN) {
            if (url === '' || (url && !ignoreExceptionUrls.includes(url))) {
                Sentry.withScope(scope => {
                    let wholeStore = Object.assign({}, store.getState());
                    wholeStore.client = {};
                    wholeStore.user = {};
                    wholeStore.garage = {};
                    wholeStore.notifications = {};
                    wholeStore.recipients = {};
                    wholeStore.mechanics = {};

                    scope.setExtra('state', JSON.stringify(wholeStore));
                    if (url) {
                        scope.setExtra('apiRequestedUrl', url);
                    }
                    Sentry.captureException(error)
                })
            }
        }
    } catch (exception) {

    }
}

