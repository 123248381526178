export const selectPackagesState = state => state.packages;

export const findPackage = (state, packageId) => {
    return state.packages.find(_package => _package.id == packageId)
}

export const availableWorks = _package => {
    let works = {}
    _package.labours.map(labour => {
        works[`id:${_package.id}/labours/itemMpId:${labour.itemMpId}`] = []

        return labour.works.map(work => {
            if (work.selected === true) {
                works[`id:${_package.id}/labours/itemMpId:${labour.itemMpId}`].push(work)
            }
        })
    })

    return works
}

export const availableParts = _package => {
    let parts = {}
    _package.labours.map(labour =>
        labour.works.map(work =>
            work.selected === true
                ? work.parts.map(part => {
                      parts[
                          `id:${_package.id}/labours/itemMpId:${labour.itemMpId}/works/id:${work.id}/parts/id:${part.id}`
                      ] = part
                  })
                : null,
        ),
    )

    return parts
}

export const haxMaxPackages = state => {
    return state.garage.packages.max <= state.packages.length
}
