import React, {Component} from 'react';
import {translate} from "react-i18next";
import TiresAxel from "./TiresAxel";
import {connect} from "react-redux";
import './TiresPickContainer.scss';
import {
    tiresAxelDimensionUpdate,
    tiresAxelSameDimensionSet, tiresAdditionalSelect, tiresAdditionalSelectLabour,
    tiresGet, tiresLoadingUpdate, tiresReset, tiresResetSelectedTires, tiresSelectTire,
    tiresSet, tiresSortUpdate, tiresStepUpdate,
    tiresUpdate, tiresAxelDimensionReset
} from "../../store/actions/tires.actions";

import {
    TIRES_STEP_AXEL,
    TIRES_STEP_LIST_PREMIUM, TIRES_STEP_LIST_PRICE_QUALITY, TIRES_STEPS,
    TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPE_BRAND_PRICE_QUALITY
} from "../../store/consts/tires.constants";
import TiresSelect from "./TiresSelect";
import {get} from 'lodash';
import {selectTires, selectTiresFormatSelected, selectTiresValidateSteps} from "../../store/selectors/tires";
import Popup from "../shared/Popup/Popup";
import {compare} from "../../utils/common";

export const TIRES_CONTAINER_CALLED_FROM_CHECKLIST = 'checklist';

class TiresPickContainer extends Component {

    componentWillUnmount() {
        const {dispatch} = this.props;

        dispatch(tiresReset())
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {tires, tiresValidateSteps} = this.props;

        let toCompareFront = ['axel.front.wide', 'axel.front.depth', 'axel.front.diameter'];
        let toCompareRear = ['axel.rear.wide', 'axel.rear.depth', 'axel.rear.diameter'];

        if (compare(nextProps.tires.axel, this.props.tires.axel)) {
            for (let path of toCompareFront) {
                if (get(tires, path) !== get(nextProps.tires, path)) {
                    this.getTires()
                    break;
                }
            }
        }

        if (!nextProps.tires.sameDimensions) {
            for (let path of toCompareRear) {
                if (get(tires, path) !== get(nextProps.tires, path)) {
                    this.getTires()
                    break;
                }
            }
        }

        if (tires.step > parseInt(Object.keys(tiresValidateSteps).find(step => tiresValidateSteps[step] === false))) {
            this.goToStep(parseInt(Object.keys(tiresValidateSteps).find(step => tiresValidateSteps[step] === false)))
        }
    }

    getTires = () => {
        const {dispatch} = this.props;

        dispatch(tiresGet())
            .then((response) => {

                dispatch(tiresUpdate(response.data))

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => dispatch(tiresLoadingUpdate(false)))
    }

    updateTires = (path, value) => this.props.dispatch(tiresSet(path, value))

    setSameDimensions = () => this.props.dispatch(tiresAxelSameDimensionSet())

    resetSelectedTires = (brandType) => this.props.dispatch(tiresResetSelectedTires(brandType))

    goToStep = (step) => {
        const {onCreate, dispatch, tiresFormatSelected} = this.props;

        if (TIRES_STEPS.indexOf(step) !== -1) {
            dispatch(tiresStepUpdate(step, true))
        } else if (step > TIRES_STEPS[TIRES_STEPS.length - 1]) {
            onCreate(tiresFormatSelected)
        }
    }

    axelDimensionUpdate = (axel, name, value) => this.props.dispatch(tiresAxelDimensionUpdate(axel, name, value))
    axelDimensionReset = (axel) => this.props.dispatch(tiresAxelDimensionReset(axel))

    selectTire = (tire, brandType, axels) => this.props.dispatch(tiresSelectTire(tire, brandType, axels))

    tiresAdditionalSelect = (name, checked) => this.props.dispatch(tiresAdditionalSelect(name, checked))
    tiresAdditionalSelectLabour = (name, id) => this.props.dispatch(tiresAdditionalSelectLabour(name, id))

    sortUpdate = (column) => this.props.dispatch(tiresSortUpdate(column));

    render() {
        const {t, tires, tiresValidateSteps, title, onClose, calledFrom} = this.props;

        return (
            <Popup
                title={(
                    <>
                        <span className={tires.step > TIRES_STEP_AXEL ? `font-weight-light` : ''}>{title}</span>
                        {tires.step > TIRES_STEP_AXEL &&
                        <span> - {t('tires.step_title.' + tires.step)}</span>
                        }
                    </>
                )}
                visible={true}
                onClose={onClose}
                responsive={true}
                contentScrollable={true}
            >
                <div className="checklist-labour-tires">
                    <div className="tires-pick-container">
                        {tires.step === TIRES_STEP_AXEL &&
                        <TiresAxel tires={tires} goToStep={this.goToStep} onGet={this.getTires}
                                   onUpdate={this.updateTires}
                                   axelDimensionUpdate={this.axelDimensionUpdate}
                                   axelDimensionReset={this.axelDimensionReset}
                                   setSameDimensions={this.setSameDimensions} validateSteps={tiresValidateSteps}
                                   resetSelectedTires={this.resetSelectedTires}
                                   tiresAdditionalSelect={this.tiresAdditionalSelect}
                                   tiresAdditionalSelectLabour={this.tiresAdditionalSelectLabour}
                                   calledFrom={calledFrom}/>
                        }
                        {tires.step === TIRES_STEP_LIST_PREMIUM &&
                        <TiresSelect tires={tires} goToStep={this.goToStep} onGet={this.getTires}
                                     onUpdate={this.updateTires}
                                     selectTire={this.selectTire}
                                     brandType={TIRES_TYPE_BRAND_PREMIUM} validateSteps={tiresValidateSteps}
                                     resetSelectedTires={this.resetSelectedTires} sortUpdate={this.sortUpdate}/>
                        }
                        {tires.step === TIRES_STEP_LIST_PRICE_QUALITY &&
                        <TiresSelect tires={tires} goToStep={this.goToStep} onGet={this.getTires}
                                     onUpdate={this.updateTires}
                                     selectTire={this.selectTire}
                                     brandType={TIRES_TYPE_BRAND_PRICE_QUALITY} validateSteps={tiresValidateSteps}
                                     resetSelectedTires={this.resetSelectedTires} sortUpdate={this.sortUpdate}/>
                        }
                    </div>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = state => ({
    tires: selectTires(state),
    tiresValidateSteps: selectTiresValidateSteps(state),
    tiresFormatSelected: selectTiresFormatSelected(state),
});

export default connect(mapStateToProps)(translate('translations')(TiresPickContainer));
