import { STEP_SET_DATA, STEP_SET_DATA_ACTIVE_ONE } from 'store/actions/step.actions';
import step from 'store/models/step';

export default function(state = step, action) {
    switch (action.type) {
        case STEP_SET_DATA:
            return action.payload;

        case STEP_SET_DATA_ACTIVE_ONE:
            return {
                numberOfSteps: state.numberOfSteps,
                activeStep: action.payload.activeStep.step
            };

        default:
            return state;
    }
}
