import React, {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {setNavigationTitle} from 'utils/setNavigationTitle'
import Header from 'components/layout/Header/Header'
import {intersect} from 'utils/common'
import './PackageDetailsContainer.scss'
import {PackageDetailsCheck} from 'routes/middleware/PackageDetails/PackageDetailsCheck'
import Popup from 'components/shared/Popup/Popup'
import UrgencyLevel from './UrgencyLevel'
import AvailableWorks from './Work/AvailableWorks'
import {compare} from 'utils/common'
import {findPackage} from 'store/selectors/package'
import AvailableParts from './Parts/AvailableParts'
import {removeDeep} from 'utils/common'
import {packagesUpdate} from 'store/actions/packages.actions'
import history from 'routes/history'
import pages from 'app/consts/routes'
import {packageRemove} from 'store/actions/packages.actions'
import Description from './Description'
import {
    packageDiscount, brakeDiscount, getDiscount
} from 'store/selectors/packagePrices'
import Confirm from '../../components/shared/Confirm/Confirm'
import {availableParts, availableWorks} from "../../store/selectors/package";
import Discount from './Discount'
import {partDiscount} from "../../store/selectors/packagePrices";
import {deepObject} from "../../utils/common";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import {packagesFileAdd, packagesFileRemove} from "../../store/actions/packages.actions";
import {TYPE_TO_DELETE_ALL} from "../../store/consts/package/package.type.constants";
import PackageUrgency from "../../components/shared/PackageUrgency";
import Button from "../../components/shared/Button/Button";
import {DISCOUNT_BRAKES_TYPE, DISCOUNT_PARTS_TYPE} from "../../store/consts/discounts.constants";
import BaseCountry from "../../utils/BaseCountry";
import PackagePrice from "../../components/PackageComponents/PackagePrice";
import AvailableData from "./AvailableData";
import {captureException} from "../../utils/captureException";
import Info from "../OfficialServiceEnrichContainer/Info/Info";
import {TYPE_OFFICIAL} from '../../store/consts/package/package.type.constants'
import CustomLabours from "./CustomLabours/CustomLabours";

class PackageDetailsContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            package: this.props.package,
            packages: this.props.packages,
            urgencyLevelShow: false,
            descriptionShow: false,
            discountShow: false,
            availableWorksShow: false,
            availablePartsShow: false,
            customLaboursShow: false,
            filesShow: false,
            confirm: null,
            labourInfo: null,
        }
    }

    componentDidMount() {
        const {t} = this.props

        document.title = t('titles.package_details')
        setNavigationTitle(t('pages.package_details.title'))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch} = this.props

        if (compare(prevState.package.labours, this.state.package.labours) && this.state.package.labours.length === 0) {
            dispatch(packageRemove(this.state.package.id))
            history.replace(pages.packages)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (compare(nextProps[prop], this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop],
                })
            }
        })
    }

    async confirmRemove() {
        return await new Promise((resolve, reject) => this.setState({confirm: {resolve, reject}}))
    }

    removeLabour = (packageId, labourId) => {
        this.confirmRemove()
            .then(() => {
                this.setState({confirm: null})

                let packages = this.state.packages;
                let labours = deepObject(packages, `id:${packageId}/labours`)

                labours.filter(l => l.parentId === labourId).forEach(() => {
                    packages = removeDeep(packages, `id:${packageId}/labours/parentId:${labourId}`)
                })

                this.props.dispatch(packagesUpdate(
                    removeDeep(packages, `id:${packageId}/labours/itemMpId:${labourId}`),
                    packageId,
                    true,
                ))
            })
            .catch(error => {
                captureException(error);
                this.setState({confirm: null})
            })
    }

    renderUrgencyLevel = () => {
        const {t} = this.props
        const {urgencyLevelShow} = this.state

        if (!urgencyLevelShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.urgency.title')}
                subtitle={t('pages.package_details.urgency.subtitle')}
                visible={urgencyLevelShow}
                onClose={() => this.setState({urgencyLevelShow: false})}
            >
                <UrgencyLevel
                    package={this.state.package}
                    packagePath={`id:${this.state.package.id}`}
                    onClose={() => this.setState({urgencyLevelShow: false})}
                />
            </Popup>
        )
    }

    renderDiscount = () => {
        const {t} = this.props
        const {discountShow} = this.state

        if (!discountShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.discount.title')}
                visible={discountShow}
                onClose={() => this.setState({discountShow: false})}
            >
                <Discount
                    discount={this.state.package.discount}
                    packagePath={`id:${this.state.package.id}`}
                    onClose={() => this.setState({discountShow: false})}
                />
            </Popup>
        )
    }

    renderDescription = () => {
        const {t} = this.props
        const {descriptionShow} = this.state

        if (!descriptionShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.description.title')}
                visible={descriptionShow}
                onClose={() => this.setState({descriptionShow: false})}
            >
                <Description
                    description={this.state.package.description}
                    packagePath={`id:${this.state.package.id}`}
                    onClose={() => this.setState({descriptionShow: false})}
                />
            </Popup>
        )
    }

    renderAvailableWorks = () => {
        const {t} = this.props
        const {availableWorksShow} = this.state

        if (!availableWorksShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.labour.works.title')}
                visible={availableWorksShow}
                onClose={() => this.setState({availableWorksShow: false})}
            >
                <AvailableWorks works={availableWorks(this.state.package)} packagePath={`id:${this.state.package.id}`}/>
            </Popup>
        )
    }

    renderAvailableParts = () => {
        const {t} = this.props
        const {availablePartsShow} = this.state

        if (!availablePartsShow) {
            return
        }

        return (
            <Popup
                title={t('pages.package_details.labour.parts.title')}
                visible={availablePartsShow}
                onClose={() => this.setState({availablePartsShow: false})}
            >
                <AvailableParts
                    parts={availableParts(this.state.package)}
                    packagePath={`id:${this.state.package.id}`}
                    onClose={() => this.setState({availablePartsShow: false})}
                />
            </Popup>
        )
    }

    renderCustomLabours = () => {
        const {customLaboursShow} = this.state

        if (!customLaboursShow) return

        return (
            <CustomLabours
                _package={this.state.package}
                onClose={() => this.setState({customLaboursShow: false})}
            />
        )
    }

    renderExtendPackageFiles = () => {
        const {t, dispatch} = this.props
        const {filesShow} = this.state

        if (!filesShow) return null

        return (
            <Popup
                title={t('pages.package_details.files.title')}
                visible={filesShow}
                onClose={() => {
                    this.setState({filesShow: false})
                }}
            >
                <FileUpload
                    files={this.state.package.files}
                    formData={{
                        packageId: this.state.package.id
                    }}
                    onAddFile={(file) => dispatch(packagesFileAdd(this.state.package, file))}
                    onRemoveFile={(file) => dispatch(packagesFileRemove(this.state.package, file))}
                />

            </Popup>
        )
    }

    showInfo = (e, service) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({labourInfo: service})
    }

    parts = (service) => {
        if (service.works.find(work => /^\-\d+$/.test(work.id))) {
            return service.works.find(work => /^\-\d+$/.test(work.id)).parts.filter(part => part.selected);
        }

        return [];
    }

    render() {
        const {confirm, labourInfo} = this.state
        const {t, title, formSubmitting, garage} = this.props

        return (
            <React.Fragment>
                <Header title={t(title)} onBack={() => history.replace(pages.packages)}/>

                <div className="container container--has-submit-container">
                    <div className="package-collapsable">
                        <div className="package-collapsable-header mb-10">
                            <div className="package-collapsable-header__title">
                                {this.state.package.name}
                                {this.state.package.officialService.mileage && t('pages.package_details.official_package_mileage', {mileage: this.state.package.officialService.mileage})}
                            </div>
                        </div>
                        <div className="package-collapsable-content">
                            {this.state.package.labours.filter(i => i.visible === true).map((labour, key) => (
                                <div key={key} className="extend-package-list__item">
                                    <span className="text-lighter">{labour.name}</span>
                                    {TYPE_TO_DELETE_ALL.indexOf(this.state.package.type) === -1 &&
                                    (
                                        !formSubmitting.packages ? (
                                            <span className="link-style"
                                                  onClick={() => this.removeLabour(this.state.package.id, labour.itemMpId)}
                                            >
                                        <i className="icon-bin"/> {t('pages.package_details.remove')}
                                      </span>
                                        ) : (
                                            <span className="text-lighter">{t('pages.package_details.removing')}</span>
                                        )
                                    )
                                    }

                                    {(this.state.package.type === TYPE_OFFICIAL && Boolean(labour.description)) &&
                                    <div className="official-services-single__name__right">
                                        <span className="link-style" onClick={(e) => this.showInfo(e, labour)}>
                                            <i className="icon-info-fill"/>
                                        </span>
                                    </div>
                                    }
                                </div>
                            ))}

                            {/*{TYPE_TO_APPEND_LABOUR.indexOf(this.state.package.type) !== -1 &&*/}
                            {/*<Link to={`${pages.packages}${pages.labour_search}?package=${this.state.package.id}&return`}*/}
                            {/*      className="btn btn-sm btn-secondary">*/}
                            {/*    {t('pages.packages.add_new_labour')}*/}
                            {/*</Link>*/}
                            {/*}*/}
                        </div>
                    </div>

                    <div className="package-collapsable">
                        <div className="package-collapsable-header">
                            <div
                                className="package-collapsable-header__title">{t('pages.package_details.level_of_urgency')}</div>
                        </div>
                        <div className="package-collapsable-content">
                            <div className="extend-package-list__item">
                                <PackageUrgency urgency={this.state.package.urgency}/>
                                <span className="link-style" onClick={() => this.setState({urgencyLevelShow: true})}>
                          <i className="icon-edit"/> {t('pages.package_details.change')}
                        </span>
                            </div>
                        </div>
                    </div>

                    <div className="package-collapsable">
                        <div className="package-collapsable-header">
                            <div
                                className="package-collapsable-header__title">{t('pages.package_details.description.item')}</div>
                        </div>
                        <div className="package-collapsable-content">
                            <div className="extend-package-list__item">
                        <span className="text-lighter">
                          {this.state.package.description
                              ? this.state.package.description
                              : t('pages.package_details.description.not_added')}
                        </span>
                                <span className="link-style" onClick={() => this.setState({descriptionShow: true})}>
                          <i className="icon-edit"/> {t('pages.package_details.add_edit')}
                        </span>
                            </div>
                        </div>
                    </div>

                    <div className="package-collapsable">
                        <div className="package-collapsable-header">
                            <div
                                className="package-collapsable-header__title">{t('pages.package_details.file_uploads')}</div>
                        </div>
                        <div className="package-collapsable-content">
                            <FileUpload
                                files={this.state.package.files}
                                formData={{
                                    packageId: this.state.package.id
                                }}
                                onAddFile={(file) => this.props.dispatch(packagesFileAdd(this.state.package, file))}
                                onRemoveFile={(file) => this.props.dispatch(packagesFileRemove(this.state.package, file))}
                                edit={false}
                            />
                            <div className="text-right">
                        <span className="link-style edit-link"
                              onClick={() => this.setState({filesShow: !this.state.filesShow})}>
                          <i className="icon-edit"/> {t('pages.package_details.add_edit')}
                        </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-30">
                        <AvailableData
                            _package={this.state.package}
                           openAvailableWorks={() => this.setState({availableWorksShow: true})}
                           openAvailableParts={() => this.setState({availablePartsShow: true})}
                           openCustomLabours={() => this.setState({customLaboursShow: true})}/>
                    </div>
                    <hr className="hr hr--dashed mb-10"/>
                    <div>
                        <div className="extend-package-list">
                            <div className="extend-package-list__item">
                                <span>{t('pages.package_details.total')}</span>
                                <span className="bold"><PackagePrice pack={this.state.package} /></span>
                            </div>
                            {(getDiscount(DISCOUNT_PARTS_TYPE) && partDiscount(this.state.package) > 0) ? (
                                <div className="extend-package-list__item">
                                  <span>
                                    {t('pages.package_details.discount_on_parts')}
                                  </span>
                                    <span className="bold">{getDiscount(DISCOUNT_PARTS_TYPE).discount}%</span>
                                </div>
                            ) : null}
                            {(getDiscount(DISCOUNT_BRAKES_TYPE) && brakeDiscount(this.state.package) > 0) ? (
                                <div className="extend-package-list__item">
                                  <span>
                                    {t('pages.package_details.discount_on_brakes')}
                                  </span>
                                    <span
                                        className="bold">{getDiscount(DISCOUNT_BRAKES_TYPE).discount}%</span>
                                </div>
                            ) : null}
                            {packageDiscount(this.state.package) ? (
                                <div className="extend-package-list__item">
                                  <span>
                                    {t('pages.package_details.discount_package')}
                                    {(this.state.package.discount.name !== null && this.state.package.discount.name !== '') &&
                                        <span className="text-lighter"> ({this.state.package.discount.name})</span>
                                    }
                                  </span>
                                    <span className="link-style edit-link font-size-default"
                                          onClick={() => this.setState({discountShow: true})}>
                                    <i className="icon-edit"/>{' '}
                                        {packageDiscount(this.state.package)} {BaseCountry().currency}
                                  </span>
                                </div>
                            ) : null}
                            <div className="extend-package-list__item">
                                <span className="bold">{t('pages.package_details.total_all')}</span>
                                <span className="bold total-border"><PackagePrice pack={this.state.package} fullPrice={true}/></span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20">
                        <Button size="sm" type="secondary" onClick={() => this.setState({discountShow: true})}>
                            + {t('pages.package_details.add_discount')}
                        </Button>
                    </div>
                </div>

                {this.renderUrgencyLevel()}
                {this.renderDescription()}
                {this.renderDiscount()}
                {this.renderAvailableWorks()}
                {this.renderAvailableParts()}
                {this.renderCustomLabours()}
                {this.renderExtendPackageFiles()}

                {confirm && (
                    <Confirm
                        title={t('pages.package_details.confirm_edit.title')}
                        subtitle={t('pages.package_details.confirm_edit.subtitle')}
                        visible={!!confirm}
                        accept={() => confirm.resolve(true)}
                        cancel={() => confirm.reject(false)}
                    />
                )}

                {labourInfo &&
                <Info
                    title={labourInfo.name}
                    visible={!!labourInfo}
                    content={
                        <ul><li>{labourInfo.description}</li></ul>
                    }
                    onClose={() => this.setState({labourInfo: null})}
                />
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        car: state.car,
        garage: state.garage,
        formSubmitting: state.formSubmitting,
        package: findPackage(state, props.match.params.package),
        packages: state.packages,
    }
}

export default connect(mapStateToProps)(PackageDetailsCheck(translate('translations')(PackageDetailsContainer)))
