import Yup from './yup'
import {
    AVATAR,
    USER_ROLE_MECHANIC,
    USER_ROLE_MECHANIC_ADVANCED,
    USER_INACTIVE,
} from '../../../store/consts/user.constants'

export default {
    enableReinitialize: true,

    mapPropsToValues({ initialValues = {} }) {
        return {
            avatar: null,
            avatarPath: initialValues.avatar,
            type: initialValues.type || USER_ROLE_MECHANIC,
            first_name: initialValues.first_name || '',
            last_name: initialValues.last_name || '',
            email: initialValues.email || '',
            phone: initialValues.phone || '',
            active: initialValues.active || USER_INACTIVE,
            password: '',
            username: initialValues.username || '',
        }
    },

    validationSchema: props => {
        const { t, excludePasswordValidation } = props

        const validationWithoutPassword = {
            avatar: Yup.mixed()
                .label(t('validations.label.avatar'))
                .test(
                    'fileSize',
                    t('pages.settings.section_form.file_size'),
                    value => value === null || (value && value.size <= AVATAR.FILE_SIZE),
                )
                .test(
                    'fileFormat',
                    t('pages.settings.section_form.file_format'),
                    value => value === null || (value && AVATAR.SUPPORTED_FORMATS.includes(value.type)),
                )
                .test('avatar', t('validations.default.mixed.required'), function() {
                    return !!(
                        this.parent.type != USER_ROLE_MECHANIC_ADVANCED ||
                        (this.parent.avatarPath || this.parent.avatar)
                    )
                }),

            type: Yup.string()
                .required()
                .label(t('validations.label.type')),

            first_name: Yup.string()
                .required()
                .label(t('validations.label.firstname')),

            last_name: Yup.string()
                .required()
                .label(t('validations.label.lastname')),

            email: Yup.string()
                .email()
                .label(t('validations.label.email'))
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function() {
                    return this.parent.phone || this.parent.email
                }),

            phone: Yup.number()
                .test('oneOfRequired', t('pages.request_preview.section_form.oneOfRequired'), function() {
                    return this.parent.phone || this.parent.email
                })
                .label(t('validations.label.phone')),

            active: Yup.string()
                .required()
                .label(t('validations.label.activity')),
        }

        const validationPassword = {
            password: Yup.string()
                .required()
                .label(t('validations.label.password')),
        }

        const validation = excludePasswordValidation
            ? validationWithoutPassword
            : { ...validationWithoutPassword, ...validationPassword }

        return Yup.object().shape(validation)
    },

    handleSubmit(values, formikBag) {
        formikBag.props.onSubmit(values)
    },
}
