import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Button from "components/shared/Button/Button";
import {usePrevious} from "components/hooks/utils";
import {updateObject} from "utils/common";
import {packagesUpdate} from "store/actions/packages.actions";
import TextArea from "components/shared/TextArea/TextArea";
import {deepObject} from "utils/common";
import {parseDeepPath} from "../../utils/common";
import {withFormik} from "formik";
import {formDescription} from "../../app/config/yup";

export const Description = props => {

    const {t, packages, formSubmitting, packagePath, dispatch, onClose, touched, values, errors, isValid, handleBlur, handleChange} = props;

    const prevPackages = usePrevious(props.packages);

    useEffect(() => {
        if (prevPackages) {
            onClose();
        }
    }, [props.packages]);

    let submit = (e) => {
        e.preventDefault();

        if (isValid) {
            dispatch(packagesUpdate(updateObject(packages, {...deepObject(packages, packagePath), ...{description: values.description}}, packagePath), parseDeepPath(packagePath)[0].id));
        }
    };

    return (
        <React.Fragment>

            <form onSubmit={submit}>
                <div className="mb-20">
                    <TextArea
                        type="text"
                        placeholder={t('pages.package_details.labour.works.section_form.placeholder.description')}
                        id="description"
                        name="description"
                        label={t('pages.package_details.labour.works.section_form.label.description')}
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && errors.description}
                    />
                </div>

                <Button disabled={formSubmitting.packages} size="lg"
                        type="primary">{t('pages.price_update.section_form.button_save')}</Button>

            </form>
        </React.Fragment>
    );
};

const DescriptionForm = withFormik(formDescription)(Description);


const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
        packages: state.packages,
    };
};

export default connect(mapStateToProps)(translate('translations')(DescriptionForm));
