import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import TextArea from "../../components/shared/TextArea/TextArea";
import {withFormik} from "formik";
import {formCustomerSignature} from "../../app/config/yup";
import Button from "../../components/shared/Button/Button";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";

class CustomerSignature extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: null
        }
    }

    render() {
        const {t, garage, handleChange, handleBlur, touched, errors, handleSubmit, formSubmitting} = this.props;

        return (
            <React.Fragment>

                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.settings.section_tabs.customer_signature')}</div>
                    </div>
                    <div className="settings-tab__content">

                        <form onSubmit={handleSubmit}>
                            <TextArea
                                type="text"
                                placeholder={t('pages.settings.section_form.placeholder.customer_signature')}
                                id="customer_signature"
                                name="customer_signature"
                                rows={5}
                                label={t('pages.settings.section_form.label.customer_signature')}
                                value={garage.customer_signature}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.customer_signature && errors.customer_signature}
                            />

                            <SubmitContainer stat={true}>
                                <div className="submit-btn-padd">
                                    <Button size="lg" type="primary" extraClass="mt-20" disabled={formSubmitting.settings}>
                                        {t('global.save')}
                                    </Button>
                                </div>
                            </SubmitContainer>
                        </form>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const CustomerSignatureForm = withFormik(formCustomerSignature)(CustomerSignature);

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
        formSubmitting: state.formSubmitting,
    };
};

export default connect(mapStateToProps)(translate('translations')(CustomerSignatureForm));
