import { API_ERROR, API_SUCCESS, apiRequest } from 'store/actions/api.actions';
import {CHANGE_USER_DETAILS_SETTING_UPDATE} from "store/actions/change-user-details.settings.actions";
import {formSubmittingSettings} from "store/actions/formSubmitting.actions";
import {userSet} from "store/actions/user.actions";

export const SETTINGS_CHANGE_PASSWORD_UPDATE_URL = 'garage/settings/update-user-details';

export const changeUserDetailsSettingMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {

            case CHANGE_USER_DETAILS_SETTING_UPDATE:
                dispatch(formSubmittingSettings(true));

                let formData = new FormData();
                Object.keys(action.payload).forEach(key => {
                    formData.append(key, action.payload[key]);
                });

                if(!action.payload.avatar){
                    formData.delete('avatar');
                }

                dispatch(
                    apiRequest({
                        body: formData,
                        method: 'POST',
                        url: SETTINGS_CHANGE_PASSWORD_UPDATE_URL,
                        signature: CHANGE_USER_DETAILS_SETTING_UPDATE
                    })
                );

                break;

            case `${CHANGE_USER_DETAILS_SETTING_UPDATE} ${API_SUCCESS}`:
                dispatch(formSubmittingSettings(false));
                dispatch(userSet(action.payload.data));
                break;

            case `${CHANGE_USER_DETAILS_SETTING_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingSettings(false));
                break;

            default:
                break;
        }
    };
};
