import React, { Component } from 'react';
import "./ImageCrop.scss";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Button from "../Button/Button";
import {translate} from "react-i18next";
import resizeImg from "./resizeImage";
import {AVATAR} from "../../../store/consts/user.constants";

class ImageCrop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageSrc: props.image,
            crop: { x: 0, y: 0 },
            cropSize: { width: 200, height: 200 },
            zoom: 1,
            minZoom: 0.1,
            rotation: 0,
            aspect: 1,
            croppedAreaPixels: null,
            cropping: false,
        }
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }

    saveCroppedImage = () => {
        const {onCrop} = this.props;
        const {imageSrc, rotation, croppedAreaPixels} = this.state;

        this.setState({cropping: true})

        getCroppedImg(imageSrc, croppedAreaPixels, rotation)
        .then((e) => {
            resizeImg(e, AVATAR.WIDTH, AVATAR.HEIGHT).then(res => {
                onCrop(res)
                this.setState({cropping: false})
            });
        })
        .catch((e) => {
            this.setState({cropping: false})
        });
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    render() {
        const {t, onCancel} = this.props;
        const {imageSrc, crop, zoom, minZoom, aspect, cropSize, rotation, cropping} = this.state;

        return (
            <>
                <div className="crop-container">
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        minZoom={minZoom}
                        aspect={aspect}
                        cropSize={cropSize}
                        rotation={rotation}
                        cropShape="round"
                        showGrid={false}
                        restrictPosition={false}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                </div>
                <div className="controls mt-20">
                    <div className="row">
                        <div className="col-xs-6">
                            <input type="range" id="zoom" name="zoom" value={zoom} min={minZoom} max="3" step={minZoom} onChange={(e) => this.setState({zoom: e.target.value})}/>
                            <div className="text-center font-size-sm"><label htmlFor="zoom">{t('pages.user_profile.section_form.avatar.crop.zoom')}</label></div>
                        </div>
                        <div className="col-xs-6">
                            <input type="range" id="rotate" name="rotate" value={rotation} min={0} max="360" step={1} onChange={(e) => this.setState({rotation: e.target.value})}/>
                            <div className="text-center font-size-sm"><label htmlFor="rotate">{t('pages.user_profile.section_form.avatar.crop.rotate')}</label></div>
                        </div>
                    </div>
                </div>
                <div className="mt-20">
                    <Button size="lg" type="primary" onClick={this.saveCroppedImage} disabled={cropping}>{t('pages.user_profile.section_form.avatar.crop.save')}</Button>
                    <div className="text-center">
                        <span className="link-style" onClick={onCancel}>{t('pages.user_profile.section_form.avatar.crop.cancel')}</span>
                    </div>
                </div>
            </>
        );
    }
}

export default translate('translations')(ImageCrop);
