import Resizer from "react-image-file-resizer";

export const resizeImg = (file, maxWidth, maxHeight) => new Promise(resolve => {
    Resizer.imageFileResizer(file, maxWidth, maxHeight, 'PNG', 100, 0,
        uri => {
            resolve(uri);
        },
        'blob'
    );
});

export default resizeImg;
