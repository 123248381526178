import checklistLabours from '../models/checklistLabours'
import checklistLabourModel from '../models/checklistLabour'
import {
    CHECKLIST_LABOURS_FIELD_UPDATE,
    CHECKLIST_LABOURS_RESET,
    CHECKLIST_LABOURS_SET,
    CHECKLIST_LABOURS_UPDATE
} from '../actions/checklistLabours.actions'
import {defaultsDeep} from "lodash";

export default function(state = checklistLabours, action) {
    switch (action.type) {
        case CHECKLIST_LABOURS_SET:
            return action.payload.checklistLabours.map(checklistLabour => (
                defaultsDeep(
                    checklistLabour,
                    checklistLabourModel
                )
            ));

        case CHECKLIST_LABOURS_UPDATE:
            return state.map(labour => {
                if (labour.id === action.payload.checklistLabour.id) {
                    return defaultsDeep(
                        action.payload.checklistLabour,
                        labour
                    )
                }

                return labour
            })

        case CHECKLIST_LABOURS_FIELD_UPDATE:
            return state.map(labour => {
                if (labour.id === action.payload.checklistLabour.id) {
                    return {
                        ...labour,
                        ...action.payload.field,
                    }
                }

                return labour
            })

        case CHECKLIST_LABOURS_RESET:
            return checklistLabours

        default:
            return state
    }
}
