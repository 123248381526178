import React, {Component} from 'react';
import './CarDetail.scss';
import {translate} from "react-i18next";
import {getValueFromArray} from "utils/car";
import classNames from "classnames";
import {Link} from "react-router-dom";
import pages from "app/consts/routes";

class CarDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            carDetailShow: false
        };
    }

    toggleCarDetail = () => {
        this.setState({
            carDetailShow: !this.state.carDetailShow
        });
    };

    render() {
        const {t, car, editable} = this.props;
        const {carDetailShow} = this.state;

        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);
        const type = getValueFromArray(car.types, 'id', car.type_id);

        if (!car) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="car-detail">

                    <div className="row" onClick={this.toggleCarDetail}>
                        <div className="col-xs-11">
                            {make && make.name && (
                                <div className="car-detail__item">
                                    <span className="car-detail__make">{make.name} {range && range.name && range.name}</span>
                                </div>
                            )}
                        </div>
                        <div className="col-xs-1">
                            <div className="car-detail__icon">
                                <i className={classNames("", {
                                    'icon-chevron-down': this.state.carDetailShow === false,
                                    'icon-chevron-up': this.state.carDetailShow === true
                                })}/>
                            </div>
                        </div>
                    </div>

                    {carDetailShow &&
                        <div>
                            <div className="car-detail__sub row mt-20">
                                <div className="car-detail__sub__label col-xs-3">{t('global.car.make')}</div>
                                <div className="car-detail__sub__value col-xs-9">{make.name}</div>
                            </div>
                            <div className="car-detail__sub row">
                                <div className="car-detail__sub__label col-xs-3">{t('global.car.range')}</div>
                                <div className="car-detail__sub__value col-xs-9">{range.name}</div>
                            </div>
                            <div className="car-detail__sub row">
                                <div className="car-detail__sub__label col-xs-3">{t('global.car.type')}</div>
                                <div className="car-detail__sub__value col-xs-9">{type.name}</div>
                            </div>
                            <div className="car-detail__sub row">
                                <div className="car-detail__sub__label col-xs-3">{t('global.car.year')}</div>
                                <div className="car-detail__sub__value col-xs-9">{car.first_registration}</div>
                            </div>
                            {car.licence_plate &&
                            <div className="car-detail__sub row">
                                <div className="car-detail__sub__label col-xs-3">{t('global.car.licence_plate')}</div>
                                <div className="car-detail__sub__value col-xs-9">{car.licence_plate}</div>
                            </div>}
                            {car.mileage &&
                                <div className="car-detail__sub row">
                                    <div className="car-detail__sub__label col-xs-3">{t('global.car.mileage')}</div>
                                    <div className="car-detail__sub__value col-xs-9">{car.mileage}</div>
                                </div>
                            }

                            {editable &&
                                <div className="car-detail__icon mt-10">
                                    <Link to={pages.add_cost}><i className="icon-edit"/> {t('global.car.edit')}</Link>
                                </div>
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default translate('translations')(CarDetail);
