export function setSessionStorageItem(key, item) {
    sessionStorage.setItem(key, item);
}

export function removeSessionStorageItem(key) {
    sessionStorage.removeItem(key);
}

export function setLocalStorageItem(key, item) {
    localStorage.setItem(key, item);
}

export function removeLocalStorageItem(key) {
    localStorage.removeItem(key);
}

export default {
    setLocalStorageItem,
    removeLocalStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem
};
