import React, { Component } from 'react';
import './InputNumber.scss';
import classNames from 'classnames';
import {FLOAT_REGEX, INTEGER_REGEX} from "../Input/Input";

class Input extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || this.props.minimum
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const {float, integer} = this.props;

        if(float){
            this.inputFilter(this.inputRef, value => FLOAT_REGEX.test(value));
        }
        else if (integer) {
            this.inputFilter(this.inputRef, value => INTEGER_REGEX.test(value));
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    inputFilter = (textbox, inputFilter) => {
        ['input', 'keydown', 'keyup'].forEach((event) => {
            textbox.addEventListener(event, (e) => {
                let ele = e.target;
                if (inputFilter(ele.value)) {
                    ele.value = this.escapeNonValidCharacters(ele.value);
                    ele.oldValue = this.escapeNonValidCharacters(ele.value);
                    ele.oldSelectionStart = ele.selectionStart;
                    ele.oldSelectionEnd = ele.selectionEnd;

                    if(event === 'input'){
                        this.change(e);
                    }
                } else if (ele.hasOwnProperty('oldValue')) {
                    ele.value = this.escapeNonValidCharacters(ele.oldValue);
                    ele.setSelectionRange(ele.oldSelectionStart, ele.oldSelectionEnd);
                }
            });
        });
    };

    escapeNonValidCharacters = (value) => {
        const {float, integer} = this.props;

        if(float && value.indexOf(',') !== -1) {
            return value.replace(',', '.').trim();
        }
        else if(integer) {
            return value.trim();
        }

        return value;
    };

    handleChange = (e, callback) => {
        if (e.target instanceof HTMLInputElement) {

            let maxLen = e.target.maxLength.parseInt();
            if(maxLen > 0 && e.target.value.toString().length > maxLen){
                return;
            }

            this.setState({ value: e.target.value });

            callback();
        }
    };

    increase = () => {
        this.setState({
            value: this.state.value.parseInt() + 1
        }, () => {
            this.props.onChange(this.state.value);
        })
    };

    decrease = () => {
        if(this.state.value.parseInt() <= this.props.minimum){
            return;
        }

        this.setState({
            value: this.state.value.parseInt() - 1
        }, () => {
            this.props.onChange(this.state.value);
        })
    };

    change = e => {
        const {onChange} = this.props;

        this.handleChange(e, () => {
            if (onChange) {
                onChange(e.target.value);
            }
        });
    }

    render() {
        const { value } = this.state;
        const {
            name,
            id,
            placeholder,
            label,
            onClick,
            onBlur,
            onFocus,
            onPaste,
            error,
            className,
            maxLength,
            extraClass,
            hideErrorMessage,
            autoFocus,
            preventOnEnter,
            hidden,
            onKeyPress,
            readonly,
            step,
            disabled
        } = this.props;

        if (hidden) return null;

        return (
            <div className={`form-group ${extraClass ? extraClass : ''} ${disabled ? 'disabled' : ''}`}>

                {label && <label htmlFor={name}>{label}</label>}

                <div className="relative">
                    <div className="input-icon input-icon--left input-icon--left--border font-size-2" onClick={this.decrease}>
                        <span className="link-style font-size-2">-</span>
                    </div>

                    <input
                        onKeyPress={onKeyPress}
                        ref={ref => this.inputRef = ref}
                        type="tel"
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        className={classNames(className, {
                            'form-control': true,
                            'has-left-icon': true,
                            'has-right-icon': true,
                            'input-error': error,
                            'text-center': true
                        })}
                        onChange={this.change}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }}
                        onPaste={e => {
                            if (onPaste)
                                onPaste(e.clipboardData.getData('text/plain'));
                            return false;
                        }}
                        value={value}
                        autoComplete="off"
                        maxLength={maxLength}
                        readOnly={readonly}
                        autoFocus={autoFocus}
                        onKeyDown={e => {
                            if (preventOnEnter && e.keyCode === 13) {
                                e.preventDefault();
                                return false;
                            }
                        }}
                        step={step}
                        disabled={disabled}
                    />

                    <div className="input-icon input-icon--right input-icon--right--border" onClick={this.increase}>
                        <span className="link-style font-size-2">+</span>
                    </div>
                </div>



                {error && !hideErrorMessage && (
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                )}
            </div>
        );
    }
}

export default Input;
