import React, {Component} from 'react';
import {translate} from "react-i18next";
import {CHECKLIST_OK, CHECKLIST_NOT_OK} from "../ChecklistLabour";
import TiresPickContainer, {TIRES_CONTAINER_CALLED_FROM_CHECKLIST} from "../../Tires/TiresPickContainer";
import {TYPE_TIRES} from "../../../store/consts/package/package.type.constants";
import ChecklistLayout from "../components/ChecklistLayout";

class TiresType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTires: false,
            title: props.labour.name,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {labour} = this.props;
        const {updating} = this.state;

        if (prevState.updating !== updating) {
            this.props.setPassed({
                ...labour,
                passed: (prevState.updating === false && updating === true ? null : labour.passed)
            });
        }
    }

    packageExists = () => this.props.packages.find(p => p.id === this.props.labour.package_id)


    handleChange = (passed) => {
        const {onUpdatePassed, labourFieldUpdate} = this.props;

        if (passed === CHECKLIST_NOT_OK) {
            this.setState({
                showTires: true,
            })
        } else if (passed === CHECKLIST_OK) {
            labourFieldUpdate({updating: true, passed: passed}).then(() => {
                onUpdatePassed();
            })
        }
    }

    onCloseShowTires = () => {
        this.setState({
            showTires: false,
        })
    }

    submit = (tires) => {
        const {createOrUpdate, labourFieldUpdate} = this.props;

        labourFieldUpdate({updating: true, passed: CHECKLIST_NOT_OK})

        createOrUpdate(tires, this.packageExists())

        this.setState({
            showTires: false,
        })
    }

    render() {
        const {t, labour, tiresEditPrepare, showNotePopup} = this.props;
        const {showTires, updating} = this.state;

        let noteDisabled = labour.passed === null || labour.updating;

        return (
            <React.Fragment>
                <div className="checklist-labour">
                    <div className="col-xs-8">
                        <div className="checklist-labour__name">
                            {labour.name}
                            {(this.packageExists() && this.packageExists().type === TYPE_TIRES && !updating && labour.passed === CHECKLIST_NOT_OK) &&
                            <span className="link-style" onClick={() => {
                                tiresEditPrepare(this.packageExists().tires)
                                this.setState({showTires: true})
                            }}>
                                    <i className="icon-edit"/>
                                </span>
                            }
                        </div>
                    </div>
                    <ChecklistLayout name={"checklist-labour-list-item"} handleChange={this.handleChange} labour={labour}/>
                    <div className="col-xs-2">
                        <div className="checklist-labour__btn">
                            <span className="link-style" disabled={noteDisabled} onClick={!noteDisabled ? showNotePopup : null}>
                                {labour.note ? t('pages.checklist_labours.note.view') : t('pages.checklist_labours.note.new')}
                            </span>
                        </div>
                    </div>
                </div>

                {showTires &&
                <TiresPickContainer
                    title={labour.name}
                    onCreate={this.submit}
                    onClose={this.onCloseShowTires}
                    calledFrom={TIRES_CONTAINER_CALLED_FROM_CHECKLIST}
                />
                }

            </React.Fragment>
        )
    }
}

export default translate('translations')(TiresType);
